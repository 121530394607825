import { useTheme } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { useStyles } from './styles';
import './styles.css';

export const UChart = props => {
    const classes = useStyles();
    const theme = useTheme();
    const options = {
        title: {
            text: props.title
        },
        tooltip: {
            pointFormat: props.tooltip === 'pie' ? '{series.name}: <b>{point.percentage:.1f}%</b>' : ''
        },
        yAxis: {
            title: {
                text: props.titleY
            }
        },
        xAxis: {
            categories: props.categories,
        },
        series: props.series,
        legend: {
            labelFormatter: function () {
                return this.name + ' ' + (this.y ? this.y : 0)
            }
        }
    }

    return (
        <div className={classes.root}>
            <HighchartsReact
                highcharts={Highcharts}
                className={classes.chart}
                containerProps={{
                    style: {
                        height: '100%',
                        width: '70vw',
                        [theme.breakpoints.up('sm')]: {
                            width: '100%'
                        }
                    }
                }}
                options={props.options || options}
            />
        </div>
    )
};

export default UChart;
