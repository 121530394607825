import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { styled, useTheme } from '@mui/material/styles';
// import { useChart } from '../../components/chart';
import chart, { useChart } from '../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

const StyledChart = styled(chart)(({ theme }) => ({
    height: CHART_HEIGHT,
    '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
        height: `100% !important`,
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        borderTop: `dashed 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

export default function DunutChart({ title, subheader, totalcenter, chart, colors, ...other }) {
    const theme = useTheme();

    const { series, options } = chart;

    const chartSeries = series.map((i) => i.value);

    const chartOptions = useChart({
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        colors,
        labels: series.map((i) => i.label),
        stroke: {
            //   colors: [theme.palette.background.paper],
        },
        legend: {
            floating: true,
            position: 'bottom',
            horizontalAlign: 'center',
        },
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false,
            },
            formatter: (val, opts) => `R$ ${opts.w.globals.series[opts.seriesIndex]}`, // Exibe o valor em vez da porcentagem
        },
        tooltip: {
            fillSeriesColor: false,
            theme: 'dark',
            style: {
                fontSize: '12px',
                color: 'white',
            },
            y: {
                formatter: (value) => value,
                title: {
                    color: "white",
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70%', // Tamanho do centro da rosquinha
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '22px',
                            color: theme.palette.text.primary,
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            color: theme.palette.text.secondary,
                            formatter: (val) => val,
                        },
                        total: {
                            show: true,
                            showAlways: true,
                            label: 'Total',
                            fontSize: '22px',
                            fontWeight: 600,
                            color: theme.palette.text.primary,
                            formatter: (w) => `R$ ${totalcenter}`,
                        },
                    },
                },
            },
        },
        ...options,
    });

    return (
        <Card {...other} style={{ width: "-webkit-fill-available", boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.75)" }}>
            <CardHeader title={title} subheader={subheader} sx={{ mb: 5 }} />

            <StyledChart
                dir="ltr"
                type="donut" // Alterando o tipo do gráfico para 'donut'
                series={chartSeries}
                options={chartOptions}
                width="100%"
                height={280}
            />
        </Card>
    );
}

DunutChart.propTypes = {
    chart: PropTypes.object,
    subheader: PropTypes.string,
    title: PropTypes.string,
};
