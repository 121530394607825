import api from '../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const hotelRepositorio = {
    postSearchHotels: async (data) => {
        let response = await api.post('/Hotelv2/v2/GetSearch', data, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response.data;
    },
    getLocations: async () => {
        let response = await api.get('/Hotelv2/v2/GetHotelLocations', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response.data;
    },
    getHotelDetail: async (token) => {
        let response = await api.get('/Hotelv2/v2/GetHotelDetail/' + encodeURIComponent(token), {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })

        return response.data;
    },
    postHotelPrice: async (data) => {
        let response = await api.post('/Hotelv2/v2/GetHotelPrice', data, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response.data;
    },
    postHotelRules: async (data) => {
        let response = await api.post('/Hotelv2/v2/GetHotelSearchBookingRules', data, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response.data;
    },
    postHotelPaymentModes: async (data) => {
        let response = await api.post('/Hotel/PostHotelPaymentModes', data, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response.data;
    },
    getHotelPaymentModesInLines: async (id) => {
        let response = await api.get(`/Hotelv2/v2/getHotelPaymentModesInLines/${id}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response.data;
    },
    postHotelReserve: async (data) => {
        let response = await api.post('/Hotel/PostHotelBookingCreate', data, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        })
        return response.data
    },

    getBookingHotelInLines: async (id) => {
        let response = await api.get('/Reserva/GetBookingHotelInLines/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        })
        return response.data
    },

    postCommitBookingHotel: async (id, dto) => {
        let response = await api.post('/Hotelv2/v2/PostHotelBookingAndIssueLines_v2/' + id, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    postHotelBookingCancel_Lines: async (id) => {
        let response = await api.delete('/Hotelv2/v2/PostHotelBookingCancel_Lines/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    postEnviarHoteisCotar: async (model) => {
        let response = await api.post('/Hotelv2/v2/SendHotelCotacao', model, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    updateCCPorPassageiro: async (model) => {
        let response = await api.put('/Hotelv2/v2/UpdateCCPorPassageiro', model, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    updateInfoBookingByRoom: async (model) => {
        let response = await api.put('/Hotelv2/v2/UpdateInfoBookingByRoom', model, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    }
}

export default hotelRepositorio;
