import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, SaveOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import LinesTextField from '../../../components/LinesTextField';
import USearchField from '../../../components/usearchfield';
import pacoteAgenteRepositorio from '../../../repositorios/pacoteAgente';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogCreatePacote(props) {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [viajante, setViajante] = useState(null);
    const [titulo, setTitulo] = useState('');
    const doneAction = () => {
        props.handleClickOpenDialogCreatePacote()
        props.setLoad(!props.load)
    }

    const handleCloseDialog = () => {
        props.handleClickOpenDialogCreatePacote()
    }

    const handleChangedTitulo = (event) => {
        setTitulo(event.target.value)
    }

    const handleClickSave = async () => {
        if (titulo === '') {
            props.setSnackVariant('error');
            props.setSnackMessage('Título não pode ser vazio.');
            props.setSnackVisible({ visible: true })
            return;
        }

        if (viajante === null) {
            props.setSnackVariant('error');
            props.setSnackMessage('Viajante não pode ser vazio.');
            props.setSnackVisible({ visible: true })
            return;
        }
        props.setLoad(!props.load)

        let dto = {
            idviajante: viajante.value,
            titulo: titulo
        }

        try {
            props.setIsLoading(true)
            await pacoteAgenteRepositorio.postCreatePacote(dto);
            doneAction()
        } catch (e) {
            props.setSnackVariant('error');
            props.setSnackMessage('Falha ao salvar pacote.');
            props.setSnackVisible({ visible: true })
        } finally {
            props.setIsLoading(false)
        }
    }

    useEffect(() => {
        setViajante(null)
        setTitulo('')
    }, [])

    return (
        <div>
            <Dialog
                onClose={() => handleCloseDialog()}
                open={props.openDialogCreatePacote}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={responsive}
                maxWidth={'xl'}
            >
                <DialogTitle style={{ background: '#C62828' }}>
                    <Typography style={{ color: '#ffffff' }}>Novo pacote</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <Box minWidth={'40vw'}>
                            <LinesTextField
                                onChange={handleChangedTitulo}
                                value={titulo}
                                name="titulo"
                                fullWidth
                                label="Título"
                                margin="dense"
                                variant="outlined"
                            />
                            <USearchField label="Viajante" itens={props.viajantes} value={viajante} setValue={setViajante} />
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog()} className={responsive ? classes.buttonMobile : classes.button} endIcon={<CloseOutlined />}>Fechar </Button>
                    <Button onClick={() => handleClickSave()} className={responsive ? classes.buttonMobile : classes.button} endIcon={<SaveOutlined />}>Salvar </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default DialogCreatePacote;
