import { Box, Button, ButtonGroup, Container, Grid, Hidden, IconButton, InputAdornment, Paper, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GetApp, ImportExportOutlined, SearchOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LinesTextField from '../../../../components/LinesTextField';
import Snackbar from '../../../../components/snackbar';
import UDatePicker from '../../../../components/udatepicker';
import ExportExcel from '../../../../components/UExportExcel';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import compraConsolidadaRepositorio from '../../../../repositorios/relatorio/compraConsolidada';
import Dashboard from '../../../dashboard';
import ComprasConsolidadasGrid from './ComprasConsolidadasGrid';
import ComprasConsolidadasList from './ComprasConsolidadasList';
import DialogImportar from './DialogImportar';
import { useStyles } from './styles';

function isValidDate(date) {
    return moment(date).isValid();
}

export const ListaComprasConsolidadas = () => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [colunasExcel, setColunasExcel] = useState([]);
    const [rowsExcel, setRowsExcel] = useState([]);
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    const politicaViagem = useSelector(state => state.permissao.politicaViagem);
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [data, setData] = useState({ total: 0, data: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [centroCusto, setCentroCusto] = useState(null);
    const [viajante, setViajante] = useState(null);
    const [viajantes, setViajantes] = useState([]);
    const [tipoValue, setTipoValue] = useState(null);
    const [tiposValues, setTiposValues] = useState(null);
    const [nFatura, setNFatura] = useState(null);
    const [modalPlanilha, setModalPlanilha] = useState(false);
    const [pesquisado, setPesquisado] = useState(false);
    const [periodo, setPeriodo] = useState();

    const handleClickPesquisarByNFaturado = async () => {
        if (nFatura === null || nFatura === '') {
            setSnackVariant('error');
            setSnackMessage('Nº da fatura está vazio.');
            setSnackVisible({ visible: true })
            return;
        }
        try {
            setIsLoading(true);
            let response = await compraConsolidadaRepositorio.getConsolidadoTenantByNFatura(nFatura);
            setData(response)
            setPesquisado(true)
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro na busca por Nº da fatura.');
            setSnackVisible({ visible: true })
            return;
        } finally {
            setIsLoading(false);
        }
    }

    const handleClickSearch = async () => {
        if (dataInicio > dataFim) {
            setSnackVariant('error');
            setSnackMessage('Data de início maior que data final.');
            setSnackVisible({ visible: true })
            return;
        }

        if (!isValidDate(dataInicio) || !isValidDate(dataFim)) {
            setSnackVariant('error');
            setSnackMessage('Data Inválida.');
            setSnackVisible({ visible: true });
            return;
        }

        let dto = {
            dataInicio,
            dataFim,
            idCentroCusto: centroCusto ? centroCusto.value : 0,
            idViajante: viajante ? viajante.value : 0,
            nFatura: nFatura ? nFatura : '',
            tipo: tipoValue ? tipoValue.label : '',
        }

        try {
            setIsLoading(true);
            const response = await compraConsolidadaRepositorio.getConsolidadoTenant(dto);
            setData(response)
            setPesquisado(true)
        } catch (error) {
            setSnackVariant('error');
            setSnackMessage('Erro ao gerar relatório.');
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false);
        }
    }

    
    const handleChangeData = () => {
        let inicio = new Date();
        if ((dataInicio !== '' && dataInicio !== undefined && dataInicio !== null) && (dataFim !== '' && dataFim !== undefined && dataFim !== null)) {
            if (dataInicio.toLocaleDateString() === new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date().toLocaleDateString()) {
                return 1;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth(), 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0).toLocaleDateString()) {
                return 2;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), 0, 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), 11, 31).toLocaleDateString()) {
                return 3;
            }
            return 4;
        }
    }

    useEffect(() => {
        setColunasExcel(["Nº Fatura", "Faturado", "Nome", "Fornecedor", "Cidade", "Estado", "Data Ida", "Data Volta", "Valor", "Diárias", "Origem", "Destino", "Retorno", "Descrição", "Centro de Custo", "Tipo"])
        const datas = data?.data?.map(item => {
            return [
                item.nfatura,
                item.faturado,
                item.nome,
                item.fornecedor,
                item.cid,
                item.uf,
                item.dataida,
                item.datavolta,
                item.valor,
                item.diarias,
                item.origem,
                item.destino,
                item.retorno,
                item.descricao,
                item.centrocusto,
                item.tipo,
            ]
        })
        setRowsExcel(datas);
    }, [data])

    const handleClickOpenDialogImportar = () => {
        setModalPlanilha(true);
    }

    useEffect(() => {
        if (periodo) {
          let inicio = new Date();
          let fim = new Date();
    
          switch (periodo) {
            case 1:
              inicio.setDate(inicio.getDate() - 7);
              break;
            case 2:
              inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
              fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
              break;
            case 3:
              inicio = new Date(inicio.getFullYear(), 0, 1);
              fim = new Date(inicio.getFullYear(), 11, 31);
              break;
          }
    
          setDataInicio(inicio);
          setDataFim(fim);
        }
      }, [periodo]);

    useEffect(() => {
        (async () => {
            const response = await centroCustoRepositorio.getSearchField();
            const responseViajantes = await pessoaRepositorio.getSearchField();
            const responseTipos = await compraConsolidadaRepositorio.getConsolidadoTiposByTenant();
            setTiposValues(responseTipos.itens)
            setCentrosCusto(response);
            setViajantes(responseViajantes);
        })()
    }, [])

    return (
        <section>
            <Hidden smUp>
                <ULoaderVoo isLoading={isLoading} />
            </Hidden>
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <DialogImportar modalPlanilha={modalPlanilha} setModalPlanilha={setModalPlanilha} />
            <Container maxWidth='xl'>
                <Box mt={2} mb={responsive ? 10 : 0}>
                    <Box mb={1}>
                        <Grid container>
                            <Grid item xs={responsive ? 12 : 3}>
                                <Typography variant="h6" component="h1" > Compras Consolidadas </Typography>
                            </Grid>
                            {responsive ? null : (
                                <>
                                    <Grid item xs={7}>
                                        <Grid item xs={12} className={classes.groupButton}>
                                            <ButtonGroup>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 1 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 1 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodo(1)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Semanal</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 2 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 2 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodo(2)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Mensal</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 3 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 3 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodo(3)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Anual</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 4 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 4 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodo(4)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Personalizado</Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    <Paper elevation={3}>
                        <Box p={2}>
                            <form autoComplete="off">
                                <Grid container spacing={1} >
                                    <Grid item xs={12} md={2}>
                                        <UDatePicker date={dataInicio} setDate={setDataInicio} label="Data Início" />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <UDatePicker date={dataFim} setDate={setDataFim} label="Data Fim" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        {permissaoDashboard.relatorioCentroCusto ? (
                                            <USearchField disabled={!politicaViagem.utilizarcentrocusto} label="Centro de custo" itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {permissaoDashboard.relatorioPessoal ? (
                                            <USearchField label="Viajante" itens={viajantes} value={viajante} setValue={setViajante} />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={1} style={{ alignSelf: 'center' }}>
                                        {responsive ? null : (
                                            <Button onClick={handleClickSearch} className={classes.button} variant="contained" fullWidth >
                                                <SearchOutlined />
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={3}>
                                        <USearchField label="Tipo de Produto" itens={tiposValues} value={tipoValue} setValue={setTipoValue} />
                                    </Grid>
                                    {responsive ? (
                                        <Button onClick={handleClickSearch} className={classes.button} variant="contained" fullWidth >
                                            <SearchOutlined />
                                        </Button>
                                    ) : null}
                                    <Grid item xs={responsive ? 12 : 3} >
                                        <LinesTextField
                                            fullWidth
                                            label="Pesquisa por Nº da Fatura"
                                            value={nFatura}
                                            onChange={e => setNFatura(e.target.value)}
                                            variant="outlined"
                                            margin="dense"
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position={'end'}>
                                                        <Tooltip title={<Typography style={{ fontSize: '10pt' }}>Pesquisar somente pelo Nº da Fatura.</Typography>}>
                                                            <IconButton onClick={() => handleClickPesquisarByNFaturado()}>
                                                                <SearchOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                            }}
                                        />
                                    </Grid>

                                    {responsive ? (
                                        <>
                                            <Grid item xs={12} className={classes.gridToolBar2}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Button className={classes.button} onClick={() => handleClickOpenDialogImportar()} fullWidth>
                                                            <ImportExportOutlined />
                                                            Importar
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        {pesquisado ? (
                                                            <>
                                                                <Typography variant='body2' style={{ marginLeft: '15px' }}>

                                                                    {`Total: ${data.total}`}
                                                                </Typography>
                                                                <ExportExcel name="Relatório de Compras Consolidadas" columns={colunasExcel} data={rowsExcel} element={
                                                                    <>
                                                                        <Button className={classes.button}>
                                                                            <GetApp />
                                                                            Exportar
                                                                        </Button>
                                                                    </>
                                                                } />
                                                            </>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={responsive ? 12 : 3} className={classes.gridToolBar}>

                                            </Grid>
                                        </>
                                    ) : (
                                        <>
                                            <Grid item xs={3} className={classes.gridToolBar2}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Button className={classes.button} onClick={() => handleClickOpenDialogImportar()} fullWidth={responsive ? true : false}>
                                                        <ImportExportOutlined />
                                                        Importar
                                                    </Button>
                                                    {pesquisado ? (
                                                        <Typography variant='body2' style={{ marginLeft: '15px' }}>
                                                            {`Total: ${data.total}`}
                                                        </Typography>
                                                    ) : null}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={responsive ? 12 : 3} className={classes.gridToolBar}>
                                                {pesquisado ? (
                                                    <ExportExcel name="Relatório de Compras Consolidadas" columns={colunasExcel} data={rowsExcel} element={
                                                        <>
                                                            <Button className={classes.button}>
                                                                <GetApp />
                                                                Exportar
                                                            </Button>
                                                        </>
                                                    } />
                                                ) : null}
                                            </Grid>
                                        </>
                                    )}
                                </Grid>

                            </form>
                        </Box>
                    </Paper>
                    <Box mt={2}>
                        {responsive ? (
                            <>
                                <ComprasConsolidadasList isLoading={isLoading} data={data.data} />
                            </>
                        ) : (
                            <>
                                <Paper elevation={3}>
                                    <ComprasConsolidadasGrid isLoading={isLoading} data={data.data} />
                                </Paper>
                            </>
                        )}
                    </Box>
                </Box>
            </Container >
        </section >
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaComprasConsolidadas} />
        </div>
    )
};
export default Lista;
