import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import LinesTextField from '../../../../components/LinesTextField';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import aereoPassageiro from '../../../../repositorios/aereoPassageiro';

export const AlterarValorPassageiro = props => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [value, setValue] = useState();
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const handleClickSalvar = async () => {
        try {
            setIsLoading(true);
            let dto = null
            if (props.typeEditPassageiro == 'tarifa') {
                dto = {
                    idaereopassageiro: props.passageiro.idaereopassageiro,
                    total: value,
                }
                await aereoPassageiro.editValor(dto);
            } else if (props.typeEditPassageiro == 'extras') {
                dto = {
                    idaereopassageiro: props.passageiro.idaereopassageiro,
                    taxes: value,
                }
                await aereoPassageiro.putEditTaxes(dto);
            }
            await props.loadAereo();
            setIsLoading(false);
            props.setOpen(false);
            setMensagemSnack('Valor alterado!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setIsLoading(false);
            setMensagemSnack('Erro ao salvar!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }

    }

    function setConfigPage() {
        switch (props.typeEditPassageiro) {
            case 'tarifa':
                return {
                    valor: props.passageiro.total,
                    messageTitle: 'Alterar valor da tarifa'
                }
            case 'extras':
                return {
                    valor: props.passageiro.taxes,
                    messageTitle: 'Alterar valor dos extras'
                }
            default:
                break;
        }
    }

    useEffect(() => {
        (async () => {
            setValue(setConfigPage()?.valor);
        })();
    }, [props.passageiro])

    return (
        <>
            <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                fullWidth
                maxWidth="xs"
                fullScreen={responsive}
            >
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#646464' }}>
                        {/* <Typography style={{ color: '#fff' }}>Alterar valor do passageiro</Typography> */}
                        <Typography style={{ color: '#fff' }}>{setConfigPage()?.messageTitle}</Typography>
                        <IconButton onClick={() => props.setOpen(false)} >
                            <CloseOutlined style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
                    <LinesTextField fullWidth variant='outlined' margin='dense' name="value" label="Novo Valor"
                        value={value} onChange={(e) => setValue(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClickSalvar}
                        variant="contained"
                        style={{
                            color: '#fff',
                            background: '#c62828',
                            '&:hover': {
                                background: '#b91119'
                            }
                        }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default AlterarValorPassageiro;
