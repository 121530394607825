import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Paper,
  TableContainer,
  List,
  ListSubheader,
  TableBody,
  Checkbox,
  ListItem,
  ListItemText,
  useMediaQuery,
  Grid,
} from "@material-ui/core";
import permissaoRepositorio from "../../../../../repositorios/permissao";
import UCheckbox from "../../../../../components/UCheckbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import USelect from "../../../../../components/uselect";

export const CadastroConfiguracaoPermissaoUsuario = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width: 480px)");
  const [expanded, setExpanded] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
    panel7: false,
    panel8: false,
    panel9: false,
    panel10: false,
    panel11: false,
    panel12: false,
    panel13: false,
  });

  const handleChange = (newExpanded) => {
    setExpanded({ ...expanded, [newExpanded]: !expanded[newExpanded] });
  };

  const handleChangeCheckbox = (name) => (event) => {
    props.setPermissao({
      ...props.permissao,
      [name]: event.target.checked,
    });
  };

  const itens = [
    {
      titulo: "Aéreo",
      panelVaue: expanded.panel1,
      painel: "panel1",
      itens: [
        {
          titulo: "Ver aéreo",
          value: "permiteveraereo",
          checked: props.permissao.permiteveraereo,
        },
      ],
    },

    {
      titulo: "Caixa de entrada",
      panelVaue: expanded.panel2,
      painel: "panel2",
      itens: [
        {
          titulo: "Ver caixa de entrada",
          value: "permitevercaixadeentrada",
          checked: props.permissao.permitevercaixadeentrada,
        },
      ],
    }, {
      titulo: "Centro de custo",
      panelVaue: expanded.panel3,
      painel: "panel3",
      itens: [
        {
          titulo: "Cadastrar centro de custo",
          value: "permitecriarcentrocusto",
          checked: props.permissao.permitecriarcentrocusto,
        },
        {
          titulo: "Editar centro de custo",
          value: "permiteeditarcentrocusto",
          checked: props.permissao.permiteeditarcentrocusto,
        },
        {
          titulo: "Ver centro de custo",
          value: "permitevercentrocusto",
          checked: props.permissao.permitevercentrocusto,
        },
      ],
    }, {
      titulo: "Despesa por viagem",
      panelVaue: expanded.panel4,
      painel: "panel4",
      itens: [
        {
          titulo: "Tipo de usuário",
          value: "permissaodespesaporviagem",
        },
      ],
    }, {
      titulo: "Eco Gestão",
      panelVaue: expanded.panel12,
      painel: "panel12",
      itens: [
        {
          titulo: "Editar Eco Gestão",
          value: "permiteeditarecogestao",
          checked: props.permissao.permiteeditarecogestao,
        },
        {
          titulo: "Motorista",
          value: "permitesermotorista",
          checked: props.permissao.permitesermotorista,
        },
        {
          titulo: "Ver Eco Gestão",
          value: "permiteverecogestao",
          checked: props.permissao.permiteverecogestao,
        },
      ],
    }, {
      titulo: "Fornecedor",
      panelVaue: expanded.panel5,
      painel: "panel5",
      itens: [
        {
          titulo: "Cadastrar fornecedor",
          value: "permitecriarfornecedor",
          checked: props.permissao.permitecriarfornecedor,
        },
        {
          titulo: "Editar fornecedor",
          value: "permiteeditarfornecedor",
          checked: props.permissao.permiteeditarfornecedor,
        },
        {
          titulo: "Ver fornecedor",
          value: "permiteverfornecedor",
          checked: props.permissao.permiteverfornecedor,
        },
      ],
    }, {
      titulo: "Hotel",
      panelVaue: expanded.panel13,
      painel: "panel13",
      itens: [
        {
          titulo: "Ver Hotel",
          value: "permiteverhotel",
          checked: props.permissao.permiteverhotel,
        },
        {
          titulo: "Reservar Hotel",
          value: "permitereservarhotel",
          checked: props.permissao.permitereservarhotel,
        },
        {
          titulo: "Emitir Hotel",
          value: "permiteemitirhotel",
          checked: props.permissao.permiteemitirhotel,
        },
      ],
    },
    // {
    //   titulo: "Hotsite",
    //   panelVaue: expanded.panel3,
    //   painel: "panel3",
    //   itens: [
    //     {
    //       titulo: "Criar hotsite",
    //       value: "permitecriarhotsite",
    //       checked: props.permissao.permitecriarhotsite,
    //     },
    //     {
    //       titulo: "Ver hotsite",
    //       value: "permiteverhotsite",
    //       checked: props.permissao.permiteverhotsite,
    //     },
    //     {
    //       titulo: "Editar hotsite",
    //       value: "permiteeditarhotsite",
    //       checked: props.permissao.permiteeditarhotsite,
    //     },
    //   ],
    // },
    {
      titulo: "Pessoa",
      panelVaue: expanded.panel6,
      painel: "panel6",
      itens: [
        {
          titulo: "Cadastrar pessoa",
          value: "permitecriarpessoa",
          checked: props.permissao.permitecriarpessoa,
        },
        {
          titulo: "Editar Pessoa",
          value: "permiteeditarpessoa",
          checked: props.permissao.permiteeditarpessoa,
        },
        {
          titulo: "Ver pessoa",
          value: "permiteverpessoa",
          checked: props.permissao.permiteverpessoa,
        },
      ],
    },
    {
      titulo: "Produto",
      panelVaue: expanded.panel7,
      painel: "panel7",
      itens: [
        {
          titulo: "Cadastrar produto",
          value: "permitecriarproduto",
          checked: props.permissao.permitecriarproduto,
        },
        {
          titulo: "Editar Produto",
          value: "permiteeditarproduto",
          checked: props.permissao.permiteeditarproduto,
        },
        {
          titulo: "Ver produto",
          value: "permiteverproduto",
          checked: props.permissao.permiteverproduto,
        },
      ],
    },
    {
      titulo: "Reservas",
      panelVaue: expanded.panel8,
      painel: "panel8",
      itens: [
        {
          titulo: "Criar Aéreo",
          value: "permitereservaraereo",
          checked: props.permissao.permitereservaraereo,
        },
        {
          titulo: "Emitir Aéreo",
          value: "permiteemitir",
          checked: props.permissao.permiteemitir,
        },
        {
          titulo: "Cancelar Aéreo",
          value: "permitecancelarreserva",
          checked: props.permissao.permitecancelarreserva,
        },
        {
          titulo: "Alterar status do Aéreo",
          value: "permitealterarstatusreserva",
          checked: props.permissao.permitealterarstatusreserva,
        },
        {
          titulo: "Fluxo de autorização",
          value: "permiteautorizarnegar",
          checked: props.permissao.permiteautorizarnegar,
        },
      ],
    },
    {
      titulo: "Relatórios",
      panelVaue: expanded.panel9,
      painel: "panel9",
      itens: [
        {
          titulo: "Ver relatórios",
          value: "permiteverrelatorio",
          checked: props.permissao.permiteverrelatorio,
        },
        {
          titulo: "Somente relatórios pessoais",
          value: "permiteverrelatoriopessoal",
          checked: props.permissao.permiteverrelatoriopessoal,
        },
        {
          titulo: "Relatórios por setores",
          value: "permiteverrelatoriocentrocusto",
          checked: props.permissao.permiteverrelatoriocentrocusto,
        },
        {
          titulo: "Inserir dados no relatório",
          value: "permiteinserirdadosrelatorio",
          checked: props.permissao.permiteinserirdadosrelatorio,
        },
      ],
    },
    {
      titulo: "Solicitação",
      panelVaue: expanded.panel10,
      painel: "panel10",
      itens: [
        {
          titulo: "Criar solicitação",
          value: "permitecriarsolicitacao",
          checked: props.permissao.permitecriarsolicitacao,
        },
        {
          titulo: "Alterar status solicitação",
          value: "permitealterarstatussolicitacao",
          checked: props.permissao.permitealterarstatussolicitacao,
        },
        {
          titulo: "Inserir produto solicitação",
          value: "permitevincularprodutosolicitacao",
          checked: props.permissao.permitevincularprodutosolicitacao,
        },
        {
          titulo: "Ver solicitação",
          value: "permiteversolicitacao",
          checked: props.permissao.permiteversolicitacao,
        },
        {
          titulo: "Alterar status financeiro",
          value: "permitealterarfinanceiro",
          checked: props.permissao.permitealterarfinanceiro,
        }
      ],
    },
    {
      titulo: "Usuário",
      panelVaue: expanded.panel11,
      painel: "panel11",
      itens: [
        {
          titulo: "Cadastrar usuário",
          value: "permitecriarusuario",
          checked: props.permissao.permitecriarusuario,
        },
        {
          titulo: "Editar usuário",
          value: "permiteeditarusuario",
          checked: props.permissao.permiteeditarusuario,
        },
        {
          titulo: "Ver usuário",
          value: "permiteverusuario",
          checked: props.permissao.permiteverusuario,
        },
      ],
    },




  ];
  const [usuarioPermissao, setUsuarioPermissao] = useState(
    props.permissao.permissaodespesaporviagem === null
      ? 0
      : props.permissao.permissaodespesaporviagem
  );

  const permissaoUsuario = [
    {
      value: 1,
      label: "Colaborador",
    },
    {
      value: 2,
      label: "Aprovador",
    },
    {
      value: 3,
      label: "Autorizador",
    },
    {
      value: 4,
      label: "Financeiro",
    },
    {
      value: 5,
      label: "Todas permissões",
    },
  ];
  const handleChangePermissao = (event) => {
    props.setPermissao({
      ...props.permissao,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={classes.body}>
      <Paper>
        {!matches ? (
          <>
            <Accordion
              square
              expanded={expanded.panel1}
              onChange={() => handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Aéreo</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Permitir ver aéreo</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteveraereo")}
                        checked={props.permissao.permiteveraereo}
                        value={"permiteveraereo"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel2}
              onChange={() => handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Caixa de entrada
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      Permitir ver caixa de entrada
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitevercaixadeentrada"
                        )}
                        checked={props.permissao.permitevercaixadeentrada}
                        value={"permitevercaixadeentrada"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel3}
              onChange={() => handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Centro de custo
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      Cadastrar centro de custo
                    </TableCell>
                    <TableCell align="center">Editar centro de custo</TableCell>
                    <TableCell align="center">Ver centro de custo</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitecriarcentrocusto"
                        )}
                        checked={props.permissao.permitecriarcentrocusto}
                        value={"permitecriarcentrocusto"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permiteeditarcentrocusto"
                        )}
                        checked={props.permissao.permiteeditarcentrocusto}
                        value={"permiteeditarcentrocusto"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permitevercentrocusto")}
                        checked={props.permissao.permitevercentrocusto}
                        value={"permitevercentrocusto"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded.panel4}
              onChange={() => handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Despesa por viagem
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid style={{ width: "100%" }}>
                  <USelect
                    {...{
                      itens: permissaoUsuario,
                      value: usuarioPermissao,
                      setValue: setUsuarioPermissao,
                      label: "Permissao",
                    }}
                    disabled={false}
                    name="permissaodespesaporviagem"
                    onChange={handleChangePermissao}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion
              square
              expanded={expanded.panel12}
              onChange={() => handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Eco Gestão
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Editar eco gestão</TableCell>
                    <TableCell align="center">Motorista</TableCell>
                    <TableCell align="center">Ver eco gestão</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permiteeditarecogestao"
                        )}
                        checked={props.permissao.permiteeditarecogestao}
                        value={"permiteeditarecogestao"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitesermotorista"
                        )}
                        checked={props.permissao.permitesermotorista}
                        value={"permitesermotorista"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permiteverecogestao"
                        )}
                        checked={props.permissao.permiteverecogestao}
                        value={"permiteverecogestao"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel5}
              onChange={() => handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Fornecedor</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Cadastrar fornecedor</TableCell>
                    <TableCell align="center">Editar fornecedor</TableCell>
                    <TableCell align="center">Ver fornecedor</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitecriarfornecedor"
                        )}
                        checked={props.permissao.permitecriarfornecedor}
                        value={"permitecriarfornecedor"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permiteeditarfornecedor"
                        )}
                        checked={props.permissao.permiteeditarfornecedor}
                        value={"permiteeditarfornecedor"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteverfornecedor")}
                        checked={props.permissao.permiteverfornecedor}
                        value={"permiteverfornecedor"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel13}
              onChange={() => handleChange("panel13")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Hotel</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Ver Hotel</TableCell>
                    <TableCell align="center">Reservar Hotel</TableCell>
                    <TableCell align="center">Emitir Hotel</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteverhotel")}
                        checked={props.permissao.permiteverhotel}
                        value={"permiteverhotel"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permitereservarhotel")}
                        checked={props.permissao.permitereservarhotel}
                        value={"permitereservarhotel"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteemitirhotel")}
                        checked={props.permissao.permiteemitirhotel}
                        value={"permiteemitirhotel"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel6}
              onChange={() => handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Produto</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Cadastrar produto</TableCell>
                    <TableCell align="center">Editar produto</TableCell>
                    <TableCell align="center">Ver produto</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permitecriarproduto")}
                        checked={props.permissao.permitecriarproduto}
                        value={"permitecriarproduto"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteeditarproduto")}
                        checked={props.permissao.permiteeditarproduto}
                        value={"permiteeditarproduto"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteverproduto")}
                        checked={props.permissao.permiteverproduto}
                        value={"permiteverproduto"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel7}
              onChange={() => handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Reserva</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Criar Aéreo</TableCell>
                    <TableCell align="center">Emitir Aéreo</TableCell>
                    <TableCell align="center">Cancelar Aéreo</TableCell>
                    <TableCell align="center">
                      Alterar status do Aéreo
                    </TableCell>
                    <TableCell align="center">Fluxo de autorização</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permitereservaraereo")}
                        checked={props.permissao.permitereservaraereo}
                        value={"permitereservaraereo"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteemitir")}
                        checked={props.permissao.permiteemitir}
                        value={"permiteemitir"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitecancelarreserva"
                        )}
                        checked={props.permissao.permitecancelarreserva}
                        value={"permitecancelarreserva"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitealterarstatusreserva"
                        )}
                        checked={props.permissao.permitealterarstatusreserva}
                        value={"permitealterarstatusreserva"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteautorizarnegar")}
                        checked={props.permissao.permiteautorizarnegar}
                        value={"permiteautorizarnegar"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel8}
              onChange={() => handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Pessoas e usuário
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Cadastrar usuário</TableCell>
                    <TableCell align="center">Editar usuário</TableCell>
                    <TableCell align="center">Ver usuário</TableCell>
                    <TableCell align="center">Cadastrar pessoa</TableCell>
                    <TableCell align="center">Editar pessoa</TableCell>
                    <TableCell align="center">Ver pessoa</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permitecriarusuario")}
                        checked={props.permissao.permitecriarusuario}
                        value={"permitecriarusuario"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteeditarusuario")}
                        checked={props.permissao.permiteeditarusuario}
                        value={"permiteeditarusuario"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteverusuario")}
                        checked={props.permissao.permiteverusuario}
                        value={"permiteverusuario"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permitecriarpessoa")}
                        checked={props.permissao.permitecriarpessoa}
                        value={"permitecriarpessoa"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteeditarpessoa")}
                        checked={props.permissao.permiteeditarpessoa}
                        value={"permiteeditarpessoa"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteverpessoa")}
                        checked={props.permissao.permiteverpessoa}
                        value={"permiteverpessoa"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel9}
              onChange={() => handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Relatórios</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Ver relatórios</TableCell>
                    <TableCell align="center">
                      Somente relatórios pessoais
                    </TableCell>
                    <TableCell align="center">Relatórios por setores</TableCell>
                    <TableCell align="center">
                      Inserir dados no relatório
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteverrelatorio")}
                        checked={props.permissao.permiteverrelatorio}
                        value={"permiteverrelatorio"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permiteverrelatoriopessoal"
                        )}
                        checked={props.permissao.permiteverrelatoriopessoal}
                        value={"permiteverrelatoriopessoal"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permiteverrelatoriocentrocusto"
                        )}
                        checked={props.permissao.permiteverrelatoriocentrocusto}
                        value={"permiteverrelatoriocentrocusto"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permiteinserirdadosrelatorio"
                        )}
                        checked={props.permissao.permiteinserirdadosrelatorio}
                        value={"permiteinserirdadosrelatorio"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded.panel10}
              onChange={() => handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Solicitação</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Criar solicitação</TableCell>
                    <TableCell align="center">
                      Alterar status solicitação
                    </TableCell>
                    <TableCell align="center">
                      Inserir produto solicitação
                    </TableCell>
                    <TableCell align="center">
                      Permitir ver solicitação
                    </TableCell>
                    <TableCell align="center">
                      Alterar status financeiro
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitecriarsolicitacao"
                        )}
                        checked={props.permissao.permitecriarsolicitacao}
                        value={"permitecriarsolicitacao"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitealterarstatussolicitacao"
                        )}
                        checked={
                          props.permissao.permitealterarstatussolicitacao
                        }
                        value={"permitealterarstatussolicitacao"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox(
                          "permitevincularprodutosolicitacao"
                        )}
                        checked={
                          props.permissao.permitevincularprodutosolicitacao
                        }
                        value={"permitevincularprodutosolicitacao"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permiteversolicitacao")}
                        checked={props.permissao.permiteversolicitacao}
                        value={"permiteversolicitacao"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <UCheckbox
                        onChange={handleChangeCheckbox("permitealterarfinanceiro")}
                        checked={props.permissao.permitealterarfinanceiro}
                        value={"permitealterarfinanceiro"}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <>
            <List className={classes.list} subheader={<li />}>
              {[0].map((sectionId) => (
                <li
                  key={`section-${sectionId}`}
                  className={classes.listSection}
                >
                  <List className={classes.ul}>
                    {itens.map((item) => (
                      <div>
                        <Accordion
                          square
                          expanded={item.panelVaue}
                          onChange={() => handleChange(item.painel)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>
                              {item.titulo}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TableBody>
                              <TableRow>
                                <Paper className={classes.paperList} style={{ width: "290px" }}>
                                  {item.itens.map((subItem) => (
                                    <ListItem className={classes.listItem} style={{ width: "100%" }}>
                                      <Typography>{subItem.titulo}</Typography>
                                      {subItem.titulo == "Tipo de usuário" ? (
                                        <Grid style={{ width: "100%" }}>
                                          <USelect
                                            {...{
                                              itens: permissaoUsuario,
                                              value: usuarioPermissao,
                                              setValue: setUsuarioPermissao,
                                              label: "Permissao",
                                            }}
                                            disabled={false}
                                            name="permissaodespesaporviagem"
                                            onChange={handleChangePermissao}
                                          />
                                        </Grid>
                                      ) : (
                                        <UCheckbox
                                          onChange={handleChangeCheckbox(
                                            subItem.value
                                          )}
                                          checked={subItem.checked}
                                          value={subItem.value}
                                        />
                                      )}
                                      {/* <ListItemText primary={subItem.titulo} />
                                                            <ListItemText primary={<UCheckbox onChange={handleChangeCheckbox(subItem.value)} checked={subItem.checked} value={subItem.value} />} /> */}
                                    </ListItem>
                                  ))}
                                </Paper>
                              </TableRow>
                            </TableBody>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    ))}
                  </List>
                </li>
              ))}
            </List>
          </>
        )}
      </Paper>
    </div>
  );
};

export default CadastroConfiguracaoPermissaoUsuario;
