import { CardContent, CardMedia, Container, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Button, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { CreditCardOutlined, EcoOutlined, EditOutlined, InsertDriveFileOutlined, MobileFriendlyOutlined, MobileOffOutlined, WhatsApp } from '@material-ui/icons';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import CustomizedSnackbars from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import Enums from '../../../../enums';
import EnumCompany from '../../../../enums/enumFlightCompany';
import api01 from '../../../../images/api/01.svg';
import api02 from '../../../../images/api/02.svg';
import aereoRepositorio from '../../../../repositorios/aereo';
import reservaRepositorio from '../../../../repositorios/reserva';
import whatappRepositorio from '../../../../repositorios/whatsapp';
import AddBilhetePDF from '../AddBilhetePDF';
import AlterarCentroCusto from '../AlterarCentroCusto';
import AlterarValorPassageiro from '../AlterarValorPassageiro';
import CartaoFaturamento from '../CartaoFaturamento';
import { BilheteDesk } from './bilheteDesk';
import { BilheteMob } from './bilheteMob';
import { useStyles } from './styles';

export const BilheteAereo = props => {
    const classes = useStyles();
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [aereo, setAereo] = useState({});
    const [passageiro, setPassageiro] = useState({});
    const cookies = new Cookies();
    const [backDrop, setBackDrop] = useState(false);
    const [modalAlterarCentroCusto, setModalAlterarCentroCusto] = useState(false);
    const [editValorPassageiro, setEditValorPassageiro] = useState(false);
    const [typeEditPassageiro, setTypeEditPassageiro] = useState('default');
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    const [openAddBilhetePDF, setOpenAddBilhetePDF] = useState(false);
    const [statusBilheteView, setStatusBilheteView] = useState(false);
    const [openCartaoFaturamento, setOpenCartaoFaturamento] = useState(false);
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');
    const [valuesStatusEnviado, setValuesStatusEnviado] = useState(null);
    const [valueIconMessage, setValueIconMessage] = useState(0);
    const [tooltipMessage, setTooltipMessage] = useState('');

    const handleClickOpenAddBilhetePDF = () => {
        setOpenAddBilhetePDF(true);
    };

    const handleClickOpenCartaoFaturamento = (item) => {
        // switch (item) {
        //     case 7:
        //         setStatusBilheteView(true)
        //         break;
        //     case 12:
        //         setStatusBilheteView(true)
        //         break;
        //     case 10:
        //         setStatusBilheteView(true)
        //         break;
        // }
        setStatusBilheteView(false)
        setOpenCartaoFaturamento(true);
    };

    const getTotalPassageiros = (passageiros) => {
        if (passageiros) {
            let soma = 0;
            passageiros.map(item => {
                soma += item.total;
            });
            return String(soma.toFixed(2));
        }
    }

    const handleClickEditCentroCusto = (item) => {
        setPassageiro(item);
        setModalAlterarCentroCusto(true);
    }

    const handleClickEditValorPassageiro = (item, type) => {
        setPassageiro(item);
        setTypeEditPassageiro(type)
        setEditValorPassageiro(true);
    }

    const getStatusReserva = (idstatus) => {
        let listaStatus = [7, 8, 9, 10, 5, 12];
        let status = {
            7: 'Emitido',
            8: 'Tarifa Garantida',
            9: 'Tarifa Garantida',
            10: 'Expirado',
            5: 'Encerado',
            12: 'Cancelado',
            11: 'Emissão Negada'
        }
        let temStatus = listaStatus.find(item => item === idstatus);
        if (temStatus) {
            return status[idstatus]
        }
        return 'Pendente'
    }

    const getFaixaEtaria = {
        'ADT': 'Adulto',
        'CHD': 'Criança',
        'INF': 'Bebê'
    }

    const getGenero = {
        1: 'Masculino',
        2: 'Feminino'
    }

    const getDataFormatada = (data) => {
        let dataFormatada = new Date(data).toLocaleDateString().substr(0, 10);
        let horaFormatada = new Date(data).toLocaleTimeString().substr(0, 5);
        return `${dataFormatada} às ${horaFormatada}`;
    }

    const loadAereo = async () => {
        try {
            props.setBackDrop(true)
            let response = await aereoRepositorio.get(props.reserva.idaereo);
            setAereo(response.data);
        } catch {

        } finally {
            props.setBackDrop(false)
        }
    }

    const handleClickSendBilheteByViajante = async (value, issolicitante) => {
        props.setBackDrop(true)
        let dto = {
            idreserva: props.reserva.idreserva,
            idpessoa: value,
            solicitante: issolicitante,
        }
        try {
            let response = await whatappRepositorio.postBilheteByPessoa(dto);
            if (response.status === 204) {
                if (issolicitante) {
                    var solicitante = [{ idpessoa: value, bilheteenviado: 1 }];
                    setValuesStatusEnviado({
                        ...valuesStatusEnviado,
                        solicitante: solicitante
                    });
                } else {
                    let passageiros = valuesStatusEnviado.passageiros.filter(p => p.idpessoa !== value);
                    passageiros.push({ idpessoa: value, bilheteenviado: 1 });
                    setValuesStatusEnviado({
                        ...valuesStatusEnviado,
                        passageiros: passageiros
                    });
                }
                setValueIconMessage(1)
                setSnackVariant('warning');
                setSnackMessage('Número inválido.');
                setSnackState({
                    visible: true
                });
                return;
            }
            if (response.status === 200) {
                setSnackVariant('success');
                setSnackMessage('Enviado com sucesso.');
                setSnackState({
                    visible: true
                });
                loadStatusEnviado()
            }
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao tentar enviar bilhete.');
            setSnackState({
                visible: true
            });
        } finally {
            props.setBackDrop(false)
        }
    }

    const showIconByValue = (value, idStatus, issolicitante) => {
        if (valuesStatusEnviado !== null) {
            let qualquercoisaai = null;
            if (issolicitante)
                qualquercoisaai = valuesStatusEnviado.solicitante.find(i => i.idpessoa === value);
            else
                qualquercoisaai = valuesStatusEnviado.passageiros.find(i => i.idpessoa === value);

            switch (qualquercoisaai.bilheteenviado) {
                case 0:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Clique para enviar o bilhete.</Typography>)}>
                            <IconButton onClick={() => handleClickSendBilheteByViajante(value, issolicitante)}>
                                <WhatsApp style={{ color: '#34af23' }} />
                            </IconButton>
                        </Tooltip>
                    )
                case 1:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Favor cadastrar/conferir o número do(a) pessoa desejada.</Typography>)}>
                            <IconButton disabled>
                                <MobileOffOutlined style={{ color: '#030303' }} />
                            </IconButton>
                        </Tooltip>
                    )
                case 2:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Já foi enviado o bilhete.</Typography>)}>
                            <IconButton onClick={() => handleClickSendBilheteByViajante(value, issolicitante)}>
                                <MobileFriendlyOutlined />
                            </IconButton>
                        </Tooltip>
                    )
                case 3:
                    return (
                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>Viajante está configurado para não receber envio automático.</Typography>)}>
                            <IconButton onClick={() => handleClickSendBilheteByViajante(value, issolicitante)}>
                                <WhatsApp style={{ color: '#c62828' }} />
                            </IconButton>
                        </Tooltip >
                    )
                default:
                    return (null)
                    break;
            }
        }
    }

    const loadStatusEnviado = async () => {
        try {
            props.setBackDrop(true)
            let response = await reservaRepositorio.getBilheteEnviadoByReserva(props.reserva.idreserva);
            setValuesStatusEnviado(response);
        } catch {

        } finally {
            props.setBackDrop(false)
        }
    }

    function getLogoAPI(id) {
        switch (id) {
            case 1:
                return (
                    <object data={api01} width={80} height={80} type="image/svg+xml" />
                )
            case 2:
                return (
                    <object data={api02} width={80} height={80} type="image/svg+xml" />
                )
            default:
                return null;
        }
    }

    useLayoutEffect(() => {
        if (props.reserva) {
            loadStatusEnviado();
            loadAereo();
        }
    }, [])

    return (
        <div className={classes.bilhete}>
            <ULoaderVoo isLoading={backDrop} />
            <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
            {openAddBilhetePDF ? (
                <AddBilhetePDF
                    open={openAddBilhetePDF}
                    setOpenAddBilhetePDF={setOpenAddBilhetePDF}
                    data={aereo}
                />
            ) : null}
            {openCartaoFaturamento ? (
                <CartaoFaturamento
                    open={openCartaoFaturamento}
                    statusBilheteView={statusBilheteView}
                    setOpenCartaoFaturamento={setOpenCartaoFaturamento}
                    idreserva={props.reserva.idreserva}
                />
            ) : null}
            <Container style={fullscreen ? { paddingLeft: "0px", paddingRight: "0px" } : (null)}>
                <AlterarCentroCusto
                    loadAereo={loadAereo}
                    open={modalAlterarCentroCusto}
                    setOpen={setModalAlterarCentroCusto}
                    passageiro={passageiro}
                    setPassageiro={setPassageiro}
                />
                {editValorPassageiro ? (
                    <AlterarValorPassageiro
                        open={editValorPassageiro}
                        {...{ typeEditPassageiro, passageiro, setPassageiro, loadAereo }}
                        setOpen={setEditValorPassageiro}
                    />
                ) : null}
                {
                    fullscreen ? (
                        <BilheteMob
                            setBackDrop={props.setBackDrop}
                            reserva={props.reserva}
                            aereo={aereo}
                            {...{
                                getLogoAPI,
                                permissaoDashboard,
                                getStatusReserva,
                                getDataFormatada,
                                showIconByValue,
                                handleClickOpenCartaoFaturamento,
                                handleClickOpenAddBilhetePDF,
                                getFaixaEtaria,
                                getGenero,
                                handleClickEditCentroCusto,
                                showIconByValue,
                                handleClickEditValorPassageiro
                            }} />
                    ) : (
                        <BilheteDesk
                            setBackDrop={props.setBackDrop}
                            reserva={props.reserva}
                            aereo={aereo}
                            {...{
                                handleClickEditValorPassageiro,
                                getLogoAPI,
                                permissaoDashboard,
                                getStatusReserva,
                                getDataFormatada,
                                showIconByValue,
                                handleClickOpenCartaoFaturamento,
                                handleClickOpenAddBilhetePDF,
                                getFaixaEtaria,
                                getGenero,
                                handleClickEditCentroCusto,
                                showIconByValue
                            }} />
                    )
                }
            </Container >
        </div >
    )
};

export default BilheteAereo;
