import { Avatar, Button, Tooltip, Typography } from '@material-ui/core';
import { XGrid } from '@material-ui/x-grid';
import React from 'react';
import CustomNoRowsOverlay from '../../../../configuracao/email/lista/emptyList';

function TempoDecisaoGrid(props) {
    const getCor = value => {
        return value < 0 ? 'red' : 'green';
    }
    const definirAssunto = (assunto) => {
        if (!assunto) {
            return '';
        }

        const textos = assunto.split('<br />');
        return (
            <div style={{ display: 'flex' }}>
                {textos.map(item => (
                    <Typography style={{ marginRight: '30px' }} variant="subtitle2">{item}</Typography>
                ))}
            </div>
        );
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 130 },
        {
            field: 'assunto', headerName: 'Descrição', width: 130, height: 75,
            renderCell: (item) => (
                definirAssunto(item.value)
            )
        },
        { field: 'dataCriacao_antigo', headerName: 'Data Inicial', width: 150 },
        {
            field: 'valor_antigo', headerName: 'Valor inicial',
            valueFormatter: p => p?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        { field: 'dataCriacao', headerName: 'Data Final', width: 150 },
        {
            field: 'valor_atual', headerName: 'Valor Emitido',
            valueFormatter: p => p?.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            field: 'diferenca', headerName: 'Diferença', width: 240,
            renderCell: (item) => (
                <Typography style={{ color: getCor(item.value.total) }}>{`R$ ${Number(item.value.total).toFormatDefault()} (${Number(item.value.porcentagem).toFormatDefault()}%)`}</Typography>
            )
        },
        {
            field: 'solicitante', headerName: 'Solicitante', width: 130,
            renderCell: (item) => (
                <Tooltip title={item.value.nomeUsuario}>
                    <Avatar width={80} height="auto" src={item.value.urlImagem} />
                </Tooltip>
            )
        },
    ];



    return (
        <>
            <div style={{ height: '52vh', width: '100%' }}>
                <XGrid
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    id={Math.random()}
                    rows={props.data}
                    columns={columns}
                    loading={props.isLoading}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    hideFooterRowCount
                    pagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            </div>
        </>
    );
};

export default TempoDecisaoGrid;
