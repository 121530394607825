import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { SaveOutlined, SearchOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import Cookie from "universal-cookie";
import LinesTextField from "../../../../components/LinesTextField";
import Snackbar from "../../../../components/snackbar";
import ULoaderVoo from "../../../../components/uloadervoo";
import UMaskTextfield from "../../../../components/umasktextfield";
import USearchField from "../../../../components/usearchfield";
import USearchFieldAsync from "../../../../components/uSearchFieldAsync";
import UYesNoDialog from "../../../../components/uyesnodialog";
import api01 from "../../../../images/api/01.svg";
import api02 from "../../../../images/api/02.svg";
import Faturado from "../../../../images/faturado.svg";
import aereoRepositorio from "../../../../repositorios/aereo";
import aereoBilhete from "../../../../repositorios/aereoBilhete";
import { useStyles } from "./styles";

export const ViewEmissao = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const cookies = new Cookie();
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [tipoPagamento, setTipoPagamento] = useState(3);
  const [value, setValue] = useState(null);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openYesNoCartao, setOpenYesNoCartao] = useState(false);
  const [openYesNoCartaoSalvo, setOpenYesNoCartaoSalvo] = useState(false);
  const [openYesNoFaturado, setOpenYesNoFaturado] = useState(false);
  const [itens, setItens] = useState([]);
  const [variantSnack, setVariantSnack] = useState("success");
  const [mensagemSnack, setMensagemSnack] = useState("");
  const [selecionado, setSelecionado] = useState(null);
  const [selecionadoOpcoes, setSelecionadoOpcoes] = useState(null);
  const [itensLista, setItensLista] = useState(null);
  const [itensListaOpcoes, setItensListaOpcoes] = useState(null);
  const [showOpcoes, setShowOpcoes] = useState(false);
  const [snackbarSucess, setSnackbarSucess] = useState({
    visible: false,
  });

  const [state, setState] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    cpf: "",
  });

  const bandeirasCartao = [
    {
      title: "AMEX",
      regex: /^3[47][0-9]{13}$/,
      result: "AX",
    },
    {
      title: "DINERS",
      regex: /'(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
      result: "DC",
    },
    {
      title: "DISCOVER",
      regex: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      result: "EL",
    },
    {
      title: "ELO",
      regex: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
      result: "EL",
    },
    {
      title: "HIPERCARD",
      regex: /^606282|^3841(?:[0|4|6]{1})0/,
      result: "HC",
    },
    {
      title: "MASTERCARD",
      regex: /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
      result: "CA",
    },
    {
      title: "VISA",
      regex: /^4[0-9]{15}$/,
      result: "VI",
    },
  ];

  const converteDataCartao = (dataCartao = "00/00") => {
    const mes = Number(dataCartao.substr(0, 2));
    const ano = Number("20" + dataCartao.substr(3, 2));
    let dataFormatada = new Date(ano, mes, 0);
    return dataFormatada;
  };

  const validarCartao = (nCartao) => {
    let sigla = bandeirasCartao.find((item) => item.regex.test(nCartao)) || {};

    return sigla || "";
  };

  function getLogoAPI(id) {
    switch (id) {
      case 1:
        return (
          <object data={api01} width={80} height={80} type="image/svg+xml" />
        );
      case 2:
        return (
          <object data={api02} width={80} height={80} type="image/svg+xml" />
        );
      default:
        return null;
    }
  }

  const emitirFaturado = async () => {
    let dtoDados = {
      companhiaaerea: props.aereo.companhiaaerea,
      localizador: props.aereo.localizador,
      idreserva: props.reserva.idreserva,
      sessionId: cookies.get("sessionId"),
      tipoPagamento: 1,
      valor: props.aereo.total,
    };
    try {
      props.setBackDrop(true);
      const response = await aereoRepositorio.emitir(dtoDados);
      if (response.data.status === "SUCCESS") {
        await props.loadReserva();
        props.setVariant("success");
        props.setMessage("Aéreo emitido com sucesso!");
        props.setSnackbar({ visible: true });
        props.setModalEmissao(false);
      } else {
        props.setBackDrop(false);
        setVariantSnack("error");
        setMensagemSnack(
          `Ocorreu o seguinte erro ao emitir: ${response.data.value.error.message} `
        );
        setSnackbarSucess({ visible: true });
      }
    } catch (e) {
      props.setBackDrop(false);
      setMensagemSnack("Não foi possivel Emitir!");
      setVariantSnack("error");
      setSnackbarSucess({ visible: true });
    } finally {
      props.setBackDrop(false);
    }
  };

  const emitirCartao = async () => {
    let cartaoValido = validarCartao(state.number).result;
    if (!cartaoValido) {
      setVariantSnack("error");
      setMensagemSnack("Cartão inválido!");
      setSnackbarSucess({ visible: true });
      return;
    }
    let dataValida = converteDataCartao(state.expiry);

    let dtoDadosCartao = {
      idreserva: props.reserva.idreserva,
      sessionId: cookies.get("sessionId"),
      localizador: props.aereo.localizador,
      companhiaaerea: props.aereo.companhiaaerea,
      valor: props.aereo.total,
      emissorCartao: cartaoValido,
      cartaoNome: state.name,
      cartaoNumero: state.number,
      dataValidade: dataValida,
      codigo: state.cvc,
      nParcelas: value.value,
      tipoPagamento: 2,
      idparcela: value.idparcela,
      cpf: state.cpf,
    };

    try {
      props.setBackDrop(true);
      const response = await aereoRepositorio.emitir(dtoDadosCartao);
      if (response.data.status === "SUCCESS") {
        await props.loadReserva();
        props.setVariant("success");
        props.setMessage("Aéreo emitido com sucesso!");
        props.setSnackbar({ visible: true });
        props.setModalEmissao(false);
      } else {
        props.setBackDrop(false);
        setVariantSnack("error");
        setMensagemSnack(
          `Ocorreu o seguinte erro ao emitir: ${response.data.value.error.message} `
        );
        setSnackbarSucess({ visible: true });
      }
    } catch (e) {
      props.setBackDrop(false);
      setMensagemSnack("Não foi possivel Emitir!");
      setVariantSnack("error");
      setSnackbarSucess({ visible: true });
    } finally {
      props.setBackDrop(false);
    }
  };

  const emitirCartaoSalvo = async () => {
    let dto = {
      idcartao: selecionado?.value,
      idreserva: props.reserva.idreserva,
      descParcela: selecionadoOpcoes?.label,
      nParcelas: selecionadoOpcoes?.value,
      idparcela: selecionadoOpcoes?.idparcela,
    };
    // return; 

    let dtoDados = {
      // companhiaaerea: props.aereo.companhiaaerea,
      // localizador: props.aereo.localizador,
      // idreserva: props.reserva.idreserva,
      // sessionId: cookies.get("sessionId"),
      // tipoPagamento: 3,
      // valor: props.aereo.total,
    };

    return;

    if (dto.idparcela === undefined) {
      props.setVariant("error");
      props.setMessage("Opção de parcelas não pode ser vazio.");
      props.setSnackbar({
        visible: true,
      });
      props.setBackDrop(false)
      return
    }

    try {
      const response = await aereoRepositorio.emitir(dtoDados);
      props.setBackDrop(true)
      if (response.data.status === "SUCCESS") {
        await aereoBilhete.putSaveReserveCard(dto);
        await props.loadReserva();
        props.setVariant("success");
        props.setMessage("Reserva emitida");
        props.setSnackbar({
          visible: true,
        });
      }
      props.setModalEmissao(false)
    } catch (e) {
      setVariantSnack("error");
      setMensagemSnack("Erro ao emitir");
      setSnackbarSucess({
        visible: true,
      });
    } finally {
      props.setBackDrop(false);
    }
  }

  const buscarParcelas = async () => {
    let validade = converteDataCartao(state.expiry);
    let cartaoValido = validarCartao(state.number).result;

    if (!cartaoValido) {
      setVariantSnack("error");
      setMensagemSnack("Cartão não reconhecido!");
      setSnackbarSucess({ visible: true });
      setItens([]);
      setValue(null);
      return;
    }
    if (!state.name) {
      setVariantSnack("error");
      setMensagemSnack("Preencha o nome do titular!");
      setSnackbarSucess({ visible: true });
      setItens([]);
      setValue(null);
      return;
    }
    if (!state.number) {
      setVariantSnack("error");
      setMensagemSnack("Preencha o numero do cartão!");
      setSnackbarSucess({ visible: true });
      setItens([]);
      setValue(null);
      return;
    }
    if (!state.expiry) {
      setVariantSnack("error");
      setMensagemSnack(" Preencha a validade!");
      setSnackbarSucess({ visible: true });
      setItens([]);
      setValue(null);
      return;
    }
    if (!state.cvc) {
      setVariantSnack("error");
      setMensagemSnack("Preencha o código!");
      setSnackbarSucess({ visible: true });
      setItens([]);
      setValue(null);
      return;
    }

    setItens([]);
    setValue(null);
    setLoading(true);

    let dtoDados = {
      idreserva: props.reserva.idreserva,
      companhiaaerea: props.aereo.companhiaaerea,
      localizador: props.aereo.localizador,
      cartaoNumero: state.number,
      emissorCartao: cartaoValido,
      dataValidade: validade,
      cartaoNome: state.name,
      codigo: state.cvc,
    };

    const response = await aereoRepositorio.recuperarFinanciamentos(dtoDados);

    if (response.data) {
      let listaItens = response.data.installmentOptions[0].options.map(
        (item) => {
          if (item.quantity === 1) {
            return {
              value: item.quantity,
              label: `1x - 1 de R$${item.installment}`,
              idparcela: item.idparcela,
            };
          }
          return {
            value: item.quantity,
            label: `${item.quantity}x - 1 de R$${item.firstInstallment} e ${item.quantity - 1
              }x de R$${item.installment}`,
            idparcela: item.idparcela,
          };
        }
      );
      setItens(listaItens);
    }

    setChanged(false);
    setLoading(false);
    return "";
  };

  const handleClick = (tipo) => {
    setTipoPagamento(tipo);
  };

  const handleChange = (event) => {
    if (event.target.name === "name") {
      if (!isNaN(event.target.value) && event.target.value !== "") {
        return;
      }
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setValue(null);
    setChanged(true);
  };

  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.name });
  };

  const handleClickSearchParcelas = async () => {
    let dto = null;
    props.setBackDrop(true);
    try {
      dto = {
        idreserva: props.reserva.idreserva,
        idcartao: selecionado.value,
      };
      let response = await aereoBilhete.postSelectedCreditCard(dto);
      setItensListaOpcoes(response.data.itens);
    } catch (e) {
    } finally {
      props.setBackDrop(false);
    }
    setShowOpcoes(true);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      let response = await aereoBilhete.getCreditCards();
      setItensLista(response.data.itens);
      let response2 = await aereoBilhete.getReserveCard(
        props.reserva.idreserva
      );
      setSelecionado(response2.data.cardSelecionado);
      if (response2.data.parcelSelecionado.value >= 0) {
        setShowOpcoes(true);
        setSelecionadoOpcoes(response2.data.parcelSelecionado);
      }
      setLoading(false);
    } catch (e) {
    } finally {
    }
  };

  const handleClickSave = async () => {

    if (selecionado === null) {
      setVariantSnack("error");
      setMensagemSnack("Opções de cartão não pode ser vazio.");
      setSnackbarSucess({ visible: true });
      return;
    }

    if (selecionado.value > 0 && selecionadoOpcoes?.value === null) {
      setVariantSnack("error");
      setMensagemSnack("Opções de parcelamento não pode ser vazio.");
      setSnackbarSucess({ visible: true });
      return;
    }

    setOpenYesNoCartaoSalvo(true);

  };

  useEffect(() => {
    setItensLista([]);
    setItensListaOpcoes([]);
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <div style={{ width: "100%", margin: "0px" }}>
      <Snackbar
        {...{
          variant: variantSnack,
          message: mensagemSnack,
          visible: snackbarSucess.visible,
          setSnackbar: setSnackbarSucess,
          autoHideDuration: 5000,
        }}
      />
      <UYesNoDialog
        yesEvent={emitirCartao}
        message="Deseja realmente efetuar a emissão?"
        setOpen={setOpenYesNoCartao}
        open={openYesNoCartao}
      />
      <UYesNoDialog
        yesEvent={emitirCartaoSalvo}
        message="Deseja realmente efetuar a emissão?"
        setOpen={setOpenYesNoCartaoSalvo}
        open={openYesNoCartaoSalvo}
      />
      <UYesNoDialog
        yesEvent={emitirFaturado}
        message="Deseja realmente efetuar a emissão?"
        setOpen={setOpenYesNoFaturado}
        open={openYesNoFaturado}
      />
      <Grid container xs={12} style={{ marginTop: "10px", height: "100%" }}>
        <Grid item xs={12}>
          <Grid container item xs={12} style={{ padding: theme.spacing(2) }}>
            <Grid item xs={fullscreen ? 12 : 10}>
              <ButtonGroup
                orientation={fullscreen ? "vertical" : "horizontal"}
                style={{ width: "100%" }}
              >
                <Button
                  onClick={() => handleClick(1)}
                  style={{
                    color: tipoPagamento === 1 ? "white" : "#c62828",
                    background:
                      tipoPagamento === 1 ? "#c62828" : "transparent",
                    borderColor: "#c62828",
                  }}
                >
                  Faturado
                </Button>
                <Button
                  onClick={() => handleClick(2)}
                  style={{
                    color: tipoPagamento === 2 ? "white" : "#c62828",
                    background:
                      tipoPagamento === 2 ? "#c62828" : "transparent",
                    borderColor: "#c62828",
                  }}
                >
                  Cartão de Crédito
                </Button>
                <Button
                  onClick={() => handleClick(3)}
                  style={{
                    color: tipoPagamento === 3 ? "white" : "#c62828",
                    background:
                      tipoPagamento === 3 ? "#c62828" : "transparent",
                    borderColor: "#c62828",
                  }}
                >
                  Método de pagamento
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid
              item
              xs={fullscreen ? 12 : 2}
              style={fullscreen ? {
                display: "center",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 0,
              } : {
                display: "center",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 0,
                marginBottom: "-17%"
              }}
            >
              {getLogoAPI(props.reserva.idapi)}
            </Grid>
          </Grid>
          {tipoPagamento === 1 ? (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{
                padding: fullscreen ? 0 : theme.spacing(3),
                margin: 0,
                maxHeight: "350px"
              }}
            >
              <Grid
                container
                item
                spacing={2}
                style={{
                  padding: fullscreen ? 0 : theme.spacing(2),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                xs={fullscreen ? 12 : 6}
              >
                <img src={Faturado} width={fullscreen ? 125 : 290}></img>
              </Grid>
              <Grid
                container
                item
                xs={fullscreen ? 12 : 6}
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <Grid
                  container
                  item
                  xs={fullscreen ? 12 : 8}
                  spacing={2}
                  style={{ margin: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      <b>Pagamento</b>
                    </Typography>
                    <Typography>
                      <b>Quantidade</b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Faturado</Typography>
                    <Typography>1</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>
                      <b>Total</b>
                    </Typography>
                    <Typography>
                      <b>
                        {"R$" + props.aereo.total.toFormatDefault() + " "}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: theme.spacing(1),
                    }}
                  >
                    <Button
                      onClick={() => setOpenYesNoFaturado(true)}
                      className={classes.buttonEmissao}
                    >
                      Emitir{" "}
                      {"R$" + props.aereo.total.toFormatDefault() + " "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {tipoPagamento === 2 ? (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{
                padding: fullscreen ? 0 : -80,
                margin: 0,
                maxHeight: "350px"
              }}
            >
              <Grid
                container
                item
                spacing={2}
                style={{
                  padding: fullscreen ? 0 : theme.spacing(1),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                  minHeight: "120px"
                }}
                xs={fullscreen ? 12 : 6}
              >
                <Cards
                  cvc={state.cvc}
                  expiry={state.expiry}
                  focused={state.focus}
                  name={state.name}
                  number={state.number}
                />
              </Grid>
              <Grid
                container
                item
                xs={fullscreen ? 12 : 6}
                spacing={0}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                  minHeight: "100px"
                }}
              >
                <Grid
                  container
                  item
                  xs={fullscreen ? 12 : 8}
                  // spacing={1}
                  style={{ margin: 0 }}
                >
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      <b>Dados para pagamento</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LinesTextField
                      name="name"
                      variant="outlined"
                      margin="dense"
                      onFocus={handleInputFocus}
                      onChange={handleChange}
                      value={state.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%" }}
                      label="Nome do titular no cartão"
                    />
                  </Grid>
                  {props.reserva.idpai === 1 ? null : (
                    <Grid item xs={12}>
                      <UMaskTextfield
                        mask="999.999.999-99"
                        maskChar=""
                        name="cpf"
                        label="CPF"
                        value={state.cpf || ""}
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <UMaskTextfield
                      mask="9999999999999999"
                      maskChar={null}
                      name="number"
                      label="Nº do cartão"
                      value={state.number}
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                    />
                  </Grid>
                  <Grid item xs={fullscreen ? 12 : 6}>
                    <UMaskTextfield
                      mask="99/99"
                      maskChar=""
                      formatChars={{
                        9: "[0-9]",
                        "/": "",
                      }}
                      name="expiry"
                      label="Validade"
                      value={state.expiry}
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                    />
                  </Grid>
                  <Grid item xs={fullscreen ? 12 : 6}>
                    <UMaskTextfield
                      mask="999?"
                      formatChars={{
                        9: "[0-9]",
                        "?": "[0-9]",
                      }}
                      maskChar=""
                      name="cvc"
                      label="Código"
                      value={state.cvc}
                      onFocus={handleInputFocus}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <USearchFieldAsync
                      itens={itens}
                      value={value}
                      request={buscarParcelas}
                      loading={loading}
                      changed={changed}
                      setValue={setValue}
                      label="Parcelas"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: theme.spacing(1),
                    }}
                  >
                    <Button
                      onClick={() => setOpenYesNoCartao(true)}
                      className={classes.buttonEmissao}
                    >
                      Emitir{" "}
                      {"R$" + props.aereo.total.toFormatDefault() + " "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {tipoPagamento === 3 ? (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              style={{
                padding: fullscreen ? 0 : theme.spacing(3),
                margin: 0,
                maxHeight: "350px"
              }}
            >
              <Grid
                container
                item
                spacing={2}
                style={{
                  padding: fullscreen ? 0 : theme.spacing(2),
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                xs={fullscreen ? 12 : 6}
              >
                <img src={Faturado} width={fullscreen ? 100 : 290}></img>
              </Grid>
              <Grid
                container
                item
                xs={fullscreen ? 12 : 6}
                spacing={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
              >
                <Grid
                  container
                  item
                  xs={fullscreen ? 12 : 8}
                  spacing={2}
                  style={{ margin: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={10}>
                      <USearchField
                        disabled={false}
                        itens={itensLista}
                        value={selecionado}
                        onChange={() => { setItensListaOpcoes([]); setSelecionadoOpcoes(null) }}
                        setValue={setSelecionado}
                        label="Opções de pagamento"
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => handleClickSearchParcelas()}>
                        <SearchOutlined />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {showOpcoes && selecionado?.value >= 0 ? (
                      <Grid item xs={12}>
                        {loading ? null : (
                          <USearchField
                            disabled={false}
                            itens={itensListaOpcoes}
                            value={selecionadoOpcoes}
                            setValue={setSelecionadoOpcoes}
                            label="Opções de parcelamento"
                          />
                        )}
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: theme.spacing(1),
                    }}
                  >
                    <Button
                      onClick={() => handleClickSave()}
                      className={classes.buttonEmissao}
                      disabled={loading}
                    >
                      Emitir
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewEmissao;
