import { Box, Grid, Grow, IconButton, Paper, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { HelpOutline, Instagram, VisibilityOutlined } from '@material-ui/icons';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import UChart from '../../../components/uchart';
import permissaoRepositorio from '../../../repositorios/permissao';
import viagemPoliticaDocRepositorio from '../../../repositorios/politicaViagemDoc/index';
import Dashboard from '../../dashboard';
import { useStyles } from './styles';

import { useHistory } from 'react-router';

export const DashboardView = props => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const [grow, setGrow] = useState(false)
    const smartphone = useMediaQuery(theme.breakpoints.down('sm'));
    const [aereo, setAereo] = useState(0);
    const [solicitacao, setSolicitacao] = useState(0);
    const [categorias, setCategorias] = useState([]);
    const [titulo, setTitulo] = useState('');

    const [series, setSeries] = useState([{
        name: 'Aéreos',
        type: 'column',
        data: [2, 1, 3]
    }])
    const [series2, setSeries2] = useState([{
        name: 'Aereos',
        type: 'pie',
        data: [{
            name: 'Aéreos',
            y: 1,
            sliced: true,
        }, {
            name: 'Solicitações',
            y: 1,
            sliced: true,
        }]
    }])

    const getMonthName = (index, tipo) => {
        switch (index) {
            case 0:
                return { label: 'Janeiro', name: tipo === 1 ? 'aereoJaneiro' : tipo === 2 ? 'solicitacaoJaneiro' : '' };
            case 1:
                return { label: 'Fevereiro', name: tipo === 1 ? 'aereoFevereiro' : tipo === 2 ? 'solicitacaoFevereiro' : '' };
            case 2:
                return { label: 'Março', name: tipo === 1 ? 'aereoMarco' : tipo === 2 ? 'solicitacaoMarco' : '' };
            case 3:
                return { label: 'Abril', name: tipo === 1 ? 'aereoAbril' : tipo === 2 ? 'solicitacaoAbril' : '' };
            case 4:
                return { label: 'Maio', name: tipo === 1 ? 'aereoMaio' : tipo === 2 ? 'solicitacaoMaio' : '' };
            case 5:
                return { label: 'Junho', name: tipo === 1 ? 'aereoJunho' : tipo === 2 ? 'solicitacaoJunho' : '' };
            case 6:
                return { label: 'Julho', name: tipo === 1 ? 'aereoJulho' : tipo === 2 ? 'solicitacaoJulho' : '' };
            case 7:
                return { label: 'Agosto', name: tipo === 1 ? 'aereoAgosto' : tipo === 2 ? 'solicitacaoAgosto' : '' };
            case 8:
                return { label: 'Setembro', name: tipo === 1 ? 'aereoSetembro' : tipo === 2 ? 'solicitacaoSetembro' : '' };
            case 9:
                return { label: 'Outubro', name: tipo === 1 ? 'aereoOutubro' : tipo === 2 ? 'solicitacaoOutubro' : '' };
            case 10:
                return { label: 'Novembro', name: tipo === 1 ? 'aereoNovembro' : tipo === 2 ? 'solicitacaoNovembro' : '' };
            case 11:
                return { label: 'Dezembro', name: tipo === 1 ? 'aereoDezembro' : tipo === 2 ? 'solicitacaoDezembro' : '' };
        }
    }

    const onClickAereosFiltrar = async () => {
        const data = new Date();
        data.getMonth();
        setTitulo('Aéreos');
        const response = await permissaoRepositorio.getDadosDashboard();
        let dadosData = [];
        let dadosCategoria = [];
        for (let i = 0; i <= data.getMonth(); i++) {
            let ae = getMonthName(i, 1);
            dadosCategoria.push(ae.label);
            dadosData.push(response.data[ae.name]);
        }
        setSeries([{
            name: 'Aéreos',
            type: 'column',
            data: dadosData
        }])
        setCategorias(dadosCategoria);
    }

    const onClickButtonPromocoes = () => {
        window.open('https://www.instagram.com/linesturturismo', '_blank');
    }

    const onClickSolicitacoesFiltrar = async () => {
        const data = new Date();
        data.getMonth();
        setTitulo('Solicitações');
        const response = await permissaoRepositorio.getDadosDashboard();
        let dadosData = [];
        let dadosCategoria = [];
        for (let i = 0; i <= data.getMonth(); i++) {
            let ae = getMonthName(i, 2);
            dadosCategoria.push(ae.label);
            dadosData.push(response.data[ae.name]);
        }
        setSeries([{
            name: 'Solicitações',
            type: 'column',
            data: dadosData
        }])
        setCategorias(dadosCategoria);
    }

    useEffect(() => {
        (async () => {
            const data = new Date();
            data.getMonth();
            setTitulo('Aéreos');
            const response = await permissaoRepositorio.getDadosDashboard();
            setAereo(response.data.aereosQuantidadeSemanal);
            setSolicitacao(response.data.solicitacoesQuantidadeSemanal);
            let dadosData = [];
            let dadosCategoria = [];
            for (let i = 0; i <= data.getMonth(); i++) {
                let ae = getMonthName(i, 1);
                dadosCategoria.push(ae.label);
                dadosData.push(response.data[ae.name]);
            }
            setSeries([{
                name: 'Aéreos',
                type: 'column',
                data: dadosData
            }])
            setCategorias(dadosCategoria);

            if (response.data.porcentagemAereo > 0 || response.data.porcentagemSolicitacao > 0) {
                setSeries2([{
                    name: 'Reservas',
                    colorByPoint: true,
                    data: [{
                        name: 'Aéreos',
                        y: response.data.porcentagemAereo,
                        sliced: true,
                    }, {
                        name: 'Solicitações',
                        y: response.data.porcentagemSolicitacao,
                        sliced: true,
                    }]
                }])
            }
            setGrow(true);
        })()
    }, [])

    useLayoutEffect(() => {
        (async () => {
            let response = await viagemPoliticaDocRepositorio.getStatusConfirmed();
            if (!response) history.push('/PoliticaViagemDoc');
        })()
    }, [aereo])

    return (
        <Grid container xs={12} className={classes.root}>
            <Grid container xs={12} spacing={3}>
                <Grow in={grow}
                    {...(grow ? { timeout: 1000 } : {})}>
                    <Grid item xs={smartphone ? 12 : 3}>
                        <Paper elevation={5}>
                            <Box className={classes.card}>
                                <div>
                                    <Typography variant="body2">Aéreos</Typography>
                                    <Typography variant="h6">{aereo}</Typography>
                                </div>
                                <Tooltip title="Visualizar Aéreos Anuais">
                                    <IconButton onClick={onClickAereosFiltrar} style={{ background: '#689f38', color: 'white' }}>
                                        <VisibilityOutlined />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Paper>
                    </Grid>
                </Grow>
                <Grow in={grow}
                    {...(grow ? { timeout: 1000 } : {})}>
                    <Grid item xs={smartphone ? 12 : 3}>
                        <Paper elevation={5} >
                            <Box className={classes.card}>
                                <div>
                                    <Typography variant="body2">Solicitações</Typography>
                                    <Typography variant="h6">{solicitacao}</Typography>
                                </div>
                                <Tooltip title="Visualizar Solicitações Anuais">
                                    <IconButton onClick={onClickSolicitacoesFiltrar} style={{ background: '#01579b', color: 'white' }}>
                                        <VisibilityOutlined />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Paper>
                    </Grid>
                </Grow>
                <Grow in={grow}
                    {...(grow ? { timeout: 1000 } : {})}>
                    <Grid item xs={smartphone ? 12 : 3}>
                        <Paper elevation={5} >
                            <Box className={classes.card}>
                                <Typography>Dicas de viagem</Typography>
                                <Tooltip title="Em Breve">
                                    <IconButton style={{ background: '#ffa000', color: 'white' }}>
                                        <HelpOutline />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Paper>
                    </Grid>
                </Grow>
                <Grow in={grow}
                    {...(grow ? { timeout: 1000 } : {})}>
                    <Grid item xs={smartphone ? 12 : 3}>
                        <Paper elevation={5} >
                            <Box className={classes.card}>
                                <Typography>Promoções</Typography>
                                <IconButton onClick={onClickButtonPromocoes} style={{ background: 'linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)', color: 'white' }} >
                                    <Instagram />
                                </IconButton>
                            </Box>
                        </Paper>
                    </Grid>
                </Grow>
                <Grow in={grow}
                    {...(grow ? { timeout: 2000 } : {})}>
                    <Grid item xs={12}>
                        <Paper elevation={5} style={{ minHeight: 500 }} >
                            <Box className={classes.card}>
                                <Grid container item xs={12}>
                                    <Grid item xs={!smartphone ? 6 : 12}>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <UChart
                                                series={series}
                                                title={titulo + ' - Anual'}
                                                titleY={'Meses'}
                                                categories={categorias}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={!smartphone ? 6 : 12}>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <UChart
                                                series={series2}
                                                title="Relação de Reservas"
                                                titleY={'Meses'}
                                                tooltip={'pie'}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                </Grow>
            </Grid>
        </Grid>
    )
};

export const DashboardPage = props => {
    return (
        <div>
            <Dashboard {...props} component={DashboardView} />
        </div>
    )
};
export default DashboardPage;
