export const EnumStatus = {

    statusCode: {
        aguardandoAtendimento: 1,
        emAtendimento: 2,
        emissaoPendente: 3,
        aguardandoSolicitante: 4,
        encerrado: 5,
        rascunho: 6,
        emitido: 7,
        reservado: 8,
        autorizacaoPendente: 9,
        expirado: 10,
        emissaoNegada: 11,
        cancelado: 12,
        limiteExcedido: 13,
        aprovacaoPendente: 14,
        default: 8,
    },

    statusCodeAprovation: {
        pendenteNaLinestur: 1,
        pendenteAutorizacao: 2,
        pendenteAprovacao: 3,
        aguardandoSolicitante: 4,
    },

    statusTextAprovation: {
        pendenteNaLinestur: {
            text: 'Pendente na Linestur',
            color: '#ff9100'
        },
        pendenteAutorizacao: {
            text: 'Pendente de Autorização',
            color: '#9e9d24'
        },
        pendenteAprovacao: {
            text: 'Pendente de Aprovação',
            color: 'deepskyblue'
        },
        aguardandoSolicitante: {
            text: 'Aguardando Solicitante',
            color: '#ff9100'
        },
    },

    statusBooking: {
        aguardandoAtendimento: {
            text: 'Aguardando Atendimento',
            color: '#bf360c'
        },
        emAtendimento: {
            text: 'Em Atendimento',
            color: '#ff9100'
        },
        emissaoPendente: {
            text: 'Emissão Pendente',
            color: '#ef6c00'
        },
        aguardandoSolicitante: {
            text: 'Aguardando Solicitante',
            color: '#3f51b5'
        },
        encerrado: {
            text: 'Encerrado',
            color: '#000000'
        },
        rascunho: {
            text: 'Rascunho',
            color: 'gray'
        },
        emitido: {
            text: 'Emitido',
            color: '#43a047'
        },
        reservado: {
            text: 'Reservado',
            color: '#1e88e5'
        },
        expirado: {
            text: 'Expirado',
            color: 'gray'
        },
        emissaoNegada: {
            text: 'Emissão Negada',
            color: '#f44336'
        },
        cancelado: {
            text: 'Cancelado',
            color: '#f44336'
        },
        limiteExcedido: {
            text: 'Limite Excedido',
            color: '#f44336'
        },
        aprovacaoPendente: {
            text: 'Aprovação Pendente',
            color: 'royalblue'
        },
    },

    getInfoAprovation: function (valueCodeAprovation) {
        let dto = {
            text: null,
            color: null,
        };
        switch (valueCodeAprovation) {
            case this.statusCodeAprovation.pendenteNaLinestur:
                return dto = {
                    text: this.statusTextAprovation.pendenteNaLinestur.text,
                    color: this.statusTextAprovation.pendenteNaLinestur.color,
                };
            case this.statusCodeAprovation.pendenteAutorizacao:
                return dto = {
                    text: this.statusTextAprovation.pendenteAutorizacao.text,
                    color: this.statusTextAprovation.pendenteAutorizacao.color,
                };
            case this.statusCodeAprovation.pendenteAprovacao:
                return dto = {
                    text: this.statusTextAprovation.pendenteAprovacao.text,
                    color: this.statusTextAprovation.pendenteAprovacao.color,
                };
            case this.statusCodeAprovation.aguardandoSolicitante:
                return dto = {
                    text: this.statusTextAprovation.aguardandoSolicitante.text,
                    color: this.statusTextAprovation.aguardandoSolicitante.color,
                };
        }
    },


    getInfoBooking: function (valueCode, valueCodeAprovation) {
        let dto = {
            text: null,
            color: null,
        };

        switch (valueCode) {
            case this.statusCode.aguardandoAtendimento:
                return dto = {
                    text: this.statusBooking.aguardandoAtendimento.text,
                    color: this.statusBooking.aguardandoAtendimento.color,
                }
            case this.statusCode.emAtendimento:
                return dto = {
                    text: this.statusBooking.emAtendimento.text,
                    color: this.statusBooking.emAtendimento.color,
                }
            case this.statusCode.emissaoPendente:
                return dto = {
                    text: this.statusBooking.emissaoPendente.text,
                    color: this.statusBooking.emissaoPendente.color,
                }
            case this.statusCode.aguardandoSolicitante:
                return dto = {
                    text: this.statusBooking.aguardandoSolicitante.text,
                    color: this.statusBooking.aguardandoSolicitante.color,
                }
            case this.statusCode.encerrado:
                return dto = {
                    text: this.statusBooking.encerrado.text,
                    color: this.statusBooking.encerrado.color,
                }
            case this.statusCode.rascunho:
                return dto = {
                    text: this.statusBooking.rascunho.text,
                    color: this.statusBooking.rascunho.color,
                }
            case this.statusCode.emitido:
                return dto = {
                    text: this.statusBooking.emitido.text,
                    color: this.statusBooking.emitido.color,
                }
            case this.statusCode.reservado:
                return dto = {
                    text: this.statusBooking.reservado.text,
                    color: this.statusBooking.reservado.color,
                }
            case this.statusCode.autorizacaoPendente:
                {
                    switch (valueCodeAprovation) {
                        case this.statusCodeAprovation.pendenteNaLinestur:
                            return dto = {
                                text: this.statusTextAprovation.pendenteNaLinestur.text,
                                color: this.statusTextAprovation.pendenteNaLinestur.color,
                            };
                        case this.statusCodeAprovation.pendenteAutorizacao:
                            return dto = {
                                text: this.statusTextAprovation.pendenteAutorizacao.text,
                                color: this.statusTextAprovation.pendenteAutorizacao.color,
                            };
                        case this.statusCodeAprovation.pendenteAprovacao:
                            return dto = {
                                text: this.statusTextAprovation.pendenteAprovacao.text,
                                color: this.statusTextAprovation.pendenteAprovacao.color,
                            };
                        case this.statusCodeAprovation.aguardandoSolicitante:
                            return dto = {
                                text: this.statusTextAprovation.aguardandoSolicitante.text,
                                color: this.statusTextAprovation.aguardandoSolicitante.color,
                            };
                    }
                }
                return dto
            case this.statusCode.expirado:
                return dto = {
                    text: this.statusBooking.expirado.text,
                    color: this.statusBooking.expirado.color,
                }
            case this.statusCode.emissaoNegada:
                return dto = {
                    text: this.statusBooking.emissaoNegada.text,
                    color: this.statusBooking.emissaoNegada.color,
                }
            case this.statusCode.cancelado:
                return dto = {
                    text: this.statusBooking.cancelado.text,
                    color: this.statusBooking.cancelado.color,
                }
            case this.statusCode.limiteExcedido:
                return dto = {
                    text: this.statusBooking.limiteExcedido.text,
                    color: this.statusBooking.limiteExcedido.color,
                }
            case this.statusCode.aprovacaoPendente:
                return dto = {
                    text: this.statusBooking.aprovacaoPendente.text,
                    color: this.statusBooking.aprovacaoPendente.color,
                }
        }
    }
}

export default EnumStatus;
