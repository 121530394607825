import {
  Box,
  CardContent,
  CardMedia,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  CreditCardOutlined,
  EcoOutlined,
  EditOutlined,
  InsertDriveFileOutlined,
} from "@material-ui/icons";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";
import CustomizedSnackbars from "../../../../../components/snackbar";
import ULoaderVoo from "../../../../../components/uloadervoo";
import Enums from "../../../../../enums";
import EnumCompany from "../../../../../enums/enumFlightCompany";
import api01 from "../../../../../images/api/01.svg";
import api02 from "../../../../../images/api/02.svg";
import aereoRepositorio from "../../../../../repositorios/aereo";
import reservaRepositorio from "../../../../../repositorios/reserva";
import whatappRepositorio from "../../../../../repositorios/whatsapp";
import AddBilhetePDF from "../../AddBilhetePDF";
import AlterarCentroCusto from "../../AlterarCentroCusto";
import AlterarValorPassageiro from "../../AlterarValorPassageiro";
import CartaoFaturamento from "../../CartaoFaturamento";
import { useStyles } from "./styles";
import logoteste from "../../../../../images/Logos/logo_linesturDigital.svg";
import CustomizedProgressBars from "../../../../../components/LinearProgress";
import EnumStatus from "../../../../../enums/enumStatusBooking";
import ReservaStatusComponent from "../../../../../components/LinearProgress/StatusVerifiqued";

export const BilheteDesk = (props) => {
  const classes = useStyles();
  const cookies = new Cookies();

  return (
    <>
      <div className={classes.dados}>
        <div>
          <CardContent>
            <CardMedia
              component="img"
              style={{ width: "230px" }}
              image={logoteste}
              onLoad={() => props.setBackDrop(false)}
            />
          </CardContent>
        </div>
        <div></div>
        {cookies.get("usession") == "thalysson" ||
          cookies.get("usession") == "levi" ? (
          <div>{props.getLogoAPI(props.reserva.idapi)}</div>
        ) : null}
        <div>
          <Typography>
            <b>Plano de Viagem</b>
          </Typography>
          <Typography>
            Agente:   Zulma A. Torassi {`(${props.reserva.idapi})`}
          </Typography>
          <Typography>E-mail:    zulma@linestur.com </Typography>
        </div>
      </div>
      <div>
        <TableContainer>
          <Table padding="checkbox">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ width: "10%" }}
                  className={classes.titulos}
                >
                  Localizador
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "10%" }}
                  className={classes.titulos}
                >
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "15%" }}
                  className={classes.titulos}
                >
                  Sistema
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "15%" }}
                  className={classes.titulos}
                >
                  Criação
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "15%" }}
                  className={classes.titulos}
                >
                  Solicitante
                </TableCell>
                <TableCell
                  align="center"
                  style={
                    props.permissaoDashboard.usuarioLinestur
                      ? { width: "15%" }
                      : { width: "15%" }
                  }
                  className={classes.titulos}
                >
                  Contatos
                </TableCell>
                {/* {props.permissaoDashboard.usuarioLinestur ? ( */}
                <TableCell
                  align="center"
                  style={{ width: "10%" }}
                  className={classes.titulos}
                >
                  Cartão
                </TableCell>
                {/* ) : null} */}
                <TableCell
                  align="center"
                  style={{ width: "10%" }}
                  className={classes.titulos}
                >
                  Anexos
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  {props.permissaoDashboard.usuarioLinestur ? (
                    <>{props.aereo.localizador}</>
                  ) : (
                    <>
                      {[3, 8, 9, 14].includes(props.reserva.status) ? (
                        "Aguardando"
                      ) : (
                        <>{props.aereo.localizador}</>
                      )}
                    </>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="caption"
                    style={{
                      width: "fit-content",
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: Enums.getReservaStatusCor(
                        props.reserva.status
                      ),
                      background: Enums.getReservaStatusCor(
                        props.reserva.status
                      ),
                      color: "white",
                      borderRadius: 3,
                      padding: 5,
                    }}
                  >
                    {props.getStatusReserva(props.reserva.status)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    title={
                      <Typography style={{ fontSize: "12pt" }}>
                        {
                          EnumCompany.getLogoCompanhiaAereaEnum(
                            props.aereo.companhiaaerea
                          ).nome
                        }
                      </Typography>
                    }
                  >
                    <img
                      src={
                        props.aereo.companhiaaerea === undefined
                          ? null
                          : EnumCompany.getLogoCompanhiaAereaEnum(
                            props.aereo.companhiaaerea
                          ).url_high
                      }
                      width={80}
                      height={80}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  {props.getDataFormatada(props.reserva.datacriacao)}
                </TableCell>
                <TableCell align="center">
                  {props.reserva.usuario.pessoa !== null ? (
                    <>
                      {props.reserva?.usuario.pessoa.nome +
                        " " +
                        props.reserva?.usuario.pessoa.sobrenome}

                      {props.reserva.status === 7 &&
                        props.permissaoDashboard.usuarioLinestur
                        ? props.showIconByValue(
                          props.reserva?.usuario.pessoa.idpessoa,
                          props.reserva?.usuario.pessoa.bilheteenviado,
                          true
                        )
                        : null}
                    </>
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2">(48) 3045-7015 ou </Typography>
                  <Typography variant="body2">(48) 99976-0516</Typography>
                </TableCell>
                {/* {props.permissaoDashboard.usuarioLinestur ? ( */}
                <TableCell align="center">
                  <IconButton
                    onClick={() =>
                      props.handleClickOpenCartaoFaturamento(
                        props.reserva.status
                      )
                    }
                    style={{ color: "#c62828" }}
                  >
                    <CreditCardOutlined />
                  </IconButton>
                </TableCell>
                {/* ) : null} */}
                <TableCell align="center">
                  <IconButton
                    onClick={() => props.handleClickOpenAddBilhetePDF()}
                    style={{ color: "#c62828" }}
                  >
                    <InsertDriveFileOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {
            <ReservaStatusComponent
              status={props.reserva.status}
              idreserva={props.reserva.idreserva}
            />
          }
          <Typography className={classes.titulo}>Passageiros</Typography>
          <Table padding="checkbox">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.titulos}>
                  Tipo
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Sobrenome
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Nome
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Sexo
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Nascimento
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Centro de Custo
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Emissão CO2 (kg)
                </TableCell>
                {props.reserva.status === 7 &&
                  props.permissaoDashboard.usuarioLinestur ? (
                  <TableCell align="center" className={classes.titulos}>
                    Envio Bilhete
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.aereo.passageiros
                ? props.aereo.passageiros.map((item) => (
                  <TableRow>
                    <TableCell align="center">
                      {props.getFaixaEtaria[item.faixaetaria]}
                    </TableCell>
                    <TableCell align="center">
                      {item.passageiro.sobrenome}
                    </TableCell>
                    <TableCell align="center">
                      {item.passageiro.nome}
                    </TableCell>
                    <TableCell align="center">
                      {props.getGenero[item.passageiro.sexo]}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(
                        item.passageiro.datanascimento
                      ).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      <nobr>
                        {`${item.centroCusto.nome} - ${item.centroCusto.codigo}`}
                        {props.permissaoDashboard.usuarioLinestur ? (
                          <IconButton
                            onClick={() =>
                              props.handleClickEditCentroCusto(item)
                            }
                            size="small"
                          >
                            <EditOutlined fontSize="small" />
                          </IconButton>
                        ) : null}
                      </nobr>
                    </TableCell>
                    <TableCell align="center" style={{ color: "#43a047" }}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <b>{item.carbono}</b>
                        <EcoOutlined style={{ color: "#43a047" }} />
                      </Box>
                    </TableCell>
                    {props.reserva.status === 7 &&
                      props.permissaoDashboard.usuarioLinestur ? (
                      <TableCell align="center">
                        <>
                          {props.showIconByValue(
                            item.idpassageiro,
                            item.bilheteenviado,
                            false
                          )}
                        </>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))
                : null}
            </TableBody>
          </Table>
          <Typography className={classes.titulo}>Voos</Typography>
          <Table padding="checkbox">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.titulos}>
                  Cia
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Voo
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Origem
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Destino
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Esc.
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Cl.
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Info
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Loc Cia
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.aereo.voos
                ? props.aereo.voos.map((item) => (
                  <TableRow>
                    <TableCell align="center">
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: "12pt" }}>
                            {
                              EnumCompany.getLogoCompanhiaAereaEnum(item.iata)
                                .nome
                            }
                          </Typography>
                        }
                      >
                        <img
                          src={
                            item.iata === undefined
                              ? null
                              : EnumCompany.getLogoCompanhiaAereaEnum(
                                item.iata
                              ).url_low
                          }
                          width={80}
                          height={80}
                          style={{
                            margin: "5px",
                            height: "12px",
                            width: "60px",
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{item.numero}</TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontSize: 12 }}>
                        {`${item.origemdescricao} (${item.origem})`}
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>
                        {`${new Date(item.datapartida)
                          .toLocaleString()
                          .slice(
                            0,
                            new Date(item.datapartida).toLocaleString()
                              .length - 3
                          )}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography style={{ fontSize: 12 }}>
                        {`${item.destinodescricao} (${item.destino})`}
                      </Typography>
                      <Typography style={{ fontSize: 12 }}>
                        {`${new Date(item.datachegada)
                          .toLocaleString()
                          .slice(
                            0,
                            new Date(item.datachegada).toLocaleString()
                              .length - 3
                          )}`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">{item.escala}</TableCell>
                    <TableCell align="center">{item.classe}</TableCell>
                    <TableCell align="center">
                      {item.incluibagagem
                        ? `Com Bagagem(${item.tipobagagem === null
                          ? "Sem nome"
                          : item.tipobagagem
                        })`
                        : `Sem Bagagem(${item.tipobagagem === null
                          ? "Sem nome"
                          : item.tipobagagem
                        })`}
                    </TableCell>
                    <TableCell align="center">
                      {props.aereo.localizador}
                    </TableCell>
                  </TableRow>
                ))
                : null}
            </TableBody>
          </Table>
          <Typography className={classes.titulo}>Valores</Typography>
          <Table padding="checkbox">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.titulos}>
                  Passageiro
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Tarifas
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Extras
                </TableCell>
                <TableCell align="center" className={classes.titulos}>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.aereo.passageiros
                ? props.aereo.passageiros.map((item) => (
                  <TableRow>
                    <TableCell align="center">
                      {item.passageiro.sobrenome + "/" + item.passageiro.nome}
                    </TableCell>
                    <TableCell align="center">
                      {"R$" + item.total.toFormatDefault() + " "}
                      {props.permissaoDashboard.usuarioLinestur &&
                        props.reserva.status === 7 ? (
                        <IconButton
                          onClick={() =>
                            props.handleClickEditValorPassageiro(
                              item,
                              "tarifa"
                            )
                          }
                          size="small"
                        >
                          <EditOutlined fontSize="small" />
                        </IconButton>
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      {"R$" + item.taxes.toFormatDefault() + " "}
                      {props.permissaoDashboard.usuarioLinestur &&
                        props.reserva.status === 7 ? (
                        <IconButton
                          onClick={() =>
                            props.handleClickEditValorPassageiro(
                              item,
                              "extras"
                            )
                          }
                          size="small"
                        >
                          <EditOutlined fontSize="small" />
                        </IconButton>
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      {"R$" + item.total_geral.toFormatDefault() + " "}
                      {/* {props.permissaoDashboard.usuarioLinestur && props.reserva.status === 7 ? (
                                                <IconButton onClick={() => handleClickEditValorPassageiro(item)} size="small">
                                                    <EditOutlined fontSize="small" />
                                                </IconButton>
                                            ) : null} */}
                    </TableCell>
                  </TableRow>
                ))
                : null}
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">
                  <b>{"R$" + props.aereo.total?.toFormatDefault() + " "}</b>
                </TableCell>
                <TableCell align="center">
                  <b>{"R$" + props.aereo.taxes?.toFormatDefault() + " "}</b>
                </TableCell>
                <TableCell align="center">
                  <b>
                    {"R$" + props.aereo.total_geral?.toFormatDefault() + " "}
                  </b>
                </TableCell>
                {/* <TableCell align="center">{`R$${Number(getTotalPassageiros(props.aereo.passageiros)).toFormatDefault()}`}</TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
          {props.aereo.bilhete ? (
            <React.Fragment>
              {props.aereo.bilhete.length > 0 ? (
                <React.Fragment>
                  <Typography className={classes.titulo}>Bilhetes</Typography>
                  <Table padding="checkbox">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className={classes.titulos}>
                          Número
                        </TableCell>
                        <TableCell align="center" className={classes.titulos}>
                          Passageiro
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.aereo.bilhete.map((item) => (
                        <TableRow>
                          <TableCell align="center">{item.nbilhete}</TableCell>
                          <TableCell align="center">{`${item.nomepassageiro} ${item.sobrenomepassageiro}`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ) : null}
        </TableContainer>
        <div className={classes.info}>
          <Typography> Informações para Embarque </Typography>
          <Typography>
            {" "}
            Apresente-se em nosso checkin com 2 horas de antecedência em voos
            nacionais e com 3 horas de antecedência em voos internacionais.{" "}
          </Typography>
          <Typography>
            {" "}
            Levar documento original: <b>Carteira de Identidade</b> para vôos
            nacionais.
          </Typography>
          <Typography>
            {" "}
            Levar documento original: <b>Passaporte</b> e os vistos necessários
            para entrada no pais de destino para voos internacionais.
          </Typography>
          <Typography>
            Informações sobre validade de PASSAPORTE, VACINAS e VISTOS que
            possam ser necessários para sua viagem devem ser consultados com as
            respectivas embaixadas ou despachantes de vistos.
          </Typography>
          <Typography>
            Verifique essa necessidade para todos os países envolvidos na
            viagem, mesmo aqueles onde há apenas uma escala.
          </Typography>
          <Typography>
            Lembre-se que alguns países exigem que o passaporte tenha uma
            validade mínima de 6 meses para embarcar.
          </Typography>
        </div>
      </div>
    </>
  );
};
