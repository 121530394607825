import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, SaveOutlined } from '@material-ui/icons';
import React from 'react';
import pacoteAgenteRepositorio from '../../../repositorios/pacoteAgente';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogConfirm(props) {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCloseDialog = () => {
        props.handleClickOpenDialogConfirm()
    }

    const handleClickSave = async () => {
        try {
            props.setIsLoading(true)
            let response = null
            if (props.type === "Finalizar") {
                response = await pacoteAgenteRepositorio.putFinalizarPacote(props.idpacote);
            } else if (props.type === "Cancelar") {
                response = await pacoteAgenteRepositorio.putCancelarPacote(props.idpacote);
            } else if (props.type === "Reenviar") {
                response = await pacoteAgenteRepositorio.putResendPacote(props.idpacote);
            }
            props.doneAction()
        } catch (e) {
            props.setSnackVariant('error');
            props.setSnackMessage(`Erro ao ${props.type}.`);
            props.setSnackVisible({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    return (
        <div>
            <Dialog
                onClose={() => handleCloseDialog()}
                open={props.openDialogConfirm}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={responsive}
                maxWidth={'xl'}
                min
            >
                <DialogTitle style={{ background: '#C62828' }}>
                    <Typography style={{ color: '#ffffff' }}>{props.type}</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <Box minWidth={'40vw'}>
                            <Typography>
                                Confirme se realmente deseja {props.type}.
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog()} className={responsive ? classes.buttonMobile : classes.button} endIcon={<CloseOutlined />}>Fechar </Button>
                    <Button onClick={() => handleClickSave()} className={responsive ? classes.buttonMobile : classes.button} endIcon={<SaveOutlined />}>Salvar </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default DialogConfirm;
