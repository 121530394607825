import { CardMembership, CreditCardOutlined, PaymentsOutlined, PixOutlined, ReceiptOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";

// Definindo o enum para formas de pagamento com números
export const EnumFormaPagamento = {
    formas: {
        credito: 1,
        debito: 2,
        transferencia: 3,
        dinheiro: 4,
        pix: 5
    }
};

const formatarFormaPagamento = (valor) => {
    //DECIDIR CORES DEPOOIS
    const mapaNomes = {
        credito: (
            <Chip
                label="Cartão de Crédito"
                style={{ backgroundColor: "#1976d2", color: "white" }}
            />
        ),
        debito: (
            <Chip
                label="Cartão de Débito"
                style={{ backgroundColor: "#00796b", color: "white" }}
            />
        ),
        transferencia: (
            <Chip
                label="Transferência Bancária"
                style={{ backgroundColor: "#c2185b", color: "white" }}
            />
        ),
        dinheiro: (
            <Chip
                label="Dinheiro"
                style={{ backgroundColor: "#ff9800", color: "white" }}
            />
        ),
        pix: (
            <Chip
                label="Pix"
                style={{ backgroundColor: "#4caf50", color: "white" }}
            />
        )
    };

    if (typeof valor === "number") {
        const chave = Object.keys(EnumFormaPagamento.formas).find(
            (key) => EnumFormaPagamento.formas[key] === valor
        );
        return chave ? mapaNomes[chave] : undefined;
    }

    // Encontrando a chave correspondente ao valor numérico

    // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
};


export const formatarFormaPagamentoIcon = (valor) => {
    //DECIDIR CORES DEPOOIS
    const mapaNomes = {
        credito: (
            <CreditCardOutlined style={{ marginRight: "8px" }} />
        ),
        debito: (
            <CardMembership style={{ marginRight: "8px" }} />
        ),
        transferencia: (
            <ReceiptOutlined style={{ marginRight: "8px" }} />
        ),
        dinheiro: (
            <PaymentsOutlined style={{ marginRight: "8px" }} />
        ),
        pix: (
            <PixOutlined style={{ marginRight: "8px" }} />
        )
    };

    if (typeof valor === "number") {
        const chave = Object.keys(EnumFormaPagamento.formas).find(
            (key) => EnumFormaPagamento.formas[key] === valor
        );
        return chave ? mapaNomes[chave] : undefined;
    }

    // Encontrando a chave correspondente ao valor numérico

    // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
};

export const formatarFormaPagamentoString = (valor) => {
    //DECIDIR CORES DEPOOIS
    const mapaNomes = {
        credito: (
            "Cartão de crédito"
        ),
        debito: (
            "Cartão de débito"
        ),
        transferencia: (
            "Transferência bancária"
        ),
        dinheiro: (
            "Dinheiro"
        ),
        pix: (
            "Pix"
        )
    };

    if (typeof valor === "number") {
        const chave = Object.keys(EnumFormaPagamento.formas).find(
            (key) => EnumFormaPagamento.formas[key] === valor
        );
        return chave ? mapaNomes[chave] : undefined;
    }

    // Encontrando a chave correspondente ao valor numérico

    // Retorna o nome formatado se encontrado, caso contrário, retorna undefined
};