import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {

    },
    button: {
        color: '#fff'
    },
    buttonConfirm: {
        background: '#c62828',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#b91119',
        }
    },
    appbar: {
        background: '#616161',
    },
    inline: {
        display: 'inline',
    },

}));
