import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(() => ({
    root: {

    },
    button: {
        color: '#ffffff',
    },
    buttonConfirm: {
        background: '#c62828',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#b91119',
        }
    },
    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },
    inline: {
        display: 'inline',
    },
    iconButton: {
        color: "white"
    },
    iconButtonRed: {
        color: '#c62828'
    },
    gridContainerCenter: {
        alignItems: 'center',
        textAlign: 'center',
    },
    buttonSave: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },

}));
