import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CancelOutlined, DeleteOutline } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import cardsRepositorio from '../../../../repositorios/cartao';
import { useStyles } from './styles';

export const DialogConfirm = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [listReservas, setListReservas] = useState([]);

    const handleCloseDialogConfirm = () => {
        props.setOpenDialogConfirm(false);
    }

    const confirmDelete = () => {
        props.deleteCard(props.selectedItem.idcartao)
    }

    const getListReservas = async () => {
        try {
            props.setIsLoading(true);
            let response = await cardsRepositorio.getListReservasCard(props.selectedItem.idcartao);
            setListReservas(response.data)
        } catch (error) {
            props.setSnackVariant("error");
            props.setSnackMessage("Falha ao consultar aéreo!");
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false);
        }
    }

    useLayoutEffect(() => {
        getListReservas();
    }, [])

    return (
        <>
            <Dialog
                open={props.openDialogConfirm}
                maxWidth="sm"
                onClose={handleCloseDialogConfirm}
                fullWidth
                fullScreen={responsive}
            >
                <DialogTitle className={classes.dialogTitle}><Typography className={classes.typoTitle}>Confirmação</Typography></DialogTitle>
                <DialogContent >
                    {props.isLoading ? (
                        <Skeleton variant='rect' height={'150px'} />
                    ) : (
                        <>
                            <Typography>Deseja realmente excluir o cartão com o final: &nbsp;{props.selectedItem.numero}? </Typography>
                            <br />
                            {listReservas.length > 0 ? (
                                <>
                                    <Typography>Lista de aéreos que possuem este cartão inserido:</Typography>
                                    <ul>
                                        {listReservas.map((item) => (
                                            <li><Typography>{item}</Typography></li>
                                        ))}
                                    </ul>
                                </>
                            ) : null}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleCloseDialogConfirm()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                    )}
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => confirmDelete()} className={classes.buttonDelete} startIcon={<DeleteOutline />}>Deletar {listReservas.length > 0 ? 'E FATURAR' : null}</Button>
                    )}
                </DialogActions>
            </Dialog >
        </>
    );
}
