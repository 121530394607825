import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';
import hotelRepositorio from '../../../../repositorios/hotel';

export const HotelChangeCC = (props) => {
    const [centroCusto, setCentroCusto] = useState(null);
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [centros, setCentros] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const handleClickSalvar = async () => {
        if (!centroCusto) {
            setMensagemSnack('Centro de custo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        let dto = {
            idcentrocusto: centroCusto.value,
            idpessoa: props.dtoChangeCC.idpessoa,
            idreserva: props.dtoChangeCC.idreserva
        }

        try {
            setIsLoading(true);
            await hotelRepositorio.updateCCPorPassageiro(dto);
            setIsLoading(false);
            setMensagemSnack('Centro de custo alterado!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
            props.getHotelTicket();
            props.setOpenChangeCC(false);
        } catch (e) {
            setIsLoading(false);
            setMensagemSnack('Erro ao salvar!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }
    }

    useEffect(() => {
        (async () => {
            const response = await centroCustoRepositorio.getSearchField();
            setCentros(response);
        })();
    }, [])

    return (
        <>
            <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Dialog
                open={props.open}
                onClose={() => props.setOpenChangeCC(false)}
                fullWidth
                maxWidth="xs"
                fullScreen={responsive}
            >
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#646464' }}>
                        <Typography style={{ color: '#fff' }}>Alterar centro de custo</Typography>
                        <IconButton onClick={() => props.setOpenChangeCC(false)} >
                            <CloseOutlined style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
                    <USearchField {...{ itens: centros, value: centroCusto, setValue: setCentroCusto, label: 'Centro de Custo' }} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClickSalvar}
                        variant="contained"
                        style={{
                            color: '#fff',
                            background: '#c62828',
                            '&:hover': {
                                background: '#b91119'
                            }
                        }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default HotelChangeCC;
