import { EnumTipo } from "../enums/enumPrestacaoDeContas";
import { EnumTipoVeiculo } from "../enums/enumVeiculos";
import {
  writeHistoryTransferencias_Mock,
  writeHistory_Mock,
} from "./historico";

export var viagensdousuario = [
  {
    id: 1,
    pessoa: {
      idusuario: 1,
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_10.jpg",
      nome: "Rian Sheik do egito",
      email: "chromecrast@gmail.com",
    },
    datadecriacao: new Date(2024, 3, 3),
    titulo: "Viagem a negócios para local 1",
    centrodecusto: "Desenvolvimento",
    totalinicial: 200,
    total: 0,
    status: EnumTipo.viagemstatus.aguardandoinicio,
    datainicio: new Date(2024, 6, 6),
    datafim: new Date(2024, 6, 14),
    adiantamento: {
      pedido: true,
      status: 1,
    },
    despesas: [
      // {
      //   id: 1,
      //   datadecriacao: new Date(2024, 0, 28),
      //   tipo: EnumTipo.despesas.hodometro,
      //   descricao: "Descrição da despesa 86",
      //   hodometro: {
      //     kmtotal: 1.0,
      //     kmida: 0.2,
      //     kmfim: 1.2,
      //     duracao: 47,
      //     valor: 10.45,
      //     categoria: EnumTipoVeiculo.categoria.carro,
      //     combustivel: EnumTipoVeiculo.combustivel.gasolina,
      //     porte: EnumTipoVeiculo.porte.medio,
      //     comprovanteida:
      //       "https://th.bing.com/th/id/OIP.bd8aZhK5Bl-SqrDSlGrWQgAAAA?rs=1&pid=ImgDetMain",
      //     comprovantevolta: "",
      //     veiculo: {
      //       veiculo: 1,
      //       combustivel: 1,
      //       porte: 2,
      //     },
      //   },
      //   produtos: {
      //     descricao: "Manual",
      //     value: 1,
      //   },
      //   aprovado: false,
      //   controladoria: false,
      //   comprovante: "",
      //   total: 112.02,
      //   mensagem: "",
      // },
      // {
      //   id: 11221,
      //   datadecriacao: new Date(2024, 3, 28),
      //   tipo: EnumTipo.despesas.hodometro,
      //   descricao: "Vou para criciuma comprar um tenis",
      //   hodometro: {
      //     kmtotal: 1.5,
      //     kmida: 0.5,
      //     kmfim: 2.0,
      //     duracao: 47,
      //     valor: 10.45,
      //     categoria: EnumTipoVeiculo.categoria.carro,
      //     combustivel: EnumTipoVeiculo.combustivel.gasolina,
      //     porte: EnumTipoVeiculo.porte.medio,
      //     cords: [
      //       { lat: -28.679785, lng: -49.363487 },
      //       { lat: -28.679043, lng: -49.362594 },
      //       { lat: -28.67860359812248, lng: -49.362134110135294 },
      //     ],
      //     veiculo: {
      //       veiculo: 1,
      //       combustivel: 1,
      //       porte: 2,
      //     },
      //   },
      //   produtos: {
      //     descricao: "Google Maps",
      //     value: 2,
      //   },
      //   aprovado: false,
      //   controladoria: false,
      //   comprovante: "",
      //   total: 112.02,
      //   mensagem: "",
      // },
      // {
      //   id: 2,
      //   datadecriacao: new Date(2024, 0, 28),
      //   tipo: 1,
      //   descricao: "Comprar um pastel",
      //   produtos: {
      //     descricao: "Lanche",
      //     value: 1,
      //   },
      //   aprovado: false,
      //   controladoria: false,
      //   comprovante:
      //     "https://jornaloexpresso.files.wordpress.com/2014/01/comprovante.jpg?w=600",
      //   total: 15,
      //   mensagem: "",
      // },
    ],
    extras: [
      // {
      //   id: 21312312,
      //   datadecriacao: new Date(2024, 0, 16),
      //   titulo: "Despesa 213",
      //   tipo: EnumTipo.despesas.aereo,
      //   descricao: "Descrição da despesa 213",
      //   produtos: [
      //     {
      //       descricao: "Almoço",
      //       value: 2,
      //     },
      //   ],
      //   aprovado: false,
      //   controladoria: true,
      //   comprovante:
      //     "https://th.bing.com/th/id/R.107209006a622712010f5f0525c6dd67?rik=wBbYC8toTFWUdw&riu=http%3a%2f%2f2.bp.blogspot.com%2f-FrC7gVrA9Io%2fVLWILut_-9I%2fAAAAAAAADsQ%2fSKxjqBAyN_8%2fs1600%2f20150113_174151.jpg&ehk=h7x9qRj2rvriVZeS0rciWOtol6ttMd64tRXb3LFu%2fv0%3d&risl=&pid=ImgRaw&r=0",
      //   total: 48.4,
      //   comprovate: "",
      //   mensagem: "",
      // },
    ],
  },
  {
    id: 2,
    pessoa: {
      idusuario: 1,
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_10.jpg",
      nome: "Rian Sheik do egito",
      email: "chromecrast@gmail.com",
    },
    datadecriacao: new Date(2023, 6, 21),
    titulo: "Viagem a negócios para local 2",
    centrodecusto: "Desenvolvimento",
    totalinicial: 435.25,
    total: 410.9,
    status: 1,
    datainicio: new Date(2022, 4, 3),
    datafim: new Date(2023, 2, 15),
    adiantamento: {
      pedido: true,
      status: EnumTipo.adiantamento.solicitado,
    },
    despesas: [
      {
        id: 2,
        datadecriacao: new Date(2024, 0, 16),
        titulo: "Despesa 6",
        tipo: 1,
        descricao: "Descrição da despesa 18",
        produtos: [
          {
            descricao: "Produto 7",
            quantidade: 2,
            preço: 30.01,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante:
          "https://th.bing.com/th/id/R.107209006a622712010f5f0525c6dd67?rik=wBbYC8toTFWUdw&riu=http%3a%2f%2f2.bp.blogspot.com%2f-FrC7gVrA9Io%2fVLWILut_-9I%2fAAAAAAAADsQ%2fSKxjqBAyN_8%2fs1600%2f20150113_174151.jpg&ehk=h7x9qRj2rvriVZeS0rciWOtol6ttMd64tRXb3LFu%2fv0%3d&risl=&pid=ImgRaw&r=0",
        total: 48.4,
        comprovate: "",
        mensagem: "",
      },
      {
        id: 3,
        datadecriacao: new Date(2024, 0, 30),
        titulo: "Despesa 27",
        tipo: 5,
        descricao: "Descrição da despesa 8",
        produtos: [
          {
            descricao: "Produto 6",
            quantidade: 5,
            preço: 32.13,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante: "",
        total: 177.69,
        comprovate: "",
        mensagem: "",
      },
      {
        id: 4,
        datadecriacao: new Date(2024, 0, 7),
        titulo: "Despesa 13",
        tipo: 4,
        descricao: "Descrição da despesa 57",
        produtos: [
          {
            descricao: "Produto 5",
            quantidade: 4,
            preço: 12.1,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante:
          "https://media.metrolatam.com/2019/01/16/boletase7e5a919e77016b28a7139219c63f8261200x600-779cfddaf30e5384a431654edf239817-1200x800.jpg",
        total: 103.51,
        comprovate: "",
        mensagem: "",
      },
    ],
    extras: [
      {
        id: 21312312,
        datadecriacao: new Date(2024, 0, 16),
        titulo: "Despesa 213",
        tipo: EnumTipo.despesas.aereo,
        descricao: "Descrição da despesa 213",
        produtos: {
          descricao: "Almoço",
          value: 2,
        },
        aprovado: false,
        controladoria: true,
        comprovante:
          "https://th.bing.com/th/id/R.107209006a622712010f5f0525c6dd67?rik=wBbYC8toTFWUdw&riu=http%3a%2f%2f2.bp.blogspot.com%2f-FrC7gVrA9Io%2fVLWILut_-9I%2fAAAAAAAADsQ%2fSKxjqBAyN_8%2fs1600%2f20150113_174151.jpg&ehk=h7x9qRj2rvriVZeS0rciWOtol6ttMd64tRXb3LFu%2fv0%3d&risl=&pid=ImgRaw&r=0",
        total: 48.4,
        comprovate: "",
        mensagem: "",
      },
    ],
  },
  {
    id: 3,
    pessoa: {
      idusuario: 1,
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_10.jpg",
      nome: "Rian Sheik do egito",
      email: "chromecrast@gmail.com",
    },
    centrodecusto: "Desenvolvimento",
    datadecriacao: new Date(2023, 11, 28),
    titulo: "Viagem a negócios para local 3",
    centrodecusto: 3,
    totalinicial: 813.96,
    total: 783.55,
    status: 1,
    datainicio: new Date(2023, 1, 23),
    datafim: new Date(2023, 7, 5),
    adiantamento: {
      pedido: true,
      status: 1,
    },
    despesas: [
      {
        id: 5,
        datadecriacao: new Date(2024, 1, 14),
        titulo: "Despesa 98",
        tipo: 1,
        descricao: "Descrição da despesa 36",
        produtos: [
          {
            descricao: "Produto 2",
            quantidade: 3,
            preço: 35.27,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante:
          "https://media.metrolatam.com/2019/01/16/boletase7e5a919e77016b28a7139219c63f8261200x600-779cfddaf30e5384a431654edf239817-1200x800.jpg",
        total: 158.74,
        comprovate: "",
        mensagem: "",
      },
      {
        id: 6,
        datadecriacao: new Date(2024, 1, 21),
        titulo: "Despesa 81",
        tipo: 3,
        descricao: "Descrição da despesa 59",
        produtos: [
          {
            descricao: "Produto 1",
            quantidade: 3,
            preço: 10.31,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante: "",
        total: 121.06,
        comprovate: "",
        mensagem: "",
      },
      {
        id: 8,
        datadecriacao: new Date(2024, 0, 30),
        titulo: "Despesa 80",
        tipo: 1,
        descricao: "Descrição da despesa 31",
        produtos: [
          {
            descricao: "Produto 5",
            quantidade: 4,
            preço: 30.68,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante: "",
        total: 134.62,
        comprovate: "",
        mensagem: "",
      },
      {
        id: 10,
        datadecriacao: new Date(2024, 1, 10),
        titulo: "Despesa 5",
        tipo: 5,
        descricao: "Descrição da despesa 21",
        produtos: [
          {
            descricao: "Produto 7",
            quantidade: 2,
            preço: 24.55,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante: "",
        total: 36.33,
        comprovate: "",
        mensagem: "",
      },
      {
        id: 12,
        datadecriacao: new Date(2024, 1, 12),
        titulo: "Despesa 5",
        tipo: 4,
        descricao: "Descrição da despesa 13",
        produtos: [
          {
            descricao: "Produto 10",
            quantidade: 2,
            preço: 36.2,
          },
        ],
        aprovado: false,
        controladoria: false,
        comprovante: "",
        total: 48.31,
        comprovate: "",
        mensagem: "",
      },
    ],
    extras: [],
  },
];

export const adicionarNovaViagem_Mock = async (novaviagem) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  // let tipo = 1;

  const nova = {
    id: Date.now(),
    pessoa: {
      idusuario: 1,
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_10.jpg",
      nome: "Rian Sheik do egito",
      email: "chromecrast@gmail.com",
    },
    datadecriacao: new Date(),
    titulo: novaviagem.titulo,
    centrodecusto: novaviagem.id_centrocusto,
    total: novaviagem.total,
    totalinicial: novaviagem.totalinicial,
    status: EnumTipo.viagemstatus.aguardandoinicio,
    datainicio: novaviagem.datainicio,
    datafim: novaviagem.datafim,
    despesas: [],
    extras: [],
    adiantamento: {
      status: novaviagem.adiantamento.status,
    },
  };
  todasviagens = [...todasviagens, nova];

  return nova;
};

export const editarViagem_Mock = (id, viagem) => {
  viagensdousuario.map((item) => {
    if (item.id === id) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      if (viagem.titulo != null || viagem.titulo != "") {
        item.titulo = viagem.titulo;
      }

      return item;
    }
  });
};

export const adicionarNovaDespesa_Mock = async (idviagem, despesa) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const viagemIndex = todasviagens.findIndex((viagem) => viagem.id == idviagem);
  if (viagemIndex !== -1) {
    if (despesa.isExtra) {
      todasviagens[viagemIndex].extras.push({
        id: Date.now(),
        mensagem: "",
        add: true,
        ...despesa,
      });
    } else {
      todasviagens[viagemIndex].despesas.push({
        id: Date.now(),
        mensagem: "",
        ...despesa,
      });
    }
    todasviagens[viagemIndex].total += despesa.total;
  } else {
  }
};

export const adicionarNovaDespesaExtra_Mock = async (despesa) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  viagensdousuario.map((viagem) => {
    if (viagem.id == despesa.idviagem) {
      viagem.extras.map((item) => {
        if (item.id == despesa.id) {
          item.add = true;
        }
      });
    }
  });
};

export const criarNovaDespesaExtra_Mock = async (despesa) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const viagemIndex = viagensdousuario.findIndex(
    (viagem) => viagem.id == despesa.idviagem
  );
  if (viagemIndex !== -1) {
    viagensdousuario[viagemIndex].extras.push({
      id: Date.now(),
      mensagem: "",
      ...despesa,
    });

    let total = 0;
    viagensdousuario[viagemIndex].extras.forEach((extra) => {
      total += extra.total;
    });

    viagensdousuario[viagemIndex].totalextra = total;
  } else {
  }
};

export const adicionarNovoHodometro_Mock = async (idviagem, despesa) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  const viagemIndex = viagensdousuario.findIndex(
    (viagem) => viagem.id == idviagem
  );

  if (viagemIndex !== -1) {
    viagensdousuario[viagemIndex].despesas.push({
      id: Date.now(),
      mensagem: "",
      hodometro: {
        kmtotal: despesa.kmfinal - despesa.kminicial,
        kmida: despesa.kminicial,
        kmfim: despesa.kmtotal != null ? despesa.kmtotal : despesa.kmfinal,
        duracao: despesa.min != null ? despesa.min : 0,
        preco: despesa.preco,
        comprovanteida: despesa.comprovanteida,
        comprovantevolta: despesa.comprovantevolta,
        cords: despesa.cords,
        veiculo: {
          veiculo: despesa.veiculo.veiculo,
          combustivel: despesa.veiculo.combustivel,
          porte: despesa.veiculo.porte,
        },
      },
      datadecriacao: new Date(),
      tipo: 6,
      descricao: despesa.descricao,
      produtos: { value: despesa.produtos.value },
      aprovado: false,
      controladoria: false,
      comprovante: "hodometro",
      total: 0,
    });
    viagensdousuario[viagemIndex].total += despesa.total;
  } else {
  }
};

export const editarDespesa_Mock = async (idviagem, despesa) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == despesa.id) {
          item.datadecriacao = despesa.datadecriacao;
          item.titulo = despesa.titulo;
          item.tipo = despesa.tipo;
          item.descricao = despesa.descricao;
          item.produtos = despesa.produtos;
          item.comprovante = despesa.comprovante;
          item.total = despesa.total;
          if (viagem.status == EnumTipo.viagemstatus.controladoria) {
            viagem.status = EnumTipo.viagemstatus.controladoria;
          }
          if (viagem.status == EnumTipo.viagemstatus.pendenteacorrecao) {
            if (item.mensagem != "") {
              viagem.status = EnumTipo.viagemstatus.correcaorealizada;
              item.mensagem = "";
            }
          }
        }
      });
      return viagem;
    }
  });
};

export const editarHodometro_Mock = async (idviagem, despesa) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  viagensdousuario.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == despesa.id) {
          item.datadecriacao = despesa.datadecriacao;
          item.titulo = despesa.titulo;
          item.tipo = 6;
          item.descricao = despesa.descricao;
          item.produtos = despesa.produtos;
          item.comprovante = despesa.comprovante;
          item.total = despesa.total;
          item.hodometro = {
            kmtotal: despesa.kmfinal - despesa.kminicial,
            kminicial: despesa.kminicial,
            kmfinal:
              despesa.kmtotal != null ? despesa.kmtotal : despesa.kmfinal,
            duracao: despesa.min != null ? despesa.min : 0,
            preco: despesa.preco,
            comprovanteida: despesa.comprovanteida,
            comprovantevolta: despesa.comprovantevolta,
            veiculo: {
              veiculo: despesa.veiculo.veiculo,
              combustivel: despesa.veiculo.combustivel,
              porte: despesa.veiculo.porte,
            },
            cords: item.hodometro.cords,
          };
          if (item.mensagem != "") {
            viagem.status = EnumTipo.viagemstatus.correcaorealizada;
            item.mensagem = "";
          }
        }
        return item;
      });
      return viagem;
    }
  });
};

export const excluirDespesa_Mock = async (viagemId, despesaId) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  viagensdousuario.map((viagem) => {
    if (viagem.id === viagemId) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas = viagem.despesas.filter(
        (despesa) => despesa.id !== despesaId
      );
      return viagem;
    }
  });

  return viagensdousuario;
};

export const excluirExtra_Mock = async (viagemId, despesaId) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  viagensdousuario.map((viagem) => {
    if (viagem.id === viagemId) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.extras = viagem.extras.filter(
        (despesa) => despesa.id !== despesaId
      );
      return viagem;
    }
  });

  return viagensdousuario;
};

export const avancarViagem_Mock = async (viagemId) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const viagem = todasviagens.find((viagem) => viagem.id === viagemId);
  if (viagem) {
    viagem.status = EnumTipo.viagemstatus.viagemfinalizada;
    viagem.despesas.map((item) => {
      item.mensagem = "";
      return item;
    });
    return viagem;
  } else {
    return null;
  }
};

export const iniciarViagem_Mock = async (viagemId) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const viagem = todasviagens.find((viagem) => viagem.id === viagemId);
  if (viagem) {
    viagem.status = EnumTipo.viagemstatus.emviagem;
    viagem.despesas.forEach((item) => {
      item.mensagem = "";
    });
    return viagem;
  } else {
    return null;
  }
};

export var todasviagens = [
  ...viagensdousuario,
  {
    id: 19,
    pessoa: {
      idusuario: 2,
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_7.jpg",
      nome: "Julio Balestrin",
      email: "juliobalestrin@gmail.com",
    },
    datadecriacao: new Date(2023, 6, 21),
    titulo: "Viagem a negócios para local 2",
    centrodecusto: "Marketing",
    totalinicial: 435.25,
    total: 410.9,
    status: EnumTipo.viagemstatus.controladoria,
    datainicio: new Date(2022, 4, 3),
    datafim: new Date(2023, 2, 15),
    adiantamento: {
      pedido: true,
      status: EnumTipo.adiantamento.aprovado,
    },
    despesas: [
      {
        id: 2,
        datadecriacao: new Date(2024, 0, 16),
        titulo: "Despesa 6",
        tipo: 1,
        descricao: "Descrição da despesa 18",
        produtos: [
          {
            descricao: "Almoço",
            value: 2,
          },
        ],
        aprovado: false,
        controladoria: true,
        comprovante:
          "https://th.bing.com/th/id/R.107209006a622712010f5f0525c6dd67?rik=wBbYC8toTFWUdw&riu=http%3a%2f%2f2.bp.blogspot.com%2f-FrC7gVrA9Io%2fVLWILut_-9I%2fAAAAAAAADsQ%2fSKxjqBAyN_8%2fs1600%2f20150113_174151.jpg&ehk=h7x9qRj2rvriVZeS0rciWOtol6ttMd64tRXb3LFu%2fv0%3d&risl=&pid=ImgRaw&r=0",
        total: 48.4,
        comprovate: "",
        mensagem: "Não pode comprar maconha cara",
      },
    ],
  },
  {
    id: 18939,
    pessoa: {
      idusuario: 3,
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_13.jpg",
      nome: "Renato Cariani",
      email: "retinhowhey1kg@gmail.com",
    },
    datadecriacao: new Date(2023, 6, 21),
    titulo: "Viagem pra growth",
    centrodecusto: "Influencer",
    totalinicial: 600,
    total: 589.9,
    status: EnumTipo.viagemstatus.correcaorealizada,
    datainicio: new Date(2022, 4, 3),
    datafim: new Date(2023, 2, 15),
    adiantamento: {
      pedido: true,
      status: 3,
    },
    despesas: [
      {
        id: 2,
        datadecriacao: new Date(2024, 0, 16),
        tipo: 1,
        descricao: "Compras de um whey",
        produtos: {
          descricao: "Almoço",
          value: 2,
        },
        aprovado: false,
        controladoria: false,
        comprovante:
          "https://3.bp.blogspot.com/-6RT2BMy30ic/VDrCIInmMmI/AAAAAAAARKA/ztqAW7X9r8g/s1600/doacao09b-out2014.jpg",
        total: 100.0,
        mensagem: "",
      },
      {
        id: 23123,
        datadecriacao: new Date(2024, 0, 16),
        tipo: 1,
        descricao: "Compras de um whey",
        produtos: {
          descricao: "Almoço",
          value: 3,
        },
        aprovado: false,
        controladoria: false,
        comprovante:
          "https://amocanela.files.wordpress.com/2020/06/whatsapp-image-2020-06-23-at-05.59.42.jpeg",
        total: 150.0,
        mensagem: "",
      },
    ],
  },
  // {
  //   id: 2,
  //   pessoa: {
  //     img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_10.jpg",
  //     nome: "Rian Sheik do egito",
  //     email: "chromecrast@gmail.com",
  //   },
  //   datadecriacao: new Date(2023, 6, 21),
  //   titulo: "Viagem a negócios para local 2",
  //   centrodecusto: 1,
  //   totalinicial: 435.25,
  //   total: 410.9,
  //   status: 8,
  //   datainicio: new Date(2022, 4, 3),
  //   datafim: new Date(2023, 2, 15),
  //   adiantamento: {
  //     pedido: true,
  //     status: 3,
  //   },
  //   despesas: [
  //     {
  //       id: 2,
  //       datadecriacao: new Date(2024, 0, 16),
  //       titulo: "Despesa 6",
  //       tipo: 1,
  //       descricao: "Descrição da despesa 18",
  //       produtos: [
  //         {
  //           descricao: "Produto 7",
  //           quantidade: 2,
  //           preço: 30.01,
  //         },
  //       ],
  //       comprovante:
  //         "https://th.bing.com/th/id/R.107209006a622712010f5f0525c6dd67?rik=wBbYC8toTFWUdw&riu=http%3a%2f%2f2.bp.blogspot.com%2f-FrC7gVrA9Io%2fVLWILut_-9I%2fAAAAAAAADsQ%2fSKxjqBAyN_8%2fs1600%2f20150113_174151.jpg&ehk=h7x9qRj2rvriVZeS0rciWOtol6ttMd64tRXb3LFu%2fv0%3d&risl=&pid=ImgRaw&r=0",
  //       total: 48.4,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //     {
  //       id: 3,
  //       datadecriacao: new Date(2024, 0, 30),
  //       titulo: "Despesa 27",
  //       tipo: 5,
  //       descricao: "Descrição da despesa 8",
  //       produtos: [
  //         {
  //           descricao: "Produto 6",
  //           quantidade: 5,
  //           preço: 32.13,
  //         },
  //       ],
  //       comprovante: "",
  //       total: 177.69,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //     {
  //       id: 4,
  //       datadecriacao: new Date(2024, 0, 7),
  //       titulo: "Despesa 13",
  //       tipo: 4,
  //       descricao: "Descrição da despesa 57",
  //       produtos: [
  //         {
  //           descricao: "Produto 5",
  //           quantidade: 4,
  //           preço: 12.1,
  //         },
  //       ],
  //       comprovante:
  //         "https://media.metrolatam.com/2019/01/16/boletase7e5a919e77016b28a7139219c63f8261200x600-779cfddaf30e5384a431654edf239817-1200x800.jpg",
  //       total: 103.51,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //   ],
  // },
  // {
  //   id: 546,
  //   pessoa: {
  //     img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_1.jpg",
  //     nome: "Zulma torassi",
  //     email: "zulmatorassi@gmail.com",
  //   },
  //   datadecriacao: new Date(2023, 6, 21),
  //   titulo: "Viagem para são francisco",
  //   centrodecusto: 1,
  //   totalinicial: 900,
  //   total: 689.9,
  //   status: 8,
  //   datainicio: new Date(2022, 9, 3),
  //   datafim: new Date(2023, 1, 15),
  //   adiantamento: {
  //     pedido: true,
  //     status: 1,
  //   },
  //   despesas: [
  //     {
  //       id: 2,
  //       datadecriacao: new Date(2024, 0, 16),
  //       titulo: "Despesa 6",
  //       tipo: 1,
  //       descricao: "Descrição da despesa 18",
  //       produtos: [
  //         {
  //           descricao: "Produto 7",
  //           quantidade: 2,
  //           preço: 30.01,
  //         },
  //       ],
  //       comprovante:
  //         "https://th.bing.com/th/id/R.107209006a622712010f5f0525c6dd67?rik=wBbYC8toTFWUdw&riu=http%3a%2f%2f2.bp.blogspot.com%2f-FrC7gVrA9Io%2fVLWILut_-9I%2fAAAAAAAADsQ%2fSKxjqBAyN_8%2fs1600%2f20150113_174151.jpg&ehk=h7x9qRj2rvriVZeS0rciWOtol6ttMd64tRXb3LFu%2fv0%3d&risl=&pid=ImgRaw&r=0",
  //       total: 48.4,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //     {
  //       id: 3,
  //       datadecriacao: new Date(2024, 0, 30),
  //       titulo: "Despesa 27",
  //       tipo: 5,
  //       descricao: "Descrição da despesa 8",
  //       produtos: [
  //         {
  //           descricao: "Produto 6",
  //           quantidade: 5,
  //           preço: 32.13,
  //         },
  //       ],
  //       comprovante: "",
  //       total: 177.69,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //     {
  //       id: 4,
  //       datadecriacao: new Date(2024, 0, 7),
  //       titulo: "Despesa 13",
  //       tipo: 4,
  //       descricao: "Descrição da despesa 57",
  //       produtos: [
  //         {
  //           descricao: "Produto 5",
  //           quantidade: 4,
  //           preço: 12.1,
  //         },
  //       ],
  //       comprovante:
  //         "https://media.metrolatam.com/2019/01/16/boletase7e5a919e77016b28a7139219c63f8261200x600-779cfddaf30e5384a431654edf239817-1200x800.jpg",
  //       total: 103.51,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //   ],
  // },
  // {
  //   id: 125,
  //   pessoa: {
  //     img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_5.jpg",
  //     nome: "Thalysson de souza",
  //     email: "curtogays@gmail.com",
  //   },
  //   datadecriacao: new Date(2024, 1, 21),
  //   titulo: "Viagem para são francisco",
  //   centrodecusto: 1,
  //   totalinicial: 14000,
  //   total: 6345.9,
  //   status: 8,
  //   datainicio: new Date(2024, 3, 17),
  //   datafim: new Date(2023, 3, 23),
  //   adiantamento: {
  //     pedido: true,
  //     status: 1,
  //   },
  //   despesas: [
  //     {
  //       id: 2,
  //       datadecriacao: new Date(2024, 0, 16),
  //       titulo: "Despesa 6",
  //       tipo: 1,
  //       descricao: "Descrição da despesa 18",
  //       produtos: [
  //         {
  //           descricao: "Produto 7",
  //           quantidade: 2,
  //           preço: 30.01,
  //         },
  //       ],
  //       comprovante:
  //         "https://th.bing.com/th/id/R.107209006a622712010f5f0525c6dd67?rik=wBbYC8toTFWUdw&riu=http%3a%2f%2f2.bp.blogspot.com%2f-FrC7gVrA9Io%2fVLWILut_-9I%2fAAAAAAAADsQ%2fSKxjqBAyN_8%2fs1600%2f20150113_174151.jpg&ehk=h7x9qRj2rvriVZeS0rciWOtol6ttMd64tRXb3LFu%2fv0%3d&risl=&pid=ImgRaw&r=0",
  //       total: 48.4,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //     {
  //       id: 3,
  //       datadecriacao: new Date(2024, 0, 30),
  //       titulo: "Despesa 27",
  //       tipo: 5,
  //       descricao: "Descrição da despesa 8",
  //       produtos: [
  //         {
  //           descricao: "Produto 6",
  //           quantidade: 5,
  //           preço: 32.13,
  //         },
  //       ],
  //       comprovante: "",
  //       total: 177.69,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //     {
  //       id: 4,
  //       datadecriacao: new Date(2024, 0, 7),
  //       titulo: "Despesa 13",
  //       tipo: 4,
  //       descricao: "Descrição da despesa 57",
  //       produtos: [
  //         {
  //           descricao: "Produto 5",
  //           quantidade: 4,
  //           preço: 12.1,
  //         },
  //       ],
  //       comprovante:
  //         "https://media.metrolatam.com/2019/01/16/boletase7e5a919e77016b28a7139219c63f8261200x600-779cfddaf30e5384a431654edf239817-1200x800.jpg",
  //       total: 103.51,
  //       aprovado: false,
  //       mensagem: "",
  //     },
  //   ],
  // },
];

export const getAllViagensUsuario_Mock = async () => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const v = todasviagens
    .filter((viagem) => viagem.pessoa.idusuario === 1)
    .sort((a, b) => b.datadecriacao - a.datadecriacao);

  if (v.length > 0) {
    const response = {
      viagens: v,
      pessoa: {
        nome: v[0].pessoa.nome,
        centrocusto: "Marketing - 01010101",
        img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_10.jpg",
      },
    };

    return response;
  }

  return { viagens: [], pessoa: {} };
};

export const getAllDespesas_Mock = async (idviagem) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const viagem = todasviagens.find((viagem) => viagem.id === idviagem);
  if (viagem) {
    const response = {
      despesas: viagem.despesas,
      extras: viagem.extras,
    };

    return response;
  }
  return { despesas: [], extras: {} };
};

export const getAllViagensAuditor_Mock = async () => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  return todasviagens.filter(
    (item) => item.status != EnumTipo.viagemstatus.aguardandoinicio
  );
};

export const SearchFiltersViagens_Mock = async (filters) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  // Extrai os filtros
  const { name, startDate, endDate, statusFilter, statusArr } = filters;

  // Filtra as viagens com base nos filtros fornecidos
  let filteredViagens = todasviagens;

  if (name) {
    filteredViagens = filteredViagens.filter((viagem) =>
      viagem.pessoa.nome.toLowerCase().includes(name.toLowerCase())
    );
  }

  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    filteredViagens = filteredViagens.filter((viagem) => {
      const creationDate = new Date(viagem.datadecriacao);
      return creationDate >= start && creationDate <= end;
    });
  }

  if (statusFilter && statusFilter !== "todos") {
    filteredViagens = filteredViagens.filter((viagem) =>
      statusArr.includes(viagem.status)
    );
  }

  return filteredViagens;
};

export const retornarDespesaParaViajante_Mock = (
  idviagem,
  iddespesa,
  mensagem,
  status
) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.mensagem = mensagem;
        }
        return item;
      });

      viagem.status = status;

      return viagem;
    }
  });
};

export const cancelarEnvioDeMensagem_Mock = (idviagem, iddespesa, status) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.mensagem = "";
          item.controladoria = false;
        }
        return item;
      });

      viagem.status = status;
      return viagem;
    }
  });
};

export const cancelarEnvioDeMensagem_CONTROLADORIA_Mock = (
  idviagem,
  iddespesa,
  status
) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.mensagemcontrolador = "";
          item.controladoria = true;
        }
        return item;
      });

      viagem.status = status;
      return viagem;
    }
  });
};

export const enviarParaControladoria_Mock = (idviagem, mensagem, iddespesa) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Atualiza a despesa específica
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.controladoria = true;
          item.mensagem = mensagem;
        }
        return item;
      });

      // Verifica se existe pelo menos uma despesa em controladoria
      const existeControladoria = viagem.despesas.some(
        (item) => item.controladoria === true
      );

      // Verifica se todas as outras despesas foram aprovadas
      const todasAprovadas = viagem.despesas.every((item) =>
        item.id !== iddespesa ? item.aprovado : true
      );
      if (existeControladoria && todasAprovadas) {
        viagem.status = EnumTipo.viagemstatus.controladoria;
      }

      return viagem;
    }
  });
};

export const auditarValorTotalDespesa_Mock = (idviagem, total, iddespesa) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          // item.controladoria = false;
          // item.mensagem = mensagem;
          item.totalauditado = total;
        }
        return item;
      });

      return viagem;
    }
  });
};

export const enviarParaAuditor_Mock = (idviagem, mensagem, iddespesa) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.controladoria = false;
          item.mensagem = mensagem;
        }
        return item;
      });

      viagem.status = EnumTipo.viagemstatus.viagemfinalizada;

      return viagem;
    }
  });
};

export const enviarParaPessoa_CONTROLADOR_Mock = (
  idviagem,
  mensagem,
  iddespesa
) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.controladoria = true; // Marcar despesa como não controlada
          item.mensagemcontrolador = mensagem; // Adicionar mensagem à despesa
        }
        return item;
      });

      return viagem;
    }
  });
};

export const aprovarDespesa_CONTROLADOR_Mock = (idviagem, iddespesa) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.controladoria = false; // Marcar despesa como não controlada
          item.mensagem = ""; // Adicionar mensagem à despesa
          item.aprovado = true;
        }
        return item;
      });

      const todasDespesasControladoria = viagem.despesas.every(
        (item) => item.controladoria == true
      );

      let contemcontroladoria = false;
      let contempendente = false;

      viagem.despesas.forEach((despesa) => {
        if (despesa.controladoria) {
          contemcontroladoria = true;
        } else if (despesa.mensagem != "" && !despesa.aprovado) {
          contempendente = true;
        }
      });

      if (contemcontroladoria) {
        viagem.status = EnumTipo.viagemstatus.controladoria;
      } else if (contempendente) {
        viagem.status = EnumTipo.viagemstatus.pendenteacorrecao;
      } else {
        viagem.status = EnumTipo.viagemstatus.viagemfinalizada;
      }

      return viagem;
    }
  });
};

export const getViagem_Mock = (idviagem) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      return viagem;
    }
  });
};

export const aprovarDespesas_Mock = async (idviagem, despesas) => {
  await new Promise((resolve) => setTimeout(resolve, 2000));

  todasviagens.forEach((viagem) => {
    if (viagem.id === idviagem) {
      // Aprova as despesas fornecidas
      viagem.despesas.forEach((item) => {
        despesas.forEach((i) => {
          if (item.id === i.id) {
            item.aprovado = true;
          }
        });
      });

      // Verifica se existe pelo menos uma despesa em controladoria
      const existeControladoria = viagem.despesas.some(
        (item) => item.controladoria === true
      );

      // Verifica se todas as outras despesas, exceto a marcada para controladoria, foram aprovadas
      const todasAprovadas = viagem.despesas.every(
        (item) => item.controladoria || item.aprovado
      );

      // Atualiza o status baseado nas despesas aprovadas e controladoria
      if (existeControladoria && todasAprovadas) {
        viagem.status = EnumTipo.viagemstatus.controladoria;
      } else if (!existeControladoria && todasAprovadas) {
        viagem.status = EnumTipo.viagemstatus.viagemfinalizada;
      }

      return viagem;  // Retorna a viagem atualizada
    }
  });

  return null;  // Retorna null se a viagem não for encontrada
};

export const retornarCancelarAprovacaoDeDespesa_Mock = (
  idviagem,
  iddespesa
) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      viagem.despesas.map((item) => {
        if (item.id == iddespesa) {
          item.aprovado = false;
        }
        return item;
      });

      return viagem;
    }
  });
};

export const aprovarViagemParaFinanceiro_Mock = (idviagem) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      viagem.status = EnumTipo.viagemstatus.financeiro;
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      return viagem;
    }
  });
};

//Financeiro

export const aprovarAdiantamento_Mock = (idviagem, info) => {


  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      viagem.financeiro = {
        nota: info.nota,
        desconto: info.desconto,
        tax: info.tax,
        total: info.total,
        forma: info.forma,
        desconto: info.desconto,
      };
      viagem.totalinicial = info.total;

      let mensagem = "Adiantamento aprovado";

      if (viagem.status == EnumTipo.viagemstatus.financeiro) {
        viagem.status = EnumTipo.viagemstatus.finalizado;

        mensagem = "Pagamento Realizado";
        viagem.financeiro.nota =
          info.nota +
          " - O usuário nessa viagem pagou suas despesas e finalizou sua viagem";
      }

      viagem.adiantamento = {
        pedido: true,
        status: EnumTipo.adiantamento.aprovado,
      };

      writeHistory_Mock(
        viagem.pessoa.id,
        mensagem,
        viagem.titulo +
        " " +
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(info.total),
        "sucess"
      );
      writeHistoryTransferencias_Mock(
        viagem.pessoa.nome,
        viagem.pessoa.img,
        "Pagamento Realizado",
        info.total
      );
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      return viagem;
    }
  });
};

export const negarAdiantamento_Mock = (idviagem, info) => {
  todasviagens.map((viagem) => {
    if (viagem.id == idviagem) {
      viagem.financeiro = {
        nota: info.nota,
        desconto: info.desconto,
        tax: info.tax,
        total: info.total,
        forma: info.forma,
        desconto: info.desconto,
      };
      viagem.totalinicial = info.total;

      if (viagem.status == EnumTipo.viagemstatus.financeiro) {
        viagem.status = EnumTipo.viagemstatus.finalizado;
      }

      viagem.adiantamento = {
        pedido: true,
        status: EnumTipo.adiantamento.negado,
      };

      writeHistory_Mock(
        viagem.pessoa.id,
        "Adiantamento negado",
        viagem.titulo +
        " " +
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(info.total),
        "error"
      );
      // Filtra as despesas para excluir a despesa com o despesaId fornecido
      return viagem;
    }
  });
};
