import { Box, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from '@material-ui/core';
import { FirstPageOutlined, KeyboardArrowLeft, KeyboardArrowRight, LastPageOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import EnumPackagesAgentes from '../../../enums/enumStatusPackagesAgentes';
import { useStyles } from './styles';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: '#f3d4d4'
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#c62828"
    }
}))(LinearProgress);

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageOutlined /> : <FirstPageOutlined />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageOutlined /> : <LastPageOutlined />}
            </IconButton>
        </div>
    );
}

function TabelaDados(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickRow = (event, idpacote) => {
        props.setOpenDialogPacote(true)
        props.setSelectedPacote(idpacote)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TableContainer component={Paper} style={{ maxWidth: '92vw', maxHeight: '70vh', minHeight: 440 }}>
                <Table stickyHeader size={'small'} className={classes.tableCustom}>
                    <TableHead >
                        <TableRow  >
                            <TableCell colSpan={6} style={{ textAlign: '-webkit-center' }}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, { label: 'Todos', value: -1 }]}
                                    count={props.data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell >ID</TableCell>
                            <TableCell style={{ maxWidth: '10px' }}>Status</TableCell>
                            <TableCell >Data de Criação</TableCell>
                            <TableCell>Título</TableCell>
                            <TableCell>Viajante</TableCell>
                            <TableCell style={{ minWidth: '200px' }}>Progresso </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : props.data
                        ).map((item) => (
                            <TableRow key={item.idpacote} onClick={(event) => handleClickRow(event, item)}>
                                <TableCell>{item.idpacotetenant}</TableCell>
                                <TableCell>
                                    <Typography variant="caption"
                                        style={{
                                            width: 'fit-content',
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: EnumPackagesAgentes.getInfoPackage(item.status).color,
                                            background: EnumPackagesAgentes.getInfoPackage(item.status).color,
                                            color: 'white', borderRadius: 3, padding: 5
                                        }}
                                    >
                                        {
                                            EnumPackagesAgentes.getInfoPackage(item.status).text
                                        }
                                    </Typography>
                                </TableCell>
                                <TableCell>{item.datacriacao}</TableCell>
                                <TableCell>{item.titulo}</TableCell>
                                <TableCell>{item.viajante}</TableCell>
                                <TableCell><LinearProgressWithLabel value={item.progresso} /> </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    );
};

export default TabelaDados;
