import { Button, Container, createTheme, Grid, Grow, IconButton, Paper, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { DataGrid } from '@material-ui/data-grid';
import { AddCircleOutlineOutlined, CloseOutlined, EditOutlined, SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import LinesTextField from '../../../../components/LinesTextField';
import emailRepositorio from '../../../../repositorios/email';
import Dashboard from '../../../dashboard';
import CadastroEmail from '../cadastro';
import CustomNoRowsOverlay from './emptyList';
import { useStyles } from './styles';

const themePT = createTheme({
    palette: {
        primary: { main: '#1976d2' },
    },
}, ptBR)

export const ListaEmail = () => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [pesquisa, setPesquisa] = useState('');
    const [openModalCadastro, setOpenModalCadastro] = useState(false);
    const [email, setEmail] = useState({});

    const onOpenModalCadastro = (item) => {
        let dto = {
            ...item
        }
        delete dto.id;

        setEmail(item);
        setOpenModalCadastro(true);
    }

    const columns = [
        {
            field: 'id', headerName: 'Ação', renderCell: (params) => (
                <IconButton onClick={() => onOpenModalCadastro(params.row)}>
                    <EditOutlined />
                </IconButton>
            ), width: 100
        },
        { field: 'id', headerName: 'ID', type: 'number', width: 100 },
        { field: 'nome', headerName: 'Nome', type: 'string', width: 300 },
        { field: 'email', headerName: 'Email', width: 300 },
    ];

    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);

    const getSearch = async () => {
        try {
            setLoading(true);
            const response = await emailRepositorio.getSearch(pesquisa);
            setEmails(mapToData(response.data));
            setLoading(false);
        } catch (e) {
            setLoading(false);

        }
    }

    const mapToData = listaResponse => listaResponse.map(item => {
        return {
            id: item.idcontaemail,
            ...item
        }
    });


    const loadEmails = async () => {
        try {
            setLoading(true);
            const response = await emailRepositorio.getAllContaEmail();
            setEmails(mapToData(response.data));
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadEmails();
        })();
    }, [])

    return (
        <div className={classes.main}>
                <CadastroEmail refreshList={loadEmails} email={email} setEmail={setEmail} open={openModalCadastro} setOpen={setOpenModalCadastro} />
                <Container maxWidth="xl" className={classes.root}>
                    <Grow in timeout={500}>
                        <Paper elevation={5} className={classes.container}>
                            <Toolbar>
                                <Grid container spacing={2} style={{ margin: 0 }}>
                                    {!responsive ? (
                                        <Grid item xs={6} className={classes.gridAlign}>
                                            <Button
                                                className={classes.button}
                                                onClick={() => onOpenModalCadastro({})}
                                                startIcon={<AddCircleOutlineOutlined style={{ color: '#fff' }} />}
                                            >Novo</Button>
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={12} md={6} className={classes.gridAlignEnd}>
                                        <LinesTextField
                                            placeholder="Pesquisar Nome"
                                            variant="outlined"
                                            margin="dense"
                                            value={pesquisa}
                                            onChange={e => setPesquisa(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        <IconButton onClick={() => setPesquisa('')} size="small">
                                                            <CloseOutlined fontSize="small" />
                                                        </IconButton>
                                                        <IconButton onClick={getSearch} size="small">
                                                            <SearchOutlined fontSize="small" />
                                                        </IconButton>
                                                    </>
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <div style={{ height: '100%' }}>
                                <DataGrid
                                    components={{
                                        noRowsOverlay: CustomNoRowsOverlay
                                    }}
                                    hideFooterSelectedRowCount
                                    disableSelectionOnClick
                                    hideFooterRowCount
                                    rows={emails}
                                    loading={loading}
                                    columns={columns}
                                    pageSize={10}
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                />
                            </div>
                        </Paper>
                    </Grow>
                </Container>
        </div>
    )
};

export const Email = props => {

    return (
        <div>
            <Dashboard {...props} component={ListaEmail} />
        </div>
    )
};
export default Email
