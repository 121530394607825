import { Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { SendOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import LinesTextField from '../../../../components/LinesTextField';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import EnumCompany from '../../../../enums/enumFlightCompany';
import LogoAzul from '../../../../images/azul.png';
import LogoGol from '../../../../images/gol.png';
import LogoItapemirim from '../../../../images/itapemirim.png';
import LogoLatam from '../../../../images/latam.jpg';
import LogoMap from '../../../../images/map.png';
import LogoVoePass from '../../../../images/voepass.png';
import emailRepositorio from '../../../../repositorios/email';
import { useStyles } from './styles';

export const EnviarEmail = props => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [cc, setCc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [snackVariant, setSnackVariant] = useState('success');
    const [mensagemValidacao, setMensagemValidacao] = useState('success');
    const [validacao, setValidacao] = useState({ visible: false });
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const getLogoCompanhiaAerea = {
        'JJ': LogoLatam,
        'LA': LogoLatam,
        'G3': LogoGol,
        '2Z': LogoVoePass,
        '7M': LogoMap,
        'AD': LogoAzul,
        '8I': LogoItapemirim
    }

    const getEnumCompanhiaAereo = {
        'JJ': 1,
        'LA': 2,
        'G3': 3,
        '2Z': 4,
        '7M': 5,
        'AD': 6,
        '8I': 7
    }

    const onClickEnviarEmail = async () => {

        const validaEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

        if (props.listaCotacao.length <= 0) {
            setSnackVariant('error');
            setMensagemValidacao('Lista de Cotação não pode ser vazia!');
            setValidacao({ visible: true });
            return;
        }

        let listaVazia = props.listaCotacao.find(item => item.length <= 0);

        if (listaVazia) {
            setSnackVariant('error');
            setMensagemValidacao('Lista de Cotação não pode ser vazia!');
            setValidacao({ visible: true });
            return;
        }

        if (!email || !validaEmail.test(email)) {
            setSnackVariant('error');
            setMensagemValidacao('Por favor digite um e-mail válido');
            setValidacao({ visible: true });
            return;
        }

        if (!validaEmail.test(cc) && cc) {
            setSnackVariant('error');
            setMensagemValidacao('Por favor digite um e-mail - CC válido');
            setValidacao({ visible: true });
            return;
        }

        setIsLoading(true);

        let dtoEmail = {
            email: email,
            cc: cc,
            trechos: props.listaCotacao.map((trecho, i) => {
                return {
                    origem: props.voos[i][0][0].departure,
                    destino: props.voos[i][0][0].arrival,
                    segments: trecho.map(item => {
                        return {
                            cia: getEnumCompanhiaAereo[item[0].legs[0].managedBy.iata],
                            voos: item[0].legs.map(leg => {
                                return {
                                    numeroVoo: String(leg.flightNumber),
                                    saida: (new Date(leg.departureDate)).toLocaleString(),
                                    chegada: (new Date(leg.arrivalDate)).toLocaleString(),
                                    origem: leg.departure,
                                    destino: leg.arrival,
                                    escala: String(leg.numberOfStops)
                                }
                            }),
                            semBagagem: getSemBagagem(item) <= 0 ? 'Não Possui' : String(getSemBagagem(item)),
                            comBagagem: getComBagagem(item) <= 0 ? 'Não Possui' : String(getComBagagem(item))
                        }
                    })
                }
            })
        }

        const response = await emailRepositorio.enviarEmailCotacao(dtoEmail);

        if (response.status !== 204) {
            setIsLoading(false);
            setSnackVariant('error');
            setMensagemValidacao('Não foi possível enviar a cotação!');
            setValidacao({ visible: true });
            return;
        }

        setIsLoading(false);
        setSnackVariant('success');
        setMensagemValidacao('Cotação enviada com sucesso!');
        setValidacao({ visible: true });
        setTimeout(() => {
            props.setModalCotar(false);
        }, 2000);
    }

    const getSemBagagem = (lista) => {
        let tem = lista.filter(item => !item.fareProfile.baggage.isIncluded);
        return tem ? tem.reduce((acum, atual) => {

            return atual.fareGroup.priceWithTax < acum || acum <= 0 ?
                atual.fareGroup.priceWithTax :
                acum;
        }, 0) : 'Não possui';
    };
    const getComBagagem = (lista) => {
        let tem = lista.filter(item => item.fareProfile.baggage.quantity >= 1);
        return tem ? tem.reduce((acum = 0, atual) => {
            return atual.fareGroup.priceWithTax < acum || acum <= 0 ?
                atual.fareGroup.priceWithTax :
                acum;
        }, 0) : 'Não possui';
    }

    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: snackVariant, message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <ULoaderVoo isLoading={isLoading} />
            <Grid container spacing={3} style={{ margin: 0 }} item xs={12}>
                <Grid item xs={!responsive ? 5 : 12}>
                    <form>
                        <LinesTextField
                            id="linestur_email_cotacao"
                            autoComplete="email"
                            fullWidth
                            variant="outlined"
                            label="E-mail"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </form>
                </Grid>
                <Grid item xs={!responsive ? 5 : 12}>
                    <LinesTextField
                        fullWidth
                        variant="outlined"
                        label="CC"
                        value={cc}
                        onChange={e => setCc(e.target.value)}
                    />
                </Grid>
                <Grid item xs={!responsive ? 2 : 12}>
                    <Button
                        className={classes.button}
                        onClick={onClickEnviarEmail}
                        fullWidth
                        endIcon={<SendOutlined />}
                    >
                        <b>Enviar</b>
                    </Button>
                </Grid>
                {responsive ? (
                    <Grid item xs={12}>
                        {props.listaCotacao.map((voo, i) => (
                            <Grid container item xs={12} spacing={1} style={{ margin: 0 }}>
                                <Grid item xs={12}>
                                    <Toolbar style={{ color: '#616161', fontFamily: 'calibri' }}>
                                        <Typography><b>{` ${props.voos[i][0][0].departure} - ${props.voos[i][0][0].arrival} `}</b></Typography>
                                    </Toolbar>
                                </Grid>
                                {voo.map(item => (
                                    <Grid container spacing={2} style={{ margin: 0, marginBottom: theme.spacing(7) }} item xs={12}>
                                        <Grid item xs={12}>
                                            <Grid item container spacing={2} xs={12} style={{ margin: 0 }}>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img width={100} height={100} src={EnumCompany.getLogoCompanhiaAereaEnum(item[0].legs[0].managedBy.iata).url_high} />
                                                    </Grid>
                                                </Grid>
                                                {item[0].legs.map(leg => (
                                                    <Grid item container spacing={2} style={{ margin: 0 }} xs={12}>
                                                        <Grid container item xs={12} style={{ border: 'solid 1px silver', borderRadius: 20 }}>
                                                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography style={{ fontSize: 12 }}>{`${leg.departure}`}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography style={{ fontSize: 12 }}>{`${leg.arrival}`}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2} style={{ margin: 0 }} item xs={12} >
                                                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography style={{ fontSize: 10 }}>{`${new Date(leg.departureDate).toLocaleString().replace(':00', '')}`}</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography style={{ fontSize: 10 }} >{`${new Date(leg.arrivalDate).toLocaleString().replace(':00', '')}`}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12} style={{ border: 'solid 1px silver', borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography style={{ fontSize: 12 }}>{`Sem Bagagem: ${getSemBagagem(item) <= 0 ? 'Não Possui' : 'R$' + getSemBagagem(item)}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{ border: 'solid 1px silver', borderRadius: 20, marginTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography style={{ fontSize: 12 }}>{`Com Bagagem: ${getComBagagem(item) <= 0 ? 'Não Possui' : 'R$' + getComBagagem(item)}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container item xs={12}>
                        {props.listaCotacao.map((voo, i) => (
                            <Grid item xs={12}>
                                <Paper elevation={3}>
                                    <Toolbar style={{ background: '#616161', color: 'white', fontFamily: 'calibri' }}>
                                        <Typography><b>{` ${props.voos[i][0][0].departure} - ${props.voos[i][0][0].arrival} `}</b></Typography>
                                    </Toolbar>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">CIA</TableCell>
                                                    <TableCell align="center">Voo</TableCell>
                                                    <TableCell align="center">Saída</TableCell>
                                                    <TableCell align="center">Chegada</TableCell>
                                                    <TableCell align="center">Origem</TableCell>
                                                    <TableCell align="center">Destino</TableCell>
                                                    <TableCell align="center">ESC.</TableCell>
                                                    <TableCell align="center">Sem Bagagem</TableCell>
                                                    <TableCell align="center">Com Bagagem</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {voo.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            <img width={80} height={80} src={EnumCompany.getLogoCompanhiaAereaEnum(item[0].legs[0].managedBy.iata).url_high} />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map(leg => (
                                                                <div>
                                                                    <Typography style={{ fontSize: 12 }}>{leg.flightNumber}</Typography>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map(leg => (
                                                                <div>
                                                                    <Typography style={{ fontSize: 12 }}>{(new Date(leg.departureDate)).toLocaleString()}</Typography>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map(leg => (
                                                                <div>
                                                                    <Typography style={{ fontSize: 12 }}>{(new Date(leg.arrivalDate)).toLocaleString()}</Typography>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map(leg => (
                                                                <div>
                                                                    <Typography style={{ fontSize: 12 }}>{leg.departure}</Typography>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map(leg => (
                                                                <div>
                                                                    <Typography style={{ fontSize: 12 }}>{leg.arrival}</Typography>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map(leg => (
                                                                <div>
                                                                    <Typography style={{ fontSize: 12 }}>{leg.numberOfStops}</Typography>
                                                                    <br />
                                                                </div>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography>
                                                                {`${getSemBagagem(item) <= 0 ? 'Não Possui' : 'R$' + getSemBagagem(item)}`}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography>
                                                                {`${getComBagagem(item) <= 0 ? 'Não Possui' : 'R$' + getComBagagem(item)}`}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </div >
    )
};

export default EnviarEmail;
