import { XGrid, ptBR } from '@material-ui/x-grid';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import CustomNoRowsOverlay from '../../../configuracao/email/lista/emptyList';

export const RelatorioDesktopEcoGestao = (props) => {
    const [data, setData] = useState(props.dataPesquisa);

    const columns = [
        { field: 'id', headerName: 'ID', width: 250, hide: true },
        { field: 'passageiro', headerName: 'Passageiro', width: 220, },
        { field: 'loc', headerName: 'Loc', width: 120, },
        { field: 'trecho', headerName: 'Trecho', width: 120, },
        { field: 'sentido', headerName: 'Sentido', width: 120, },
        {
            field: 'datavoo', headerName: 'Data', width: 120,
            valueFormatter: (params) => {
                return format(new Date(params.value), 'dd/MM/yyyy');
            },
        },
        { field: 'emissaoInicial', headerName: 'CO² Inicial', width: 150, },
        { field: 'emissao', headerName: 'CO² Emitido', width: 150, },
        { field: 'eco', headerName: 'Eco', width: 120, },
        { field: 'tipo', headerName: 'Tipo', width: 120, }
    ];

    useEffect(() => {
        setData(props.dataPesquisa)
    }, [props.dataPesquisa])

    return (
        <>
            <div style={{ height: '52vh', width: '100%' }}>

                <XGrid
                    localeText={ptBR.props.MuiDataGrid.localeText}
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    id={Math.random()}
                    rows={data}
                    columns={columns}
                    loading={props.isLoading}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    hideFooterRowCount
                    pagination
                    rowsPerPageOptions={[50, 100,]}
                />
            </div>
        </>
    )
}
