
import { Box, Button, ButtonGroup, Grid, ThemeProvider, Typography, useMediaQuery, useTheme } from "@mui/material";
import Dashboard from "../dashboard";
import { useCallback, useEffect, useState } from "react";
import { Auditor } from "./Desktop/auditor";
import { MinhasViagens } from "./Desktop/minhasviagens";
import { MinhasViagensM } from "./Mobile/minhasviagens";
import { grey, themepalette } from "../../theme/palette";
import { AuditorM } from "./Mobile/auditor";
import IntegrationNotistack from "../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../components/uloadervoo";
import { ConfigAll } from "./Desktop/config";
import { ConfigAllM } from "./Mobile/config";
import { prestacaoDeContasConfigRepositorio } from "../../repositorios/prestacaodecontas/config";

// VALUES VIAGENS

export const PrestacaoDeContas = () => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentTab, setCurrentTab] = useState("minhasviagens");
    const [loading, setLoading] = useState(false)
    const [permissions, setPermissions] = useState([])
    const [themeall, setTheme] = useState(null);

    useEffect(() => {
        setTheme(themepalette);
    }, []);

    // const { enqueueSnackbar } = useCustomSnackbar();

    const TABSdesk = [
        ...(permissions?.permissao_contabilidade || permissions?.permissao_controladoria ? [{
            value: 'auditor',
            label: 'Auditar viagens',
            viewer: <Auditor permissions={permissions} />
        }] : []),
        {
            value: 'minhasviagens',
            label: 'Minhas viagens',
            viewer: <MinhasViagens />
        },
        ...(permissions?.permissao_editarpermissao || permissions.permissao_vergrupopalavra ? [{
            value: 'configuracoes',
            label: 'Configuração',
            viewer: <ConfigAll permissions={permissions} />
        }] : []),
    ];

    const TABSmob = [
        ...(permissions?.permissao_contabilidade || permissions?.permissao_controladoria ? [{
            value: 'auditor',
            label: 'Auditar viagens',
            viewer: <AuditorM permissions={permissions} />
        }] : []),
        {
            value: 'minhasviagens',
            label: 'Minhas viagens',
            viewer: <MinhasViagensM />
        },
        ...(permissions?.permissao_editarpermissao || permissions.permissao_vergrupopalavra ? [{
            value: 'configuracoes',
            label: 'Configuração',
            viewer: <ConfigAllM permissions={permissions} />
        }] : []),
    ];

    const getMyPermission = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasConfigRepositorio.getPermissionUsuario()
            setPermissions(response.permissoes)
        } catch {
            // enqueueSnackbar('Erro ao obter suas permissões.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    useEffect(() => {
        getMyPermission()
    }, [])

    return (
        <ThemeProvider theme={themeall || theme}>
            <IntegrationNotistack>
                <ULoaderVoo isLoading={loading} />
                <Grid container style={{ backgroundColor: "#FFFFFF" }}>
                    {!fullscreen ? (
                        <>
                            <Grid item xs={12}>
                                <div style={{ padding: "10px", marginRight: "20px" }}>
                                    <Box sx={{ p: 1, textAlign: 'left', borderRadius: '8px', maxWidth: '500px' }}>
                                        <Typography variant="h6" sx={{ mb: 1, color: grey[800] }}>
                                            Apenas para testes
                                        </Typography>
                                    </Box>
                                </div>
                            </Grid>
                            {permissions?.permissao_contabilidade || permissions?.permissao_editarpermissao || permissions.permissao_vergrupopalavra ? (
                                <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "25px" }}>
                                    <ButtonGroup
                                        variant="outlined"
                                        color="inherit"
                                        style={{ justifyContent: "center", borderColor: "#D6D6D6", borderRadius: "10px" }}
                                    >
                                        {TABSdesk.map((tab, index) => (
                                            <Button
                                                key={tab.value}
                                                onClick={() => handleChangeTab(tab.value)}
                                                style={{
                                                    textTransform: "none", // Mantém a capitalização original do texto
                                                    fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                                    borderColor: currentTab === tab.value ? "#1C252E" : "#D6D6D6",
                                                    backgroundColor: currentTab === tab.value ? "#1C252E" : "#FFFFFF",
                                                    color: currentTab === tab.value ? "#FFFFFF" : "#1C252E",
                                                    borderRadius: index === 0 ? "8px 0 0 8px" : index === TABSdesk.length - 1 ? "0 8px 8px 0" : "0" // Aplica borderRadius apenas nos extremos
                                                }}
                                            >
                                                {tab.label}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Grid>
                            ) : (
                                null
                            )}

                            <Grid item xs={12}>
                                {TABSdesk.slice(0, 3).map(
                                    (tab) =>
                                        tab.value === currentTab && (
                                            <>
                                                {tab.viewer}
                                            </>
                                        )
                                )}
                            </Grid>
                        </>
                    ) : (
                        // MOBILE
                        <>
                            {permissions?.permissao_contabilidade || permissions?.permissao_editarpermissao || permissions.permissao_vergrupopalavra ? (
                                <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginTop: "10px", padding: "20px" }}>
                                    <ButtonGroup variant="outlined" color="inherit" size="large" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                        {TABSmob.map((tab, index) => (
                                            <Button
                                                key={tab.value}
                                                onClick={() => handleChangeTab(tab.value)}
                                                style={{
                                                    textTransform: "none", // Mantém a capitalização original do texto
                                                    fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                                    borderColor: currentTab === tab.value ? "#1C252E" : "#D6D6D6",
                                                    backgroundColor: currentTab === tab.value ? "#1C252E" : "#FFFFFF",
                                                    color: currentTab === tab.value ? "#FFFFFF" : "#1C252E",
                                                    borderRadius: index === 0 ? "8px 0 0 8px" : index === TABSdesk.length - 1 ? "0 8px 8px 0" : "0" // Aplica borderRadius apenas nos extremos
                                                }}
                                            >
                                                {tab.label}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                {TABSmob.slice(0, 3).map(
                                    (tab) =>
                                        tab.value === currentTab && (
                                            <Box
                                                key={tab.value}
                                                sx={{
                                                    p: 2,
                                                    borderRadius: 1,
                                                    bgcolor: 'background.neutral',
                                                }}
                                            >
                                                {tab.viewer}
                                            </Box>
                                        )
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            </IntegrationNotistack>
        </ThemeProvider>
    )
}

export const PagePrestacaoDeContas = props => {
    return (
        <div>
            <Dashboard {...props} component={PrestacaoDeContas} />
        </div>
    )
};
export default PagePrestacaoDeContas;