import cookie from 'universal-cookie';
import api from '../../Api';
import reservaRepositorio from '../reserva';

const cookies = new cookie();

const solicitacaoRepositorio = {

    passageiroAdicionar: async dtoPassageiro => {
        const response = await api.post('/SolicitacaoPassageiro', dtoPassageiro, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    passageiroRemover: async idpassageiro => {
        const response = await api.delete('/SolicitacaoPassageiro/' + idpassageiro, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    destinoProdutoAdicionar: async dtoProduto => {
        const response = await api.post('/SolicitacaoDestinoProduto', dtoProduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    destinoProdutoRemover: async iddestinoproduto => {
        const response = await api.delete('/SolicitacaoDestinoProduto/' + iddestinoproduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    produtoPassageiroAdicionar: async dtoPassageiro => {
        const response = await api.post('/SolicitacaoProdutoPassageiro', dtoPassageiro, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    produtoPassageiroRemover: async idprodutopassageiro => {
        const response = await api.delete('/SolicitacaoProdutoPassageiro/' + idprodutopassageiro, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    destinoAdicionar: async dtoDestino => {
        const response = await api.post('/SolicitacaoDestino', dtoDestino, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    destinoRemover: async iddestino => {
        const response = await api.delete('/SolicitacaoDestino/' + iddestino, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    produtoAdicionar: async dtoProduto => {
        const response = await api.post('/SolicitacaoProduto', dtoProduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    produtoRemover: async idproduto => {
        const response = await api.delete('/SolicitacaoProduto/' + idproduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    mensagemAdicionar: async dtoMensagem => {
        const response = await api.post('/SolicitacaoMensagem', dtoMensagem, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    post: async dtoSolicitacao => {
        try {
            const response = await api.post('/solicitacao', dtoSolicitacao.solicitacao, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            });
            if (response.data) {
                dtoSolicitacao.destinos.map(async (item) => {
                    let dtoDestino = { ...item };
                    dtoDestino.idsolicitacao = response.data.idsolicitacao;
                    dtoDestino.idtenant = response.data.idtenant;
                    return await api.post('/SolicitacaoDestino', dtoDestino, {
                        headers: {
                            Authorization: 'Bearer ' + cookies.get('token')
                        }
                    })
                });

                dtoSolicitacao.passageiros.map(async (item) => {
                    let dtoViajante = { ...item };
                    dtoViajante.idsolicitacao = response.data.idsolicitacao;
                    dtoViajante.idtenant = response.data.idtenant;
                    return await api.post('/SolicitacaoPassageiro', dtoViajante, {
                        headers: {
                            Authorization: 'Bearer ' + cookies.get('token')
                        }
                    })

                });

                let dtoMensagem = { ...dtoSolicitacao.mensagem };
                dtoMensagem.idsolicitacao = response.data.idsolicitacao;
                dtoMensagem.idtenant = response.data.idtenant;
                await api.post('/SolicitacaoMensagem', dtoMensagem, {
                    headers: {
                        Authorization: 'Bearer ' + cookies.get('token')
                    }
                });

                let reservaDto = {
                    assunto: dtoSolicitacao.solicitacao.motivo,
                    status: 1,
                    tipo: 2,
                    idaereo: 0,
                    idsolicitante: dtoSolicitacao.solicitacao.idsolicitante,
                    idsolicitacao: response.data.idsolicitacao,
                    sessionId: cookies.get('sessionId'),
                    etapaaprovacao: 1,
                    datacriacao: new Date(),
                    dataultimaatualizacao: new Date(),
                };
                await reservaRepositorio.post(reservaDto);
                return { sucess: true }
            }
        } catch (e) {
            return { sucess: false, message: e }
        }
    },

    get: async dtoDadosSolicitacao => {
        const response = await api.post('/Solicitacao/GetSolicitacaoTenant', dtoDadosSolicitacao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    put: async dtoSolicitacao => {
        try {
            const response = await api.put('/solicitacao/' + dtoSolicitacao.solicitacao.idsolicitacao, dtoSolicitacao.solicitacao, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            });
            if (response.status === 204) {
                await reservaRepositorio.put(dtoSolicitacao.reserva);
                return { sucess: true }
            }
        } catch (e) {
            return { sucess: false, message: e }
        }
    },

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/produto', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        response.data.map(item => {
            lista.push({
                label: item.nome,
                value: item.idestado
            })
        })
        return lista;
    },
    putUpdateTitle: async (idreserva, assunto) => {
        let response = await api.put(
            `Reserva/Assunto/${idreserva}`, { assunto }, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        }
        );
        return response;
    },
    putUpdateFinanceiro: async (idsolicitacao, status, idreserva, idtenant) => {
        let response = await api.put(
            'solicitacao/financiastatus/', { idsolicitacao: idsolicitacao, status: status, idreserva: idreserva, idtenant: idtenant }, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        }
        );
        return response;
    }
}

export default solicitacaoRepositorio;
