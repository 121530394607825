import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import viagemRepositorio from '../../../../repositorios/viagem';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogAccept = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [disableButton, setDisableButton] = React.useState(false);

    const handleCloseModalAccept = async () => {
        await props.getData();
        props.setOpenModalAccept(false);
    }

    const handleClickModalAcceptYes = async () => {
        try {
            setDisableButton(true)
            await viagemRepositorio.avancarStatusViagem(props.modalTeste.data.idviagem);
            handleCloseModalAccept();
        } catch (e) {
            return;
        } finally {
            setDisableButton(false)
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseModalAccept}
            maxWidth="sm"
            fullWidth
            fullScreen={responsive}
            TransitionComponent={Transition}
        >
            <AppBar position="relative" style={{ background: 'none' }}>
                <Toolbar className={classes.appbar} >
                    <Typography>Confirmação</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <DialogContentText >
                    <Typography >Deseja avançar a viagem?</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClickModalAcceptYes}
                    disabled={disableButton}
                    className={classes.buttonConfirm}
                >
                    SIM
                </Button>
                <Button
                    onClick={handleCloseModalAccept}
                    className={classes.buttonConfirm}
                >
                    NÃO
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogAccept;
