import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import ULoaderVoo from '../../../../components/uloadervoo';
import reservaRepositorio from '../../../../repositorios/reserva';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogAcceptAllReservesStatusPending = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseModalAccept = async () => {
        props.setOpenModalAccept(false);
    }

    const handleClickModalAcceptYes = async () => {
        try {
            setIsLoading(true)
            await reservaRepositorio.aproveAll();
            props.setSnackVariant('success');
            props.setSnackMessage('Sucesso ao aprovar todas reservas.');
            props.setSnackState({ visible: true });
            handleCloseModalAccept();
        } catch (e) {
            props.setSnackVariant('error');
            props.setSnackMessage('Falha ao aprovar todas reservas.');
            props.setSnackState({ visible: true });
        } finally {
            props.getBookingList()
            setIsLoading(false)
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseModalAccept}
            maxWidth="sm"
            fullWidth
            fullScreen={responsive}
            TransitionComponent={Transition}
        >
            <ULoaderVoo isLoading={isLoading} />
            <AppBar position="relative" style={{ background: 'none' }}>
                <Toolbar className={classes.appbar} >
                    <Typography>Confirmação</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <DialogContentText >
                    <Typography >Deseja aprovador todas reservas em <b>'Aprovação pendente'</b>?</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClickModalAcceptYes}
                    disabled={isLoading}
                    className={classes.buttonConfirm}
                >
                    SIM
                </Button>
                <Button
                    onClick={handleCloseModalAccept}
                    className={classes.buttonConfirm}
                >
                    NÃO
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogAcceptAllReservesStatusPending;
