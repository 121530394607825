import { makeStyles, fade } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    height: '100%',
    width: '450px'
  },
  media: {
    height: 140,
    minHeight: 307.91
  },
  cards: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '30px'
  },
  teste: {
    display: 'flex',
    flexDirection: 'row'
  },
  button: {
    background: yellow[300],
    '&:hover': {
      background: yellow[500],
    },
    color: 'black'

  },
  titulo: {
    fontSize: '25px',
    padding: theme.spacing(1, 5)
  },
  expansion: {
    paddingTop: 0
  },
  search: {
    width: '70%',
    marginLeft: '1%'
  },
  search2: {
    width: '30%',
    marginLeft: '1%',
    marginRight: '1%',
  },
  heading: {

  },
  filtros: {
    marginTop: '3%',
    marginBottom: '1%'
  },
  testevai: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2)

  },
  botoes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'

  },
  botao: {
    display:'flex',
    justifyContent:'center',
    flexDirection:'row',

  },
  udate: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row'
  },
  lista2: {
    padding: theme.spacing(0, 5)
  },
  search3: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  lista: {

    padding: theme.spacing(5)
  }


}));