import { Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material";
import { UploadAvatar } from "../../../../../../upload";
import { UtaliiDatePickerNormal } from "../../../sections/custom-date-picker-normal";
import { useCallback, useEffect, useState } from "react";
import { convertImageToBase64 } from "../../../../../../hooks/convertImageToBase64";
import { fData } from "../../../../../../upload/utils/utils";
import { getTipoDespesaForNumberValue, tiposdedespesas_Mock } from "../../../../../../mock/tiposdespesas";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import prestacaoDeContasRepositorio from "../../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar";
import { formatarMoeda, getCurrencySymbol } from "../../../../../../enums/enumPrestacaoDeContas";

export const EditarDespesaComponent = ({ viagem, despesa, handleEdit, setType }) => {
    const [file, setFile] = useState(null);
    const [titulo, setTitulo] = useState("");
    const [tipodedespesa, setTipodedespesa] = useState(null);
    const [tipoGasto, setTipoGasto] = useState(null)
    const [descricao, setDescricao] = useState("");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [totalgasto, setTotalgasto] = useState(0);
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [isExtra, setIsExtra] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas()
            setCategorias(response)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleCreateDespesa = () => {
        const despesadata = {
            idviagem: viagem.id,
            iddespesa: despesa.iddespesa,
            datadecompra: datadecompra,
            descricao: descricao,
            hodometro: null,
            // produtos: {
            //     iddespesa_tipo: tipodedespesa != null ? tipodedespesa.value : null,
            //     iddespesa_subtipo: tipoGasto != null ? tipoGasto.value : null
            // },
            aprovado: false,
            controladoria: false,
            is_extra: isExtra,
            total: totalgasto,
            mensagem: "",
            comprovante: file,
            QrCode: null,
        }

        if (tipodedespesa != null && tipoGasto != null) {
            despesadata.produtos = {
                iddespesa_tipo: tipodedespesa.value,
                iddespesa_subtipo: tipoGasto.value
            }
            if (despesadata.produtos.iddespesa_tipo != null && despesadata.produtos.iddespesa_subtipo == null) {
                enqueueSnackbar('Adicione com oque foi gasto.', { variant: 'warning' });
                return
            }
        }

        setType(0)
        handleEdit(despesadata)
    }

    const handleChangeTipoDeDespesa = (value) => {
        setTipoGasto(null)
        setTipodedespesa(value);
    };

    const handleChangeGasto = (value) => {
        setTipoGasto(value)
    }

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            convertImageToBase64(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(
                    base64String // Aqui você tem a imagem convertida em base64
                );
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;

        // Formata o valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        setTotalgasto(valorFormatado);
    };

    useEffect(() => {
        getAlltipos()
    }, [])

    useEffect(() => {
        setFile(null)
        setTitulo(despesa.titulo);
        categorias?.forEach(categoria => {
            if (categoria.iddespesa_tipo == despesa.produtos.iddespesa_tipo) {
                setTipodedespesa({ label: categoria.name, value: despesa.produtos.iddespesa_tipo, sub: categoria.produtos });
                categoria.produtos.forEach(sub => {
                    setTipoGasto({ label: sub.name, value: despesa.produtos.iddespesa_subtipo })
                })
            }
        })
        setDescricao(despesa.descricao);
        setDatadecompra(new Date(despesa.datadecompra));
        setTotalgasto(despesa.total);
        // getAlltipos()
    }, [despesa, categorias])

    return (
        <Grid container item xs={12} style={{ width: "auto", minWidth: "500px" }}>
            <Grid container item xs={12} style={{ justifyContent: "left", marginTop: "-20px", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                <Grid item xs={6}>
                    <Typography style={{ cursor: "pointer" }} onClick={() => setType(0)}>Voltar</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={4} style={{ justifyContent: "center", textAlign: "left", alignContent: "space-between", marginTop: "10px", padding: "10px" }} spacing={1}>
                <UploadAvatar
                    file={file || despesa.comprovante}
                    accept="image/jpeg,image/png,image/gif"
                    style={{ height: "auto" }}
                    onDrop={handleDropAvatar}
                    fullScreen={true}
                // validator={(fileData) => {
                //     if (fileData.size > 1000000) {
                //         return {
                //             code: 'file-too-large',
                //             message: `Essse arquivo é muito grande!! ${fData(1000000)}`,
                //         };
                //     }
                //     return null;
                // }}
                />
            </Grid>
            <Grid container item xs={8} style={{ marginTop: "10px", alignContent: "space-between", paddingLeft: "10px", alignContent: "center" }} spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        id="filled-multiline-flexible"
                        variant="outlined"
                        fullWidth
                        required
                        defaultValue={despesa.descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                        multiline
                        maxRows={4}
                        label="Justificativa/Descrição"
                    />
                </Grid>
                <Grid item xs={12}>
                    {categorias.length == 0 ? (
                        <Skeleton>
                        </Skeleton>
                    ) : (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tipodedespesa != null ? tipodedespesa.value : 0}
                                label="Categoria"
                            >
                                {categorias?.map((e) => (
                                    <MenuItem value={e.iddespesa_tipo} onClick={() => handleChangeTipoDeDespesa({ label: e.name, value: e.iddespesa_tipo, sub: e.produtos })}>{e.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>
                {tipodedespesa != null && tipodedespesa?.sub?.lenght != 0 && tipodedespesa?.label != "Outros" && tipodedespesa.value != 7 ? (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Gasto com</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={tipoGasto != null ? tipoGasto.value : null}
                                defaultValue={0}
                                label="Categoria"

                            >
                                {tipodedespesa?.sub?.map((e) => (
                                    <MenuItem value={e.iddespesa_subtipo} onClick={() => handleChangeGasto({ label: e.name, value: e.iddespesa_subtipo })}>{e.name}</MenuItem>
                                ))}
                                <MenuItem value={0} onClick={() => handleChangeGasto({ label: "Outros", value: 0 })}>Outros</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                ) : (
                    null
                )}
                <Grid item xs={6} style={{ paddingRight: "10px" }}>

                    <TextField
                        fullWidth
                        id="outlined-number"
                        label="Total gasto"
                        // value={totalgasto}
                        defaultValue={despesa.total}
                        onChange={handleValorChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {getCurrencySymbol(despesa.tipo_moeda)}
                                </InputAdornment>
                            ),
                            inputProps: {
                                inputMode: 'numeric', // Orienta o uso de teclado numérico
                                style: { fontSize: '18px' }
                            }
                        }}
                    />

                    {/* <TextField
                        fullWidth
                        id="outlined-number"
                        label="Total gasto"
                        defaultValue={despesa.total}
                        onChange={(e) => setTotalgasto(e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: 0
                        }}
                    /> */}

                </Grid>
                <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                    <UtaliiDatePickerNormal
                        value={datadecompra}
                        onChange={setDatadecompra}
                        label="Data de Compra"
                    // Você pode adicionar outras propriedades conforme necessário
                    />
                </Grid>
            </Grid>
            {tipodedespesa != null ? (
                <>
                    <Grid container item xs={6} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                        <Grid item xs={6} style={{}}>
                            <Button
                                onClick={() => setType(0)}
                                startIcon={<ArrowBackIosNewIcon />}
                                color="primary"
                            >
                                Voltar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "right" }}>
                        <Button variant="contained" color="primary" autoFocus onClick={() => handleCreateDespesa()}>
                            Editar despesa
                        </Button>
                    </Grid>
                </>
            ) : (
                null
            )}
        </Grid>
    )
}