import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Slide, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmIssueDialog = (props) => {
    const open = props.open;
    const booking = props.booking;
    const responsive = props.responsive;
    const classes = useStyles();

    return (
        <>
            <Dialog open={open} onClose={() => props.handleClickCloseConfirmDialog()} fullWidth maxWidth="xs" fullScreen={responsive} TransitionComponent={Transition}>
                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography>Confirmação</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText>
                        <Typography>Deseja realmente efetuar a emissão?</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'outlined'} disabled={props.isLoading} className={classes.buttonNegar} onClick={() => props.handleClickCloseConfirmDialog()}>Não</Button>
                    <Button variant={'outlined'} disabled={props.isLoading} className={classes.buttonAutorizar} onClick={() => props.yesEvent()}>Sim</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
