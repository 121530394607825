import React from "react";
import { useState } from "react";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Grow, List, ListItem, Paper, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Doughnut, Pie } from "react-chartjs-2";
import { Chart } from "chart.js";
import { useHistory } from 'react-router';
import { useStyles } from "./styles";

const EconomiaTotalDesktop = (props) => {
    const [showGrow, setShowGrow] = useState(true);
    const history = useHistory();
    const classes = useStyles();
    const options = props.options
    const teste = props.data

    return (
        <>
            {options.length <= 0 ? null : (
                <Grid item container xs={12}>
                    <Grid item container xs={12} className={classes.gridCard}>
                        <Grow in={showGrow} timeout={1000}>
                            <div className={classes.cards}>
                                <List className={classes.listCard}>
                                    <ListItem>
                                        <Card className={classes.card}>
                                            <CardMedia
                                                style={{
                                                    height: 30,
                                                    minHeight: 30,
                                                    background: "#2196f3",
                                                }}
                                            />
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="h2"
                                                    style={{
                                                        color: "#2196f3",
                                                    }}
                                                >
                                                    <b> Reduzido </b>
                                                    {teste.economyIssued.economy != 0 ? (teste.reduction.reductioned / teste.reduction.original * 100).toFixed(2).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 }) + "%" : null}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    <Typography className={classes.typography_G} variant="h6">
                                                        {teste.reduction.reductioned.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                    Total de reduções sobre as tarifas
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </ListItem>
                                    <ListItem>
                                        <Card className={classes.card}>
                                            <CardMedia
                                                style={{
                                                    height: 30,
                                                    minHeight: 30,
                                                    background: "#43a047",
                                                }}
                                            />
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="h2"
                                                    style={{
                                                        color: "#43a047",
                                                    }}
                                                >
                                                    <b> Economia </b>
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    <Typography className={classes.typography_G} variant="h6">
                                                        {(teste.reduction.reductioned + teste.economyIssued.economy).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                    Total economizado com reduções e na economia emissão IA
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </ListItem>
                                    <ListItem>
                                        <Card className={classes.card}>
                                            <CardMedia
                                                style={{
                                                    height: 30,
                                                    minHeight: 30,
                                                    background: "#ffa000",
                                                }}
                                            />
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h5"
                                                    component="h2"
                                                    style={{
                                                        color: "#ffa000",
                                                    }}
                                                >
                                                    <b>Economia Emissão IA </b>
                                                    {teste.economyIssued.economy != 0 ? (teste.economyIssued.economy / teste.economyIssued.final * 100).toFixed(2).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 2 }) + "%" : null}

                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                >
                                                    <Typography className={classes.typography_G} variant="h6">{teste.economyIssued.economy.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                    Total economizado pela emissão com IA
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </ListItem>
                                </List>
                            </div>
                        </Grow>
                    </Grid>
                    {(teste.reduction.reductioned + teste.economyIssued.economy) != 0 ? (
                        <>
                            <Grid container item xs={12} style={{ padding: "15px" }}>
                                <Grid item xs={6} style={{ padding: "10px" }}>
                                    <Grid container item xs={12} style={{
                                        boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)',
                                        padding: "15px",
                                        borderRadius: "1px",
                                    }}>
                                        <Grid container item xs={12} className={classes.gridCard} style={{ padding: "6px", display: "flex" }}>
                                            <Grid item xs={5} style={{ justifyContent: "left", display: "flex" }}>
                                                <Typography variant="h5" >Reduções IA</Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ justifyContent: "right", display: "flex" }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    endIcon={<ArrowForwardIosIcon></ArrowForwardIosIcon>}
                                                    onClick={() => history.push('/listaMenorTarifa')}
                                                >
                                                    Ver detalhes
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none" }}></hr>
                                        <Grid item xs={6} className={classes.chartGrid} style={{ justifyContent: "left", padding: "10px" }}>
                                            <Pie data={options[0]} style={{ maxHeight: '20rem' }} />
                                        </Grid>
                                        <Grid item xs={6} className={classes.gridCard} style={{ marginTop: "20px" }}>
                                            <Grid container style={{ textAlign: "left", marginTop: "40px" }}>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "18px", fontWeight: "500" }}><span className={classes.dot_O} /> Total Inicial: </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "20px", fontWeight: "600" }}>{teste.reduction.original.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "18px", fontWeight: "500" }}><span className={classes.dot_P} /> Total Emitido:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "20px", fontWeight: "600" }}>{teste.reduction.issued.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "18px", fontWeight: "500" }}><span className={classes.dot_G} /> Total Reduzido:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "20px", fontWeight: "600" }}>{teste.reduction.reductioned.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} style={{ padding: "10px" }}>
                                    <Grid container item xs={12} style={{
                                        boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)',
                                        padding: "15px",
                                        borderRadius: "1px",
                                    }}>
                                        <Grid container item xs={12} className={classes.gridCard} style={{ padding: "6px", display: "flex" }}>
                                            <Grid item xs={6} style={{ justifyContent: "left", display: "flex" }}>
                                                <Typography variant="h5" >Economia na emissão</Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ justifyContent: "right", display: "flex" }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.button}
                                                    endIcon={<ArrowForwardIosIcon></ArrowForwardIosIcon>}
                                                    onClick={() => history.push('/listaEconomiaNaEmissao')}
                                                >
                                                    Ver detalhes
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none" }}></hr>
                                        <Grid item xs={6} className={classes.gridCard} style={{ marginTop: "20px" }}>
                                            <Grid container style={{ textAlign: "left", marginTop: "40px" }}>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "18px", fontWeight: "500" }}><span className={classes.dot_O} /> Total Final:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "20px", fontWeight: "600" }}>{teste.economyIssued.final.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "18px", fontWeight: "500" }}><span className={classes.dot_P} /> Total Emitido: </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "20px", fontWeight: "600" }}>{teste.economyIssued.original.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "18px", fontWeight: "500" }}><span className={classes.dot_G} /> Total Economizado:</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography style={{ color: "#252525", fontSize: "20px", fontWeight: "600" }}>{teste.economyIssued.economy.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} className={classes.chartGrid} style={{ justifyContent: "left", padding: "10px" }}>
                                            <Doughnut is3d={true} data={options[1]} style={{ maxHeight: '20rem' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (null)}
                </Grid>
            )}
        </>
    )
}


export default EconomiaTotalDesktop;
