import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Badge, TextField } from '@mui/material';
import { ptBR } from 'date-fns/locale';
import { PickersDay } from '@mui/x-date-pickers';
import { useState } from 'react';
import dayjs from 'dayjs';

// ----------------------------------------------------------------------

// const isEventDate = (date, events) => events.some(event => AdapterDateFns.isSameDay(new Date(event), date));

export default function CustomDateRangePicker({
    title = 'Definir período de busca',
    variant = 'input',
    startDate,
    endDate,
    onChangeStartDate,
    onChangeEndDate,
    open,
    onClose,
    error,
    events,
    dialogActions
}) {
    const [highlightedDays, setHighlightedDays] = useState(events);
    const mdUp = 'up';
    const isCalendarView = variant === 'calendar';

    function ServerDay(props) {
        const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

        // Verifica se o dia atual deve ser destacado
        const isSelected = !outsideCurrentMonth && highlightedDays.some(highlightedDay =>
            dayjs(highlightedDay.datadecriacao).isSame(dayjs(day), 'day')
        );

        return (
            <Badge
                key={day.toString()}
                overlap="circular"
                badgeContent={isSelected ? '🧾' : undefined}
            >
                <PickersDay {...other} day={day} />
            </Badge>
        );
    }

    return (
        <Dialog
            fullWidth
            maxWidth={isCalendarView ? false : 'xs'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    ...(isCalendarView && {
                        maxWidth: 720,
                        borderRadius: "20px"
                    }),
                },
            }}
        >
            <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

            <DialogContent
                sx={{
                    ...(isCalendarView &&
                        mdUp && {
                        overflow: 'unset',
                    }),
                }}
            >
                <Stack
                    justifyContent="center"
                    spacing={isCalendarView ? 3 : 2}
                    direction={isCalendarView && mdUp ? 'row' : 'column'}
                    sx={{ pt: 1 }}
                >

                    {isCalendarView ? (
                        <>
                            {highlightedDays != null ? (
                                <>
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 2,
                                            borderColor: 'divider',
                                            borderStyle: 'dashed',
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <DateCalendar
                                                value={startDate}
                                                onChange={onChangeStartDate}
                                                slots={{
                                                    day: ServerDay,
                                                }}
                                                slotProps={{
                                                    day: {
                                                        highlightedDays,
                                                    },
                                                }} 
                                            />
                                        </LocalizationProvider>
                                    </Paper>
                                    <Paper
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 2,
                                            borderColor: 'divider',
                                            borderStyle: 'dashed',
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                            <DateCalendar
                                                value={endDate}
                                                onChange={onChangeEndDate}
                                                minDate={startDate}
                                                slots={{
                                                    day: ServerDay,
                                                }}
                                                slotProps={{
                                                    day: {
                                                        highlightedDays,
                                                    },
                                                }} />
                                        </LocalizationProvider>
                                    </Paper>

                                </>
                            ) : (
                                null
                            )}
                        </>
                    ) : (
                        <>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                <DatePicker
                                    label="Data inicial da viagem"
                                    value={startDate}
                                    onChange={onChangeStartDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    label="Data final da viagem"
                                    value={endDate}
                                    onChange={onChangeEndDate}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={startDate}
                                />
                            </LocalizationProvider>
                        </>
                    )}
                </Stack>

                {error && (
                    <FormHelperText error sx={{ px: 2 }}>
                        End date must be later than start date
                    </FormHelperText>
                )}
            </DialogContent>

            {dialogActions}
        </Dialog>
    );
}

CustomDateRangePicker.propTypes = {
    error: PropTypes.bool,
    onChangeEndDate: PropTypes.func,
    onChangeStartDate: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    variant: PropTypes.oneOf(['input', 'calendar']),
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
};
