import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {

    },
    content: {
        padding: 0,
        paddingTop: '0px !important',
        margin: 0,
        maxHeight: '100vh'
    },
    toolbar: {
        height: 200,
        background: '#ac58aa',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'start'
    },
    button: {
        background: '#ac58aa',
        color: 'white',
        '&:hover': {
            background: '#924091'
        }
    },
    media: {
        height: 250,
    },
}));