import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    appbar: {
        background: '#616161',
        position: 'relative',
        maxHeight: '48px',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    buttoncustom: {
        width: 150,
        marginRight: '15px',
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900]
        }
    },
    listItemTextcustom: {
        display:'grid',
        justifyContent: 'center',
        marginTop: '0px',
        marginBottom: '0px'
    },

    button: {
        color: '#fff'
    },
}));
