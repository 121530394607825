import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import { DoneAllOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import UCheckbox from '../../../components/UCheckbox';
import EnumStatus from '../../../enums/enumStatusBooking';
import pacoteAgenteRepositorio from '../../../repositorios/pacoteAgente';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogPacoteListAdd(props) {
    const classes = useStyles();
    const [data, setData] = useState({ idpacote: null, itens: [], titulo: null })
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);

    const handleCloseDialog = () => {
        props.handleClickOpenDialogPacoteListAdd()
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (idreserva) => selected.indexOf(idreserva) !== -1;

    const handleClick = (idreserva) => {
        const selectedIndex = selected.indexOf(idreserva);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, idreserva);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleClickConfirm = async () => {
        try {
            let dto = data.itens.reduce((acum, item) => {
                if (selected.includes(item.idpacoteitens)) {
                    acum.push({ idreservatenant: item.idreserva, idsolicitacaoproduto: item.idsolicitacaoproduto })
                }
                return acum
            }, [])
            props.setIsLoading(true)
            await pacoteAgenteRepositorio.putItensAddPacote(props.selectedIdPacote, dto);
            setSelected([])
            props.getData()
        } catch (e) {
            props.setSnackVariant('error');
            props.setSnackMessage('Erro ao adicionar itens.');
            props.setSnackVisible({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    const ajustaString = (assunto) => {
        if (!assunto) {
            return '';
        }
        const textos = assunto.split('<br />');
        return (
            <div>
                {textos.map(item => (
                    <div>{item}</div>
                ))}
            </div>
        );
    }

    useEffect(() => {
        setSelected([]);
        (async () => {
            try {
                props.setIsLoading(true)
                if (isNaN(props.selectedIdViajante) || props.selectedIdViajante <= 0) {
                    return
                }
                let response = await pacoteAgenteRepositorio.getListItemPacoteToAdd(props.selectedIdViajante);
                setData(response.data)
            } catch (e) {
                props.setSnackVariant('error');
                props.setSnackMessage('Erro ao pesquisar itens.');
                props.setSnackVisible({ visible: true });
            } finally {
                props.setIsLoading(false)
            }
        })()

    }, [props.selectedIdViajante, props.openDialogPacoteListAdd])

    return (
        <div>
            <Dialog
                onClose={() => handleCloseDialog()}
                open={props.openDialogPacoteListAdd}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xl'}
            >
                <DialogTitle style={{ background: '#C62828' }}>
                    <Typography style={{ color: '#ffffff' }}>Adicionar itens</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <TableContainer component={Paper} style={{ maxWidth: '92vw' }}>
                            <Table stickyHeader aria-label="sticky table" size={'small'} className={classes.tableCustom}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell hidden={true}></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Título</TableCell>
                                        <TableCell>Data Criação</TableCell>
                                        <TableCell>Origem</TableCell>
                                        <TableCell>Destino</TableCell>
                                        <TableCell>Data Ida</TableCell>
                                        <TableCell>Ida e Volta</TableCell>
                                        <TableCell>Tipo</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.itens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                        const isItemSelected = isSelected(row.idpacoteitens);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                key={row.idpacoteitens}
                                                role="checkbox"
                                                tabIndex={-1}
                                                aria-checked={isItemSelected}
                                                selected={isItemSelected}
                                                onClick={() => handleClick(row.idpacoteitens)}
                                            >
                                                <TableCell hidden={true} id={labelId}></TableCell>
                                                <TableCell padding="checkbox">
                                                    <UCheckbox
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.idreserva}</TableCell>
                                                <TableCell>
                                                    <Typography variant="caption"
                                                        style={{
                                                            width: 'fit-content',
                                                            borderStyle: 'solid',
                                                            borderWidth: 1,
                                                            borderColor: EnumStatus.getInfoBooking(row.cod_status_reserva, row.cod_status_etapaaprovacao).color,
                                                            background: EnumStatus.getInfoBooking(row.cod_status_reserva, row.cod_status_etapaaprovacao).color,
                                                            color: 'white', borderRadius: 3, padding: 5
                                                        }}
                                                    >
                                                        {
                                                            EnumStatus.getInfoBooking(row.cod_status_reserva, row.cod_status_etapaaprovacao).text
                                                        }
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{ajustaString(row.titulo)}</TableCell>
                                                <TableCell>{row.datacriacao}</TableCell>
                                                <TableCell>{row.origem}</TableCell>
                                                <TableCell>{row.destino}</TableCell>
                                                <TableCell>{row.dataida}</TableCell>
                                                <TableCell>{row.idavolta}</TableCell>
                                                <TableCell>{row.tipo}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                    <TablePagination
                        style={{ minWidth: 'max-content' }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.itens.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickConfirm} className={classes.button} endIcon={<DoneAllOutlined />}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default DialogPacoteListAdd;
