export function convertImageToBase64(file) {
  return new Promise((resolve, reject) => {
    // Cria uma instância de FileReader para ler o arquivo
    const reader = new FileReader();

    // Define o que fazer quando a leitura for concluída com sucesso
    reader.onload = () => {
      // A imagem convertida em base64 está agora em reader.result
      resolve(reader.result);
    };

    // Define o que fazer em caso de erro na leitura do arquivo
    reader.onerror = (error) => {
      reject(error);
    };

    // Inicia a leitura do arquivo como um URL de dados base64
    reader.readAsDataURL(file);
  });
}
