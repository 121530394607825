import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        minHeight: '400px'
    },
    button: {
        width: 200,
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900]
        }
    }
}));

