import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        background: 'whitesmoke',
        overflowX: 'auto',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: theme.spacing(3)
        },
    },
    addicon: {
        color: red[700]
    },
    table: {
        minWidth: 650
    },
    linha: {
        display: 'flex',
        flexDirection: 'row'
    },
    appBar: {
        background: 'indigo',
        padding: theme.spacing(2),
    },
    button: {
        color: 'white',
        background: red[700],
        '&:hover': {
            background: red[900]
        }
    },
    dialogActions: {
        background: '#d1d1d1'
    },
    dialogTitle: {
        background: '#d1d1d1',
        color: 'black',
        padding: '5px 16px'
    },
    container: {
        maxHeight: 440,
        minHeight: 440,
    },
    voos1: {
        paddingTop: theme.spacing(2),
    },
    inPaper: {
        padding: theme.spacing(0, 1)
    },
    inPaper2: {
        padding: theme.spacing(2)
    },
    data: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(0)
    },
    ta: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    datas: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: 30,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'silver'

    },
    logobutton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    buttonReserva: {
        borderStyle: 'solid',
        borderColor: '#c62828',
        borderWidth: '1px',
        color: '#c62828',
        background: 'transparent',
        '&:hover': {
            borderColor: '#c62828',
            color: 'white',
            background: '#c62828',
        }
    },
    bagagem: {
        display: 'flex',
        borderRadius: 30,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'silver',
        padding: theme.spacing(1),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    avatarbutton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dados: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dadosvoos: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    avatar: {
        width: '73%',
        margin: '4%',
        marginLeft: '4%'
    },
    buttonjunto: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',


    },
    buttonjunto1: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4%',

    },
    buttonjunto2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '4%',
    },
    voos: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button1: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    }



}))
