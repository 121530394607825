import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const organizacaoConfigRepositorio = {

    getListAeroportos: async (idtenant) => {
        let response = await api.get('/organizacaoConfig/getListAirports/' + idtenant, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    putRemoveAirport: async (dto) => {
        let response = await api.put('/organizacaoConfig/putRemoveAirport', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    putAddAirport: async (dto) => {
        let response = await api.put('/organizacaoConfig/putAddAirport', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },


}

export default organizacaoConfigRepositorio;
