import { FormControl, FormControlLabel, Switch, withStyles } from '@material-ui/core';
import React from 'react';

const ColorSwitch = withStyles({
    switchBase: {
        color: '#c62828',
        '&$checked': {
            color: '#b91119',
        },
        '&$checked + $track': {
            backgroundColor: '#b91119',
        },
    },
    checked: {},
    track: {},
})(Switch);

export const USwitch = props => {

    const handleChange = item => {
        props.setChecked(!item);
        if (props.onChange) {
            props.onChange(props.name, !item);
        }
    }

    return (
        <div>
            <FormControl row>
                <FormControlLabel
                    disabled={props.disabled}
                    control={
                        <ColorSwitch name={props.name} checked={props.checked} onChange={() => handleChange(props.checked)} />
                    }
                    label={props.label}
                >
                </FormControlLabel>
            </FormControl>
        </div>
    )
};

export default USwitch;
