import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React, { useState } from 'react';
import { useStyles } from './styles';

export const USpeedDial = props => {
    const classes = useStyles();
    const [hidden] = useState(true);

    const handleOpen = (action) => {
        props.setOpen(false);
        if (action.action) {
            action.action();
        }
    };

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div >
            <Backdrop onClick={handleClose} open={props.open} className={classes.backdrop} />
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={props.className ? props.className : classes.speedDial}
                hidden={hidden}
                onClick={() => props.setOpen(!props.open)}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={() => props.setOpen(true)}
                open={props.open}
            >
                {props.actions.map(action => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipPlacement={props.tooltipPlacement}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => handleOpen(action)}
                    />
                ))}
            </SpeedDial>
        </div>
    )
};

export default USpeedDial;
