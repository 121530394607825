import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },
    iconButton: {
        color: "white"
    },
    buttonSave: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },

}));
