import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const menorTarifaRepositorio = {

    postRelatorioMenorTarifaByTenant: async (dto) => {
        const response = await api.post('/Relatorio/PostRelatorioMenorTarifaByTenant', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            },
            timeout: 0
        })
        return response.data;
    },

    getHistoricoRelatorioMenorTarifa: async (idreserva) => {
        const response = await api.get('/Relatorio/GetHistoricoRelatorioMenorTarifa/' + idreserva, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response.data;
    },
}

export default menorTarifaRepositorio;
