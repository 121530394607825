import { AppBar, Box, Dialog, DialogContent, Divider, Grid, IconButton, Input, Slide, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AddCircleOutline, AssignmentTurnedInOutlined, Close, CloudUploadOutlined, DeleteOutlined, GetAppOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizedSnackbars from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import enviarArquivos from '../../../../repositorios/enviarArquivos';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddAttachmentsTicket = (props) => {
    const open = props.openAddAttchments;
    const data = props.itemSelectedAddAttchments;
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [listaItens, setListaItens] = useState([]);
    const [selecionado, setSelecionado] = useState(null)
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');
    const [file, setFile] = useState(null);
    const [existPDF, setExistPDF] = useState(false);
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    // const [fileName, setFileName] = useState(null);
    const [startValue, setStartValue] = useState(data);
    const [listaItensVinculados, setListaItensVinculados] = useState([]);

    const loadData = useCallback(async () => {
        try {
            setIsLoading(true)
            let valorInicial = data;
            let tamanhoObject = Object.values(valorInicial).length;
            let dto = null;
            if (tamanhoObject > 0) {
                let arrayTemp = [];
                let arrayTempVinculado = [];
                var response = null;
                try {
                    dto = {
                        idreference: startValue.idaereo,
                        tipo: 1,
                    }
                    response = await enviarArquivos.getAllVoucherPdf(dto);
                } catch (e) {
                    return;
                }
                if (response.data.length > 0) {
                    valorInicial.passageiros.forEach(item => {
                        let count = 0;
                        response.data.forEach(element => {
                            if (item.idaereopassageiro === element.idreference) { count++; }
                        })
                        if (count === 0) {
                            arrayTemp = [...arrayTemp, { label: item.passageiro.nome + ' ' + item.passageiro.sobrenome, value: item.idaereopassageiro }]
                        } else {
                            arrayTempVinculado = [...arrayTempVinculado, { label: item.passageiro.nome + ' ' + item.passageiro.sobrenome, value: item.idaereopassageiro, }]
                        }
                    })
                } else {
                    valorInicial.passageiros.forEach(item => { arrayTemp = [...arrayTemp, { label: item.passageiro.nome + ' ' + item.passageiro.sobrenome, value: item.idaereopassageiro }] })
                }
                setListaItens(Object.values(arrayTemp))
                setListaItensVinculados(Object.values(arrayTempVinculado))
            }
        } catch {
            setSnackVariant('error');
            setSnackMessage('Falha ao carregar anexos.');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }, [data, startValue])

    const handleCloseDialog = () => {
        props.setOpenAddAttchments(false);
        setSelecionado(-1);
        setListaItensVinculados([]);
        setFile(null);
    }

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
            cb(null, reader.result)
        }
        reader.onerror = function (error) {
            cb(error, null)
        }
    }

    const onUploadFileChange = ({ target }) => {
        setIsLoading(true)
        try {
            fileToBase64(target.files[0], (err, result) => {
                try {
                    if (result) {
                        if (target.files[0].type === "application/pdf") {
                            setFile(result)
                            // setFileName(target.files[0])
                        } else {
                            setSnackVariant('error');
                            setSnackMessage('Formato de arquivo inválido, favor enviar no formato PDF.');
                            setSnackState({ visible: true });
                        }
                    }
                } catch (e) { return; }
            })
            setExistPDF(true)
            if (target.files < 1 || !target.validity.valid) { return }
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao converter PDF.');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickAdd = async () => {
        setIsLoading(true)
        if (selecionado === null || selecionado < 0) {
            setSnackVariant('error');
            setSnackMessage('Sem viajante selecionado.');
            setSnackState({ visible: true });
            setIsLoading(false)
            return;
        }
        if (file === null) {
            setSnackVariant('error');
            setSnackMessage('Sem PDF selecionado.');
            setSnackState({ visible: true });
            setIsLoading(false)
            return;
        }
        try {
            let dto = {
                base64File: file,
                idreference: selecionado.value,
                tipo: 1
            }
            let response = await enviarArquivos.addVoucherPDF(dto);
            setListaItens(listaItens.filter((item) => item.value !== response.data.idreference));
            let sempremelhor = listaItens.filter((item) => item.value === response.data.idreference);
            setListaItensVinculados([...listaItensVinculados, sempremelhor[0]]);
            setSelecionado(-1);
            setFile(null)
            setExistPDF(false)
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Falha ao vincular PDF.');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false);
        }
    }

    const handleClickDelete = async (item) => {
        setIsLoading(true);
        let dto = {
            idreference: item.value,
            tipo: 1
        }
        try {
            await enviarArquivos.deleteVoucherPDF(dto);
            let sempremelhor = listaItensVinculados.filter((index) => index.value === item.value);
            setListaItens([...listaItens, sempremelhor[0]]);
            setListaItensVinculados(listaItensVinculados.filter((index) => index.value !== item.value));
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao deletar o  PDF.');
            setSnackState({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickDownload = async (item) => {
        let dto = {
            idreference: item.value,
            tipo: 1,
        }
        try {
            let response = await enviarArquivos.getVoucherPdf(dto);
            window.open(response.data.absoluteUri, '_blank').focus();
        } catch (e) { return; }
    }

    useEffect(() => {
        setStartValue(data)
        loadData()
    }, [loadData, setStartValue, data])

    return (
        <>
            <Dialog open={open} onClose={handleCloseDialog} maxWidth={"sm"} fullWidth fullScreen={responsive} TransitionComponent={Transition}            >
                <ULoaderVoo isLoading={isLoading} />
                <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Typography>Anexos</Typography>
                        <IconButton onClick={handleCloseDialog}><Close className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={responsive ? { paddingLeft: '5px', paddingRight: '5px' } : null}>
                    <Box>
                        {permissaoDashboard.usuarioLinestur ? (
                            <Grid container className={classes.gridContainerCenter}>
                                <Grid item xs={responsive ? 8 : 10}>
                                    <USearchField itens={listaItens} value={selecionado} setValue={setSelecionado} label="Viajantes" />
                                </Grid>
                                <Grid item xs={responsive ? 2 : 1}>
                                    <Tooltip title="Adicionar PDF">
                                        <label htmlFor="icon-button-file">
                                            <Input style={{ display: 'none' }} accept="application/pdf" id="icon-button-file" name="filetobase64" type="file" onChange={onUploadFileChange} />
                                            <IconButton disabled={isLoading} style={{ color: '#c62828' }} aria-label="upload picture" component="span">
                                                {existPDF ? (<AssignmentTurnedInOutlined />) : (<CloudUploadOutlined />)}
                                            </IconButton>
                                        </label>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={responsive ? 2 : 1}>
                                    <Tooltip title="Vincular">
                                        <IconButton disabled={isLoading} style={{ color: '#c62828' }} onClick={() => handleClickAdd()}><AddCircleOutline /></IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        ) : null}
                        <Box style={{ height: '200px', marginTop: '15px' }}>
                            {listaItensVinculados?.map((item) => (
                                <>
                                    <Grid container style={{ alignItems: 'center' }}>
                                        <Grid item xs={responsive ? 8 : 10}>
                                            <Typography>{item.label}</Typography>
                                        </Grid>
                                        {permissaoDashboard.usuarioLinestur ? (
                                            <Grid item xs={responsive ? 2 : 1}>
                                                <IconButton onClick={() => handleClickDelete(item)} className={classes.iconButtonRed}><DeleteOutlined /></IconButton>
                                            </Grid>
                                        ) : null}
                                        <Grid item xs={responsive ? 2 : 1}>
                                            <IconButton onClick={() => handleClickDownload(item)} className={classes.iconButtonRed}><GetAppOutlined /></IconButton>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </>
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddAttachmentsTicket;
