import { AppBar, Button, CardActions, Dialog, DialogContent, Grid, Grow, IconButton, Paper, Toolbar, Typography } from "@material-ui/core";
import { CloseOutlined, SendOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import LinesTextField from "../../../../components/LinesTextField";
import LogoEmail from '../../../../images/linestur_homem_email.svg';
import reservaRepositorio from "../../../../repositorios/reserva";
import { useStyles } from "./styles";

export const SendEmail = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const classes = useStyles();
    const [dtoEmail, setDtoEmail] = useState({ email: "", cc: "", });

    const handleChanged = (event) => { setDtoEmail({ ...dtoEmail, [event.target.name]: event.target.value }) }

    const handleClickSendEmail = async () => {
        try {
            const validaEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

            let dto = {
                email: dtoEmail.email.toLowerCase(),
                cc: dtoEmail.cc.toLowerCase(),
                idreserva: props.bookingSelected.idreserva,
            };
            if (!dto.email || !validaEmail.test(dto.email)) {
                props.setSnackVariant("error");
                props.setSnackMessage("Por favor digite um e-mail válido");
                props.setSnackState({ visible: true });
                return;
            }

            if (!validaEmail.test(dto.cc) && dto.cc) {
                props.setSnackVariant("error");
                props.setSnackMessage("Por favor digite um e-mail - CC válido");
                props.setSnackState({ visible: true });
                return;
            }

            props.setIsLoading(true);

            const response = await reservaRepositorio.enviarEmailAereo(dto);

            if (response.status !== 204) {
                props.setIsLoading(false);
                props.setSnackVariant("error");
                props.setSnackMessage("Não foi possível enviar!");
                props.setSnackState({ visible: true });
                return;
            }

            props.setSnackVariant("success");
            props.setSnackMessage("Mensagem enviada!");
            props.setSnackState({ visible: true });
            props.setOpen(false)
        } catch (e) {
            props.setSnackVariant("error");
            props.setSnackMessage("Não foi possível enviar!");
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    };

    return (
        <>
            <Dialog open={open} onClose={() => props.setOpen(false)} fullWidth maxWidth="sm" fullScreen={responsive} TransitionComponent={Grow}>
                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography >Enviar por Email</Typography>
                        <IconButton onClick={() => props.setOpen(false)} ><CloseOutlined className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.gridCenterAll}>
                    <Paper className={classes.paperMain} elevation={responsive ? 0 : 5} style={{ margin: responsive ? 0 : '2rem' }}>
                        <Grid container item xs={12}>
                            <Grid item xs={12} >
                                <img className={classes.media} src={LogoEmail} alt="Logo do email" />
                            </Grid>
                            <CardActions style={{ width: '100%' }}>
                                <Grid item container xs={12} spacing={2}  >
                                    <Grid item xs={12} >
                                        <LinesTextField fullWidth name="email" value={dtoEmail.email} onChange={handleChanged} variant="outlined" margin="dense" label="E-mail" />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <LinesTextField fullWidth name="cc" value={dtoEmail.cc} onChange={handleChanged} variant="outlined" margin="dense" label="CC" />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button disabled={props.isLoading} fullWidth startIcon={<SendOutlined />} onClick={() => handleClickSendEmail()} className={classes.buttonSave}>Enviar</Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Grid>
                    </Paper>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={() => handleClickSave()} className={classes.buttonSave} startIcon={<SaveOutlined />} disabled={isLoading}>Salvar</Button>
                </DialogActions> */}
            </Dialog>
        </>
    )
}
