export const buscarAereo = (e) => {
    return {
        type: 'BUSCAR_AEREO',
        payload: e
    }
};

export const setVoosMultiTrecho = (e) => {
    return {
        type: 'SET_MULTI',
        payload: e
    }
}

export const buscarVoosIda = (e) => {
    return {
        type: 'BUSCAR_IDAS',
        payload: e
    }
};

export const buscarVoosVolta = (e) => {
    return {
        type: 'BUSCAR_VOLTAS',
        payload: e
    }
};
export const buscarVoosIdaComBagagem = (e) => {
    return {
        type: 'BUSCAR_IDAS_COM_BAGAGEM',
        payload: e
    }
};

export const buscarVoosVoltaComBagagem = (e) => {
    return {
        type: 'BUSCAR_VOLTAS_COM_BAGAGEM',
        payload: e
    }
};

export const setVooIda = (e) => {
    return {
        type: 'IDA_CHANGED',
        payload: e
    }
};
export const setPassageiro = (e) => {
    return {
        type: 'PASSAGEIRO_CHANGED',
        payload: e
    }
};

export const setVooVolta = (e) => {
    return {
        type: 'VOLTA_CHANGED',
        payload: e
    }
};