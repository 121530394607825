import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Slide, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const HotelNotConfirmedIssue = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const { setOpenDialogWarningNotConfirmed } = props

    const [progress, setProgress] = useState(0);
    const progressRef = useRef(null);

    useEffect(() => {
        if (progress === 100) {
            setOpenDialogWarningNotConfirmed(false)
            setProgress(0);
        }
    }, [progress, setOpenDialogWarningNotConfirmed]);

    const handleMouseDown = () => {
        progressRef.current = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 2 : prev)); // Incrementa o progresso
        }, 100); // 100ms * 50 = 5000ms = 5 segundos
    };

    const handleMouseUp = () => {
        clearInterval(progressRef.current);
        setProgress(0);
    };


    // const handleClose = () => {
    //     setOpen(false);
    // };


    return (
        <>
            <Dialog open={open} fullWidth fullScreen={responsive} maxWidth="sm" TransitionComponent={Transition} >
                <DialogTitle>
                    <Typography variant="h5">
                        <b>Aviso!</b>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        <b>
                            A emissão do hotel não foi confirmada, favor conferir diretamente no portal da Euro a disponibilidade do quarto!
                        </b>
                    </Typography>
                    <Typography variant="body2">
                        Para fechar o aviso, é necessário manter o botão 'Entendi' pressionado durante 5 (cinco) segundos.
                    </Typography>
                    <LinearProgress variant="determinate" value={progress} style={{ color: "#c62828", width: '100%', marginTop: '10px' }} />
                </DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                        onTouchStart={handleMouseDown}
                        onTouchEnd={handleMouseUp}
                        variant="contained"
                        style={{ background: "#c62828", color: "#ffffff" }}
                    >
                        Entendi
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}
