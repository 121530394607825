import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    tableCustom: {
        minWidth: 650,
    },
    divCustom: {
        maxHeight: '50vh',
        background: '#c62828'
    }
}));
