import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },
    iconButton: {
        color: "white"
    },
    buttonSave: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },
    media: {
        height: '15rem'
    },
    gridCenterAll: {
        textAlign: 'center',
        placeContent: 'center',
        padding: '2px 6px'
    },
    paperMain: {
        // padding: 5,
        minHeight: 300,
        // margin: '2rem'
    }
}));
