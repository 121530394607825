import { Accordion, AccordionDetails, AccordionSummary, AppBar, Dialog, DialogContent, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import LogoVideo from '../../images/logo128.png';
import { useStyles } from './styles';

export const UTutorial = props => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (_event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            fullWidth
            maxWidth="lg"
            fullScreen={responsive}
        >
            <AppBar position="relative">
                <Toolbar className={classes.toolbar}>
                    <Typography>Ajuda</Typography>
                    <IconButton onClick={() => props.setOpen(false)}>
                        <Close style={{ color: '#fff' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <React.Fragment>
                    {responsive ? (
                        <Grid container item xs={12} spacing={2} style={{ margin: 0 }}>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Como reservar um voo</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594819/tutoriais/Mobile/Como_Reservar_Aereo_-_zvhfqs.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel11bh-content"
                                        id="panel11bh-header"
                                    >
                                        <Typography className={classes.heading}>Como fazer uma cotação</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594819/tutoriais/Mobile/Como_Cotar_Aereo_-_vkhz1t.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography className={classes.heading}>Como criar uma solicitação</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594818/tutoriais/Mobile/Como_Criar_Solicitacao_-_rhipxo.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography className={classes.heading}>Como Extrair relatório de divergência</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594805/tutoriais/Mobile/Como_Extrair_Relatorio_Antecedencia_-_k0fgbb.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5bh-content"
                                        id="panel5bh-header"
                                    >
                                        <Typography className={classes.heading}>Como extrair relatório de menor tarifa</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594793/tutoriais/Mobile/Como_Extrair_Relatorio_Menor_Tarifa_-_fyofk1.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography className={classes.heading}>Como Cancelar uma Reserva</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594784/tutoriais/Mobile/Como_Cancelar_Reserva_-_pkgovp.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6bh-content"
                                        id="panel6bh-header"
                                    >
                                        <Typography className={classes.heading}>Como ver o histórico da reserva</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594804/tutoriais/Mobile/Como_Ver_Historico_-_e6urnx.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7bh-content"
                                        id="panel7bh-header"
                                    >
                                        <Typography className={classes.heading}>Enviar reserva por e-mail</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594818/tutoriais/Mobile/Enviar_Aereo_Email_-_atx0fo.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8bh-content"
                                        id="panel8bh-header"
                                    >
                                        <Typography className={classes.heading}>Autorizar reserva</Typography>
                                        <Typography className={classes.secondaryHeading}>Dependendo do seu nível de permissão</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594781/tutoriais/Mobile/Como_Autorizar_Reserva_-_ktuazi.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9bh-content"
                                        id="panel9bh-header"
                                    >
                                        <Typography className={classes.heading}>Negar reserva</Typography>
                                        <Typography className={classes.secondaryHeading}>Dependendo do seu nível de permissão</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594795/tutoriais/Mobile/Como_Negar_Reserva_-_pz1s4i.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10bh-content"
                                        id="panel10bh-header"
                                    >
                                        <Typography className={classes.heading}>Alterar organização atual</Typography>
                                        <Typography className={classes.secondaryHeading}>Dependendo da quantidade de organizações vinculadas</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video poster={LogoVideo} className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602594777/tutoriais/Mobile/Como_Alterar_Organiza%C3%A7%C3%A3o_-_xyou3x.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>

                    ) : (
                        <Grid container spacing={2} item xs={12} style={{ minHeight: 600, maxHeight: 600, margin: 0 }}>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography className={classes.heading}>Como reservar um voo</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087069/tutoriais/Desktop/Como_Reservar_Um_Voo_-_w2oyiw.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel11bh-content"
                                        id="panel11bh-header"
                                    >
                                        <Typography className={classes.heading}>Como fazer uma cotação</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087041/tutoriais/Desktop/Como_Cotar_Um_Aereo_-_y97v6k.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography className={classes.heading}>Como criar uma solicitação</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087068/tutoriais/Desktop/Como_Criar_Uma_Solicitacao_-_cmqnfg.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography className={classes.heading}>Como Extrair relatório de divergência</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087061/tutoriais/Desktop/Como_Extrair_Relatorio_Tempo_De_Antecedencia_-_lrgz3a.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5bh-content"
                                        id="panel5bh-header"
                                    >
                                        <Typography className={classes.heading}>Como extrair relatório de menor tarifa</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087034/tutoriais/Desktop/Como_Extrair_Relatorio_Menor_Tarifa_-_nv92mv.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4bh-content"
                                        id="panel4bh-header"
                                    >
                                        <Typography className={classes.heading}>Como Cancelar uma Reserva</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087022/tutoriais/Desktop/Como_Cancelar_Uma_Reserva_-_i02soz.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6bh-content"
                                        id="panel6bh-header"
                                    >
                                        <Typography className={classes.heading}>Como ver o histórico da reserva</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087054/tutoriais/Desktop/Como_Ver_Historico_Reserva_-_pr86fc.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7bh-content"
                                        id="panel7bh-header"
                                    >
                                        <Typography className={classes.heading}>Enviar reserva por e-mail</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087036/tutoriais/Desktop/Como_Enviar_Reserva_Por_Email_-_is4mrk.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8bh-content"
                                        id="panel8bh-header"
                                    >
                                        <Typography className={classes.heading}>Autorizar reserva</Typography>
                                        <Typography className={classes.secondaryHeading}>Dependendo do seu nível de permissão</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087014/tutoriais/Desktop/Como_Autorizar_Uma_Reserva_-_uljwwz.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9bh-content"
                                        id="panel9bh-header"
                                    >
                                        <Typography className={classes.heading}>Negar reserva</Typography>
                                        <Typography className={classes.secondaryHeading}>Dependendo do seu nível de permissão</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602087042/tutoriais/Desktop/Como_Negar_Uma_Reserva_-_ciq7tc.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={12}>
                                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10bh-content"
                                        id="panel10bh-header"
                                    >
                                        <Typography className={classes.heading}>Alterar organização atual</Typography>
                                        <Typography className={classes.secondaryHeading}>Dependendo da quantidade de organizações vinculadas</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <video className={classes.video} controls controlsList="nodownload">
                                            <source src="https://res.cloudinary.com/dwkxt4am5/video/upload/v1602593657/tutoriais/Desktop/Como_Alterar_Organizacao_-_fyd7ci.mp4" type="video/mp4" />
                                            Seu navegador Não suporta o vídeo
                                        </video>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    )}
                </React.Fragment>
            </DialogContent>
        </Dialog>
    )
};

export default UTutorial;
