import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    buttonAction: {
        background: '#ffffff',
        color: '#c62828',
        minWidth: '1rem',
        '&:hover': {
            background: '#c62828',
            color: '#ffffff ',
        }
    },

    buttonAproveAll: {
        borderSpacing: '0px',
        margin: '10px',
        borderRadius: 20,
        background: '#c62828',
        color: '#ffffff',
        '&:hover': {
            background: red[900]
        },
        whiteSpace: 'nowrap'
    },

    gridMainBar: {
        padding: '0.5rem',
        display: 'flex',
        alignItems: 'center',
    },

    mainBar: {
        background: '#d1d1d1'
    },

    root: {
        overflowX: 'hidden',
        overflowY: 'auto',
        width: '100%',
        // marginBottom: theme.spacing(5),
        margin: 0
    },

    syncButton: {
        background: '#d1d1d1',
        boxShadow: '5px 5px 10px #b2b2b2, -5px -5px 10px #f0f0f0',
        color: 'grey'
    },

    tableCellCustom01: {
        borderWidth: 0,
        cursor: 'pointer'
    },

    tableCellCustom02: {
        borderWidth: 0,
    },

    tableContainerCustom: {
        maxHeight: 'calc(100vh-138px)',
        height: 'calc(100vh - 138px)'
    },
    imagem: {
        textAlign: "center"
    }

}));
