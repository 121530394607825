import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles(() => ({

    dialogTitle: {
        backgroundColor: '#c62828',
        color: '#ffffff',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    typographyTitle: {
        fontSize: '12pt',
    },
    typographyInfo: {
        marginTop: '5px',
        fontSize: '16pt',
        textAlign: 'center',
    },
    dialogContent: {
        spacing: '4',
        padding: '0',
        backgroundColor: '#e6e6e6',
    },
    gridContainer: {
        alignItems: 'center',
    },

    container: {
        padding: '0px',
        maxWidth: '100%',
        justifyContent: 'center',
        alignItens: 'center',
    },
    embed: {
        width: '-webkit-fill-available',
        minHeight: 'inherit',
    },
    dialogActions: {
        backgroundColor: '#c62828',
        color: '#ffffff',
        paddingBottom: '3px',
        paddingTop: '3px',
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    button: {
        border: 'solid 1px #ffffff',
        color: '#ffffff',
        marginLeft: '10px',
        background: 'transparent',
        '&:hover': {
            color: '#c62828',
            background: 'white'
        }
    },
    appBarCuston: {
        minHeight: '64px',
    },
    toolbarCustom: {
        minHeight: '64px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#c62828'
    },
    buttonExit: {
        color: '#ffffff',
    },
    boxCheck: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-start'
    },
    boxButtonsConcordarDesk: {
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'flex-end',
    },
    boxButtonsConcordarMobi: {
        display: 'flex',
        width: '-webkit-fill-available',
        justifyContent: 'center',
    },
    check: {
        color: '#ffffff',
        '&.Mui-checked': {
            color: '#ffffff',
        },
        '& .MuiSvgIcon-root': {
            fontSize: 28
        }
    },
    modal: {
        width: 400,
        height: 200,
    },
    iconPDF: {
        fontSize: 70,
        color: '#ffffff'
    },
    iconBackground: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#c62828',
        '&:hover': {
            backgroundColor: '#c62828',
        }
    },
    boxPDF: {
        width: '-webkit-fill-available',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    aCustom: {
        textDecoration: 'none',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridContainerLogout: {
        display: 'flex',
        justifyContent: 'right'
    }




}));
