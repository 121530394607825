import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
    },
    dialogTitle: {
        background: '#616161',
        color: 'white',
        padding: theme.spacing(0, 2)
    },
    button: {
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#b91119'
        }
    }
}));
