import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {

    },
    iconCustom_G: {
        color: '#43B947',
    },
    iconCustom_R: {
        color: '#E50008',
    },
    iconCustom_B: {
        color: '#41A5D1',
    }

}));
