import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookie from 'universal-cookie';
import LinesTextField from '../../../../components/LinesTextField';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import EnumCompany from '../../../../enums/enumFlightCompany';
import aereoRepositorio from '../../../../repositorios/aereo';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import usuarioRepositorio from '../../../../repositorios/usuario';
import { useStyles } from './styles';

export const ImportarBilhete = props => {
    const classes = useStyles();
    const theme = useTheme();
    const cookies = new Cookie();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const variadosReduxTipoPesquisa = useSelector(state => state.variados.aereosSearch);
    const [isLoading, setIsLoading] = useState(false);
    const [validacao, setValidacao] = useState({ visible: false });
    const [mensagemValidacao, setMensagemValidacao] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('error');
    const [passageiros, setPassageiros] = useState([])
    const [centrosCustos, setCentrosCustos] = useState([])
    const [valuecentroCusto, setValueCentroCusto] = useState([])
    const [valuePassageiros, setValuePassageiros] = useState([])
    const [solicitantes, setSolicitantes] = useState([]);
    const [solicitante, setSolicitante] = useState({});
    const [dtoBilhete, setDtoBilhete] = useState({});
    const [gdsValue, setGdsValue] = useState(null);
    const [apiValue, setApiValue] = useState(null);
    const [stateGDS, setStateGDS] = useState({ gds: '', });
    const [stateApi, setStateApi] = useState({ API: 0, });
    const [aereo, setAereo] = useState({
        voos: [],
        passageiros: [],
        valores: [],
        bilhetes: [],
        trechos: []
    });

    const listaGDS = [
        {
            label: 'Amadeus (AMD)',
            value: 'AMD',
        },
        {
            label: 'Azul (AD)',
            value: 'AD',
        },
        {
            label: 'Galileo (GLL)',
            value: 'GLL',
        },
        {
            label: 'GOL (GOL)',
            value: 'SGOL',
        },
        {
            label: 'Latam (TAM)',
            value: 'LA',
        },
        {
            label: 'Sabre (SAB)',
            value: 'SAB',
        },
        {
            label: 'Sabre (SAB)',
            value: 'SAB',
        },
        {
            label: 'NAMD',
            value: 'NAMD',
        }
    ];

    const listApi = [
        {
            label: 'Reserva Fácil',
            value: 1,
        },
        {
            label: 'Flytour',
            value: 2,
        },
    ];

    const onClickBuscarReserva = async () => {
        try {
            setIsLoading(true);
            let dto = { ...dtoBilhete, api: apiValue.value, };
            if (variadosReduxTipoPesquisa.tipoPesquisaAct === 'internacional') {
                dto = { ...dtoBilhete, cia: gdsValue.value, api: apiValue.value, }
            };
            const response = await aereoRepositorio.getBilhete(dto);
            if (response.data.variant !== "error") { setAereo(response.data); };
            setSnackbarVariant(response.data.variant);
            setMensagemValidacao(response.data.message);
            setValidacao({ visible: true });
        } catch (e) {
            setSnackbarVariant('error');
            setMensagemValidacao('Não foi possível consultar favor verificar dados!');
            setValidacao({ visible: true });
        } finally {
            setIsLoading(false);
        }
    };

    const onClickConcluirImportacao = async () => {
        try {
            const listaPassageiro = valuePassageiros.map((item, index) => {
                return {
                    ...aereo.passageiros[index],
                    idpassageiro: item ? item.value : 0,
                    idcentrocusto: valuecentroCusto[index] ? valuecentroCusto[index].value : 0
                }
            })

            const dto = {
                ...aereo,
                passageiros: listaPassageiro,
                sessionId: cookies.get('sessionId'),
                idsolicitante: solicitante ? solicitante.value : 0
            }
            if (valuePassageiros.length < aereo.passageiros.length) {
                setIsLoading(false);
                setSnackbarVariant('error');
                setMensagemValidacao('Favor preencher todos os passageiros!');
                setValidacao({ visible: true });
                return
            }
            if (valuecentroCusto.length < aereo.passageiros.length) {
                setIsLoading(false);
                setSnackbarVariant('error');
                setMensagemValidacao('Favor preencher todos os centros de custos!');
                setValidacao({ visible: true });
                return
            }
            setIsLoading(true);
            const response = await aereoRepositorio.importarBilhete(dto);
            if (response.status !== 204) {
                setIsLoading(false);
                setSnackbarVariant('error');
                setMensagemValidacao('Erro ao importar dados!');
                setValidacao({ visible: true });
                return
            }
            props.setOpen(false);
            setAereo({
                voos: [],
                passageiros: [],
                valores: [],
                bilhetes: [],
                trechos: []
            });
            setValuePassageiros([]);
            setValueCentroCusto([]);
            setIsLoading(false);
            setSnackbarVariant('success');
            setMensagemValidacao('Dados Importados com sucesso!');
            setValidacao({ visible: true });
        } catch (e) {
            setIsLoading(false);
            setSnackbarVariant('error');
            setMensagemValidacao('Erro ao importar dados!');
            setValidacao({ visible: true });
        }
    }

    const handleChanged = event => {
        setDtoBilhete({ ...dtoBilhete, [event.target.name]: String(event.target.value).toUpperCase() })
    }

    const handleChangeAuto = (campo, valor) => {
        switch (campo) {
            case 'GDS': setStateGDS({ ...stateGDS, [campo]: valor })
                break;
            case 'API': setStateApi({ ...stateApi, [campo]: valor })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        (async () => {
            const viajantes = await pessoaRepositorio.getSearchField();
            setPassageiros(viajantes);
            const centros = await centroCustoRepositorio.getSearchField();
            setCentrosCustos(centros);
            let listaUsuarios = await usuarioRepositorio.getSearchField();
            setSolicitantes(listaUsuarios);
        })()
    }, [])

    return (
        <React.Fragment>
            {!props.open ? (<Snackbar {...{ variant: snackbarVariant, message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />) : null}
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                fullWidth
                maxWidth="lg"
                fullScreen={responsive}
            >
                {props.open ? (<Snackbar {...{ variant: snackbarVariant, message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />) : null}
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative">
                    <Toolbar className={classes.toolbar}>
                        <Typography>Importar Bilhete</Typography>
                        <IconButton className={classes.icon} onClick={() => props.setOpen(false)}><Close /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12} container spacing={responsive ? 1 : 2} style={{ margin: 0 }}>
                            <Grid item xs={responsive ? 12 : 4} style={{ display: 'flex', alignItems: 'center' }}>
                                <USearchField name="API" onChange={handleChangeAuto} {...{ itens: listApi, value: apiValue, setValue: setApiValue, label: 'PORTAL' }} />
                            </Grid>
                            <Grid item xs={responsive ? 6 : 4} style={{ display: 'flex', alignItems: 'center' }} >
                                <LinesTextField
                                    onChange={handleChanged}
                                    value={dtoBilhete.localizador}
                                    name="localizador"
                                    fullWidth
                                    label="Localizador"
                                    margin="dense"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={responsive ? 6 : 4} style={{ display: 'flex', alignItems: 'center' }}>
                                {variadosReduxTipoPesquisa.tipoPesquisaAct === 'nacional' ? (
                                    <LinesTextField
                                        onChange={handleChanged}
                                        value={dtoBilhete.cia}
                                        name="cia"
                                        fullWidth
                                        label="Iata"
                                        margin="dense"
                                        variant="outlined"
                                    />
                                ) : (
                                    <USearchField name="GDS" onChange={handleChangeAuto} {...{ itens: listaGDS, value: gdsValue, setValue: setGdsValue, label: 'GDS' }} />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={responsive ? 1 : 2} style={{ margin: 0 }}>
                                <Grid item xs={responsive ? 8 : 10} style={{ display: 'flex', alignItems: 'center' }} >
                                    <USearchField itens={solicitantes} value={solicitante} setValue={setSolicitante} label="Solicitante" />
                                </Grid>
                                <Grid item xs={responsive ? 4 : 2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    <Button onClick={onClickBuscarReserva} className={classes.button}>Consultar</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {aereo.localizador ? responsive ? (
                            <Grid item xs={12} container style={{ margin: '5px 0px' }}>
                                <Paper elevation={1} style={{ width: '-webkit-fill-available' }}>
                                    <Grid item xs={12} className={classes.gridCenter}>
                                        <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>{EnumCompany.getLogoCompanhiaAereaEnum(aereo.cia).nome}</Typography>)}>
                                            <img width={'auto'} height={70} src={EnumCompany.getLogoCompanhiaAereaEnum(aereo.cia).url_low} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridCenter}>
                                        <Typography>{aereo.status}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridCenter}>
                                        <Typography variant='h5'><b>{aereo.localizador}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant='subtitle2'>Criação:</Typography>
                                        <Typography>{new Date(aereo.dataCriacao).toLocaleString()}</Typography>
                                    </Grid>
                                </Paper>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Paper elevation={5} style={{ padding: 10, margin: '5px' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Status</TableCell>
                                                    <TableCell align="center">Sistema</TableCell>
                                                    <TableCell align="center">Criação</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center">{aereo.localizador}</TableCell>
                                                    <TableCell align="center">{aereo.status}</TableCell>
                                                    <TableCell align="center">
                                                        <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>{EnumCompany.getLogoCompanhiaAereaEnum(aereo.cia).nome}</Typography>)}>
                                                                <img
                                                                    src={EnumCompany.getLogoCompanhiaAereaEnum(aereo.cia).url_low}
                                                                    width={80}
                                                                    height={80}
                                                                    style={{ height: '30px', width: '60px' }}
                                                                />
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center">{new Date(aereo.dataCriacao).toLocaleString()}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer >
                                </Paper >
                            </Grid >
                        ) : null}

                        {
                            aereo.passageiros.length > 0 ? responsive ? (
                                <Paper elevation={1} style={{ width: '-webkit-fill-available', margin: '5px 0px' }}>
                                    <Typography variant="h6" style={{ margin: '5px' }}><b>Passageiros</b></Typography>
                                    <List>
                                        {aereo.passageiros.map((pax, index) => (
                                            <>
                                                <ListItem>
                                                    <Grid container>
                                                        <Grid item xs={12} className={classes.gridCenter}>
                                                            <Typography variant={'h6'}>{`${pax.nome} ${pax.sobrenome}`}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                            <Typography variant={'subtitle2'}>Tipo:</Typography>
                                                            <Typography>{pax.tipo}</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                            <Typography variant={'subtitle2'}>Gênero:</Typography>
                                                            <Typography>{pax.sexo}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <USearchField
                                                                label={'Passageiro'}
                                                                itens={passageiros}
                                                                value={valuePassageiros[index]}
                                                                valueArray={valuePassageiros}
                                                                index={index}
                                                                setValueArray={setValuePassageiros}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <USearchField
                                                                label={'Centro de Custo'}
                                                                itens={centrosCustos}
                                                                value={valuecentroCusto[index]}
                                                                valueArray={valuecentroCusto}
                                                                index={index}
                                                                setValueArray={setValueCentroCusto}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                                <Divider variant="middle" />
                                            </>
                                        ))}
                                    </List>
                                </Paper>
                            ) : (
                                <Grid item xs={12}>
                                    <Paper elevation={10} style={{ padding: 10, margin: "5px" }}>
                                        <Typography variant="h6" style={{ margin: '5px' }}><b>Passageiros</b></Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Tipo</TableCell>
                                                        <TableCell align="center">Sobrenome</TableCell>
                                                        <TableCell align="center">Nome</TableCell>
                                                        <TableCell align="center">Gênero</TableCell>
                                                        <TableCell align="center">Passageiro</TableCell>
                                                        <TableCell align="center">Centro de Custo</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {aereo.passageiros.map((item, index) => (
                                                        <TableRow>
                                                            <TableCell align="center">{item.tipo}</TableCell>
                                                            <TableCell align="center">{item.sobrenome}</TableCell>
                                                            <TableCell align="center">{item.nome}</TableCell>
                                                            <TableCell align="center">{item.sexo}</TableCell>
                                                            <TableCell align="center">
                                                                <USearchField
                                                                    itens={passageiros}
                                                                    value={valuePassageiros[index]}
                                                                    valueArray={valuePassageiros}
                                                                    index={index}
                                                                    setValueArray={setValuePassageiros}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <USearchField
                                                                    itens={centrosCustos}
                                                                    value={valuecentroCusto[index]}
                                                                    valueArray={valuecentroCusto}
                                                                    index={index}
                                                                    setValueArray={setValueCentroCusto}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                            ) : null
                        }

                        {
                            aereo.voos.length > 0 ? responsive ? (
                                <>
                                    <Paper elevation={1} style={{ width: '-webkit-fill-available', margin: '5px 0px' }}>
                                        <Typography variant="h6" style={{ margin: '5px' }}><b>Voos</b></Typography>
                                        <List>
                                            {aereo.voos.map((item) => (
                                                <>
                                                    <ListItem>
                                                        <Grid container>
                                                            <Grid item xs={12} className={classes.gridCenter}>
                                                                <img width={'auto'} height={30} src={EnumCompany.getLogoCompanhiaAereaEnum(item.cia).url_low} />
                                                            </Grid>
                                                            <Grid item xs={12} className={classes.gridCenter}>
                                                                <Typography variant='h6'><b>{`${item.numero} (${aereo.localizador})`}</b></Typography>
                                                            </Grid>
                                                            <Grid item xs={12} container>
                                                                <Grid item xs={6} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }} >
                                                                    <Typography variant='subtitle2'>Origem:</Typography>
                                                                    <Typography variant='body1' style={{ textAlign: 'center' }}><div><b>{item.origem}</b></div><div>{new Date(item.datapartida).toLocaleString()}</div></Typography>
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                                    <Typography variant='subtitle2'>Destino:</Typography>
                                                                    <Typography variant='body1' style={{ textAlign: 'center' }}><div><b>{item.destino}</b></div><div>{new Date(item.datachegada).toLocaleString()}</div></Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} container>
                                                                <Grid item xs={3} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                                    <Typography variant='subtitle2'>Esc.:</Typography>
                                                                    <Typography variant='body1'>{item.esc}</Typography>
                                                                </Grid>
                                                                <Grid item xs={3} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                                    <Typography variant='subtitle2'>Cl.:</Typography>
                                                                    <Typography variant='body1'>{item.classe}</Typography>
                                                                </Grid>
                                                                <Grid item xs={6} className={classes.gridCenter} style={{ flexDirection: 'column', alignItems: 'center' }} >
                                                                    <Typography variant='subtitle2'>Info.:</Typography>
                                                                    <Typography variant='body1'>{`${item.bagagem ? 'Com Bagagem(' : 'Sem Bagagem('}${item.descricaoBagagem})`}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                    <Divider variant="middle" />
                                                </>
                                            ))}
                                        </List>
                                    </Paper >
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <Paper elevation={5} style={{ padding: 10, margin: "5px" }}>
                                        <Typography variant="h6" style={{ margin: '5px' }}> <b>Voos</b></Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">CIA</TableCell>
                                                        <TableCell align="center">Voo</TableCell>
                                                        <TableCell align="center">Origem</TableCell>
                                                        <TableCell align="center">Destino</TableCell>
                                                        <TableCell align="center">Esc.</TableCell>
                                                        <TableCell align="center">Cl.</TableCell>
                                                        <TableCell align="center">Info</TableCell>
                                                        <TableCell align="center">Loc Cia</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {aereo.voos.map(item => (
                                                        <TableRow>
                                                            <TableCell align="center">
                                                                <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>{EnumCompany.getLogoCompanhiaAereaEnum(item.cia).nome}</Typography>)}>
                                                                    <img
                                                                        src={EnumCompany.getLogoCompanhiaAereaEnum(item.cia).url_low}
                                                                        width={80}
                                                                        height={80}
                                                                        style={{ margin: '5px', height: '12px', width: '60px' }}
                                                                    />
                                                                </Tooltip>
                                                            </TableCell>
                                                            <TableCell align="center">{item.numero}</TableCell>
                                                            <TableCell align="center"><div>{item.origem}</div><div>{new Date(item.datapartida).toLocaleString()}</div></TableCell>
                                                            <TableCell align="center"><div>{item.destino}</div><div>{new Date(item.datachegada).toLocaleString()}</div></TableCell>
                                                            <TableCell align="center">{item.esc}</TableCell>
                                                            <TableCell align="center">{item.classe}</TableCell>
                                                            <TableCell align="center">{`${item.bagagem ? 'Com Bagagem(' : 'Sem Bagagem('}${item.descricaoBagagem})`}</TableCell>
                                                            <TableCell align="center">{aereo.localizador}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                            ) : null
                        }

                        {
                            aereo.valores.length > 0 ? (
                                <Grid item xs={12}>
                                    <Paper elevation={responsive ? 1 : 5} style={{ padding: responsive ? 0 : 10, margin: responsive ? '5px 0px' : "5px" }}>
                                        <Typography variant="h6" style={{ margin: '5px' }}><b>Valores</b></Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Passageiro</TableCell>
                                                        <TableCell align="center">Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {aereo.valores.map(item => (
                                                        <TableRow>
                                                            <TableCell align="center">{item.passageiro}</TableCell>
                                                            <TableCell align="center">R$ {(Number(item.total)).toFormatDefault()}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                            ) : null
                        }

                        {
                            aereo.bilhetes.length > 0 ? (
                                <Grid item xs={12}>
                                    <Paper elevation={responsive ? 1 : 5} style={{ padding: responsive ? 0 : 10, margin: responsive ? '5px 0px' : '5px' }}>
                                        <Typography variant="h6" style={{ margin: '5px' }}><b>Bilhetes</b></Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Número</TableCell>
                                                        <TableCell align="center">Passageiro</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {aereo.bilhetes.map(item => (
                                                        <TableRow>
                                                            <TableCell align="center">{item.numero}</TableCell>
                                                            <TableCell align="center">{`${item.sobrenomePassageiro}/${item.nomePassageiro}`}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </Grid>
                            ) : null
                        }
                    </Grid >
                </DialogContent >
                {!aereo.localizador ? (null) : (
                    <DialogActions>
                        <Button disabled={!aereo.localizador} onClick={onClickConcluirImportacao} className={classes.button}>Concluir Importação</Button>
                    </DialogActions>
                )}
            </Dialog >
        </React.Fragment >
    )
};

export default ImportarBilhete;
