import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const viagemPoliticaDocRepositorio = {

    getLinkDocTenant: async () => {
        const response = await api.get('/politicaViagemDoc/GetLinkDocTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getStatusConfirmed: async () => {
        const response = await api.get('/politicaViagemDoc/GetStatusConfirmed', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response.data;
    },

    getExistPoliticaDoc: async () => {
        const response = await api.get('/politicaViagemDoc/GetExistPoliticaDoc', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response.data;
    },

    putConfirmPoliticaViagem: async () => {
        const response = await api.put('/politicaViagemDoc/PutConfirmPoliticaViagem', {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },
}

export default viagemPoliticaDocRepositorio;
