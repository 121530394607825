import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },
    iconButton: {
        color: "#ffffff"
    },
    buttonAutorizar: {
        borderStyle: 'solid',
        borderWidth: 1,
        background: 'darkgreen',
        color: '#fafafa',
        '&:hover': {
            background: '#fafafa',
            color: 'darkgreen'
        }
    },
    buttonNegar: {
        borderStyle: 'solid',
        borderWidth: 1,
        background: '#c62828',
        color: '#fafafa',
        '&:hover': {
            background: '#fafafa',
            color: '#c62828'
        }
    },
}));
