const INITIAL_STATE = { aereos: {}, vooIda: {}, vooVolta: {}, voosIda: [], voosVolta: [], voosIdaComBagagem: [], voosVoltaComBagagem: [], passageiro: {}, voosMulti: [] }
//obs nunca colocar async para essa função se não da pau
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'BUSCAR_AEREO':

            return {
                ...state,
                aereos: action.payload
            }

        case 'SET_MULTI':

            return {
                ...state,
                voosMulti: action.payload
            }

        case 'IDA_CHANGED':

            return {
                ...state,
                vooIda: action.payload
            }

        case 'PASSAGEIRO_CHANGED':

            return {
                ...state,
                passageiro: action.payload
            }

        case 'VOLTA_CHANGED':

            return {
                ...state,
                vooVolta: action.payload
            }

        case 'BUSCAR_IDAS':

            return {
                ...state,
                voosIda: action.payload
            }

        case 'BUSCAR_VOLTAS':

            return {
                ...state,
                voosVolta: action.payload
            }

        case 'BUSCAR_IDAS_COM_BAGAGEM':

            return {
                ...state,
                voosIdaComBagagem: action.payload
            }

        case 'BUSCAR_VOLTAS_COM_BAGAGEM':

            return {
                ...state,
                voosVoltaComBagagem: action.payload
            }

        default:
            return state;
    }
}