import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 0,
        width: '100%',
    },

    cadastro: {
        paddingTop: '0px'
    },

    linha: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },
    linhat: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },

    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
        height: 400
    },
    body2: {
        height: 400
    },
    divApp: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    divs: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textfield: {
       
    },
    form: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },
    textField2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        padding: '5px',
        width: '100%',
    },

    txt: {
        width: '90%',
    },
    txtfd: {
        width: '100%'
    },
    card: {
        display: 'flex',
        flexDirection: 'Row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
        width: '100%'
    },

    bigAvatar: {
        margin: 10,
        width: 150,
        height: 150,
    },
    bigAvatar2: {
        margin: 10,
        width: 100,
        height: 100,
    },

    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },

    button2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'right',
        marginLeft: 250,
        marginTop: 100,
    },

    coluna: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        width: '100%',
        marginRight: theme.spacing(3),
    },
    coluna2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        alignItems: 'center',
        marginRight: theme.spacing(3),
    },
    iconbutton: {
        right: '-23%',
        bottom: '20%',
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#810b11'
        }

    },
    obs: {
        width: 665
    },
    textfielde: {
        width: '100%',
    },
    widht480: {
    },
    escolhausuario: {
    }
}));
