import React, { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formHelperTextClasses } from '@mui/material/FormHelperText';
import { ImportContactsTwoTone, ImportExport } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CustomPopover, { usePopover } from '../../../../../components/custom-popover';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GetAppIcon from '@mui/icons-material/GetApp';
import { ptBR } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ExportExcel from '../../../../../components/UExportExcel';
import { formatarParaNomeStatusAdiantamentoString, formatarParaNomeStatusViagem, formatarParaNomeStatusViagemString, mapaNomesViagemStatus } from '../../../../../enums/enumPrestacaoDeContas';
import { Autocomplete, Box, Button, FormControl, InputLabel, OutlinedInput, Select, useTheme } from '@mui/material';
import centroCustoRepositorio from '../../../../../repositorios/centroCusto';
import pessoaRepositorio from '../../../../../repositorios/pessoa';
import usuarioRepositorio from '../../../../../repositorios/usuario';

// ----------------------------------------------------------------------

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function OrderTableToolbar({ data, filters, setFilters, handleClickSearch }) {
  const theme = useTheme();
  const [colunasExcel, setColunasExcel] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [centrodecusto, setcentrodecusto] = useState(0);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [pessoa, setPessoa] = useState({ label: "Todos", value: 0 });
  const [custo, setCusto] = useState(0);

  useLayoutEffect(() => {
    (async () => {
      const responsePessoas = await usuarioRepositorio.getAll();
      const response = await centroCustoRepositorio.getSearchField();
      // await getData();
      setCentrosCusto(response);
      const mappedPessoas = responsePessoas.data.map(pessoa => ({
        label: pessoa.pessoa.nome, // Assumindo que `pessoa.pessoa.nome` contém o nome que você deseja exibir
        value: pessoa.pessoa.idpessoa, // Assumindo que `pessoa.pessoa.idpessoa` é o valor único da pessoa
        ...pessoa.pessoa, // Mapeie outras propriedades se necessário
      }));
      
      setPessoas(mappedPessoas);
      // setPessoas(responsePessoas.data)
    })()
  }, [])

  const handleChangeCentrodecusto = (event) => {
    setcentrodecusto(event.value);
    setFilters((prevFilters) => ({ ...prevFilters, centrodecusto: event.value }));
  };

  // const handleChangePessoa = (event) => {
  //   setPessoa(event.value);
  //   setFilters((prevFilters) => ({ ...prevFilters, idpessoa: event.value }));
  // };

  const handleChangePessoa = (event, newValue) => {
    if (newValue) {
        setPessoa({ label: newValue.label, value: newValue.value });
    } else {
        // Se não há novo valor (caso o usuário apague a seleção), pode-se redefinir para o padrão
        setPessoa({ label: "Todos", value: 0 });
    }
};

  const popover = usePopover();

  const statusOptions = Object.entries(mapaNomesViagemStatus);

  const handleFilterName = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, name: value }));
  }

  const handleFilterStartDate = (newValue) => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate: newValue }));
  }

  const handleFilterEndDate = (newValue) => {
    const hasError = filters.startDate && newValue && newValue < filters.startDate;
    setDateError(hasError);
    if (!hasError) {
      setFilters((prevFilters) => ({ ...prevFilters, endDate: newValue }));
    }
  }

  const handleChange = (event) => {
    const { target: { value } } = event;
    setPersonName(
      typeof value === 'string' ? value.split(',') : value,
    );
    setFilters((prevFilters) => ({ ...prevFilters, statusFilter: typeof value === 'string' ? value.split(',') : value }));
  };

  // useEffect(() => {
  //   setColunasExcel([
  //     "ID",
  //     "Pessoa",
  //     "Email",
  //     "Titulo",
  //     "Data de criação",
  //     "Centro de custo",
  //     "Total inicial",
  //     "Adiantamento",
  //     "Total",
  //     "Status",
  //     "Data inicio",
  //     "Data final",
  //   ]);
  //   const datas = data.map((item) => {
  //     return [
  //       item.id,
  //       item.pessoa.nome,
  //       item.pessoa.email,
  //       item.titulo,
  //       // new Date(item.datadecriacao).toLocaleDateString(), // Formatando a data
  //       // item.centrodecusto,
  //       // new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.totalinicial),
  //       // formatarParaNomeStatusAdiantamentoString(item.adiantamento.status),
  //       // new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total),
  //       // formatarParaNomeStatusViagemString(item.status), 
  //       // new Date(item.datainicio).toLocaleDateString(), // Formatando a data
  //       // new Date(item.datafim).toLocaleDateString(), // Formatando a data
  //     ];
  //   });
  //   setDataExcel(datas);
  // }, [data]);

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <DatePicker
            label="Data inicial"
            value={filters.startDate}
            onChange={handleFilterStartDate}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
            sx={{
              maxWidth: { md: 200 },
            }}
          />

          <DatePicker
            label="Data final"
            value={filters.endDate}
            onChange={handleFilterEndDate}
            slotProps={{
              textField: {
                fullWidth: true,
                error: dateError,
                helperText: dateError && 'Data final não pode ser maior que a data inicial',
              },
            }}
            sx={{
              maxWidth: { md: 200 },
              [`& .${formHelperTextClasses.root}`]: {
                position: { md: 'absolute' },
                bottom: { md: -40 },
              },
            }}
          />
        </LocalizationProvider>

        <FormControl style={{ width: "50%" }}>
          <InputLabel id="demo-simple-select-label">Centro de custo</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={centrodecusto}
            defaultValue={0}
            label="Centro de custo"

          >
            <MenuItem value={0} onClick={() => handleChangeCentrodecusto({ label: "Todos", value: 0 })}>Todos</MenuItem>
            {centrosCusto?.map((e) => (
              <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
            ))} 
          </Select>
        </FormControl>


        {/* <FormControl sx={{ width: "50%" }}>
          <InputLabel id="demo-multiple-chip-label">Status Da Viagem</InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Status Da Viagem" />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: "inherit", gap: 0.5 }}>
                {selected.map((value) => (
                  <>
                    {formatarParaNomeStatusViagem(value)}
                  </>
                ))}
              </Box>
            )}
            MenuProps={{ PaperProps: { style: { maxHeight: 224, width: 250 } } }}
          >
            {statusOptions.map(([key, label]) => (
              <MenuItem
                key={key}
                value={key}
                style={getStyles(key, personName, theme)}
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          {/* <TextField
            fullWidth
            onChange={(e) => handleFilterName(e.target.value)}
            placeholder="Procurar viajante por nome..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          /> */}

          <FormControl fullWidth>
            <Autocomplete
              options={pessoas}
              getOptionLabel={(option) => option.label}
              value={pessoa}
              onChange={handleChangePessoa}
              renderInput={(params) => <TextField {...params} label="Pessoa" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            {/* <InputLabel id="demo-simple-select-label">Pessoa</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pessoa}
              defaultValue={0}
              label="Pessoa"
            >
              <MenuItem value={0} onClick={() => handleChangePessoa({ label: "Todos", value: 0 })}>Todos</MenuItem>
              {pessoas?.map((e) => (
                <MenuItem value={e.pessoa.idpessoa} onClick={() => handleChangePessoa({ label: e.pessoa.nome, value: e.pessoa.idpessoa })}>{e.pessoa.nome + " " + e.pessoa.sobrenome}</MenuItem>
              ))}
            </Select> */}
          </FormControl>

          <IconButton onClick={() => handleClickSearch()}>
            <SearchIcon />
          </IconButton>
          {/* <IconButton onClick={popover.onOpen}>
            <MoreVertIcon />
          </IconButton> */}
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <ExportExcel
          name="Divergência"
          columns={colunasExcel}
          data={dataExcel}
          element={<MenuItem><GetAppIcon /> Exportar</MenuItem>}
        />

        {/* <MenuItem onClick={popover.onClose}>
          <GetAppIcon />
          Export
        </MenuItem> */}
      </CustomPopover>
    </>
  );
}
