import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { withStyles } from "@material-ui/core/styles";
import {
  Add,
  AddAPhotoOutlined,
  CloseOutlined,
  DeleteOutline,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LinesTextField from "../../../components/LinesTextField";
import Snackbar from "../../../components/snackbar";
import UCheckbox from "../../../components/UCheckbox";
import USearchField from "../../../components/usearchfield";
import Enums from "../../../enums";
import centroCustoRepositorio from "../../../repositorios/centroCusto";
import emailRepositorio from "../../../repositorios/email";
import pessoaRepositorio from "../../../repositorios/pessoa";
import UsuarioTenantRepositorio from "../../../repositorios/usuarioTenant";
import { useStyles } from "./styles";

const ColorSwitch = withStyles({
  switchBase: {
    color: "#c62828",
    "&$checked": {
      color: "#b91119",
    },
    "&$checked + $track": {
      backgroundColor: "#b91119",
    },
  },
  checked: {},
  track: {},
})(Switch);

const AntTabs = withStyles({
  root: {
    background: "#616161",
    color: "white",
  },
  indicator: {
    backgroundColor: "#c62828",
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const CadastroUsuario = (props) => {
  const classes = useStyles();
  const permissao = useSelector((state) => state.permissao.permissaoDashboard);
  const [value, setValue] = React.useState(0);
  const [pessoas, setPessoas] = useState([]);
  const [contasEmail, setContasEmail] = useState([]);
  const [iEmpresas, setIEmpresas] = useState([]);
  const [iEmpresa, setIEmpresa] = useState(null);
  const [snackVariant, setSnackVariant] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [empresas, setEmpresas] = useState([]);
  const [ativo, setAtivo] = useState(
    (props.usuario.idusuario ? props.usuario.ativo : true) || null
  );
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [privilegio, setPrivilegio] = useState(
    props.usuario.idusuario ? props.usuario.privilegio : ""
  );
  //   const [tipoUsuario, setTipoUsuario] = useState(
  //     (props.usuario.idusuario ? String(props.usuario.tipousuario) : "1") || "1"
  //   );
  const [picture, setPicture] = useState(
    props.usuario.imagem ? props.usuario.imagem : "" || null
  );
  const [pessoa, setPessoa] = useState(
    props.usuario.pessoa
      ? {
          label:
            props.usuario.pessoa.nome + " " + props.usuario.pessoa.sobrenome,
          value: props.usuario.idpessoa,
        }
      : null
  );
  const [contaEmail, setContaEmail] = useState(
    props.usuario.contaEmail
      ? {
          label: props.usuario.contaEmail.email,
          value: props.usuario.idcontaemail,
        }
      : null
  );
  const [centroCusto, setCentroCusto] = useState(
    props.usuario.idusuario
      ? {
          label:
            props.usuario.centroCusto.nome +
            " - " +
            props.usuario.centroCusto.codigo,
          value: props.usuario.idcentrocusto,
        }
      : null
  );

  const privilegios = [
    {
      value: Enums.privilegio.administrador,
      label: "Administrador",
    },
    {
      value: Enums.privilegio.agente,
      label: "Agente",
    },
    {
      value: Enums.privilegio.autorizador,
      label: "Autorizador",
    },
    {
      value: Enums.privilegio.aprovador,
      label: "Aprovador",
    },
    {
      value: Enums.privilegio.gerente,
      label: "Gerente",
    },
    {
      value: Enums.privilegio.colaborador,
      label: "Colaborador",
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const onAddOrganizacao = async () => {
    try {
      if (iEmpresa === null || !iEmpresa.value) {
        setSnackVariant("error");
        setSnackMessage("Organização não pode ser vazio");
        setSnackVisible({ visible: true });
        return;
      }

      const temEmpresa = empresas.find(
        (item) => item.idtenant === iEmpresa.value
      );

      if (temEmpresa) {
        setSnackVariant("warning");
        setSnackMessage("Organização já esta na lista.");
        setSnackVisible({ visible: true });
        return;
      }

      const dtoOrganizacao = {
        idusuario: props.usuario.idusuario,
        idtenant: iEmpresa.value,
      };
      await UsuarioTenantRepositorio.addOrganizacao(dtoOrganizacao);
      setSnackVariant("success");
      setSnackMessage("Organização adicionada");
      setSnackVisible({ visible: true });
      const responseGetAll = await UsuarioTenantRepositorio.getAllByUsuario(
        props.usuario.idusuario
      );
      setEmpresas(responseGetAll.data);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao adicionar");
      setSnackVisible({ visible: true });
    }
  };

  const onDeleteOrganizacao = async (item) => {
    try {
      if (!item.idusuariotenant) {
        return;
      }

      await UsuarioTenantRepositorio.deleteOrganizacao(item.idusuariotenant);
      setSnackVariant("success");
      setSnackMessage("Organização removida");
      setSnackVisible({ visible: true });
      const responseGetAll = await UsuarioTenantRepositorio.getAllByUsuario(
        props.usuario.idusuario
      );
      setEmpresas(responseGetAll.data);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao remover");
      setSnackVisible({ visible: true });
    }
  };

  const upload = (arquivo) => {
    const imagemUpload = arquivo.target.files;
    if (imagemUpload.lenght <= 0) return;
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setPicture(fileReader.result);
      props.setPicture(fileReader.result);
    };
    fileReader.readAsDataURL(imagemUpload[0]);
  };

  const uploadImagem = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.type = "file";
    input.onchange = upload;
    input.accept = "image/png, image/jpeg";
    input.click();
    return;
  };

  const handleChange = (event) => {
    props.setUsuario({
      ...props.usuario,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeAuto = (campo, valor = null) => {
    props.setUsuario((prevUsuario) => ({
      ...prevUsuario,
      [campo]: valor,
    }));
  };

  useEffect(() => {
    (async () => {
      centroCustoRepositorio
        .getSearchField()
        .then((response) => setCentrosCusto(response));
      pessoaRepositorio
        .getSearchField()
        .then((response) => setPessoas(response));
      const responseContasAdmin =
        await emailRepositorio.getAllContaEmailSearchField();
      setContasEmail(responseContasAdmin);
      const searchEmpresas = await UsuarioTenantRepositorio.getSearchField();
      setIEmpresas(searchEmpresas);
      const response = await UsuarioTenantRepositorio.getAllByUsuario(
        props.usuario.idusuario
      );
      setEmpresas(response.data);
    })();
  }, [props.usuario.idusuario]);

  //   useEffect(() => {
  //     if (tipoUsuario) {
  //       handleChangeAuto("tipousuario", Number(tipoUsuario));
  //     }
  //   }, [tipoUsuario]);

  const handlePrivilegioChange = (novoPrivilegio) => {
    setPrivilegio(novoPrivilegio);
    handleChangeAuto("privilegio", novoPrivilegio);
  };

  const handleAtivoChange = (novoAtivo) => {
    setAtivo(novoAtivo);
    handleChangeAuto("ativo", novoAtivo);
  };

//   useEffect(() => {
//     if (ativo !== null) {
//       //   handleChangeAuto("ativo", ativo);
//       props.setUsuario((prevUsuario) => ({
//         ...prevUsuario,
//         [ativo]: ativo,
//       }));
//     }
//   }, [ativo]);

  return (
    <Box className={classes.root}>
      <Snackbar
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />

      <AntTabs
        value={value}
        onChange={handleChangeTab}
        aria-label="simple tabs example"
      >
        <Tab label="Usuário" {...a11yProps(0)} />
        {permissao.usuarioLinestur && props.usuario.idusuario > 0 ? (
          <Tab label="Organização" {...a11yProps(1)} />
        ) : null}
        <IconButton
          style={{
            color: "white",
            position: "absolute",
            right: 5,
          }}
          onClick={() => props.setCadastroOpen(false)}
        >
          <CloseOutlined />
        </IconButton>
      </AntTabs>
      <TabPanel value={value} index={0}>
        <div className={classes.body}>
          <div className={classes.linha}>
            <div className={classes.coluna2}>
              <Avatar
                alt="Remy Sharp"
                src={picture}
                className={classes.bigAvatar}
              />
              <IconButton
                onClick={uploadImagem}
                className={classes.iconbutton}
                size="large"
                onChange={upload}
              >
                <AddAPhotoOutlined fontSize="large" />
              </IconButton>
            </div>
            <div className={classes.coluna}>
              <div className={classes.widht480}>
                {/* <USwitch
                  {...{ checked: ativo, setChecked: setAtivo, label: "Ativo" }}
                /> */}
                <FormControl row>
                  <FormControlLabel
                    control={
                      <ColorSwitch
                        name={"Ativo"}
                        checked={ativo}
                        onChange={() => handleAtivoChange(!ativo)}
                      />
                    }
                    label={"Ativo"}
                  ></FormControlLabel>
                </FormControl>
              </div>
              <div className={classes.linhat}>
                <form className={classes.form} autoComplete="false">
                  <LinesTextField
                    required
                    fullWidth
                    autoComplete="off"
                    name="usuario"
                    label="Usuario"
                    variant="outlined"
                    onChange={handleChange}
                    value={props.usuario["usuario"] || ""}
                    margin="dense"
                  />
                </form>
                <div className={classes.txtfd}>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel>Privilégio *</InputLabel>
                    <Select
                      value={privilegio}
                      onChange={(e) => handlePrivilegioChange(e.target.value)}
                      label="Privilégio *"
                    >
                      {privilegios.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <USelect {...{ itens: privilegios, value: privilegio, setValue: setPrivilegio, label: 'Privilégio *' }} /> */}
                </div>
              </div>
              <div className={classes.linha}>
                <USearchField
                  {...{
                    itens: centrosCusto,
                    value: centroCusto,
                    setValue: setCentroCusto,
                    label: "Centro de Custo *",
                  }}
                  name="idcentrocusto"
                  onChange={handleChangeAuto}
                />
              </div>

              <div className={classes.linha}>
                <USearchField
                  {...{
                    itens: pessoas,
                    value: pessoa,
                    setValue: setPessoa,
                    label: "Nome",
                  }}
                  name="idpessoa"
                  onChange={handleChangeAuto}
                />
              </div>
              {permissao.usuarioLinestur && props.usuario.privilegio === 2 ? (
                <div className={classes.linha}>
                  <USearchField
                    {...{
                      itens: contasEmail,
                      value: contaEmail,
                      setValue: setContaEmail,
                      label: "Email agente",
                    }}
                    name="idcontaemail"
                    onChange={handleChangeAuto}
                  />
                </div>
              ) : null}
              <form className={classes.linha} autoComplete="off">
                <LinesTextField
                  required
                  fullWidth
                  name="email"
                  className={classes.textfielde}
                  onChange={handleChange}
                  value={props.usuario.email || ""}
                  label="Email"
                  variant="outlined"
                  margin="dense"
                />
              </form>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <UCheckbox
                      checked={props.alterarSenha}
                      onChange={(e) => props.setAlterarSenha(e.target.checked)}
                    />
                  }
                  label={
                    props.usuario.idusuario ? "Alterar Senha" : "Criar Senha"
                  }
                />
              </FormGroup>
              {props.alterarSenha ? (
                <form className={classes.linhat} autoComplete="off">
                  <LinesTextField
                    required
                    fullWidth
                    name="senha"
                    label="Senha"
                    variant="outlined"
                    margin="dense"
                    type="password"
                    onChange={handleChange}
                    value={props.usuario.senha || ""}
                  />
                  <LinesTextField
                    required
                    fullWidth
                    name="confirmacaoSenha"
                    className={classes.textfield}
                    label="Confirmar Senha"
                    value={props.usuario.confirmacaoSenha || ""}
                    onChange={handleChange}
                    type="password"
                    variant="outlined"
                    margin="dense"
                  />
                </form>
              ) : null}
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.body2}>
          <Grid
            container
            spacing={1}
            style={{
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
          >
            <Grid item xs={10}>
              <USearchField
                {...{
                  itens: iEmpresas,
                  value: iEmpresa,
                  setValue: setIEmpresa,
                  label: "Organização",
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Adicionar Organização">
                <IconButton>
                  <Add onClick={onAddOrganizacao} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <List>
                {empresas.map((item) => (
                  <ListItem>
                    <ListItemText>{item.tenant.nome}</ListItemText>
                    <Tooltip title="Remover Organização">
                      <IconButton>
                        <DeleteOutline
                          onClick={() => onDeleteOrganizacao(item)}
                        />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </TabPanel>
    </Box>
  );
};

export default CadastroUsuario;
