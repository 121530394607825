import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const PoliticaViagemRepositorio = {
    post: async () => {

    },
    validarConsulta: async () => {
        const response = await api.get('/PoliticaViagem/ValidarConsulta', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    validarAntecedencia: async (dtoValidarAntecedencia) => {
        const response = await api.post('/PoliticaViagem/ValidarAntecedencia', dtoValidarAntecedencia, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    adicionarExcecao: async (dtoExcecao) => {
        const response = await api.post('/PoliticaViagemExcecao/AdicionarExcecao', dtoExcecao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    editarExcecao: async (dtoExcecao) => {
        const response = await api.put('/PoliticaViagemExcecao/' + dtoExcecao.idpoliticaviagemexcecao, dtoExcecao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    removerExcecao: async (idexcecao) => {
        const response = await api.delete('/PoliticaViagemExcecao/' + idexcecao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    produtoAdicionar: async (dtoProduto) => {
        const response = await api.post('/PoliticaViagemProduto/AdicionarProduto', dtoProduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    produtoEditar: async (dtoProduto) => {
        const response = await api.put('/PoliticaViagemProduto/' + dtoProduto.idpoliticaviagemproduto, dtoProduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    produtoDeletar: async (idproduto) => {
        const response = await api.delete('/PoliticaViagemProduto/' + idproduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getPoliticaEmpresa: async idempresa => {
        const response = await api.get('/PoliticaViagem/GetPoliticaEmpresa', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                idempresa
            }
        })
        return response;
    },

    update: async dtoPoliticaViagem => {
        const response = await api.put('/PoliticaViagem/' + dtoPoliticaViagem.idpoliticaviagem, dtoPoliticaViagem, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    }
}

export default PoliticaViagemRepositorio;
