import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    gridTitle: {
        textAlign: 'center',
    },

    typoLittleFont: {
        fontSize: '10pt',
    },

    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119'
        }
    },

}));
