import { IconButton, InputLabel } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import React from 'react';
import LinesTextField from '../LinesTextField';
import { useStyles } from './styles';

export const UStepper = props => {
    const classes = useStyles();

    const onIconClick = (operation) => {
        if (operation === '+') {
            if (props.value >= props.maxValue) {
                return;
            }
            props.setValue.setValue({
                ...props,
                value: props.value + 1
            });
            return;
        }

        if (props.value <= props.minValue) {
            return;
        }
        props.setValue.setValue({
            ...props,
            value: props.value - 1
        });
    }

    return (
        <div className={classes.root}>
            <InputLabel className={classes.label} align="left">{props.label}</InputLabel>
            <div className={classes.linha}>

                <div className={classes.borda}>
                    <IconButton size="small" onClick={e => onIconClick('+')}>
                        <AddCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                    </IconButton>
                </div>
                <LinesTextField disabled={true}
                    value={props.value}
                    className={classes.textfield}
                    inputProps={{
                        style: { textAlign: "center" }
                    }}
                />
                <div className={classes.borda}>
                    <IconButton size="small" onClick={e => onIconClick('-')}>
                        <RemoveCircleOutline style={{ color: '#c62828' }} fontSize="inherit" />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default UStepper;
