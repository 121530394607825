import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Chip, DialogActions, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material"
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { grey, themepalette, warning } from "../../../../../theme/palette"
import { IconsSource, IconsSourceSvg } from "../../../../../images/Icons"
import { useCallback, useEffect, useState } from "react";
import Upload from "../../../../../upload/upload";
import { useBoolean } from "../../../../../hooks/use-boolean";
import { centrosdecustos_Mock } from "../../../../../mock/centrodecusto";
import { UploadAvatar } from "../../../../../upload";
import { fData } from "../../../../../upload/utils/utils";
import { getTipoDespesaForNumber, getTipoHodometroForNumber, tiposdedespesas, tiposdedespesas_Mock } from "../../../../../mock/tiposdespesas";
import { UtaliiDatePickerNormal } from "../../sections/custom-date-picker-normal";
import { convertImageToBase64 } from "../../../../../hooks/convertImageToBase64";
import { CreationDate, CriadoPor, IniciadoEm } from "../../sections";
import { useStyles } from "./style";
import { ImageComponent } from "../../../../../components/ImageComponent";
import { AdicionarDespesaComponent } from "./AdicionarDespesa";
import DateDifference from "../../sections/custom-date-difference";
import CustomDateRangePicker from "../../sections/custom-date-range-picker";
import EditIcon from '@mui/icons-material/Edit';
import { TroubleshootOutlined } from "@mui/icons-material";
import FeedbackIcon from '@mui/icons-material/Feedback';
import DialogComponent from "../../sections/dialogs-desktop";
import { EditarDespesaComponent } from "./EditarDespesa";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { EnumTipo, formatarParaMoedaStringComFlag, formatarParaNomeStatusAdiantamento, formatarParaNomeStatusViagem, formatarTotalDespesasForMoeda, formatarValorComMoeda } from "../../../../../enums/enumPrestacaoDeContas";
import CustomSnackbar from "../../sections/custom-custom-snackbar";
import { HodometroComponent } from "./Hodometro";
import { ViewHodometerDComponent } from "./HodometroInfo";
import { ExtrasDespesasComponent } from "./Extras";
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../../../components/uloadervoo";

export const ViagemComponent = ({ viagem, handleCreate, handleEdit, handleDelete, handleIniciarViagem, setTypeGlobal, handleFinalizar, handleClickCreateHodometro, handleClickEditHodometro }) => {
    const classes = useStyles();
    //Type 0
    const [type, setType] = useState(0);
    const [openDeleteDespesa, setOpenDeleteDespesa] = useState(false);
    const [openFinalizarDespesa, setOpenFinalizarDespesa] = useState(false);
    const [openDeleteViagem, setOpenDeleteViagem] = useState(false);
    const [openMessageDespesa, setOpenMessageDespesa] = useState(false);
    const [openIniciarViagem, setOpenIniciarViagem] = useState(false);
    const [typehodometer, setTypeHodometer] = useState(0);
    const [despesas, setDespesas] = useState([]);
    const [despesa, setDespesa] = useState([]);
    const [extras, setExtras] = useState([]);
    const [currentTab, setCurrentTab] = useState(1);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useCustomSnackbar();

    const getDespesas = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas)
            setExtras(response.extras)
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    //Type 1
    const handleClickDeleteDespesa = () => {
        setOpenDeleteDespesa(false)
        handleDelete({
            idviagem: viagem.id,
            id: despesa.iddespesa
        })
    }

    const handleClickInfoDespesa = (despesa) => {
        setDespesa(despesa)
        setOpenMessageDespesa(true)
    }

    const handleClickFinalizarViagem = () => {
        setOpenFinalizarDespesa(false)
        let err = false;
        despesas.forEach(item => {
            if (item.produtos.iddespesa_tipo == 1) {
                enqueueSnackbar('Há uma despesa pendente!.', { variant: 'warning' });
                err = true;
            }
        })

        if (err) {
            return
        }
        handleFinalizar(viagem.id)
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    useEffect(() => {
        getDespesas()
    }, [viagem])

    useEffect(() => {
        let c = 0;
        extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [extras])

    useEffect(() => {
        setTypeGlobal(type)
        if (type == 0) {
            getDespesas()
        }
    }, [type])

    return (
        <Grid>
            <ULoaderVoo isLoading={loading} />
            <DialogComponent
                title={viagem?.titulo}
                open={openDeleteDespesa}
                handleClose={() => setOpenDeleteDespesa(false)}
                body={
                    <Grid container item xs={12}>
                        <Typography>Deseja realmente deletar essa despesa?</Typography>
                    </Grid>
                }
                action={
                    <Grid>
                        <Button onClick={() => setOpenDeleteDespesa(false)}>Cancelar</Button>
                        <Button onClick={() => handleClickDeleteDespesa()}>Confimar</Button>
                    </Grid>
                }
                width={"sm"}
            />
            {type == 0 ? (
                <Grid style={{ minWidth: "500px" }}>
                    <Grid container item xs={12} style={{ display: "grid", justifyContent: "center" }}>
                        {viagem.status == 1 || viagem.status == 0 || viagem.status == EnumTipo.viagemstatus.pendenteacorrecao ? (
                            <Grid style={{ minWidth: "200px", marginTop: "-10px", justifyContent: "center", textAlign: "center" }}>

                                {viagem != null && despesas.length != 0 ? (
                                    <Button variant="contained" color="primary" onClick={() => setOpenFinalizarDespesa(true)}>Finalizar viagem</Button>
                                ) : (null)}
                                <DialogComponent
                                    title={viagem?.titulo}
                                    open={openFinalizarDespesa}
                                    handleClose={() => setOpenFinalizarDespesa(false)}
                                    body={
                                        <Grid container item xs={12}>
                                            <Typography>Ao avançar sua viagem não poderá retorna-la, apenas ver sua viagem finalizada</Typography>
                                        </Grid>
                                    }
                                    action={
                                        <Grid>
                                            <Button onClick={() => setOpenFinalizarDespesa(false)}>Cancelar</Button>
                                            <Button onClick={() => handleClickFinalizarViagem()}>Confimar</Button>
                                        </Grid>
                                    }
                                    width={"sm"}
                                />

                                {viagem.status != 9 && viagem.status != 10 && viagem.status != EnumTipo.viagemstatus.pendenteacorrecao ? (
                                    <>
                                        <Button fullWidth variant="contained" color="primary" onClick={() => setType(1)}>Adicionar despesa</Button>
                                        <Button fullWidth variant="contained" startIcon={<img src={IconsSource.googleMapIcon} width={20}></img>} color="secondary" onClick={() => setType(2)}>Hodometro</Button>
                                    </>
                                ) : (null)}
                            </Grid>
                        ) : (null)}
                    </Grid>
                    {viagem != null && count != 0 ? (
                        <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {[{ value: 1, label: "Despesas" }, { value: 2, label: "Extras" }].map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                            {tab.label}
                                        </Typography>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    ) : (null)}
                    {currentTab == 1 && (
                        <>
                            {viagem != null && despesas.length != 0 ? (
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                                    {viagem.status == 1 ? (
                                        <>
                                        </>
                                    ) : (
                                        <Grid item xs={12} style={{ marginTop: "8px" }}>
                                            {formatarParaNomeStatusViagem(viagem.status)}
                                        </Grid>
                                    )}
                                    {despesas?.map((value) => (
                                        <>
                                            {!value.aprovado || viagem.status != EnumTipo.viagemstatus.finalizado ? (
                                                <Grid
                                                    item xs={12}
                                                    className={classes.card}
                                                    style={{ marginTop: "20px" }}
                                                    container
                                                >
                                                    {value.hodometro ? (
                                                        <>
                                                            <Grid container item xs={12} style={{ padding: "20px" }}>
                                                                <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                    <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                                                                </Grid>
                                                                <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                                    <Grid style={{ marginLeft: "auto" }}>
                                                                        <CreationDate data={value.datacriacao} />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                    <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                                                </Grid>
                                                                <Grid item xs={6} style={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-start",
                                                                    alignContent: "flex-start",
                                                                    alignItems: "flex-start",
                                                                    flexDirection: "row",
                                                                    marginTop: "auto"
                                                                }}>
                                                                    <Typography style={{ fontSize: "small" }}>
                                                                        {value.despesa_desc}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3} style={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end",
                                                                    flexDirection: "row",
                                                                    marginTop: "auto"
                                                                }}>
                                                                    {
                                                                        viagem.status == 1 ||
                                                                            viagem.status == 9 ||
                                                                            viagem.status == 10 ||
                                                                            viagem.status == EnumTipo.viagemstatus.controladoria ? (
                                                                            <>
                                                                                {value.controladoria ? (
                                                                                    <>
                                                                                        {value.mensagemcontrolador != "" && value.mensagemcontrolador != null ? (
                                                                                            <>
                                                                                                <IconButton color="warning" onClick={() => handleClickInfoDespesa(value)}>
                                                                                                    <FeedbackIcon />
                                                                                                </IconButton>
                                                                                                <DialogComponent
                                                                                                    title={
                                                                                                        <>
                                                                                                            Mensagem
                                                                                                        </>
                                                                                                    }
                                                                                                    open={openMessageDespesa}
                                                                                                    handleClose={() => setOpenMessageDespesa(false)}
                                                                                                    body={
                                                                                                        <Grid container item xs={12} width={280}>
                                                                                                            <Typography>{despesa.mensagemcontrolador}</Typography>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    action={
                                                                                                        <Grid>
                                                                                                            <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    width={"sm"}
                                                                                                />
                                                                                                <IconButton onClick={() => {
                                                                                                    setDespesa(value);
                                                                                                    if (value.produtos.value == 1) {
                                                                                                        setTypeHodometer(1)
                                                                                                    } else {
                                                                                                        setTypeHodometer(1)
                                                                                                    }
                                                                                                    setType(value.hodometro != null ? (4) : (3));
                                                                                                }}>
                                                                                                    <EditRoundedIcon />
                                                                                                </IconButton>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Button color="warning" style={{ marginTop: "0px", marginLeft: "5px" }} startIcon={<AdminPanelSettingsIcon />}>
                                                                                                Controladoria
                                                                                            </Button>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {value.mensagem != "" ? (
                                                                                            <>
                                                                                                <IconButton onClick={() => handleClickInfoDespesa(value)}>
                                                                                                    <FeedbackIcon />
                                                                                                </IconButton>
                                                                                                <DialogComponent
                                                                                                    title={
                                                                                                        <>
                                                                                                            Mensagem
                                                                                                        </>
                                                                                                    }
                                                                                                    open={openMessageDespesa}
                                                                                                    handleClose={() => setOpenMessageDespesa(false)}
                                                                                                    body={
                                                                                                        <Grid container item xs={12} width={280}>
                                                                                                            <Typography>{despesa.mensagem}</Typography>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    action={
                                                                                                        <Grid>
                                                                                                            <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    width={"sm"}
                                                                                                />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {!value.aprovado && value.status == EnumTipo.viagemstatus.pendenteacorrecao ? (
                                                                                                    <Chip label="Pendente" color="secondary" />
                                                                                                ) : (null)}
                                                                                            </>
                                                                                        )}

                                                                                        {!value.aprovado ? (
                                                                                            <>
                                                                                                {value.mensagem != "" ? (
                                                                                                    <>
                                                                                                        <IconButton onClick={() => {
                                                                                                            setDespesa(value);
                                                                                                            if (value.produtos.value == 1) {
                                                                                                                setTypeHodometer(1)
                                                                                                            } else {
                                                                                                                setTypeHodometer(2)
                                                                                                            }
                                                                                                            setType(value.hodometro != null ? (4) : (3));
                                                                                                        }}>

                                                                                                            <EditRoundedIcon />
                                                                                                        </IconButton>
                                                                                                    </>
                                                                                                ) : (null)}
                                                                                                {viagem.status == EnumTipo.viagemstatus.emviagem ? (
                                                                                                    <>
                                                                                                        <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                            <DeleteOutlineOutlinedIcon />
                                                                                                        </IconButton>
                                                                                                        <IconButton onClick={() => {
                                                                                                            setDespesa(value);
                                                                                                            if (value.produtos.value == 1) {
                                                                                                                setTypeHodometer(1)
                                                                                                            } else {
                                                                                                                setTypeHodometer(2)
                                                                                                            }
                                                                                                            setType(value.hodometro != null ? (4) : (3));
                                                                                                        }}>
                                                                                                            <EditRoundedIcon />
                                                                                                        </IconButton>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (<></>)}


                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (null)
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={3} style={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end",
                                                                    flexDirection: "row",
                                                                }}>
                                                                    {
                                                                        viagem.status == EnumTipo.viagemstatus.viagemfinalizada ||
                                                                            viagem.status == EnumTipo.viagemstatus.pendenteacorrecao ?
                                                                            (
                                                                                <Grid style={{ marginRight: "10px" }}>
                                                                                    {value.aprovado ? (
                                                                                        <Chip label="Aprovado" color="primary" />
                                                                                    ) : (
                                                                                        <Chip label="Pendente" color="secondary" />
                                                                                    )}
                                                                                </Grid>
                                                                            ) : (null)
                                                                    }
                                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>
                                                                        {value.hodometro.kmtotal} Km
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid container item xs={10} style={{ padding: "20px" }}>
                                                                <Grid container item xs={7} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                    <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                                                                </Grid>
                                                                <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                                    <CreationDate data={value.datacriacao} />
                                                                </Grid>
                                                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                    <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                                                </Grid>
                                                                <Grid item xs={6} style={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-start",
                                                                    alignContent: "flex-start",
                                                                    alignItems: "flex-start",
                                                                    flexDirection: "row",
                                                                    marginTop: "auto"
                                                                }}>
                                                                    <Typography style={{ fontSize: "small" }}>
                                                                        {value.despesa_desc}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={3} style={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end",
                                                                    flexDirection: "row",
                                                                    marginTop: "auto"
                                                                }}>
                                                                    {
                                                                        viagem.status == 1 ||
                                                                            viagem.status == 9 ||
                                                                            viagem.status == 10 ||
                                                                            viagem.status == EnumTipo.viagemstatus.controladoria ? (
                                                                            <>
                                                                                {value.controladoria ? (
                                                                                    <>
                                                                                        {value.mensagemcontrolador != "" && value.mensagemcontrolador != null ? (
                                                                                            <>
                                                                                                <IconButton color="warning" onClick={() => handleClickInfoDespesa(value)}>
                                                                                                    <FeedbackIcon />
                                                                                                </IconButton>
                                                                                                <DialogComponent
                                                                                                    title={
                                                                                                        <>
                                                                                                            Mensagem
                                                                                                        </>
                                                                                                    }
                                                                                                    open={openMessageDespesa}
                                                                                                    handleClose={() => setOpenMessageDespesa(false)}
                                                                                                    body={
                                                                                                        <Grid container item xs={12} width={280}>
                                                                                                            <Typography>{despesa.mensagemcontrolador}</Typography>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    action={
                                                                                                        <Grid>
                                                                                                            <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    width={"sm"}
                                                                                                />
                                                                                                <IconButton onClick={() => {
                                                                                                    setDespesa(value);
                                                                                                    if (value.produtos.value == 1) {
                                                                                                        setTypeHodometer(1)
                                                                                                    } else {
                                                                                                        setTypeHodometer(2)
                                                                                                    }
                                                                                                    setType(value.hodometro != null ? (4) : (3));
                                                                                                }}>
                                                                                                    <EditRoundedIcon />
                                                                                                </IconButton>
                                                                                            </>
                                                                                        ) : (
                                                                                            <Button color="warning" style={{ marginTop: "0px", marginLeft: "5px" }} startIcon={<AdminPanelSettingsIcon />}>
                                                                                                Controladoria
                                                                                            </Button>
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {value.mensagem != "" ? (
                                                                                            <>
                                                                                                <IconButton onClick={() => handleClickInfoDespesa(value)}>
                                                                                                    <FeedbackIcon />
                                                                                                </IconButton>
                                                                                                <DialogComponent
                                                                                                    title={
                                                                                                        <>
                                                                                                            Mensagem
                                                                                                        </>
                                                                                                    }
                                                                                                    open={openMessageDespesa}
                                                                                                    handleClose={() => setOpenMessageDespesa(false)}
                                                                                                    body={
                                                                                                        <Grid container item xs={12} width={280}>
                                                                                                            <Typography>{despesa.mensagem}</Typography>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    action={
                                                                                                        <Grid>
                                                                                                            <Button onClick={() => setOpenMessageDespesa(false)}>Fechar</Button>
                                                                                                        </Grid>
                                                                                                    }
                                                                                                    width={"sm"}
                                                                                                />
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {!value.aprovado && value.status == EnumTipo.viagemstatus.pendenteacorrecao ? (
                                                                                                    <Chip label="Pendente" color="secondary" />
                                                                                                ) : (null)}
                                                                                            </>
                                                                                        )}

                                                                                        {!value.aprovado ? (
                                                                                            <>
                                                                                                {value.mensagem != "" ? (
                                                                                                    <>
                                                                                                        <IconButton onClick={() => {
                                                                                                            setDespesa(value);
                                                                                                            if (value.produtos.value == 1) {
                                                                                                                setTypeHodometer(1)
                                                                                                            } else {
                                                                                                                setTypeHodometer(2)
                                                                                                            }
                                                                                                            setType(value.hodometro != null ? (4) : (3));
                                                                                                        }}>

                                                                                                            <EditRoundedIcon />
                                                                                                        </IconButton>
                                                                                                    </>
                                                                                                ) : (null)}
                                                                                                {viagem.status == EnumTipo.viagemstatus.emviagem ? (
                                                                                                    <>
                                                                                                        <IconButton onClick={() => { setDespesa(value); setOpenDeleteDespesa(true); }}>
                                                                                                            <DeleteOutlineOutlinedIcon />
                                                                                                        </IconButton>
                                                                                                        <IconButton onClick={() => {
                                                                                                            setDespesa(value);
                                                                                                            if (value.produtos.value == 1) {
                                                                                                                setTypeHodometer(1)
                                                                                                            } else {
                                                                                                                setTypeHodometer(2)
                                                                                                            }
                                                                                                            setType(value.hodometro != null ? (4) : (3));
                                                                                                        }}>
                                                                                                            <EditRoundedIcon />
                                                                                                        </IconButton>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (<></>)}

                                                                                        <DialogComponent
                                                                                            title={viagem?.titulo}
                                                                                            open={openDeleteDespesa}
                                                                                            handleClose={() => setOpenDeleteDespesa(false)}
                                                                                            body={
                                                                                                <Grid container item xs={12}>
                                                                                                    <Typography>Deseja realmente deletar essa despesa?</Typography>
                                                                                                </Grid>
                                                                                            }
                                                                                            action={
                                                                                                <Grid>
                                                                                                    <Button onClick={() => setOpenDeleteDespesa(false)}>Cancelar</Button>
                                                                                                    <Button onClick={() => handleClickDeleteDespesa()}>Confimar</Button>
                                                                                                </Grid>
                                                                                            }
                                                                                            width={"sm"}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (null)}
                                                                </Grid>
                                                                <Grid item xs={3} style={{
                                                                    display: "flex",
                                                                    justifyContent: "flex-end",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end",
                                                                    flexDirection: "row",
                                                                }}>
                                                                    {
                                                                        viagem.status == EnumTipo.viagemstatus.viagemfinalizada ||
                                                                            viagem.status == EnumTipo.viagemstatus.pendenteacorrecao ? (
                                                                            <Grid style={{ marginRight: "10px" }}>
                                                                                {value.aprovado ? (
                                                                                    <Chip label="Aprovado" color="primary" />
                                                                                ) : (
                                                                                    <>
                                                                                        {viagem.status == EnumTipo.viagemstatus.controladoria && !value.controladoria ? (
                                                                                            <Chip label="Pendente" color="secondary" />
                                                                                        ) : (
                                                                                            null
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </Grid>
                                                                        ) : (null)}
                                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>
                                                                        {value.hodometro != null ? (<>
                                                                            {value.hodometro.kmtotal} Km
                                                                        </>) : (<>
                                                                            {formatarValorComMoeda(value.tipo_moeda, value.total)}
                                                                        </>)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <ImageComponent width={100} height={148} src={value.comprovante} alt={"Imagem não encontrada"} />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Grid container item xs={12}>
                                                    <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
                                                        {formatarParaNomeStatusAdiantamento(viagem.adiantamento.status, viagem.totalinicial)}
                                                    </Grid>
                                                    {viagem?.despesas?.map((item) => (
                                                        <>
                                                            <Grid
                                                                item xs={12}
                                                                className={classes.card}
                                                                style={{ marginTop: "20px" }}
                                                                container
                                                            >
                                                                {item.hodometro ? (
                                                                    <>
                                                                        <Grid container item xs={12} style={{ padding: "20px" }}>
                                                                            <Grid container item xs={6}>
                                                                                <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                                    <Typography style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                                                <Grid style={{ marginLeft: "auto" }}>
                                                                                    <CreationDate data={item.datadecriacao} />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={8} style={{ textAlign: "left" }}>
                                                                                <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={4} style={{ textAlign: "right" }}>
                                                                            </Grid>
                                                                            <Grid item xs={6} style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-start",
                                                                                alignContent: "flex-start",
                                                                                alignItems: "flex-end",
                                                                                flexDirection: "row",
                                                                            }}>
                                                                                <Grid style={{ marginLeft: "2px" }}>
                                                                                    {getTipoHodometroForNumber(item.hodometro.veiculo)}
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={6} style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                                alignContent: "flex-end",
                                                                                alignItems: "center",
                                                                                flexDirection: "row",
                                                                            }}>

                                                                                <Grid style={{}}>
                                                                                    {item.controladoria ? (
                                                                                        <>
                                                                                            <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                                            </Chip>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {item.aprovado ? (
                                                                                                <Chip label="Aprovado" color="primary" />
                                                                                            ) : (
                                                                                                <Chip label="Pendente" color="secondary" />
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </Grid>

                                                                                <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{item.hodometro.kmtotal} Km - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.hodometro.valor || 0)}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </>
                                                                )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <Grid container item xs={10} style={{ padding: "20px" }}>
                                                                                <Grid container item xs={6} >
                                                                                    <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                                                        <Typography style={{ fontWeight: "600" }}>Despesa</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "right" }}>
                                                                                    <CreationDate data={item.datadecriacao} />
                                                                                </Grid>
                                                                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                                    <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-start",
                                                                                    alignContent: "flex-start",
                                                                                    alignItems: "flex-start",
                                                                                    flexDirection: "row",
                                                                                    marginTop: "auto"
                                                                                }}>
                                                                                    <Typography style={{ fontSize: "small" }}>
                                                                                        {item.despesa_desc}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={6} style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-end",
                                                                                    alignContent: "flex-end",
                                                                                    alignItems: "center",
                                                                                    flexDirection: "row",
                                                                                    marginTop: "auto"
                                                                                }}>
                                                                                    <Grid style={{ marginRight: "10px" }}>
                                                                                        {item.controladoria ? (
                                                                                            <>
                                                                                                <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                                                </Chip>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {item.aprovado ? (
                                                                                                    <Chip label="Aprovado" color="primary" />
                                                                                                ) : (
                                                                                                    <Chip label="Pendente" color="secondary" />
                                                                                                )} 
                                                                                            </>
                                                                                        )}
                                                                                    </Grid>
                                                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(item.tipo_moeda, item.total)}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                <ImageComponent width={100} height={148} src={item.comprovante} alt={"Imagem não encontrada"} />
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                            </Grid>
                                                        </>
                                                    ))}
                                                </Grid>
                                            )}
                                        </>
                                    ))}
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginBottom: "20px" }}>
                                        <img src={IconsSourceSvg.pastas}></img>
                                        <Typography>Nenhuma despesa encontrada</Typography>
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {currentTab == 2 && (
                        <>
                            <ExtrasDespesasComponent viagem={viagem} extras={extras} />
                        </>
                    )}
                </Grid>
            ) : (
                null
            )}
            {type == 1 ? (
                <>
                    <AdicionarDespesaComponent viagem={viagem} handleCreate={handleCreate} setType={setType} />
                </>
            ) : (
                null
            )}
            {type == 3 ? (
                <>
                    <EditarDespesaComponent viagem={viagem} despesa={despesa} handleEdit={handleEdit} setType={setType} />
                </>
            ) : (
                null
            )}
            {type == 2 ? (
                <>
                    <HodometroComponent idviagem={viagem.id} setType={setType} handleClickCreateHodometro={handleClickCreateHodometro} />
                </>
            ) : (
                null
            )}
            {type == 4 ? (
                <>
                    <ViewHodometerDComponent viagem={viagem} hodometro={despesa} setType={setType} editHodometro={handleClickEditHodometro} typehodometro={typehodometer} />
                </>
            ) : (
                null
            )}
        </Grid>
    )
}

export const ConfirmButtonViagem = ({ viagem, onClickEdit, setAlterViagem }) => {
    const [datainicio, setDataInicio] = useState(new Date(viagem.datainicio));
    const [datafim, setDataFim] = useState(new Date(viagem.datafim));
    const [openOptionsDate, setOpenOptionsDate] = useState(false);
    const [isDateValid, setIsDateValid] = useState(true);

    const onCloseDateOptions = useCallback(() => {
        setOpenOptionsDate(false);
        setDataInicio(new Date(viagem.datainicio));
        setDataFim(new Date(viagem.datafim));
    }, [viagem.datainicio, viagem.datafim]);

    const onChangeStartDate = (newValue) => {
        setDataInicio(newValue);
        setAlterViagem(prevState => ({
            ...prevState,
            datainicio: newValue
        }));
    };

    const onChangeEndDate = (newValue) => {
        setDataFim(newValue);
        setAlterViagem(prevState => ({
            ...prevState,
            datafim: newValue
        }));
    };

    useEffect(() => {
        if (datafim && datainicio) {
            setIsDateValid(datafim >= datainicio);
        } else {
            setIsDateValid(false);
        }
    }, [datainicio, datafim]);

    const handleClickDefinir = () => {
        if (isDateValid) {
            onClickEdit();
            onCloseDateOptions();
        }
    };

    return (
        <>
            {viagem.tem_monetario ? (
                <Grid item xs={8} style={{ textAlign: "left", display: "flex" }}>
                    sw
                    {viagem.adiantamento.map((item) => (
                        <Grid item xs={12} style={{ padding: "10px" }}>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>
                                        {formatarParaMoedaStringComFlag(item.tipo_moeda, item.total)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", justifyContent: "left" }}>
                                {/* <Box justifyContent="space-between">
                                    <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total prestado</Typography>
                                    <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarTotalDespesasForMoeda(despesas, item.tipo_moeda)}</Typography>
                                </Box> */}
                                <Box sx={{ textAlign: 'center', px: 2 }}>
                                    <Box justifyContent="space-between">
                                        <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total informado</Typography>
                                        <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(item.tipo_moeda, item.total)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ textAlign: 'center', px: 2 }}>
                                    <Box justifyContent="space-between">
                                        <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Total em reais</Typography>
                                        <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, item.total_brl)}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid item xs={8} style={{ textAlign: "left", display: "flex" }}>
                    <Typography style={{ fontWeight: "500", fontSize: "12px" }}>
                        Total inicial: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial)}
                    </Typography>
                    {/* Usando um span para controle fino sobre o caractere e espaçamento */}
                    <span style={{ margin: "0 4px" }}>•</span>
                    <Typography style={{ fontWeight: "600", fontSize: "12px" }}>
                        Total: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total)}
                        {viagem.totalinicial !== 0 && (
                            <>
                                <span style={{ margin: "0 4px" }}>•</span>
                                <span style={{ color: viagem.totalinicial - viagem.total > 0 ? "#FFAB00" : "#FF0000" }}>
                                    Restante: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.totalinicial - viagem.total)}
                                </span>
                            </>
                        )}
                    </Typography>
                </Grid>
            )}

            <Grid item xs={4} style={{
                textAlign: "right", justifyContent: "right", display: "flex", display: "inherit",
                flexWrap: "nowrap",
                alignItems: "right",
                flexDirection: "row-reverse"
            }}>
                <Typography style={{ fontWeight: "600", display: "flex" }}>
                    <DateDifference startDate={datainicio} endDate={datafim} />
                    {viagem.status == 1 || viagem.status == 0 ? (
                        <EditIcon style={{ cursor: "pointer" }} variant="contained" color="info" onClick={() => setOpenOptionsDate(true)} />
                    ) : (
                        null
                    )}
                </Typography>
                <CustomDateRangePicker
                    open={openOptionsDate}
                    startDate={datainicio}
                    endDate={datafim}
                    onChangeStartDate={onChangeStartDate}
                    onChangeEndDate={onChangeEndDate}
                    onClose={onCloseDateOptions}
                    dialogActions={
                        <DialogActions>
                            <Button variant="outlined" color="inherit" onClick={onCloseDateOptions}>
                                Cancelar
                            </Button>
                            <Button disabled={!isDateValid} variant="contained" onClick={() => handleClickDefinir()}>
                                Definir
                            </Button>
                        </DialogActions>
                    }
                    title="Definir periódo da viagem"
                />
            </Grid>
        </>
    )
}