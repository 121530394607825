import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },

  gridGrupos: {
    height: 'min-content',
    // border: '2px solid #c62828',
    // borderRadius: '10px',
    padding: '10px'
  },

  gridItemGrupo: {
    height: 'min-content',
    border: '2px solid #c62828',
    borderRadius: '10px',
    padding: '10px',
    marginBottom: '15px'
  },

  gridAeroportosPax: {
    padding: '10px',
  }
}));
