import { useState } from "react";
import EnumStatus from "../../enums/enumStatusBooking";
import CustomizedProgressBars from "./index";
import reservaRepositorio from "../../repositorios/reserva";
import { useEffect } from "react";

export default function ReservaStatusComponent(props) {
  const [reduce, setReduce] = useState(null);

  const getReduce = async () => {
    try {
      const reduction = await reservaRepositorio.getReduce(props.idreserva);
      setReduce(reduction);
      if(props.setIsReduce != null){
        props.setIsReduce(true)
      }
    } catch (error) {
      // Handle any errors that occur during the API call
    }
  };

  useEffect(() => {
    // Call getReduce only if the status is not in the excluded list
    if (![ // List of excluded status codes
      EnumStatus.statusCode.encerrado,
      EnumStatus.statusCode.emitido,
      EnumStatus.statusCode.expirado,
      EnumStatus.statusCode.emissaoNegada,
      EnumStatus.statusCode.cancelado,
      EnumStatus.statusCode.limiteExcedido,
      EnumStatus.statusCode.reembolsoIntegral,
      EnumStatus.statusCode.reembolsoParcial,
    ].includes(props.status)) {
      getReduce();
    }
  }, [props.status]); // Run this effect whenever the status prop changes

  switch (props.status) {
    case EnumStatus.statusCode.encerrado:
    case EnumStatus.statusCode.emitido:
    case EnumStatus.statusCode.expirado:
    case EnumStatus.statusCode.emissaoNegada:
    case EnumStatus.statusCode.cancelado:
    case EnumStatus.statusCode.limiteExcedido:
    case EnumStatus.statusCode.reembolsoIntegral:
    case EnumStatus.statusCode.reembolsoParcial:
      return null;
    default:
      return (
        <div>
          {reduce ? (
            <CustomizedProgressBars reduce={reduce} />
          ) : null}
        </div>
      );
  }
}

