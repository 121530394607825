import { Box, Button, Grid, LinearProgress, List, ListItem, Typography } from '@material-ui/core';
import { RemoveRedEyeOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import EnumPackagesAgentes from '../../../enums/enumStatusPackagesAgentes';
import { useStyles } from './styles';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor: '#f3d4d4'
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#c62828"
    }
}))(LinearProgress);

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function MobileDados(props) {
    const classes = useStyles();

    const handleClickRow = (item) => {
        props.setOpenDialogPacote(true)
        props.setSelectedPacote(item)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <List>
                {props.data.map((item) => (
                    <ListItem divider>
                        <Grid container spacing={1} style={{ border: '1px solid #c62828', padding: '10px', borderRadius: '20px' }}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>{item.idpacotetenant} - {item.titulo}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption"
                                    style={{
                                        width: '-webkit-fill-available',
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        borderColor: EnumPackagesAgentes.getInfoPackage(item.status).color,
                                        background: EnumPackagesAgentes.getInfoPackage(item.status).color,
                                        color: 'white', borderRadius: 3, padding: 5
                                    }}
                                >
                                    {
                                        EnumPackagesAgentes.getInfoPackage(item.status).text
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{item.viajante}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <LinearProgressWithLabel value={item.progresso} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={() => handleClickRow(item)} className={classes.button} endIcon={<RemoveRedEyeOutlined />}> Abrir</Button>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))
                }
            </List>
        </div >
    );
};

export default MobileDados;
