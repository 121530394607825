import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import "./style.css";
//componentes
import UDatePicker from "../../../components/udatepicker";
import UStepper from "../../../components/ustepper";
import USearchField from "../../../components/usearchfield";
import LinesTextField from "../../../components/LinesTextField";
import { IconsSource } from "../../../images/Icons";
import MapViewerHotels from "../../../components/MapViewer";
import MenuSelectField from "../Modais/SearchField";
import RoomBooking from "../Modais/test";
import RoomOccupants from "../Modais/tests";
import "../../../fonts/fonts.css"
import ImageSlider from "../../../components/UImageSlider";
import USearchFieldHotel from "../../../components/usearchfield/USearchFieldHotel";
import MapViewerHotel from "../Modais/Views/MapViewer";
//bibliotecas
import PropTypes from 'prop-types';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-icon-2x.png"
import { Icon } from "leaflet";
//material ui 4
//components
import { Badge, Box, Button, Card, CardActions, CardContent, Checkbox, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Menu, MenuItem, Paper, Tooltip, Typography, withStyles } from "@material-ui/core";
import { Alert, Pagination, Rating, Skeleton } from "@material-ui/lab";
//icons
import { ArrowBackOutlined, ErrorOutline, FreeBreakfast, FreeBreakfastOutlined, KingBed, LocalOffer, LoopOutlined, MonetizationOnOutlined, MoodBadOutlined, MoodOutlined, NewReleasesOutlined, SearchOutlined } from "@material-ui/icons";
import ApartmentIcon from '@material-ui/icons/Apartment';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import PoolIcon from '@material-ui/icons/Pool';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BathtubIcon from '@material-ui/icons/Bathtub';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TranslateIcon from '@material-ui/icons/Translate';
import BusinessIcon from '@material-ui/icons/Business';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import SpaIcon from '@material-ui/icons/Spa';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import WifiIcon from '@material-ui/icons/Wifi';
import TvIcon from '@material-ui/icons/Tv';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SingleBedIcon from '@material-ui/icons/SingleBed';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import defaultlinesimg from '../../../images/Logos/logo_default.png'
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import ClearIcon from '@material-ui/icons/Clear';
import StarsIcon from '@material-ui/icons/Stars';
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import EmailChipInput from "../Modais/EmailInput";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const StyledRating = withStyles({
    iconFilled: {
        color: '#252525',
    },
    iconHover: {
        color: '#292929',
    },
})(Rating);

export const HotelDesktop = props => {
    const classes = useStyles();
    const cookie = new Cookies();
    const [state, setState] = useState({
        checkedExclusive: false,
        checkedLaundry: false,
        checkedParking: false,
        checkedPool: false,
        checkedRestaurant: false,
    });
    const facilidades = organizarFacilidadesPorComodo(props.hotel?.facilities || []);
    const [filtredHotels, setFiltredHotels] = useState([])
    const [page, setPage] = useState(1);
    const [itensPerPage, setItensPerPage] = useState(10);
    const pages = Math.ceil(filtredHotels.length / itensPerPage);
    const start = (page - 1) * itensPerPage;
    const end = start + itensPerPage;
    const todasHoteis = filtredHotels.slice(start, end);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const description = props.hotel && props.hotel.description ? props.hotel.description : '';
    const limite = 440;

    //ordenar tarifas
    const [tarifaOrder, setTarifaOrder] = useState(1)

    const onClickOrderTarifa = (value) => {
        setTarifaOrder(value)
    }

    //cotar
    const [openCotar, setOpenCotar] = useState(false);
    const dadosSessao = useSelector(state => state.permissao.dadosSessao);

    const handleOpenCotar = () => {
        setOpenCotar(true)
    }

    const handleClickString = () => setIsExpanded(!isExpanded);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    function organizarFacilidadesPorComodo(facilidades) {
        const comodos = {
            'Quarto': ['Ar Condicionado', 'Telefone', 'Cofre', 'Menu de travesseiros', '220 AC', 'Apartamento para não Fumantes', 'Secretária', 'Acesso a internet', 'Internet Wireless', 'Mini-Bar', 'Chaleira Eléctrica', 'Copos', 'Artigos para Banheiro', 'Chinelos de quarto', 'Roupão de Banho', 'Secador de cabelo', 'Banheiro privado', 'Telefone WC', 'Leitor de DVD', 'Rádio', 'Tv de tela Plano / Plasma / LCD', 'TV a Cabo', 'Tv com controle Remoto', 'Serviço de Despertar'],
            'Outros': ['120 AC', 'Apartamento para fumantes', 'Telefone com linha direta', 'Banheira', 'Bidet', 'Televisão', 'Berço', 'Varanda', 'Frigobar', 'Telefone IP', 'Área de Estar', 'Cofre para Computadores', 'Jornais diários', '120 DC', '220 DC'],
            'Negócios': ['Fax', 'Impressora', 'Computador', 'Sala de reuniões', 'Kitchenette', 'Micro-ondas', 'Torradeira', 'Pratos', 'Talheres'],
            'Banheiro': ['Banheira ou Duche', 'Banheiro Compartilhado', 'Ducha', 'Jacuzzi / hidromassagem'],
            'Entretenimento': ['Leitor de CD', 'Pay-TV', 'Dock para MP3/4', 'Video Clube'],
            'Outros': ['Tábua de Passar', 'Lareira', 'Cozinha']
        };

        // Usar no futuro
        // const tiposAmenidades = {
        //     'Infantil': ['Berço disponível a pedido', 'Parquinho', 'Babá a Pedido'],
        //     'Deficientes': ['Quartos para Deficientes'],
        //     'Cartões de Crédito': ['Aceita os principais cartões de crédito'],
        //     'Línguas': ['Equipe Poliglota'],
        //     'Negócios': ['Business Center', 'Fotocópias', 'Recepção 24 horas', 'Estacionamento', 'Estacionamento de ônibus', 'Mordomo', 'Posto de Turismo', 'Serviço de Quarto 24 horas'],
        //     'Alimentação': ['Bar', 'Restaurante', 'Serviços de Cocktails'],
        //     'Lazer': ['SPA', 'Ginásio', 'Piscina', 'Aluguel de bicicletas', 'Esportes aquáticos'],
        //     'Eventos': ['Salas de Reuniões', 'Palco', 'Serviços de Aluguel de automóveis', 'Auditório'],
        //     'Wi-Fi': ['Free Wifi'],
        //     'Esportes': ['Quadra de Padel', 'Academia de ginástica gratuita', 'Massagens a Pedido']
        // };

        const facilidadesOrganizadas = {};

        facilidades.forEach((facilidade) => {
            const comodo = Object.keys(comodos).find(key => comodos[key].includes(facilidade.name));

            if (comodo) {
                if (!facilidadesOrganizadas[comodo]) {
                    facilidadesOrganizadas[comodo] = [];
                }
                facilidadesOrganizadas[comodo].push(facilidade);
            }
        });

        return facilidadesOrganizadas;
    }

    function getIconForComodo(comodo) {
        switch (comodo.toLowerCase()) {
            case 'quarto':
                return <MeetingRoomIcon style={{ marginRight: "5px", marginLeft: "-10px" }} />;
            case 'banheiro':
                return <BathtubIcon style={{ marginRight: "5px", marginLeft: "-10px" }} />;
            case 'negócios':
                return <BusinessCenterIcon style={{ marginRight: "5px", marginLeft: "-10px" }} />;
            case 'entretenimento':
                return <TvIcon style={{ marginRight: "5px", marginLeft: "-10px" }} />;
            default:
                return '';
        }
    }

    function getIconForAmenidade(amenidade) {
        switch (amenidade.toLowerCase()) {
            case 'berço disponível a pedido':
                return <ChildCareIcon style={{ marginRight: "10px" }} />;
            case 'aceita os principais cartões de crédito':
                return <CreditCardIcon style={{ marginRight: "10px" }} />;
            case 'equipe poliglota':
                return <TranslateIcon style={{ marginRight: "10px" }} />;
            case 'business center':
                return <BusinessIcon style={{ marginRight: "10px" }} />;
            case 'internet banda larga':
                return <NetworkCheckIcon style={{ marginRight: "10px" }} />;
            case 'recepção 24 horas':
                return <QueryBuilderIcon style={{ marginRight: "10px" }} />;
            case 'estacionamento':
                return <LocalParkingIcon style={{ marginRight: "10px" }} />;
            case 'estacionamento de ônibus':
                return <DirectionsBusIcon style={{ marginRight: "10px" }} />;
            case 'serviço de quarto 24 horas':
                return <RoomServiceIcon style={{ marginRight: "10px" }} />;
            case 'bar':
                return <LocalBarIcon style={{ marginRight: "10px" }} />;
            case 'restaurante':
                return <RestaurantMenuIcon style={{ marginRight: "10px" }} />;
            case 'spa':
                return <SpaIcon style={{ marginRight: "10px" }} />;
            case 'academia':
            case 'ginásio':
                return <FitnessCenterIcon style={{ marginRight: "10px" }} />;
            case 'piscina':
                return <PoolIcon />;
            case 'aluguel de bicicletas':
                return <DirectionsBikeIcon style={{ marginRight: "10px" }} />;
            case 'salas de reuniões':
                return <BusinessIcon style={{ marginRight: "10px" }} />;
            case 'serviços de aluguel de automóveis':
                return <DriveEtaIcon style={{ marginRight: "10px" }} />;
            case 'internet':
            case 'free wifi':
                return <WifiIcon style={{ marginRight: "10px" }} />;
            case 'café da manhã':
                return <LocalCafeIcon style={{ marginRight: "10px" }} />
            case 'lavanderia':
                return <LocalLaundryServiceIcon style={{ marginRight: "10px" }} />
            default:
                return amenidade; // Ícone padrão para amenidades não reconhecidas
        }
    }

    function filterHotels(searchString = "") {
        setPage(1)
        searchString = searchString.toLowerCase();

        let listhotel = [];

        props.listHotel.hotels?.forEach(hotel => {
            let atendeExclusivo = state.checkedExclusive ? hotel.hasExclusive : true;
            let atendeLaundry = state.checkedLaundry ? hotel.hasLaundry : true;
            let atendeParking = state.checkedParking ? hotel.hasParking : true;
            let atendePool = state.checkedPool ? hotel.hasPool : true;
            let atendeRestaurant = state.checkedRestaurant ? hotel.hasRestaurant : true;
            if (atendeExclusivo && atendeLaundry && atendeParking && atendePool && atendeRestaurant) {
                listhotel.push(hotel);
            }
        });

        return listhotel;
    }

    const handleSearchName = (search) => {
        let searchString = search.toLowerCase();

        setPage(1)

        let filteredHotels = props.listHotel.hotels.filter((item) =>
            item.name.toLowerCase().includes(searchString) || item.location.address.toLowerCase().includes(searchString)
        );

        if (search != "") {
            setFiltredHotels(filteredHotels)
        } else {
            setFiltredHotels(props.listHotel.hotels)
        }
    };

    const handleCloseOrder = (value) => {
        setPage(1)
        if (value == 1) {
            // Ordena por preço mais baixo primeiro
            filtredHotels.sort((a, b) => a.lowerPrices.priceTotal - b.lowerPrices.priceTotal);
        } else if (value === 2) {
            // Ordena por preço mais alto primeiro
            filtredHotels.sort((a, b) => b.lowerPrices.priceTotal - a.lowerPrices.priceTotal);
        }
        setAnchorEl(null);
    }

    const handleCloseOrderTarifas = () => {
        setAnchorEl(null);
    }

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setFiltredHotels(filterHotels());
    };

    useEffect(() => {
        if (props.listHotel != null) {
            setFiltredHotels(filterHotels());
        }
    }, [state])

    useEffect(() => {
        if (props.listHotel != null) {
            setFiltredHotels(props.listHotel.hotels);
            handleCloseOrder(1)
        }
    }, [props.listHotel])

    useEffect(() => {
        if (props.step == 1 && props.listHotel != null) {
            setFiltredHotels(props.listHotel.hotels);
            handleCloseOrder(1)
        }
    }, [props.step])

    const renderIconAndText = (hasFeature, icon, text) => {
        if (hasFeature) {
            return (
                <Tooltip title={text} aria-label="add">
                    <Typography style={{ color: "#424242", fontWeight: "500", fontSize: "16px", display: 'flex', alignItems: 'center', marginTop: "10px" }}>
                        {icon}
                    </Typography>
                </Tooltip>
            );
        }
        return null;
    };

    const quantityRoomsForHotel = (hotel) => {
        let total = 0;

        hotel.rooms.forEach(room => {
            total += room.availabilitys[0].quantity;
        });

        return (
            <>
                {total > 1 ?
                    (total < 10 ? (
                        total + " Quartos disponíveis"
                    )
                        :
                        (
                            <span style={{ backgroundColor: "#227950", fontWeight: "500", fontSize: "14px", padding: "4px 10px 4px 10px", borderRadius: "3px", marginBottom: "10px", width: "fit-content" }}>
                                <Typography style={{ color: "white", fontWeight: "500", fontSize: "13px", fontWeight: "600" }}>
                                    Exelente disponiblidade
                                </Typography>
                            </span>
                        )
                    ) : (

                        <span style={{ backgroundColor: "#D73B3B", fontWeight: "500", fontSize: "14px", padding: "4px 10px 4px 10px", borderRadius: "3px", marginBottom: "10px", width: "fit-content" }}>
                            <Typography style={{ color: "white", fontWeight: "500", fontSize: "13px", fontWeight: "600" }}>
                                {total} Quarto disponível
                            </Typography>
                        </span>

                    )}
            </>);

    };

    function formatPhoneNumber(value) {
        // Extrai os números do valor recebido
        const numbers = value.replace(/\D/g, '');

        // Verifica se temos o comprimento correto para a formatação desejada
        if (numbers.length === 10) {
            // Formata para o padrão (XXX) XXX-XXXX
            return `(${numbers.substr(0, 2)}) ${numbers.substr(2, 4)}-${numbers.substr(6)}`;
        }

        // Retorna o valor não formatado caso não atenda ao critério
        return value;
    }


    const scrollTo = (string) => {
        const politicaElement = document.getElementById(string);
        if (politicaElement) {
            politicaElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const customIcon = new Icon({
        iconUrl: IconsSource.locIcon,
        iconSize: [38, 38]
    })

    return (
        <>
            <Container maxWidth="lg">
                {/* Dialog de cotação transformar em component aaa */}
                <Dialog onClose={() => setOpenCotar(false)} aria-labelledby="customized-dialog-title" open={openCotar}>
                    <DialogTitle id="customized-dialog-title" onClose={() => setOpenCotar(false)}>
                        Cotação para hotel
                        {props.listCotacao.length != 0 ? (
                            <EmailChipInput setEmailsCotar={props.setEmailsCotar} emailsCotar={props.emailsCotar} />
                        ) : (
                            null
                        )}
                    </DialogTitle>
                    <DialogContent dividers style={{ borderRadius: "10px" }}>
                        <Grid container item xs={12}>
                            {props.listCotacao.length == 0 ? (
                                <>
                                    <Grid item xs={12} style={{ width: "500px", height: "200px", textAlign: "center", alignItems: "center", display: "flex", justifyContent: "center" }}>
                                        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                                            Adicione tarifas
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (<>
                                <Grid item xs={12}>
                                    <Typography>Cotação - {cookie.get('org').toLowerCase()}</Typography>
                                </Grid>
                            </>)}
                            {props.listCotacao?.map((acotar) => (
                                <>
                                    <Grid item xs={6} style={{ padding: "5px" }}>
                                        <Card sx={{
                                            minWidth: 325,
                                            justifyContent: "right",
                                            filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                            borderRadius: '30px', // Adicionando sombra nos outros lados
                                            borderBottom: 'none', // Removendo a sombra inferior,
                                            backgroundColor: "#FFFFFF",
                                            filter: 'none',  // Remove a sombra
                                            boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
                                            borderBottom: 'none',
                                            marginTop: "-45px"
                                        }}>
                                            <CardContent>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <Typography style={{ fontWeight: "600", color: "#c62828" }} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        {acotar.hotelname}
                                                    </Typography>
                                                    <IconButton onClick={() => props.handleClickRemoverDaListCotar(acotar.id)}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </Box>
                                                <Typography variant="body2">
                                                    {acotar.hotelendereco}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {acotar.description} (BRL)
                                                </Typography>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                                                    <Typography variant="body2" component="div">
                                                        <Box component="span" sx={{ borderRadius: '4px', padding: '2px 6px', marginRight: '8px' }}>
                                                            {acotar.cafedamanha ? (
                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                                                                    <FreeBreakfast style={{ marginRight: "7px" }} /> café da manhã
                                                                </Typography>
                                                            ) :
                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                                                                    <HighlightOffIcon style={{ marginRight: "7px" }} /> sem café da manhã
                                                                </Typography>
                                                            }
                                                        </Box>
                                                        <Box component="span" sx={{ borderRadius: '4px', padding: '2px 6px', marginRight: '8px' }}>
                                                            {!acotar.nonRefundable ? (
                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                                                                    <StarsIcon style={{ marginRight: "7px" }} /> Reembolsável
                                                                </Typography>
                                                            ) :
                                                                <Typography style={{ display: "flex", alignItems: "center", fontSize: "12px", marginTop: "-18px" }}>
                                                                    <NewReleasesOutlined style={{ marginRight: "7px" }} /> Não reembolsável
                                                                </Typography>
                                                            }
                                                        </Box>
                                                    </Typography>
                                                    <Typography variant="h6" component="div">
                                                        R${acotar.priceperday.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
                                                        <Typography variant="h8" component="div" style={{ textAlign: "right", fontSize: "12px" }}>
                                                            / Diária
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                                {/* {acotar.superFare ? (
                                                    <Grid item xs={12} style={{ textAlign: "right" }}>
                                                        <a style={{ backgroundColor: "#DF3333", marginLeft: "5px", color: "white", padding: "4px 10px 4px 10px", borderRadius: "6px" }}>
                                                            Oferta!
                                                        </a>
                                                    </Grid>
                                                ) : (
                                                    null
                                                )} */}
                                                <Typography variant="body1" component="div" sx={{ fontWeight: 'bold', marginTop: '8px' }}>
                                                    Total: R${acotar.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
                                                    {acotar.superFare ? (
                                                        <a style={{ backgroundColor: "#DF3333", marginLeft: "5px", color: "white", padding: "4px 10px 4px 10px", borderRadius: "6px" }}>
                                                            Oferta!
                                                        </a>) : (null)} inclui impostos e taxas
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </DialogContent>
                    {props.listCotacao.length != 0 ? (
                        <DialogActions>
                            <Button variant="contained" style={{ color: "white", backgroundColor: "#c62828" }} autoFocus onClick={() => { props.handleClickEnviarCotar(); setOpenCotar(false) }} color="primary">
                                Enviar cotação
                            </Button>
                        </DialogActions>
                    ) : (
                        null
                    )}
                </Dialog>
                {/* Dialog de cotação transformar em component aaa */}
                <Box mt={2} mb={0}>
                    <Grid container className={classes.mainGrid}>
                        <Grid item container xs={12} styles={{ marginBottom: 1 }}>
                            <Grid item xs={2}>
                                <Typography variant="h6" component="h1">Hotel</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <Box p={3}>
                                    <form autoComplete="off">
                                        <Grid container spacing={1}>
                                            {props.locations != null ? (
                                                <Grid item xs={12} md={3}>
                                                    <USearchFieldHotel {...{ itens: props.locations, value: props.locationid, setValue: props.setLocationid }}
                                                        label="Destino"
                                                        name="origem"
                                                        msgAlt="Digite ao menos 3 caracteres"
                                                        onChange={props.handleChangeAuto}
                                                    />
                                                </Grid>
                                            ) : (
                                                <Grid item xs={12} md={3} style={{
                                                    display: "grid",
                                                    justifyContent: "center",
                                                    alignContent: "center"
                                                }}>
                                                    <Skeleton variant="rect" width={250} height={40} />
                                                </Grid>
                                            )}
                                            <Grid item xs={12} md={2}>
                                                <UDatePicker date={props.checkin} setDate={props.setCheckin} disablePast={true} label="Entrada" />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <UDatePicker date={props.checkout} setDate={props.setCheckout} minDate={props.checkin} disablePast={true} label="Saída" />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MenuSelectField
                                                    stepperAdultos={props.stepperAdultos}
                                                    setStepperAdultos={props.setStepperAdultos}
                                                    stepperCriancas={props.stepperCriancas}
                                                    setStepperCriancas={props.setStepperCriancas}
                                                    stepperRooms={props.stepperRooms}
                                                    setStepperRooms={props.setStepperRooms}
                                                    setQuartos={props.setRoomsForHotel}
                                                    quartos={props.roomsForHotel}
                                                    fullScreen={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={1} style={{ alignSelf: 'center' }}>
                                                <Button className={classes.button} onClick={() => props.handleSearchHotel()} variant="contained" fullWidth>
                                                    <SearchOutlined />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid >
                </Box>
                <Grid container item xs={12} style={{ textAlign: "center", marginBottom: "-20px" }}>
                    {/* Botão de retornar */}
                    {props.step != 1 ? (
                        <a color="inherit" onClick={
                            () => { props.setStep(props.step - 1) }
                        }
                            style={{ alignSelf: "center", display: "flex", alignItems: "center", marginTop: "10px", marginLeft: "15px", cursor: "pointer" }}
                        >
                            <ArrowBackOutlined style={{ width: "20px" }} />
                            <span style={{ marginLeft: "5px" }}>
                                {props.step == 2 ? (
                                    <>
                                        Ver todas as propriedades
                                    </>
                                ) : (
                                    <>
                                        Ver hotel
                                    </>
                                )}
                            </span>
                        </a>
                    ) : (
                        null
                    )}
                </Grid>
            </Container >
            {
                props.listHotel != null ? (
                    <>
                        {props.step == 1 ? (
                            <Container container maxWidth="lg">
                                <Grid container>
                                    <Grid container item xs={3} style={{ padding: "40px 20px 20px 0px", maxHeight: "600px", position: "sticky", top: -160 }}>
                                        <Grid container item xs={12} style={{
                                            boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)',
                                            borderRadius: "8px",
                                            marginBottom: "17px",
                                            height: "150px"
                                        }}>
                                            <Grid item xs={12}>
                                                {props.locationUser != null ?
                                                    (
                                                        <Grid style={{ height: "100px" }}>
                                                            <MapContainer center={[props.locationUser.latitude, props.locationUser.longitude]} zoom={13}>
                                                                <TileLayer
                                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                />
                                                                <Marker position={[props.locationUser.latitude, props.locationUser.longitude]} icon={customIcon}>
                                                                    <Popup>
                                                                        Seu local. <br />
                                                                    </Popup>
                                                                </Marker>
                                                            </MapContainer>
                                                        </Grid>
                                                    ) : (
                                                        <Typography style={{ fontSize: "20px", color: "#424242", fontWeight: "bold" }}>Aceite a localização do seu navegador</Typography>
                                                    )}
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "center", padding: "12px" }}>
                                                {props.locationUser != null && props.listHotel != null ? (
                                                    <MapViewerHotels locUser={props.locationUser} locsHotels={props.listHotel.hotels} handleClickDisponiblity={props.handleClickDisponiblity} />
                                                ) : (
                                                    null
                                                )}
                                            </Grid>
                                        </Grid>
                                        <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none" }}></hr>
                                        <Grid container item xs={12} style={{
                                            boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)',
                                            borderRadius: "8px",
                                            marginTop: "17px",
                                            padding: "14px",
                                        }}>
                                            <Grid item xs={12}>
                                                <Typography style={{ fontSize: "20px", color: "#424242", fontWeight: "bold" }}>Buscar por nome</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginBottom: "16px" }}>
                                                <LinesTextField
                                                    placeholder="Pesquisar nome/bairro"
                                                    variant="outlined"
                                                    margin="dense"
                                                    fullWidth
                                                    onChange={e => handleSearchName(e.target.value)}
                                                />
                                            </Grid>
                                            <hr style={{ width: "100%", backgroundColor: "#CACACA", height: "1px", border: "none" }}></hr>
                                            <Grid item xs={12} style={{ marginTop: "16px" }}>
                                                <Typography style={{ fontSize: "18px", color: "#424242", fontWeight: "500" }}>Filtro por</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ marginTop: "16px" }}>
                                                <Typography style={{ fontSize: "14px", color: "#424242", fontWeight: "500" }}>Filtros mais usados</Typography>
                                            </Grid>
                                            <Grid container item xs={12} style={{ marginTop: "16px" }}>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.checkedExclusive} onChange={handleChange} name="checkedExclusive" />}
                                                        label={<Typography style={{ fontSize: "12px" }}>Exclusivo para empresas</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.checkedLaundry} onChange={handleChange} name="checkedLaundry" />}
                                                        label={<Typography style={{ fontSize: "12px" }}>Lavanderia</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.checkedParking} onChange={handleChange} name="checkedParking" />}
                                                        label={<Typography style={{ fontSize: "12px" }}>Estacionamento</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.checkedPool} onChange={handleChange} name="checkedPool" />}
                                                        label={<Typography style={{ fontSize: "12px" }}>Piscina</Typography>}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.checkedRestaurant} onChange={handleChange} name="checkedRestaurant" />}
                                                        label={<Typography style={{ fontSize: "12px" }}>Restaurante</Typography>}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={9} container style={{ padding: "58px 0px 0px 0px" }}>
                                        <Grid container item xs={12} style={{ textAlign: "left", justifyContent: "left" }}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={6}>
                                                    <Typography style={{ fontSize: "20px", fontWeight: "600" }}>{filtredHotels.length} acomodações encontradas</Typography>
                                                </Grid>
                                                <Grid item xs={6} style={{ justifyContent: "right", textAlign: "right" }}>
                                                    <Button style={{
                                                        fontWeight: "600",
                                                        lineHeight: "15px",
                                                        fontSize: "15px",
                                                        fontFamily: "HindBold",
                                                        alignItems: "center",
                                                        color: "#131313"
                                                    }} aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleOpenCotar()}>
                                                        <Badge badgeContent={props.listCotacao.length} color="error">
                                                            <HowToVoteIcon style={{ marginBottom: "4px", color: "#131313", marginRight: "6px" }} />
                                                        </Badge>
                                                        Cotação
                                                    </Button>
                                                    <Button style={{
                                                        fontWeight: "600",
                                                        lineHeight: "15px",
                                                        fontSize: "15px",
                                                        fontFamily: "HindBold",
                                                        alignItems: "center",
                                                        color: "#131313"
                                                    }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                                        <ImportExportIcon style={{ marginBottom: "4px", color: "#131313" }} /> Ordenar
                                                    </Button>
                                                    <Menu
                                                        id="simple-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl)}
                                                        onClose={handleCloseOrder}
                                                    >
                                                        {props.orderprices.map((value) => (
                                                            <MenuItem onClick={() => handleCloseOrder(value.value)}>{value.label}</MenuItem>
                                                        ))}
                                                    </Menu>
                                                </Grid>
                                            </Grid>
                                            {todasHoteis[0]?.location?.id == 1027131 && (
                                                <Grid item xs={12} style={{ display: "flex", alignItems: "center", borderRadius: "4px", color: "#ffffff", background: "#3f51b5", padding: "2px" }}>
                                                    <LocalOffer style={{ margin: "5px", color: "#ffffff" }} />
                                                    <Typography variant="body2">Atenção! Os hotéis de Criciúma têm ofertas especiais negociadas com a Linestur para a sua empresa. Os preços listados a seguir são os valores normais. Para verificar as condições, consulte sua agente designada, seja por solicitação ou via WhatsApp.</Typography>
                                                </Grid>
                                            )}
                                            {(todasHoteis)?.map((item) => (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        container
                                                        style={{
                                                            boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)',
                                                            borderRadius: "6px",
                                                            marginTop: "20px",

                                                        }}
                                                    >
                                                        <Grid item xs={3} style={{ maxHeight: "250px", minHeight: "250px", position: "relative" }}>
                                                            <img
                                                                src={item.thumbImage}
                                                                onError={(e) => { e.target.src = defaultlinesimg; }}
                                                                alt={"Imagem não encontrada"}
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'block',
                                                                    height: "100%",
                                                                    borderRadius: "6px 0px 0px 6px",
                                                                    maxHeight: "auto",
                                                                    objectFit: 'cover'
                                                                }}
                                                            />
                                                            {todasHoteis[0]?.location?.id == 1027131 && (
                                                                <div style={{ borderRadius: "50%", background: "#3f51b5", color: "#ffffff", right: "10px", bottom: "10px", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                                                                    <LocalOffer style={{ color: "#ffffff" }} />
                                                                </div>
                                                            )}
                                                        </Grid>
                                                        <Grid container item xs={9} style={{}}>
                                                            <Grid item xs={6} style={{ padding: "10px", display: "flex", flexDirection: "column" }}>
                                                                <Typography style={{ color: "#c62828", fontWeight: "700", fontSize: "18px" }}>{item.name}</Typography>
                                                                <Typography style={{ color: "#424242", fontWeight: "500", fontSize: "15px" }}>{item.location.address}</Typography>
                                                                <Grid style={{ padding: "0px", display: "flex", flexDirection: "row" }}>
                                                                    {renderIconAndText(item.hasExclusive, <ApartmentIcon style={{ color: "#424242", width: "20px", marginRight: '5px', width: '20px' }} />, 'Exclusivo')}
                                                                    {renderIconAndText(item.hasLaundry, <LocalLaundryServiceIcon style={{ color: "#424242", width: "20px", marginRight: '5px', width: '20px' }} />, 'Lavanderia')}
                                                                    {renderIconAndText(item.hasParking, <LocalParkingIcon style={{ color: "#424242", width: "20px", marginRight: '5px', width: '20px' }} />, 'Estacionamento')}
                                                                    {renderIconAndText(item.hasPool, <PoolIcon style={{ color: "#424242", width: "20px", marginRight: '5px', width: '20px' }} />, 'Piscina')}
                                                                    {renderIconAndText(item.hasRestaurant, <RestaurantIcon style={{ color: "#424242", width: "20px", marginRight: '5px', width: '20px' }} />, 'Restaurante')}
                                                                </Grid>
                                                                <Typography style={{ borderRadius: "6px", color: "#424242", fontWeight: "500", fontSize: "14px", marginTop: 'auto' }}>
                                                                </Typography>
                                                                {quantityRoomsForHotel(item)}
                                                            </Grid>
                                                            <Grid item xs={6}
                                                                style={{
                                                                    padding: "10px",
                                                                    justifyContent: "right",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end"
                                                                }}>
                                                                <Typography style={{ color: "#424242", fontWeight: "500", fontSize: "19px" }}>{item.lowerPrices?.description}</Typography>
                                                                <div style={{
                                                                    maxHeight: "22px", // altura máxima baseada no lineHeight para 1 linha
                                                                    overflowY: "auto", // ativa a rolagem vertical automaticamente quando necessário
                                                                    fontFamily: "HindBold", // assegura que a fonte do contêiner combine com a do Typography
                                                                }}>
                                                                    <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "16px" }}>Regime: {item.lowerPrices?.planDescription}</Typography>
                                                                </div>
                                                                <Grid container style={{
                                                                    flexDirection: "column",
                                                                    alignContent: "flex-end",
                                                                    alignItems: "flex-end",
                                                                    marginTop: "auto"
                                                                }}>
                                                                    <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "14px" }}>Diária / <b style={{ fontFamily: "HindBold" }}>{item.lowerPrices?.pricePerDay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></Typography>
                                                                    <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "14px" }}>
                                                                        Total para {Math.floor((new Date(props.checkout) - new Date(props.checkin)) / (1000 * 60 * 60 * 24))} diária(s)
                                                                    </Typography>
                                                                    <Typography style={{ color: "#353535", fontWeight: "500", fontSize: "20px", fontFamily: "HindBold", }}>{item.lowerPrices?.priceTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                                                    <Button className={classes.button} onClick={
                                                                        () => props.handleClickDisponiblity(item.id, item.rooms, item.lowerPrices, item.thumbImage)
                                                                    }>
                                                                        Ver disponiblidade
                                                                    </Button>
                                                                    {item.lowerPrices.priceWithTax != 0 ? (
                                                                        <Typography style={{ color: "#6D6D6D", fontWeight: "500", fontSize: "14px" }}>{item.lowerPrices?.priceWithTax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} de impostos</Typography>
                                                                    ) : (
                                                                        null
                                                                    )}
                                                                    <Typography style={{ color: "#c62828", fontWeight: "500", fontSize: "11px" }}>{item.lowerPrices?.nonRefundable ? ("* COBRANÇA DE MULTAS PARA CANCELAMENTOS") : (null)}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ))}
                                            <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", margin: "20px" }}>
                                                <Pagination
                                                    count={pages} // Total de páginas
                                                    page={page} // Página atual
                                                    onChange={handleChangePage} // Função para mudar a página
                                                    variant="outlined"
                                                    shape="rounded"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                        ) : (
                            <>
                                {/* Aparece após o usuario clicar em ver disponiblidade */}
                                {props.step == 2 ? (
                                    <Container container maxWidth="lg">
                                        {props.hotel != null ? (
                                            <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                                <Grid container>
                                                    <Grid item xs={8} style={{ padding: "20px", marginBottom: "-20px" }}>
                                                        <ImageSlider images={props.hotel.images} />
                                                    </Grid>

                                                    <Grid id="#hotel" container item xs={4} style={{
                                                        padding: "20px",

                                                    }}>
                                                        <Grid item xs={12} container style={{
                                                            marginTop: "0px",
                                                            padding: "22px",
                                                            width: '100px',
                                                            flexShrink: 0,
                                                            fill: '#FFF',
                                                            top: "40%",
                                                            filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                            borderRadius: '10px', // Adicionando sombra nos outros lados
                                                            borderBottom: 'none', // Removendo a sombra inferior,
                                                            backgroundColor: "#FFFFFF",
                                                            filter: 'none',  // Remove a sombra
                                                            boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                            borderBottom: 'none'
                                                        }}>
                                                            <Grid item xs={12} style={{ textAlign: "left" }}>
                                                                <Typography style={{ fontSize: "18px", fontWeight: "600", lineHeight: "32px", fontFamily: "HindBold" }}>Sua hospedagem</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ textAlign: "center", marginTop: "22px" }}>
                                                                <Typography style={{ fontSize: "34px", fontWeight: "600", lineHeight: "32px", fontFamily: "HindBold" }}>
                                                                    {props.hotel.lowerPrices.pricePerDay.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<span style={{ fontSize: "18px" }}>/diária</span>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ marginTop: "12px" }}>
                                                                <Grid item xs={12} style={{
                                                                    fill: '#FFF',
                                                                    top: "40%",
                                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                    borderRadius: '10px', // Adicionando sombra nos outros lados
                                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                                    backgroundColor: "#FFFFFF",
                                                                    filter: 'none',  // Remove a sombra
                                                                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                    padding: "17px"
                                                                }}>
                                                                    <Typography style={{ fontSize: "16px", fontWeight: "600", lineHeight: "32px", fontFamily: "HindBold" }}>{props.hotel.lowerPrices.description}</Typography>
                                                                </Grid>
                                                                <Grid item xs={12} style={{
                                                                    fill: '#FFF',
                                                                    top: "40%",
                                                                    marginTop: "10px",
                                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                    borderRadius: '10px', // Adicionando sombra nos outros lados
                                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                                    backgroundColor: "#FFFFFF",
                                                                    filter: 'none',  // Remove a sombra
                                                                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                    padding: "17px",
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}>
                                                                    {props.hotel.lowerPrices.planDescription.toLowerCase() == "café da manhã" ? (
                                                                        <FreeBreakfast style={{ marginRight: "12px" }} />
                                                                    ) : (
                                                                        null
                                                                    )}
                                                                    <div style={{
                                                                        maxHeight: "32px", // altura máxima baseada no lineHeight para 1 linha
                                                                        overflowY: "auto", // ativa a rolagem vertical automaticamente quando necessário
                                                                        fontFamily: "HindBold", // assegura que a fonte do contêiner combine com a do Typography
                                                                    }}>
                                                                        <Typography style={{ fontSize: "16px", fontWeight: "600", lineHeight: "32px", fontFamily: "HindBold" }}>
                                                                            {props.hotel.lowerPrices.planDescription}
                                                                        </Typography>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={6} style={{ marginTop: "18px" }}>
                                                                <Grid item xs={12}>
                                                                    <Typography style={{ fontSize: "15px", fontWeight: "600", lineHeight: "32px", fontFamily: "HindBold" }}>
                                                                        Valor final pra
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} style={{ marginTop: "-20px" }}>
                                                                    <Typography style={{ fontSize: "15px", fontWeight: "600", lineHeight: "32px", fontFamily: "HindBold", color: "#c62828" }}>
                                                                        {Math.floor((new Date(props.checkout) - new Date(props.checkin)) / (1000 * 60 * 60 * 24))} diária(s)
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={6} style={{
                                                                textAlign: "right", justifyContent: "center",
                                                                display: "flex", alignSelf: "center"
                                                            }}>
                                                                <Typography style={{ fontSize: "28px", fontWeight: "600", lineHeight: "32px", fontFamily: "HindBold" }}>
                                                                    {props.hotel.lowerPrices.priceTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ textAlign: "right", margin: "-40px -30px -40px" }}>
                                                                <p>Impostos e taxas inclusos</p>
                                                            </Grid>
                                                            <hr style={{ width: "100%", backgroundColor: "#CACACA", height: "1px", border: "none" }}></hr>
                                                            <Grid item xs={12}>
                                                                <Button className={classes.button} fullWidth onClick={() => scrollTo("#Quartos")}>Ver quartos</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} style={{ padding: "20px", marginBottom: "-48px" }}>
                                                        <Typography style={{ fontSize: "25px", fontWeight: "600", lineHeight: "22px", fontFamily: "HindBold" }}>{props.hotel.name}</Typography>
                                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                                                            <StyledRating name="read-only" size="small" value={4} readOnly />
                                                            <Typography style={{ fontSize: "14px", lineHeight: "12px", marginLeft: "4px" }}> {props.hotel.location.address}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: "-12px" }}>
                                                            CONTATO -
                                                            {props.hotel.contact.phones.map((value) => (
                                                                <p style={{ marginLeft: "4px", marginRight: "2px" }}>
                                                                    {formatPhoneNumber(value)}
                                                                </p>
                                                            ))}
                                                        </Grid>
                                                        {todasHoteis[0]?.location?.id == 1027131 && (
                                                            <Grid item xs={8} style={{ display: "flex", alignItems: "center", borderRadius: "4px", color: "#ffffff", background: "#3f51b5", padding: "2px" }}>
                                                                <LocalOffer style={{ margin: "5px", color: "#ffffff" }} />
                                                                <Typography variant="body2">Atenção! Os hotéis de Criciúma têm ofertas especiais negociadas com a Linestur para a sua empresa. Os preços listados a seguir são os valores normais. Para verificar as condições, consulte sua agente designada, seja por solicitação ou via WhatsApp.</Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <Grid container item xs={12} style={{ padding: "20px" }}>
                                                        <Grid id="#Quartos" container item xs={8}>
                                                            <p style={{ fontSize: "12px" }}>
                                                                {description.length > limite && !isExpanded ? `${description.substring(0, limite)}...` : description}
                                                                {description.length > limite && (
                                                                    <a onClick={handleClickString} style={{ marginLeft: "5px", fontSize: "12px", cursor: "pointer" }}>
                                                                        {isExpanded ? 'ver menos' : 'ver mais'}
                                                                    </a>
                                                                )}
                                                            </p>
                                                        </Grid>
                                                        <Grid item xs={4} style={{
                                                            justifyContent: "center",
                                                            textAlign: "center", // Adicione esta linha
                                                            backgroundSize: "cover", // Isso fará com que a imagem cubra todo o espaço disponível
                                                            backgroundPosition: "center",
                                                            padding: "0px 0px 0px 24px"
                                                        }}>
                                                            <Grid item xs={12} style={{
                                                                borderRadius: "8px",
                                                                justifyContent: "right",
                                                                filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                borderRadius: '10px', // Adicionando sombra nos outros lados
                                                                borderBottom: 'none', // Removendo a sombra inferior,
                                                                backgroundColor: "#FFFFFF",
                                                                filter: 'none',  // Remove a sombra
                                                                boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                borderBottom: 'none',
                                                                marginTop: "-45px"
                                                            }}>
                                                                <Grid container item xs={12} style={{
                                                                    borderRadius: "10px",
                                                                    justifyContent: "right"
                                                                }}>
                                                                    <Grid item xs={12} style={{ height: "100px" }}>
                                                                        <MapContainer
                                                                            center={[props.hotel.location.latitude, props.hotel.location.longitude]}
                                                                            zoom={13}
                                                                            scrollWheelZoom={false}
                                                                            dragging={false}
                                                                            touchZoom={false}
                                                                            doubleClickZoom={false}
                                                                            zoomControl={false}
                                                                            style={{ pointerEvents: 'none' }}>
                                                                            <TileLayer
                                                                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                            />
                                                                            <Marker position={[props.hotel.location.latitude, props.hotel.location.longitude]} icon={customIcon}>
                                                                            </Marker>
                                                                        </MapContainer>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container item xs={12} style={{ padding: "8px" }}>
                                                                    <Grid item xs={12} style={{ marginTop: "7px", textAlign: "center" }}>
                                                                        <MapViewerHotel hotel={props.hotel} />
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={12} style={{ padding: "10px" }}>
                                                        <Grid container id="#Quartos">
                                                            <Grid container item xs={12} style={{ textAlign: "left", justifyContent: "left", padding: "20px", marginTop: "-20px", marginBottom: "-50px" }}>
                                                                <Grid item xs={6} style={{ textAlign: "left", justifyContent: "left", padding: "20px", marginTop: "-20px", marginBottom: "-20px" }}>
                                                                    <a style={{
                                                                        fontWeight: "600",
                                                                        fontSize: "15px",
                                                                        color: "white",
                                                                        backgroundColor: "#d32f2f", // Uma cor vermelha mais escura para indicar uma ação de remover
                                                                        border: "none",
                                                                        cursor: "pointer",
                                                                        padding: "10px 20px",
                                                                        borderRadius: "30px",
                                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                                        transition: "background-color 0.3s, transform 0.3s",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        width: "fit-content"
                                                                    }}>
                                                                        <SingleBedIcon style={{ marginRight: "5px" }} />
                                                                        Quartos disponíveis
                                                                    </a>
                                                                </Grid>
                                                                <Grid item xs={6} style={{ textAlign: "right", justifyContent: "right", padding: "20px", marginTop: "-20px", marginBottom: "-50px" }}>
                                                                    <Button style={{
                                                                        fontWeight: "600",
                                                                        lineHeight: "15px",
                                                                        fontSize: "15px",
                                                                        fontFamily: "HindBold",
                                                                        alignItems: "center",
                                                                        color: "#131313"
                                                                    }} aria-controls="simple-menu" aria-haspopup="true" onClick={() => handleOpenCotar()}>
                                                                        <Badge badgeContent={props.listCotacao.length} color="error">
                                                                            <HowToVoteIcon style={{ marginBottom: "4px", color: "#131313", marginRight: "6px" }} />
                                                                        </Badge>
                                                                        Cotação
                                                                    </Button>
                                                                    {props.hotelprices != null ? (
                                                                        <Button style={{
                                                                            fontWeight: "600",
                                                                            lineHeight: "15px",
                                                                            fontSize: "15px",
                                                                            fontFamily: "HindBold",
                                                                            alignItems: "center",
                                                                            color: "#131313"
                                                                        }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                                                            <ImportExportIcon style={{ marginBottom: "4px", color: "#050404" }} /> Ordenar Tarifas
                                                                        </Button>
                                                                    ) : (null)}
                                                                    <Menu
                                                                        id="simple-menu"
                                                                        anchorEl={anchorEl}
                                                                        keepMounted
                                                                        open={Boolean(anchorEl)}
                                                                        onChange={(e) => onClickOrderTarifa(e.target.value)}
                                                                        onClose={handleCloseOrderTarifas}
                                                                    >
                                                                        {props.orderprices.map((value) => (
                                                                            <MenuItem value={value.value} onClick={() => onClickOrderTarifa(value.value)}>{value.label}</MenuItem>
                                                                        ))}
                                                                    </Menu>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={12}>
                                                                <Grid item xs={12} style={{ padding: "10px" }}>

                                                                    <Grid container item xs={12} style={{
                                                                        // padding: "18px",
                                                                        width: '100%',
                                                                        height: 'auto',
                                                                        flexShrink: 0,
                                                                        fill: '#FFF',
                                                                        top: "40%",
                                                                        borderRadius: '10px',
                                                                        borderBottom: 'none',
                                                                    }}>
                                                                        <Grid item xs={12} style={{ padding: "20px", justifyContent: "right" }}>
                                                                            <Grid item xs={12}>
                                                                                <RoomBooking
                                                                                    hotel={props.hotel}
                                                                                    hotelplan={props.hotelprices}
                                                                                    numberOfRooms={props.roomsForHotel.length}
                                                                                    roomSelections={props.roomSelections}
                                                                                    setRoomSelections={props.setRoomSelections}
                                                                                    handleChangeRoom={props.handleChangeRoom}
                                                                                    hotelrules={props.hotelrules}
                                                                                    setListCotacao={props.setListCotacao}
                                                                                    listCotacao={props.listCotacao}
                                                                                    checkin={props.checkin}
                                                                                    checkout={props.checkout}
                                                                                    adutnumber={props.stepperAdultos}
                                                                                    criancasnumber={props.stepperCriancas}
                                                                                    orderTarifas={tarifaOrder}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid id="#Facilidades" container item xs={12} style={{
                                                            marginTop: "14px",
                                                            padding: "18px",
                                                            width: '100%',
                                                            height: 'auto',
                                                            flexShrink: 0,
                                                            fill: '#FFF',
                                                            top: "40%",
                                                            borderRadius: '0px', // Adicionando sombra nos outros lados
                                                            borderBottom: 'none', // Removendo a sombra inferior,
                                                            backgroundColor: "#FFFFFF",
                                                            filter: 'none',  // Remove a sombra
                                                            boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                            borderBottom: 'none'
                                                        }}>
                                                            <Grid container item xs={12}>
                                                                <Grid container item xs={12} style={{ padding: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography style={{ fontSize: "24px", fontWeight: "600", lineHeight: "32px" }}>Comodidades {props.hotel.name}</Typography>
                                                                    </Grid>
                                                                    {props.hotel.amenities.map((comodo, index) => (
                                                                        <>
                                                                            {getIconForAmenidade(comodo.name) != null ? (
                                                                                <Grid container item xs={3} key={index} style={{ padding: "10px" }}>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography style={{ fontSize: "13px", fontWeight: "500", lineHeight: "24px", display: "inline-flex", alignItems: "center" }}>
                                                                                            {getIconForAmenidade(comodo.name)} {comodo.name}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ) : null}
                                                                        </>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                            {/* <Grid container item xs={12}>
                                                                <Grid container item xs={12} style={{ padding: "10px" }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography style={{ fontSize: "24px", fontWeight: "600", lineHeight: "32px" }}>Facilidades dos quartos</Typography>
                                                                    </Grid>
                                                                    {Object.keys(facilidades).map((comodo, index) => (
                                                                        <Grid container item xs={3} key={index} style={{ padding: "10px" }}>
                                                                            <Grid container item xs={12}>
                                                                                <Grid item xs={12}>
                                                                                    <Typography style={{ fontSize: "20px", fontWeight: "600", lineHeight: "24px", display: "inline-flex", alignItems: "center" }}>{getIconForComodo(comodo)} {comodo}</Typography>
                                                                                    {facilidades[comodo].map((facilidade, index) => (
                                                                                        <Typography key={index}>{facilidade.name}</Typography>
                                                                                    ))}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid container justify="center" alignItems="center" style={{ height: "100%" }} item xs={12}>
                                                    <CircularProgress size={60} style={{ color: '#c62828' }} />
                                                </Grid>
                                            </>
                                        )}
                                    </Container>
                                ) : (
                                    // Aparece após o usuario escolher um quarto e uma tarifa
                                    <Container container maxWidth="lg">
                                        <Grid container item xs={12} style={{ padding: "20px", marginBottom: "20px" }}>
                                            <Grid container item xs={8} style={{ padding: "0px 30px 0px 0px" }}>
                                                <Grid item xs={12}>

                                                    {props.roomSelections.map((value, index) => (
                                                        <Grid item xs={12} container style={{
                                                            marginTop: "14px",
                                                        }}>
                                                            <>
                                                                <Grid item xs={12} style={{
                                                                    marginTop: "14px",
                                                                    padding: "18px",
                                                                    flexShrink: 0,
                                                                    fill: '#FFF',
                                                                    top: "40%",
                                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                    borderRadius: '10px', // Adicionando sombra nos outros lados
                                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                                    backgroundColor: "#FFFFFF",
                                                                    filter: 'none',  // Remove a sombra
                                                                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                    borderBottom: 'none'
                                                                }}>
                                                                    <Typography style={{ fontWeight: "600", fontSize: "20px" }}>Titular da reserva</Typography>
                                                                    {/* <Typography>Será a pessoa responsável por fazer check-in e check-out da hospedagem</Typography> */}
                                                                    <Grid item xs={12} style={{ justifyContent: "center", textAlign: "center" }}>
                                                                        <RoomOccupants
                                                                            roomNumber={index + 1}
                                                                            valueRoom={value}
                                                                            pessoas={props.pessoas}
                                                                            pessoa={props.pessoa}
                                                                            setPessoa={props.setPessoa}
                                                                            centrosdecusto={props.centrosdecusto}
                                                                            centrodecusto={props.centrodecusto}
                                                                            setCentroDeCusto={props.setCentroDeCusto}
                                                                            paxsForRooms={props.paxsForRooms}
                                                                            setPaxsForRooms={props.setPaxsForRooms}
                                                                            stepperAdultos={props.stepperAdultos}
                                                                            stepperCriancas={props.stepperCriancas}
                                                                            handleClickAdicionarHospede={props.handleClickAdicionarHospede}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container item xs={12} style={{
                                                                    marginTop: "14px",
                                                                    padding: "18px",
                                                                    flexShrink: 0,
                                                                    fill: '#FFF',
                                                                    top: "40%",
                                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                    borderRadius: '6px', // Adicionando sombra nos outros lados
                                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                                    backgroundColor: "#FFFFFF",
                                                                    filter: 'none',  // Remove a sombra
                                                                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                    borderBottom: 'none'
                                                                }}>
                                                                    <Grid item xs={12}>
                                                                        <Typography style={{ fontWeight: "600", fontSize: "20px" }}>Solicitante da reserva</Typography>
                                                                        {/* <Typography>A pessoa rensponsável por realizar a reserva </Typography> */}
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <USearchField itens={props.solicitantes} value={props.solicitante} setValue={props.setSolicitante} label="Solicitante" />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container item xs={12} style={{
                                                                    marginTop: "14px",
                                                                    padding: "18px",
                                                                    flexShrink: 0,
                                                                    fill: '#FFF',
                                                                    top: "40%",
                                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                                    borderRadius: '6px', // Adicionando sombra nos outros lados
                                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                                    backgroundColor: "#FFFFFF",
                                                                    filter: 'none',  // Remove a sombra
                                                                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                                    borderBottom: 'none'
                                                                }}>
                                                                    <Typography style={{ fontWeight: "500", fontSize: "20px", marginBottom: "15px" }}>Políticas de cancelamento {props.hotel.name}</Typography>
                                                                    {value.cancelpolicy?.cancellationPolicies.map((item, index) => (
                                                                        <>
                                                                            <Grid container item xs={12}>
                                                                                <Grid item xs={12}>
                                                                                    {item.deadLineType == "DeadLine" ? (
                                                                                        <>
                                                                                            <Typography style={{ fontWeight: "600", fontSize: "16px", marginBottom: "10px" }}>DeadLine - Prazo para o cancelamento.</Typography>
                                                                                            <Typography style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                                {/* {item.penaltyDescription} */}
                                                                                                <br></br>
                                                                                                {item.deadLineDescription}
                                                                                            </Typography>
                                                                                        </>
                                                                                    ) : (
                                                                                        null
                                                                                    )}
                                                                                    {item.deadLineType == "NoShow" ? (
                                                                                        <>
                                                                                            <Typography style={{ fontWeight: "600", fontSize: "16px", marginBottom: "10px", marginTop: "10px" }}>No-Show - Ao não comparecer a uma reserva confirmada</Typography>
                                                                                            <Typography style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                                Cancelar depois da data de <b>Check-In</b> Entrar em contato com a agente
                                                                                            </Typography>
                                                                                        </>
                                                                                    ) : (
                                                                                        null
                                                                                    )}
                                                                                    {item.deadLineType == "ImmediateDeadLine" ? (
                                                                                        <>
                                                                                            <Typography style={{ fontWeight: "600", fontSize: "16px", marginBottom: "10px" }}>Tarifa Irrevogável. {item.amountValue}% do valor total</Typography>
                                                                                            <Typography style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                                {/* {item.penaltyDescription} */}
                                                                                                <br></br>
                                                                                                {item.deadLineDescription}
                                                                                            </Typography>
                                                                                        </>
                                                                                    ) : (
                                                                                        null
                                                                                    )}
                                                                                </Grid>
                                                                                <Grid item xs={2} style={{ textAlign: "left", marginTop: "15px" }}>
                                                                                    <Button fullWidth className={classes.button} onClick={() => props.handleClickReservar()}>
                                                                                        Reservar
                                                                                    </Button>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    ))}
                                                                </Grid>
                                                            </>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={4} style={{ padding: "26px 10px 10px 0px", maxHeight: "600px", marginBottom: "40px" }}>
                                                <Grid container item xs={12} style={{
                                                    flexShrink: 0,
                                                    fill: '#FFF',
                                                    top: "40%",
                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                    borderRadius: '10px', // Adicionando sombra nos outros lados
                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                    backgroundColor: "#FFFFFF",
                                                    filter: 'none',  // Remove a sombra
                                                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                    borderBottom: 'none',
                                                }}>
                                                    <Grid container item xs={12} style={{ padding: "20px", marginBottom: "-38px" }}>
                                                        <Grid item xs={6} style={{ display: "flex", height: "100%", textAlign: "left", alignItems: "center" }}>
                                                            <Typography style={{ fontSize: "15px" }}>
                                                                Preço por dia
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ display: "flex", height: "100%", textAlign: "right", justifyContent: "right", alignItems: "center" }}>
                                                            <Typography style={{ fontSize: "15px" }}>
                                                                {props.valuesHotel.totalpordia.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid container item xs={12} style={{ padding: "20px", marginBottom: "-20px" }}>
                                                        <Grid item xs={9} style={{ display: "flex", height: "100%", textAlign: "left", alignItems: "center" }}>
                                                            <Typography style={{ fontSize: "15px" }}>
                                                                Impostos, taxas e encargos
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} style={{ display: "flex", height: "100%", textAlign: "right", justifyContent: "right", alignItems: "center" }}>
                                                            <Typography style={{ fontSize: "15px" }}>
                                                                {props.valuesHotel.totaltax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid> */}
                                                    <Grid container item xs={12} style={{ textAlign: "right", padding: "20px" }}>
                                                        <Grid item xs={6} style={{ display: "flex", height: "100%", textAlign: "left", alignItems: "center" }}>
                                                            <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
                                                                TOTAL
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ display: "flex", alignItems: "center", height: "100%", textAlign: "right", justifyContent: "right" }}>
                                                            <a style={{ fontSize: "16px", marginTop: "6px" }}>
                                                                R$
                                                            </a>
                                                            <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                                                                {props.valuesHotel.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', '')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ textAlign: "right", justifyContent: "right", marginTop: "-10px" }}>
                                                            <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                                                                Inclui {props.valuesHotel.totaltax.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} de impostos e taxas
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontSize: "24px", fontWeight: "500", marginTop: "18px", marginBottom: "16px" }}>Detalhes da reserva</Typography>
                                                </Grid>
                                                <Grid container item xs={12} style={{
                                                    marginTop: "4px",
                                                    flexShrink: 0,
                                                    fill: '#FFF',
                                                    top: "40%",
                                                    filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
                                                    borderRadius: '10px', // Adicionando sombra nos outros lados
                                                    borderBottom: 'none', // Removendo a sombra inferior,
                                                    backgroundColor: "#FFFFFF",
                                                    filter: 'none',  // Remove a sombra
                                                    boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
                                                    borderBottom: 'none',
                                                }}>
                                                    <Grid container item xs={12} style={{ padding: "15px 16px 15px 16px" }}>
                                                        <Grid item xs={12} style={{ marginTop: "0px" }}>
                                                            <KingBed />
                                                            <Typography style={{ fontSize: "24px", fontWeight: "500" }}>{props.hotel.name}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography style={{ fontSize: "14px" }}>{props.hotel.location.address}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none" }}></hr>
                                                    </Grid>
                                                    <Grid container item xs={12} style={{ backgroundColor: "#FFFFFF", marginTop: "0px", padding: "20px", }}>
                                                        <Grid container item xs={12} style={{ marginTop: "0px" }}>
                                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                                <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#6B6B6B" }}>Entrada</Typography>
                                                            </Grid>
                                                            <Grid item xs={6} style={{ textAlign: "left" }}>
                                                                <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#6B6B6B", marginLeft: "8px" }}>Saída</Typography>
                                                            </Grid>
                                                            <Grid container item xs={12} style={{ marginTop: "10px" }}>
                                                                <Grid item xs={6}>
                                                                    <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#464646" }}>
                                                                        {new Date(props.checkin).toLocaleDateString('pt-BR', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6} style={{ borderLeft: '1px solid #0000003B', padding: '0px 8px 8px 8px' }}>
                                                                    <Typography style={{ fontSize: "14px", fontWeight: "600", color: "#464646" }}>
                                                                        {new Date(props.checkout).toLocaleDateString('pt-BR', { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} style={{ marginTop: "30px", marginBottom: "16px" }}>
                                                            <Typography style={{ fontSize: "14px", color: "#6B6B6B" }}>{Math.floor((new Date(props.checkout) - new Date(props.checkin)) / (1000 * 60 * 60 * 24))} noite(s), {props.roomSelections.length} quarto(s) para {props.stepperAdultos + props.stepperCriancas} hóspede(s)</Typography>
                                                            {props.roomSelections?.map((item) => (
                                                                <>
                                                                    <Typography style={{ fontSize: "16px", fontWeight: "500", color: "#6B6B6B", marginBottom: "10px" }}>{item.name}</Typography>
                                                                    <Grid item xs={12} style={{ display: "flex" }}>
                                                                        {
                                                                            item.plano?.cafedamanha ? (
                                                                                <Chip
                                                                                    icon={<FreeBreakfastOutlined style={{ color: "#2573B3" }} />}
                                                                                    style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                    label="Café da manhã incluído"
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    <Chip
                                                                                        icon={<ErrorOutline style={{ color: "#B32525" }} />}
                                                                                        style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                        label="Sem café da manhã"
                                                                                    />
                                                                                </>
                                                                            )
                                                                        }
                                                                        {
                                                                            item.plano?.nonRefundable ? (
                                                                                <Chip
                                                                                    icon={<MoodBadOutlined style={{ color: "#B32525" }} />}
                                                                                    style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                    label="Não reembolsável"
                                                                                />
                                                                            ) : (
                                                                                <Chip
                                                                                    icon={<MoodOutlined style={{ color: "#25B33D" }} />}
                                                                                    style={{ backgroundColor: "white", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "8px" }}
                                                                                    label="Reembolsável"
                                                                                />
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    null
                )
            }
        </>
    )
}
