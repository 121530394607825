import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Grow, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CancelOutlined, CheckCircleOutlineOutlined, PowerInputSharp } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useLayoutEffect, useState } from "react";
import LinesTextField from "../../../components/LinesTextField";
import USearchField from "../../../components/usearchfield";
import ecogestaoRepositorio from "../../../repositorios/ecogestao";
import { useStyles } from "./styles";

export const DialogSelectPax = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [endereco, setEndereco] = useState('');
    const [opcoesMotorista, setOpcoesMotorista] = useState([]);
    const [opcaoMotorista, setOpcaoMotorista] = useState({});
    const [opcoesGrupo, setOpcoesGrupo] = useState([]);
    const [opcaoGrupo, setOpcaoGrupo] = useState({});
    const [opcoesPartida, setOpcoesPartida] = useState([
        {
            value: 0,
            label: 'Empresa'
        },
        {
            value: 1,
            label: 'Moradia'
        },
        {
            value: 2,
            label: 'Outros'
        },
    ])
    const [opcaoPartida, setOpcaoPartida] = useState(
        {
            value: 0,
            label: 'Empresa'
        }
    )

    const handleCloseDialogClose = () => {
        props.setOpenDialogSelectPax(false);
    }

    const searchOpcoes = async () => {
        try {
            props.setIsLoading(true);
            let response = await ecogestaoRepositorio.postAllInfoDialog(props.dataPartida);
            setOpcoesMotorista(response.data.motoristas)
            setOpcoesGrupo(response.data.grupos)
        } catch (error) {
            props.setSnackVariant('error');
            props.setSnackMessage('Falha ao buscar lista de motoristas.');
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false);
        }
    }

    const handleChanged = (event) => {
        setEndereco(event.target.value)
    }

    const handleClickConfirm = async () => {
        props.setIsLoading(true)

        if (opcaoPartida === null) {
            props.setSnackVariant('warning');
            props.setSnackMessage('Local de partida não pode ser vazio.');
            props.setSnackState({ visible: true });
            props.setIsLoading(false);
            return
        }

        if (opcaoGrupo === null) {
            props.setSnackVariant('warning');
            props.setSnackMessage('Grupo não pode ser vazio.');
            props.setSnackState({ visible: true });
            props.setIsLoading(false);
            return
        }

        try {
            let dto = {
                idmotorista: opcaoMotorista?.value ? opcaoMotorista.idmotorista : 0,
                idpassageiro: props.selectedPax.idpassageiro,
                idaereovoo: props.selectedPax.idaereovoo,
                idpartida: opcaoPartida.value,
                ida: props.selectedPax.ida,
                partida: endereco != '' ? endereco : null,
                idgroup: opcaoGrupo.idgroup,
                dadospesquisa: {
                    idCentroCusto: props.centroCusto ? props.centroCusto.value : null,
                    dataPartida: props.dataPartida
                }
            }
            let response = await ecogestaoRepositorio.postSavePaxInGroup(dto);
            props.setDataList(response.data);
            handleCloseDialogClose();
        } catch (error) {
            props.setSnackVariant('error');
            props.setSnackMessage('Falha ao atribuir passageiro ao grupo.');
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    useLayoutEffect(() => {
        searchOpcoes()
    }, [])

    useLayoutEffect(() => {
        if (opcaoGrupo?.value !== 1 && opcaoGrupo !== null) {
            setOpcaoMotorista({})
        }
    }, [opcaoGrupo])


    return (
        <>
            <Dialog
                open={props.openDialogSelectPax}
                maxWidth="sm"
                onClose={handleCloseDialogClose}
                fullWidth
                fullScreen={responsive}
            >
                <DialogTitle className={classes.dialogTitle}><Typography className={classes.typoTitle}>{props.selectedPax.nome}</Typography></DialogTitle>
                <DialogContent >
                    {props.isLoading ? (
                        <Skeleton variant='rect' height={'150px'} />
                    ) : (
                        <>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Typography>Grupo:</Typography>
                                    <USearchField itens={opcoesGrupo} value={opcaoGrupo} setValue={setOpcaoGrupo} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography>Motorista: </Typography>
                                    <USearchField disabled={!(opcaoGrupo === null || opcaoGrupo?.value === 1)} itens={opcoesMotorista} value={opcaoMotorista} setValue={setOpcaoMotorista} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography>Local de partida:</Typography>
                                    <USearchField itens={opcoesPartida} value={opcaoPartida} setValue={setOpcaoPartida} />
                                </Grid>
                                {opcaoPartida?.value === 2 ? (
                                    <Grow in={true} timeout={800}>
                                        <Grid item xs={12} md={12}>
                                            <Typography>Endereço:</Typography>
                                            <LinesTextField
                                                type={'address'}
                                                onChange={handleChanged}
                                                value={endereco}
                                                name="endereco"
                                                fullWidth
                                                label="Endereço"
                                                margin="dense"
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grow>
                                ) : null}
                            </Grid>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleCloseDialogClose()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                    )}
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleClickConfirm()} className={classes.buttonDelete} startIcon={<CheckCircleOutlineOutlined />}>Confirmar</Button>
                    )}
                </DialogActions>
            </Dialog >
        </>
    )
}
