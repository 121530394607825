import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CancelOutlined, CheckCircleOutlineOutlined } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useStyles } from "./styles";

export const DialogExportExcel = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCloseDialogClose = () => {
        props.setOpenConfirmDialogExport(false);
    }

    const handleClickConfirm = async () => {

    }

    return (
        <>
            <Dialog
                open={props.openConfirmDialogExport}
                // open={true}
                maxWidth="sm"
                onClose={handleCloseDialogClose}
                fullWidth
                fullScreen={responsive}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={classes.typoTitle} style={{ fontSize: '14pt' }}><b>Exportação</b></Typography>
                </DialogTitle>
                <DialogContent >
                    {props.isLoading ? (
                        <Skeleton variant='rect' height={'150px'} />
                    ) : (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body1">Confirmar exportação na data pesquisada.</Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleCloseDialogClose()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                    )}
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleClickConfirm()} className={classes.buttonDelete} startIcon={<CheckCircleOutlineOutlined />}>Confirmar</Button>
                    )}
                </DialogActions>
            </Dialog >
        </>
    )
}
