import { Box, CardContent, CardMedia, Chip, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { CreditCardOutlined, EcoOutlined, EditOutlined, InsertDriveFileOutlined, } from "@material-ui/icons";
import React from "react";
import Cookies from "universal-cookie";
import Enums from "../../../../enums";
import EnumCompany from "../../../../enums/enumFlightCompany";
import logoteste from "../../../../images/Logos/logo_linesturDigital.svg";
import { useStyles } from "./styles";
import { EnumPax } from "../../../../enums/enumPax";
import ReservaStatusComponent from "../../../../components/LinearProgress/StatusVerifiqued";

export const BookingTicketDesktop = (props) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const data = props.bookingTicketData;
  const permissaoDashboard = props.permissaoDashboard;

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <CardContent>
            <CardMedia component="img" style={{ width: "200px" }} image={logoteste} />
          </CardContent>
        </Grid>
        <Grid item xs={2}>
          {cookies.get("usession") === "thalysson" ? (<div>{props.getLogoAPI(data.aereo.idapi)}</div>) : null}
        </Grid>
        <Grid item xs={4} className={classes.titleGrid}>
          <Typography ><b>Plano de Viagem</b></Typography>
          <Typography>Agente:   Zulma A. Torassi {`(${data.aereo.idapi})`}</Typography>
          <Typography>E-mail:    zulma@linestur.com </Typography>
        </Grid>
      </Grid>
      <TableContainer>
        <Table padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: "10%" }} className={classes.tableTitles}>Localizador</TableCell>
              <TableCell align="center" style={{ width: "10%" }} className={classes.tableTitles}>Status</TableCell>
              <TableCell align="center" style={{ width: "15%" }} className={classes.tableTitles}>Sistema</TableCell>
              <TableCell align="center" style={{ width: "15%" }} className={classes.tableTitles}>Criação</TableCell>
              <TableCell align="center" style={{ width: "15%" }} className={classes.tableTitles}>Solicitante</TableCell>
              <TableCell align="center" style={{ width: "15%" }} className={classes.tableTitles}>Contatos</TableCell>
              <TableCell align="center" style={{ width: "10%" }} className={classes.tableTitles}>Cartão</TableCell>
              <TableCell align="center" style={{ width: "10%" }} className={classes.tableTitles}>Anexos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center">
                {permissaoDashboard.usuarioLinestur ? data.aereo.localizador : [3, 8, 9, 14].includes(data.status) ? ("Aguardando") : data.aereo.localizador}
              </TableCell>
              <TableCell align="center">
                <Chip size="medium" label={Enums.getReservaStatusDescricao(data.status)} style={{ background: Enums.getReservaStatusCor(data.status), color: "#ffffff", }} />
              </TableCell>
              <TableCell align="center">
                <Tooltip title={<Typography style={{ fontSize: "12pt" }}>{EnumCompany.getLogoCompanhiaAereaEnum(data.aereo.companhiaaerea).nome}</Typography>}>
                  <img alt="Logo da campanhia não encontrada" src={data.aereo.companhiaaerea === undefined ? null : EnumCompany.getLogoCompanhiaAereaEnum(data.aereo.companhiaaerea).url_high} width={80} height={80} />
                </Tooltip>
              </TableCell>
              <TableCell align="center">{props.getDataFormatada(data.datacriacao)}</TableCell>
              <TableCell align="center">
                {data.usuario.usuario}
                {data.status === 7 && props.permissaoDashboard.usuarioLinestur ? (
                  <>
                    {props.showIconWppByValue(data.usuario.idpessoa, 1, true)}
                  </>
                ) : null}
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2">(48) 3045-7015 ou </Typography>
                <Typography variant="body2">(48) 99976-0516</Typography>
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => props.handleClickOpenChangeValuePayment(data.status, data.idreserva)} style={{ color: "#c62828" }}><CreditCardOutlined /></IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => props.handleClickOpenAddAttachments()} style={{ color: "#c62828" }}><InsertDriveFileOutlined /></IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Grid item xs={12} style={{ margin: "10px" }}>
          {
            <ReservaStatusComponent
              status={data.status}
              idreserva={data.idreserva}
            />
          }
        </Grid>

        <Typography variant="h6">Passageiros</Typography>
        <Table padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableTitles}>Tipo</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Sobrenome</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Nome</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Gênero</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Nascimento</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Centro de Custo</TableCell>
              <TableCell align="center" style={{ width: "10%" }} className={classes.tableTitles}>Emissão CO2</TableCell>
              {data.status === 7 && permissaoDashboard.usuarioLinestur ? (
                <TableCell align="center" className={classes.tableTitles}>Envio Bilhete</TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.aereo.passageiros.map((item) => (
              <TableRow>
                <TableCell align="center">{EnumPax.getFaixaEtaria[item.faixaetaria]}</TableCell>
                <TableCell align="center">{item.passageiro.sobrenome}</TableCell>
                <TableCell align="center">{item.passageiro.nome}</TableCell>
                <TableCell align="center">{EnumPax.getGenero[item.passageiro.sexo]}</TableCell>
                <TableCell align="center">{new Date(item.passageiro.datanascimento).toLocaleDateString()}</TableCell>
                <TableCell align="center">
                  <nobr>
                    {`${item.centroCusto.nome} - ${item.centroCusto.codigo}`}
                    {permissaoDashboard.usuarioLinestur ? (<IconButton onClick={() => props.handleClickOpenChangeValueCC(item)} size="small"><EditOutlined fontSize="small" /></IconButton>) : null}
                  </nobr>
                </TableCell>
                <TableCell align="center" style={{ color: '#43a047' }}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'}><b>{item.carbono}</b><EcoOutlined style={{ color: '#43a047' }} />
                  </Box>
                </TableCell>
                {data.status === 7 && permissaoDashboard.usuarioLinestur ? (
                  <TableCell align="center" >{props.showIconWppByValue(item.idpassageiro, item.bilheteenviado, false)}</TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Typography variant="h6">Voos</Typography>
        <Table padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableTitles}>Cia</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Voo</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Origem</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Destino</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Esc.</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Cl.</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Info</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Loc Cia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.aereo.voos.map((item) => (
              <TableRow>
                <TableCell align="center">
                  <Tooltip title={<Typography style={{ fontSize: "12pt" }}>{EnumCompany.getLogoCompanhiaAereaEnum(item.iata).nome}</Typography>}>
                    <img alt="Logo da campanhia não encontrada" src={item.iata === undefined ? null : EnumCompany.getLogoCompanhiaAereaEnum(item.iata).url_low} width={'auto'} height={25} className={classes.imgCia} />
                  </Tooltip> 
                </TableCell>
                <TableCell align="center">{item.numero}</TableCell>
                <TableCell align="center">
                  <Typography variant="body2">{`${item.origemdescricao} (${item.origem})`}</Typography>
                  <Typography variant="body2">{`${new Date(item.datapartida).toLocaleString().slice(0, new Date(item.datapartida).toLocaleString().length - 3)}`}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body2">{`${item.destinodescricao} (${item.destino})`}</Typography>
                  <Typography variant="body2">{`${new Date(item.datachegada).toLocaleString().slice(0, new Date(item.datachegada).toLocaleString().length - 3)}`}</Typography>
                </TableCell>
                <TableCell align="center">{item.escala}</TableCell>
                <TableCell align="center">{item.classe}</TableCell>
                <TableCell align="center">{item.incluibagagem ? `Com Bagagem(${item.tipobagagem === null ? "Sem nome" : item.tipobagagem})` : `Sem Bagagem(${item.tipobagagem === null ? "Sem nome" : item.tipobagagem})`}</TableCell>
                <TableCell align="center">{data.aereo.localizador}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Typography variant="h6">Valores</Typography>
        <Table padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableTitles}>Passageiro(a)</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Tarifa(s)</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Extra(s)</TableCell>
              <TableCell align="center" className={classes.tableTitles}>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.aereo.passageiros.map((item) => (
              <TableRow>
                <TableCell align="center">{item.passageiro.sobrenome + "/" + item.passageiro.nome}</TableCell>
                <TableCell align="center">
                  {"R$ " + item.total.toFormatDefault() + " "}
                  {permissaoDashboard.usuarioLinestur && data.status === 7 ? (
                    <IconButton onClick={() => props.handleClickOpenChangeValuePax(item, "tarifa")} size="small"><EditOutlined fontSize="small" /></IconButton>
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  {"R$ " + item.taxes.toFormatDefault() + " "}
                  {permissaoDashboard.usuarioLinestur && data.status === 7 ? (
                    <IconButton onClick={() => props.handleClickOpenChangeValuePax(item, "extras")} size="small"><EditOutlined fontSize="small" /></IconButton>
                  ) : null}
                </TableCell>
                <TableCell align="center">{"R$ " + item.total_geral.toFormatDefault() + " "}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center"><b>{"R$ " + data.aereo.total?.toFormatDefault() + " "}</b></TableCell>
              <TableCell align="center"><b>{"R$ " + data.aereo.taxes?.toFormatDefault() + " "}</b></TableCell>
              <TableCell align="center"><b>{"R$ " + data.aereo.total_geral?.toFormatDefault() + " "}</b></TableCell>
            </TableRow>
          </TableBody>
        </Table>

      </TableContainer>

      <div className={classes.info}>
        <Typography> Informações para Embarque </Typography>
        <Typography>
          {" "}
          Apresente-se em nosso checkin com 2 horas de antecedência em voos
          nacionais e com 3 horas de antecedência em voos internacionais.{" "}
        </Typography>
        <Typography>
          {" "}
          Levar documento original: <b>Carteira de Identidade</b> para vôos
          nacionais.
        </Typography>
        <Typography>
          {" "}
          Levar documento original: <b>Passaporte</b> e os vistos necessários
          para entrada no pais de destino para voos internacionais.
        </Typography>
        <Typography>
          Informações sobre validade de PASSAPORTE, VACINAS e VISTOS que
          possam ser necessários para sua viagem devem ser consultados com as
          respectivas embaixadas ou despachantes de vistos.
        </Typography>
        <Typography>
          Verifique essa necessidade para todos os países envolvidos na
          viagem, mesmo aqueles onde há apenas uma escala.
        </Typography>
        <Typography>
          Lembre-se que alguns países exigem que o passaporte tenha uma
          validade mínima de 6 meses para embarcar.
        </Typography>
      </div>
    </>
  );
};

