import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    gridTitle: {
        textAlign: 'center',
    },

    typoLittleFont: {
        fontSize: '10pt',
    },

    listItemCustom: {
        border: '2px solid #c62828',
        borderRadius: '10px',
        marginTop: '8px',
        marginBottom: '8px'
    },

    listItemPax: {
        paddingLeft: '4px',
        paddingRight: '4px'
    },

    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119'
        }
    },
}));
