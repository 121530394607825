export const Enums = {
    statusSolicitacao: {
        aguardandoatendimento: 1,
        ematendimento: 2,
        aguardandosolicitante: 3,
        encerrado: 4,
        rascunho: 5,
        autorizacaopendente: 6,
        expirado: 7,
        cancelado: 8,
        emitido: 9,
        limiteexcedido: 10,

    },

    aereoStatus: {
        emissaopendente: 1,
        reservado: 2,
        emitido: 3,
        autorizacaopendente: 4,
        expirado: 5,
        emissaonegada: 6,
        cancelado: 7
    },

    tipoEmpresa: {
        fisica: 1,
        juridica: 2
    },

    categoriaProduto: {
        hotel: 1,
        onibus: 2,
        locacaoDeCarro: 3,
        seguroViagem: 4,
        pacotes: 5,
        aereo: 6,
        aereoInternacional: 7,
        outros: 8,
    },

    tipoMoeda: {
        real: 1,
        dolar: 2,
        euro: 3
    },

    tipoUsuario: {
        usuario: 1,
        passageiro: 2,
        balcao: 3
    },

    privilegio: {
        administrador: 1,
        agente: 2,
        autorizador: 3,
        gerente: 4,
        colaborador: 5,
        aprovador: 6
    },

    genero: {
        masculino: 1,
        feminino: 2
    },

    tipoSanguineo: {
        a: 1,
        b: 2,
        ab: 3,
        o: 4
    },

    fatorRh: {
        positivo: 1,
        negativo: 2
    },

    tamanhoCamisa: {
        pp: 1,
        p: 2,
        m: 3,
        g: 4,
        gg: 5,
        xgg: 6
    },

    acaoRequerida: {
        ver: 1,
        criar: 2,
        editar: 3,
        autorizarnegar: 4,
        alterarstatus: 5,
        vincular: 6,
        emitir: 7,
        cancelar: 8,
        relatoriopessoal: 9,
        relatoriocentrocusto: 10
    },

    moduloSistema: {
        aereo: 1,
        solicitacao: 2,
        caixaentrada: 3,
        usuario: 4,
        produto: 5,
        fornecedor: 6,
        empresa: 7,
        centrocusto: 8,
        permissao: 9,
        politicaviagem: 10,
        relatorios: 11,
        hotsite: 12,
        pessoa: 13
    },

    reservaStatus: {
        aguardandoAtendimento: 1,
        emAtendimento: 2,
        emissaoPendente: 3,
        aguardandoSolicitante: 4,
        encerrado: 5,
        rascunho: 6,
        emitido: 7,
        reservado: 8,
        autorizacaoPendente: 9,
        expirado: 10,
        emissaoNegada: 11,
        cancelado: 12,
        limiteExcedido: 13,
        aprovacaoPendente: 14,
        reembolsointegral: 15,
        reembolsoparcial: 16
    },

    despesaStatus: {
        emViagem: 1,
        contabilidade: 2,
        controladoria: 3,
        financeiro: 4,
        finalizado: 5,
        financeiroProg: 6
    },

    etapaAprovacao: {
        foraPolitica: 1,
        dentroPolitica: 2
    },
    getStatusReservaSolicitacao: {
        0: 0,
        1: 1,
        2: 2,
        3: 4,
        4: 5,
        5: 6,
        6: 9,
        7: 10,
        8: 12,
        9: 7,
        10: 13,
    },
    getStatusSolicitacaoReserva: {
        0: 0,
        1: 1,
        2: 2,
        4: 3,
        5: 4,
        6: 5,
        9: 6,
        10: 7,
        12: 8,
        7: 9,
        13: 10,
    },
    getReservaStatusDescricao: function (status) {
        switch (status) {
            case this.reservaStatus.aguardandoAtendimento:
                return 'Aguardando Atendimento'
            case this.reservaStatus.emAtendimento:
                return 'Em Atendimento'
            case this.reservaStatus.emissaoPendente:
                return 'Emissão Pendente'
            case this.reservaStatus.aguardandoSolicitante:
                return 'Aguardando Solicitante'
            case this.reservaStatus.encerrado:
                return 'Encerrado'
            case this.reservaStatus.rascunho:
                return 'Rascunho'
            case this.reservaStatus.emitido:
                return 'Emitido'
            case this.reservaStatus.reservado:
                return 'Tarifa Garantida'
            case this.reservaStatus.autorizacaoPendente:
                return 'Autorização Pendente'
            case this.reservaStatus.expirado:
                return 'Expirado'
            case this.reservaStatus.emissaoNegada:
                return 'Emissão Negada'
            case this.reservaStatus.cancelado:
                return 'Cancelado'
            case this.reservaStatus.limiteExcedido:
                return 'Limite Excedido'
            case this.reservaStatus.aprovacaoPendente:
                return 'Aprovação Pendente'
            case this.reservaStatus.reembolsointegral:
                return 'Reembolso Integral'
            case this.reservaStatus.reembolsoparcial:
                return 'Reembolso Parcial'
            default:
                return ''
        }
    },

    getReservaStatusCor: function (status) {
        switch (status) {
            case this.reservaStatus.aguardandoAtendimento:
                return '#bf360c'
            case this.reservaStatus.emAtendimento:
                return '#ff9100'
            case this.reservaStatus.emissaoPendente:
                return '#ef6c00'
            case this.reservaStatus.aguardandoSolicitante:
                return '#3f51b5'
            case this.reservaStatus.encerrado:
                return 'black'
            case this.reservaStatus.rascunho:
                return 'gray'
            case this.reservaStatus.emitido:
                return '#43a047'
            case this.reservaStatus.reservado:
                return '#1e88e5'
            case this.reservaStatus.autorizacaoPendente:
                return '#9e9d24'
            case this.reservaStatus.expirado:
                return 'grey'
            case this.reservaStatus.emissaoNegada:
                return '#f44336'
            case this.reservaStatus.cancelado:
                return '#f44336'
            case this.reservaStatus.limiteExcedido:
                return '#f44336'
            case this.reservaStatus.aprovacaoPendente:
                return 'royalblue'
            case this.reservaStatus.reembolsointegral:
                return '#f44336'
            case this.reservaStatus.reembolsoparcial:
                return '#43a047'
            default:
                return ''
        }
    },

    getDespesaStatus: function (status) {
        switch (status) {
            case this.despesaStatus.emViagem:
                return 'Em viagem'
            case this.despesaStatus.contabilidade:
                return 'Aprovador'
            case this.despesaStatus.controladoria:
                return 'Autorizador'
            case this.despesaStatus.financeiro:
                return 'Financeiro'
            case this.despesaStatus.financeiroProg:
                return 'Pagamento agendado'
            case this.despesaStatus.finalizado:
                return 'Finalizado'
            default:
                return 'ERRO'
        }
    },

    getDespesaCor: function (status) {
        switch (status) {
            case this.despesaStatus.emViagem:
                return '#C62828'
            case this.despesaStatus.contabilidade:
                return 'royalblue'
            case this.despesaStatus.controladoria:
                return '#9e9d24'
            case this.despesaStatus.financeiro:
                return '#00BFFF'
            case this.despesaStatus.financeiroProg:
                return '#b266b2'
            case this.despesaStatus.finalizado:
                return '#43A047'
            default:
                return '#000000'
        }
    }
}

export default Enums;
