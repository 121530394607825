import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const reservaRepositorio = {

    post: async reserva => {
        const response = await api.post('/reserva', reserva, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    getReservaHistorico: async idreserva => {
        const response = await api.get('/Reserva/GetReservaHistorico', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                idreserva
            }
        });
        return response;
    },

    alterarStatusSolicitacaoAgente: async (dtoAlterarStatusSolicitacaoAgente) => {
        const response = await api.post('/reserva/AlterarStatusSolicitacaoAgente', dtoAlterarStatusSolicitacaoAgente, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    alterarDataCriacao: async (dtoAlterarDataCriacao) => {
        const response = await api.post('/reserva/AlterarCriacao', dtoAlterarDataCriacao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    enviarEmailAereo: async (dtoEnviarAereoEmail) => {
        const response = await api.post('/reserva/EnviarAereoEmail', dtoEnviarAereoEmail, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getFilterPorTenant: async (pesquisa) => {
        const dto = {
            pesquisa,
            sessionId: cookies.get('sessionId')
        };
        const response = await api.post('/reserva/GetFilterPorTenant', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    alterarStatus: async (dtoStatusReserva) => {
        const response = await api.put('/reserva/AlterarStatus', dtoStatusReserva, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    getReduce: async (idreserva) => {
        const response = await api.get('/reserva/getreduce/' + idreserva, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response.data;
    },

    getAll: async () => {
        const response = await api.get('/reserva/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    getAll_v2: async (dto) => {
        const response = await api.post('/BookingBox/GetAllPorTenant_v2', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    autorizarNegarReserva: async dtoAutorizarNegar => {
        const response = await api.post('/Reserva/AutorizarNegar', dtoAutorizarNegar, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    put: async (reserva) => {
        const response = await api.put('/reserva/' + reserva.idreserva, reserva, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    get: async idreserva => {
        const response = await api.get('/reserva/' + idreserva, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response.data;
    },

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/reserva/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        response.data.map(item => {
            lista.push({
                label: item.nome,
                value: item.idcentrocusto
            })
        })
        return lista;
    },

    getBilheteEnviadoByReserva: async (idreserva) => {
        const response = await api.get('/reserva/getBilheteEnviadoByReserva/' + idreserva, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response.data;
    },

    aproveAll: async () => {
        await api.put('/reserva/AprovarTudo', {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
    },

    repay: async (id, status) => {
        const response = await api.put('/reserva/reembolso/' + id, status, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },
}

export default reservaRepositorio;
