import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, TablePagination, Typography } from '@material-ui/core';
import React, { useState } from 'react';

function ComprasConsolidadasList(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = Math.ceil(props.data.length);
    const s = page * itensPerPage;
    const r = itensPerPage + s;
    const todaConsolidada = props.data.slice(s, r);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    return (
        <>
            <TablePagination
                style={{ minWidth: 'max-content' }}
                labelRowsPerPage=''
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Box>
                {todaConsolidada.map((item) => (
                    <Accordion>
                        <AccordionSummary>
                            <Typography>{item.nfatura} ({item.faturado})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>Nome: {item.nome}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Fornecedor: {item.fornecedor}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Cidade: {item.cid}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Estado: {item.uf}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Data Ida: {item.dataida}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Data Volta: {item.datavolta}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Valor: {item.valor}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Diárias: {item.diarias}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Origem: {item.origem}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Destino: {item.destino}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Retorno: {item.retorno}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Descrição: {item.descricao}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Centro de Custo: {item.centrocusto}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Tipo: {item.tipo}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </>
    )
}

export default ComprasConsolidadasList;
