import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        },
    },
    buttonSave: {
        background: '#c62828',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#b91119',
        }
    },
    typographButton: {
        marginRight: '5px'
    },
}));
