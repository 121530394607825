import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        border: 'solid 1px silver',
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
