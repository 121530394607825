import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    appbar: {
        background: '#616161',
    },
    button: {
        color: '#fff'
    },
    buttoncustom: {
        width: 150,
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900]
        }
    }
}));
