import bagageIcon from "./backpack.png";
import backpackIcon from "./luggage.png";
import locIcon from "./maps.png";
import GmapsIcon from "./google-maps.png";
import bagageIconRed from "./malared.png";
import hotelIcon from "./hotel.png";
import bookmark from "./bookmark.png";
import icsolararrowup from "./ic-solar_double-alt-arrow-up-bold-duotone.png";
import pastas from "./stack.svg";
import image from "./imagem.png";
import pdf from "./pdf.png";
import txt from "./txt.png";
import recibo from "./recibo2.png"
import hodometroicon from "./gps.png";
import mapsandlocation from "./maps-and-location.png"
import locationOff from "./location-off.png"
import backgroundcover from "./cover_4.jpg"
import logo from "./logo.png"
import logol from "./favicon.png"
import success from "./success.gif"
import passport from "./passport.gif"
import receivemoney from "./receivemoney.gif"

export const LogoSistemSource = {
  index: logo,
  logoL: logol
}

export const IconsSource = {
  bagage: bagageIcon,
  backpack: backpackIcon,
  locIcon: locIcon,
  googleMapIcon: GmapsIcon,
  bagageIconRed: bagageIconRed,
  hotelIcon: hotelIcon,
  bookmarkIcon: bookmark,
  icsolararrowupIcon: icsolararrowup,
  recibo: recibo,
  hodometroIcon: hodometroicon,
  mapsandlocation: mapsandlocation,
  locationoff: locationOff
};

export const IconsSourceSvg = {
  pastas: pastas,
};

export const ImageBackgroundSource = {
  backgroundcover: backgroundcover,
};

export const ImageGIFSource = {
  success: success,
  passport: passport
}

export const IconsSourceName = (value) => {
  switch (value) {
    case "image":
      return image;
    case "pdf":
      return pdf;
    case "txt":
      return txt;
    default:
      return txt;
      break;
  }
};
