import cookie from 'universal-cookie';
import api from '../../Api';
import aereoPassageiroRepositorio from '../aereoPassageiro';
import aereoVooRepositorio from '../aereoVoo';
import reservaRepositorio from '../reserva';

import {
    cancelarReserva, emitir, recuperarFinanciamentos
} from '../../LinkAereo';

const cookies = new cookie();

export const aereoRepositorio = {

    post: async (aereo, passageiros, assunto) => {
        if (aereo.data) {
            let aereoDto = {
                metodopagamento: null,
                entityid: aereo.data.Retrieve.RetrieveBaseRS[0].IDEntity,
                sourceid: aereo.data.Retrieve.RetrieveBaseRS[0].SourceID,
                status: 2,
                dataemissao: null,
                total: aereo.data.Retrieve.RetrieveBaseRS[0].TotalFare.Total,
                localizador: aereo.data.Retrieve.RetrieveBaseRS[0].RecordLocator,
                dataexpiracaoemissao: null,
                companhiaaerea: aereo.data.Retrieve.RetrieveBaseRS[0].SourceCode,
                datacriacao: new Date(),
                dataaereo: aereo.data.Retrieve.RetrieveBaseRS[0].BookingDate,
                dataatualizacao: new Date(),
                sessionId: cookies.get('sessionId')
            }

            const response = await api.post('/Aereo', aereoDto, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            })

            if (response.data) {
                let bilheteDto = {
                    metodopagamento: null,
                    status: 2,
                    datacriacao: new Date(),
                    dataemissao: null,
                    necessitachavecancelamento: false,
                    total: aereoDto.total,
                    localizador: aereoDto.localizador,
                    permitecancelar: false,
                    permiteemissao: false,
                    dataexpiracaoemissao: null,
                    companhiaaerea: aereoDto.companhiaaerea,
                    dataatualizacao: new Date(),
                    idtenant: response.data.idtenant,
                    idaereo: response.data.idaereo,
                }

                const responseBilhete = await api.post('/AereoBilhete', bilheteDto, {
                    headers: {
                        Authorization: 'Bearer ' + cookies.get('token')
                    }
                })
                if (responseBilhete.data) {
                    let dtoFares = [];

                    aereo.data.Retrieve.RetrieveBaseRS[0].Fares.map(item => {
                        dtoFares.push({
                            type: item.PaxType,
                            total: (item.FareAmount + item.TaxesAmount + item.DUAmount)
                        })
                    })

                    const getFares = type => {
                        let fare = dtoFares.find(item => item.type === type);
                        return fare.total;
                    }

                    let passageiroDto = [];

                    aereo.data.Retrieve.RetrieveBaseRS[0].Passengers.map(item => {
                        passageiroDto.push({
                            faixaetaria: item.Type,
                            bebe: false,
                            fidelidade: '',
                            total: getFares(item.Type),
                            porcentagem: passageiros[item.Code].porcentagem,
                            idpassageiro: passageiros[item.Code].idpassageiro,
                            idtenant: response.data.idtenant,
                            idaereo: response.data.idaereo,
                            idcentrocusto: passageiros[item.Code].idcentrocusto,
                        })
                    })
                    passageiroDto.map(async (item) => {
                        return await aereoPassageiroRepositorio.post(item);
                    });

                    let voosDto = [];

                    let ordemVoo = 0;

                    aereo.data.Retrieve.RetrieveBaseRS[0].Segments.map(segment => {
                        segment.Flights.map(voo => {
                            ordemVoo += 1;
                            voosDto.push({
                                idaereo: response.data.idaereo,
                                iata: voo.CarrierCode,
                                ordem: ordemVoo,
                                iatadescricao: '',
                                numero: Number(voo.FlightNumber),
                                duracao: 0,
                                idtenant: response.data.idtenant,
                                classe: voo.FareOption.Class,
                                escala: voo.Stops,
                                tipobagagem: voo.FareOption.FareFamilyName,
                                conexoes: segment.Flights.length - 1,
                                origem: voo.DepartureStation,
                                origemdescricao: '',
                                destino: voo.ArrivalStation,
                                destinodescricao: '',
                                datapartida: voo.DepartureDateTime,
                                datachegada: voo.ArrivalDateTime,
                                datacriacao: new Date(),
                                dataatualizacao: new Date(),
                            })
                        })
                    })

                    voosDto.map(async (item) => {
                        return await aereoVooRepositorio.post(item);
                    });
                    let reservaDto = {
                        assunto: assunto,
                        status: 8,
                        tipo: 1,
                        idaereo: response.data.idaereo,
                        idsolicitacao: 0,
                        sessionId: cookies.get('sessionId'),
                        etapaaprovacao: 1,
                        datacriacao: new Date(),
                        dataultimaatualizacao: new Date(),
                    };

                    const reservaResponse = await reservaRepositorio.post(reservaDto);

                    return {
                        success: true,
                        reserva: reservaResponse
                    };
                }
            }
        }
    },

    get: async (idaereo) => {
        const response = await api.get('/Aereo/GetAereoTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                idaereo: idaereo
            }
        });

        return response;
    },
    sincronizarAereo: async (idreserva) => {
        const response = await api.post('/Aereo/Sincronizar', {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                idreserva
            }
        });

        return response;
    },
    importarBilhete: async (dtoResponseBilhete) => {
        const response = await api.post('/Aereo/ImportarBilhete', dtoResponseBilhete, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response
    },
    getBilhete: async (dtoGetBilhete) => {
        const response = await api.post('/Aereo/GetBilhete', dtoGetBilhete, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response
    },
    recuperarFinanciamentos: async (dtoDadosCartao) => {
        try {
            const response = await recuperarFinanciamentos(dtoDadosCartao);
            return response;
        } catch (e) {
            return {};
        }
    },

    emitir: async (dtoEmissao) => {
        const response = await emitir(dtoEmissao);
        return response;
    },

    cancelarAereo: async (idaereo) => {
        const dtoCancelar = { idaereo, sessionId: cookies.get('sessionId') }
        await cancelarReserva(dtoCancelar);
        return { status: true }
    },

    getSearchField: async () => {

    }
}

export default aereoRepositorio;
