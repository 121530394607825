const axios = require('axios');

const cepBRL = {

    getCEP_BRL: async (cep) => {
        const response = await axios.get('https://viacep.com.br/ws/' + cep + '/json/');
        return response;
    }
}

export default cepBRL;
