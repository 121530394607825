import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { getTipoDespesaForNumberUnique } from '../../../../mock/tiposdespesas';

const createData = (id, description, qty, unitPrice, subtotal, details) => {
  return { id, description, qty, unitPrice, subtotal, details };
};

const rows = [
  createData(1, 'UX Design', 1, 12.45, 85.02, 'Wireframe of screen, storyboards and sitemap.'),
  createData(2, 'UX Front end Development', 2, 43.83, 84.70, 'Css, Html and Javascript files.'),
  createData(3, 'UI Design', 3, 90.69, 69.22, 'Build design system in sketch.'),
];

const MiniTableDespesas = ({ despesas }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Descrição</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Permitido</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {despesas?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">{getTipoDespesaForNumberUnique(row.tipo)}</Typography>
                <Typography variant="body2" color="textSecondary">{row.descricao}</Typography>
              </TableCell>
              <TableCell>{row.total}</TableCell>
              <TableCell>Não definido</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MiniTableDespesas;
