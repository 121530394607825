import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, makeStyles, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography, useTheme } from '@material-ui/core';
import { FirstPageOutlined, KeyboardArrowLeft, KeyboardArrowRight, LastPageOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import pacoteAgenteRepositorio from '../../../repositorios/pacoteAgente';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageOutlined /> : <FirstPageOutlined />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageOutlined /> : <LastPageOutlined />}
            </IconButton>
        </div>
    );
}

function DialogHistorico(props) {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [data, setData] = useState([])
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleCloseDialog = () => {
        props.handleClickOpenHistorico(false)
    }

    const handleChangePage = (newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        (async () => {
            try {
                props.setIsLoading(true)
                if (isNaN(props.selectedIdPacote) || props.selectedIdPacote <= 0) {
                    return
                }
                let response = await pacoteAgenteRepositorio.getHistoricPacote(props.selectedIdPacote);
                setData(response.data)

            } catch (e) {
                props.setSnackVariant('error');
                props.setSnackMessage('Erro ao pesquisar dados do pacote.');
                props.setSnackVisible({ visible: true });
            } finally {
                props.setIsLoading(false)
            }
        })()
    }, [props.selectedIdPacote, props.openDialogHistorico])

    return (
        <div>
            <Dialog
                onClose={() => handleCloseDialog()}
                open={props.openDialogHistorico}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xl'}
            >
                <DialogTitle style={{ background: '#C62828' }}>
                    <Typography style={{ color: '#ffffff' }}> Histórico</Typography>
                </DialogTitle>
                <DialogContent style={{ maxHeight: '50vh' }}>
                    <DialogContentText>
                        <TableContainer component={Paper}>
                            <Table size={'small'} className={classes.tableCustom}>
                                <TableHead >
                                    <TableRow>
                                        <TableCell>Data</TableCell>
                                        <TableCell>Descrição</TableCell>
                                        <TableCell>Usuário</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map(item => (
                                        <TableRow>
                                            <TableCell>{item.dataultimaatualizacao}</TableCell>
                                            <TableCell>{item.descricao}</TableCell>
                                            <TableCell>{item.usuario}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableFooter style={{ position: 'sticky' }}>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 25, 50, { label: 'Todos', value: -1 }]}
                                            colSpan={3}
                                            count={data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' },
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DialogHistorico;
