import { makeStyles } from '@material-ui/core/styles';
import { purple, deepPurple, red } from '@material-ui/core/colors';

const drawerWidth = 240;

export const useStyles = makeStyles(theme => ({
    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },
    button: {
        background: '#c62828',
        textTransform: 'none',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },
}));
