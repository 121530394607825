import { Checkbox, withStyles } from '@material-ui/core';
import React from 'react';

const UCheckbox = withStyles({
    root: {
        color: '#c62828',
        '&$checked': {
            color: '#b91119',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" checked={props.checked}  {...props} />);

export default UCheckbox;
