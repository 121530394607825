import { Avatar, Button, Dialog, DialogContent, Grid, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { EditRounded, Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cookie from 'universal-cookie';
import api from '../../../Api';
import LoadingButton from '../../../components/loadingButton';
import Snackbar from '../../../components/snackbar';
import UFloatButton from '../../../components/ufloatbutton';
import Enums from '../../../enums';
import permissaoRepositorio from '../../../repositorios/permissao';
import usuarioRepositorio from '../../../repositorios/usuario';
import Dashboard from '../../dashboard';
import CadastroUsuario from '../cadastro';
import { useStyles } from './styles';

export const ListaUsuario = (props) => {
    const theme = useTheme();
    const matches = useMediaQuery('(max-width:480px)');
    const matches2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const matches3 = useMediaQuery('(min-width:600px) and (orientation: portrait)');
    const classes = useStyles();
    const cookies = new cookie();
    const permissao = useSelector(state => state.permissao.permissaoDashboard);
    const [validacao, setValidacao] = useState({ visible: false });
    const [mensagemValidacao, setMensagemValidacao] = useState('');
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosPesquisa, setUsuariosPesquisa] = useState([]);
    const [usuario, setUsuario] = useState({});
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fullscreen2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const [cadastroOpen, setCadastroOpen] = useState(false);
    const [picture, setPicture] = useState(null);
    const [alterarSenha, setAlterarSenha] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = usuariosPesquisa.length;
    const start = page * itensPerPage;
    const r = itensPerPage + start;
    const todosUsuarios = usuariosPesquisa.slice(start, r);
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    const onPesquisar = event => {
        const contem = usuarios.filter(item => {
            return (item.usuario.toLowerCase().includes(event.target.value.toLowerCase()) || item.centroCusto.nome.toLowerCase().includes(event.target.value.toLowerCase()));
        })

        setUsuariosPesquisa(contem);
    }
    const salvar = async () => {
        let dto = {
            ...usuario,
            sessionId: cookies.get('sessionId'),
            picture: picture,
            CentroCusto: null,
            alterarSenha
        }

        if (!dto.idpessoa) {
            setMensagemValidacao('O campo pessoal não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!dto) {
            setMensagemValidacao('É necessário preencher os dados para cadastrar');
            setValidacao({ visible: true });
            return;
        }

        if (!dto.usuario) {
            setMensagemValidacao('O campo usuário não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!dto.email) {
            setMensagemValidacao('O campo e-mail não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!dto.senha && alterarSenha) {
            setMensagemValidacao('O campo senha não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (dto.senha !== dto.confirmacaoSenha && alterarSenha) {
            setMensagemValidacao('As senhas não conferem');
            setValidacao({ visible: true });
            return;
        }
        if (!dto.privilegio) {
            setMensagemValidacao('O campo privilégio não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!dto.idcontaemail && dto.privilegio === 2 && permissao.usuarioLinestur) {
            setMensagemValidacao('Email admin não pode ser vazio para um agente Linestur');
            setValidacao({ visible: true });
            return;
        }

        if (!dto.idcentrocusto) {
            setMensagemValidacao('O campo centro de custo não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }

        if (usuario.idusuario) {
            const temPermissaoEditar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.usuario, Enums.acaoRequerida.editar)
            if (!temPermissaoEditar.data) {
                setMensagemValidacao('Você não possui permissão!');
                setValidacao({ visible: true });
                return
            }
            const response = await api.put('/usuario/' + usuario.idusuario, dto, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            });

            if (response.data.error) {
                setMensagemValidacao(response.data.error);
                setValidacao({ visible: true });
                return;
            }

            const resp = await usuarioRepositorio.getAll();

            setUsuarios(resp.data);
            setUsuariosPesquisa(resp.data);
            setSnackbarSucess({ visible: true });
            setAlterarSenha(false);
            setUsuario({});
            return response;
        }
        const temPermissaoCriar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.usuario, Enums.acaoRequerida.criar)
        if (!temPermissaoCriar.data) {
            setMensagemValidacao('Você não possui permissão!');
            setValidacao({ visible: true });
            return
        }
        const response = await api.post('/usuario', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })

        if (response.data.error) {
            setMensagemValidacao(response.data.error);
            setValidacao({ visible: true });
            return;
        }

        const resp = await usuarioRepositorio.getAll();

        setUsuarios(resp.data);
        setUsuariosPesquisa(resp.data);
        setSnackbarSucess({ visible: true });
        setAlterarSenha(false);
        setUsuario({});
        return response;
    }

    const handleCadastroOpen = (item, visible) => {
        setUsuario(item);
        setAlterarSenha(false);
        setCadastroOpen(visible);
    }
    
    useEffect(() => {
        setUsuario((prevUsuario) => ({
            ...prevUsuario,
            senha: ''
        }));
    }, [usuario.idusuario]);

    useEffect(() => {

        (async () => {
            const response = await usuarioRepositorio.getAll();
            setUsuarios(response.data);
            setUsuariosPesquisa(response.data);
        })()
    }, [])

    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: 'success', message: 'Dados salvos com sucesso!', visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Snackbar {...{ variant: 'error', message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <Dialog
                className={classes.cadastro}
                open={cadastroOpen}
                onClose={e => setCadastroOpen()}
                fullScreen={fullscreen || fullscreen2}
                maxWidth="sm"
                fullWidth={true}
            >
                {/* <DialogTitle style={{ background: '#616161', padding: theme.spacing(1, 3) }}>
                    <div className={classes.divtitle}>
                        <Typography style={{
                            color: 'white'
                        }}><b>Usuário</b>
                        </Typography>
                        <IconButton className={classes.fechar} onClick={e => setCadastroOpen(false)}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </DialogTitle> */}
                <DialogContent style={{
                    padding: '0px',
                    paddingTop: '0px'
                }}>
                    <CadastroUsuario {...{ usuario: usuario, setUsuario: setUsuario, picture: picture, setPicture: setPicture, setCadastroOpen, alterarSenha, setAlterarSenha }} />
                    <div className={classes.salvar}>
                        <LoadingButton {...{ salvar: salvar, modalOpen: cadastroOpen, setModalOpen: setCadastroOpen }} />
                    </div>
                </DialogContent>
                {/* <DialogActions>
                    {/* <Button disabled={isLoading} onClick={salvar} className={classes.buttonSalvar}>
                    <SaveOutlined />
                    Salvar
                    </Button> *
                </DialogActions> */}
            </Dialog>
            <Paper>
                <Toolbar className={classes.toolbar}>
                    {!matches & !matches2 && !matches3 ? (<Button className={classes.button} onClick={e => handleCadastroOpen({ tipousuario: 1, idusuario: 0 }, true)}>Novo</Button>) : (<UFloatButton actions={[]} handleClick={e => handleCadastroOpen({ tipousuario: 1 }, true)} />)}
                    <div className={classes.search3}>
                        <div className={classes.searchIcon}>
                            <Search />
                        </div>
                        <InputBase
                            onChange={onPesquisar}
                            placeholder="Pesquise…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                </Toolbar>
                <Grid item xs={12}>
                    <Paper elevation={!fullscreen ? 5 : 0}>
                        <TableContainer style={{ wordBreak: 'break-all' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                        <TableCell className={classes.heads} align="center"></TableCell>
                                        <TableCell className={classes.heads} align="center">Usuario</TableCell>
                                        {!matches && !matches2 ? (<TableCell className={classes.heads} align="center">Email</TableCell>) : null}
                                        {!matches ? (<TableCell className={classes.heads} align="center">Centro de Custo</TableCell>) : null}
                                        <TableCell className={classes.heads} align="center">Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {todosUsuarios.map(item => (
                                        <TableRow className={classes.row} key={item.idusuario}>
                                            <TableCell className={classes.bodys} align="center" >
                                                <IconButton onClick={e => handleCadastroOpen(item, true)} margin="small">
                                                    <EditRounded fontSize="default" />              
                                                </IconButton>
                                            </TableCell>
                                            <TableCell className={classes.bodys} align="center">
                                                <Avatar src={item.imagem}></Avatar>
                                            </TableCell>
                                            <TableCell className={classes.bodys} align="center">{item.usuario}</TableCell>
                                            {!matches && !matches2 ? (<TableCell className={classes.bodys} align="center">{item.email}</TableCell>) : null}
                                            {!matches ? (<TableCell className={classes.bodys} align="center">{item.centroCusto.nome}</TableCell>) : null}
                                            <TableCell className={classes.bodys} align="center">{item.ativo ? "ATIVO" : "INATIVO"}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid style={{ width: "100%", maxWidth: "none", textAlign: "center" }}>
                            <TablePagination
                                component="div"
                                count={pages}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[10, 25, 50]}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Paper>
        </div>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaUsuario} />
        </div>
    )
};
export default Lista;
