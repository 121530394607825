import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';

export const URadioGroup = props => {
    const handleChange = event => {
        props.setValue(event.target.value);
    }
    return (
        <div>
            <FormControl component="fieldset" >
                <RadioGroup aria-label="position" name="position" value={props.value || ''} onChange={handleChange} row>
                    {props.itens.map(item => (
                        <FormControlLabel
                            value={item.value}
                            control={<Radio color="primary" />}
                            label={item.item}
                            labelPlacement="end"
                            name="tipoCategoria"
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </div>
    )
};

export default URadioGroup;
