import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import aereoPassageiro from '../../../../repositorios/aereoPassageiro';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const ChangeValueCC = (props) => {
    const open = props.openChangeValueCC;
    const pax = props.itemSelectedChangeValueCC;
    const [centroCusto, setCentroCusto] = useState(null);
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [centros, setCentros] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const handleClickSalvar = async () => {
        if (!centroCusto) {
            setMensagemSnack('Centro de custo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        try {
            setIsLoading(true);
            let dto = { ...pax, idcentrocusto: centroCusto.value }
            await aereoPassageiro.put(dto);
            props.getBookingTicket();
            props.setOpenChangeValueCC(false);
        } catch (e) {
            setIsLoading(false);
            setMensagemSnack('Erro ao salvar!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            const response = await centroCustoRepositorio.getSearchField();
            setCentros(response);
        })();
    }, [])

    return (
        <>
            <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Dialog open={open} onClose={() => props.setOpenChangeValueCC(false)} fullWidth maxWidth="xs" fullScreen={responsive} TransitionComponent={Transition}>
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#c62828' }}>
                        <Typography style={{ color: '#fff' }}>Alterar centro de custo</Typography>
                        <IconButton onClick={() => props.setOpenChangeValueCC(false)} >
                            <CloseOutlined style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
                    <USearchField {...{ itens: centros, value: centroCusto, setValue: setCentroCusto, label: 'Titulo' }} />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClickSalvar}
                        variant="contained"
                        style={{ color: '#fff', background: '#c62828', '&:hover': { background: '#b91119' } }}
                        disabled={isLoading}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default ChangeValueCC;
