import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import USearchField from "../../../../components/usearchfield";
import Faturado from "../../../../images/faturado.svg";
import aereoBilhete from "../../../../repositorios/aereoBilhete";
import hotelRepositorio from "../../../../repositorios/hotel";
import { useStyles } from "./styles";
import ULoaderVoo from "../../../../components/uloadervoo";
import { HotelNotConfirmedIssue } from "./HotelNotConfirmedIssue";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const HotelIssue = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const classes = useStyles();
    const booking = props.bookingSelected;
    const { setSnackMessage, setSnackState, setSnackVariant } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [dataPayment, setDataPayment] = useState([]);
    const [dataPaymentSelected, setDataPaymentSelected] = useState({});
    const [itensLista, setItensLista] = useState(null);
    const [selecionado, setSelecionado] = useState(null);
    const [openDialogWarningPriceChanged, setOpenDialogWarningPriceChanged] = useState(false);
    const [openDialogWarningNotConfirmed, setOpenDialogWarningNotConfirmed] = useState(false);
    const [objWarning, setObjWarning] = useState({
        valor_original: null,
        valor_novo: null
    });
    const [dataPaymentSelectedParcela, setDataPaymentSelectedParcela] = useState({ value: 1 });
    const dataPaymentParcela = [
        { label: "1x", value: 1 },
        { label: "2x", value: 2 },
        { label: "3x", value: 3 },
        { label: "4x", value: 4 },
    ];

    const handleCloseHotelIssue = () => { props.handleClickCloseHotelIssue() }

    const handleClickCommitIssue = async () => {
        setIsLoading(true);
        try {
            var dto = {
                id_modo_pagamento: dataPaymentSelected?.value ? dataPaymentSelected.value : 0,
                id_cartao: selecionado?.value ? selecionado.value : 0,
                qtd_parcelas: dataPaymentSelectedParcela.value,
                id_modo_pagamento_desc: dataPaymentSelected.label ? dataPaymentSelected.label : ""
            }
            if (dto.id_modo_pagamento === null || dto.id_modo_pagamento === 0) {
                setSnackMessage('Opção de pagamento inválida..');
                setSnackState({ visible: true });
                setSnackVariant('error');
                return;
            }
            const response = await hotelRepositorio.postCommitBookingHotel(booking.idreserva, dto);

            if (response.status === 202) {
                setOpenDialogWarningNotConfirmed(true);
                setIsLoading(false)
                return;
            }



            props.handleClickCloseHotelIssue();
        } catch (e) {
            if (e.response.status === 406) {
                setObjWarning({
                    valor_novo: e.response.data.valor_novo,
                    valor_original: e.response.data.valor_original
                })
                setOpenDialogWarningPriceChanged(true)
            } else if (e.response.status === 202) {
                props.setSnackMessage('Emitida porém não confirmada.');
                props.setSnackState({ visible: true });
                props.setSnackVariant('error');
            }
            else {
                props.setSnackMessage('Falha ao tentar emitir.');
                props.setSnackState({ visible: true });
                props.setSnackVariant('error');
            }
        } finally {
            props.getBookingList();
            setIsLoading(false);
        }
    };

    const handleClickGetPaymentsMode = useCallback(async () => {
        try {
            setIsLoading(true)
            let response = await hotelRepositorio.getHotelPaymentModesInLines(booking.idreserva);
            let response2 = await aereoBilhete.getCreditCards();
            setItensLista(response2.data.itens);
            setDataPayment(response)
        } catch (error) {
            setSnackMessage('Falha ao buscar metodos de pagamentos.');
            setSnackState({ visible: true });
            setSnackVariant('error');
        } finally {
            setIsLoading(false)
        }
    }, [setSnackMessage, setSnackState, setSnackVariant, booking.idreserva])

    useEffect(() => {
        handleClickGetPaymentsMode()
    }, [handleClickGetPaymentsMode])

    return (
        <>
            <ULoaderVoo isLoading={isLoading} />
            <Dialog open={open} onClose={() => handleCloseHotelIssue()} fullWidth maxWidth="md" fullScreen={responsive} TransitionComponent={Transition}>
                {openDialogWarningPriceChanged && (
                    <Dialog open={openDialogWarningPriceChanged} maxWidth="sm" fullScreen={responsive} TransitionComponent={Transition}>
                        <DialogTitle className={classes.appbar}>
                            <Typography style={{ color: '#ffffff' }}>Alerta!</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <Typography>
                                    Valor antigo: {objWarning.valor_original.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </Typography>
                                <Typography>
                                    <b>Valor atual: {objWarning.valor_novo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </b>
                                </Typography>
                                <br />
                                <Typography>
                                    O valor sofreu alteração, favor confirmar antes de continuar a emissão. Caso deseje emitir: clicar novamente em 'EMITIR'.
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDialogWarningPriceChanged(false)} className={classes.buttonEmissao}>Entendi</Button>
                        </DialogActions>
                    </Dialog>
                )}
                {
                    openDialogWarningNotConfirmed && (
                        <HotelNotConfirmedIssue {...{
                            open: openDialogWarningNotConfirmed,
                            responsive: responsive,
                            setOpenDialogWarningNotConfirmed: setOpenDialogWarningNotConfirmed
                        }} />
                    )
                }

                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography>Método de Emissão</Typography>
                        <IconButton onClick={() => handleCloseHotelIssue()} ><CloseOutlined className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container style={{ margin: responsive ? '1rem' : '0' }}>
                        <Grid item xs={responsive ? 12 : 6} className={classes.gridCenter}>
                            <img alt="Foi faturado" src={Faturado} width={responsive ? 100 : 290} />
                        </Grid>
                        <Grid item xs={responsive ? 12 : 6} className={classes.gridCenter}>
                            <Box className={classes.boxCustom} >
                                <Grid container>
                                    <Grid item container xs={12}>
                                        <Grid item xs={12}>
                                            <USearchField
                                                disabled={isLoading}
                                                itens={dataPayment}
                                                value={dataPaymentSelected}
                                                setValue={setDataPaymentSelected}
                                                label="Opções de pagamento"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {dataPaymentSelected?.value === 501 && !isLoading ? (
                                            <USearchField
                                                disabled={isLoading}
                                                itens={dataPaymentParcela}
                                                value={dataPaymentSelectedParcela}
                                                setValue={setDataPaymentSelectedParcela}
                                                label="Quantidade de vezes"
                                            />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {dataPaymentSelected?.value === 501 && !isLoading ? (
                                            <USearchField
                                                disabled={isLoading}
                                                itens={itensLista}
                                                value={selecionado}
                                                setValue={setSelecionado}
                                                label="Cartão"
                                            />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button disabled={isLoading} onClick={() => handleClickCommitIssue()} className={classes.buttonEmissao}>Emitir</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}
