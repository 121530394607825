import axios from 'axios';
import Cookies from 'universal-cookie';
import apiLines from './Api';

const api = axios.create({
    baseURL: 'https://service-rest.esferaplus.com'
    //baseURL: 'https://service-logitravel.esferaplus.com'
    //baseURL: 'http://192.168.25.139/utalii/api'
});

export const montarDtoReserva = (segments, passageiros) => {
    let passageirosVoo = [];
    let varId = 0;
    passageiros.map(item => {
        passageirosVoo.push(
            {
                "Id": varId,
                "Type": item.faixaEtaria.type,
                "FirstName": item.nome,
                "LastName": item.sobrenome,
                "BirthDate": item.dataNascimento ? item.dataNascimento : null,
                "Identification": item.cpf || 0,
                "IdType": "",
                "Nationality": "",
                "FrecuentFlyer": "",
                "PassportNumber": "",
                "PassportExpiration": null,
                "PassoportCountryIssue": "",
                "VisaNumber": "",
                "VisaCountryIssue": "",
                "VisaCityIssue": "",
                "VisaExpiration": null,
                "BirthPlace": "",
                "Remarks": [],
                "ContactInfo": {
                    "Address": null,
                    "Email": item.email,
                    "PhoneNumbers": [],
                    "URL": ""
                }
            }
        )
        varId += 1;
    });

    let dtoReservarAereo = {
        "Segments": segments,
        "SourceCode": segments[0].SourceCode,
        "Passengers": passageirosVoo,
        "ContactInfo": {
            "Address": null,
            "Email": "naotem@esferatur.com.br",
            "PhoneNumbers": [
                {
                    "Type": "BUSINESS",
                    "Number": ""
                }
            ],
            "URL": ""
        },
        "BestBuy": false,
        "CorporateBookingInfo": {
            "CostCenterList": [],
            "ApproverList": []
        },
        "GetFareRules": true,
        "TotalFareSummary": [
            {
                "ItineraryRPH": "undefined"
            }
        ],
        "Cabin": "Economy"
    }
    return dtoReservarAereo;
}

const cookies = new Cookies();

export const buscarVoos = async props => {

    let dtoVoo = {
        adultos: props.adultos,
        criancas: props.criancas,
        bebes: props.bebes,
        apenasVoosDiretos: props.apenasVoosDiretos,
        Trechos: props.Trechos,
        tipoPesquisa: props.tipoPesquisa,
    };

    const response = await apiLines.post('/Aereo/BuscarVoos', dtoVoo, {
        headers: {
            Authorization: 'Bearer ' + cookies.get('token')
        }
    });

    // return montaListaAereo(response.data, props);
    return response.data.trechos;

}

export const reservar = async (dtoTarifarAereo) => {

    const response = await apiLines.post('/Aereo/Reservar', dtoTarifarAereo, {
        headers: {
            Authorization: 'Bearer ' + cookies.get('token')
        }
    });
    return response;
};

export const cancelarReserva = async (dtoCancelar) => {

    const response = await apiLines.post('/Aereo/CancelarAereo', dtoCancelar, {
        headers: {
            Authorization: 'Bearer ' + cookies.get('token')
        }
    });

    return response;

}

export const tarifarAereo = async (dtoTarifarAereo) => {
    const response = await apiLines.post('Aereo/Tarifar', dtoTarifarAereo, {
        headers: {
            Authorization: 'Bearer ' + cookies.get('token')
        }
    });

    return response;

};

export const emitir = async dtoEmissao => {

    try {
        const response = await apiLines.post('/Aereo/Emitir', dtoEmissao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;

    } catch (e) {
        return;
    }
}

const montaListaAereo = (listaVoos, props) => {
    let api = 1;
    switch (api) {
        case 1:
            if (props.tipoPesquisa === 'nacional' || props.Trechos.length <= 1) {
                return montaListaAereoReservaFacil(listaVoos, props)
            }
            else {
                return montaListaAereoReservaFacilInternacional(listaVoos, props);
            }
        default:
            return;
    }
}

const comparaArrays = (lista1, lista2, atributo) => {
    if (lista1.length !== lista2.length) {
        return false;
    }

    for (let i = 0; i < lista2.length; i++) {
        if (lista1[i][atributo] !== lista2[i][atributo]) {
            return false;
        }
    }
    return true;
}

const montaListaAereoReservaFacil = (listaVoos, props) => {
    let separar = [],
        listaMontada = [],
        separarLista = [];

    if (!listaVoos.flights) {
        return [];
    }
    //essa é mais ou menos a lógica pra separar e deixar certinho os voos com o seu respectivo ratetoken
    //entendeu, mais ou menos, vou ler a logica blz isso é pra ti entender a logica e aplicar no back dai blz
    //vlws flws, flw

    listaVoos.flights.map(item => {
        item.segments.map(segment => {
            segment.fareGroup = item.fareGroup;
            separar.push(segment);
        })
    })

    listaMontada = separar.reduce((acum, atual) => {
        let grupo = separar.filter(item => comparaArrays(atual.legs, item.legs, 'flightNumber'));
        let tiposBagagemSet = new Set(grupo.map(item => item.fareProfile.fareFamily));
        let tiposBagagem = [...tiposBagagemSet];
        let grupoFiltrado = tiposBagagem.map(item => grupo.filter(i => i.fareProfile.fareFamily === item)[0]);
        let tem = acum.filter(item => comparaArrays(grupoFiltrado, item, 'rateToken'));
        return tem.length > 0 ? acum : [...acum, grupoFiltrado];
    }, [])

    props.Trechos.map((trecho) => {
        separarLista.push(listaMontada.filter(item => item[0].departure === trecho.origem));
    })
    return separarLista;
}

const montaListaAereoReservaFacilInternacional = (listaVoos, props) => {
    let separar = [],
        listaMontada = [],
        separarLista = [];

    if (!listaVoos.flights) {
        return [];
    }
    //essa é mais ou menos a lógica pra separar e deixar certinho os voos com o seu respectivo ratetoken
    //entendeu, mais ou menos, vou ler a logica blz isso é pra ti entender a logica e aplicar no back dai blz
    //vlws flws, flw

    separar = listaVoos.flights;

    listaMontada = [];
    separar.map(flight => {
        let t = flight.segments.reduce((acum, atual) => {
            atual.fareGroup = flight.fareGroup;
            let grupo = flight.segments.filter(item => comparaArrays(atual.legs, item.legs, 'flightNumber'));
            let tiposBagagemSet = new Set(grupo.map(item => item.fareProfile.fareFamily));
            let tiposBagagem = [...tiposBagagemSet];
            let grupoFiltrado = tiposBagagem.map(item => grupo.filter(i => i.fareProfile.fareFamily === item)[0]);
            let tem = acum.filter(item => comparaArrays(grupoFiltrado, item, 'rateToken'));
            return tem.length > 0 ? acum : [...acum, grupoFiltrado];
        }, [])
        let teste = [];
        t.map(item => {
            teste.push(item[0])
        })
        let idas = teste.filter(item => item.departure === props.Trechos[0].origem)
        let restos = teste.filter(item => item.departure !== props.Trechos[0].origem)
        let voo = [];
        idas.map(ida => {
            restos.map(resto => {
                let i = ida;
                i.rateToken = [];
                i.rateToken.push(ida.rateToken);
                i.rateToken.push(resto.rateToken);
                resto.legs.map(item => i.legs.push(item));
                voo.push(i)
            })
        })
        listaMontada.push(voo);
    });

    separarLista.push(listaMontada);

    return separarLista;
}

export const recuperarFinanciamentos = async dtoDados => {
    try {
        const response = await apiLines.post('/Aereo/RecuperarFinanciamentos', dtoDados, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })

        return response;
    } catch (e) {
        return;
    }
}

export default api;
