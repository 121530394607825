export const EnumTipoVeiculo = {
  categoria: {
    carro: 1,
    van: 2,
    onibus: 3
  },
  combustivel: {
    gasolina: 1,
    etanol: 2,
    diesel: 3,
    gnv: 4
  },
  porte: {
    pequeno: 1,
    medio: 2,
    grande: 3
  },
};
