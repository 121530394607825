export const EnumPackagesAgentes = {

    statusCode: {
        emprocesso: 1,
        aguardandoagente: 2,
        finalizado: 3,
        cancelado: 4,
    },

    statusPackage: {
        emprocesso: {
            text: 'Em Processo',
            color: '#ff9100'
        },
        aguardandoagente: {
            text: 'Aguardando Agente',
            color: '#bf360c'
        },
        finalizado: {
            text: 'Finalizado',
            color: '#43a047'
        },
        cancelado: {
            text: 'Cancelado',
            color: '#f44336'
        },
    },

    getInfoPackage: function (valueCode) {
        let dto = {
            text: null,
            color: null,
        };

        switch (valueCode) {
            case this.statusCode.emprocesso:
                return dto = {
                    text: this.statusPackage.emprocesso.text,
                    color: this.statusPackage.emprocesso.color,
                }
            case this.statusCode.aguardandoagente:
                return dto = {
                    text: this.statusPackage.aguardandoagente.text,
                    color: this.statusPackage.aguardandoagente.color,
                }
            case this.statusCode.finalizado:
                return dto = {
                    text: this.statusPackage.finalizado.text,
                    color: this.statusPackage.finalizado.color,
                }
            case this.statusCode.cancelado:
                return dto = {
                    text: this.statusPackage.cancelado.text,
                    color: this.statusPackage.cancelado.color,
                }
        }
    }
}

export default EnumPackagesAgentes;
