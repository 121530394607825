import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { withStyles } from '@material-ui/core/styles';
import { Add, DeleteRounded, EditOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LinesTextField from '../../../../components/LinesTextField';
import Snackbar from '../../../../components/snackbar';
import UCheckbox from '../../../../components/UCheckbox';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import UTimePicker from '../../../../components/utimepicker';
import cidadeRepositorio from '../../../../repositorios/cidade';
import PoliticaViagemRepositorio from '../../../../repositorios/politicaViagem';
import UsuarioTenantRepositorio from '../../../../repositorios/usuarioTenant';
import { AeroportosProximos } from './AeroportosProximos';
import CadastroExcecao from './excecao';
import CadastroProduto from './produto';
import { useStyles } from './styles';

const AntTabs = withStyles({
    root: {
        background: '#616161',
        color: 'white'
    },
    indicator: {
        backgroundColor: '#c62828',
    },
})(Tabs);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CadastroConfiguracaoOrganizacao = props => {
    const classes = useStyles();
    const theme = useTheme();
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    const [cidades, setCidades] = useState([]);
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState(0);
    const [tipoModal, setTipoModal] = useState(0);
    const [tituloModal, setTituloModal] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [tipoSnack, setTipoSnack] = useState('success');
    const [statusSnack, setStatusSnack] = useState({ visible: false });
    const [backDrop, setBackDrop] = useState(false);
    const [cidade, setCidade] = useState({
        value: null,
        label: null
    })
    const [produtos, setProdutos] = useState([]);
    const [produto, setProduto] = useState({});
    const [excecoes, setExcecoes] = useState([]);
    const [excecao, setExcecao] = useState({});


    const itens2 = [{
        value: 6,
        label: 'Aéreo'
    }, {
        value: 7,
        label: 'Aéreo Internacional'
    }, {
        value: 5,
        label: 'Pacotes'
    }, {
        value: 4,
        label: 'Seguro Viagem'
    }, {
        value: 1,
        label: 'Hotel'
    }, {
        value: 2,
        label: 'Ônibus'
    },
    {
        value: 3,
        label: 'Locação de carro'
    },
    {
        value: 8,
        label: 'Outros'
    }];

    const getProduto = (idproduto) => {
        return itens2.find(item => item.value === idproduto) || {};
    }

    const handleChange = event => {
        props.setPolitica({
            ...props.politica,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeCheckbox = event => {
        props.setPolitica({
            ...props.politica,
            [event.target.name]: event.target.checked
        })
    }

    const handleNovoProdutoClick = (item) => {
        setProduto(item);
        setTituloModal('Produto');
        setTipoModal(1);
        setModalOpen(true);
    }

    const handleNovaExcecaoClick = (item) => {
        setExcecao(item);
        setTituloModal('Exceção');
        setTipoModal(2);
        setModalOpen(true);
    }

    const handleChangeAuto = (campo, valor) => {
        props.setPolitica({
            ...props.politica,
            [campo]: valor
        })
    }

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    }

    const onSalvarExcecao = async () => {
        const dtoExcecao = {
            ...excecao,
            idpoliticaviagem: props.politica.idpoliticaviagem,
            idtenant: props.politica.idtenant,
            tipoexcecao: 1
        }
        if (!excecao.idpoliticaviagemexcecao) {
            setBackDrop(true);
            const responseSalvar = await PoliticaViagemRepositorio.adicionarExcecao(dtoExcecao);
            if (responseSalvar.status !== 201) {
                setTipoSnack('error');
                setMensagemSnack('Erro');
                setBackDrop(false);
                setStatusSnack({ visible: true });
                return;
            }

            setTipoSnack('success');
            setMensagemSnack('Dados Salvos com sucesso!');
            const politicaResponse = await PoliticaViagemRepositorio.getPoliticaEmpresa(props.politica.idempresa);
            props.setPolitica(politicaResponse.data);
            setBackDrop(false);
            setStatusSnack({ visible: true });
            setModalOpen(false);
            return
        }

        setBackDrop(true);
        const responseEditar = await PoliticaViagemRepositorio.editarExcecao(dtoExcecao);
        if (responseEditar.status !== 204) {
            setTipoSnack('error');
            setMensagemSnack('Erro');
            setBackDrop(false);
            setStatusSnack({ visible: true });
            return;
        }

        setTipoSnack('success');
        setMensagemSnack('Dados Salvos com sucesso!');
        const politicaResponse = await PoliticaViagemRepositorio.getPoliticaEmpresa(props.politica.idempresa);
        props.setPolitica(politicaResponse.data);
        setBackDrop(false);
        setStatusSnack({ visible: true });
        setModalOpen(false);
    }

    const onSalvarProduto = async () => {
        const dtoProduto = {
            ...produto,
            idpoliticaviagem: props.politica.idpoliticaviagem,
            idtenant: props.politica.idtenant
        }
        if (!produto.idpoliticaviagemproduto) {
            setBackDrop(true);
            const responseSalvar = await PoliticaViagemRepositorio.produtoAdicionar(dtoProduto);
            if (responseSalvar.status !== 201) {
                setTipoSnack('error');
                setMensagemSnack('Erro');
                setBackDrop(false);
                setStatusSnack({ visible: true });
                return;
            }

            setTipoSnack('success');
            setMensagemSnack('Dados Salvos com sucesso!');
            const politicaResponse = await PoliticaViagemRepositorio.getPoliticaEmpresa(props.politica.idempresa);
            props.setPolitica(politicaResponse.data);
            setBackDrop(false);
            setStatusSnack({ visible: true });
            setModalOpen(false);
            return
        }

        setBackDrop(true);
        const responseEditar = await PoliticaViagemRepositorio.produtoEditar(dtoProduto);
        if (responseEditar.status !== 204) {
            setTipoSnack('error');
            setMensagemSnack('Erro');
            setBackDrop(false);
            setStatusSnack({ visible: true });
            return;
        }

        setTipoSnack('success');
        setMensagemSnack('Dados Salvos com sucesso!');
        const politicaResponse = await PoliticaViagemRepositorio.getPoliticaEmpresa(props.politica.idempresa);
        props.setPolitica(politicaResponse.data);
        setBackDrop(false);
        setStatusSnack({ visible: true });
        setModalOpen(false);
    }

    const onDeletarExcecao = async (item) => {
        if (item.idpoliticaviagemexcecao) {
            setBackDrop(true);
            const response = await PoliticaViagemRepositorio.removerExcecao(item.idpoliticaviagemexcecao);
            if (response.status !== 204) {
                setTipoSnack('error');
                setMensagemSnack('Erro');
                setBackDrop(false);
                setStatusSnack({ visible: true });
                return;
            }
            setTipoSnack('success');
            setMensagemSnack('Exceção Removida com sucesso!');
            const politicaResponse = await PoliticaViagemRepositorio.getPoliticaEmpresa(props.politica.idempresa);
            props.setPolitica(politicaResponse.data);
            setBackDrop(false);
            setStatusSnack({ visible: true });
            setModalOpen(false);
        }
    }

    const onDeletarProduto = async (item) => {
        if (item.idpoliticaviagemproduto) {
            setBackDrop(true);
            const response = await PoliticaViagemRepositorio.produtoDeletar(item.idpoliticaviagemproduto);
            if (response.status !== 204) {
                setTipoSnack('error');
                setMensagemSnack('Erro');
                setBackDrop(false);
                setStatusSnack({ visible: true });
                return;
            }
            setTipoSnack('success');
            setMensagemSnack('Produto Removido com sucesso!');
            const politicaResponse = await PoliticaViagemRepositorio.getPoliticaEmpresa(props.politica.idempresa);
            props.setPolitica(politicaResponse.data);
            setBackDrop(false);
            setStatusSnack({ visible: true });
            setModalOpen(false);
        }
    }

    useEffect(() => {
        cidadeRepositorio.getSearchField().then(response => setCidades(response))

    }, [])

    return (
        <div>
            <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: statusSnack.visible, setSnackbar: setStatusSnack }} />
            <ULoaderVoo isLoading={backDrop} />
            <Dialog
                open={modalOpen}
                fullWidth={true}
                maxWidth={'xs'}
                onClose={() => setModalOpen(false)}>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography>{tituloModal}</Typography>
                </DialogTitle>
                <DialogContent>
                    <ULoaderVoo isLoading={backDrop} />
                    {tipoModal === 1 ? (
                        <CadastroProduto {...{ produtos: itens2, produto, setProduto }} />
                    ) : tipoModal === 2 ? (
                        <CadastroExcecao {...{ cidades, excecao, setExcecao }} />
                    ) : null}
                    <DialogActions id="action4">
                        <Button className={classes.button} onClick={tipoModal === 2 ? onSalvarExcecao : tipoModal === 1 ? onSalvarProduto : null}>Salvar</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Box className={classes.root}>
                <AntTabs value={value} variant="scrollable" scrollButtons="on" style={{ position: 'relative' }} onChange={handleChangeTab}>
                    {/* <Tab label="Autorizadores" {...a11yProps(0)} /> */}
                    <Tab label="Política de Viagem" {...a11yProps(0)} />
                    <Tab label="Emissões" {...a11yProps(1)} />
                    <Tab label="ConfiguraçÕes" {...a11yProps(2)} />
                    <Tab label="Política de Gastos" {...a11yProps(3)} />
                    {permissaoDashboard.usuarioLinestur ? (
                        <Tab label="Eco Gestão" {...a11yProps(4)} />
                    ) : null}
                    <Button style={{ color: '#ffffff' }} onClick={() => props.setModalOpen(false)}>Fechar</Button>
                </AntTabs>
                {/* <TabPanel value={value} index={0} key={value === 0 ? true : false}>
                    <div className={classes.body}>
                        <div className={classes.card}>
                            <TableBody>
                                <TableRow>
                                    <TableCell align='center'></TableCell>
                                </TableRow>
                            </TableBody>
                        </div>
                    </div>
                </TabPanel> */}
                <TabPanel value={value} index={0}>
                    <div className={classes.body}>
                        <div className={classes.textField2}>
                            <LinesTextField
                                id="teste1"
                                className={classes.txt}
                                label="Politica de viagem"
                                variant="outlined"
                                margin="dense"
                                value={props.politica.politicaviagem}
                                onChange={handleChange}
                                name="politicaviagem"
                                multiline
                                rows={18}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} >
                    <div className={classes.body}>
                        {!matches ? (
                            <Paper>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        padding="checkbox"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center">Permite Emitir?</TableCell>
                                                <TableCell align="center">Horário Mínimo</TableCell>
                                                <TableCell align="center">Horário Máximo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center">Segundas-Feiras</TableCell>
                                                <TableCell align="center"><UCheckbox checked={props.politica.permiteemitirsegundasfeiras} onChange={handleChangeCheckbox} name="permiteemitirsegundasfeiras" /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horaminimasegundas} name='horaminimasegundas' onChange={handleChangeAuto} /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horamaximasegundas} name='horamaximasegundas' onChange={handleChangeAuto} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">Terças-Feiras</TableCell>
                                                <TableCell align="center"><UCheckbox checked={props.politica.permiteemitirtercasfeiras} onChange={handleChangeCheckbox} name="permiteemitirtercasfeiras" /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horaminimatercas} name='horaminimatercas' onChange={handleChangeAuto} /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horamaximatercas} name='horamaximatercas' onChange={handleChangeAuto} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">Quartas-Feiras</TableCell>
                                                <TableCell align="center"><UCheckbox checked={props.politica.permiteemitirquartasfeiras} onChange={handleChangeCheckbox} name="permiteemitirquartasfeiras" /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horaminimaquartas} name='horaminimaquartas' onChange={handleChangeAuto} /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horamaximaquartas} name='horamaximaquartas' onChange={handleChangeAuto} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">Quintas-Feiras</TableCell>
                                                <TableCell align="center"><UCheckbox checked={props.politica.permiteemitirquintasfeiras} onChange={handleChangeCheckbox} name="permiteemitirquintasfeiras" /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horaminimaquintas} name='horaminimaquintas' onChange={handleChangeAuto} /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horamaximaquintas} name='horamaximaquintas' onChange={handleChangeAuto} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">Sextas-Feiras</TableCell>
                                                <TableCell align="center"><UCheckbox checked={props.politica.permiteemitirsextasfeiras} onChange={handleChangeCheckbox} name="permiteemitirsextasfeiras" /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horaminimasextas} name='horaminimasextas' onChange={handleChangeAuto} /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horamaximasextas} name='horamaximasextas' onChange={handleChangeAuto} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">Sabados</TableCell>
                                                <TableCell align="center"><UCheckbox checked={props.politica.permiteemitirsabados} onChange={handleChangeCheckbox} name="permiteemitirsabados" /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horaminimasabados} name='horaminimasabados' onChange={handleChangeAuto} /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horamaximasabados} name='horamaximasabados' onChange={handleChangeAuto} /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center">Domingo</TableCell>
                                                <TableCell align="center"><UCheckbox checked={props.politica.permiteemitirdomingos} onChange={handleChangeCheckbox} name="permiteemitirdomingos" /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horaminimadomingos} name='horaminimadomingos' onChange={handleChangeAuto} /></TableCell>
                                                <TableCell align="center"><UTimePicker className={classes.utime} value={props.politica.horamaximadomingos} name='horamaximadomingos' onChange={handleChangeAuto} /></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>) :

                            <List className={classes.root} subheader={<li />}>
                                {[0].map(sectionId => (
                                    <li key={`section-${sectionId}`} className={classes.listSection}>
                                        <ul className={classes.ul}>
                                            <div>
                                                <Paper className={classes.paperList}>
                                                    <ListSubheader>{'Segundas-Feiras'}</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={'Permite emitir?'} />
                                                        <ListItemText primary={<UCheckbox checked={props.politica.permiteemitirsegundasfeiras} onChange={handleChangeCheckbox} name="permiteemitirsegundasfeiras" />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Minímo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horaminimasegundas} name='horaminimasegundas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Máximo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horamaximasegundas} name='horamaximasegundas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                </Paper>
                                                <Paper className={classes.paperList}>
                                                    <ListSubheader>{'Terças-Feiras'}</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={'Permite emitir?'} />
                                                        <ListItemText primary={<UCheckbox checked={props.politica.permiteemitirtercasfeiras} onChange={handleChangeCheckbox} name="permiteemitirtercasfeiras" />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Minímo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horaminimatercas} name='horaminimatercas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Máximo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horamaximatercas} name='horamaximatercas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                </Paper>
                                                <Paper className={classes.paperList}>
                                                    <ListSubheader>{'Quartas-Feiras'}</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={'Permite emitir?'} />
                                                        <ListItemText primary={<UCheckbox checked={props.politica.permiteemitirquartasfeiras} onChange={handleChangeCheckbox} name="permiteemitirquartasfeiras" />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Minímo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horaminimaquartas} name='horaminimaquartas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Máximo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horamaximaquartas} name='horamaximaquartas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                </Paper>
                                                <Paper className={classes.paperList}>
                                                    <ListSubheader>{'Quintas-Feiras'}</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={'Permite emitir?'} />
                                                        <ListItemText primary={<UCheckbox checked={props.politica.permiteemitirquintasfeiras} onChange={handleChangeCheckbox} name="permiteemitirquintasfeiras" />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Minímo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horaminimaquintas} name='horaminimaquintas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Máximo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horamaximaquintas} name='horamaximaquintas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                </Paper>
                                                <Paper className={classes.paperList}>
                                                    <ListSubheader>{'Sextas-Feiras'}</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={'Permite emitir?'} />
                                                        <ListItemText primary={<UCheckbox checked={props.politica.permiteemitirsextasfeiras} onChange={handleChangeCheckbox} name="permiteemitirsextasfeiras" />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Minímo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horaminimasextas} name='horaminimasextas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Máximo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horamaximasextas} name='horamaximasextas' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                </Paper>
                                                <Paper className={classes.paperList}>
                                                    <ListSubheader>{'Sabado'}</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={'Permite emitir?'} />
                                                        <ListItemText primary={<UCheckbox checked={props.politica.permiteemitirsabados} onChange={handleChangeCheckbox} name="permiteemitirsabados" />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Minímo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horaminimasabados} name='horaminimasabados' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Máximo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horamaximasabados} name='horamaximasabados' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                </Paper>
                                                <Paper className={classes.paperList}>
                                                    <ListSubheader>{'Domingo'}</ListSubheader>
                                                    <ListItem>
                                                        <ListItemText primary={'Permite emitir?'} />
                                                        <ListItemText primary={<UCheckbox checked={props.politica.permiteemitirdomingos} onChange={handleChangeCheckbox} name="permiteemitirdomingos" />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Minímo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horaminimadomingos} name='horaminimadomingos' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                    <ListItem>
                                                        <ListItemText primary={'Horário Máximo'} />
                                                        <ListItemText primary={<UTimePicker className={classes.utime} value={props.politica.horamaximadomingos} name='horamaximadomingos' onChange={handleChangeAuto} />} />
                                                    </ListItem>
                                                </Paper>
                                            </div>
                                        </ul>
                                    </li>
                                ))}
                            </List>
                        }
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2} >
                    {!matches ? (
                        <div className={classes.body}>
                            <div className={classes.linha}>
                                <div className={classes.form} >
                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.permiteautnegemail} onChange={handleChangeCheckbox} name="permiteautnegemail" />}
                                                label="Autorizar Ou Negar Emissão Por E-Mail"
                                                name="apenasVoosDiretos"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.permitepreenchermanualpassageiro} onChange={handleChangeCheckbox} name="permitepreenchermanualpassageiro" />}
                                                label="Preenchimento Manual De Dados Do Passageiro"
                                                name="apenasVoosDiretos"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.impedirreservaforadoprazo} onChange={handleChangeCheckbox} name="impedirreservaforadoprazo" />}
                                                label="Impedir Aéreo Anterior Do Menor Dia De Antecedência"
                                                name="apenasVoosDiretos"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.utilizarcentrocusto} onChange={handleChangeCheckbox} name="utilizarcentrocusto" />}
                                                label="Utilizar Centro De Custo"
                                                name="apenasVoosDiretos"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.autocancelamento} onChange={handleChangeCheckbox} name="autocancelamento" />}
                                                label="Auto Cancelar Aéreos à 00:00"
                                                name="apenasVoosDiretos"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.menortarifa} onChange={handleChangeCheckbox} name="menortarifa" />}
                                                label="Reduções - IA"
                                                name="menortarifa"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.politicadespesaviagem} onChange={handleChangeCheckbox} name="politicadespesaviagem" />}
                                                label="Utilizar Despesas por Viagem"
                                                name="apenasVoosDiretos"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.utilizarantecedenciasolicitacao} onChange={handleChangeCheckbox} name="utilizarantecedenciasolicitacao" />}
                                                label="Utilizar tempo de antecedência para Solicitações"
                                                name="tempoAntecedenciaHoteis"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.autorizadoraereointernacional} onChange={handleChangeCheckbox} name="autorizadoraereointernacional" />}
                                                label="Autorização para todos aéreos internacionais"
                                                name="autorizadoraereointernacional"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.permiteautodespesas} onChange={handleChangeCheckbox} name="permiteautodespesas" />}
                                                label="Criar despesas automático"
                                                name="permiteautodespesas"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                        {/* <FormGroup aria-label="position" name="position" column>
                                            <FormControlLabel
                                                value="Apenas Vôos diretos"
                                                control={<UCheckbox checked={props.politica.autorizadorsolicitacaointernacional} onChange={handleChangeCheckbox} name="autorizadorsolicitacaointernacional" />}
                                                label="Aprovação para todas solicitações internacionais"
                                                name="autorizadorsolicitacaointernacional"
                                                labelPlacement="end"
                                            />
                                        </FormGroup> */}
                                    </FormControl>
                                </div>

                                <div class Name={classes.restricao}>
                                    {/* <LinesTextField className={classes.textField3} required id="standard-required" label="Hora Mínima" defaultValue="Sem Restrição" /> */}
                                    {/* <LinesTextField className={classes.textField3} required id="standard-required" label="Horário Máximo" defaultValue="Sem Restrição" /> */}
                                    <UTimePicker className={classes.textField3} value={props.politica.horaminimapadrao} name='horaminimapadrao' label="Horário Mínimo" onChange={handleChangeAuto} />
                                    <UTimePicker className={classes.textField3} value={props.politica.horamaximapadrao} name='horamaximapadrao' label="Horário Máximo" onChange={handleChangeAuto} />
                                    <LinesTextField className={classes.textField3} required label="Antecedência Média" value={props.politica.antecedenciamedia} onChange={handleChange} name="antecedenciamedia" placeholder="Sem Restrição" />
                                    <LinesTextField className={classes.textField3} label="VoeBiz" value={props.politica.voebiz} onChange={handleChange} name="voebiz" />
                                </div>
                            </div>
                        </div>) : <List className={classes.root} subheader={<li />}>
                        {[0].map(sectionId => (
                            <li key={`section-${sectionId}`} className={classes.listSection}>
                                <ul className={classes.ul}>
                                    <Paper className={classes.paperList}>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Autorizar ou Negar emissões por e-mail'} />
                                            <UCheckbox checked={props.politica.permiteautnegemail} onChange={handleChangeCheckbox} name="permiteautnegemail" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Preenchimento Manual De Dados Do Passageiro'} />
                                            <UCheckbox checked={props.politica.permitepreenchermanualpassageiro} onChange={handleChangeCheckbox} name="permitepreenchermanualpassageiro" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Impedir Aéreo Anterior Do Menor Dia De Antecedência'} />
                                            <UCheckbox checked={props.politica.impedirreservaforadoprazo} onChange={handleChangeCheckbox} name="impedirreservaforadoprazo" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Utilizar Centro De Custo'} />
                                            <UCheckbox checked={props.politica.utilizarcentrocusto} onChange={handleChangeCheckbox} name="utilizarcentrocusto" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Auto Cancelar Aéreos à 00:00'} />
                                            <UCheckbox checked={props.politica.autocancelamento} onChange={handleChangeCheckbox} name="autocancelamento" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Reduções - IA'} />
                                            <UCheckbox checked={props.politica.menortarifa} onChange={handleChangeCheckbox} name="menortarifa" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Utilizar Despesas por Viagem'} />
                                            <UCheckbox checked={props.politica.politicadespesaviagem} onChange={handleChangeCheckbox} name="politicadespesaviagem" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <ListItemText primary={'Utilizar tempo de antecedência para Solicitações'} />
                                            <UCheckbox checked={props.politica.utilizarantecedenciasolicitacao} onChange={handleChangeCheckbox} name="utilizarantecedenciasolicitacao" />
                                        </ListItem>
                                        <ListItem className={classes.configuracoes}>
                                            <div class Name={classes.restricao}>
                                                <UTimePicker className={classes.textField3} value={props.politica.horaminimapadrao} name='horaminimapadrao' label="Horário Mínimo" onChange={handleChangeAuto} />
                                                <UTimePicker className={classes.textField3} value={props.politica.horamaximapadrao} name='horamaximapadrao' label="Horário Máximo" onChange={handleChangeAuto} />
                                                <LinesTextField className={classes.textField3} required label="Antecedência Média" value={props.politica.antecedenciamedia} onChange={handleChange} name="antecedenciamedia" placeholder="Sem Restrição" />
                                                <LinesTextField className={classes.textField3} label="VoeBiz" value={props.politica.voebiz} onChange={handleChange} name="voebiz" />
                                            </div>
                                        </ListItem>
                                    </Paper>
                                </ul>
                            </li>
                        ))}
                    </List>}
                </TabPanel>
                <TabPanel value={value} index={3} >
                    {!matches ? (
                        <div className={classes.body}>
                            <div className={classes.adicionar}>
                                <Typography>Produto</Typography>
                                <Button className={classes.button} onClick={() => handleNovoProdutoClick({})}>
                                    <Add />
                                    Adicionar
                                </Button>
                            </div>
                            <Paper>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        padding="checkbox"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Produto</TableCell>
                                                <TableCell align="center">Tipo</TableCell>
                                                <TableCell align="center">Limite</TableCell>
                                                <TableCell align="center">Internacional</TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* <TableRow>
                                                <TableCell align="center"><div style={{ minWidth: 200 }}><USelect {...{ itens: itens2, value: value2, setValue: setValue2 }}></USelect></div></TableCell>
                                                <TableCell align="center"><LinesTextField className={classes.utime} variant="outlined" margin="dense" /></TableCell>
                                                <TableCell align="center"><LinesTextField className={classes.utime} variant="outlined" margin="dense" /></TableCell>
                                                <TableCell align="center"><UCheckbox /></TableCell>
                                            </TableRow> */}
                                            {props.politica.produtos.map(item => (
                                                <TableRow>
                                                    <TableCell align="center">{getProduto(item.tipoproduto).label}</TableCell>
                                                    <TableCell align="center">{item.palavrachave}</TableCell>
                                                    <TableCell align="center">{item.limite}</TableCell>
                                                    <TableCell align="center">{item.internacional ? 'Sim' : 'Não'}</TableCell>
                                                    <TableCell align="right"><IconButton onClick={() => handleNovoProdutoClick(item)} ><EditOutlined /></IconButton></TableCell>
                                                    <TableCell align="right"><IconButton onClick={() => onDeletarProduto(item)}><DeleteRounded /></IconButton></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            <div style={{ margin: '20px 0px' }}>
                            </div>
                            <div className={classes.adicionar}>
                                <Typography>Exceção</Typography>
                                <Button className={classes.button} onClick={() => handleNovaExcecaoClick({})}>
                                    <Add />
                                    Adicionar
                                </Button>
                            </div>
                            <Paper>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        padding="checkbox"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Cidade</TableCell>
                                                <TableCell align="center">Tipo</TableCell>
                                                <TableCell align="center">Limite</TableCell>
                                                <TableCell align="right"></TableCell>
                                                <TableCell align="right"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.politica.excecoes.map(item => (
                                                <TableRow>
                                                    <TableCell align="center">{item.cidade.nome}</TableCell>
                                                    <TableCell align="center">{item.palavrachave}</TableCell>
                                                    <TableCell align="center">{item.limite}</TableCell>
                                                    <TableCell align="right"><IconButton onClick={() => handleNovaExcecaoClick(item)} ><EditOutlined /></IconButton></TableCell>
                                                    <TableCell align="right"><IconButton onClick={() => onDeletarExcecao(item)}><DeleteRounded /></IconButton></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </div>) : (
                        <Grid container spacing={3} style={{ margin: 0 }} item xs={12}>
                            <Grid item xs={12}>
                                <Paper>
                                    <List
                                        subheader={
                                            <ListSubheader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} component="div" id="subheader-produtos">
                                                <Typography>Produtos</Typography>
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => handleNovoProdutoClick({})}
                                                >
                                                    <Add style={{ color: '#c62828' }} />
                                                </IconButton>
                                            </ListSubheader>
                                        }
                                    >
                                        {props.politica.produtos.map(item => (
                                            <ListItem
                                                button
                                                onClick={() => handleNovoProdutoClick(item)}
                                            >
                                                <ListItemText
                                                    primary={getProduto(item.tipoproduto).label}
                                                    secondary={`${item.palavrachave} - R$${String(item.limite.toFixed(2)).replace('.', ',')} - ${item.internacional ? 'Internacional' : 'Nacional'}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="editar"
                                                        onClick={() => onDeletarProduto(item)}
                                                    >
                                                        <DeleteRounded style={{ color: '#c62828' }} />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper>

                                    <List
                                        subheader={
                                            <ListSubheader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} component="div" id="subheader-produtos">
                                                <Typography>Exceções</Typography>
                                                <IconButton
                                                    edge="end"
                                                    onClick={() => handleNovaExcecaoClick({})}
                                                >
                                                    <Add style={{ color: '#c62828' }} />
                                                </IconButton>
                                            </ListSubheader>
                                        }
                                    >
                                        {props.politica.excecoes.map(item => (
                                            <ListItem
                                                button
                                                onClick={() => handleNovaExcecaoClick(item)}
                                            >
                                                <ListItemText
                                                    primary={item.cidade.nome}
                                                    secondary={`${item.palavrachave} - R$${String(item.limite.toFixed(2)).replace('.', ',')} - ${item.internacional ? 'Internacional' : 'Nacional'}`}
                                                />
                                                <ListItemSecondaryAction>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="editar"
                                                        onClick={() => onDeletarExcecao(item)}
                                                    >
                                                        <DeleteRounded style={{ color: '#c62828' }} />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>



                        // <div>
                        //     <Paper className={classes.paperList}>
                        //         <ListItem className={classes.configuracoes}>
                        //             <ListItemText primary={'Produto'}>
                        //             </ListItemText>
                        //         </ListItem>
                        //         <div style={{ minWidth: 200 }}><USelect {...{ itens: itens2, value: value2, setValue: setValue2 }}></USelect></div>
                        //         <ListItemText primary={'Tipo'}></ListItemText>
                        //         <LinesTextField className={classes.utime} variant="outlined" margin="dense" />
                        //         <ListItemText primary={'Limite'}></ListItemText>
                        //         <LinesTextField className={classes.utime} variant="outlined" margin="dense" />
                        //         <ListItemText primary={'Internacional'}></ListItemText>
                        //         <UCheckbox />
                        //     </Paper>
                        //     <Container>
                        //         {produtos.map(item => (
                        //             <Paper className={classes.paperList}>
                        //                 <ListItem className={classes.configuracoes}>
                        //                     <ListItemText primary={'Produto'}>
                        //                     </ListItemText>
                        //                 </ListItem>
                        //                 <div style={{ minWidth: 200 }}><USelect {...{ itens: itens2, value: value2, setValue: setValue2 }}></USelect></div>
                        //                 <ListItemText primary={'Tipo'}></ListItemText>
                        //                 <LinesTextField className={classes.utime} variant="outlined" margin="dense" />
                        //                 <ListItemText primary={'Limite'}></ListItemText>
                        //                 <LinesTextField className={classes.utime} variant="outlined" margin="dense" />
                        //                 <ListItemText primary={'Internacional'}></ListItemText>
                        //                 <UCheckbox />
                        //             </Paper>
                        //         ))}

                        //     </Container>

                        //     <Paper>
                        //         <ListItem className={classes.configuracoes}>
                        //             <ListItemText primary={'Exceção'}></ListItemText>
                        //         </ListItem>
                        //         <ListItemText primary={'Cidade'}></ListItemText>
                        //         <div style={{ minWidth: 200 }}><USelect {...{ itens: itens2, value: value2, setValue: setValue2 }}></USelect></div>
                        //         <ListItemText primary={'Tipo'}></ListItemText>
                        //         <LinesTextField className={classes.utime} variant="outlined" margin="dense" />
                        //         <ListItemText primary={'Limite'}></ListItemText>
                        //         <LinesTextField className={classes.utime} variant="outlined" margin="dense" />
                        //     </Paper>
                        // </div>
                    )}
                </TabPanel>
                {permissaoDashboard.usuarioLinestur && value === 4 ? (
                    <TabPanel value={value} index={4} >
                        <div className={classes.body2} style={{ margin: '-24px' }}>
                            <AeroportosProximos {...{ setTipoSnack, setMensagemSnack, setStatusSnack, idtenantEmpresa: props.politica.idtenant }} />
                        </div>
                    </TabPanel>
                ) : null}
            </Box>
        </div>
    )
};

export default CadastroConfiguracaoOrganizacao;
