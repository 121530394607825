import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const fidelidadeRepositorio = {

    getLoyaltyCompaniesOptions: async (id) => {
        const response = await api.get('/CiasFidelidade/getLoyaltyCompaniesOptions/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    postNewAlternativeName: async (dto) => {
        const response = await api.post('/CiasFidelidade/postNewAlternativeName', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    postDeleteAlternativeName: async (dto) => {
        const response = await api.post('/CiasFidelidade/postDeleteAlternativeName', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getListAlternativeName: async (id) => {
        const response = await api.get('/CiasFidelidade/getListAlternativeName/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

}

export default fidelidadeRepositorio;
