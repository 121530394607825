import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const tempoDecisaoRepositorio = {

    getRelatorioTempoDecisao: async dtoDadosRelatorio => {
        const response = await api.post('/Relatorio/GetRelatorioTempoDecisao', dtoDadosRelatorio, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    }
}

export default tempoDecisaoRepositorio;
