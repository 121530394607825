import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    imgCia: {
        margin: "5px",
    },

    tableTitles: {
        background: 'DimGray',
        color: 'white',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },

    titleGrid: {
        textAlign: 'end'
    },




    info: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        fontFamily: 'arial',
        fontSize: '1%',
        marginTop: theme.spacing(2)
    },

}));



