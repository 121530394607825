import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    closeButton: {
        color: '#ffffff'
    },

    dialogTitle: {
        background: '#c62828',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight: "4vh"
    },

    ticket: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
}));



