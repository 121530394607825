import { NewReleasesOutlined, VerifiedOutlined } from "@mui/icons-material"
import { Box, Container, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"

export const ViewQrCodeProducts = ({ QrCode }) => {
    return (
        <>
            <Container sx={{ height: "100%" }}>
                <Grid container item xs={12} style={{ marginTop: "10px", alignContent: "space-between", alignContent: "center", padding: "10px" }} spacing={1}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                            Produtos encontrados:
                        </Typography>
                    </Box>

                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}
                        aria-label="contacts"
                    >
                        {QrCode?.itens?.map((item) => (
                            <ListItem
                                key={item.id}
                                style={{
                                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    borderRadius: "16px",
                                    marginBottom: "10px"
                                }}
                            >
                                <ListItemIcon>
                                    {item.permitido ? (
                                        <VerifiedOutlined color="primary" />
                                    ) : (
                                        <NewReleasesOutlined color="error" />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="body2">
                                            {item.name}
                                        </Typography>
                                    }
                                    secondary={
                                        new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        }).format(item.vlTotal)
                                    }
                                />
                            </ListItem>
                        ))}
                        <Divider primary="Full width variant below" />
                    </List>
                </Grid>
            </Container>
        </>
    )
}