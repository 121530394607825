import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Select, MenuItem, InputAdornment, Divider, Chip, Avatar, IconButton } from '@mui/material';
import { AttachMoney, ArrowForward, VisibilityOff, Visibility, ArrowForwardIos } from '@mui/icons-material';
import { EnumTipo, formatarParaMoeda, formatarParaMoedaString, formatarParaNomeStatusAdiantamento, formatarValorComMoeda } from '../../../enums/enumPrestacaoDeContas';
import Flag from 'react-world-flags';
import { grey } from '../../../theme/palette';

const TransferComponent = ({ viagem, despesas, totalex = 0.00, totalbrl = 0.00, moeda = EnumTipo.moedas.USD.id, status }) => {
    const [showBalance, setShowBalance] = useState(true);
    const [total, setTotal] = useState(0)

    const handleToggleBalance = () => {
        setShowBalance(!showBalance);
    };

    const GetGastoTotal = () => {
        let total2 = 0;
        despesas.forEach(despesa => {
            if(despesa.tipo_moeda === moeda) {
                total2 += despesa.total;
            }
        });
        setTotal(total2);
    }
    

    useEffect(() => {
        GetGastoTotal()
    },[despesas])

    return (
        <Box sx={{ maxWidth: 400, p: 3, borderRadius: "10px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", backgroundColor: 'white' }}>
            <Grid container spacing={1}>
                {status === EnumTipo.adiantamento.aprovado ? (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="caption" color="textSecondary">{formatarParaMoedaString(moeda, totalex)}</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "-14px" }}>
                                <Typography variant="h6" style={{ fontWeight: "600", color: '#262626', fontSize: "20px", textAlign: "left", display: "flex" }}>
                                    <div style={{ display: "flex" }}>
                                        {showBalance ? formatarValorComMoeda(moeda, totalex - total) : '***'}
                                    </div>
                                    <IconButton onClick={handleToggleBalance} size="small" style={{ marginTop: "-3px" }}>
                                        {showBalance ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </Typography>
                                {/* <Grid item xs={12} style={{ display: "flex", justifyContent: "right", textAlign: "right", paddingBottom: "-6px" }}>
                                    <Button onClick={() => setMyLimits(true)} size="large" endIcon={<ArrowForwardIos sx={{ width: "15px" }} />} style={{ color: "#1C252E" }}>Meus limites</Button>
                                </Grid> */}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2px" }}>
                                <Typography variant="body2" sx={{ fontWeight: "600", color: '#393939' }}>
                                    Saldo Atual
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            {formatarParaMoeda(moeda, totalex)}
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="body2" style={{ fontWeight: "500", color: grey[600] }}>Valor em reais</Typography>
                                <Typography variant="body2" style={{ fontWeight: "600", color: grey[800] }}>{formatarValorComMoeda(EnumTipo.moedas.BRL.id, totalbrl)}</Typography>
                            </Box>
                        </Grid>

                        <Divider sx={{ my: 2, width: '100%' }} />
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" color="textSecondary" style={{ display: "contents" }}>
                                        {/* <ArrowForward fontSize="small" />  */}
                                        Financeiro
                                    </Typography>
                                </Box>
                                <Typography variant="caption" color="textSecondary">
                                    {formatarParaNomeStatusAdiantamento(status)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default TransferComponent;
