import { Button, Container, Grid, Typography } from "@mui/material"
import { EnumTipo } from "../../../../../../../enums/enumPrestacaoDeContas";
import { CreationDate } from "../../../../../Desktop/sections";
import { useState } from "react";
import { adicionarNovaDespesaExtra_Mock, adicionarNovaDespesa_Mock } from "../../../../../../../mock/prestacaodecontas";
import { AddModeratorOutlined, SaveAltOutlined } from "@mui/icons-material";
import DialogComponent from "../../../../../Desktop/sections/dialogs-desktop";

export const ExtrasDespesasComponentM = ({ viagem }) => {
    const [dialogConfirm, setDialogConfirm] = useState(false);
    const [despesa, setDespesa] = useState(null);

    const handleClickOpenAdd = (d) => {
        setDespesa({ idviagem: viagem.id, ...d })
        setDialogConfirm(true)
    }

    const handleClickConfirmarAdd = () => {
        adicionarNovaDespesaExtra_Mock(despesa)
        setDialogConfirm(false)
    }

    return (
        <>
            <DialogComponent
                title={viagem?.titulo}
                open={dialogConfirm}
                handleClose={() => setDialogConfirm(false)}
                body={
                    <Grid container item xs={12}>
                        <Typography>Ao Adicionar o extra se tornara uma despesa comum</Typography>
                    </Grid>
                }
                action={
                    <Grid>
                        <Button size="large" onClick={() => setDialogConfirm(false)}>Cancelar</Button>
                        <Button size="large" onClick={() => handleClickConfirmarAdd()}>Confimar</Button>
                    </Grid>
                }
                width={"sm"}
            />
            <Container>
                {viagem.extras?.map((value) => (
                    <>
                        {!value.aprovado || viagem.status != EnumTipo.viagemstatus.finalizado && viagem.status != EnumTipo.viagemstatus.viagemfinalizada ? (
                            <Grid
                                item xs={12}
                                style={{
                                    marginTop: "20px", padding: "20px", transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                                    marginTop: "50px",
                                    cursor: 'default', // Cursor padrão quando não está em hover
                                    backgroundColor: "#FFFFFF",
                                    textAlign: 'center',
                                    borderRadius: "10px",
                                    boxShadow: "10px",
                                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                }}
                                container
                            >
                                <Grid container item xs={12}>
                                    <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                        <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "left" }}>
                                    <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                </Grid>
                                <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "space-between", justifyContent: "left" }}>
                                    <CreationDate data={value.datadecriacao} />
                                </Grid>
                                <Grid item xs={3} style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignContent: "flex-end",
                                    alignItems: "flex-end",
                                    flexDirection: "row"
                                }}>
                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button size="large" onClick={() => handleClickOpenAdd(value)} fullWidth variant="contained" color="secondary" style={{ marginTop: "0px", marginRight: "3px", borderRadius: "20px" }} startIcon={<SaveAltOutlined />}>
                                        Adicionar
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            null
                        )}
                    </>
                ))}
            </Container>
        </>
    )
}
