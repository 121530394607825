import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Grid, List, ListItem, Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import { EditOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import PropTypes from 'prop-types';
import React, { useState } from "react";
import USearchField from "../../../components/usearchfield";
import { useStyles } from "./styles";

const AntTabs = withStyles({
  root: {
    background: '#fafafa',
    color: '#c62828'
  },
  indicator: {
    backgroundColor: '#c62828',
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={0}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const EcoGestaoMobi = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0)

  const isPast = props.dataList.isPast;

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  }

  const handleClickRemovePaxFromGroup = (group, pax) => {
    props.handleClickRemovePaxFromGroup(group, pax)
  }

  const changeVeiculo = (group) => {
    props.onChangeVeiculoGroup(group)
  }

  function definirAssunto(assunto) {
    if (!assunto) { return ""; }
    let textos = assunto.split("<br />");
    return (<div>{textos.map((item) => (<div>{item}</div>))}</div>);
  }

  return (
    <>
      <Box className={classes.boxPage}>
        <AntTabs
          variant="fullWidth"
          scrollButtons="on"
          value={tabValue}
          onChange={handleChangeTab}
        >
          <Tab label="Passageiros" {...a11yProps(0)} />
          <Tab label="Grupos" {...a11yProps(1)} />
        </AntTabs>
        <TabPanel value={tabValue} index={0}>
          <Grid container>
            <Grid item xs={12} className={classes.gridAeroportosPax}>
              {props.dataList.aeroportosPassageiros.length <= 0 ? null : props.dataList.aeroportosPassageiros.map((item) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography style={{ fontSize: '14pt' }}><b>{item.cidade}</b></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item container xs={12}>
                        <List divider>
                          {item.passageiros.map((pax) => (
                            <ListItem className={classes.listItemPax}>
                              <Grid container>

                                <Grid item container xs={12}>
                                  <Grid item xs={12} className={classes.gridTitle}>
                                    <Typography variant="h6"><b>{pax.nome}</b></Typography>
                                  </Grid>
                                  <Grid item xs={12} className={classes.gridTitle}>
                                    <Typography>Horário: <b>{pax.horadesc} - {pax.hora}</b> </Typography>
                                  </Grid>
                                  <Grid item xs={12} className={classes.gridTitle}>
                                    <Typography ><b>{pax.cc}</b></Typography>
                                    <Typography>{pax.ccNome}</Typography>
                                  </Grid>
                                  <Grid item xs={12} className={classes.gridTitle}>
                                    <Typography>{definirAssunto(pax.trecho)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} className={classes.gridTitle}>
                                    <Typography >Emissão CO2 (kg):</Typography>
                                    <Typography style={{ color: '#c62828' }}> <b>{pax.carbono}</b></Typography>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.gridButton}>
                                  <Button disabled={isPast || !props.permissao.permiteeditarecogestao} onClick={() => props.handleClickPax(pax, isPast)} className={classes.button} variant="contained" fullWidth >
                                    <Typography>SELECIONAR</Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {props.dataList.grupos.length <= 0 ? (
            <Typography variant="h6" className={classes.gridTitle}> Não há registros para essa data </Typography>
          ) : (
            <List >
              {props.dataList.grupos.map((item) => (
                <ListItem className={classes.listItemCustom}>
                  <Grid container >
                    <Grid item xs={12} className={classes.gridTitle}>
                      <Typography variant="h6"><b>Nº {item.idgrouptenant} - {item.nome} ({item.ocupacao})</b></Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.gridTitle}>
                      <Typography variant="h6"> <b>{item.cidade}</b></Typography>
                      <Typography className={classes.typoLittleFont}>Aeroporto: {item.aeroporto}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.gridTitle}>
                      <Typography variant="h6"> Veículo:</Typography>
                      <Button
                        disabled={isPast || !props.permissao.permiteeditarecogestao}
                        onClick={() => props.changeVeiculo(item)}
                        endIcon={<EditOutlined />}
                      >
                        <b>{item.veiculo.label}</b>
                      </Button>
                    </Grid>
                    <Grid item xs={12} className={classes.gridTitle}>
                      <Typography>Passageiro(s):</Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <List divider>
                        {item.passageiros.map((pax) => (
                          <>
                            <ListItem className={classes.listItemPax}>
                              <Grid container>
                                <Grid item xs={12} className={classes.gridTitle}>
                                  <Typography><b>{pax.nome}</b></Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.gridTitle}>
                                  <Typography variant="h6">Horário:</Typography>
                                  <Typography variant="h6"><b> {pax.horadesc} - {pax.hora}</b> </Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.gridTitle}>
                                  <Typography variant="h6">{definirAssunto(pax.trecho)}</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.gridTitle}>
                                  <Typography ><b>{pax.cc}</b></Typography>
                                  <Typography>{pax.ccNome}</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.gridTitle}>
                                  <Typography >Emissão CO2 (kg):</Typography>
                                  <Typography style={item.isEco ? { color: '#43a047' } : { color: '#c62828' }}> <b>{pax.carbono}</b></Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.gridTitle}>
                                  <Typography>Endereço: {pax.pontoPartida}</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.gridButton}>
                                  <Button disabled={isPast || !props.permissao.permiteeditarecogestao} onClick={() => handleClickRemovePaxFromGroup(item, pax)} className={classes.button} variant="contained" fullWidth >
                                    <Typography>RETIRAR</Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          )}
        </TabPanel>
      </Box>
    </>
  );
};

export default EcoGestaoMobi;
