import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { IconsSource } from '../../images/Icons';
import { Icon } from 'leaflet';
import { Grid } from '@material-ui/core';
import { GifsSource } from '../../images/Gifs';
import { useStyles } from './styles';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function MapViewerHotels(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedHotel, setSelectedHotel] = React.useState(null);

    const handleClickOpen = (hotel) => {
        setSelectedHotel(hotel); // Atualiza o estado com as informações do hotel clicado
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedHotel(null); // Limpa o hotel selecionado quando o popup é fechado
    };

    const customIcon = new Icon({
        iconUrl: IconsSource.hotelIcon,
        iconSize: [38, 38]
    })

    return (
        <div>
            <Typography style={{
                color: "#c62828", fontWeight: "600",
                fontSize: "15px",
                cursor: "pointer"
            }} onClick={handleClickOpen}>Ver mais</Typography>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} >
                {props.mobile != null ? (
                    <DialogContent dividers>
                        <MapContainer center={[props.locsHotels[0]?.location?.latitude, props.locsHotels[0]?.location?.longitude]} zoom={15}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {props.locsHotels.map((item) => (
                                <>
                                    <Marker position={[parseFloat(item.location.latitude), parseFloat(item.location.longitude)]} icon={customIcon}>
                                        <Popup>
                                            <Grid item xs={12} >
                                                <img src={item.thumbImage} width={250}></img>
                                            </Grid>
                                            <Grid item xs={12} container style={{ marginTop: "-30px" }}>
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontWeight: "700", fontSize: "20px" }}>{item.name}</Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: "16px",
                                                            marginTop: "-20px",
                                                            textAlign: "justify",
                                                            maxHeight: "100px",
                                                            overflowY: "auto",
                                                            scrollbarWidth: "thin", // Aplica-se apenas ao Firefox
                                                            scrollbarColor: "#c62828 #ffffff", // Aplica-se apenas ao Firefox, cor da barra e cor do fundo
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <a href={`https://www.google.com/maps/place/${item.name}+${item.location.city}/@${item.location.latitude},${item.location.longitude},17z`} target="_blank" rel="noopener noreferrer">
                                                        Ver no Google Maps
                                                    </a>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button fullWidth className={classes.button} onClick={() => props.handleClickDisponiblity(item.id, item.rooms, item.lowerPrices)}>
                                                    Ver disponiblidade
                                                </Button>
                                            </Grid>
                                        </Popup>
                                    </Marker>
                                </>
                            ))}
                        </MapContainer>

                    </DialogContent>
                ) : (
                    <DialogContent dividers style={{ width: "600px", height: "600px" }}>
                        <MapContainer center={[props.locsHotels[0]?.location?.latitude, props.locsHotels[0]?.location?.longitude]} zoom={15}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {props.locsHotels.map((item) => (
                                <>
                                    <Marker position={[parseFloat(item.location.latitude), parseFloat(item.location.longitude)]} icon={customIcon}>
                                        <Popup>
                                            <Grid item xs={12} >
                                                <img src={item.thumbImage} width={250}></img>
                                            </Grid>
                                            <Grid item xs={12} container style={{ marginTop: "-30px" }}>
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontWeight: "700", fontSize: "20px" }}>{item.name}</Typography>
                                                    <Typography
                                                        style={{
                                                            fontSize: "16px",
                                                            marginTop: "-20px",
                                                            textAlign: "justify",
                                                            maxHeight: "100px",
                                                            overflowY: "auto",
                                                            scrollbarWidth: "thin", // Aplica-se apenas ao Firefox
                                                            scrollbarColor: "#c62828 #ffffff", // Aplica-se apenas ao Firefox, cor da barra e cor do fundo
                                                        }}
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <a href={`https://www.google.com/maps/place/${item.name}+${item.location.city}/@${item.location.latitude},${item.location.longitude},17z`} target="_blank" rel="noopener noreferrer">
                                                        Ver no Google Maps
                                                    </a>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button fullWidth className={classes.button} onClick={() => props.handleClickDisponiblity(item.id, item.rooms, item.lowerPrices)}>
                                                    Ver disponiblidade
                                                </Button>
                                            </Grid>
                                        </Popup>
                                    </Marker>
                                </>
                            ))}
                        </MapContainer>

                    </DialogContent>
                )}


                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
