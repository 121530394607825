import { useState, useCallback, useEffect, useLayoutEffect } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import { alpha } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import OrderTableToolbar from '../../sections/forTables/order-table-toolbar';
import { isAfter } from 'date-fns';
import { Avatar, Chip, Divider, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PaidIcon from '@mui/icons-material/Paid';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import OrderTableFiltersResult from '../../sections/forTables/order-table-filters-result';
import { emptyRows, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, useTable } from '../../../../../components/table';
import Scrollbar from '../../../../../components/scrollbar';
import OrderTableRow from '../../sections/forTables/order-table-row';
import { fDate, fTime } from '../../../../../utils/format-time';
import { formatarParaNomeStatusAdiantamento, formatarParaNomeStatusUser, formatarParaNomeStatusViagem } from '../../../../../enums/enumPrestacaoDeContas';
import centroCustoRepositorio from '../../../../../repositorios/centroCusto';
import pessoaRepositorio from '../../../../../repositorios/pessoa';
import { MoreVertOutlined, Search, Settings } from '@mui/icons-material';
import { usePopover } from '../../../../../components/custom-popover';
// import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from '../../../../../components/table';
// import OrderTableRow from '../../sections/forTables/order-table-row';
// import Scrollbar from '../../../../../components/scrollbar';
// import InvoiceAnalytic from '../../sections/custom-totals';

const TABLE_HEAD = [
    { id: 'pessoa', label: 'Pessoa', width: 300 },
    { id: 'centrodecusto', label: 'Centro De Custo', width: 250 },
    { id: 'permissao', label: 'Permissão', width: 120, align: 'center' },
    // { id: 'totalinicial', label: 'Total inicial', width: 120, align: 'center' },
    // { id: 'status', label: 'Status', width: 110, align: 'center' },
];

const defaultFilters = {
    name: '',
    startDate: null,
    endDate: null,
    statusFilter: 'todos',
    statusArr: [0],
    centrodecusto: 0,
    idpessoa: 0
};

// ----------------------------------------------------------------------

export default function OrderListView({ data, handleClickEditDespesa, handleClickFilter, getViagensReload }) {
    const table = useTable({ defaultOrderBy: 'orderNumber' });
    const [centrodecusto, setcentrodecusto] = useState(0);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [pessoas, setPessoas] = useState([]);
    const [pessoa, setPessoa] = useState([]);
    const [custo, setCusto] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [viagem, setViagem] = useState(null);
    const open = Boolean(anchorEl);

    const popover = usePopover();

    useLayoutEffect(() => {
        (async () => {
            const responsePessoas = await pessoaRepositorio.getSearchField();
            const response = await centroCustoRepositorio.getSearchField();
            // await getData();
            setCentrosCusto(response);
            setPessoas(responsePessoas)
        })()
    }, [])

    const handleChangeCentrodecusto = (event) => {
        setcentrodecusto(event.value);
        setFilters((prevFilters) => ({ ...prevFilters, centrodecusto: event.value }));
    };

    const handleChangePessoa = (event) => {
        setPessoa(event.value);
        setFilters((prevFilters) => ({ ...prevFilters, idpessoa: event.value }));
    };


    const STATUS_OPTIONS = [
        { value: 'todos', label: 'Todos', status: [0] },
        { value: 'usuarios', label: 'Usuário', status: [3] },
        { value: 'auditores', label: 'Auditores', status: [5] },
        { value: 'controladores', label: 'Controladores', status: [4] },
        { value: 'administradores', label: 'Administradores', status: [4] },
        // { value: 'pendente', label: 'Pendente', status: [2, 8, 9, 10] },
        // { value: 'cancelado', label: 'Cancelado', status: [7] },
    ];

    const [dataTable, setDataTable] = useState(data)
    const [filters, setFilters] = useState(defaultFilters);
    const dateError = isAfter(filters.startDate, filters.endDate);
    const dataFiltered = dataTable;

    const denseHeight = table.dense ? 56 : 56 + 20;

    const canReset =
        !!filters.name || filters.statusFilter !== 'todos';

    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const handleFilters = useCallback(
        (name, value) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    const handleResetFilters = useCallback(() => {
        handleStatusOption('todos', [0])
        setFilters(defaultFilters);
    }, []);

    const handleDeleteRow = useCallback(
        (id) => {
            const deleteRow = dataTable.filter((row) => row.id !== id);
            setDataTable(deleteRow);
        },
        [
            table,
            dataTable]
    );

    const handleStatusOption = (value, status) => {
        if (status[0] == 0) {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: 'todos', statusArr: [0] }));
            return
        } else {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: value, statusArr: status }));
            return
        }
    }

    const applyFilters = () => {
        let filteredData = data;

        if (filters.name) {
            filteredData = filteredData.filter(item =>
                item.titulo.toLowerCase().includes(filters.name.toLowerCase())
            );
        }

        if (filters.statusFilter && filters.statusFilter !== 'todos') {
            filteredData = filteredData.filter(item => filters.statusArr.includes(item.status));
        }

        setDataTable(filteredData);
    };

    const handleViewRow = useCallback(
    );

    const getInvoiceLength = (status) => {
        if (status.includes(0)) {  // Considerando '0' como o valor default para todos os status
            return data.length;
        }
        return data.filter((item) => status.includes(item.status)).length;
    }

    const getTotalAmount = (statuses) => {
        if (statuses.includes(0)) {  // Considerando '0' como o valor default para todos os status
            return 0
        }
        return data
            .filter(item => statuses.includes(item.status))
            .reduce((acc, item) => acc + item.total, 0);
    };

    const handleClickSearch = () => {
        handleClickFilter(filters)
    }

    useEffect(() => {
        setDataTable(data)
    }, [data])

    useEffect(() => {
        applyFilters()
    }, [filters])

    return (
        <>
            <Container maxWidth={'lg'}>
                <Card style={{ marginBottom: "40px" }}>
                    {/* <Tabs
                        value={filters.statusFilter}
                        // onChange={handleFilterStatus}
                        sx={{
                            px: 2.5,
                            boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                        }}
                        color="secondary"
                    >
                        {STATUS_OPTIONS.map((tab) => (
                            <Tab
                                key={tab.value}
                                iconPosition="end"
                                value={tab.value}
                                label={tab.label}
                                variant="subtitle1"
                                style={{ fontFamily: "unset " }}
                                onClick={() => handleStatusOption(tab.value, tab.status)}
                                icon={
                                    <Chip
                                        size="small"
                                        variant={
                                            ((tab.status.includes(STATUS_OPTIONS[0].value) || tab.value === filters.status) && 'filled') || 'soft'
                                        }
                                        color={
                                            "primary"
                                        }
                                        style={tab.value === 'cancelado' ? { backgroundColor: "#c62828", borderRadius: "5px" } : { borderRadius: "5px" }}
                                        label={tab.value === 'todos'
                                            ? data.length
                                            : getInvoiceLength(tab.status)}
                                    >
                                    </Chip>
                                }
                            />
                        ))}
                    </Tabs> */}

                    {/* <OrderTableToolbar
                        filters={filters}
                        setFilters={setFilters}
                        data={dataTable}
                        onFilters={handleFilters}
                        handleClickSearch={handleClickSearch}
                        dateError={dateError}
                    /> */}

                    {/* <Stack
                        spacing={2}
                        alignItems={{ xs: 'flex-end', md: 'center' }}
                        direction={{
                            xs: 'column',
                            md: 'row',
                        }}
                        sx={{
                            p: 2,
                            pr: { xs: 2.5, md: 1 },
                        }}
                    >
                        <FormControl style={{ width: "50%" }}>
                            <InputLabel id="demo-simple-select-label">Centro de custo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={centrodecusto}
                                defaultValue={0}
                                label="Centro de custo"

                            >
                                <MenuItem value={0} onClick={() => handleChangeCentrodecusto({ label: "Todos", value: 0 })}>Todos</MenuItem>
                                {centrosCusto?.map((e) => (
                                    <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Pessoa</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pessoa}
                                    defaultValue={0}
                                    label="Pessoa"

                                >
                                    <MenuItem value={0} onClick={() => handleChangePessoa({ label: "Todos", value: 0 })}>Todos</MenuItem>
                                    {pessoas?.map((e) => (
                                        <MenuItem value={e.value} onClick={() => handleChangePessoa({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <IconButton onClick={() => handleClickSearch()}>
                                <Search />
                            </IconButton>
                            <IconButton onClick={popover.onOpen}>
                                <MoreVertOutlined />
                            </IconButton>
                        </Stack>
                    </Stack> */}

                    {canReset && (
                        <OrderTableFiltersResult
                            filters={filters}
                            onFilters={handleFilters}
                            handleStatusOption={handleStatusOption}
                            onResetFilters={handleResetFilters}
                            results={dataFiltered.length}
                            sx={{ p: 2.5, pt: 0 }}
                        />
                    )}

                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                        <Scrollbar>
                            <Table size={table.dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    order={table.order}
                                    orderBy={table.orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={dataTable.length}
                                    numSelected={table.selected.length}
                                    onSort={table.onSort}
                                    onSelectAllRows={(checked) =>
                                        table.onSelectAllRows(
                                            checked,
                                            dataFiltered.map((row) => row.id)
                                        )
                                    }
                                />

                                <TableBody>
                                    {dataTable ? (
                                        <>
                                            {
                                                dataTable?.slice(
                                                    table.page * table.rowsPerPage,
                                                    table.page * table.rowsPerPage + table.rowsPerPage
                                                ).map((row) => (
                                                    <>
                                                        <TableRow hover selected={table.selected.includes(row.id)} width="auto">
                                                            <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Avatar alt={row.pessoa.nome} src={row.pessoa.img} sx={{ mr: 2 }} />

                                                                <ListItemText
                                                                    primary={row.pessoa.nome}
                                                                    secondary={row.pessoa.nome}
                                                                    primaryTypographyProps={{ fontSize: "14px" }}
                                                                    secondaryTypographyProps={{
                                                                        fontSize: "13px",
                                                                        color: 'text.disabled',
                                                                    }}
                                                                />

                                                            </TableCell>

                                                            <TableCell>
                                                                <Typography style={{ fontSize: "14px" }}>
                                                                    {row.pessoa.centrodecusto}
                                                                </Typography>
                                                                {/* <ListItemText
                                                                    primary={row.pessoa.nome}
                                                                    secondary={row.pessoa.nome}
                                                                    primaryTypographyProps={{ fontSize: "12px" }}
                                                                    secondaryTypographyProps={{
                                                                        fontSize: "11px",
                                                                        color: 'text.disabled',
                                                                    }}
                                                                /> */}
                                                            </TableCell>

                                                            <TableCell align="center">
                                                           {formatarParaNomeStatusUser(row.pessoa.permissao)}
                                                            </TableCell>

                                                            <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
                                                                <IconButton
                                                                    id="demo-customized-button"
                                                                    aria-controls={open ? 'demo-customized-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    variant="contained"
                                                                    disableElevation
                                                                // onClick={(e) => handleClick(e, row)}
                                                                >
                                                                    <Settings />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                    // <OrderTableRow
                                                    //     key={row.id}
                                                    //     row={row}
                                                    //     data={data}
                                                    //     selected={table.selected.includes(row.id)}
                                                    //     onSelectRow={() => table.onSelectRow(row.id)}
                                                    //     onDeleteRow={() => handleDeleteRow(row.id)}
                                                    //     onViewRow={() => handleViewRow(row.id)}
                                                    //     getViagensReload={getViagensReload}
                                                    //     handleClickEditDespesa={handleClickEditDespesa}
                                                    // />
                                                ))}
                                            <TableEmptyRows
                                                height={denseHeight}
                                                emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered.length)}
                                            />
                                        </>
                                    ) : (
                                        null
                                    )}

                                    <TableNoData notFound={notFound} />
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>
                </Card>
            </Container>
        </>
    );
}