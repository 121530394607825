import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Paper, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AddRounded, GetApp, SearchOutlined, SendOutlined } from '@material-ui/icons';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStyles } from './styles';

export const DialogDelete = props => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className={classes.root}>
            <Dialog
                open={props.openModalExcluir}
                maxWidth="xs"
                onClose={() => props.setOpenModalExcluir(false)}
                fullWidth
                fullScreen={responsive}
            >
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar
                        style={{
                            background: '#c62828',
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                        <Typography>Confirmação</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText >
                        <Typography>
                            Deseja realmente deletar a viagem?
                            <br />
                            <br />
                            Obs.: não será possível recuperar a viagem nem seus itens atrelados.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.buttonConfirm}
                        onClick={() => props.setOpenModalExcluir(false)}
                        disabled={props.isLoading}
                        startIcon={<CancelIcon />}
                        style={{ background: '#808080', color: "white" }}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        className={classes.buttonConfirm}
                        onClick={() => props.handleExcluirViagem()}
                        startIcon={<DeleteIcon />}
                        style={{ background: '#c62828', color: "white" }}
                    >
                        DELETAR
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};
