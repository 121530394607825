import { blue, green, red } from '@material-ui/core/colors';
import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme => ({
  card: {
    maxWidth: 345,
    height: '100%',
    width: '450px'
  },
  dialogTittle: {
    background: '#616161',
    color: 'white'

  },
  buttonImportar: {
    background: green[700],
    color: 'white',
    '&:hover': {
      background: green[900],
    }
  },
  buttonCancelar: {
    background: red[700],
    color: 'white',
    '&:hover': {
      background: red[900],
    }
  },
  media: {
    height: 140,
    minHeight: 307.91
  },
  cards: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '30px'
  },
  teste: {
    display: 'flex',
    flexDirection: 'row'
  },
  button: {
    background: '#b91119',
    color: 'white',
    '&:hover': {
      background: '#810b11'
    },
  },
  titulo: {
    fontSize: '25px',
    padding: theme.spacing(1, 5)
  },
  expansion: {
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(7)
    }
  },
  search: {
    width: '70%',
    marginLeft: '1%'
  },
  search2: {
    width: '30%',
    marginLeft: '1%',
    marginRight: '1%',
  },
  selecionardadosbutton: {
    background: 'linear-gradient(25deg, #3f51b5, transparent)',
    color: 'white'
  },

  filtros: {
    ['@media (max-width:480px)']: {
      marginTop: '0%',
      marginBottom: '0%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  testevai: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2)

  },
  botoes: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  ['@media (max-width: 900px) and (orientation: landscape)']: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  botoes2: {
    borderStyle: 'solid',
    borderColor: 'black',
    marginLeft: '0.1%'
  },
  botao: {
    position: 'static',
    width: '100%',
    ['@media (max-width:480px)']: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.5)
    },
    ['@media (max-width:900px) and (orientation: landscape)']: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
    },

  },
  udate: {
    width: '70%',
    display: 'flex',
    flexDirection: 'row'
  },
  lista2: {
    padding: theme.spacing(0, 5)
  },
  search3: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
    ['@media(max-width:480px)']: {
      width: '98%',
      marginLeft: '1%',
    },
    ['@media(max-width:900px) and (orientation: landscape)']: {
      width: '50%',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1)
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  botao0: {
    marginLeft: '2%',
    background: '#b91119',
    color: 'white',
    '&:hover': {
      background: '#810b11'
    },
  },
  botao1: {
    marginLeft: '2%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    }
  },
  botao2: {
    marginLeft: '2%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'black'
    }
  },
  botao3: {
    marginLeft: '2%',
    background: '#b91119',
    color: 'white',
    '&:hover': {
      background: '#810b11'
    },
  },
  botao4: {
    marginLeft: '2%',
    marginRight: '1%',
    background: '#b91119',
    color: 'white',
    '&:hover': {
      background: '#810b11'
    },
  },
  lista: {
    padding: theme.spacing(5),
    ['@media(max-width:480px)']: {
      padding: '15px'
    }
  },
  separar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '0%',
    paddingBottom: '0%',
    ['@media(max-width:480px)']: {
      paddingTop: '5%',
      paddingBottom: '10%',
      flexDirection: 'column'
    },
    ['@media(max-width:900px) and (orientation: landscape)']: {
      paddingTop: '5%',
      padding: theme.spacing(0),
      paddingBottom: '5%',
      flexDirection: 'row'
    }
  },
  selecionar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  botaoselecionados: {
    color: 'white',
    background: blue[500],
    '&:hover': { background: blue[700] }
  },
  gridToolBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right'
  },
  gridToolBar2: {
    display: 'flex',
    alignItems: 'center',
  },
  gridToolBar3: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1px'
  },

})));
