import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const economiaEmissaoRepositorio = {

    postAllByTenant: async (dtoDadosRelatorio) => {
        const response = await api.post('/Relatorio/PostAllByTenantEconomiaEmissao', dtoDadosRelatorio, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

}

export default economiaEmissaoRepositorio;
