import { AppBar, Box, Button, CircularProgress, Dialog, Grid, IconButton, List, ListItem, ListItemText, Slide, Snackbar, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { XGrid } from '@material-ui/x-grid';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import ExportExcel from '../../../../components/UExportExcel';
import ULoaderVoo from '../../../../components/uloadervoo';
import compraConsolidadaRepositorio from '../../../../repositorios/relatorio/compraConsolidada';
import DialogFinalizar from '../DialogFinalizar';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const currencyFormatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
});

const brlPrice = {
    type: "number",
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: "font-tabular-nums"
};

export const CheckListExcel = (props) => {
    const cookie = new Cookies();
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [openDialogFinalizar, setOpenDialogFinalizar] = useState(false);
    const [itensVerified, setItensVerified] = useState([]);
    const [itensFromCC, setItensFromCC] = useState({ itens: [], codigo: 0 });
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [colunasExcel, setColunasExcel] = useState([]);
    const [rowsExcel, setRowsExcel] = useState([]);

    const columns = [
        { field: 'dataEmissao', headerName: 'Data Emissão', type: 'date', width: 170, editable: false },
        { field: 'nome', headerName: 'Nome', type: 'text', width: 200, editable: false },
        { field: 'fornecedor', headerName: 'Fornecedor', type: 'text', width: 150, editable: false },
        { field: 'cidade', headerName: 'Cidade', type: 'text', width: 130, editable: false },
        { field: 'estado', headerName: 'Estado', type: 'text', width: 130, editable: false },
        { field: 'inicio', headerName: 'Inicio', type: 'date', width: 150, editable: false },
        { field: 'final', headerName: 'Final', type: 'date', width: 150, editable: false },
        { field: 'total', headerName: 'Total Venda', ...brlPrice, width: 200, editable: false },
        { field: 'diarias', headerName: 'Diárias', width: 140, type: 'number', editable: false },
        { field: 'origem', headerName: 'Origem', width: 150, editable: false },
        { field: 'destino', headerName: 'Destino', width: 150, editable: false },
        { field: 'retorno', headerName: 'Retorno', width: 100, editable: false },
        { field: 'descricao', headerName: 'Descrição', width: 200, editable: false },
        { field: 'centroCusto', headerName: 'Centro Custo', width: 150, editable: false },
        { field: 'tipo', headerName: 'Tipo', width: 150, editable: false },
    ];

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleSelectCentroCusto = (item) => {
        setItensFromCC(item)
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 100);
    }

    const handleClickSendFile = () => {
        setItensVerified([...itensVerified, itensFromCC.codigo])
    }

    const handleClickFinalizar = () => {
        setOpenDialogFinalizar(true)
    }

    const handleClickGerarExcel = async () => {
        let result = await compraConsolidadaRepositorio.GenerateExcel(props.itensFromFile);
        setRowsExcel(result.data)
        document.getElementById("btnExport").click()
    }

    const saveEditedItens = async () => {
        try {
            setIsLoading(true)
            let result = await compraConsolidadaRepositorio.InserirExcelConsolidadoNew(props.itensFromFile)
            let datas = result.data
            setRowsExcel(result.data)
            document.getElementById("btnExport").click()
            alert('Dados importados com sucesso!');
            setSnackVariant('success');
            setSnackMessage('Importado com sucesso.');
            setSnackVisible({ visible: true });
            handleClose()
        } catch (e) {
            alert('Erro ao importar dados!');
            setSnackVariant('error');
            setSnackMessage('Falha do importar.');
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setColunasExcel(['Data Emissão', 'Nome', 'Fornecedor', 'Cidade', 'Estado', 'Início',
            'Final', 'Total Venda', 'Diárias', 'Origem', 'Destino', 'Retorno', 'Descrição', 'Centro Custo', 'Tipo']);
    }, [])

    return (
        <>
            <form >
                <ULoaderVoo isLoading={isLoading} />
                <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
                    <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
                    <Box hidden>
                        <ExportExcel name="Relatório Consolidado" columns={colunasExcel} data={rowsExcel} element={
                            <>
                                <Button id={'btnExport'} className={classes.button}>
                                    Exportar
                                </Button>
                            </>
                        } />
                    </Box>
                    <DialogFinalizar
                        {...{ open: openDialogFinalizar, setOpen: setOpenDialogFinalizar, saveEditedItens }}
                    />
                    <AppBar className={classes.appbar}>
                        <Toolbar style={{ minHeight: '0px' }}>
                            <IconButton edge={'end'} color='inherit' onClick={handleClose}>
                                <Close />
                            </IconButton>
                            {responsive ? (
                                null
                            ) : (
                                <>
                                    <Typography variant='h6' className={classes.title}>
                                        {cookie.get('org')}
                                    </Typography>
                                </>
                            )}
                        </Toolbar>
                    </AppBar>
                    <Grid container>
                        {responsive ? (
                            <>
                                <Box style={{ height: 'calc(100vh - 48px)', width: '100vw', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography>Somente é permito o acesso via Desktop.</Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Grid container item>
                                        <Grid item xs={12} style={{ paddingLeft: '10px' }}>
                                            <Typography variant='h6'> ABC010203 - Filial de São Paulo</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List style={{ display: 'flex', flexDirection: 'row', overflowY: 'hidden', overflowX: 'scroll' }}>
                                                {props.itensFromFile.map((item) => (
                                                    <>
                                                        <ListItem style={{ whiteSpace: 'nowrap' }}>
                                                            <ListItemText style={{ marginTop: '0px', marginBottom: '0px' }} className={classes.listItemTextcustom} primary={
                                                                (
                                                                    <>
                                                                        <Button
                                                                            style={
                                                                                (itensVerified.find(r => r === item.codigo) !== null) ?
                                                                                    { background: '#ffd8d8 ', display: 'grid' } :
                                                                                    { display: 'grid' }}
                                                                            onClick={() => handleSelectCentroCusto(item)}>
                                                                            <Typography variant='subtitle2'>{item.codigo}</Typography>
                                                                            <Typography variant='body2'>{item.nome}</Typography>
                                                                        </Button>
                                                                    </>
                                                                )
                                                            } />
                                                        </ListItem>
                                                    </>
                                                ))}
                                            </List>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: '10px' }}>
                                            {isLoading ? (
                                                <Box width={'100%'} height={'62vh'} display={'grid'} alignContent={'center'} justifyContent={'center'}>
                                                    <CircularProgress style={{ color: '#c62828' }} />
                                                </Box>
                                            ) : (
                                                <Box width={'100%'} height={'61vh'} display={'grid'} >
                                                    <XGrid
                                                        disableColumnFilter={true}
                                                        disableColumnMenu={true}
                                                        checkboxSelection
                                                        rows={itensFromCC.itens}
                                                        columns={columns}
                                                    />
                                                </Box>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>

                                        </Grid>
                                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Button className={classes.buttoncustom} onClick={handleClickGerarExcel}> Gerar Excel</Button>
                                            <Button className={classes.buttoncustom} onClick={handleClickFinalizar}> Finalizar</Button>
                                            <Button className={classes.buttoncustom} onClick={handleClickSendFile}> Destacar</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Dialog>
            </form >
        </>
    )
}

export default CheckListExcel;
