import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const viagemRepositorio = {

    getAll: async ({ dataInicio, dataFim, idCentroCusto, idViajante }) => {
        let filtros = '';
        filtros += `?dataInicio=${new Date(dataInicio).toISOString()}&dataFim=${new Date(dataFim).toISOString()}`;
        if (idCentroCusto) {
            filtros += `&idCentroCusto=${idCentroCusto}`
        }
        if (idViajante) {
            filtros += `&idViajante=${idViajante}`;
        }
        const response = await api.get(`/viagem${filtros}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    getAllEmptyexpanses: async (idViajante) => {
        const response = await api.get('/viagem/Emptyexpanses/'+idViajante, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    postExportDataAllViagens: async (dto) => {
        const response = await api.post(`/viagem/postExportDataAllViagens`, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    permissaoDespesaByViagem: async () => {
        const response = await api.get(`/usuario/permissaodespesabyviagem`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    get: async (idviagem) => {
        const response = await api.get(`/viagem/${idviagem}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    put: async (viagem) => {
        const response = await api.put('/viagem/', viagem, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    editViagemDespesa: async (dto) => {
        const response = await api.put('/viagem/EditViagemDespesa', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    addViagem: async (viagem) => {
        const response = await api.post('/viagem', viagem, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },
    AttDespesasForViagem: async (despesas, idviagem) => {
        let dto = {
            despesas: despesas,
            idviagem: idviagem
        }
        const response = await api.post('/viagem/Attdespesas', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },
    addViagemDespesa: async (despesa) => {
        const response = await api.post('/viagem/AddDespesa', despesa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    removeViagemAutoDespesa: async (iddespesa) => {
        const response = await api.delete(`/viagem/RemoveAutoDespesa/${iddespesa}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    removeViagemDespesa: async (iddespesa) => {
        const response = await api.delete(`/viagem/RemoveDespesa/${iddespesa}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    removeDespesaRetroceder: async (idviagemdespesa) => {
        const response = await api.put(`/viagem/RemoveDespesaRetroceder/${idviagemdespesa}`, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    confirmarAuditoria: async (viagemDespesa) => {
        const response = await api.put(`/viagem/ConfirmarAuditoria`, viagemDespesa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    avancarStatusViagem: async (idviagem) => {
        const response = await api.put(`/viagem/AvancarStatusViagem/${idviagem}`, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    estornarViagem: async (idviagem) => {
        const response = await api.put(`/viagem/EstornarViagem/${idviagem}`, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    addJustificativaCancelamento: async (viagemDespesa) => {
        const response = await api.put('/viagem/AddJustificativaCancelamento/', viagemDespesa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    verificarStatusViagem: async (idviagem) => {
        const response = await api.get(`/viagem/VerificarStatusViagem/${idviagem}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    verificarRetrocedidoDespesa: async (iddespesa) => {
        const response = await api.get(`/viagem/VerificarRetrocedidoDespesa/${iddespesa}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getJustificativaCancelamento: async (idviagemdespesa) => {
        const response = await api.get(`/viagem/GetJustificativaCancelamento/${idviagemdespesa}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    salvarValorAuditado: async (viagemDespesa) => {
        const response = await api.post('viagem/SalvarValorAuditado', viagemDespesa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    salvarDescricaoControladoria: async (viagemDespesa) => {
        const response = await api.post('viagem/SalvarDescricaoControladoria', viagemDespesa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    deleteViagem: async (id) => {
        const response = await api.delete(`viagem/Deletarviagem/${id}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    }
}

export default viagemRepositorio;
