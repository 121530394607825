import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(() => ({
    root: {

    },
    button: {
        color: '#fff'
    },
    buttonConfirm: {
        background: '#c62828',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#b91119',
        }
    },
    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },
    inline: {
        display: 'inline',
    },
    inner: {
        textAlign: 'justify',
        marginBottom: '-10px',
        marginTop: '10px',
        fontSize: '11pt',
    },
    iconButton: {
        color: "white"
    }
}));
