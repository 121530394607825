import cookie from "universal-cookie";
import api from "../../Api";
import {
  adicionarNovaDespesa_Mock,
  adicionarNovaViagem_Mock,
  adicionarNovoHodometro_Mock,
  aprovarDespesas_Mock,
  avancarViagem_Mock,
  editarDespesa_Mock,
  editarHodometro_Mock,
  excluirDespesa_Mock,
  getAllDespesas_Mock,
  getAllViagensAuditor_Mock,
  getAllViagensUsuario_Mock,
  getViagem_Mock,
  iniciarViagem_Mock,
} from "../../mock/prestacaodecontas";

const cookies = new cookie();

const prestacaoDeContasRepositorio = {
  getAllViagensUser: async (model) => {
    const response = await api.post(
      "/PrestacaoContasViagem/GetAllWithFilter",
      model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  getViagem: async (idviagem) => {
    const response = await api.get(
      "/PrestacaoContasViagem/GetViagemById/" + idviagem,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  putNovaViagem: async (novaviagem) => {
    const response = await api.put(
      "/PrestacaoContasViagem/PutCreateNewViagem",
      novaviagem,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  getDespesas: async (idviagem) => {
    const response = await api.get(
      `/PrestacaoContasDespesa/GetAllByViagem/${idviagem}`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
        },
      }
    );
    return response.data;
  },

  postInfoReceita: async (url) => {
    const response = await api.post(
      `/PrestacaoContasDespesa/PostExtrairInfoReceita`,
      JSON.stringify(url), // Certifique-se de converter a string para JSON
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("token")}`,
        },
      }
    );
    return response.data.value;
  },

  postNovaDespesa: async (despesa) => {
    const response = await api.post(
      "/PrestacaoContasDespesa/PostNovaDespesa/",
      despesa,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
        },
      }
    );
    return response.data;
  },

  putEditDespesa: async (despesa) => {
    const response = await api.put(
      "/PrestacaoContasDespesa/PutUpdateDespesa/" + despesa.idviagem,
      despesa,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
        },
      }
    );
    return response.data;
  },

  deleteViagem: async (idviagem) => {
    const response = await api.delete(
      "/PrestacaoContasViagem/DeleteViagem/" + idviagem,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  deleteDespesa: async (iddespesa) => {
    const response = await api.delete(
      "/PrestacaoContasDespesa/DeleteDespesa/" + iddespesa,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  postAvancarViagem: async (idviagem) => {
    const response = await api.put(
      "/PrestacaoContasViagem/AvancarViagem/" + idviagem,
      {},
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  putEditViagem: async (idviagem, viagem) => {
    const response = await api.put(
      "/PrestacaoContasViagem/AtualizarViagem/" + idviagem,
      viagem,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  // Auditor
  putAuditarValor: async (idviagem, iddespesa, valor) => {
    const response = await api.put(
      `/PrestacaoContasDespesa/PutAuditarDespesa/${idviagem}/${iddespesa}`,
      JSON.stringify(valor),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  putEnviarControladoria: async (idviagem, iddespesa, mensagem) => {
    const response = await api.put(
      `/PrestacaoContasDespesa/PutEnviarControladoria/${idviagem}/${iddespesa}`,
      JSON.stringify(mensagem),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  putEnviarParaPessoa: async (idviagem, model) => {
    const response = await api.put(
      `/PrestacaoContasDespesa/PutEnviarParaPax/${idviagem}`,
      model,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  // putCancelarDespesas: async () => {
  //   const response = await api.put(
  //     `/PrestacaoContasDespesa/PutEnviarControladoria/${idviagem}/${iddespesa}`,
  //     JSON.stringify(mensagem),
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + cookies.get("token"),
  //         sessionId: cookies.get("sessionId"),
  //       },
  //     }
  //   );
  //   return response.data;
  // },

  searchTableFilter: async (filter) => {
    const response = getAllViagensAuditor_Mock();

    return response;

    // const response = await api.get('/prestacaodecontas/SearchFiltersViagens', filter, {
    //     headers: {
    //         Authorization: 'Bearer ' + cookies.get('token'),
    //         sessionId: cookies.get('sessionId')
    //     }
    // });
    // return response;
  },

  postAprovarDespesas: async (idviagem, despesas) => {
    const response = await api.put(
      "/PrestacaoContasDespesa/AprovarDespesas/" + idviagem,
      despesas,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );
    return response.data;
  },

  getAllTiposDeDespesas: async () => {
    const response = await api.get("/DespesasTipos/GetListPorEmpresa", {
      headers: {
        Authorization: "Bearer " + cookies.get("token"),
        sessionId: cookies.get("sessionId"),
      },
    });
    return response.data;
  },
};

export default prestacaoDeContasRepositorio;
