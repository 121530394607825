import api from '../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const centroCustoRepositorio = {

    get: async idCentroCusto => {
        const response = await api.get('/centrocusto/' + idCentroCusto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response.data;
    },

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/centrocusto/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });

        const listaComAtivos = response.data.filter(item => item.ativo);

        listaComAtivos.forEach(item => {
            lista.push({
                label: item.nome + ' - ' + item.codigo,
                value: item.idcentrocusto
            })
        })
        return lista;
    },

    getAllPorTenantList: async () => {
        const response = await api.get('/centrocusto/GetAllPorTenantList', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });

        return response;
    },

    getSearchFieldList: async () => {
        let lista = [];
        const response = await api.get('/centrocusto/GetAllPorTenantList', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });

        const listaComAtivos = response.data.filter(item => item.ativo);

        listaComAtivos.forEach(item => {
            lista.push({
                label: item.nome + ' - ' + item.codigo,
                value: item.idcentrocusto
            })
        })
        return lista;
    },

    saveUpdateCentroCusto: async (centroCusto) => {
        let response = await api.put(`/centrocusto/${centroCusto.idcentrocusto}`, centroCusto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });

        return response;
    },

    createCentroCusto: async (centroCusto) => {
        let response = await api.post(`/centrocusto`, centroCusto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response;
    },


}

export default centroCustoRepositorio;
