import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useLayoutEffect } from "react";
import CustomizedSnackbars from "../../../components/snackbar";
import ULoaderVoo from "../../../components/uloadervoo";
import cardsRepositorio from "../../../repositorios/cartao";
import Dashboard from "../../dashboard";
import { CartaoDesktop } from "./cartaoDesktop";
import { CartaoDialogEdit } from "./cartaoDialogEdit";
import { CartaoDialogRegister } from "./cartaoDialogRegister";
import { CartaoMobile } from "./cartaoMobile";
import { DialogConfirm } from "./DialogConfirm";

const Cartoes = (props) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [openDialogEdit, setOpenDialogEdit] = useState(false);
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState("success");
    const [snackMessage, setSnackMessage] = useState("");
    const [listCard, setListCard] = useState([])
    const [selectedItem, setSelectedItem] = useState({ idcartao: null, numero: null, apelido: null });

    const getListCard = async () => {
        try {
            setIsLoading(true)
            let response = await cardsRepositorio.getListCards();
            setListCard(response.data)
        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    }

    const deleteCard = async (idcard) => {
        try {
            setIsLoading(true)
            await cardsRepositorio.deleteCard(idcard);
            setOpenDialogConfirm(false);
            getListCard();
        } catch (e) {
            setSnackVariant("error");
            setSnackMessage("Falha ao remover cartão.");
            setSnackState({ visible: true });
            setIsLoading(false)
        }
    }

    const getColor = (bandeira) => {
        switch (bandeira) {
            case 'AMEX':
                return {
                    background: 'linear-gradient(50deg, rgba(48,140,103,1) 0%, rgba(163,242,207,1) 100%)',
                    color: ' #0f0f0f',
                    height: '75px',
                    width: '75px',
                }
            case 'DINERS':
                return {
                    background: 'linear-gradient(50deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%)',
                    color: ' #0f0f0f',
                    height: '75px',
                    width: '100px',
                }
            case 'ELO':
                return {
                    background: 'linear-gradient(50deg, rgba(33,28,24,1) 0%, rgba(170,167,162,1) 100%)',
                    color: ' #fafafa',
                    height: '75px',
                    width: '75px',
                }
            case 'HIPERCARD':
                return {
                    background: 'linear-gradient(50deg, rgba(139,24,27,1) 0%, rgba(222,31,39,1) 100%)',
                    color: ' #fafafa',
                    height: '75px',
                    width: '75px',
                }
            case 'MASTERCARD':
                return {
                    background: 'linear-gradient(50deg, rgba(251,251,251,1) 0%, rgba(232,233,229,1) 100%)',
                    color: ' #0f0f0f',
                    height: '75px',
                    width: '75px',
                }
            case 'VISA':
                return {
                    background: 'linear-gradient(50deg, rgba(15,80,158,1) 0%, rgba(19,153,205,1) 100%)',
                    color: ' #fafafa',
                    height: '75px',
                    width: '75px',
                }
            default:
                return {
                    background: '#808080',
                    color: ' #0f0f0f',
                    height: '60px',
                    width: '60px',
                }
        }
    }

    const handleClickDeleteOpenDialogConfirm = (idcartao, numeroCartao) => {
        setSelectedItem({ idcartao: idcartao, numero: numeroCartao })
        setOpenDialogConfirm(true);
    }

    const handleEditCardConfirm = (idcartao, numeroCartao, apelido) => {
        setSelectedItem({ idcartao: idcartao, numero: numeroCartao, apelido: apelido })
        setOpenDialogEdit(true)
    }

    useLayoutEffect(() => {
        getListCard();
    }, [])

    return (
        <>
            <ULoaderVoo isLoading={isLoading} />
            <CustomizedSnackbars
                {...{
                    variant: snackVariant,
                    message: snackMessage,
                    visible: snackState.visible,
                    setSnackbar: setSnackState,
                }}
            />
            {openDialog ? (
                <CartaoDialogRegister {...{
                    openDialog,
                    setOpenDialog,
                    setSnackMessage,
                    setSnackState,
                    setSnackVariant,
                    setIsLoading,
                    getListCard
                }} />
            ) : null}
            {openDialogEdit ? (
                <CartaoDialogEdit {...{
                    openDialogEdit,
                    setOpenDialogEdit,
                    deleteCard,
                    selectedItem,
                    setIsLoading,
                    setSnackMessage,
                    setSnackState,
                    setSnackVariant,
                    isLoading,
                    getListCard
                }}
                />
            ) :  null}
            {openDialogConfirm ? (
                <DialogConfirm {...{
                    openDialogConfirm,
                    setOpenDialogConfirm,
                    deleteCard,
                    selectedItem,
                    setIsLoading,
                    setSnackMessage,
                    setSnackState,
                    setSnackVariant,
                    isLoading,

                }} />
            ) : null}
            {responsive ? (
                <CartaoMobile {...{ isLoading, setIsLoading, setOpenDialog, listCard, handleClickDeleteOpenDialogConfirm, getColor, handleEditCardConfirm }} />
            ) : (
                
                <CartaoDesktop {...{ isLoading, setIsLoading, setOpenDialog, listCard, handleClickDeleteOpenDialogConfirm, getColor, handleEditCardConfirm }} />
            )}
        </>
    )
}

export const CartoesPage = (props) => {
    return (
        <div>
            <Dashboard {...props} component={Cartoes} />
        </div>
    );
};
export default CartoesPage;
