import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { theme } from "highcharts";
import React, { useEffect, useState } from "react";
import { IconsSource } from "../../images/Icons";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props) => {
      if (props.progress >= 80) {
        return "#00FF99";
      } else if (props.progress >= 50) {
        return "#FFD900";
      } else {
        return "#C62828";
      }
    },
  },
}))(LinearProgress);

export default function CustomizedProgressBars(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [progress, setProgress] = useState(0); // Start with 0 progress
  const [reducoes, setReducoes] = useState(props.reduce.item1);
  const [tentativas, setTentativas] = useState(props.reduce.item2);

  useEffect(() => {
    let totalProgress = 0;
    let totalReducoes = reducoes;
    let totalTentativas = tentativas;

    let oneUnity = tentativas - reducoes / 100;

    if (progress < 100) {
      const timer = setInterval(() => {
        if (reducoes > 0) {
          setReducoes((prevReducoes) => prevReducoes - 1);
          setProgress((prevProgress) =>
            prevProgress > 0 ? prevProgress + 1 : prevProgress
          );
        } else {
          clearInterval(timer);
        }
      }, 1764.7);

      return () => {
        clearInterval(timer);
      };
    }
  }, [progress, reducoes]);

  const progressPercentage = (reducoes / tentativas) * 100; // Calculate the percentage based on progress and total

  return (
    <div>
      <Grid container>
        {reducoes != 0 ? (
          <Grid item xs={12} style={{ textAlign: "center", padding: "10px" }}>
            {!fullScreen ? (
              <>
                <Grid item xs={12}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={progressPercentage}
                    progress={progressPercentage}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", justifyContent: "center" }}
                >
                  <Typography style={{ fontWeight: "600", fontSize: "13pt" }}>
                    {reducoes != 0
                      ? reducoes + " Tentativas de redução"
                      : "Número de tentativas de redução excedidos"}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "center"}}>
                  <ListItem
                    style={{
                      marginLeft: "-20px",
                      marginRight: "-30px",
                      width: "auto",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={IconsSource.zulminhaicon}
                        style={{
                          marginBottom: "5px",
                          width: theme.spacing(9),
                          height: theme.spacing(9),
                        }}
                      ></Avatar>
                    </ListItemAvatar>
                    {/* Estou realizando tentativas de redução. */}
                    <ListItemText
                      style={{ marginLeft: "15px" }}
                      secondary={
                        <Typography
                          style={{
                            fontWeight: "700",
                            fontSize: "22px",
                            color:
                              progressPercentage >= 80
                                ? "#00FF99"
                                : progressPercentage >= 50
                                ? "#FFD900"
                                : "#C62828",
                          }}
                        >
                          Tentativas de redução IA
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={progressPercentage}
                    style={{
                      height: "8rem",
                      width: "8rem",
                      color:
                        progressPercentage >= 80
                          ? "#00FF99"
                          : progressPercentage >= 50
                          ? "#FFD900"
                          : "#C62828",
                    }}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      style={{
                        color:
                          progressPercentage >= 80
                            ? "#00FF99"
                            : progressPercentage >= 50
                            ? "#FFD900"
                            : "#C62828",
                        fontSize: "16pt",
                        fontWeight: "bold",
                      }}
                    >
                      {reducoes}
                    </Typography>

                    {/* <Typography
                      variant="caption"
                      component="div"
                      style={{
                        color:
                          progressPercentage >= 80
                            ? "#00FF99"
                            : progressPercentage >= 50
                            ? "#FFD900"
                            : "#C62828",
                        fontSize: "12pt",
                      }}
                    >
                      
                    </Typography> */}
                  </Box>
                </Box>
              </>
            )}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}
