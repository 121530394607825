import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Container, Typography, Button, FormControl, Grid, TextField, Skeleton, MenuItem, InputLabel, Select, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import QrReader from 'react-qr-reader';
import { useCustomSnackbar } from '../../../../../../../components/snackbar2/NewSnackBar';
import ULoaderVoo from '../../../../../../../components/uloadervoo';
import prestacaoDeContasRepositorio from '../../../../../../../repositorios/prestacaodecontas';
import { UploadAvatar } from '../../../../../../../upload';
import { UtaliiDatePickerNormal } from '../../../../../Desktop/sections/custom-date-picker-normal';
import { convertImageToBase64 } from '../../../../../../../hooks/convertImageToBase64';
import { fData } from '../../../../../../../upload/utils/utils';
import { VerifiedOutlined } from '@mui/icons-material';
import './QrCodeScanner.css';
import { NewReleasesOutlined } from '@material-ui/icons';

export const QrCodeLearnComponent = ({ viagem, handleCreate, setType }) => {
    const [qrscan, setQrscan] = useState('No result');
    const [cameraPermission, setCameraPermission] = useState(null);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [titulo, setTitulo] = useState("");
    const [tipodedespesa, setTipodedespesa] = useState(null);
    const [tipoGasto, setTipoGasto] = useState(null)
    const [descricao, setDescricao] = useState("");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [totalgasto, setTotalgasto] = useState(0);
    const [categorias, setCategorias] = useState([]);
    const [receita, setReceita] = useState(null);

    const isMountedRef = useRef(true);
    const { enqueueSnackbar } = useCustomSnackbar();

    const contentRef = useRef(null);

    const scrollToTop = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const getAlltipos = async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas();
            setCategorias(response);
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCreateDespesa = () => {

        const despesadata = {
            idviagem: viagem.id,
            datadecompra: datadecompra,
            descricao: descricao,
            hodometro: null,
            is_extra: false,
            aprovado: false,
            controladoria: false,
            total: totalgasto,
            mensagem: "",
            comprovante: file,
            QrCode: receita
        }

        if (tipodedespesa != null && tipoGasto != null) {
            despesadata.produtos = {
                iddespesa_tipo: tipodedespesa.value,
                iddespesa_subtipo: tipoGasto.value
            }
            // despesadata.valor_limite = tipoGasto.limite
        }

        handleCreate(despesadata)

        setType(0);
    };

    const handleChangeTipoDeDespesa = (value) => {
        setTipoGasto(null);
        setTipodedespesa(value);
    };

    const handleChangeGasto = (value) => {
        setTipoGasto(value);
    };

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            convertImageToBase64(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(base64String);
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    const getInfoReceita = async (url) => {
        try {
            const response = await prestacaoDeContasRepositorio.postInfoReceita(url);
            enqueueSnackbar('Tudo certo!!.', { variant: 'success' });
            setReceita(response);
            setTotalgasto(response.totalComDesconto);
            scrollToTop()
        } catch {
            enqueueSnackbar('Ocorreu um erro ao ler QR code.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAlltipos();
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    const handleScan = (data) => {
        if (data) {
            if (isMountedRef.current) {
                setLoading(true);
                setQrscan(data);
                getInfoReceita(data);
            }
        }
    };

    const handleCanTeste = (data) => {
        setQrscan(data);
        getInfoReceita(data);
    }

    const handleError = (err) => {
        console.error("Erro ao escanear o QR Code:", err);
    };

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            {receita != null ? (
                <Container sx={{
                    height: "100%",
                    overflowY: 'auto',
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }} ref={contentRef}>
                    <Grid container item xs={12} style={{ marginTop: "10px", alignContent: "space-between", alignContent: "center", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }} spacing={1}>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                                Produtos encontrados:
                            </Typography>
                        </Box>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', }}
                            aria-label="contacts"
                        >
                            {receita.itens.map((item) => (
                                <ListItem
                                    key={item.id}
                                    style={{
                                        boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                        borderRadius: "16px",
                                        marginBottom: "10px"
                                    }}
                                >
                                    <ListItemIcon>
                                        {item.permitido ? (
                                            <VerifiedOutlined color="primary" />
                                        ) : (
                                            <NewReleasesOutlined color="error" />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2">
                                                {item.name}
                                            </Typography>
                                        }
                                        secondary={
                                            new Intl.NumberFormat('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            }).format(item.vlTotal)
                                        }
                                    />
                                </ListItem>
                            ))}
                            <Divider primary="Full width variant below" />
                        </List>
                    </Grid>
                    <Grid container item xs={12} style={{ justifyContent: "center", textAlign: "left", alignContent: "space-between", marginTop: "10px" }} spacing={1}>
                        <UploadAvatar
                            file={file}
                            accept="image/jpeg,image/png,image/gif"
                            style={{ width: "100px" }}
                            onDrop={handleDropAvatar}
                        // validator={(fileData) => {
                        //     if (fileData.size > (6 * 1024 * 1024)) {
                        //         return {
                        //             code: 'file-too-large',
                        //             message: `Esse arquivo é muito grande!! ${fData((6 * 1024 * 1024))}`,
                        //         };
                        //     }
                        //     return null;
                        // }}
                        />
                    </Grid>
                    <Grid container item xs={12} style={{ width: 320 }}>
                        <Grid container item xs={12} style={{ marginTop: "10px", alignContent: "space-between", alignContent: "center" }} spacing={1}>
                            <Grid container item xs={12} spacing={1} style={{ paddingTop: "10px" }}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="filled-multiline-flexible"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        onChange={(e) => setDescricao(e.target.value)}
                                        multiline
                                        maxRows={4}
                                        label="Justificativa/Descrição"
                                        placeholder="Viagem para..."
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {categorias.length == 0 ? (
                                    <Skeleton height={20} />
                                ) : (
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tipodedespesa != null ? tipodedespesa.value : null}
                                            defaultValue={0}
                                            label="Categoria"
                                        >
                                            {categorias?.map((e) => (
                                                <MenuItem value={e.iddespesa_tipo} onClick={() => handleChangeTipoDeDespesa({ label: e.name, value: e.iddespesa_tipo, sub: e.produtos })}>{e.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Grid>
                            {tipodedespesa != null && tipodedespesa?.sub?.length != 0 && tipodedespesa?.label != "Outros" ? (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gasto com</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tipoGasto != null ? tipoGasto.value : 1}
                                            label="Categoria"
                                        >
                                            {tipodedespesa?.sub?.map((e) => (
                                                <MenuItem value={e.iddespesa_subtipo} onClick={() => handleChangeGasto({ label: e.name, value: e.iddespesa_subtipo })}>{e.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : null}
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="outlined-number"
                                    label="Total gasto"
                                    onChange={(e) => setTotalgasto(e.target.value)}
                                    defaultValue={(receita.totalComDesconto)}
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={6} style={{ justifyContent: "center", textAlign: "center" }} width={"auto"}>
                                <UtaliiDatePickerNormal
                                    value={datadecompra}
                                    onChange={setDatadecompra}
                                    label="Data de Compra"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "right", marginBottom: "20px" }}>
                            <Button size="large" style={{ marginBottom: "20px" }} fullWidth variant="contained" color="primary" autoFocus onClick={() => handleCreateDespesa()}>
                                Criar despesa
                            </Button>
                            {/* <Button style={{ marginBottom: "20px" }} fullWidth variant="contained" color="primary" autoFocus onClick={() => scrollToTop()}>
                            Scrool
                        </Button> */}
                        </Grid>
                    </Grid>
                </Container>
            ) : (
                <Container sx={{ padding: 0, height: "100%" }}>
                    <Box display="flex" justifyContent="center" alignItems="center" position="relative" height="100%" width="100%">
                        <div>
                            <div style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '60%',
                                height: '30%',
                                transform: 'translate(-50%, -50%)',
                                boxSizing: 'border-box',
                                pointerEvents: 'none',
                                zIndex: 1
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '40px',
                                    height: '5px',
                                    top: '-5px',
                                    left: '0px'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '40px',
                                    height: '5px',
                                    top: '-5px',
                                    right: '0px'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '40px',
                                    height: '5px',
                                    bottom: '-5px',
                                    left: '0px'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '40px',
                                    height: '5px',
                                    bottom: '-5px',
                                    right: '0px'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '5px',
                                    height: '45px',
                                    top: '-5px',
                                    left: '-5px'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '5px',
                                    height: '45px',
                                    bottom: '-5px',
                                    left: '-5px'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '5px',
                                    height: '45px',
                                    top: '-5px',
                                    right: '-5px'
                                }}></div>
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: 'rgb(255, 255, 255)',
                                    width: '5px',
                                    height: '45px',
                                    bottom: '-5px',
                                    right: '-5px'
                                }}></div>
                            </div>
                        </div>
                        <div style={{ position: 'relative', width: '100%', maxWidth: '500px', margin: 'auto', height: "100%" }}>
                            <QrReader
                                constraints={{
                                    facingMode: 'environment'
                                }}
                                delay={500}
                                onError={handleError}
                                onScan={handleScan}
                                style={{ width: '100%', height: "100%", display: "flex" }}
                                className="qr-reader-container"
                            />

                        </div>
                        {qrscan === 'No result' && (
                            <>
                                <Box
                                    position="absolute"
                                    top="35%"
                                    left="50%"
                                    style={{
                                        width: 250,
                                        height: 250,
                                        transform: 'translate(-50%, -50%)',
                                        boxSizing: 'border-box',
                                        zIndex: 1,
                                        textAlign: "center"
                                    }}
                                >
                                    <Typography variant="body1" style={{ color: "white" }}>
                                        Posicione a câmera sobre o QR code e aguarde.
                                    </Typography>
                                </Box>
                                {
                                    /* <Box
                                        position="absolute"
                                        top="35%"
                                        left="50%"
                                        style={{
                                            width: 250,
                                            height: 250,
                                            transform: 'translate(-50%, -50%)',
                                            boxSizing: 'border-box',
                                            zIndex: 1,
                                            textAlign: "center"
                                        }}
                                    >
                                        <Button onClick={() => handleCanTeste("https://sat.sef.sc.gov.br/nfce/consulta?p=42240700508070000155650060000288001870088220|2|1|1|98fd8e1bcf5aab12f5697040bf1b52af49bc1df0")}>QR CODE PRONTO</Button>
                                    </Box> */
                                }
                            </>
                        )}
                    </Box>
                </Container>
            )}
        </>
    );
};
