import React, { useState } from 'react';
import { useStyles } from './style';  // Substitua pelo caminho real do seu arquivo de estilos
import { Button, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import "../../fonts/fonts.css"

const ImageSlider = ({ images }) => {
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();  // Use os estilos definidos

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <>
      {responsive ? (

        <div className={classes.imageSlider}>
          <div className={classes.indexDisplay}>
            <Typography style={{ fontFamily: "HindBold", fontSize: "12px" }}>
              {currentIndex + 1}/{images.length}
            </Typography>
          </div>
          <div className={classes.slideContainer} style={{ transform: `translateX(${-currentIndex * 100}%)` }}>

            {images.map((image, index) => (
              <div style={{ backgroundColor: "black", minWidth: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                <img
                  key={index}
                  className={classes.slide}
                  style={{ height: "250px", width: 'auto', maxWidth: '100%' }}
                  src={image.thumb}
                  alt={`Slide ${index + 1}`}
                />
              </div>
            ))}
          </div>
          {/* <Button className={`${classes.sliderButton} ${classes.next}`} onClick={nextSlide}>
      </Button> */}
          <IconButton className={`${classes.sliderButton} ${classes.next}`} onClick={nextSlide} aria-label="upload picture" component="span">
            <ArrowForwardIosIcon style={{ color: "#c62828" }} />
          </IconButton>
          <IconButton className={`${classes.sliderButton} ${classes.prev}`} onClick={prevSlide}>
            <ArrowBackIosIcon style={{ marginLeft: "4px", color: "#c62828" }} />
          </IconButton>
        </div>
      ) : (
        <div className={classes.imageSlider}>
          <div className={classes.indexDisplay} >
            <Typography style={{ fontFamily: "HindBold", fontSize: "10px" }}>
              {currentIndex + 1}/{images.length}
            </Typography>
          </div>
          <div className={classes.slideContainer} style={{ transform: `translateX(${-currentIndex * 100}%)` }}>

            {images.map((image, index) => (
              <div style={{ backgroundColor: "black", minWidth: "100%", justifyContent: "center", display: "flex", alignItems: "center", borderRadius: "10px" }}>
                <img
                  key={index}
                  className={classes.slide}
                  style={{ height: "450px" }}
                  src={image.thumb}
                  alt={`Slide ${index + 1}`}
                />
              </div>

            ))}
          </div>
          {/* <Button className={`${classes.sliderButton} ${classes.next}`} onClick={nextSlide}>
      </Button> */}
          <IconButton className={`${classes.sliderButton} ${classes.next}`} onClick={nextSlide} aria-label="upload picture" component="span">
            <ArrowForwardIosIcon style={{ color: "#c62828" }} />
          </IconButton>
          <IconButton className={`${classes.sliderButton} ${classes.prev}`} onClick={prevSlide}>
            <ArrowBackIosIcon style={{ marginLeft: "4px", color: "#c62828" }} />
          </IconButton>
        </div >
      )}
    </>
  );
};

export default ImageSlider;
