import { Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, InputBase, List, ListItem, ListItemIcon, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, EditRounded, Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import cookie from 'universal-cookie';
import api from '../../../Api';
import LoadingButton from '../../../components/loadingButton';
import Snackbar from '../../../components/snackbar';
import UFloatButton from '../../../components/ufloatbutton';
import Enums from '../../../enums';
import permissaoRepositorio from '../../../repositorios/permissao';
import Dashboard from '../../dashboard';
import CadastroEmpresa from '../cadastro';
import { useStyles } from './styles';

export const ListaEmpresa = () => {
    const classes = useStyles();
    const cookies = new cookie();
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
    const matches = useMediaQuery('(max-width:480px)');
    const matches2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const matches3 = useMediaQuery('(max-width:900px) and (orientation: portrait)');
    const [empresas, setEmpresas] = useState([]);
    const [empresasPesquisa, setEmpresasPesquisa] = useState([]);
    const [validacao, setValidacao] = useState({ visible: false });
    const [picture, setPicture] = useState(null);
    const [mensagemValidacao, setMensagemValidacao] = useState('');
    const [empresa, setEmpresa] = useState({});
    const [cadastroOpen, setCadastroOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = empresasPesquisa.length;
    const start = page * itensPerPage;
    const r = itensPerPage + start;
    const todosEmpresas = empresasPesquisa.slice(start, r);
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });
    const [snackbarError, setSnackbarError] = useState({
        visible: false
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    const onPesquisar = event => {
        const contem = empresas.filter(item => {
            return (item.cidade.nome.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.cnpjcpf.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.nomefantasia.toLowerCase().includes(event.target.value.toLowerCase()));
        })
        setEmpresasPesquisa(contem);
    }

    const salvar = async () => {
        const dto = {
            ...empresa,
            sessionId: cookies.get('sessionId'),
            picture: picture
        }
        if (!empresa.razaosocial) {
            setMensagemValidacao('O campo razão social não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.nomefantasia) {
            setMensagemValidacao('O campo nome fantasia não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.cnpjcpf) {
            setMensagemValidacao('O campo CNPJ não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.email) {
            setMensagemValidacao('O campo e-mail não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.endereco) {
            setMensagemValidacao('O campo rua não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.endereconumero) {
            setMensagemValidacao('O campo número não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.complemento) {
            setMensagemValidacao('O campo complemento não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.bairro) {
            setMensagemValidacao('O campo bairro não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.cep) {
            setMensagemValidacao('O campo CEP não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!empresa.telefone) {
            setMensagemValidacao('O campo telefone não pode ser vazio');
            setValidacao({ visible: true });
            return;

        }
        if (!empresa.dominio) {
            setMensagemValidacao('O campo domínio não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }

        if (empresa.idempresa) {
            const temPermissaoEditar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.empresa, Enums.acaoRequerida.ver)
            if (!temPermissaoEditar.data) {
                setMensagemValidacao('Você não possui permissão!');
                setValidacao({ visible: true });
                return
            }
            api.put('/empresa/' + empresa.idempresa, empresa, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            }).then(response => {
                if (response.status !== 204) {
                    setSnackbarError({ visible: true });
                    return response;
                }
                api.get('/empresa', {
                    headers: {
                        Authorization: 'Bearer ' + cookies.get('token')
                    }
                }).then(res => {
                    setEmpresas(res.data);
                    setEmpresasPesquisa(res.data);
                    setSnackbarSucess({ visible: true });
                    setCadastroOpen(false);
                })
            })
            return
        }
        const temPermissaoCriar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.empresa, Enums.acaoRequerida.ver)
        if (!temPermissaoCriar.data) {
            setMensagemValidacao('Você não possui permissão!');
            setValidacao({ visible: true });
            return
        }
        api.post('/empresa', empresa, {

            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            if (response.status !== 201) {
                setSnackbarError({ visible: true });
                return response;
            }
            api.get('/empresa', {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            }).then(res => {
                setEmpresas(res.data);
                setEmpresasPesquisa(res.data);
                setSnackbarSucess({ visible: true });
                setCadastroOpen(false);
            })
        })
    }

    const handleCadastroOpen = (item, visible) => {
        setEmpresa(item);
        setCadastroOpen(visible);
    }

    useEffect(() => {
        api.get('/empresa', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            setEmpresas(response.data);
            setEmpresasPesquisa(response.data);
        })
    }, [])

    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: 'success', message: 'Dados salvos com sucesso!', visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Snackbar {...{ variant: 'error', message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <Dialog
                className={classes.cadastro}
                open={cadastroOpen}
                onClose={e => setCadastroOpen(false)}
                fullScreen={fullscreen}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.divtitle}>
                        <Typography variant="h6" className={classes.title}>
                            Empresa
                        </Typography>
                        <IconButton className={classes.fechar} onClick={e => setCadastroOpen(false)}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <CadastroEmpresa {...{ empresa: empresa, setEmpresa: setEmpresa, picture: picture, setPicture: setPicture }} />
                    <div className={classes.salvar}>
                        <LoadingButton {...{ salvar: salvar, modalOpen: cadastroOpen, setModalOpen: setCadastroOpen }} />
                    </div>
                </DialogContent>
            </Dialog>
            <Paper elevation={5}>
                <Toolbar className={classes.toolbar}>
                    {!matches && !matches2 && !matches3 ? (<Button className={classes.textfield} onClick={() => handleCadastroOpen({}, true)}>Novo</Button>) : (<UFloatButton actions={[]} handleClick={() => handleCadastroOpen({ tipousuario: 1 }, true)} />)}
                    <TablePagination
                        component="div"
                        count={pages}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 25, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <div className={classes.search3}>
                        <div className={classes.searchIcon}>
                            <Search />
                        </div>
                        <InputBase
                            onChange={onPesquisar}
                            placeholder="Pesquise…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                </Toolbar>
                {!fullscreen ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.heads} align="center"></TableCell>
                                <TableCell className={classes.heads} align="center">ID</TableCell>
                                <TableCell className={classes.heads} align="center">Nome</TableCell>
                                <TableCell className={classes.heads} align="center">CNPJ/CPF</TableCell>
                                <TableCell className={classes.heads} align="center">E-mail</TableCell>
                                <TableCell className={classes.heads} align="center">Cidade</TableCell>
                                <TableCell className={classes.heads} align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {todosEmpresas.map(item => (
                                <TableRow className={classes.row} key={item.idempresa}>
                                    <TableCell className={classes.bodys} align="center" >
                                        <IconButton onClick={e => handleCadastroOpen(item, true)} margin="small">
                                            <EditRounded fontSize="default" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className={classes.bodys} align="center">{item.idempresa}</TableCell>
                                    <TableCell className={classes.bodys} align="center">{item.nomefantasia}</TableCell>
                                    <TableCell className={classes.bodys} align="center">{item.cnpjcpf}</TableCell>
                                    {!matches && !matches2 && !matches3 ? (<TableCell className={classes.bodys} align="center">{item.email}</TableCell>) : null}
                                    {!matches && !matches2 && !matches3 ? (<TableCell className={classes.bodys} align="center">{item.cidade.nome}</TableCell>) : null}
                                    {!matches && !matches2 && !matches3 ? (<TableCell className={classes.bodys} align="center">{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>) : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <List>
                        {empresasPesquisa.map(item => (
                            <div>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <IconButton onClick={e => handleCadastroOpen(item, true)} margin="small">
                                            <EditRounded />
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.nomefantasia}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    style={{ display: 'inline' }}
                                                    color="textPrimary"
                                                >
                                                    {item.cnpjcpf}
                                                    <br />
                                                    {item.email}
                                                    <br />
                                                    {item.cidade.nome}
                                                </Typography>
                                                {` - ${item.telefone}`}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </div>
                        ))}
                    </List>
                )}

            </Paper>
        </div>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaEmpresa} />
        </div>
    )
};

export default Lista;
