import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    button: {
        background: '#c62828',
        height: 50,
        fontFamily: 'calibri',
        color: 'white',
        '&:hover': {
            background: '#b91119',
        }
    }
}));
