import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Toolbar, Typography } from '@material-ui/core';
import React from 'react';

export const UYesNoDialog = props => {
    const handleEventOnClick = async (type) => {
        props.setOpen(false);

        switch (type) {
            case 'yes':
                if (props.yesEvent) {
                    await props.yesEvent();
                }
                break;
            case 'no':
                if (props.noEvent) {
                    await props.noEvent();
                }
                break;
        };

    }

    return (
        <Dialog
            open={props.open}
            fullWidth={true}
            maxWidth="xs"
        >
            <AppBar position="relative">
                <Toolbar style={{ background: '#616161', color: '#ffffff' }}>
                    <Typography>Confirmação</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <DialogContentText>
                    {props.children}
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ color: '#c62828' }} onClick={() => handleEventOnClick('no')}>Não</Button>
                <Button style={{ color: '#43a047' }} onClick={() => handleEventOnClick('yes')}>Sim</Button>
            </DialogActions>
        </Dialog >
    )
};

export default UYesNoDialog; 
