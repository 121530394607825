import { Box, Grid, List, ListItem, TablePagination, Typography } from '@material-ui/core';
import { AttachMoneyOutlined, CalendarTodayOutlined, DateRangeOutlined, FlightOutlined, TrendingUpOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useStyles } from './styles';

function EconomiaNaEmissaoList(props) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);

    const pages = Math.ceil(props.data.length);
    const s = page * itensPerPage;
    const r = itensPerPage + s;
    const todaEmissao = props.data.slice(s, r);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    return (
        <>
            <TablePagination
                style={{ minWidth: 'max-content' }}
                labelRowsPerPage=''
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <List>
                {todaEmissao?.map(item => (
                    <ListItem divider>
                        <div>
                            <Grid container spacing={1} style={{ border: '1px solid #c62828', padding: '10px', borderRadius: '20px' }}>
                                <Grid item xs={8} >
                                    <Typography>{item.idreserva} - Loc: {item.loc}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                            Dias: {item.duracao} <DateRangeOutlined fontSize={'small'} />
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{item.passageiro}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        <Box display={'flex'} width={'-webkit-fill-available'} justifyContent={'space-around'}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                {item.trecho}<FlightOutlined fontSize='small' />
                                            </Box>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                {item.datacriacao}<CalendarTodayOutlined fontSize='small' />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        <Box display={'flex'} width={'-webkit-fill-available'} justifyContent={'space-around'}>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                {item.consolidado}<AttachMoneyOutlined className={classes.iconCustom_B} fontSize='small' />
                                            </Box>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                {item.comparativo}<AttachMoneyOutlined className={classes.iconCustom_R} fontSize='small' />
                                            </Box>
                                        </Box>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h6'>
                                        <Box display={'flex'} width={'-webkit-fill-available'} justifyContent={'center'} alignItems={'center'} >
                                            {item.economia}<TrendingUpOutlined fontSize={'small'} className={classes.iconCustom_G} />
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </ListItem>
                ))}
            </List>
        </>
    );
}

export default EconomiaNaEmissaoList;
