import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const economiaTotalRepositorio = {

    getRelatorioEconomiaTotal: async dtoDadosRelatorio => {
        const response = await api.post('/Relatorio/GetEconomiaTotal', dtoDadosRelatorio, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        })
        return response;
    }
}

export default economiaTotalRepositorio;
