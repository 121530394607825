import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import { AddRounded, DeleteOutline, EditOutlined } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./styles";

export const CartaoMobile = (props) => {
    const classes = useStyles();

    const handleOpenRegisterCartao = () => {
        props.setOpenDialog(true);
    }

    return (
        <div >
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={'10px'}>
                <Typography variant={'h6'}>Cartões</Typography>
                <Tooltip title="Adicionar cartão">
                    <Button onClick={() => handleOpenRegisterCartao()} className={classes.button} endIcon={<AddRounded style={{ color: 'inherited' }} />}>
                        Adicionar Cartão
                    </Button>
                </Tooltip>
            </Box>
            {props.isLoading ? (
                null
            ) : (
                <Box mt={1} className={classes.boxCustom}>
                    {props.listCard.length === 0 ? (<Typography variant={'h5'}> Não há cartões registrados</Typography>) : props.listCard.map((item) => (
                        <Paper elevation={2} className={classes.paperCustom} style={{ background: `${props.getColor(item.bandeira).background}` }}>
                            <Grid container style={{ height: '100%' }}>
                                <Grid item container xs={12}>
                                    {item.apelido ? (
                                        <Grid item xs={12} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                            <Typography variant={'h5'} style={{ color: `${props.getColor(item.bandeira).color}` }}>
                                                {item.apelido}
                                                <IconButton onClick={() => props.handleEditCardConfirm(item.idcartao, item.numero, item.apelido)} style={{ color: `${props.getColor(item.bandeira).color}` }}>
                                                    <EditOutlined />
                                                </IconButton>
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={3} className={classes.gridDetails}>
                                        <Tooltip title={item.bandeira}>
                                            <img src={item.bandeiraUrl} height={'50px'} width={'50px'} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={7} className={classes.gridDetails}>
                                        <Typography style={{ color: `${props.getColor(item.bandeira).color}` }}>{item.numero}</Typography>
                                    </Grid>
                                    <Grid item xs={2} className={classes.gridDetails}>

                                        <IconButton onClick={() => props.handleClickDeleteOpenDialogConfirm(item.idcartao, item.numero)} style={{ color: `${props.getColor(item.bandeira).color}` }}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}
                </Box>
            )}
        </div>
    )
}
