import React from 'react';

class DateDifference extends React.Component {
  calculateDifference(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = end - start;
    const daysDiff = timeDiff / (1000 * 3600 * 24);

    // Definindo as durações
    const weeks = Math.floor(daysDiff / 7);
    const months = Math.floor(daysDiff / 30); // Aproximadamente, considerando um mês como 30 dias

    if (months > 0) {
      return `${months} mês(es)`;
    } else if (weeks > 0) {
      return `${weeks} semana(s)`;
    } else if (daysDiff > 0) {
      return `${daysDiff} dia(s)`;
    } else {
      return 'Nenhuma data selecionada';
    }
  }

  render() {
    const { startDate, endDate } = this.props;
    const duration = this.calculateDifference(startDate, endDate);

    return (
      <div>
        Duração: {duration}
      </div>
    );
  }
}

export default DateDifference;
