var historico_Mock = [
  {
    id: 1,
    idpessoa: 1,
    data: new Date(2023, 4, 30, 15, 52),
    despesa: "Viagem pra local 1",
    mensagem: "Criou nova despesa",
    tipo: "completed",
  },
  {
    id: 2,
    idpessoa: 1,
    data: new Date(2023, 4, 29, 14, 52),
    despesa: "Adiantamento aprovado",
    mensagem: "Aprovou adiantamento",
    tipo: "completed",
  },
  {
    id: 3,
    idpessoa: 2,
    data: new Date(2023, 4, 28, 13, 52),
    despesa: "Viagem pra local 2",
    mensagem: "Criou nova despesa",
    tipo: "processing",
  },
  {
    id: 4,
    idpessoa: 1,
    data: new Date(2023, 4, 27, 12, 52),
    despesa: "Pedido de reembolso",
    mensagem: "Solicitou reembolso",
    tipo: "new",
  },
  {
    id: 5,
    idpessoa: 1,
    data: new Date(2023, 4, 26, 11, 52),
    despesa: "Cancelou viagem",
    mensagem: "Cancelou a viagem",
    tipo: "cancelled",
  },
];

export const getHistory_Mock = (idpessoa) => {
  return historico_Mock
    .filter((item) => item.idpessoa === idpessoa)
    .sort((a, b) => b.data - a.data);
};

export const writeHistory_Mock = (idpessoa, despesa, mensagem, tipo) => {
  const newEvent = {
    id: historico_Mock.length + 1, // Gera um novo id baseado no tamanho do histórico
    idpessoa: idpessoa,
    data: new Date(), // Usa a data atual
    despesa: despesa,
    mensagem: mensagem,
    tipo: tipo,
  };
  historico_Mock.push(newEvent);
};

export var historicoTransferencia_Mock = [
  {
    id: 1,
    data: new Date(2023, 4, 30, 15, 52),
    nome: "Rian Rodrigues",
    perfil:
      "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_5.jpg",
    tipo: "Aprovado Adiantamento",
    total: 108.9,
  },
  {
    id: 2,
    data: new Date(2024, 4, 30, 15, 52),
    nome: "Julio Balestrin",
    perfil:
      "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_8.jpg",
    tipo: "Aprovado Adiantamento",
    total: 901.23,
  },
  {
    id: 3,
    data: new Date(2024, 3, 30, 15, 52),
    nome: "Renato Cariani",
    perfil:
      "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_8.jpg",
    tipo: "Aprovado Adiantamento",
    total: 800.0,
  },
  {
    id: 4,
    data: new Date(2024, 2, 30, 15, 52),
    nome: "Julio Balestrin",
    perfil:
      "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_8.jpg",
    tipo: "Aprovado Adiantamento",
    total: 449.9,
  },
  {
    id: 5,
    data: new Date(2024, 4, 30, 15, 52),
    nome: "Julio Balestrin",
    perfil:
      "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_5.jpg",
    tipo: "Rian Rodrigues",
    total: 901.23,
  },
  {
    id: 6,
    data: new Date(2024, 4, 30, 15, 52),
    nome: "Julio Balestrin",
    perfil:
      "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_8.jpg",
    tipo: "Aprovado Adiantamento",
    total: 901.23,
  },
];

export const getHistoryTransferencias_Mock = () => {
  return historicoTransferencia_Mock
    .sort((a, b) => b.data - a.data)
    .slice(0, 5);
};

export const writeHistoryTransferencias_Mock = (nome, perfil, tipo, total) => {
  const newEvent = {
    id: historicoTransferencia_Mock.length + 1, // Gera um novo id baseado no tamanho do histórico
    data: new Date(),
    nome: nome,
    perfil: perfil,
    tipo: tipo,
    total: total,
  };

  historicoTransferencia_Mock.push(newEvent);
};
