import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useStyles } from './styles';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import USearchField from '../../../../../components/usearchfield';
import USearchFieldHotel from '../../../../../components/usearchfield/USearchFieldHotel';
import Skeleton from '@material-ui/lab/Skeleton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DestinationDialog = (props) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {/* {props.locations != null ? (
                <>
                    {props.locationid != null ? (
                        <Typography onClick={handleClickOpen} style={{ alignSelf: "center", fontSize: "15px", display: "flex", marginLeft: "0px", color: "#c62828", fontWeight: "bold" }}>
                            {props.locationid.subtitle}
                        </Typography>
                    ) : (
                        <>
                            <Typography onClick={handleClickOpen} style={{ alignSelf: "center", fontSize: "15px", display: "flex", marginLeft: "28px", color: "#c62828", fontWeight: "bold" }}>
                                Para onde você vai?
                            </Typography>
                        </>
                    )}
                </>
            ) : (
                <Skeleton variant="rect" width={50} height={20} />
            )} */}
            <Grid item xs={12} container style={{ padding: "10px" }}>
                <Grid item xs={12}>
                    {props.locations != null ? (
                        
                        <USearchFieldHotel {...{ itens: props.locations, value: props.locationid, setValue: props.setLocationid, label: 'Para onde você vai?' }}
                            name="origem"
                            onChange={props.handleChangeAuto}
                        />
                    ) : (
                        <Skeleton variant="rect" fullWidth height={28} />
                    )}
                </Grid>
                <hr style={{ width: "100%", backgroundColor: "#D6D6D6", height: "1px", border: "none" }}></hr>
            </Grid>
            {/* <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon style={{ color: "#c62828" }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container style={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        {props.locations != null ? (
                            <USearchFieldHotel {...{ itens: props.locations, value: props.locationid, setValue: props.setLocationid, label: 'Destino' }}
                                name="origem"
                                onChange={props.handleChangeAuto}
                            />
                        ) : (null)}
                    </Grid>
                    <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none" }}></hr>
                    <Grid>

                    </Grid>
                </Grid>
            </Dialog > */}

        </>
    )
}
