import cookie from "universal-cookie";
import api from "../../Api";

const cookies = new cookie();

const financeiroRepositorio = {
  getAllViagens: async (model) => {
    const response = await api.post(
      "/PrestacaoContasFinanceiro/GetAllWithFilterFinanceiro",
      model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  getHistory: async (idviagem) => {
    const response = await api.get(
      "/PrestacaoContasFinanceiro/GetAllHistorico/" + idviagem,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  putPagarAdiantamento: async (idviagem, model) => {
    const response = await api.put(
      "/PrestacaoContasFinanceiro/PutPagarAdiantamento/" + idviagem,
      model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  putPagarViagem: async (idviagem, model) => {
    const response = await api.put(
      "/PrestacaoContasFinanceiro/PutPagarViagem/" + idviagem,
      model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  putNegarAdiantamento: async (idviagem, model) => {
    const response = await api.put(
      `/PrestacaoContasFinanceiro/PutNegarPagamentoEAdiantamento/${idviagem}/${true}`, model,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },

  getHistorico: async () => {
    const response = await api.get(
      `/PrestacaoContasFinanceiro/GetHistorico/`, 
      {
        headers: {
          Authorization: "Bearer " + cookies.get("token"),
          sessionId: cookies.get("sessionId"),
        },
      }
    );

    return response.data;
  },
};

export default financeiroRepositorio;
