import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import PostAddIcon from '@mui/icons-material/PostAdd';
import DialogComponent from "../../sections/dialogs-desktop";
import { useCallback, useEffect, useState } from "react";
import { criarNovaDespesaExtra_Mock } from "../../../../../mock/prestacaodecontas";
import { convertImageToBase64 } from "../../../../../hooks/convertImageToBase64";
import { UploadAvatar } from "../../../../../upload";
import { UtaliiDatePickerNormal } from "../../sections/custom-date-picker-normal";
import { fData } from "../../../../../upload/utils/utils";
import { tiposdedespesas_Mock } from "../../../../../mock/tiposdespesas";

export const ExtraAddComponent = ({ viagem, auditor }) => {
    const [openAddExtra, setOpenAddExtra] = useState(false);
    const [file, setFile] = useState(null);
    const [titulo, setTitulo] = useState("");
    const [tipodedespesa, setTipodedespesa] = useState(null);
    const [tipoGasto, setTipoGasto] = useState(null)
    const [descricao, setDescricao] = useState("");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [totalgasto, setTotalgasto] = useState(0);
    const [typeD, setTypeD] = useState(0);
    const [count, setCount] = useState(0);
    const [isExtra, setIsExtra] = useState(false);

    useEffect(() => {
        let c = 0;
        viagem?.extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [viagem])

    const handleCreateDespesa = () => {
        criarNovaDespesaExtra_Mock({
            idviagem: viagem.id,
            datadecriacao: datadecompra,
            titulo: titulo,
            tipo: tipodedespesa != null ? tipodedespesa.value : 7,
            comprovante: file,
            total: Number(totalgasto),
            produtos: {
                descricao: tipoGasto != null ? tipoGasto.label : "",
                value: tipoGasto != null ? tipoGasto.value : 0
            },
            descricao: descricao
        })
        setOpenAddExtra(false)
    }

    const handleChangeTipoDeDespesa = (value) => {
        setTipoGasto(null)
        setTipodedespesa(value);
    };

    const handleChangeGasto = (value) => {
        setTipoGasto(value)
    }

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            convertImageToBase64(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(
                    base64String // Aqui você tem a imagem convertida em base64
                );
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    return (
        <>
            <DialogComponent
                title={
                    <>
                        Adicionar Extra
                    </>
                }
                open={openAddExtra}
                handleClose={() => setOpenAddExtra(false)}
                body={
                    <Grid item xs={12} container style={{ width: "auto", minWidth: "350px" }}>
                        <Grid container item xs={12} style={{ marginTop: "5px", alignContent: "space-between", paddingLeft: "10px", alignContent: "center" }} spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-multiline-flexible"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={(e) => setDescricao(e.target.value)}
                                    multiline
                                    maxRows={4}
                                    label="Justificativa/Descrição"
                                    placeholder="Viagem para..."

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={tipodedespesa != null ? tipodedespesa.value : null}
                                        defaultValue={0}
                                        label="Categoria"

                                    >
                                        {tiposdedespesas_Mock?.map((e) => (
                                            <MenuItem value={e.value} onClick={() => handleChangeTipoDeDespesa({ label: e.label, value: e.value, sub: e.sub })}>{e.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {tipodedespesa != null && tipodedespesa?.sub?.lenght != 0 && tipodedespesa?.label != "Outros" ? (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gasto com</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tipoGasto != null ? tipoGasto.value : null}
                                            defaultValue={0}
                                            label="Categoria"

                                        >
                                            {tipodedespesa?.sub?.map((e) => (
                                                <MenuItem value={e.value} onClick={() => handleChangeGasto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                                            ))}
                                            <MenuItem value={0} onClick={() => handleChangeGasto({ label: "Outros", value: 0 })}>Outros</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : (
                                null
                            )}
                            <Grid item xs={6} style={{ paddingRight: "10px" }}>
                                <TextField
                                    fullWidth
                                    id="outlined-number"
                                    label="Total gasto"
                                    onChange={(e) => setTotalgasto(e.target.value)}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                                <UtaliiDatePickerNormal
                                    value={datadecompra}
                                    onChange={setDatadecompra}
                                    label="Data de Compra"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                action={
                    <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                        <Button onClick={() => handleCreateDespesa()} startIcon={<PostAddIcon />}>Adicionar Extra</Button>
                    </Grid>
                }
                width={"sm"}
            />
            <Grid style={{ display: "flex", padding: "10px", justifyContent: "right" }} item xs={12}>
                <Button onClick={() => setOpenAddExtra(true)} startIcon={<PostAddIcon />}>Adicionar Extra</Button>
            </Grid>
        </>
    )
}