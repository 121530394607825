import { AppBar, Box, Button, ButtonGroup, Container, Dialog, DialogActions, DialogContent, Grid, IconButton, Paper, Toolbar, Typography, useMediaQuery, useTheme, } from "@material-ui/core";
import { Close, DoubleArrowOutlined, GetApp, SearchOutlined, TrendingDownOutlined, } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Snackbar from "../../../components/snackbar";
import UDatePicker from "../../../components/udatepicker";
import ExportExcel from "../../../components/UExportExcel";
import ULoaderVoo from "../../../components/uloadervoo";
import USearchField from "../../../components/usearchfield";
import menorTarifaRepositorio from "../../../repositorios/relatorio/menorTarifa";
import usuarioRepositorio from "../../../repositorios/usuario";
import Dashboard from "../../dashboard";
import DialogHistoricoReserva from "./dialogHistorico";
import MenorTarifaGrid from "./menorTarifaGrid/index.js";
import MenorTarifaList from "./menorTarifaList/index.js";
import { useStyles } from "./styles";

function isValidDate(date) {
  return moment(date).isValid();
}

export const ListaMenorTarifa = () => {
  const permissao = useSelector((state) => state.permissao.permissaoDashboard);
  const classes = useStyles();
  const theme1 = useTheme();
  const responsive = useMediaQuery(theme1.breakpoints.down("sm"));
  const [pesquisado, setPesquisado] = useState(false);
  const [dataFim, setDataFim] = useState(new Date());
  const [dataInicio, setDataInicio] = useState(new Date());
  const [viajantes, setViajantes] = useState([]);
  const [viajante, setViajante] = useState(null);
  const [data, setData] = useState({ total: {}, data: [] });
  const [tipoValue, setTipoValue] = useState(0);
  const [dialogIdReserva, setDialogIdReserva] = useState(0);
  const [idreservatenant, setIdreservatenant] = useState(0);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [openDialoHistorico, setOpenDialoHistorico] = useState(false);
  const [colunasExcel, setColunasExcel] = useState([]);
  const [rowsExcel, setRowsExcel] = useState([]);
  // const [centroCusto, setCentroCusto] = useState(null);
  // const [centrosCusto, setCentrosCusto] = useState([]);
  const [loading, setLoading] = useState(false);
  const politicaViagem = useSelector((state) => state.permissao.politicaViagem);
  const [openDialog, setOpenDialog] = useState(false);
  const [reduction, setReduction] = useState();
  const [tiposValues, setTiposValues] = useState([
    {
      label: "Emitidos",
      value: 7,
    },
    {
      label: "Reservados",
      value: 4,
    },
    {
      label: "Emitidos + Reservados",
      value: 0,
    },
  ]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickPesquisar = async () => {
    if (dataInicio > dataFim) {
      setSnackVariant("error");
      setSnackMessage("Data de início maior que data final.");
      setSnackVisible({ visible: true });
      return;
    }

    if (!isValidDate(dataInicio) || !isValidDate(dataFim)) {
      setSnackVariant("error");
      setSnackMessage("Data Inválida.");
      setSnackVisible({ visible: true });
      return;
    }
    setLoading(true);
    try {
      let dto = {
        dataInicio,
        dataFim,
        // idCentroCusto: centroCusto ? centroCusto.value : 0,
        idViajante: viajante ? viajante.value : 0,
        status: tipoValue.value,
      };
      let response =
        await menorTarifaRepositorio.postRelatorioMenorTarifaByTenant(dto);
      setData(response);
      setPesquisado(true);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao pesquisar dados.");
      setSnackVisible({ visible: true });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeData = () => {
    let inicio = new Date();
    if (
      dataInicio !== "" &&
      dataInicio !== undefined &&
      dataInicio !== null &&
      dataFim !== "" &&
      dataFim !== undefined &&
      dataFim !== null
    ) {
      if (
        dataInicio.toLocaleDateString() === new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString() && dataFim.toLocaleDateString() === new Date().toLocaleDateString()
      ) {
        return 1;
      }
      if (
        dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth(), 1).toLocaleDateString() && dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0).toLocaleDateString()
      ) {
        return 2;
      }
      if (
        dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), 0, 1).toLocaleDateString() && dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), 11, 31).toLocaleDateString()) {
        return 3;
      }

      return 4;
    }
  };
  const setPeriodoPesquisa = (periodo) => {
    let inicio = new Date();
    let fim = new Date();

    switch (periodo) {
      case 1:
        inicio.setDate(inicio.getDate() - 7);
        break;
      case 2:
        inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
        fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
        break;
      case 3:
        inicio = new Date(inicio.getFullYear(), 0, 1);
        fim = new Date(inicio.getFullYear(), 11, 31);
        break;
    }

    setDataInicio(inicio);
    setDataFim(fim);
  };

  useEffect(() => {
    setColunasExcel([
      "ID da Reserva",
      "Descrição",
      "Solicitante",
      "Quantidade de Passageiro(s)",
      "Passageiro(s)",
      "Data de Criação",
      "Preço Inicial",
      "Preço Final",
      "Valor da Redução",
      "% da Redução"
    ]);
    const datas = data?.data?.map((item) => {
      var textoPassageiro = "";
      item.passageiros.map((pax) => textoPassageiro += pax + ",");
      return [
        item.idreservatenant + "",
        item.assunto,
        item.solicitante,
        item.passageiros.length,
        textoPassageiro,
        new Date(item.dataCriacao).toLocaleDateString(),
        item.precoInicial,
        item.precoFinal,
        item.diferenca,
        `${item.diferenca_percent.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`
      ];
    });
    setRowsExcel(datas);
  }, [data]);

  useEffect(() => {
    (async () => {
      setTipoValue(tiposValues[2]);
      // const response = await centroCustoRepositorio.getSearchField();
      const responseSolicitante = await usuarioRepositorio.getSearchField();
      // const responseViajantes = await pessoaRepositorio.getSearchField();
      // setCentrosCusto(responseSolicitante);
      setViajantes(responseSolicitante);
    })();
  }, []);

  return (
    <section>
      <Grid>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullScreen={responsive}
        >
          <AppBar position="relative" style={{ background: "none" }}>
            <Toolbar className={classes.appbar}>
              <Typography>Sair</Typography>
              <IconButton onClick={handleCloseDialog}>
                <Close className={classes.iconButton} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>

          </DialogContent>
          <DialogActions>

          </DialogActions>
        </Dialog>
      </Grid>
      <ULoaderVoo isLoading={loading} />
      <Snackbar
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      {pesquisado ? (
        <DialogHistoricoReserva
          open={openDialoHistorico}
          idreservatenant={idreservatenant}
          setOpenDialoHistorico={setOpenDialoHistorico}
          dialogIdReserva={dialogIdReserva}
          reduction={reduction}
        />
      ) : null}
      <Container maxWidth="xl">
        <Box mt={2} mb={responsive ? 10 : 0}>
          <Box mb={1}>
            <Grid container>
              <Grid item xs={responsive ? 12 : 2}>
                <Typography variant="h6" component="h1">
                  Reduções IA
                </Typography>
              </Grid>
              {responsive ? null : (
                <>
                  <Grid item xs={8}>
                    <Grid item xs={12} className={classes.groupButton}>
                      <ButtonGroup>
                        <Button
                          style={{
                            background:
                              handleChangeData() === 1
                                ? "#c62828"
                                : "transparent",
                            color:
                              handleChangeData() === 1 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodoPesquisa(1)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Semanal
                          </Typography>
                        </Button>
                        <Button
                          style={{
                            background:
                              handleChangeData() === 2
                                ? "#c62828"
                                : "transparent",
                            color:
                              handleChangeData() === 2 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodoPesquisa(2)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Mensal
                          </Typography>
                        </Button>
                        <Button
                          style={{
                            background:
                              handleChangeData() === 3
                                ? "#c62828"
                                : "transparent",
                            color:
                              handleChangeData() === 3 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodoPesquisa(3)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Anual
                          </Typography>
                        </Button>
                        <Button
                          style={{
                            background:
                              handleChangeData() === 4
                                ? "#c62828"
                                : "transparent",
                            color:
                              handleChangeData() === 4 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodoPesquisa(4)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Personalizado
                          </Typography>
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </>
              )}
            </Grid>
          </Box>
          <Paper elevation={3}>
            <Box p={3}>
              <form autoComplete="off">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={2}>
                    <UDatePicker
                      date={dataInicio}
                      setDate={setDataInicio}
                      label="Data Início"
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <UDatePicker
                      date={dataFim}
                      setDate={setDataFim}
                      label="Data Fim"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <USearchField
                      label="Tipo de Reserva"
                      itens={tiposValues}
                      value={tipoValue}
                      setValue={setTipoValue}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={4}>
                                        {permissao.relatorioCentroCusto ? (
                                            <USearchField disabled={!politicaViagem.utilizarcentrocusto} label="Centro de custo" itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} />
                                        ) : null}
                                    </Grid> */}
                  <Grid item xs={12} md={4}>
                    {permissao.relatorioPessoal ? (
                      <USearchField
                        label="Solicitante"
                        itens={viajantes}
                        value={viajante}
                        setValue={setViajante}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={1} style={{ alignSelf: "center" }}>
                    <Button
                      onClick={handleClickPesquisar}
                      className={classes.button}
                      variant="contained"
                      fullWidth
                    >
                      <SearchOutlined />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  {pesquisado ? (
                    <>
                      {responsive ? (
                        <Grid item xs={12}>
                          <Typography style={{ fontSize: "12pt" }}>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              Total inicial: {data.total.totalInicial}
                            </Box>
                          </Typography>
                          <Typography style={{ fontSize: "12pt" }}>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              Total final: {data.total.totalFinal}
                            </Box>
                          </Typography>
                          <Typography style={{ fontSize: "12pt" }}>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <b>Total redução:</b>
                            </Box>
                          </Typography>
                          <Typography style={{ fontSize: "12pt" }}>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              {data.total.totalDiferenca}
                              <TrendingDownOutlined
                                fontSize="medium"
                                style={{ color: "green" }}
                              />
                            </Box>
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography style={{ fontSize: "10pt" }}>
                            <Box display={"flex"} alignItems={"center"}>
                              Total inicial: {data.total.totalInicial}
                              <DoubleArrowOutlined
                                style={{ color: "#c62828" }}
                              />
                              Total final: {data.total.totalFinal}
                            </Box>
                          </Typography>
                          <Typography style={{ fontSize: "10pt" }}>
                            <Box display={"flex"} alignItems={"center"}>
                              <b>Total redução: {data.total.totalDiferenca}</b>
                              <TrendingDownOutlined
                                fontSize="medium"
                                style={{ color: "green" }}
                              />
                            </Box>
                          </Typography>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={responsive ? 12 : 2}
                        style={{
                          display: "flex",
                          justifyContent: responsive ? "center" : "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <ExportExcel
                          name="Relatório de reduções IA"
                          columns={colunasExcel}
                          data={rowsExcel}
                          element={
                            <>
                              <Button className={classes.button}>
                                <GetApp />
                                Exportar
                              </Button>
                            </>
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                </Grid>
              </form>
            </Box>
          </Paper>
          <Box mt={2}>
            {responsive ? (
              <>
                <MenorTarifaList
                  data={data.data}
                  setDialogIdReserva={setDialogIdReserva}
                  setIdreservatenant={setIdreservatenant}
                  setOpenDialoHistorico={setOpenDialoHistorico}
                  setReduction={setReduction}
                  reduction={reduction}
                />
              </>
            ) : (
              <Paper elevation={3}>
                <MenorTarifaGrid
                  data={data.data}
                  setDialogIdReserva={setDialogIdReserva}
                  setIdreservatenant={setIdreservatenant}
                  loading={loading}
                  setOpenDialoHistorico={setOpenDialoHistorico}
                  setReduction={setReduction}
                  reduction={reduction}
                />
              </Paper>
            )}
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export const RelatorioMenorTarifa = (props) => {
  return (
    <div>
      <Dashboard {...props} component={ListaMenorTarifa} />
    </div>
  );
};
export default RelatorioMenorTarifa;
