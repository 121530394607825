import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    tableCustom: {
        minWidth: 650
    },
    button: {
        width: '9vw',
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#810b11'
        },
    },
    buttonMobile: {
        width: '35vw',
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#810b11'
        },
    },
}));
