import { Box, Grid, List, ListItem, TablePagination, Typography } from '@material-ui/core';
import { AirlineSeatReclineExtraOutlined, AirplanemodeActiveOutlined, AttachMoneyOutlined, CalendarTodayOutlined, ConfirmationNumberOutlined, EventAvailableOutlined, EventBusyOutlined, LocalAtmOutlined, MoneyOffOutlined } from '@material-ui/icons';
import React, { useState } from 'react';

function TempoDecisaoList(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = Math.ceil(props.data.length);
    const s = page * itensPerPage;
    const r = itensPerPage + s;
    const todaDecisao = props.data.slice(s, r);

    const getCor = value => {
        return value < 0 ? 'red' : 'green';
    };

    const definirAssunto = (assunto) => {
        if (!assunto) {
            return '';
        }
        const textos = assunto.split('<br />');
        return (
            <div>
                {textos.map(item => (
                    <div>{item}</div>
                ))}
            </div>
        );
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };


    return (
        <>
            <TablePagination
                style={{ minWidth: 'max-content' }}
                labelRowsPerPage=''
                component="div"
                count={pages}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <List>
                {todaDecisao.map(item => (
                    <ListItem divider style={{ border: "2px solid #c62828", borderRadius: "10px", marginBottom: '10px' }}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box mr={0.5}>
                                        <ConfirmationNumberOutlined fontSize="small" />
                                    </Box>
                                    <Typography variant='h6'><b>{item.id}</b></Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <AirplanemodeActiveOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{definirAssunto(item.assunto)}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <AirlineSeatReclineExtraOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.solicitante.nomeUsuario}</Typography>
                                </Grid>


                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Aéreo Inicial</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <EventBusyOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.dataCriacao_antigo}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5} style={{ display: 'flex' }}>
                                        <MoneyOffOutlined fontSize="small" />
                                    </Box>
                                    <Typography >
                                        <Typography>{item.valor_antigo.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                    </Typography>
                                </Grid>


                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Aéreo Emitido</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <EventAvailableOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.dataCriacao}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5} style={{ display: 'flex' }}>
                                        <AttachMoneyOutlined fontSize="small" />
                                    </Box>
                                    <Typography>{item.valor_atual.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>


                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography>Perda</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Box mr={0.5}>
                                        <LocalAtmOutlined fontSize="small" />
                                    </Box>
                                    <Typography style={{ color: getCor(item.diferenca.total) }}>
                                        {`R$ ${Number(item.diferenca.total).toFormatDefault()}(${Number(item.diferenca.porcentagem).toFormatDefault()}%)`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </ListItem>
                ))}
            </List>
        </>
    );
}

export default TempoDecisaoList;
