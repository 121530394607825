import { Avatar, Box, Button, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Pagination, Select, Stack, Switch, Tooltip, Typography } from "@mui/material"
import { useEffect, useLayoutEffect, useState } from "react";
import { MiniPersonCardItem } from "../../../../../components/autalii_expanses/minicardperson";
import { prestacaoDeContasConfigRepositorio } from "../../../../../repositorios/prestacaodecontas/config";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import DialogComponent from "../../../Desktop/sections/dialogs-desktop";
import { EmailOutlined, GroupsOutlined, SaveAsOutlined, WhatsApp } from "@mui/icons-material";
import { stringAvatar } from "../../../../../theme/palette";
import pessoaRepositorio from "../../../../../repositorios/pessoa";
import centroCustoRepositorio from "../../../../../repositorios/centroCusto";

export const PermissionsListUsers = () => {
    const [loading, setLoading] = useState(false)
    const [centrodecusto, setcentrodecusto] = useState({ label: "Todos", value: 0 });
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [pessoas, setPessoas] = useState([]);
    const [pessoa, setPessoa] = useState({ label: "Todos", value: 0 });
    const [usuarios, setUsuarios] = useState([])
    const [filterUsuarios, setFilterUsuarios] = useState(usuarios)
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = filterUsuarios.slice(startIndex, endIndex);
    const [openDialogConfig, setOpenDialogConfig] = useState(false)
    const [selectedPessoa, setSelectedPessoa] = useState(null)
    const [originalPermissoes, setOriginalPermissoes] = useState();
    const [grupos, setGrupos] = useState([])
    const [hasChanges, setHasChanges] = useState(false);
    const [originalPermissionG, setOriginalPermissionG] = useState(false)
    const [pessoaNewGroup, setPessoaNewGroup] = useState({ label: "Nenhum grupo encontrado", value: 0 })

    const { enqueueSnackbar } = useCustomSnackbar();

    useLayoutEffect(() => {
        (async () => {
            const responsePessoas = await pessoaRepositorio.getSearchField();
            const response = await centroCustoRepositorio.getSearchField();
            // await getData();
            setCentrosCusto(response);
            setPessoas(responsePessoas)
        })()
    }, [])

    const filterUsers = (filters) => {
        let filteredUsers = usuarios;

        if (filters.pessoa && filters.pessoa.value !== 0) {
            filteredUsers = filteredUsers.filter(user => user.pessoa.nome === filters.pessoa.label);
        }

        if (filters.centrodecusto !== undefined && filters.centrodecusto.value !== 0) {
            filteredUsers = filteredUsers.filter(user => user.pessoa.centrodecusto_codigo === filters.centrodecusto);
        }

        setPage(1);
        setFilterUsuarios(filteredUsers);
    };

    const handleClickPessoaConfig = (value) => {
        setOriginalPermissoes(value.permissoes)
        setSelectedPessoa(value)
        setOpenDialogConfig(true)
        // setHasChanges(false)
    }

    const getAllUsers = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasConfigRepositorio.getAllUsuarios();
            setUsuarios(response)
            setFilterUsuarios(response)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar as pessoas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const getAllGrupos = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasConfigRepositorio.getAllGroupQrCode();
            setGrupos(response)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar as pessoas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleChangePessoaGrupo = (event) => {
        if (selectedPessoa.pessoa.idgrupo == event.value) {
            setOriginalPermissionG(false)
        } else {
            setOriginalPermissionG(true)
        }
        setPessoaNewGroup(event);
    };

    const handleSave = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.putEditPermissions(selectedPessoa.pessoa.idusuario, selectedPessoa.permissoes)
            setOpenDialogConfig(false)
            getAllUsers()
            enqueueSnackbar('Configurações aplicadas.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao atualizar configurações.', { variant: 'error' });
        } finally {
            setLoading(false)
            setHasChanges(false);
        }
        // Aqui você pode adicionar a lógica para salvar as mudanças no backend ou qualquer outra ação necessária
    };

    const handleSaveGrupo = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.putEditPermissionUser(selectedPessoa.pessoa.idusuario, selectedPessoa.pessoa.idgrupo)
            setOpenDialogConfig(false)
            getAllUsers()
            enqueueSnackbar('Configurações aplicadas.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao atualizar configurações.', { variant: 'error' });
        } finally {
            setLoading(false)
            setHasChanges(false);
        }
        // Aqui você pode adicionar a lógica para salvar as mudanças no backend ou qualquer outra ação necessária
    };

    const handleChangeCentrodecusto = (event) => {
        const value = Number(event.value);
        setcentrodecusto(event);
        filterUsers({ pessoa, centrodecusto: { label: event.label, value: value } });
    };

    const handleChangePessoa = (event) => {
        setPessoa(event);
        filterUsers({ pessoa: event, centrodecusto });
    };

    const handleChangePermission = (permission) => {
        setSelectedPessoa((prevState) => {
            const newPermissoes = {
                ...prevState.permissoes,
                [permission]: !prevState.permissoes[permission],
            };
            setHasChanges(!(JSON.stringify(newPermissoes) === JSON.stringify(originalPermissoes)));
            return {
                ...prevState,
                permissoes: newPermissoes,
            };
        });
    };


    useEffect(() => {
        getAllUsers()
        getAllGrupos()
    }, [])

    return (
        <>
            <DialogComponent
                title={"Opções do usuário"}
                open={openDialogConfig}
                handleClose={() => setOpenDialogConfig(false)}
                fullScreen={true}
                body={
                    <Grid container item xs={12} style={{ width: "400px", maxWidth: "auto" }}>
                        {selectedPessoa != null && selectedPessoa.permissoes != null && (
                            <>
                                <Grid container item xs={12} style={{ padding: 10, display: 'flex', alignItems: 'center', boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)", borderRadius: "10px", marginBottom: "20px" }}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', maxWidth: "auto" }}>
                                        <>
                                            <Avatar
                                                src={selectedPessoa.pessoa.img}
                                                {...stringAvatar(selectedPessoa.pessoa.nome)}
                                                style={{ width: 48, height: 48 }}
                                            />
                                            <div style={{ flexGrow: 1, textAlign: "left", marginLeft: "10px" }}>
                                                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                    {selectedPessoa.pessoa.nome}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {selectedPessoa.pessoa.centrodecusto_nome} - {selectedPessoa.pessoa.centrodecusto_codigo}
                                                </Typography>
                                            </div>
                                            {selectedPessoa.pessoa.whats !== "" && selectedPessoa.pessoa.whats !== null && (
                                                <Tooltip title="Contate-o pelo WhatsApp">
                                                    <IconButton aria-label="Entrar em contato">
                                                        <a href={selectedPessoa.pessoa.whats} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                                                            <WhatsApp />
                                                        </a>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {selectedPessoa.pessoa.email !== "" && selectedPessoa.pessoa.email !== null && (
                                                <Tooltip title="Contate-o pelo Email">
                                                    <IconButton aria-label="Entrar em contato">
                                                        <a href={`mailto:${selectedPessoa.pessoa.email}`} style={{ color: 'inherit' }}>
                                                            <EmailOutlined />
                                                        </a>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} style={{ textAlign: "center", padding: "10px", marginTop: "10px" }}>
                                            {formatarParaNomeStatusUser(2)}
                                        </Grid> */}
                                <Grid container item xs={12} style={{ padding: "0px" }}>
                                    <List style={{ width: '100%' }}>
                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                            <ListItemText primary={"Registrar suas viagens"} secondary={"Pode criar viagens e adicionar despesas."} style={{ maxWidth: '80%' }} />
                                            <ListItemSecondaryAction>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        onClick={() => handleChangePermission('permissao_somentepessoal')}
                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_somentepessoal} />}
                                                        label="Ativo"
                                                    />
                                                </FormGroup>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                            <ListItemText primary={"Ser Auditor"} secondary={"Pode ver transações financeiras, realizar transações financeiras, acompanhar viagens."} style={{ maxWidth: '80%' }} />
                                            <ListItemSecondaryAction>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        onClick={() => handleChangePermission('permissao_contabilidade')}
                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_contabilidade} />}
                                                        label="Ativo"
                                                    />
                                                </FormGroup>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                            <ListItemText primary={"Ser controlador"} secondary={"Pode verificar viagens no status controlador e aprovar viagens."} style={{ maxWidth: '80%' }} />
                                            <ListItemSecondaryAction>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        onClick={() => handleChangePermission('permissao_controladoria')}
                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_controladoria} />}
                                                        label="Ativo"
                                                    />
                                                </FormGroup>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <ListItem style={{ boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.5)', borderRadius: '10px', padding: '10px', marginBottom: '10px' }}>
                                            <ListItemText primary={"Ser financeiro"} secondary={"Pode acessar o financeiro, realizar pagamentos e acesso total a todos relatórios."} style={{ maxWidth: '80%' }} />
                                            <ListItemSecondaryAction>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        onClick={() => handleChangePermission('permissao_financeiro')}
                                                        control={<Switch checked={selectedPessoa.permissoes.permissao_financeiro} />}
                                                        label="Ativo"
                                                    />
                                                </FormGroup>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <List style={{ width: "100%" }}>
                                            <ListItem style={{ boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)", borderRadius: "10px", padding: "10px", marginBottom: "10px" }}>
                                                <ListItemText primary={"Criar grupos"} secondary={"Pode criar grupos, remover e editar."} style={{ maxWidth: "80%" }} />
                                                <ListItemSecondaryAction>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            onClick={() => handleChangePermission('permissao_vergrupopalavra')}
                                                            control={<Switch defaultChecked={selectedPessoa.permissoes?.permissao_vergrupopalavra} />}
                                                            label="Ativo"
                                                        />
                                                    </FormGroup>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </List>
                                    {hasChanges && (
                                        <Grid item xs={12} style={{ textAlign: 'right', marginTop: "-20px" }}>
                                            <Button startIcon={<SaveAsOutlined />} variant="contained" fullWidth color="primary" onClick={handleSave}>Salvar</Button>
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid item xs={12} style={{ padding: "10px", marginTop: "0px" }}>
                                    <Divider width={"100%"} />
                                </Grid>
                                <Grid container item xs={12} style={{ display: "flex", alignItems: "center", padding: "10px" }}>
                                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", padding: "inherit" }}>
                                        <GroupsOutlined />
                                        <Typography variant="h6" style={{ marginLeft: "5px" }}>
                                            Grupo
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" style={{ color: "#555555" }}>
                                            Grupos com que o usuário faz parte, as palavras dentro desse grupo serão lidas ao utilizar Qr code e determina assim como categorias.
                                        </Typography>
                                        <FormControl fullWidth style={{ marginTop: "10px" }}>
                                            <InputLabel id="demo-simple-select-label">Associado ao grupo...</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={pessoaNewGroup.value}
                                                defaultValue={selectedPessoa.pessoa.idgrupo}
                                                label="Associado ao grupo..."
                                            >
                                                {grupos?.map((e) => (
                                                    <MenuItem value={e.id_config_group} onClick={() => handleChangePessoaGrupo({ label: e.nameconfig, value: e.id_config_group })}>{e.nameconfig}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        {originalPermissionG && (
                                            <>
                                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                                    <Button fullWidth startIcon={<SaveAsOutlined />} variant="contained" color="primary" onClick={handleSaveGrupo}>Salvar</Button>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                }
                // action={<ConfirmButtonNovaViagemM />}
                width={"sm"}
            />
            <Container maxWidth={"xl"} style={{ padding: "20px" }}>
                <Grid item xs={12} spacing={2}>
                    <FormControl style={{ width: "100%", marginTop: "10px" }}>
                        <InputLabel id="demo-simple-select-label">Centro de custo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Number(centrodecusto.value)}
                            defaultValue={0}
                            label="Centro de custo"

                        >
                            <MenuItem value={0} onClick={() => handleChangeCentrodecusto({ label: "Todos", value: 0 })}>Todos</MenuItem>
                            {centrosCusto?.map((e) => (
                                <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1, marginTop: "10px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Pessoa</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={pessoa.value}
                                defaultValue={0}
                                label="Pessoa"

                            >
                                <MenuItem value={0} onClick={() => handleChangePessoa({ label: "Todos", value: 0 })}>Todos</MenuItem>
                                {pessoas?.map((e) => (
                                    <MenuItem value={e.value} onClick={() => handleChangePessoa({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                    <Divider component="ul" style={{ marginTop: "16px" }} />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        gap={3}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                            md: 'repeat(3, 1fr)',
                        }}
                        style={{ paddingTop: "16px", padding: "10px", marginTop: "10px" }}
                    >
                        {currentItems?.map((item) => (
                            <>
                                <MiniPersonCardItem
                                    key={1}
                                    pessoa={item.pessoa}
                                    selected={false}
                                    onSelected={() => handleClickPessoaConfig(item)}
                                />
                            </>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                        color="primary"
                        count={Math.ceil(filterUsuarios.length / itemsPerPage)}
                        page={page}
                        // onChange={handleChangePage}
                        siblingCount={0}
                        shape="rounded"
                    />
                </Grid>
            </Container>
        </>
    )
}