import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import cookie from 'universal-cookie';
import api from '../../../Api';
import LinesTextField from '../../../components/LinesTextField';
import USearchField from '../../../components/usearchfield';
import { useStyles } from './styles';

export const CadastroFornecedor = props => {
    const classes = useStyles();
    const cookies = new cookie();
    const [cidades, setCidades] = useState([]);
    const [cidade, setCidade] = useState((props.fornecedor.cidade ? { label: props.fornecedor.cidade.nome, value: props.fornecedor.idcidade } : { label: '', value: 0 }) || null);

    const handleChange = event => {
        props.setFornecedor({
            ...props.fornecedor,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeAuto = item => {
        props.setFornecedor({
            ...props.fornecedor,
            [item.name]: item.value,
        })
    }

    useEffect(() => {
        let listaCidades = [];
        api.get('/cidade', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            response.data.map(item => {
                listaCidades.push({
                    label: item.nome,
                    value: item.idcidade
                })
            })
            setCidades(listaCidades)
        })
    }, [])

    useEffect(() => {
        if (cidade) {
            handleChangeAuto({ name: 'idcidade', value: cidade.value })
        }
    }, [cidade])

    return (
        <div className={classes.root}>
            <Grid container item xs={12} spacing={1} style={{ margin: 0 }}>
                <Grid item xs={12}>
                    <LinesTextField
                        required
                        fullWidth
                        autoComplete='new-password'
                        label="Nome"
                        name='nome'
                        value={props.fornecedor.nome}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <LinesTextField
                        fullWidth
                        autoComplete='new-password'
                        label="Endereço"
                        name='endereco'
                        value={props.fornecedor.endereco}
                        onChange={handleChange}
                        variant="outlined"
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <USearchField {...{ itens: cidades, value: cidade, setValue: setCidade, label: 'Cidade *' }} />
                </Grid>
            </Grid>
        </div>
    )
};

export default CadastroFornecedor;
