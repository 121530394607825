import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const whatappRepositorio = {

    postAvisoWpp: async (dto) => {
        const response = await api.post('/whatsapp/postPermissaoWhatsByPessoa', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    postBilheteByPessoa: async (dto) => {
        const response = await api.post('/whatsapp/postBilheteByPessoa', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
}

export default whatappRepositorio;
