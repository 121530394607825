import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import React from 'react';
import { useStyles } from './styles';

export const UFloatButton = props => {
    const classes = useStyles();
    const [hidden] = React.useState(false);

    const handleClick = () => {
        if (props.actions) {
            if (props.actions.length <= 0) {
                if (props.handleClick) {
                    props.handleClick()
                    return;
                }
            }
            if (props.setOpen) {
                props.setOpen(true);
            }
        }
    }

    const handleOpen = (action) => {
        props.setOpen(false);
        if (action.action) {
            action.action();
        }

    };

    const handleClose = () => {
        if (props.setOpen) {
            props.setOpen(false);
        }
    };

    return (
        <div className={classes.root}>
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={props.className ? props.className : classes.speedDial}
                hidden={hidden}
                onClick={() => handleClick()}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={props.setOpen ? () => props.setOpen(true) : null}
                open={props.open}
            >
                {props.actions.map(action => (
                    <SpeedDialAction
                        style={{ marginBottom: '5px', marginTop: '5px' }}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => handleOpen(action)}
                    />
                ))}
            </SpeedDial>
        </div>
    )
};

export default UFloatButton;
