import { Button, Container, Grid } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { EditHodometerGoogleMapsD } from "./GoogleMaps"
import { EditHodometerManualD } from "./Manual"

export const ViewHodometerDComponent = ({ viagem, hodometro, setType, editHodometro, typehodometro }) => {
    return (
        <>
            <Grid container item xs={12} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                <Grid item xs={6} style={{ marginTop: "-20px" }}>
                    <Button
                        onClick={() => setType(0)}
                        startIcon={<ArrowBackIosNewIcon />}
                        color="info"
                    >
                        Voltar
                    </Button>
                </Grid>
            </Grid>
            <Container item xs={12} maxWidth={"sm"}>
                {hodometro.hodometro.is_manual ? (
                    <EditHodometerManualD viagemid={viagem.id} editHodometro={editHodometro} hodometro={hodometro} setType={setType} />
                ) : (
                    <EditHodometerGoogleMapsD viagemid={viagem.id} editHodometro={editHodometro} hodometro={hodometro} setType={setType} />
                )}
            </Container>
        </>
    )
}