import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close, WarningOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import ULoaderVoo from '../../../components/uloadervoo';
import UMaskTextfield from '../../../components/umasktextfield';
import pessoaRepositorio from '../../../repositorios/pessoa';
import validatorCPF from '../../../utils/cpfValidator';
import { useStyles } from './styles';

export const CheckUpCadastro = props => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [errorValue, setErrorValue] = useState({
        cpf: false,
        datanascimento: false,
        telefone: false,
    })

    const [dtoPessoa, setDtoPessoa] = useState({
        telefone: props?.data?.telefone || '',
        cpf: props?.data?.cpf || '',
        datanascimento: props?.data?.dataNascimento || new Date,
    });

    const handleCloseDialogCheckUp = () => {
        props.setOpen(false);
    }

    const handleChangeDtoPessoa = (event) => {
        setDtoPessoa({
            ...dtoPessoa,
            [event.target.name]: event.target.value
        })
    }

    const handleSaveUpdateDados = async () => {
        setIsLoading(true)
        if (dtoPessoa.telefone === "(__)_____-____" || dtoPessoa.telefone === null || dtoPessoa.telefone === 'undefined' || dtoPessoa.telefone.trim() === "") {
            props.setSnackVariant('error');
            props.setSnackMessage('O campo telefone não pode ser vazio.');
            props.setSnackVisible({ visible: true });
            setIsLoading(false)
            return;
        }

        if (dtoPessoa.cpf === '') {
            props.setSnackVariant('error');
            props.setSnackMessage('O campo CPF não pode ser vazio.');
            props.setSnackVisible({ visible: true });
            setIsLoading(false)
            return;
        }


        if (!validatorCPF(dtoPessoa.cpf)) {
            props.setSnackVariant('error');
            props.setSnackMessage('CPF inválido.');
            props.setSnackVisible({ visible: true });
            setIsLoading(false)
            return;
        }

        if (dtoPessoa.datanascimento === null) {
            props.setSnackVariant('error');
            props.setSnackMessage('O campo data de nascimento não pode ser vazio.');
            props.setSnackVisible({ visible: true });
            setIsLoading(false)
            return;
        }


        try {
            let response = await pessoaRepositorio.updatePessoaANAC(dtoPessoa);
            if (response.status === 207) {
                props.setSnackVariant('error');
                props.setSnackMessage(response.data.message);
                props.setSnackVisible({ visible: true });
                setIsLoading(false)
                return
            }
            if (response.status === 202) {
                props.setSnackVariant('info');
                props.setSnackMessage(response.data.message);
                props.setSnackVisible({ visible: true });
            } else {
                props.setSnackVariant('success');
                props.setSnackMessage(response.data.message);
                props.setSnackVisible({ visible: true });
            }

            let passageiro = {
                idpassageiro: props?.passageiro.idpassageiro,
                idcentrocusto: props?.passageiro.idcentrocusto,
                dataNascimento: dtoPessoa.datanascimento,
                centroCusto: props?.passageiro.centroCusto,
                email: props?.passageiro.email,
                faixaEtaria: props?.passageiro.faixaEtaria,
                genero: props?.passageiro.genero,
                nome: props?.passageiro.nome,
                porcentagem: props?.passageiro.porcentagem,
                sobrenome: props?.passageiro.sobrenome,
                cpf: dtoPessoa.cpf,
                telefone: dtoPessoa.telefone
            };
            props?.setPassageiro(passageiro)
            let paxs = props?.passageiros;
            paxs[props?.indexPassageiro] = passageiro;
            props?.setPassageiros(paxs);

            handleCloseDialogCheckUp()
        } catch (e) {
            props.setSnackVariant('error');
            props.setSnackMessage('Falha ao atualizar dados.');
            props.setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setDtoPessoa({ ...dtoPessoa, idpessoa: props?.passageiro.idpassageiro || 0 })
    }, [props?.passageiro])

    return (
        <form >
            <Dialog
                open={props.open}
                onClose={handleCloseDialogCheckUp}
                maxWidth="sm"
                fullWidth
                fullScreen={responsive}
                hideBackdrop
            >
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                            <Typography>{props.data?.nome + ' ' + props.data?.sobrenome}</Typography>
                            <IconButton className={classes.button} onClick={handleCloseDialogCheckUp}>
                                <Close style={{ color: 'inherited' }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <WarningOutlined fontSize='medium' htmlColor='yellow' />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant='caption' >
                                        Segundo consta na Resolução 595 da ANAC, tornou-se <b>mandatório</b> o envio do CPF, DATA DE NASCIMENTO e
                                        TELEFONE do passageiro para a garantia do aéreo, portanto solicitamos o cadastro correto dos
                                        seguintes campos.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <UMaskTextfield
                                helperText="Formato de telefone incorreto"
                                mask="(99)99999-9999"
                                error={errorValue.telefone}
                                value={dtoPessoa.telefone}
                                required
                                label="Telefone"
                                style={{ marginLeft: '10px' }}
                                alwaysShowMask={false}
                                name="telefone"
                                onChange={handleChangeDtoPessoa}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UMaskTextfield
                                helperText="Formato de CPF incorreto"
                                alwaysShowMask={false}
                                error={errorValue.cpf}
                                maskChar=''
                                mask="999.999.999-99"
                                required
                                label="CPF"
                                value={dtoPessoa.cpf}
                                name='cpf'
                                onChange={handleChangeDtoPessoa}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.appbar}>
                    <Button className={classes.buttoncustom} onClick={() => handleSaveUpdateDados()}>Salvar</Button>
                </DialogActions>
            </Dialog>
        </form >
    )
};

export default CheckUpCadastro;
