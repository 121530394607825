import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    closeButton: {
        color: '#ffffff'
    },

    dialogTitle: {
        maxHeight: '3rem',
        background: '#c62828',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    ticket: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },

    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(7)
        }
    },
    buttonAtender: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'darkgreen',
        background: 'white',
        color: 'darkgreen',
        '&:hover': {
            borderColor: 'white',
            color: 'white',
            background: 'darkgreen',
        }
    },
    inline: {
        display: 'inline',
    },
    rootList: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    uselect: {
        width: 100
    },
    tituloAvatar: {
        margin: theme.spacing(0, 2)
    },
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    TableContainer: {
        maxHeight: 350
    },
    email: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    linha: {
        display: 'flex',
        flexDirection: 'row',
        // ['@media (max-width:480px)']: {
        //     flexDirection: 'column'
        // },
    },
    linha2: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        // ['@media (max-width:480px)']: {
        //     flexDirection: 'column',
        // },
    },
    linhaBotao: {
        display: 'flex',
        padding: theme.spacing(1, 0),
        flexDirection: 'row',
        justifyContent: 'flex-end',
        // ['@media (max-width:480px)']: {
        //     flexDirection: 'column',
        //     justifyContent: 'center',
        // },
    },
    flex1: {
        flex: 1
    },
    umulti: {
        width: '100%',
        marginLeft: theme.spacing(1)
    },
    button: {
        width: '100%',
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#810b11'
        },
    },
    buttonPDF: {
        marginRight: '10px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#c62828',
        background: 'white',
        color: '#c62828',
        '&:hover': {
            borderColor: 'white',
            color: 'white',
            background: '#c62828',
        }
    },
    button10: {
        background: 'linear-gradient(45deg, black, transparent)',
        color: 'white'
    }
}));



