import React, { useState } from "react";
import { Dialog, DialogContent, IconButton, AppBar, Toolbar, Box, useTheme, useMediaQuery, MobileStepper, Button, } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    img: {
        maxWidth: "100%",
        height: "auto",
        objectFit: "cover",
    },
}));

const Recibo = (props) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();

    const handleCloseModal = () => {
        props.setOpen(false);
    };

    // Initialize activeStep state
    const [activeStep, setActiveStep] = useState(0);

    // Create an array to hold the tutorialSteps
    const tutorialSteps = [
        { label: "Recibo Image 1", imgPath: props.despesa.urlrecibo },
        { label: "Recibo Image 2", imgPath: props.despesa.urlrecibo_2 },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Dialog open={props.open} onClose={handleCloseModal} fullScreen={responsive}    >
            <AppBar position="relative" style={{ background: "transparent", boxShadow: "none" }}      >
                <Toolbar>
                    <Box width="100%" justifyContent="flex-end" alignItems="center">
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <DialogContent className={classes.root} style={{ width: "auto", maxWidth: "500px", height: "500px" }}>
                {props.despesa.urlrecibo_2 ? (<img className={classes.img} src={tutorialSteps[activeStep].imgPath} alt={tutorialSteps[activeStep].label} />
                ) : (<img width="100%" height="auto" src={props.despesa.urlrecibo} alt="Imagem Recibo" object-fit="fill" />)}
            </DialogContent>
            {props.despesa.urlrecibo_2 ? (
                <MobileStepper
                    steps={tutorialSteps.length}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={<Button size="small" onClick={handleNext} disabled={activeStep === tutorialSteps.length - 1}>Próximo</Button>}
                    backButton={<Button size="small" onClick={handleBack} disabled={activeStep === 0}>Voltar</Button>}
                />
            ) : null}
        </Dialog>
    );
};

export default Recibo;
