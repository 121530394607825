import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import UCheckbox from '../UCheckbox';
import { useStyles } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(item, value, theme) {
    return {
        fontWeight:
            value.indexOf(item) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const UMultiSelect = props => {
    const classes = useStyles();
    const theme = useTheme();
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    const handleChange = event => {
        props.setValue(event.target.value);
    };

    return (
        <div>
            <FormControl className={classes.formControl} margin="dense" variant="outlined" fullWidth>
                <InputLabel id="demo-mutiple-chip-label" htmlFor="select-multiple-chip" ref={inputLabel}>{props.label}</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    fullWidth
                    disabled={props.disabled}
                    value={props.value || ''}
                    onChange={handleChange}
                    labelWidth={labelWidth}
                    renderValue={selected => (
                        <div className={classes.chips}>
                            {selected.map(value => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {props.itens.map(item => (
                        <MenuItem key={item} value={item} style={getStyles(item, props.value, theme)}>
                            <UCheckbox checked={props.value.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

export default UMultiSelect;
