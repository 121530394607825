import { Tooltip } from '@material-ui/core';
import { XGrid } from '@material-ui/x-grid';
import React, { useEffect, useState } from 'react';
import CustomNoRowsOverlay from '../../../../configuracao/email/lista/emptyList';

function ComprasConsolidadasGrid(props) {
    const [length, setLength] = useState(props.data.length)
    const columns = [
        { field: 'id', headerName: 'ID', width: 250, hide: true },
        {
            field: 'nfatura', headerName: 'Nº Fatura', width: 100,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.nfatura}>
                        <div>
                            {item.row.nfatura}
                        </div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'faturado', headerName: 'Faturado', width: 110,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.faturado}>
                        <div>{item.row.faturado}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'nome', headerName: 'Nome', width: 200,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.nome}>
                        <div>{item.row.nome}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'fornecedor', headerName: 'Fornecedor', width: 150,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.fornecedor}>
                        <div>{item.row.fornecedor}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'cid', headerName: 'Cidade', width: 100,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.cid}>
                        <div>{item.row.cidade}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'uf', headerName: 'Estado', width: 100,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.uf}>
                        <div>{item.row.uf}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'dataida', headerName: 'Data Ida', width: 110,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.dataida}>
                        <div>{item.row.dataida}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'datavolta', headerName: 'Data Volta', width: 110,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.datavolta}>
                        <div>{item.row.datavolta}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'valor', headerName: 'Valor', width: 105,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.valor}>
                        <div>{item.row.valor}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'diarias', headerName: 'Diárias', width: 80,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.diarias}>
                        <div>{item.row.diarias}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'origem', headerName: 'Origem', width: 140,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.origem}>
                        <div>{item.row.origem}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'destino', headerName: 'Destino', width: 140,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.destino}>
                        <div>{item.row.destino}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'retorno', headerName: 'Retorno', width: 85,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.retorno}>
                        <div>{item.row.retorno}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'descricao', headerName: 'Descrição', width: 200,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.descricao}>
                        <div>{item.row.descricao}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'centrocusto', headerName: 'Centro de Custo', width: 135,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.centrocusto}>
                        <div>{item.row.centrocusto}</div>
                    </Tooltip>
                </>
            )
        },
        {
            field: 'tipo', headerName: 'Tipo', width: 80,
            renderCell: (item) => (
                <>
                    <Tooltip title={item.row.tipo}>
                        <div>{item.row.tipo}</div>
                    </Tooltip>
                </>
            )
        }
    ];

    useEffect(() => {
        setLength(props.data.length)
    }, [props.data.length])

    return (
        <>
            <div style={{ height: '52vh', width: '100%' }}>
                <XGrid
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    id={Math.random()}
                    rows={props.data}
                    columns={columns}
                    loading={props.isLoading}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    hideFooterRowCount
                    pagination
                    rowsPerPageOptions={[50, 100, 1000, length]}
                />
            </div>
        </>
    )
}

export default ComprasConsolidadasGrid;
