import { Button, IconButton, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import React, {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { VariableSizeList } from "react-window";
import LinesTextField from "../LinesTextField";
import "../../fonts/fonts.css";
import ClearIcon from "@material-ui/icons/Clear";
import { useStyles } from "./styles";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }
    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];

export const USearchFieldHotel = (props) => {
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [customNoOptionsText, setCustomNoOptionsText] = useState('');

  const cName = { className: "MuiAutocomplete-inputRootDense" };

  return (
    <>
      {responsive ? (
        <Autocomplete
          disableListWrap
          options={props.itens}
          name={props.name}
          clearText="Limpar"
          disabled={props.disabled}
          className={classes.root}
          getOptionLabel={(option) =>
            option.label === undefined ? "" : option?.label
          }
          style={{ width: "100%", ...props.style }}
          // noOptionsText={props.msgAlt ? props.msgAlt : "Não há nada para selecionar"}
          noOptionsText={customNoOptionsText}
          value={props.value === null ? null : props.value}
          renderGroup={renderGroup}
          ListboxComponent={ListboxComponent}
          onChange={(event, newValue) => {
            if (props.setValue) {
              props.setValue(newValue);
            }
            if (props.onChange && newValue) {
              props.onChange(props.name, newValue.value, newValue);
            }
            if (props.setValueArray) {
              let array = [...props.valueArray];
              if (props.tipoArray) {
                array[props.index][props.tipoArray] = newValue;
                props.setValueArray(array);
                return;
              }
              array[props.index] = newValue;
              props.setValueArray(array);
            }
            if (props.hotel == true && props.type == null) {
              props.setIndex(props.index);
              props.handleClickRoom();
            }
            if (props.hotel == true && props.type == "order") {
              props.handleChangeOrder();
            }
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref} className={classes.textfield}>
              <input
                type="text"
                {...params.inputProps}
                required={props.required}
                placeholder={props.label}
                style={{
                  width: "100%",
                  borderWidth: "0",
                  boxShadow: "none",
                  fontSize: "1.25rem",
                  fontWeight: "600",
                  paddingInlineEnd: "3rem",
                  color: "#1A1A1A",
                  outline: "0px",
                  fontFamily: "HindBold"
                }}
              />
              {props.value && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear selection"
                    onClick={() => {
                      props.setValue && props.setValue(null); // Limpa o valor selecionado
                    }}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )}
            </div>
          )}
          renderOption={(option) => (
            <Tooltip title={option.label}>
              <div>
                <Typography
                  style={{ fontSize: "14px", fontFamily: "HindBold" }}
                  noWrap
                >
                  {option.label}
                </Typography>
                <Typography style={{ fontSize: "9px" }} noWrap>
                  {option.subtitle}
                </Typography>
              </div>
            </Tooltip>
          )}
          filterOptions={(options, state) => {
            // Só mostra as opções se o termo de pesquisa tiver pelo menos 3 caracteres
            if (state.inputValue.trim().length < 3) {
              setCustomNoOptionsText('Digite pelo menos 3 letras');
            } else {
              setCustomNoOptionsText('Nenhum resultado encontrado');
            }
            if (state.inputValue.length < 3) {
              return [];
            }

            const normalizeText = text => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

            const inputValueNormalized = normalizeText(state.inputValue);

            return options.filter((option) =>
              normalizeText(option.label).includes(inputValueNormalized)
            ).sort((a, b) => {
              // Verifica se a opção é exatamente igual ao termo de busca
              const isExactMatchA = normalizeText(a.label) === inputValueNormalized;
              const isExactMatchB = normalizeText(b.label) === inputValueNormalized;

              // Prioriza a opção que é uma correspondência exata
              if (isExactMatchA && !isExactMatchB) {
                return -1; // a vem antes de b
              } else if (!isExactMatchA && isExactMatchB) {
                return 1; // b vem antes de a
              }
              return 0; // Sem mudança de ordem para opções que têm o mesmo status de correspondência
            }).slice(0, 5);
          }}
        />
      ) : (
        <>
          <Autocomplete
            disableListWrap
            options={props.itens}
            name={props.name}
            clearText="Limpar "
            disabled={props.disabled}
            className={classes.root}
            getOptionLabel={(option) =>
              option.label === undefined ? "" : option?.label
            }
            width={"100%"}
            style={props.style}
            // noOptionsText={props.msgAlt ? props.msgAlt : "Não há nada para selecionar"}
            noOptionsText={customNoOptionsText}
            value={props.value === null ? null : props.value}
            renderGroup={renderGroup}
            ListboxComponent={ListboxComponent}
            onChange={(event, newValue) => {
              if (props.setValue) {
                props.setValue(newValue);
              }
              if (props.onChange && newValue) {
                props.onChange(props.name, newValue.value, newValue);
              }
              if (props.setValueArray) {
                let array = [...props.valueArray];
                if (props.tipoArray) {
                  array[props.index][props.tipoArray] = newValue;
                  props.setValueArray(array);
                  return;
                }
                array[props.index] = newValue;
                props.setValueArray(array);
              }
              if (props.hotel == true && props.type == null) {
                props.setIndex(props.index);
                props.handleClickRoom();
              }
              if (props.hotel == true && props.type == "order") {
                props.handleChangeOrder();
              }
            }}
            renderInput={(params) => (
              <LinesTextField
                {...params}
                className={classes.textfield}
                required={props.required}
                label={props.label}
                variant="outlined"
                // defaultValue={props.defaultValue ? props.defaultValue.label : null}
                margin="dense"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  ...cName,
                  ...[props.InputProps ? props.InputProps : {}],
                }}
              />
            )}
            renderOption={(option) => (
              <Tooltip title={option.label}>
                <div>
                  <Typography style={{ fontSize: "14px", fontFamily: "HindBold" }} noWrap>
                    {option.label}
                  </Typography>
                  <Typography style={{ fontSize: "9px" }} noWrap>
                    {option.subtitle}
                  </Typography>
                </div>
              </Tooltip>
            )}
            filterOptions={(options, state) => {
              if (state.inputValue.trim().length < 3) {
                setCustomNoOptionsText('Digite pelo menos 3 letras');
              } else {
                setCustomNoOptionsText('Nenhum resultado encontrado');
              }
              if (state.inputValue.length < 3) {
                return [];
              }
  
              const normalizeText = text => text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  
              const inputValueNormalized = normalizeText(state.inputValue);
  
              return options.filter((option) =>
                normalizeText(option.label).includes(inputValueNormalized)
              ).sort((a, b) => {
                // Verifica se a opção é exatamente igual ao termo de busca
                const isExactMatchA = normalizeText(a.label) === inputValueNormalized;
                const isExactMatchB = normalizeText(b.label) === inputValueNormalized;
  
                // Prioriza a opção que é uma correspondência exata
                if (isExactMatchA && !isExactMatchB) {
                  return -1; // a vem antes de b
                } else if (!isExactMatchA && isExactMatchB) {
                  return 1; // b vem antes de a
                }
                return 0; // Sem mudança de ordem para opções que têm o mesmo status de correspondência
              }).slice(0, 5);
            }}

          />
        </>
      )}
    </>
  );
};

export default USearchFieldHotel;
