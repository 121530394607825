import { Button, Card, CardActions, Dialog, DialogContent, Fade, Grid, Grow, IconButton, TextField, Toolbar, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import LogoEmail from '../../../../images/linestur_homem_email.svg';
import { useStyles } from './styles';

const EmailTextField = withStyles({
    root: {
        '& .MuiInputBase-root': {
            color: 'black'
        },
        '& label': {
            color: 'inherited',
        },
        '& label.Mui-focused': {
            color: '#ac58aa',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#ac58aa',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'silver',
            },
            '&:hover fieldset': {
                borderColor: 'silver',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ac58aa',
            },
        },
    }
})(TextField)

export const EnviarEmailAereo = props => {
    const classes = useStyles();
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChanged = event => {
        if (props.setValues) {
            props.setValues({
                ...props.values,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleClose = () => {
        props.setOpen(false);
        if (props.setValues) {
            props.setValues({
                email: '',
                cc: ''
            })
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullscreen}
            maxWidth="sm"
            transitionDuration={150}
            TransitionComponent={!fullscreen ? Grow : Fade}
        >
            <DialogContent className={classes.content}>
                <Toolbar className={classes.toolbar}>
                    <IconButton onClick={handleClose} style={{ marginTop: theme.spacing(2) }}>
                        <Close style={{ color: 'white' }} />
                    </IconButton>
                </Toolbar>
                <Grid container item xs={12} style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                }}>
                    <Grid item xs={10} style={{ marginTop: theme.spacing(-15), marginBottom: theme.spacing(5), zIndex: 100 }}>
                        <Card style={{ padding: 5, minHeight: 300 }} elevation={3}>
                            <Grid container item xs={12}>
                                <Grid item xs={12} style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center'
                                }}>
                                    <img
                                        className={classes.media}
                                        src={LogoEmail}
                                    />
                                </Grid>
                                <CardActions style={{ width: '100%' }}>
                                    <Grid container spacing={2} item xs={12} style={{ padding: theme.spacing(2) }}>
                                        <Grid item xs={12} style={{
                                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <EmailTextField fullWidth name="email" value={props.values.email || ''} onChange={handleChanged} variant="outlined" margin="dense" label="E-mail" />
                                        </Grid>
                                        <Grid item xs={12} style={{
                                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <EmailTextField fullWidth name="cc" value={props.values.cc || ''} onChange={handleChanged} variant="outlined" margin="dense" label="CC" />
                                        </Grid>
                                        <Grid item xs={12} style={{
                                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                                        }}>
                                            <Button fullWidth onClick={props.onClick || null}
                                                className={classes.button}>
                                                Enviar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};

export default EnviarEmailAereo;
