import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const aereoBilhete = {

    post: async bilhete => {
        const response = await api.post('/AereoBilhete', bilhete, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    getCreditCards: async () => {
        const response = await api.get('/AereoBilhete/getCreditCards', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    getReserveCard: async (idreserva) => {
        const response = await api.get(`/AereoBilhete/getReserveCard/${idreserva}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    putSelectedCreditCard: async (creditCard) => {
        const response = await api.put('/AereoBilhete/putSelectedCreditCard', creditCard, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    postSelectedCreditCard: async (creditCard) => {
        const response = await api.post('/AereoBilhete/postOpcoesCartao', creditCard, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    putSaveReserveCard: async (dto) => {
        const response = await api.put('/AereoBilhete/putSaveReserveCard', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

}

export default aereoBilhete;
