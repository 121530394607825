import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    boxCustom: {
        width: '-webkit-fill-available',
        alignSelf: 'center'
    },

    buttonEmissao: {
        background: green[600],
        color: '#ffffff',
        width: '100%',
        '&:hover': {
            background: green[800]
        }
    },

    gridSpace: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    gridCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }



}));
