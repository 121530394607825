import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    //ALL

    button: {
        background: '#c62828',
        color: '#ffffff',
        '&:hover': { background: '#b91119', }
    },

    appBar: {
        position: 'relative',
        backgroundColor: "#c62828"
    },

    hrline: {
        width: "100%",
        backgroundColor: "#ECECEC",
        height: "1px",
        border: "none"
    },

    // Primeira pagina - Pesquisa

    searchPage_backgroundTop: {
        background: 'linear-gradient(to bottom, #c62828, #c62828, #B62C2C46)',
        textAlign: "center",
        justifyContent: "center",
        height: "70px",
        padding: "20px"
    },

    searchPage_card: {
        padding: "25px",
        marginTop: "-44px",
        padding: "18px",
        width: '100%',
        height: 'auto',
        flexShrink: 0,
        fill: '#FFF',
        top: "40%",
        filter: 'drop-shadow(0px 0px 0.8999999761581421px rgba(0, 0, 0, 0.25))',
        borderRadius: '10px 10px 10px 10px', // Adicionando sombra nos outros lados
        borderBottom: 'none', // Removendo a sombra inferior,
        backgroundColor: "#FFFFFF",
        filter: 'none',  // Remove a sombra
        boxShadow: "0px 0px 1px 1px rgba(0, 0, 0, 0.1)",
        borderBottom: 'none'
    },

    searchPage_iconW5margin: {
        color: "#c62828",
        alignSelf: "center",
        marginRight: "5px"
    },

    //

    listHotels_backgroundTop: {
        backgroundColor: '#c62828', textAlign: "center", justifyContent: "center"
    },

    listHotels_accFont1: {
        color: "white",
        lineHeight: "24px",
        display: "inline-flex",
        alignItems: "center",
        fontFamily: "HindBold"
    },

    listHotels_ButtonPrimary: {
        fontWeight: "600",
        fontSize: "15px",
        color: "white",
        backgroundColor: "#c62828",
        border: "none",
        cursor: "pointer",
        padding: "10px 20px",
        borderRadius: "5px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s, transform 0.3s",
        width: "100%"
    },

    listHotels_ButtonSecondary: {
        fontWeight: "600",
        lineHeight: "15px",
        fontSize: "15px",
        fontFamily: "HindBold",
        alignItems: "center",
        color: "#131313"
    },

    listHotels_Button: {
        color: "#353535", fontWeight: "500", fontSize: "10px",
        fontWeight: "500",
        backgroundColor: "#F1F1F1", // Cor de fundo cinza
        padding: "8px 16px", // Espaçamento interno para parecer um botão
        borderRadius: "16px", // Bordas arredondadas
        cursor: "pointer", // Muda o cursor para indicar que é clicável
        display: "inline-block", // Faz com que o fundo cinza se ajuste ao texto
        transition: "background-color 0.3s", // Adiciona transição para hover
        "&:hover": {
            backgroundColor: "#BDBDBD", // Muda a cor de fundo ao passar o mouse
        }
    },

    listHotels_card: {
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
        borderRadius: "20px",
        marginTop: "20px",
        position: 'relative',
    },

    listHotels_cardimg: {
        inset: '0px',
        boxSizing: 'border-box',
        padding: '0px',
        border: 'none',
        margin: 'auto',
        display: 'block',
        width: '100%',
        height: '100%',
        minHeight: '184px',
        borderRadius: '20px 20px 0px 0px',
        clipPath: "margin-box",
        position: 'block',
        zIndex: 1,
    }

}))
