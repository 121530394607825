import { FormControlLabel, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import LinesTextField from '../../../../../components/LinesTextField';
import UCheckbox from '../../../../../components/UCheckbox';
import USelect from '../../../../../components/uselect';
import { useStyles } from './styles';

export const CadastroProduto = props => {
    const classes = useStyles();
    const [value, setValue] = useState(props.produto.idpoliticaviagemproduto ? props.produto.tipoproduto : 0);

    const handleChangeCheckbox = event => {
        props.setProduto({
            ...props.produto,
            [event.target.name]: event.target.checked
        })
    }

    const handleChange = event => {
        props.setProduto({
            ...props.produto,
            [event.target.name]: event.target.value
        })
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<UCheckbox name="internacional" onChange={handleChangeCheckbox} checked={props.produto.internacional} />}
                        label="Internacional"
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <USelect {...{ itens: props.produtos, value: value, setValue: setValue }} name="tipoproduto" onChange={handleChange} label="Produto" />
                </Grid>
                <Grid item xs={6}>
                    <LinesTextField variant="outlined" margin="dense" name="palavrachave" value={props.produto.palavrachave || ''} onChange={handleChange} label="Tipo" />
                </Grid>
                <Grid item xs={6}>
                    <LinesTextField variant="outlined" margin="dense" name="limite" value={props.produto.limite || ''} onChange={handleChange} label="Limite" />
                </Grid>
            </Grid>
        </div>
    )
};

export default CadastroProduto;
