import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const usuarioRepositorio = {

    getDadosSessao: async () => {
        const response = await api.get('/usuario/GetDadosSessao', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    getAll: async () => {
        const response = await api.get('/usuario/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    isAuthenticated: async () => {
        const response = await api.get('/token', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    get: async idusuario => {
        const response = await api.get('/usuario/' + idusuario, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getSearchField: async () => {
        const response = await api.get('/usuario/GetSearchField', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response.data;
    }
}

export default usuarioRepositorio;
