import React, { useEffect, useState } from "react";
import usuarioRepositorio from "../../../repositorios/usuario";
import { BookingListDesktop } from "./BookingListDesktop";
import { BookingListMobile } from "./BookingListMobile";
import { useStyles } from "./styles";
import { useLayoutEffect } from "react";
import reservaRepositorio from "../../../repositorios/reserva";
import { MenuAction } from "./MenuAction";
import { ChangeCreatedDate } from "./ChangeCreatedDate";
import { SendEmail } from "./SendEmail";
import CustomizedSnackbars from "../../../components/snackbar";
import ULoaderVoo from "../../../components/uloadervoo";
import { HistoryBooking } from "./HistoryBooking";
import { useSelector } from "react-redux";
import { ConfirmCancelBooking } from "./ConfirmCancelBooking";
import { BookingIssue } from "./BookingIssue";
import aereoRepositorio from "../../../repositorios/aereo";
import { SearchBookingForStatus } from "./BookingStatusSearch";
import Cookiess from "universal-cookie";
import Cookies from "js-cookie";
import DialogAccept from "../../reservas/lista/DialogAccept";
import DialogAcceptAllReservesStatusPending from "./ApproveAllBooking";
import { Button } from "@material-ui/core";
import { HotelIssue } from "./HotelIssue";
import { HotelCancel } from "./HotelCancel";

export const BookingList = (props) => {
    const responsive = props.responsive;
    const classes = useStyles();
    const cookies = new Cookiess();
    const permissaoDashboard = useSelector((state) => state.permissao.permissaoDashboard);
    const [openMenuAction, setOpenMenuAction] = useState(false);
    const [anchorMenuAction, setAnchorMenuAction] = useState(null);
    const [openChangeCreatedDate, setOpenChangeCreatedDate] = useState(false);
    const [openCancelBooking, setOpenCancelBooking] = useState(false);
    const [openSendEmail, setOpenSendEmail] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const [openBookingIssue, setOpenBookingIssue] = useState(false);
    const [openHotelIssue, setOpenHotelIssue] = useState(false);
    const [openHotelCancel, setOpenHotelCancel] = useState(false);
    const [dataBookingList, setDataBookingList] = useState([]);
    const [dataBookingListSearch, setDataBookingListSearch] = useState([])
    const [dataBookingListTotal, setDataBookingListTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [bookingSeletedAll, setBookingSeletedAll] = useState({});
    const [showTuneStatus, setShowTuneStatus] = useState(false);
    const [openModalAccept, setOpenModalAccept] = useState(false);

    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');

    //ver se eh necessario
    const [dadosUsuario, setDadosUsuario] = useState({});

    const [valueTuneStatus, setValueTuneStatus] = useState("0");
    const [valueTuneNome, setValueTuneNome] = useState("0");

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [anchorAcao, setAnchorAcao] = useState(null);
    const [parametro, setParametro] = useState("");
    const [filter, setFilter] = useState({
        parametro: parametro,
        stateparametersearch: 0,
        stateselectstatus: 0
    });
    //ok
    const getDadosSessao = async () => {
        let response = await usuarioRepositorio.getDadosSessao();
        setDadosUsuario(response.data);
    };

    const handleClickOpenMenuAction = () => { setOpenMenuAction(true) }
    const handleClickCloseMenuAction = () => { setOpenMenuAction(false) }

    const handleClickOpenChangeCreatedDate = () => { setOpenChangeCreatedDate(true) };
    const handleClickCloseChangeCreatedDate = () => { setOpenChangeCreatedDate(false) };

    const handleClickOpenHistory = () => { setOpenHistory(true) };
    const handleClickCloseHistory = () => { setOpenHistory(false) };

    const handleClickOpenSendEmail = () => { setOpenSendEmail(true) };
    const handleClickCloseSendEmail = () => { setOpenSendEmail(false) };

    const handleClickOpenCancelBooking = () => { setOpenCancelBooking(true) };
    const handleClickCloseCancelBooking = () => { setOpenCancelBooking(false) };

    const handleClickOpenBookingIssue = () => { setOpenBookingIssue(true) };
    const handleClickCloseBookingIssue = () => { setOpenBookingIssue(false) };

    const handleClickOpenHotelIssue = () => { setOpenHotelIssue(true) };
    const handleClickCloseHotelIssue = () => { setOpenHotelIssue(false) };

    const handleClickOpenHotelCancel = () => { setOpenHotelCancel(true) };
    const handleClickCloseHotelCancel = () => { setOpenHotelCancel(false) };

    const handleClickAuthorizeNegate = async (acao) => {
        try {
            setIsLoading(true);
            let dtoAutorizarNegar = {
                idreserva: props.bookingSelected.idreserva,
                acao,
                sessionId: cookies.get("sessionId"),
            };
            let response = await reservaRepositorio.autorizarNegarReserva(dtoAutorizarNegar);
            if (response.status === "400") {
                setSnackMessage('Não possui permissão.');
                setSnackVariant('warning');
                setSnackState({ visible: true });
                setIsLoading(false);
                return;
            }
            setSnackMessage(acao === 1 ? "Reserva aprovada." : "Reserva recusada.");
            setSnackVariant('success');
            setSnackState({ visible: true });
            getBookingList()
            setOpenHistory(false);
        } catch (e) {
            setSnackMessage(acao === 1 ? "Erro ao tentar aprovar." : "Erro ao tentar negar.");
            setSnackVariant('error');
            setSnackState({ visible: true });
        } finally {
            // await loadReserva();
            setIsLoading(false);
        }
    };

    const handleChangeTuneStatusValue = (event) => {
        setValueTuneStatus(event.target.value);
        Cookies.set("FilterStatus", event.target.value);
        SearchParametro()
    };

    const handleChangeTuneNomeValue = (event) => {
        setValueTuneNome(event.target.value);
        SearchParametro()
    };

    const handleClickTuneStatusOpen = () => {
        setShowTuneStatus(true);
    };

    const onPesquisarChange = (e) => {
        setParametro(e.target.value.toLowerCase())
    }

    useEffect(() => {
        SearchParametro()
    }, [parametro])

    const loadFilterMenu = async () => {
        if (Cookies.get("FilterStatus") === undefined) {
            Cookies.set("FilterStatus", "0");
        } else {
            let teste = Cookies.get("FilterStatus");
            setValueTuneStatus(teste);
            return;
        }
    }

    const handleSyncReservas = () => {

    }

    const handleClickModalAccept = () => {
        setOpenModalAccept(true);
    };

    const handleChangeRowsPerPage = () => {

    }

    const handleModalBookingOpen = () => {

    }

    const handleActionMenuButton = () => {

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const getBookingList = async (page = 1) => {
        try {
            props.setIsLoading(true);

            let filtro = {
                parametro: "",
                stateparametersearch: 0,
                stateselectstatus: 0
            };
            let paginated = {
                sessionId: cookies.get('sessionId'),
                page: page,
                itensperpage: rowsPerPage
            }
            let dto = {
                pagination: paginated,
                filter: filtro
            }
            let response = await reservaRepositorio.getAll_v2(dto);
            setDataBookingList(response.data.reservas);
            setDataBookingListTotal(response.data.total);
        } catch (e) {
        } finally {
            props.setIsLoading(false);
        }
    };

    const SearchParametro = (e) => {
        let status = Cookies.get("FilterStatus");
        let resultadoPesquisa = dataBookingList.filter((item) => {
            if (item.tipo == 1) {
                return (
                    (status === "0" || status === undefined ? true : item.status + "" === status) &&
                    (item.assunto?.toLowerCase().includes(parametro) ||
                        item.idreservatenant?.toString().includes(parametro) ||
                        item.localizador.toLowerCase().includes(parametro) ||
                        (valueTuneNome === "0" ? item.pessoas.some((item) => `${item.nomecompleto}`.toLowerCase().includes(parametro)) : item.solicitante.toLowerCase().includes(parametro)) ||
                        item.pessoas.some((item) => item.centrodecusto?.toLowerCase().includes(parametro)))
                );
            }
            if (item.tipo == 2) {
                return (
                    (status === "0" || status === undefined
                        ? true
                        : item.status + "" === status) &&
                    (item.assunto?.toLowerCase().includes(parametro) ||
                        item.idreservatenant.toString().includes(parametro) ||
                        (valueTuneNome === "0" ? item.pessoas.some((item) => `${item.nomecompleto}`.toLowerCase().includes(parametro)) : item.solicitante.toLowerCase().includes(parametro)) ||
                        item.pessoas.some((item) => item.centrodecusto?.toLowerCase().includes()))
                );
            }
            if (item.tipo == 3) {
                return (
                    (status === "0" || status === undefined
                        ? true
                        : item.status + "" === status) &&
                    (item.assunto?.toLowerCase().includes(parametro) ||
                        item.idreservatenant.toString().includes(parametro) ||
                        // ||
                        (valueTuneNome === "0" ? item.pessoas?.some((item) => `${item.nomecompleto}`.toLowerCase().includes(parametro)) : item.solicitante.toLowerCase().includes(parametro)) 
                        // ||
                        // item.pessoas?.some((item) => item.centrodecusto?.toLowerCase().includes())
                    )
                );
            }

        });
        setDataBookingListSearch(resultadoPesquisa);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) { return -1; }
        if (b[orderBy] > a[orderBy]) { return 1; }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const definirAssunto = (assunto) => {
        if (!assunto) { return ""; }
        const textos = assunto.split("<br />");
        return (<div>{textos.map((item) => (<div>{item}</div>))}</div>);
    };

    const handleClickSincronizarAereo = async (reserva) => {
        try {
            props.setIsLoading(true);
            aereoRepositorio.sincronizarAereo(props.bookingSelected.idreserva);
            setSnackMessage("Sincronização concluida.");
            setSnackState({ visible: true });
            setSnackVariant("success");
            await getBookingList();
        } catch (e) {
            setSnackMessage('Falha ao sincronizar');
            setSnackState({ visible: true });
            setSnackVariant("error");
        } finally {
            props.setIsLoading(false)
        }
    };

    useEffect(() => {
        loadFilterMenu()
        getDadosSessao()
    }, []);

    useEffect(() => {
        getBookingList()
    }, []);

    useEffect(() => {
        if (props.isUpdateTicket === true) getBookingList()
        props.setIsUpdateTicket(false)
    }, [props.isUpdateTicket]);

    return (
        <>
            <ULoaderVoo isLoading={isLoading} />
            <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
            {openBookingIssue && (<BookingIssue {...{
                open: openBookingIssue,
                handleClickCloseBookingIssue,
                responsive,
                isLoading,
                setIsLoading,
                bookingSelected: props.bookingSelected,
                setSnackMessage,
                setSnackState,
                setSnackVariant,
            }} />)}
            {openHotelIssue && (<HotelIssue {...{
                open: openHotelIssue,
                handleClickCloseHotelIssue,
                responsive,
                bookingSelected: props.bookingSelected,
                setSnackMessage,
                setSnackState,
                setSnackVariant,
                getBookingList,
                handleClickCloseHotelIssue
            }} />)}
            {openHotelCancel && (<HotelCancel {...{
                open: openHotelCancel,
                handleClickCloseHotelCancel,
                responsive,
                bookingSelected: props.bookingSelected,
                setSnackMessage,
                permissaoDashboard,
                setSnackState,
                setSnackVariant,
                getBookingList,
                handleClickCloseHotelCancel
            }} />)}

            {openCancelBooking && (<ConfirmCancelBooking {...{
                open: openCancelBooking,
                handleClickCloseCancelBooking,
                isLoading,
                setIsLoading,
                bookingSelected: props.bookingSelected,
                setSnackMessage,
                setSnackState,
                setSnackVariant,
                getBookingList
            }} />)}
            {openHistory && (<HistoryBooking {...{
                open: openHistory,
                handleClickCloseHistory,
                responsive,
                bookingSelected: props.bookingSelected,
                permissaoDashboard,
                setSnackMessage,
                setSnackState,
                setSnackVariant,
                isLoading,
                setIsLoading,
                handleClickAuthorizeNegate
            }} />)}
            {openChangeCreatedDate && (<ChangeCreatedDate {...{
                open: openChangeCreatedDate,
                setOpen: setOpenChangeCreatedDate,
                handleClickCloseChangeCreatedDate,
                responsive,
                bookingSelected: props.bookingSelected,
                setSnackMessage,
                setSnackState,
                setSnackVariant,
                isLoading,
                setIsLoading
            }} />)}
            {openSendEmail && (<SendEmail {...{
                open: openSendEmail,
                setOpen: setOpenSendEmail,
                handleClickCloseSendEmail,
                responsive,
                bookingSelected: props.bookingSelected,
                setSnackMessage,
                setSnackState,
                setSnackVariant,
                isLoading,
                setIsLoading
            }} />)}
            {openMenuAction && (<MenuAction {...{
                open: openMenuAction,
                setOpen: handleClickCloseMenuAction,
                anchor: anchorMenuAction,
                handleClickOpenChangeCreatedDate,
                handleClickOpenSendEmail,
                handleClickOpenHistory,
                bookingSelected: props.bookingSelected,
                permissaoDashboard,
                handleClickOpenCancelBooking,
                handleClickOpenBookingIssue,
                handleClickOpenHotelIssue,
                handleClickOpenHotelCancel,
                handleClickSincronizarAereo,
            }} />)}
            {responsive ? (
                <BookingListMobile {...{
                    dadosUsuario,
                    setPage,
                    stableSort,
                    page,
                    valueTuneStatus,
                    handleClickTuneStatusOpen,
                    onPesquisarChange,
                    handleClickModalAccept,
                    handleChangePage,
                    rowsPerPage,
                    handleChangeRowsPerPage,
                    dataBookingList,
                    getComparator,
                    handleModalBookingOpen,
                    handleActionMenuButton,
                    definirAssunto,
                    handleSyncReservas,
                    handleClickOpenMenuAction,
                    setAnchorMenuAction,
                    setBookingSeletedAll,
                    dataBookingListTotal,
                    showTuneStatus,
                    setShowTuneStatus,
                    valueTuneNome,
                    parametro,
                    dataBookingListSearch
                }}
                    handleClickOpenDialogBookingTicket={props.handleClickOpenDialogBookingTicket}
                    handleClickOpenDialogBookingRequestTicket={props.handleClickOpenDialogBookingRequestTicket}
                    handleClickOpenDialogHotelTicket={props.handleClickOpenDialogHotelTicket}
                    setBookingSelected={props.setBookingSelected}
                />
            ) : (
                <BookingListDesktop {...{
                    dadosUsuario,
                    setPage,
                    stableSort,
                    page,
                    valueTuneStatus,
                    handleClickTuneStatusOpen,
                    onPesquisarChange,
                    handleClickModalAccept,
                    handleChangePage,
                    rowsPerPage,
                    handleChangeRowsPerPage,
                    dataBookingList,
                    getBookingList,
                    setPage,
                    getComparator,
                    handleModalBookingOpen,
                    handleActionMenuButton,
                    definirAssunto,
                    handleSyncReservas,
                    handleClickOpenMenuAction,
                    setAnchorMenuAction,
                    setBookingSeletedAll,
                    dataBookingListTotal,
                    showTuneStatus,
                    setShowTuneStatus,
                    valueTuneNome,
                    parametro,
                    dataBookingListSearch
                }}
                    handleClickOpenDialogBookingTicket={props.handleClickOpenDialogBookingTicket}
                    handleClickOpenDialogBookingRequestTicket={props.handleClickOpenDialogBookingRequestTicket}
                    handleClickOpenDialogHotelTicket={props.handleClickOpenDialogHotelTicket}
                    setBookingSelected={props.setBookingSelected}
                />
            )}
            <DialogAcceptAllReservesStatusPending
                open={openModalAccept}
                setOpenModalAccept={setOpenModalAccept}
                getBookingList={getBookingList}
                {...{
                    setSnackMessage,
                    setSnackState,
                    setSnackVariant
                }}
            />
            <SearchBookingForStatus
                SearchBookingForStatus={SearchBookingForStatus}
                setShowTuneStatus={setShowTuneStatus}
                showTuneStatus={showTuneStatus}
                handleChangeTuneNomeValue={handleChangeTuneNomeValue}
                valueTuneNome={valueTuneNome}
                valueTuneStatus={valueTuneStatus}
                handleChangeTuneStatusValue={handleChangeTuneStatusValue}
            />
        </>
    )
};
