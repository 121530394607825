import { makeStyles } from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors'

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingTop: 0,
        ['@media (maxWidth:480px)']: {
            padding: theme.spacing(0),
            marginBottom: theme.spacing(3)
        },
        ['@media (maxWidth:900px) and (orientation: landscape)']: {
            padding: theme.spacing(0)
        },
        ['@media (minWidth:600px) and (orientation: portrait)']: {
            padding: theme.spacing(0),
        },
    },
    gridCenterCustom: {
        display: 'grid',
        alignItems: 'center',
        padding: ' 0px 5px 0px 5px'
    },

    cadastro: {
        paddingTop: '0px'
    },
    sobrenome: {
        marginLeft: theme.spacing(2),
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
        },
    },
    cpf: {
        width: '100%',
        marginLeft: '2%',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
            paddingLeft: theme.spacing(0.5),
            width: '100%'
        },
    },
    linha: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
        width: '100%',
        ['@media (maxWidth:480px)']: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
    },

    linhat: {
        marginLeft: theme.spacing(21),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },

    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%',
        height: 400,
    },

    rua: {
        ['@media (maxWidth:480px)']: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        },
    },
    numero: {
        ['@media (maxWidth:480px)']: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginLeft: theme.spacing(0)
        },
    },

    body2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 400,
        justifyContent: 'center',
        ['@media (maxWidth:480px)']: {
            marginTop: theme.spacing(5)
        },
    },
    body3: {
        minHeight: 400
    },
    grids: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    divs: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textfield: {
        marginLeft: '2%',
        width: 218.5
    },

    textField2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        padding: '5px',
        width: '100%',
    },

    txt: {
        width: '90%',
    },

    card: {
        display: 'flex',
        flexDirection: 'Row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
        width: '100%'
    },

    bigAvatar: {
        margin: 10,
        width: 150,
        height: 150,
        ['@media (maxWidth:480px)']: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

        },
    },
    bigAvatar2: {
        margin: 10,
        width: 75,
        height: 75,
        alignSelf: 'center'
    },
    button3: {
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#810b11'
        },
    },

    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },

    button2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'right',
        marginLeft: 250,
        marginTop: 100,
    },

    coluna: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        width: '100%',
    },
    coluna2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'top',
        alignItems: 'center',
        marginRight: theme.spacing(3)
    },
    iconbutton: {
        right: '-23%',
        bottom: '20%',
        background: teal[500],
        '&:hover': {
            background: teal[700]
        },
    },
    appbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px',

    },
    obs: {
        width: 665
    },
    textfielde: {
        width: '100%'
    },
    datanascimento: {
        width: '100%',
        marginLeft: '10px',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
        },
    },
    nacionalidade: {
        marginLeft: '10px',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
            width: '100%',
        },
    },
    telefonecomercial: {
        marginLeft: '10px',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
            width: '100%',
        }
    },
    telefoneemergia: {
        marginLeft: '10px',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
            width: '100%',
        }
    },
    bairro: {
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
        },
    },
    cidade: {
        width: '100%',
        marginLeft: '2%',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
            width: '100%'
        }
    },
    estado: {
        width: '100%',
        marginLeft: '2%',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
            width: '100%'
        }
    },
    pais: {
        width: '100%',
        marginLeft: '2%',
        ['@media (maxWidth:480px)']: {
            marginLeft: theme.spacing(0),
            width: '100%'
        }
    },
    tiposanguineo: {
        width: '33%',
        ['@media (maxWidth:480px)']: {
            width: '100%'
        }
    },
    fatorrh: {
        marginLeft: '15px',
        width: '33%',
        ['@media (maxWidth:480px)']: {
            width: '100%',
            marginLeft: theme.spacing(0)
        }
    },
    tamanhocamisa: {
        marginLeft: '15px',
        width: '33%',
        ['@media (maxWidth:480px)']: {
            width: '100%',
            marginLeft: theme.spacing(0)
        }
    },
    doencacronica: {
        marginLeft: '15px',
        ['@media (maxWidth:480px)']: {
            width: '100%',
            marginLeft: theme.spacing(0)
        }
    },
    intolerancia: {
        marginLeft: '15px',
        ['@media (maxWidth:480px)']: {
            width: '100%',
            marginLeft: theme.spacing(0)

        }
    },

}));
