import { Button, Typography } from '@material-ui/core';
import { XGrid } from '@material-ui/x-grid';
import React from 'react';
import EnumCompany from '../../../../../enums/enumFlightCompany';
import CustomNoRowsOverlay from '../../../../configuracao/email/lista/emptyList';
import { format, parse, parseISO } from 'date-fns';

function ItinerariosGrid(props) {
    // const columns = [
    //     { field: 'id', headerName: 'ID', width: 250, hide: true },
    //     { field: 'idreserva', headerName: 'ID', width: 80 },
    //     { field: 'passageiro', headerName: 'Passageiro', width: 250 },
    //     {
    //         field: 'cia', headerName: 'CIA', width: 130,
    //         renderCell: (item) => (
    //             <>
    //                 <img width={80} src={EnumCompany.getLogoCompanhiaAereaEnum(item.value).logo} style={item.value === '8I' ? { height: 60 } : { height: "auto" }} />
    //             </>
    //         )
    //     },
    //     { field: 'trecho', headerName: 'Trecho', width: 130 },
    //     { field: 'datacriacao', headerName: 'Data', width: 130 },
    //     { field: 'horaida', headerName: 'Ida', width: 130 },
    //     { field: 'horachegada', headerName: 'Chegada', width: 130 },
    //     { field: 'localizador', headerName: 'Loc.', width: 130 }
    // ];


    const columns = [
        { field: 'id', headerName: 'ID', width: 250, hide: true },
        { field: 'idreserva', headerName: 'ID', width: 80 },
        {
            field: 'passageiroNome', headerName: 'Passageiro', width: 200,
            valueGetter: (params) => params.row.passageiro,
            renderCell: (item) => (
                <div>
                    {item.value.map((pax) => (
                        <div>
                            <Typography>{pax.nome}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'ccCodigo', headerName: 'CC cod.', width: 80,
            valueGetter: (params) => params.row.passageiro,
            renderCell: (item) => (
                <div>
                    {item.value.map((cc) => (
                        <div>
                            <Typography>{cc.cc_codigo}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'ccNome', headerName: 'CC', width: 100,
            valueGetter: (params) => params.row.passageiro,
            renderCell: (item) => (
                <div>
                    {item.value.map((cc) => (
                        <div>
                            <Typography>{cc.cc_nome}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'passageiroTotal', headerName: 'Total p/Pax', width: 100,
            valueGetter: (params) => params.row.passageiro,
            renderCell: (item) => (
                <div>
                    {item.value.map((pax) => (
                        <div>
                            <Typography>{pax.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'cia', headerName: 'CIA', width: 100,
            renderCell: (item) => (
                <>
                    <img width={80} src={EnumCompany.getLogoCompanhiaAereaEnum(item.value).logo} style={item.value === '8I' ? { height: 60 } : { height: "auto" }} />
                </>
            )
        },
        {
            field: 'infoTrechoTrecho', headerName: "Trecho", width: 120,
            valueGetter: (params) => params.row.infoTrecho,
            renderCell: (item) => (
                <div>
                    {item.value.map((trecho) => (
                        <div>
                            <Typography>{trecho.trecho}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'infoTrechoData', headerName: "Data", width: 100,
            valueGetter: (params) => params.row.infoTrecho,
            renderCell: (item) => (
                <div>
                    {item.value.map((trecho) => (
                        <div>
                            <Typography>{format(parseISO(trecho.data), 'MM/dd/yyyy')}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'infoTrechoHoraIda', headerName: "H. Ida", width: 100,
            valueGetter: (params) => params.row.infoTrecho,
            renderCell: (item) => (
                <div>
                    {item.value.map((trecho) => (
                        <div>
                            <Typography>{format(parse(trecho.horaIda, 'HH:mm:ss', new Date()), 'HH:mm')}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        {
            field: 'infoTrechoHoraChegada', headerName: "H. Chegada", width: 100,
            valueGetter: (params) => params.row.infoTrecho,
            renderCell: (item) => (
                <div>
                    {item.value.map((trecho) => (
                        <div>
                            <Typography>{format(parse(trecho.horaChegada, 'HH:mm:ss', new Date()), 'HH:mm')}</Typography>
                        </div>
                    ))}
                </div>
            )
        },
        { field: 'localizador', headerName: 'Loc.', width: 80 },
        {
            field: 'total', headerName: 'Total', width: 130,
            renderCell: (item) => (
                <div>
                    <Typography>{item.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                </div>
            )
        },
    ];



    return (

        <>
            <div style={{ height: '52vh', width: '100%' }}>
                <XGrid
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    id={Math.random()}
                    rows={props.data}
                    columns={columns}
                    loading={props.isLoading}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    hideFooterRowCount
                    pagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            </div>
        </>
    );
};

export default ItinerariosGrid;
