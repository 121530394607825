import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Divider, Menu, MenuItem } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RejectionFiles from './errors-rejection-files';
import Image from '../images/image';
import { CameraAltOutlined, DocumentScannerOutlined, PhotoLibraryOutlined } from '@mui/icons-material';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 16,
    width: 400,
    boxShadow: "0px 0px 2.2px 0px rgba(0,0,0,0.5)"
  },
}));


export default function UploadAvatar({ error, file, disabled, helperText, fullScreen = false, sx, ...other }) {
  const [captureMode, setCaptureMode] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isIphone = /iPhone/i.test(navigator.userAgent);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    if (isIphone || fullScreen) {
      setCaptureMode('')
      document.getElementById('file-input').click();
      return
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClickConfirm = (mode) => {
    setCaptureMode(mode);
    handleClose();
    // Trigger the file input click to open camera or file selector
    document.getElementById('file-input').click();
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    accept: 'image/*',
    capture: captureMode,
    ...other,
  });

  const hasFile = !!file;
  const hasError = isDragReject || !!error;
  const imgUrl = typeof file === 'string' ? file : file?.preview;

  const renderPreview = hasFile && (
    <Image
      alt="avatar"
      src={imgUrl}
      sx={{
        width: 1,
        height: 1,
        borderRadius: '3%',
      }}
    />
  );

  const renderPlaceholder = (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      className="upload-placeholder"
      sx={{
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        zIndex: 9,
        borderRadius: '3%',
        position: 'absolute',
        color: 'text.disabled',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        '&:hover': {
          opacity: 0.72,
        },
        ...(hasError && {
          color: 'error.main',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }),
        ...(hasFile && {
          zIndex: 9,
          opacity: 0,
          color: 'common.white',
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64),
        }),
      }}
    >
      <DocumentScannerOutlined />
      <Typography variant="caption">{file ? 'Editar comprovante' : 'Enviar comprovante'}</Typography>
    </Stack>
  );

  const renderContent = (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        borderRadius: '3%',
        position: 'relative',
      }}
    >
      {renderPreview}
      {renderPlaceholder}
    </Box>
  );

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <StyledMenu
        sx={{ borderRadius: "16px", width: "250px" }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleClickConfirm("camera")} disableRipple width={100}>
          Galeria
          <PhotoLibraryOutlined style={{ marginLeft: "auto", color: "#313131" }} />
        </MenuItem>
        <Divider variant="li" />
        <MenuItem onClick={() => handleClickConfirm("")}>
          Tirar foto
          <CameraAltOutlined style={{ marginLeft: "auto", color: "#313131" }} />
        </MenuItem>
      </StyledMenu>
      <Box
        {...getRootProps()}
        onClick={handleClick}
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          p: 1,
          m: 'auto',
          width: "100%",
          maxWidth: "250px",
          height: "250px",
          maxHeight: "-webkit-fill-available",
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: '3%',
          border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
          // ...(isDragActive && {
          //   opacity: 0.72,
          // }),
          // ...(disabled && {
          //   opacity: 0.48,
          //   pointerEvents: 'none',
          // }),
          // ...(hasError && {
          //   borderColor: 'error.main',
          // }),
          // ...(hasFile && {
          //   ...(hasError && {
          //     bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
          //   }),
          //   '&:hover .upload-placeholder': {
          //     opacity: 1,
          //   }),
          // }),
          // ...sx,
        }}
      >

        {captureMode === "camera" ? (
          <input {...getInputProps()} id="file-input" capture />
        ) : (
          <input {...getInputProps()} id="file-input" />
        )}

        {renderContent}
      </Box>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />
      <p style={{ display: "none" }}>
        {captureMode}
      </p>
    </div>
  );
}

UploadAvatar.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};
