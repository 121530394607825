import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme => ({
    root: {
        width: '100%'
    },
    body: {
        height: 400,
        width: '100%',
        padding: theme.spacing(0, 0),
        ['@media (max-width:480px)']: {
            height: 150,
            width: '100%',
            padding: theme.spacing(0, 0),
        },

    },
    lista: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    ul: {
        ['@media (max-width:480px)']: {
            margin: theme.spacing(0, 0),
            width: '100%',
            justifyContent: 'space-evenly'

        },
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    //     paperList: {
    //     ['@media (max-width:480px)']: {
    //         margin: theme.spacing(0, 0),
    //         width: '100%',
    //         justifyContent: 'space-evenly'
    //     },
    // },
    list: {
        ['@media (max-width:480px)']: {
            width: '100%',
            maxWidth: '100%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: '90vh',
        },
    },
})));