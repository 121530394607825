import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography } from "@material-ui/core";
import { CloseOutlined, SaveOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import UDatePicker from '../../../../components/udatepicker';
import reservaRepositorio from "../../../../repositorios/reserva";
import { useStyles } from "./styles";

export const ChangeCreatedDate = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const classes = useStyles();
    const [dataCriacao, setDataCriacao] = useState(new Date());

    const handleClickSave = async () => {
        if (!dataCriacao) {
            props.setSnackMessage('Data de criação não pode ser vazia!');
            props.setSnackVariant('error');
            props.setSnackState({ visible: true });
            return;
        }
        let dto = {
            idreserva: props.bookingSelected.idreserva,
            datacriacao: dataCriacao
        }
        try {
            props.setIsLoading(true);
            await reservaRepositorio.alterarDataCriacao(dto);
            props.setSnackMessage('Data de criação alterada!');
            props.setSnackVariant('success');
            props.setSnackState({ visible: true });
            props.setOpen(false);
        } catch (e) {
            props.setSnackMessage('Erro ao salvar!');
            props.setSnackVariant('error');
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false);
        }
    }

    return (
        <>
            <Dialog open={open} onClose={() => props.setOpen(false)} fullWidth maxWidth="xs" fullScreen={responsive}>
                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography >Alterar Data de criação</Typography>
                        <IconButton onClick={() => props.setOpen(false)} ><CloseOutlined className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <UDatePicker {...{ date: dataCriacao, setDate: setDataCriacao, label: "Data de criação" }} name="datacriacao" />
                </DialogContent>
                <DialogActions>
                    <Button disabled={props.isLoading} onClick={() => handleClickSave()} className={classes.buttonSave} startIcon={<SaveOutlined />} >Salvar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
