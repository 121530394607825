import { Button, Dialog, DialogActions, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import ULoaderVoo from '../../components/uloadervoo';
import AviaoIMG from '../../images/PNG_random/aviao_expirado.png';
import { useStyles } from './styles';

export const DialogWarning = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseDialogWarning = () => {
        props.action()
        props.setOpenDialogWarning()
    }

    return (
        <form >
            <Dialog
                open={props.openDialogWarning}
                onClose={handleCloseDialogWarning}
                maxWidth={'sm'}
                fullScreen={responsive}
            >
                <ULoaderVoo isLoading={isLoading} />
                <DialogContent>
                    <Grid item xs={12} className={classes.gridIMG}>
                        <img width={240} height={240} src={AviaoIMG}></img>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: '400' }} paragraph variant="h5" fon className={classes.typoH5}>
                            Tempo limite dos resultado foi excedido.
                        </Typography>
                        <Typography variant="subtitle1" paragraph align='justify'>
                            Devido ao tempo que se passou, é necessário atualizar a pesquisa para que possa ver os preços atuais.
                        </Typography>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.appbar}>
                    <Button className={classes.buttoncustom} onClick={() => props.action()}>
                        <Typography className={classes.typoBUTTON}>
                            Atualizar pesquisa
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </form >
    )
};

export default DialogWarning;
