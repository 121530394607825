import { Button, ButtonGroup, Container, Grid, ThemeProvider, Typography, useMediaQuery, useTheme } from "@mui/material";
import BankingWidgetSummary from "../../components/cardbankingsummary";
import Dashboard from "../dashboard";
import { themepalette } from "../../theme/palette";
import BankingCurrentBalance from "../../components/cardbankingsummary/index-saldo";
import { _bankingCreditCard, _bankingRecentTransitions } from "../../mock/financeiro";
// import OrderListView from "../../components/OrderListView";
import { Add } from "@mui/icons-material";
import BankingRecentTransitions from "../../components/recentTransations";
import { useState } from "react";
import { PagamentosComponent } from "./Pagamentos";
import IntegrationNotistack from "../../components/snackbar2/NewSnackBar";

const FinanceiroComponent = () => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
    const [currentTab, setCurrentTab] = useState("pagamentos");

    const STATUS_OPTIONS = [
        { value: 'todos', label: 'Todos', status: 0, color: 'secondary' },
        { value: 'finalizado', label: 'Finalizado', status: 5, color: 'primary' },
        { value: 'financeiro', label: 'Financeiro', status: 4, color: 'success' },
        { value: 'controladoria', label: 'Controladoria', status: 3, color: 'info' },
        { value: 'pendente', label: 'Pendente', status: 8, color: 'warning' },
        { value: 'cancelado', label: 'Cancelado', status: 7, color: 'error' },
    ];

    const sampleData = [
        {
            id: 1,
            pessoa: 'João Silva',
            datadecriação: '2023-05-01',
            inicio: '2023-05-01 08:00',
            total: 100,
            totalinicial: 50,
            status: 5,
        },
        {
            id: 2,
            pessoa: 'Maria Oliveira',
            datadecriação: '2023-05-02',
            inicio: '2023-05-02 09:00',
            total: 200,
            totalinicial: 100,
            status: 4,
        },
        {
            id: 3,
            pessoa: 'Carlos Souza',
            datadecriação: '2023-05-03',
            inicio: '2023-05-03 10:00',
            total: 300,
            totalinicial: 150,
            status: 8,
        },
    ];

    const TABSdesk = [
        {
            value: 'pagamentos',
            label: 'Pagamentos',
            viewer: <PagamentosComponent />
        },
        // {
        //     value: 'relatorios',
        //     label: 'Relatórios',
        //     viewer: <></>
        // },
    ];

    const handleChangeTab = (value) => {
        setCurrentTab(value)
    }

    return (
        <ThemeProvider theme={themepalette}>
            <IntegrationNotistack>
                <Container maxWidth="xl">
                    <Grid container xs={12} style={{ marginTop: "15px", display: "flex", alignContent: "center", alignItems: "center" }}>
                        <Grid item xs={fullscreen?12:6} style={{ padding: "20px" }}>
                            <Typography variant="h4" style={{ color: themepalette.palette.grey[800] }}>
                                Bem vindo de volta 👋 financeiro
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={fullscreen?12:6} style={fullscreen?{ textAlign: "center", padding: "20px" }:{ textAlign: "right", padding: "20px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {TABSdesk.map((tab) => (
                                    <Button
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        {tab.label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid> */}
                        <Grid item xs={12}>
                            {TABSdesk.slice(0, 3).map(
                                (tab) =>
                                    tab.value === currentTab && (
                                        <>
                                            {tab.viewer}
                                        </>
                                    )
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </IntegrationNotistack>
        </ThemeProvider>
    )
}

export const FinanceiroComponentDash = (props) => {
    return (
        <div>
            <Dashboard {...props} component={FinanceiroComponent} />
        </div>
    );
};
export default FinanceiroComponentDash;