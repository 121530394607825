import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  root: {
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    position: 'absolute',
    zIndex: 1500
  },
  fab: {
    '&.MuiFab-primary': {
      backgroundColor: '#a82223',
      color: 'white',
    }
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));
