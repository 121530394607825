import { Box, Button, Dialog, DialogContent, DialogTitle, Grow, IconButton, InputBase, Paper, TablePagination, Toolbar, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CloseOutlined, Search } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import cookie from "universal-cookie";
import LoadingButton from "../../../components/loadingButton";
import Snackbar from "../../../components/snackbar";
import UFloatButton from "../../../components/ufloatbutton";
import ULoaderVoo from "../../../components/uloadervoo";
import Enums from "../../../enums";
import centroCustoRepositorio from "../../../repositorios/centroCusto/index";
import permissaoRepositorio from "../../../repositorios/permissao";
import Dashboard from "../../dashboard";
import CadastroCentroCusto from "../cadastro";
import CentroCustoGRID from "../centroDeCustoGRID/index";
import CentroCustoLIST from "../centroDeCustoLIST";
import { useStyles } from "./styles";

export const ListaCentroCusto = () => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(maxWidth:480px)");
  const matches2 = useMediaQuery(
    "(maxWidth:900px) and (orientation: landscape)"
  );
  const matches3 = useMediaQuery(
    "(minWidth:600px) and (orientation: portrait)"
  );
  const cookies = useMemo(() => new cookie(), []);
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [centros, setCentros] = useState([]);
  const [picture, setPicture] = useState(null);
  const [centrosPesquisa, setCentrosPesquisa] = useState([]);
  const [centroCusto, setCentroCusto] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [itensPerPage, setItensPergPage] = useState(10);
  const pages = centrosPesquisa.length;
  const start = page * itensPerPage;
  const r = itensPerPage + start;
  const todosCentros = centrosPesquisa.slice(start, r);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [cadastroOpen, setCadastroOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setItensPergPage(event.target.value);
  };

  const onPesquisar = (event) => {
    const contem = centros.filter((item) => {
      return (
        item.nome.toLowerCase().includes(event.target.value.toLowerCase()) ||
        item.codigo.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setCentrosPesquisa(contem);
  };

  const salvar = async () => {
    var data = { ...centroCusto, sessionId: cookies.get("sessionId") };
    if (data.codigo == undefined) {
      data = { ...data, codigo: "" };
    }

    if (!centroCusto.nome) {
      setSnackVariant("warning");
      setSnackMessage("O campo nome não pode ser vazio.");
      setSnackVisible({ visible: true });
      return;
    }

    if (centroCusto.idcentrocusto) {
      try {
        setIsLoading(true);
        let temPermissaoEditar = await permissaoRepositorio.temPermissao(
          Enums.moduloSistema.centrocusto,
          Enums.acaoRequerida.editar
        );

        if (!temPermissaoEditar.data) {
          setSnackVariant("warning");
          setSnackMessage("Você não possui permissão!");
          setSnackVisible({ visible: true });
          setIsLoading(false);
          return;
        }

        await centroCustoRepositorio.saveUpdateCentroCusto(centroCusto);
        getData();
        setCadastroOpen(false);
      } catch (e) {
        setSnackVariant("error");
        setSnackMessage("Erro.");
        setSnackVisible({ visible: true });
      } finally {
        setIsLoading(false);
        return
      }
    }

    try {
      setIsLoading(true)
      await centroCustoRepositorio.createCentroCusto(data);
      getData()
      setCadastroOpen(false);
    } catch (error) {
      setSnackVariant("error");
      setSnackMessage("Falha ao criar centro de custo.");
      setSnackVisible({ visible: true });
    } finally {
      setIsLoading(false)
    }

  };

  const handleCadastroOpen = (item, visible) => {
    setCentroCusto(item);
    setCadastroOpen(visible);
  };

  async function getData() {
    try {
      setIsLoading(true);
      let response = await centroCustoRepositorio.getAllPorTenantList()

      setCentros(response.data);
      setCentrosPesquisa(response.data)
    } catch {
      setSnackVariant("error");
      setSnackMessage("Erro ao buscar centros de custo.");
      setSnackVisible({ visible: true });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <ULoaderVoo isLoading={isLoading} />
      <Snackbar
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      {responsive ? (
        <>
          <Dialog
            className={classes.cadastro}
            open={cadastroOpen}
            onClose={() => setCadastroOpen(false)}
            fullScreen={fullscreen}
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle className={classes.dialog}>
              <div className={classes.divtitle}>
                <Typography style={{ color: "white" }}>
                  Centro de custo
                </Typography>
                <IconButton
                  className={classes.fechar}
                  onClick={() => setCadastroOpen(false)}
                >
                  <CloseOutlined />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <CadastroCentroCusto
                {...{
                  centroCusto: centroCusto,
                  setCentroCusto: setCentroCusto,
                  picture: picture,
                  setPicture: setPicture,
                }}
              />
              <div className={classes.salvar}>
                <LoadingButton
                  {...{
                    salvar,
                    modalOpen: cadastroOpen,
                    setModalOpen: setCadastroOpen,
                  }}
                />
              </div>
            </DialogContent>
          </Dialog>
          <Toolbar className={classes.toolbar}>
            {!matches && !matches2 && !matches3 ? (
              <Button
                className={classes.button}
                onClick={() => handleCadastroOpen({ centroCusto: {} }, true)}
              >
                Novo
              </Button>
            ) : (
              <UFloatButton
                actions={[]}
                handleClick={() => handleCadastroOpen({ tipousuario: 1 }, true)}
              />
            )}
            <div className={classes.search3}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                onChange={onPesquisar}
                placeholder="Pesquise…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Toolbar>
          <CentroCustoLIST data={centrosPesquisa}  {...{ handleCadastroOpen, todosCentros }} />
        </>
      ) : (
        <>
          <ULoaderVoo isLoading={isLoading} />
          <div className={classes.root}>
            <Dialog
              className={classes.cadastro}
              open={cadastroOpen}
              onClose={() => setCadastroOpen(false)}
              fullScreen={fullscreen}
              maxWidth="sm"
              fullWidth={true}
            >
              <DialogTitle className={classes.dialog}>
                <div className={classes.divtitle}>
                  <Typography style={{ color: "white" }}>
                    Centro de custo
                  </Typography>
                  <IconButton
                    className={classes.fechar}
                    onClick={() => setCadastroOpen(false)}
                  >
                    <CloseOutlined />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <CadastroCentroCusto
                  {...{
                    centroCusto: centroCusto,
                    setCentroCusto: setCentroCusto,
                    picture: picture,
                    setPicture: setPicture,
                  }}
                />
                <div className={classes.salvar}>
                  <LoadingButton
                    {...{
                      salvar,
                      modalOpen: cadastroOpen,
                      setModalOpen: setCadastroOpen,
                    }}
                  />
                </div>
              </DialogContent>
            </Dialog>
            <Grow in={true} timeout={500}>
              <Paper elevation={!fullscreen ? 5 : 0}>
                <Toolbar className={classes.toolbar}>
                  {!matches && !matches2 && !matches3 ? (
                    <Button
                      className={classes.button}
                      onClick={() =>
                        handleCadastroOpen({ centroCusto: {} }, true)
                      }
                    >
                      Novo
                    </Button>
                  ) : (
                    <UFloatButton
                      actions={[]}
                      handleClick={() =>
                        handleCadastroOpen({ tipousuario: 1 }, true)
                      }
                    />
                  )}
                  <TablePagination
                    component="div"
                    count={pages}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[10, 25, 50]}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  <div className={classes.search3}>
                    <div className={classes.searchIcon}>
                      <Search />
                    </div>
                    <InputBase
                      onChange={onPesquisar}
                      placeholder="Pesquise…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </div>
                </Toolbar>
                <CentroCustoGRID
                  {...{ handleCadastroOpen, todosCentros }}
                  data={centrosPesquisa}
                />
              </Paper>
            </Grow>
          </div>
        </>
      )}
    </Box>
  );
};

export const Lista = (props) => {
  return (
    <div>
      <Dashboard {...props} component={ListaCentroCusto} />
    </div>
  );
};
export default Lista;
