import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const itinerarioRealizadoRepositorio = {
    getItinerarioTenant: async (dtoDadosRelatorio) => {
        const response = await api.post('/Relatorio/GetItinerarioTenant', dtoDadosRelatorio, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    getItinerarioTenant_v2: async (dtoDadosRelatorio) => {
        const response = await api.post('/Relatorio/GetItinerarioTenant_v2', dtoDadosRelatorio, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

}

export default itinerarioRealizadoRepositorio;
