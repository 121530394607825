import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CardMedia,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Enums from "../../../../../enums";
import EnumCompany from "../../../../../enums/enumFlightCompany";
import aviao from "../../../../../images/PNG_random/aviao.png";
import logoteste from "../../../../../images/Logos/logo_linesturDigital.svg";
import aviaoChegando from "../../../../../images/PNG_random/aviao-chegando.png";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { useStyles } from "./styles";
import {
  CreditCardOutlined,
  EcoOutlined,
  EditOutlined,
  InsertDriveFileOutlined,
} from "@material-ui/icons";
import { useState } from "react";
import Cookies from "universal-cookie";
import CustomizedProgressBars from "../../../../../components/LinearProgress";
import ReservaStatusComponent from "../../../../../components/LinearProgress/StatusVerifiqued";

export const BilheteMob = (props) => {
  const classes = useStyles();
  const cookies = new Cookies();

  const tempoDeVoo = (horaida, horachegada) => {
    let diferenca = new Date(horachegada - horaida);
    let h = diferenca.getUTCHours() + "h ";
    let min = diferenca.getUTCMinutes() + "min ";
    return h + " " + min;
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container>
        <Grid container item xs={12} className={classes.GlobalGridResum1}>
          <Grid item={8} className={classes.header}>
            <Typography variant="h1">Plano de viagem</Typography>
            <Typography variant="h2">
              <span>Agente:</span> Zulma A. Torassi
            </Typography>
            <Typography variant="h2">
              <span>E-mail:</span> zulma@linestur.com
            </Typography>
          </Grid>
          <Grid item={4} className={classes.header}>
            <Typography variant="h3">Contatos</Typography>
            <Grid>
              <Typography variant="h4">(48) 3045-7015</Typography>
              <Typography variant="h4">(48) 99976-0516</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.GlobalGridResum2}>
          <Grid
            xs={6}
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <CardMedia
              style={{ padding: "14px", width: "150px" }}
              component="img"
              image={logoteste}
            />
          </Grid>
          <Grid xs={6} className={classes.buttonGrid}>
            <Button onClick={handleClick} style={{ width: "130px" }}>
              Opções
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() =>
                  props.handleClickOpenCartaoFaturamento(props.reserva.status)
                }
                className={classes.ButtonFonts}
              >
                <CreditCardOutlined />
                Cartão
              </MenuItem>
              <MenuItem
                onClick={() => props.handleClickOpenAddBilhetePDF()}
                className={classes.ButtonFonts}
              >
                <InsertDriveFileOutlined />
                Anexos
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.resumValues}>
          <Grid item xs={12} style={{ margin: "10px" }}>
            <ReservaStatusComponent
              status={props.reserva.status}
              idreserva={props.reserva.idreserva}
            />
          </Grid>
          <Grid xs={12}>
            {cookies.get("usession") == "thalysson" ||
              cookies.get("usession") == "levi" ? (
              <div>{props.getLogoAPI(props.reserva.idapi)}</div>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              style={{
                width: "fit-content",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: Enums.getReservaStatusCor(props.reserva.status),
                background: Enums.getReservaStatusCor(props.reserva.status),
                color: "white",
                borderRadius: 3,
                padding: 5,
                fontSize: "14pt",
              }}
            >
              {props.getStatusReserva(props.reserva.status)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.resumVooCenter}>
          <Grid item xs={8} style={{ textAlign: "left" }}>
            <Typography variant="h1">LOC </Typography>
            <br></br>
            <Typography variant="h2">
              {props.permissaoDashboard.usuarioLinestur ? (
                <>{props.aereo.localizador}</>
              ) : (
                <>
                  {[3, 8, 9, 14].includes(props.reserva.status) ? (
                    "Aguardando"
                  ) : (
                    <>{props.aereo.localizador}</>
                  )}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography variant="h1">Criação</Typography>
            <br></br>
            <Typography variant="h2">
              {props.getDataFormatada(props.reserva.datacriacao)}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Typography variant="h1">Solicitante</Typography>
            <br></br>
            <Typography variant="h2">
              {props.reserva.usuario.pessoa !== null ? (
                <>
                  {props.reserva?.usuario.pessoa.nome +
                    " " +
                    props.reserva?.usuario.pessoa.sobrenome}

                  {props.reserva.status === 7 &&
                    props.permissaoDashboard.usuarioLinestur
                    ? props.showIconByValue(
                      props.reserva?.usuario.pessoa.idpessoa,
                      props.reserva?.usuario.pessoa.bilheteenviado,
                      true
                    )
                    : null}
                </>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.break}>
          <hr></hr>
          <Grid container item xs={12}>
            <Grid className={classes.ResumoPVoo} item xs={4}>
              {props.aereo.voos
                ? props.aereo.voos.map((item, index) => (
                  <>
                    {index > 0 ? null : (
                      <>
                        <Typography variant="h1">{`${item.origem}`}</Typography>
                        <Typography variant="h2">
                          {`${new Date(item.datapartida)
                            .toLocaleString()
                            .slice(
                              11,
                              new Date(item.datapartida).toLocaleString()
                                .length - 3
                            )}`}
                        </Typography>
                        <Typography variant="h3">
                          {`${new Date(item.datapartida)
                            .toLocaleString()
                            .slice(
                              0,
                              new Date(item.datapartida).toLocaleString()
                                .length - 10
                            )}`}
                        </Typography>
                      </>
                    )}
                  </>
                ))
                : null}
            </Grid>
            <Grid item xs={4}>
              <Grid style={{}}>
                <Grid>
                  {props.aereo.voos
                    ? props.aereo.voos.map((item, index) => (
                      <>
                        {index > 0 ? null : (
                          <>
                            {item.origem ==
                              props.aereo.voos[props.aereo.voos.length - 1]
                                .destino ? (
                              <>
                                <img
                                  src={aviao}
                                  style={{ width: "42px" }}
                                ></img>
                                <br></br>
                                <img
                                  src={aviao}
                                  style={{
                                    width: "42px",
                                    transform: "scaleX(-1)",
                                  }}
                                ></img>
                              </>
                            ) : (
                              <img
                                src={aviao}
                                style={{ width: "68px" }}
                              ></img>
                            )}
                          </>
                        )}
                      </>
                    ))
                    : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.ResumoPVoo}>
              {props.aereo.voos
                ? props.aereo.voos.map((item, index) => (
                  <>
                    {item.destino == props.aereo.voos[0].origem ? (
                      <>
                        {props.aereo.voos.length - 1 ? (
                          <>
                            <Typography variant="h1">{`${item.origem}`}</Typography>
                            <Typography variant="h2">
                              {`${new Date(item.datapartida)
                                .toLocaleString()
                                .slice(
                                  11,
                                  new Date(item.datapartida).toLocaleString()
                                    .length - 3
                                )}`}
                            </Typography>
                            <Typography variant="h3">
                              {`${new Date(item.datapartida)
                                .toLocaleString()
                                .slice(
                                  0,
                                  new Date(item.datapartida).toLocaleString()
                                    .length - 10
                                )}`}
                            </Typography>
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {item.destino != props.aereo.voos[0].origem ? (
                      <>
                        {props.aereo.voos.length - 1 == index ? (
                          <>
                            <Typography variant="h1">{`${item.destino}`}</Typography>
                            <Typography variant="h2">
                              {`${new Date(item.datachegada)
                                .toLocaleString()
                                .slice(
                                  11,
                                  new Date(item.datachegada).toLocaleString()
                                    .length - 3
                                )}`}
                            </Typography>
                            <Typography variant="h3">
                              {`${new Date(item.datachegada)
                                .toLocaleString()
                                .slice(
                                  0,
                                  new Date(item.datachegada).toLocaleString()
                                    .length - 11
                                )}`}
                            </Typography>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ))
                : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.detailsVoo}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Grid style={{ textAlign: "center" }}>
              <Grid>
                <img
                  src={
                    props.aereo.companhiaaerea === undefined
                      ? null
                      : EnumCompany.getLogoCompanhiaAereaEnum(
                        props.aereo.companhiaaerea
                      ).url_high
                  }
                  width={"auto"}
                  style={{ height: "8rem" }}
                />
              </Grid>
              <Typography className={classes.tituloDetails}>
                DETALHES
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.accordionsList}>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreOutlinedIcon className={classes.colorIcon} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" style={{ color: "#FFFFFF" }}>
                  PASSAGEIROS
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionValues}>
                <Grid className={classes.accordionSubGrid}>
                  {props.aereo.passageiros
                    ? props.aereo.passageiros.map((item) => (
                      <Accordion
                        className={classes.accordion}
                        style={{ boxShadow: "none" }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreOutlinedIcon
                              className={classes.colorIcon}
                            />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            className={classes.heading}
                            style={{ color: "#FFFFFF" }}
                          >
                            {item.passageiro.nome + " "}
                            {item.passageiro.sobrenome}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ boxShadow: "none" }}>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.GridPassageiros}
                          >
                            <Grid item xs={6}>
                              <Typography variant="h1">Nome</Typography>
                              <Typography variant="h6">
                                {item.passageiro.nome}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h1">Sobrenome</Typography>
                              <Typography variant="h6">
                                {item.passageiro.sobrenome}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h1">Nascimento</Typography>
                              <Typography variant="h2">
                                {new Date(
                                  item.passageiro.datanascimento
                                ).toLocaleDateString()}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h1">Sexo</Typography>
                              <Typography variant="h2">
                                {props.getGenero[item.passageiro.sexo]}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h1">Tipo</Typography>
                              <Typography variant="h2">
                                {props.getFaixaEtaria[item.faixaetaria]}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {props.reserva.status === 7 &&
                                props.permissaoDashboard.usuarioLinestur ? (
                                <Typography
                                  variant="h1"
                                  style={{ color: "#FFFFFF" }}
                                >
                                  <>
                                    {props.showIconByValue(
                                      item.idpassageiro,
                                      item.bilheteenviado,
                                      false
                                    )}
                                  </>
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{ textAlign: "center" }}
                            >
                              <Typography variant="h1">
                                Emissão CO2 (kg)
                              </Typography>
                              <Typography variant="h2">
                                {item.carbono}{" "}
                                <EcoOutlined style={{ color: "#43a047" }} />
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{ textAlign: "center" }}
                            >
                              <Typography variant="h1">
                                Centro de Custo
                              </Typography>
                              <Typography variant="h2">
                                <nobr>
                                  {`${item.centroCusto.nome} - ${item.centroCusto.codigo}`}
                                  {props.permissaoDashboard
                                    .usuarioLinestur ? (
                                    <IconButton
                                      onClick={() =>
                                        props.handleClickEditCentroCusto(item)
                                      }
                                      size="small"
                                    >
                                      <EditOutlined
                                        style={{ color: "#FFFFFF" }}
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  ) : null}
                                </nobr>
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))
                    : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreOutlinedIcon className={classes.colorIcon} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h6"
                  className={classes.heading}
                  style={{ color: "#FFFFFF" }}
                >
                  VOOS
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionValues}>
                <Grid className={classes.accordionSubGrid}>
                  {props.aereo.voos
                    ? props.aereo.voos.map((item) => (
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreOutlinedIcon
                              className={classes.colorIcon}
                            />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            {`${item.origemdescricao} ⇀ ${item.destinodescricao}`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.VooResum}
                          >
                            <Grid
                              item
                              xs={12}
                              style={{ textAlign: "center" }}
                            >
                              <img
                                src={
                                  item.iata === undefined
                                    ? null
                                    : EnumCompany.getLogoCompanhiaAereaEnum(
                                      item.iata
                                    ).url_low
                                }
                                style={{
                                  margin: "5px",
                                  width: "120px",
                                }}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="h1">{`${new Date(
                                item.datapartida
                              )
                                .toLocaleString()
                                .slice(
                                  11,
                                  new Date(item.datapartida).toLocaleString()
                                    .length - 3
                                )}`}</Typography>
                              <Typography variant="h2">
                                {`${item.origem}`}
                              </Typography>
                              <Typography variant="h1">{`${item.origemdescricao}`}</Typography>
                              <Typography variant="h1">
                                {`${new Date(item.datapartida)
                                  .toLocaleString()
                                  .slice(
                                    0,
                                    new Date(
                                      item.datapartida
                                    ).toLocaleString().length - 10
                                  )}`}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                textAlign: "center",
                                marginBottom: "12px",
                              }}
                            >
                              <img
                                src={aviaoChegando}
                                style={{ width: "50px", marginTop: "0px" }}
                              ></img>
                              <Typography>
                                {tempoDeVoo(
                                  new Date(item.datapartida),
                                  new Date(item.datachegada)
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} className={classes.VooResum}>
                              <Typography variant="h1">{`${new Date(
                                item.datachegada
                              )
                                .toLocaleString()
                                .slice(
                                  11,
                                  new Date(item.datachegada).toLocaleString()
                                    .length - 3
                                )}`}</Typography>
                              <Typography variant="h2">
                                {`${item.destino}`}
                              </Typography>
                              <Typography variant="h1">{`${item.destinodescricao}`}</Typography>
                              <Typography variant="h1">
                                {`${new Date(item.datachegada)
                                  .toLocaleString()
                                  .slice(
                                    0,
                                    new Date(
                                      item.datachegada
                                    ).toLocaleString().length - 10
                                  )}`}
                              </Typography>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              style={{ marginTop: "20px" }}
                            >
                              <Grid
                                item
                                xs={4}
                                style={{ textAlign: "center" }}
                              >
                                <Typography variant="h4">Voo</Typography>
                                <Typography variant="h4">
                                  {item.numero}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{ textAlign: "center" }}
                              >
                                <Typography variant="h4">CI.</Typography>
                                <Typography variant="h4">
                                  {item.classe}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                style={{ textAlign: "center" }}
                              >
                                <Typography variant="h4">Esc.</Typography>
                                <Typography variant="h4">
                                  {item.escala}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                textAlign: "center",
                                marginTop: "20px",
                              }}
                            >
                              <Typography variant="h5">Info</Typography>
                              <Typography variant="h5">
                                {item.incluibagagem
                                  ? `Com Bagagem(${item.tipobagagem === null
                                    ? "Sem nome"
                                    : item.tipobagagem
                                  })`
                                  : `Sem Bagagem(${item.tipobagagem === null
                                    ? "Sem nome"
                                    : item.tipobagagem
                                  })`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    ))
                    : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreOutlinedIcon className={classes.colorIcon} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" className={classes.heading}>
                  VALORES
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionValues}>
                {props.aereo.passageiros
                  ? props.aereo.passageiros.map((item) => (
                    <Grid className={classes.accordionSubGrid}>
                      <Accordion className={classes.accordion}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreOutlinedIcon
                              className={classes.colorIcon}
                            />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            {item.passageiro.sobrenome +
                              "/" +
                              item.passageiro.nome}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid
                            container
                            item
                            xs={12}
                            className={classes.values}
                          >
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography variant="h1">Tarifas</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h2">
                                  {"R$" + item.total.toFormatDefault() + " "}
                                  {props.permissaoDashboard.usuarioLinestur &&
                                    props.reserva.status === 7 ? (
                                    <IconButton
                                      onClick={() =>
                                        props.handleClickEditValorPassageiro(
                                          item,
                                          "tarifa"
                                        )
                                      }
                                      size="small"
                                    >
                                      <EditOutlined
                                        style={{ color: "#FFFFFF" }}
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  ) : null}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography variant="h1">Extras</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h2">
                                  {"R$" + item.taxes.toFormatDefault() + " "}
                                  {props.permissaoDashboard.usuarioLinestur &&
                                    props.reserva.status === 7 ? (
                                    <IconButton
                                      onClick={() =>
                                        props.handleClickEditValorPassageiro(
                                          item,
                                          "extras"
                                        )
                                      }
                                      size="small"
                                    >
                                      <EditOutlined
                                        style={{ color: "#FFFFFF" }}
                                        fontSize="small"
                                      />
                                    </IconButton>
                                  ) : null}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                              <Grid item xs={6}>
                                <Typography variant="h1">Total</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h2">
                                  {"R$" +
                                    item.total_geral.toFormatDefault() +
                                    " "}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))
                  : null}
              </AccordionDetails>
            </Accordion>
            <Grid style={{ padding: "10px", marginTop: "15px" }}>
              <Grid container item xs={12} className={classes.resumValues}>
                <Grid item xs={4}>
                  <Typography variant="h1">Tarifas</Typography>
                  <Typography variant="h2">
                    {"R$" + props.aereo.total?.toFormatDefault() + " "}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h1">Extras</Typography>
                  <Typography variant="h2">
                    {"R$" + props.aereo.taxes?.toFormatDefault() + " "}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h1">Total</Typography>
                  <Typography variant="h2">
                    {"R$" + props.aereo.total_geral?.toFormatDefault() + " "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.info}>
              <Typography>
                {" "}
                <b>Informações para Embarque</b>{" "}
              </Typography>
              <Typography>
                {" "}
                Apresente-se em nosso checkin com 2 horas de antecedência em
                voos nacionais e com 3 horas de antecedência em voos
                internacionais.{" "}
              </Typography>
              <Typography> Levar documento original: </Typography>
              <Grid>
                <li>
                  {" "}
                  <b>Carteira de Identidade</b> para vôos nacionais.
                </li>
                <li>
                  {" "}
                  <b>Passaporte</b> e os vistos necessários para entrada no pais
                  de destino para voos internacionais.
                </li>
              </Grid>
              <Typography>
                Informações sobre validade de PASSAPORTE, VACINAS e VISTOS que
                possam ser necessários para sua viagem devem ser consultados com
                as respectivas embaixadas ou despachantes de vistos.
              </Typography>
              <Typography>
                Verifique essa necessidade para todos os países envolvidos na
                viagem, mesmo aqueles onde há apenas uma escala.
              </Typography>
              <Typography>
                Lembre-se que alguns países exigem que o passaporte tenha uma
                validade mínima de 6 meses para embarcar.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
