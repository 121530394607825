import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const enviarArquivos = {

    addVoucherPDF: async (dtoFile) => {
        const response = await api.post(`/BlobAzure/pdfVoucher/AddPdf`, dtoFile, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    deleteVoucherPDF: async (dtoFile) => {
        const response = await api.put('/BlobAzure/pdfVoucher/delPdf', dtoFile, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getAllVoucherPdf: async (dtoFile) => {
        const response = await api.post('/BlobAzure/pdfVoucher/getAllLinks', dtoFile, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getVoucherPdf: async (dtoFile) => {
        const response = await api.post('/BlobAzure/pdfVoucher/getPdf', dtoFile, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    }
}

export default enviarArquivos;
