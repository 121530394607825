import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Slide, Typography } from '@material-ui/core';
import { Close, ConfirmationNumberOutlined, DeleteOutlineOutlined, FlightLandOutlined, FlightTakeoffOutlined, SendOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import EnumStatus from '../../../enums/enumStatusBooking';
import pacoteAgenteRepositorio from '../../../repositorios/pacoteAgente';
import DialogConfirm from '../DialogConfirm';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MobileDialogPacote(props) {
    const classes = useStyles();
    const [data, setData] = useState({ idpacote: null, itens: [], titulo: null })
    const [opcaoSelecionada, setOpcaoSelecionada] = useState('');

    const doneAction = () => {
        props.handleClickOpenDialogConfirm()
        props.setOpenDialogPacote(false)
        props.setLoad(!props.load)
    }

    const handleCloseDialog = () => {
        props.setOpenDialogPacote(false)
    }

    const handleClickCancelarPacote = () => {
        setOpcaoSelecionada('Cancelar')
        props.handleClickOpenDialogConfirm()
    }

    const handleClickFinalizarPacote = () => {
        setOpcaoSelecionada('Finalizar')
        props.handleClickOpenDialogConfirm()
    }

    const handleClickReenviarPacote = () => {
        setOpcaoSelecionada('Reenviar')
        props.handleClickOpenDialogConfirm()
    }

    useEffect(() => {
        (async () => {
            try {
                props.setIsLoading(true)
                if (isNaN(props.selectedPacote.idpacote) || props.selectedPacote.idpacote <= 0) {
                    return
                }
                let response = await pacoteAgenteRepositorio.getItensPacote(props.selectedPacote.idpacote);
                setData(response.data)
            } catch (e) {
                props.setSnackVariant('error');
                props.setSnackMessage('Erro ao pesquisar dados do pacote.');
                props.setSnackVisible({ visible: true });
            } finally {
                props.setIsLoading(false)
            }
        })()
    }, [props.selectedPacote])

    return (
        <div>
            <Dialog
                onClose={() => handleCloseDialog()}
                open={props.openDialogPacote}
                fullScreen
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xl'}
            >
                <DialogTitle style={{ background: '#C62828' }}>
                    <Typography style={{ color: '#ffffff' }}>{data.idpacotetenant} - {data.titulo}</Typography>
                    <Typography style={{ color: '#ffffff' }}>({data.viajante})</Typography>
                </DialogTitle>
                <DialogContent style={{ padding: '5px' }}>
                    <DialogContentText >
                        <DialogConfirm
                            {...{
                                idpacote: data.idpacote,
                                openDialogConfirm: props.openDialogConfirm,
                                type: opcaoSelecionada,
                                setIsLoading: props.setIsLoading,
                                handleClickOpenDialogConfirm: props.handleClickOpenDialogConfirm,
                                setSnackVariant: props.setSnackVariant,
                                setSnackMessage: props.setSnackMessage,
                                setSnackVisible: props.setSnackVisible,
                                doneAction
                            }}
                        />
                        <List>
                            {data.itens.map((item) => (
                                <ListItem divider>
                                    <Grid container spacing={1} style={{ border: '1px solid #c62828', padding: '10px', borderRadius: '20px' }}>
                                        <Grid item xs={6}>
                                            <Typography variant='h6' style={{ display: 'flex', alignItems: 'center' }}>
                                                {item.idreserva}
                                                <ConfirmationNumberOutlined
                                                    style={item.havevoucher ? { color: 'green', marginLeft: '5px' } : { color: 'red', marginLeft: '5px', display: 'flex' }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="caption"
                                                style={{
                                                    width: 'fit-content',
                                                    borderStyle: 'solid',
                                                    borderWidth: 1,
                                                    borderColor: EnumStatus.getInfoBooking(item.cod_status_reserva, item.cod_status_etapaaprovacao).color,
                                                    background: EnumStatus.getInfoBooking(item.cod_status_reserva, item.cod_status_etapaaprovacao).color,
                                                    color: 'white', borderRadius: 3, padding: 5
                                                }}
                                            >
                                                {
                                                    EnumStatus.getInfoBooking(item.cod_status_reserva, item.cod_status_etapaaprovacao).text
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{item.titulo}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            Data de criação: {item.datacriacao}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={1} className={classes.iconCustom}>
                                                    <FlightTakeoffOutlined fontSize='small' />
                                                </Grid>
                                                <Grid item xs={5} className={classes.iconCustom}>
                                                    {item.origem}
                                                </Grid>
                                                <Grid item xs={1} className={classes.iconCustom}>
                                                    <FlightLandOutlined fontSize='small' />
                                                </Grid>
                                                <Grid item xs={5} className={classes.iconCustom}>
                                                    {item.destino}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            Ida e Volta: {item.idavolta}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Data ida: {item.dataida}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Tipo: {item.tipo}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                            }
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props?.selectedPacote?.status !== 3 && props?.selectedPacote?.status !== 4 ? (
                        <Button size='small' onClick={() => handleClickCancelarPacote()} className={classes.button} endIcon={<DeleteOutlineOutlined />}>Cancelar</Button>
                    ) : null}
                    {props?.selectedPacote?.status === 2 || props?.selectedPacote?.status === 3 ? (
                        <Button
                            size='small'
                            onClick={props?.selectedPacote?.status === 2 ? handleClickFinalizarPacote : handleClickReenviarPacote}
                            className={classes.button} endIcon={<SendOutlined />}
                        >
                            {props?.selectedPacote?.status === 2 ? "Finalizar" : "Reenviar"}
                        </Button>
                    ) : null}
                    <Button size='small' onClick={() => handleCloseDialog()} className={classes.button} endIcon={<Close />}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default MobileDialogPacote;
