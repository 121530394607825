import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buscarAereo, buscarVoosIda, buscarVoosIdaComBagagem, buscarVoosVolta, buscarVoosVoltaComBagagem, setPassageiro, setVooIda, setVoosMultiTrecho, setVooVolta } from '../../../actions/LinkAereoActions';
import Dashboard from '../../dashboard';
import ListaAereoReservaFacil from './reservafacil';
import { useStyles } from './styles';

export const ListaAereos = props => {

    useEffect(() => {
        if (
            props.voosIda.length <= 0) {
            props.history.push('/consultaAereo');
        }
    }, [])

    return (
        <div>
            <ListaAereoReservaFacil {...{ voos: props.voosIda, aereos: props.aereos, ...props }} />
        </div>
    )
}

export const Aereo = props => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Dashboard {...props} component={ListaAereos} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        aereos: state.linkAereo.aereos,
        voosIda: state.linkAereo.voosIda,
        passageiro: state.linkAereo.passageiro,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ buscarAereo, buscarVoosIda, buscarVoosVolta, setPassageiro, setVooIda, setVooVolta, buscarVoosIdaComBagagem, buscarVoosVoltaComBagagem, setVoosMultiTrecho }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Aereo);
