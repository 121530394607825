import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import LinesTextField from '../../../../../components/LinesTextField';
import USearchField from '../../../../../components/usearchfield';
import { useStyles } from './styles';

export const CadastroExcecao = props => {
    const classes = useStyles();
    const [cidade, setCidade] = useState({
        value: props.excecao.cidade ? props.excecao.cidade.idcidade : null,
        label: props.excecao.cidade ? props.excecao.cidade.nome : null
    })

    const handleChange = event => {
        props.setExcecao({
            ...props.excecao,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeAuto = (campo, valor) => {
        props.setExcecao({
            ...props.excecao,
            [campo]: valor
        });
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                    <USearchField {...{ itens: props.cidades, value: cidade, setValue: setCidade }} name="idcidade" onChange={handleChangeAuto} label="Cidade" />
                </Grid>
                <Grid item xs={6}>
                    <LinesTextField variant="outlined" margin="dense" name="palavrachave" value={props.excecao.palavrachave || ''} onChange={handleChange} label="Tipo" />
                </Grid>
                <Grid item xs={6}>
                    <LinesTextField variant="outlined" margin="dense" name="limite" value={props.excecao.limite || ''} onChange={handleChange} label="Limite" />
                </Grid>
            </Grid>
        </div>
    )
};

export default CadastroExcecao;
