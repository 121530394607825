import { Box, Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { ImportExportOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { DialogExportExcel } from "../../DialogExportExcel";
import { useStyles } from "./styles";


export const EcoGestaoResumoDesk = (props) => {
    const classes = useStyles();
    const [openConfirmDialogExport, setOpenConfirmDialogExport] = useState(false)

    const handleClickDialogExport = () => {
        setOpenConfirmDialogExport(true)
    }

    const data = props.dataList.grupos;

    return (
        <>
            {openConfirmDialogExport ? (
                <DialogExportExcel
                    {...{ openConfirmDialogExport, setOpenConfirmDialogExport }}
                />

            ) : null}
            {data.length === 0 ? (
                <Typography variant="h5" className={classes.gridTitle}> Não há registros para essa data </Typography>
            ) : (
                <Grid container >
                    <Grid item xs={12} className={classes.gridTitle}>
                        <Typography variant="h5">Data: <b>{props.dataPartida.toLocaleDateString('pt-Br')}</b></Typography>
                        {/* <Tooltip title={<Typography>Exportar resumo</Typography>}>
                            <Button onClick={() => handleClickDialogExport()} className={classes.button} variant="contained" fullWidth >
                                <ImportExportOutlined />
                            </Button>
                        </Tooltip> */}
                    </Grid>
                    {data.map((item) => (
                        <Box mt={2} mb={2} border={'2px solid #C62828'} borderRadius={'10px'} width={'-webkit-fill-available'}>
                            <Grid item xs={12} className={classes.gridTitle}>
                                <Typography variant="h5"><b>Nº {item.idgrouptenant} - {item.nome}</b></Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridTitle}>
                                <Typography variant="h6">Destino: <b>{item.cidade}</b></Typography>
                                <Typography className={classes.typoLittleFont}>Aeroporto: {item.aeroporto}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                <Typography ><b>Nome</b></Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography ><b>Centro de custo</b></Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography ><b>Hórario</b></Typography>
                                            </TableCell>
                                            <TableCell align="center" style={{ minWidth: '175px' }}>
                                                <Typography ><b>Emissão CO2 (kg)</b></Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography >
                                                    <b>Endereço</b>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {item.passageiros.map((pax) => (
                                            <TableRow>
                                                <TableCell align="center">
                                                    <Typography >
                                                        {pax.nome}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography>
                                                        {pax.cc}
                                                    </Typography>
                                                    <Typography className={classes.typoLittleFont}>
                                                        {pax.ccNome}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography>
                                                        {pax.horadesc}
                                                    </Typography>
                                                    <Typography>
                                                        {pax.hora}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography style={item.isEco ? { color: '#43a047' } : { color: '#c62828' }}><b>{pax.carbono}</b></Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Typography>
                                                        {pax.pontoPartida}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </Grid>
                        </Box>
                    ))}

                </Grid>
            )
            }
        </>
    );
};

export default EcoGestaoResumoDesk;
