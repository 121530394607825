import { Card, Divider, ExpansionPanelDetails, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import LinesTextField from '../../../../../components/LinesTextField';
import Dashboard from '../../../../dashboard';
import { useStyles } from './styles';

export const ListaImportarExcel = props => {
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:480px)');
    const matches2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const matches3 = useMediaQuery('(min-width:600px) and (orientation: portrait)');

    return (
        <div className={classes.expansion}>
            <Typography className={classes.titulo}>Compras Consolidadas</Typography>
            <div className={classes.lista2}>
                <Card >
                    {!matches ? (
                        <div className={classes.testevai}>
                            <Typography className={classes.heading}>Importar Consolidado</Typography>
                            <LinesTextField label="N Fatura" />

                        </div>) : null}
                    <Divider />
                    <ExpansionPanelDetails className={classes.filtros} />
                </Card>
            </div>
            <div className={classes.cards}>
                <Card className={classes.card} />
            </div>
        </div>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaImportarExcel} />
        </div>
    )
};
export default Lista;
