import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { AddOutlined, ConfirmationNumberOutlined, DeleteOutlined, DeleteOutlineOutlined, HistoryOutlined, SendOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import EnumStatus from '../../../enums/enumStatusBooking';
import pacoteAgenteRepositorio from '../../../repositorios/pacoteAgente';
import DialogConfirm from '../DialogConfirm';
import DialogHistorico from '../DialogHistorico';
import DialogPacoteListAdd from '../DialogPacoteListAdd';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DialogPacote(props) {
    const classes = useStyles();
    const [openDialogPacoteListAdd, setOpenDialogPacoteListAdd] = useState(false);
    const [openDialogHistorico, setOpenDialogHistorico] = useState(false);
    const [data, setData] = useState({ idpacote: null, itens: [], titulo: null })
    const [selectedIdViajante, setSelectedIdViajante] = useState(0);
    const [selectedIdPacote, setSelectedIdPacote] = useState(0);
    const [opcaoSelecionada, setOpcaoSelecionada] = useState('');

    const doneAction = () => {
        props.handleClickOpenDialogConfirm()
        props.setOpenDialogPacote(false)
        props.setLoad(!props.load)
    }

    const handleClickOpenDialogPacoteListAdd = () => {
        setSelectedIdViajante(data.idviajante)
        setOpenDialogPacoteListAdd(!openDialogPacoteListAdd);
    }

    const handleClickOpenHistorico = () => {
        setSelectedIdPacote(data.idpacote)
        setOpenDialogHistorico(!openDialogHistorico)
    }

    const handleCloseDialog = () => {
        props.setOpenDialogPacote(false)
    }

    const handleClickFinalizarPacote = () => {
        setOpcaoSelecionada('Finalizar')
        props.handleClickOpenDialogConfirm()
    }

    const handleClickReenviarPacote = () => {
        setOpcaoSelecionada('Reenviar')
        props.handleClickOpenDialogConfirm()
    }

    const handleClickCancelarPacote = () => {
        setOpcaoSelecionada('Cancelar')
        props.handleClickOpenDialogConfirm()
    }

    const handleClickDeleteItem = async (idpacote, idpacoteitens) => {
        try {
            props.setIsLoading(true)
            let dto = {
                idpacoteitens: idpacoteitens
            }
            await pacoteAgenteRepositorio.putRemoveItemPacote(idpacote, dto)
            getData()
        } catch (e) {
            props.setSnackVariant('error');
            props.setSnackMessage('Erro ao deletar item.');
            props.setSnackVisible({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    async function getData() {
        try {
            props.setIsLoading(true)
            setOpenDialogPacoteListAdd(false)
            if (isNaN(props.selectedPacote.idpacote) || props.selectedPacote.idpacote <= 0) {
                return
            }
            let response = await pacoteAgenteRepositorio.getItensPacote(props.selectedPacote.idpacote);
            setSelectedIdViajante(0)
            setData(response.data)
        } catch (e) {
            props.setSnackVariant('error');
            props.setSnackMessage('Erro ao pesquisar dados do pacote.');
            props.setSnackVisible({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    const ajustaSting = (assunto) => {
        if (!assunto) {
            return '';
        }
        const textos = assunto.split('<br />');
        return (
            <div>
                {textos.map(item => (
                    <div>{item}</div>
                ))}
            </div>
        );
    }

    useEffect(() => {
        setSelectedIdViajante(0);
        (async () => {
            getData()
        })()
    }, [props.selectedPacote])

    return (
        <div>
            <Dialog
                onClose={() => handleCloseDialog()}
                open={props.openDialogPacote}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={'xl'}
            >
                <DialogTitle style={{ background: '#C62828' }}>
                    <Typography style={{ color: '#ffffff' }}>{data.idpacotetenant} - {data.titulo}  ({data.viajante})</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {openDialogPacoteListAdd ? (
                            <DialogPacoteListAdd
                                {...{
                                    openDialogPacoteListAdd,
                                    getData,
                                    handleClickOpenDialogPacoteListAdd,
                                    selectedIdViajante,
                                    selectedIdPacote: data.idpacote,
                                    setIsLoading: props.setIsLoading,
                                    setSnackVariant: props.setSnackVariant,
                                    setSnackMessage: props.setSnackMessage,
                                    setSnackVisible: props.setSnackVisible,
                                }}
                            />
                        ) : null}
                        {openDialogHistorico ? (
                            <DialogHistorico
                                {...{
                                    openDialogHistorico,
                                    handleClickOpenHistorico,
                                    selectedIdPacote,
                                    setIsLoading: props.setIsLoading,
                                    setSnackVariant: props.setSnackVariant,
                                    setSnackMessage: props.setSnackMessage,
                                    setSnackVisible: props.setSnackVisible,
                                }}
                            />
                        ) : null}
                        {props.openDialogConfirm ? (
                            <DialogConfirm
                                {...{
                                    idpacote: data.idpacote,
                                    openDialogConfirm: props.openDialogConfirm,
                                    type: opcaoSelecionada,
                                    setIsLoading: props.setIsLoading,
                                    handleClickOpenDialogConfirm: props.handleClickOpenDialogConfirm,
                                    setSnackVariant: props.setSnackVariant,
                                    setSnackMessage: props.setSnackMessage,
                                    setSnackVisible: props.setSnackVisible,
                                    doneAction
                                }}
                            />
                        ) : null}
                        <TableContainer component={Paper} style={{ maxWidth: '92vw' }}>
                            <Table size={'small'} className={classes.tableCustom} stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Título</TableCell>
                                        <TableCell>Data Criação</TableCell>
                                        <TableCell>Origem</TableCell>
                                        <TableCell>Destino</TableCell>
                                        <TableCell>Data ida</TableCell>
                                        <TableCell>Ida e Volta</TableCell>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Voucher</TableCell>
                                        {props.selectedPacote.status !== 3 && props.selectedPacote.status !== 4 ? (
                                            <TableCell>Excluir</TableCell>
                                        ) : (null)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.itens.map((row) => (
                                        <TableRow key={row.idreserva}>
                                            <TableCell>{row.idreserva}</TableCell>
                                            <TableCell>
                                                <Typography variant="caption"
                                                    style={{
                                                        width: 'fit-content',
                                                        borderStyle: 'solid',
                                                        borderWidth: 1,
                                                        borderColor: EnumStatus.getInfoBooking(row.cod_status_reserva, row.cod_status_etapaaprovacao).color,
                                                        background: EnumStatus.getInfoBooking(row.cod_status_reserva, row.cod_status_etapaaprovacao).color,
                                                        color: 'white', borderRadius: 3, padding: 5
                                                    }}
                                                >
                                                    {
                                                        EnumStatus.getInfoBooking(row.cod_status_reserva, row.cod_status_etapaaprovacao).text
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{ajustaSting(row.titulo)}</TableCell>
                                            <TableCell>{row.datacriacao}</TableCell>
                                            <TableCell>{row.origem}</TableCell>
                                            <TableCell>{row.destino}</TableCell>
                                            <TableCell>{row.dataida}</TableCell>
                                            <TableCell>{row.idavolta}</TableCell>
                                            <TableCell>{row.tipo}</TableCell>
                                            <TableCell>
                                                <ConfirmationNumberOutlined
                                                    style={row.havevoucher ? { color: 'green' } : { color: 'red' }}
                                                />
                                            </TableCell>
                                            {props.selectedPacote.status !== 3 && props.selectedPacote.status !== 4 ? (
                                                <TableCell>
                                                    <Tooltip title={"Exluir item no pacote não ira cancelar a reserva."}>
                                                        <IconButton onClick={() => handleClickDeleteItem(data.idpacote, row.idpacoteitens)}>
                                                            <DeleteOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            ) : (null)}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props?.selectedPacote?.status === 2 || props?.selectedPacote?.status === 3 ? (
                        <Button
                            onClick={props?.selectedPacote?.status === 2 ? handleClickFinalizarPacote : handleClickReenviarPacote}
                            className={classes.button} endIcon={<SendOutlined />}
                        >
                            {props?.selectedPacote?.status === 2 ? "Finalizar" : "Reenviar"}
                        </Button>
                    ) : null}
                    {props?.selectedPacote?.status !== 3 && props?.selectedPacote?.status !== 4 ? (
                        <Button onClick={handleClickCancelarPacote} className={classes.button} endIcon={<DeleteOutlineOutlined />}>
                            Cancelar
                        </Button>
                    ) : null}
                    <Button onClick={handleClickOpenHistorico} className={classes.button} endIcon={<HistoryOutlined />}>
                        Histórico
                    </Button>
                    {props?.selectedPacote?.status !== 3 && props?.selectedPacote?.status !== 4 ? (
                        <Button onClick={handleClickOpenDialogPacoteListAdd} className={classes.button} endIcon={<AddOutlined />}>
                            Adicionar
                        </Button>
                    ) : null}
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default DialogPacote;
