import React from 'react';
import { Backdrop, Typography } from '@material-ui/core';
import Image from '../../images/travel1.gif';
import { useStyles } from './styles';

export const ULoaderVooMessage = props => {
  const classes = useStyles();

  return (
    <>
      {props.isLoading && props.message && (
        <Backdrop className={classes.Backdrop} open={props.isLoading}>
          <div style={{ textAlign: 'center' }}>
            <img src={Image} style={{ width: 350, height: 350 }} alt="Loading" />
            <Typography variant="h6" style={{ marginTop: "-30px", color: '#fff' }}>
              {props.message}
            </Typography>
          </div>
        </Backdrop>
      )}
    </>
  );
};

export default ULoaderVooMessage;
