import { Box, Button, Divider, Fab, IconButton, ListItemIcon, Paper, Tab, Tabs, Tooltip, Typography, useTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AddCircleOutlined, DeleteOutlined, PublishOutlined, SaveOutlined, SearchOutlined } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LinesTextField from '../../../components/LinesTextField';
import UDatePicker from '../../../components/udatepicker';
import ULoaderVoo from '../../../components/uloadervoo';
import UMaskTextfield from '../../../components/umasktextfield';
import USearchField from '../../../components/usearchfield';
import USelect from '../../../components/uselect';
import USwitch from '../../../components/uswitch';
import Enums from '../../../enums';
import EnumCompany from '../../../enums/enumFlightCompany';
import cepBRL from '../../../repositorios/api/cep/cep';
import cidadeRepositorio from '../../../repositorios/cidade';
import estadoRepositorio from '../../../repositorios/estado';
import fidelidadeRepositorio from '../../../repositorios/fidelidade';
import paisRepositorio from '../../../repositorios/pais';
import pessoa_documentosRepositorio from '../../../repositorios/pessoa/documentos';
import DialogShowImage from '../../../utils/dialogShowImage';
import sendPhotos from '../../../utils/SendPhotos';
import ConfirmDelete from '../confirmDelete';
import { useStyles } from './styles';

const AntTabs = withStyles({
    root: {
        background: '#616161',
        color: 'white'
    },
    indicator: {
        backgroundColor: '#c62828',
    },
})(Tabs);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CadastroUsuario = props => {
    const classes = useStyles();
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [datanascimento, setDataNascimento] = useState(props.pessoa.idpessoa ? new Date(props.pessoa.datanascimento) : new Date() || new Date())
    const [value, setValue] = React.useState(0);
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    const [cidades, setCidades] = useState([]);
    const [estados, setEstados] = useState([]);
    const [erroCpf, setErroCpf] = useState(false);
    const [erroTelefone, setErroTelefone] = useState(false);
    const [erroContatoEmergencia, setErroContatoEmergencia] = useState(false);
    const [erroCep, setErroCep] = useState(false);
    const [erroRne, setErroRne] = useState(false);
    const [paises, setPaises] = useState([]);
    const [genero, setGenero] = useState(props.pessoa.idpessoa ? props.pessoa.sexo : 0);
    const [ciaAerea, setCiaAerea] = useState();
    const [ciaAereas, setCiaAereas] = useState([]);
    const [tamanhoCamisa, setTamanhoCamisa] = useState(props.pessoa.idpessoa ? props.pessoa.tamanhocamisa : 0);
    const [tipoSanguineo, setTipoSanguineo] = useState(props.pessoa.idpessoa ? props.pessoa.tiposanguineo : 0);
    const [fatorRh, setFatorRh] = useState(props.pessoa.idpessoa ? props.pessoa.fatorrh : 0);
    const [ativo, setAtivo] = useState((props.pessoa.idpessoa ? props.pessoa.ativo : true) || null);
    const [ativoAvisoWpp, setAtivoAvisoWpp] = useState(props.pessoa.avisoWpp);
    const [isLoading, setIsLoading] = useState(false);
    const [nomeAlternativo, setNomeAlternativo] = useState({ nomeAlternativoNome: '', nomeAlternativoSobrenome: '', numeroCartaoCPF: '' });
    const [nomeAlternativos, setNomeAlternativos] = useState([]);
    const [openDialogShowImage, setOpenDialogShowImage] = useState(false);
    const [typeDocument, setTypeDocument] = useState('');
    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
    const [urlImagemShow, setUrlImagemShow] = useState(null);
    const [initialStateFields, setInitialStateFields] = useState({
        nome: props.isNewViajante,
        sobrenome: props.isNewViajante,
        rg: props.isNewViajante,
        dni: props.isNewViajante,
        genero: props.isNewViajante,
        datanascimento: true,
        nacionalidade: props.isNewViajante,
        telefone: props.isNewViajante,
        telefonecomercial: props.isNewViajante,
        contatoemergencial: props.isNewViajante,
        enviarAvisoWhats: props.isNewViajante,
        nomeAlternativo: false,
        email: props.isNewViajante
    });
    const [document, setDocument] = useState({
        passaporte: {
            docnumeropassaporte: null,
            docdatepassaporte: null,
            docurlpassaporte: null,
            docnomepassaporte: null,
            docsobrenomepassaporte: null,
            cor: null,
        }
    });
    const [documentFile, setDocumentFile] = useState({
        docfilepassaporte: null,
    });
    const [cidade, setCidade] = useState({
        label: '',
        value: 0
    });
    const [estado, setEstado] = useState({
        label: '',
        value: 0
    });
    const [pais, setPais] = useState({
        label: '',
        value: 0
    });
    const [paisNacionalidade, setPaisNacionalidade] = useState({
        label: 'BRASIL',
        value: 1
    });
    const tiposSanguineo = [{
        value: Enums.tipoSanguineo.a,
        label: 'A'
    }, {
        value: Enums.tipoSanguineo.b,
        label: 'B'
    }, {
        value: Enums.tipoSanguineo.ab,
        label: 'AB'
    }, {
        value: Enums.tipoSanguineo.o,
        label: 'O'
    }]
    const tamanhosCamisa = [{
        value: Enums.tamanhoCamisa.pp,
        label: 'PP'
    }, {
        value: Enums.tamanhoCamisa.p,
        label: 'P'
    }, {
        value: Enums.tamanhoCamisa.m,
        label: 'M'
    }, {
        value: Enums.tamanhoCamisa.g,
        label: 'G'
    }, {
        value: Enums.tamanhoCamisa.gg,
        label: 'GG'
    }, {
        value: Enums.tamanhoCamisa.xgg,
        label: 'XGG'
    }]
    const fatoresRh = [{
        value: Enums.fatorRh.positivo,
        label: 'Positivo'
    }, {
        value: Enums.fatorRh.negativo,
        label: 'Negativo'
    }]
    const generos = [{
        value: Enums.genero.masculino,
        label: 'Masculino'
    }, {
        value: Enums.genero.feminino,
        label: 'Feminino'
    }]

    const handleChange = event => {
        props.setPessoa({
            ...props.pessoa,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeNomeAlternativo = (event) => {
        setNomeAlternativo({ ...nomeAlternativo, [event.target.name]: event.target.value })
    }

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    }

    const handleChangeAuto = (campo, valor) => {
        props.setPessoa({
            ...props.pessoa,
            [campo]: valor
        })
    }

    function changeInitialStateFields() {
        if (props.pessoa.nacionalidade !== 1) {
            setInitialStateFields(
                {
                    nome: false,
                    sobrenome: false,
                    rg: false,
                    dni: false,
                    genero: false,
                    datanascimento: false,
                    nacionalidade: false,
                    telefone: false,
                    telefonecomercial: false,
                    contatoemergencial: false,
                    enviarAvisoWhats: false,
                    email: false
                }
            )
            return;
        }
        setInitialStateFields(
            {
                nome: !initialStateFields.nome,
                sobrenome: !initialStateFields.sobrenome,
                rg: !initialStateFields.rg,
                dni: !initialStateFields.dni,
                genero: !initialStateFields.genero,
                datanascimento: !props.isUnderAge,
                nacionalidade: !initialStateFields.nacionalidade,
                telefone: !initialStateFields.telefone,
                telefonecomercial: !initialStateFields.telefonecomercial,
                contatoemergencial: !initialStateFields.contatoemergencial,
                enviarAvisoWhats: !initialStateFields.enviarAvisoWhats,
                email: !initialStateFields.email,
            }
        )
    }

    const handleClickAddAlternativeName = async () => {
        let dto = {
            idpessoa: props.pessoa.idpessoa,
            value: ciaAerea,
            nome: nomeAlternativo.nomeAlternativoNome,
            sobrenome: nomeAlternativo.nomeAlternativoSobrenome,
            numero: nomeAlternativo.numeroCartaoCPF,
        }

        if (!dto.value) {
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('error')
            props.setSnackMessage('Favor selecionar uma cia.')
            return
        }
        if (!dto.nome.trim()) {
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('error')
            props.setSnackMessage('Nome não pode ser vazio.')
            return
        }
        if (!dto.sobrenome.trim()) {
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('error')
            props.setSnackMessage('Nome não pode ser vazio.')
            return
        }

        let response
        try {
            setIsLoading(true)
            response = await fidelidadeRepositorio.postNewAlternativeName(dto);
            if (response.status === 207) {
                props.setSnackVisible({
                    visible: true
                });
                props.setSnackVariant('warning')
                props.setSnackMessage(response.data.message)
                return
            }
            setCiaAereas(ciaAereas.filter(i => i.value !== ciaAerea))
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('success')
            props.setSnackMessage(response.data.message)
            setNomeAlternativos(response.data.itens)
            setNomeAlternativo({
                nomeAlternativoNome: '',
                nomeAlternativoSobrenome: '',
                numeroCartaoCPF: '',
            })
        } catch {
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('error')
            props.setSnackMessage(response.data.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleClickDeleteAlternativeName = async (item) => {
        setIsLoading(true)
        let response
        try {
            let dto = {
                value: item.value
            }
            response = await fidelidadeRepositorio.postDeleteAlternativeName(dto)
            if (response.status === 207) {
                props.setSnackVisible({
                    visible: true
                });
                props.setSnackVariant('warning')
                props.setSnackMessage(response.data.message)
                return
            }
            setNomeAlternativos(nomeAlternativos.filter(i => i.value !== item.value))
            await searchOptionsCias()
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('success')
            props.setSnackMessage(response.data.message)
        } catch {
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('error')
            props.setSnackMessage(response.data.message)
        } finally {
            setIsLoading(false)
        }
    }

    async function searchOptionsCias() {
        let response = null;
        if (props.pessoa.idpessoa && permissaoDashboard.usuarioLinestur) {
            try {
                response = await fidelidadeRepositorio.getLoyaltyCompaniesOptions(props.pessoa.idpessoa);
                if (response.status === 207) {
                    props.setSnackVisible({
                        visible: true
                    });
                    props.setSnackVariant('warning')
                    props.setSnackMessage(response.data.message)
                    return
                }
                setCiaAereas(response.data.itens)
            } catch (e) {
                props.setSnackVisible({
                    visible: true
                });
                props.setSnackVariant('error')
                props.setSnackMessage(response.data.message)
            }
        }
    }

    function handleChangeDocument(campo, valor) {
        let letDocument = document;

        letDocument = { ...document, passaporte: { ...document.passaporte, [campo]: valor } };

        setDocument(letDocument)
    }

    async function saveDocument(type) {
        switch (type) {
            case 'passaporte':
                if (!(document.passaporte.docnumeropassaporte && document.passaporte.docdatepassaporte)) {
                    props.setSnackVisible({ visible: true });
                    props.setSnackVariant('warning')
                    props.setSnackMessage('Há itens vazios no passaporte.')
                    return
                }
                try {
                    setIsLoading(true)
                    let dto = {
                        numero: document.passaporte.docnumeropassaporte,
                        validade: document.passaporte.docdatepassaporte,
                        file: await sendPhotos(documentFile.docfilepassaporte),
                        nome: document.passaporte.docnomepassaporte,
                        sobrenome: document.passaporte.docsobrenomepassaporte,
                        idpessoa: props.pessoa.idpessoa
                    }
                    let response = await pessoa_documentosRepositorio.postDocumentoPassaporte_pessoa(dto);
                    getDocuments()

                    setIsLoading(false)
                    setDocument({ ...document, passaporte: response.data })
                    props.setSnackVisible({ visible: true });
                    props.setSnackVariant('success')
                    props.setSnackMessage('Documento adicionado com sucesso.')
                } catch (e) {
                    props.setSnackVisible({ visible: true });
                    props.setSnackVariant('error')
                    props.setSnackMessage('Falha ao salvar o passaporte.')
                } finally {
                    setIsLoading(false)
                }
                break;
        }
    }

    async function searchCEP_BRL() {
        setIsLoading(true)
        try {
            if (props.pessoa.cep !== null) {
                let cep_BRL_edited = props.pessoa.cep.replace('-', '');
                let response = await cepBRL.getCEP_BRL(cep_BRL_edited);
                props.setSnackVisible({ visible: true });
                props.setSnackVariant('info')
                props.setSnackMessage('Dados do CEP encontrados.')

                let cidadetemp = cidades.find(item => item.label === response.data.localidade.toUpperCase());
                let estadotemp = estados.find(item => item.uf === response.data.uf)
                let paistemp = paises.find(item => item.label === "BRASIL")
                setPais(paistemp)
                setEstado(estadotemp)
                setCidade(cidadetemp)
                props.setPessoa({
                    ...props.pessoa,
                    bairro: response?.data?.bairro,
                    rua: response?.data?.logradouro,
                    complemento: response?.data?.complemento,
                    idcidade: cidadetemp?.value,
                    idestado: estadotemp?.value,
                    idpais: paistemp?.value
                })
            }
        } catch (e) {
            props.setSnackVisible({ visible: true });
            props.setSnackVariant('error')
            props.setSnackMessage('Erro ao pesquisar CEP.')
        }
        finally {
            setIsLoading(false)
        }

    }

    function clearComponents() {
        setDocument(
            {
                ...document, passaporte: {
                    docnumeropassaporte: null,
                    docdatepassaporte: null,
                    docurlpassaporte: null,
                    docnomepassaporte: null,
                    docsobrenomepassaporte: null,
                    cor: null,
                }
            }
        )
    }

    const getDocuments = async () => {
        try {
            setIsLoading(true)
            if (props.pessoa.idpessoa) {
                let response = await pessoa_documentosRepositorio.getDocumentos_pessoa(props.pessoa.idpessoa);
                setDocument(response.data);
            }

        } catch (e) {
            props.setSnackVisible({ visible: true });
            props.setSnackVariant('error');
            props.setSnackMessage("Falha ao buscar o(s) documento(s).");
        } finally {
            setIsLoading(false)
        }

    }

    const handleInputChange = (event, type) => {
        let letDocument = document;
        if (event.target.files[0]) {
            switch (type) {
                case 'passaporte':
                    let fileLet = (event.target.files[0]);
                    letDocument = { ...documentFile, docfilepassaporte: fileLet };
                    setDocumentFile(letDocument)
                    break;
                default:
                    break;
            }
        }
    }

    const handleClickShowDoc = (item) => {
        if (item === null || item === undefined || item === '') {
            return;
        }
        setOpenDialogShowImage(true);
        setUrlImagemShow(item);
    }

    const handleClickOpenDialogConfirm = (type) => {
        setTypeDocument(type);
        setOpenDialogConfirmDelete(true);
    }

    useEffect(() => {
        if (props.pessoa) {
            if (value === 3) {
                getDocuments()
            }
        }
    }, [value])

    useEffect(() => {
        setInitialStateFields({
            ...initialStateFields,
            datanascimento: !props.isUnderAge
        })
    }, [props.isUnderAge])

    useEffect(() => {
        if ((props.isNewViajante && props.pessoa.nome) || (props.isUnderAge)) {
            changeInitialStateFields()
        }
        if (props.pessoa.datanascimento) {
            setDataNascimento(new Date(props.pessoa.datanascimento))
        }
    }, [props.pessoa.datanascimento])

    useLayoutEffect(() => {
        (async () => {
            try {
                setIsLoading(true)
                await cidadeRepositorio.getSearchField().then(response => {
                    setCidades(response)
                    setCidade(response.find(item => item.value === props.pessoa.idcidade))
                })
                await estadoRepositorio.getSearchField().then(response => {
                    setEstados(response)
                    setEstado(response.find(item => item.value === props.pessoa.idestado))
                })
                await paisRepositorio.getSearchField().then(response => {
                    setPaises(response)
                    setPais(response.find(item => item.value === props.pessoa.idpais))
                    if (props.pessoa.idpessoa) {
                        setPaisNacionalidade(response.find(item => item.value === props.pessoa.nacionalidade))
                    }
                })

                if (props.pessoa.idpessoa) {
                    let response;
                    await searchOptionsCias()
                    try {
                        response = await fidelidadeRepositorio.getListAlternativeName(props.pessoa.idpessoa);
                        setNomeAlternativos(response.data.itens)
                    } catch {
                        props.setSnackVisible({
                            visible: true
                        });
                        props.setSnackVariant('error')
                        props.setSnackMessage("Falha ao buscar nomes alternativos")
                    }
                }

            } catch (e) {
                props.setSnackVisible({
                    visible: true
                });
                props.setSnackVariant('error')
                props.setSnackMessage("Falha ao buscar dados.")
            } finally {
                setIsLoading(false)
            }
        })()
    }, [])

    return (
        <>
            <ULoaderVoo isLoading={isLoading} />
            <DialogShowImage {...{ src: urlImagemShow, open: openDialogShowImage, setOpen: setOpenDialogShowImage }} />
            {/* {openDialogConfirmDelete ? ( */}
            <ConfirmDelete {...{
                openDialogConfirmDelete, setOpenDialogConfirmDelete, getDocuments, clearComponents, setIsLoading, typeDocument,
            }}
                setSnackVisible={() => props.setSnackVisible()}
                setSnackVariant={() => props.setSnackVariant()}
                setSnackMessage={() => props.setSnackMessage()}
                idpessoa={props.pessoa.idpessoa}
            />
            {/* ) : null} */}
            <Box className={classes.root}>
                <AntTabs
                    variant="scrollable"
                    scrollButtons="on"
                    value={value}
                    onChange={handleChangeTab}
                >
                    <Tab label="Pessoal" {...a11yProps(0)} />
                    <Tab label="Cartão Fidelidade" {...a11yProps(1)} />
                    <Tab label="Endereço" {...a11yProps(2)} />
                    <Tab label="Documentos" {...a11yProps(3)} />
                    {/* <Tab label="Adicional" {...a11yProps(3)} /> */}
                    <Button style={{ color: 'white' }} onClick={() => {
                        props.setIsUnderAge(false);
                        props.setCadastroOpen(false)
                    }}>Fechar</Button>
                </AntTabs>
                <TabPanel value={value} index={0}>
                    {value === 0 ? (
                        <div className={classes.body}>
                            <div className={classes.linha}>
                                <div className={classes.coluna}>
                                    {props.pessoa.idpessoa ? (
                                        <USwitch {...{ checked: ativo, setChecked: setAtivo, label: 'Ativo' }} name="ativo" onChange={handleChangeAuto} />
                                    ) : null}
                                    <USwitch {...{ checked: ativoAvisoWpp, disabled: initialStateFields.enviarAvisoWhats, setChecked: setAtivoAvisoWpp, label: 'Enviar avisos via Whatsapp' }} name="avisoWpp" onChange={handleChangeAuto} />
                                    <form className={classes.linha} autoComplete={false} >
                                        <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item xs={fullscreen ? 12 : 6}>
                                                <LinesTextField
                                                    disabled={initialStateFields.nome}
                                                    required
                                                    name="nome"
                                                    fullWidth
                                                    label="Nome"
                                                    variant="outlined"
                                                    value={props.pessoa.nome || ''}
                                                    onChange={handleChange}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 6}>
                                                <LinesTextField
                                                    fullWidth
                                                    disabled={initialStateFields.sobrenome}
                                                    required
                                                    name="sobrenome"
                                                    label="Sobrenome"
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    value={props.pessoa.sobrenome || ''}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                <LinesTextField
                                                    required
                                                    disabled={initialStateFields.rg}
                                                    fullWidth
                                                    name="rg"
                                                    onChange={handleChange}
                                                    label="RG"
                                                    variant="outlined"
                                                    value={props.pessoa.rg || ''}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4} style={{ display: 'flex' }}>
                                                <UMaskTextfield
                                                    disabled={props.pessoa?.verifiedCPF}
                                                    autoFocus={true}
                                                    helperText="Formato de CPF incorreto"
                                                    alwaysShowMask={false}
                                                    id={"cpfTextField"}
                                                    maskChar=''
                                                    mask="999.999.999-99"
                                                    error={erroCpf}
                                                    label="CPF"
                                                    value={props.pessoa.cpf || ''}
                                                    name='cpf'
                                                    onChange={handleChange}
                                                />
                                                {props.pessoa.idpessoa ? (
                                                    <IconButton size='small'>
                                                        <SearchOutlined onClick={() => props.handleButtonClickCheckCPF()} style={{ color: '#c62828' }}></SearchOutlined>
                                                    </IconButton>
                                                ) : null}
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                <LinesTextField
                                                    disabled={initialStateFields.dni}
                                                    fullWidth
                                                    name="dni"
                                                    onChange={handleChange}
                                                    label="DNI"
                                                    variant="outlined"
                                                    value={props.pessoa.dni || ''}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                <USelect disabled={initialStateFields.genero} {...{ itens: generos, value: genero, setValue: setGenero, label: 'Gênero' }} name="sexo" onChange={handleChange} />
                                            </Grid>
                                            {/* <Grid item xs={fullscreen ? 12 : 4}>
                                            <UDatePicker disabled={initialStateFields.dataValidade} {...{ date: dataValidade, setDate: setDataValidade, label: "Data Validade" }} name="validadedocumento" onChange={handleChangeAuto} />
                                        </Grid> */}
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                <UDatePicker readOnly={initialStateFields.datanascimento && props.pessoa.nacionalidade === 1} {...{ date: datanascimento, setDate: setDataNascimento, label: "Data de Nascimento *" }} name="datanascimento" onChange={handleChangeAuto} />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                {/* <LinesTextField
                                                disabled={initialStateFields.nacionalidade}
                                                fullWidth
                                                name="nacionalidade"
                                                onChange={handleChange}
                                                label="Nacionalidade"
                                                variant="outlined"
                                                value={props.pessoa.nacionalidade || ''}
                                                margin="dense"
                                            /> */}
                                                <USearchField
                                                    {...{ itens: paises, value: paisNacionalidade, setValue: setPaisNacionalidade, label: 'Nacionalidade' }}
                                                    name="nacionalidade"
                                                    onChange={handleChangeAuto}
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                <UMaskTextfield
                                                    helperText="Formato de telefone incorreto"
                                                    mask="(99)99999-9999"
                                                    disabled={initialStateFields.telefone}
                                                    error={erroTelefone}
                                                    label="Telefone"
                                                    style={{ marginLeft: '10px' }}
                                                    value={props.pessoa.telefone || ''}
                                                    name="telefone"
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                <LinesTextField
                                                    fullWidth
                                                    disabled={initialStateFields.telefonecomercial}
                                                    name="telefonecomercial"
                                                    onChange={handleChange}
                                                    label="Telefone comercial"
                                                    variant="outlined"
                                                    value={props.pessoa.telefonecomercial || ''}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 4}>
                                                <UMaskTextfield
                                                    disabled={initialStateFields.contatoemergencial}
                                                    helperText="Formato de telefone incorreto"
                                                    mask="(99)99999-9999"
                                                    error={erroContatoEmergencia}
                                                    label="Contato Emergencial"
                                                    value={props.pessoa.nomecontatoemergencial || ''}
                                                    name="nomecontatoemergencial"
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={fullscreen ? 12 : 12}>
                                                <LinesTextField
                                                    fullWidth
                                                    disabled={initialStateFields.email}
                                                    name="email"
                                                    onChange={handleChange}
                                                    label="Email"
                                                    variant="outlined"
                                                    value={props.pessoa.email || ''}
                                                    margin="dense"
                                                />
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {value === 1 ? (
                        <div className={classes.body}>
                            <div className={classes.linha}>
                                <div className={classes.coluna}>
                                    <Grid container>
                                        {props.pessoa.idpessoa ? (
                                            <Grid item xs={12}>
                                                <Grid container >
                                                    {permissaoDashboard.usuarioLinestur ? (
                                                        <Grid item xs={12}>
                                                            <Box border={1} borderRadius={8} width={'100%'} borderColor={'#c62828'}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={fullscreen ? 12 : 10}>
                                                                        <Grid container >
                                                                            <Grid item xs={fullscreen ? 12 : 6} className={classes.gridCenterCustom}>
                                                                                <LinesTextField
                                                                                    // style={{ width: '20vw', marginTop: '0px', marginBottom: '0px', marginRight: '15px' }}
                                                                                    disabled={initialStateFields.nomeAlternativo}
                                                                                    fullWidth
                                                                                    name="nomeAlternativoNome"
                                                                                    onChange={handleChangeNomeAlternativo}
                                                                                    label="Nome Alternativo"
                                                                                    variant="outlined"
                                                                                    value={nomeAlternativo.nomeAlternativoNome}
                                                                                    margin="dense"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={fullscreen ? 12 : 6} className={classes.gridCenterCustom}>
                                                                                <LinesTextField
                                                                                    // style={{ width: '20vw', marginTop: '0px', marginBottom: '0px', marginRight: '15px' }}
                                                                                    disabled={initialStateFields.nomeAlternativo}
                                                                                    fullWidth
                                                                                    name="nomeAlternativoSobrenome"
                                                                                    onChange={handleChangeNomeAlternativo}
                                                                                    label="Sobrenome Alternativo"
                                                                                    variant="outlined"
                                                                                    value={nomeAlternativo.nomeAlternativoSobrenome}
                                                                                    margin="dense"
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={fullscreen ? 12 : 6} className={classes.gridCenterCustom}>
                                                                                <USelect {...{ itens: ciaAereas, value: ciaAerea, setValue: setCiaAerea, label: 'Cias' }} name="cias" onChange={handleChange} />
                                                                            </Grid>
                                                                            <Grid item xs={fullscreen ? 12 : 6} className={classes.gridCenterCustom}>
                                                                                <LinesTextField
                                                                                    disabled={initialStateFields.nomeAlternativo}
                                                                                    fullWidth
                                                                                    name="numeroCartaoCPF"
                                                                                    onChange={handleChangeNomeAlternativo}
                                                                                    label={"Nº do cartão"}
                                                                                    variant="outlined"
                                                                                    value={nomeAlternativo.numeroCartaoCPF}
                                                                                    margin="dense"
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={fullscreen ? 12 : 2} className={classes.gridCenterCustom}>
                                                                        <Button onClick={() => handleClickAddAlternativeName()} className={classes.button3} style={{ display: 'center', alignItems: 'center' }} endIcon={<AddCircleOutlined />}>
                                                                            Adicionar
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Grid>
                                                    ) : (
                                                        null
                                                    )}
                                                    <Grid item xs={12}>
                                                        <Grid container style={{ display: 'grid', justifyContent: 'center', marginTop: '15px' }} >
                                                            {fullscreen ?
                                                                nomeAlternativos.map(item => (
                                                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                        <Tooltip title={EnumCompany.getLogoCompanhiaAereaEnum(item.iata).nome}>
                                                                            <img
                                                                                src={EnumCompany.getLogoCompanhiaAereaEnum(item.iata).url_low}
                                                                                width={60}
                                                                                height={50}
                                                                                style={{ height: '30px', width: '40px', marginRight: '1vw' }}
                                                                            />
                                                                        </Tooltip>
                                                                        <Typography variant='body2'>{EnumCompany.getLogoCompanhiaAereaEnum(item.iata).nome}</Typography>
                                                                        <Typography variant='body2'>{item.nome}</Typography>
                                                                        {item.numero !== '' ? (
                                                                            <Typography variant='body2'>- Nº: {item.numero}</Typography>

                                                                        ) : null}
                                                                        {props.pessoa.idpessoa && permissaoDashboard.usuarioLinestur ? (
                                                                            <ListItemIcon>
                                                                                <IconButton onClick={() => handleClickDeleteAlternativeName(item)} style={{ color: '#c62828' }}>
                                                                                    <DeleteOutlined />
                                                                                </IconButton>
                                                                            </ListItemIcon>
                                                                        ) : null}
                                                                    </Grid>
                                                                ))
                                                                : (
                                                                    <Paper elevation={1} style={{ width: '500px' }}>
                                                                        {nomeAlternativos.map(item => (
                                                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                <Tooltip title={EnumCompany.getLogoCompanhiaAereaEnum(item.iata).nome}>
                                                                                    <img
                                                                                        src={EnumCompany.getLogoCompanhiaAereaEnum(item.iata).url_low}
                                                                                        width={60}
                                                                                        height={50}
                                                                                        style={{ height: '30px', width: '40px', marginRight: '1vw' }}
                                                                                    />
                                                                                </Tooltip>
                                                                                <Typography variant='body2'>{EnumCompany.getLogoCompanhiaAereaEnum(item.iata).nome}</Typography>
                                                                                <Typography variant='body2'>{item.nome}</Typography>
                                                                                {item.numero !== '' ? (
                                                                                    <Typography variant='body2'>- Nº: {item.numero}</Typography>

                                                                                ) : null}
                                                                                {props.pessoa.idpessoa && permissaoDashboard.usuarioLinestur ? (
                                                                                    <ListItemIcon>
                                                                                        <IconButton onClick={() => handleClickDeleteAlternativeName(item)} style={{ color: '#c62828' }}>
                                                                                            <DeleteOutlined />
                                                                                        </IconButton>
                                                                                    </ListItemIcon>
                                                                                ) : null}
                                                                            </Grid>
                                                                        ))}
                                                                    </Paper>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </TabPanel>
                <TabPanel value={value} index={2} key={value === 2 ? true : false}>
                    {value === 2 ? (
                        <div className={classes.body}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <USearchField
                                        {...{ itens: paises, value: pais, setValue: setPais, label: 'Pais' }}
                                        name="idpais"
                                        onChange={handleChangeAuto}
                                    />
                                </Grid>
                                <Grid item xs={3} style={{ display: 'flex', flexDirection: 'row' }}>
                                    <UMaskTextfield
                                        required
                                        helperText="Formato de CEP incorreto"
                                        mask="99999-999"
                                        error={erroCep}
                                        label="CEP"
                                        style={{ marginLeft: '10px' }}
                                        variant="outlined"
                                        value={props.pessoa.cep || ''}
                                        margin="dense"
                                        name="cep"
                                        onChange={handleChange}
                                    />
                                    {pais?.label === "BRASIL" ?
                                        (
                                            <IconButton>
                                                <SearchOutlined style={{ color: '#c62828' }} onClick={() => searchCEP_BRL()} />
                                            </IconButton>
                                        ) : (null)
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    <USearchField
                                        disabled={pais?.value === 0 || pais === null}
                                        {...{ itens: estados, value: estado, setValue: setEstado, label: 'Estado' }}
                                        name="idestado"
                                        onChange={handleChangeAuto}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <USearchField
                                        disabled={
                                            estado?.value === 0 || estado === null ||
                                            pais?.value === 0 || pais === null
                                        }
                                        {...{ itens: cidades, value: cidade, setValue: setCidade, label: 'Cidade' }}
                                        name="idcidade"
                                        onChange={handleChangeAuto}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <LinesTextField
                                        disabled={
                                            cidade?.value === 0 || cidade === null ||
                                            estado?.value === 0 || estado === null
                                        }
                                        fullWidth
                                        className={classes.bairro}
                                        name="bairro"
                                        onChange={handleChange}
                                        label="Bairro"
                                        variant="outlined"
                                        value={props.pessoa.bairro || ''}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LinesTextField
                                        disabled={
                                            cidade?.value === 0 || cidade === null ||
                                            estado?.value === 0 || estado === null
                                        }
                                        fullWidth
                                        name="rua"
                                        className={classes.rua}
                                        onChange={handleChange}
                                        label="Rua"
                                        variant="outlined"
                                        value={props.pessoa.rua || ''}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <LinesTextField
                                        disabled={
                                            cidade?.value === 0 || cidade === null ||
                                            estado?.value === 0 || estado === null
                                        }
                                        fullWidth
                                        name="numero"
                                        className={classes.numero}
                                        onChange={handleChange}
                                        label="Numero"
                                        variant="outlined"
                                        value={props.pessoa.numero || ''}
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LinesTextField
                                        disabled={
                                            cidade?.value === 0 || cidade === null ||
                                            estado?.value === 0 || estado === null
                                        }
                                        fullWidth
                                        name="complemento"
                                        onChange={handleChange}
                                        label="Complemento"
                                        variant="outlined"
                                        value={props.pessoa.complemento || ''}
                                        margin="dense"
                                    />
                                </Grid>
                            </Grid>
                            <Divider />

                        </div>
                    ) : null}
                </TabPanel>
                <TabPanel value={value} index={3} key={value === 3 ? true : false}>
                    {value === 3 ? (
                        <div className={classes.body3}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={fullscreen ? 12 : 4} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                            <Typography align='center'>
                                                Passaporte
                                                <IconButton onClick={() => handleClickOpenDialogConfirm('passaporte')}>
                                                    <DeleteOutlined />
                                                </IconButton>
                                            </Typography>
                                            <Box width={'-webkit-fill-available'} style={{ display: 'flex', justifyContent: 'center' }}>
                                                {isLoading ?
                                                    (
                                                        <Skeleton variant="circle" width={75} height={75} />
                                                    )
                                                    : (
                                                        <>
                                                            {document.passaporte.docurlpassaporte?.toUpperCase().includes('.PDF') ?
                                                                <a href={document.passaporte.docurlpassaporte} download>
                                                                    <IconButton>
                                                                        <Avatar variant='circle' src={document.passaporte.docurlpassaporte} className={classes.bigAvatar2} style={{ boxShadow: `0px 0px 20px 5px ${document.passaporte.cor}` }} />
                                                                    </IconButton>
                                                                </a>
                                                                :
                                                                <IconButton onClick={() => handleClickShowDoc(document.passaporte.docurlpassaporte)}>
                                                                    <Avatar variant='circle' src={document.passaporte.docurlpassaporte} className={classes.bigAvatar2} style={{ boxShadow: `0px 0px 20px 5px ${document.passaporte.cor}` }} />
                                                                </IconButton>
                                                            }
                                                        </>
                                                    )}
                                            </Box>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <LinesTextField
                                                        required
                                                        name="docnomepassaporte"
                                                        fullWidth
                                                        label="Nome no Documento"
                                                        variant="outlined"
                                                        value={document?.passaporte?.docnomepassaporte || ''}
                                                        onChange={(e) => handleChangeDocument(e.target.name, e.target.value)}
                                                        margin="dense"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinesTextField
                                                        required
                                                        name="docsobrenomepassaporte"
                                                        fullWidth
                                                        label="Sobrenome no Documento"
                                                        variant="outlined"
                                                        value={document?.passaporte?.docsobrenomepassaporte || ''}
                                                        onChange={(e) => handleChangeDocument(e.target.name, e.target.value)}
                                                        margin="dense"
                                                    />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <LinesTextField
                                                        required
                                                        name="docnumeropassaporte"
                                                        fullWidth
                                                        label="Nº de Passaporte"
                                                        variant="outlined"
                                                        value={document?.passaporte?.docnumeropassaporte || ''}
                                                        onChange={(e) => handleChangeDocument(e.target.name, e.target.value)}
                                                        margin="dense"
                                                    />
                                                </Grid>
                                                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <label htmlFor="button-file" >
                                                        <input
                                                            id="button-file"
                                                            name='button-file'
                                                            style={{ display: 'none' }}
                                                            required
                                                            accept="image/*, application/pdf"
                                                            type="file"
                                                            onChange={(e) => handleInputChange(e, 'passaporte')}
                                                        />
                                                        <Fab
                                                            size='small'
                                                            variant='circular'
                                                            style={{ boxShadow: 'none', color: '#c62828', background: '#ffffff' }}
                                                            component='span'>
                                                            <PublishOutlined />
                                                        </Fab>
                                                    </label>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <UDatePicker
                                                        // readOnly={initialStateFields.datanascimento} 
                                                        {...{ date: document.passaporte.docdatepassaporte, label: "Validade" }}
                                                        name="docdatepassaporte"
                                                        onChange={(c, u) => handleChangeDocument(c, u)}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Fab
                                                        size='small'
                                                        variant='circular'
                                                        style={{ boxShadow: 'none', color: '#c62828', background: '#ffffff' }}
                                                        component='span'
                                                        disabled={isLoading}
                                                        onClick={() => saveDocument('passaporte')}
                                                    >
                                                        <SaveOutlined />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    ) : null}
                </TabPanel>
                <TabPanel value={value} index={4} key={value === 4 ? true : false} >
                    {value === 4 ? (
                        <div className={classes.body}>
                            <form className={classes.linha} autoComplete={false}>
                                <div className={classes.tiposanguineo}>
                                    <USelect {...{ itens: tiposSanguineo, value: tipoSanguineo, setValue: setTipoSanguineo, label: 'Tipo Sanguíneo' }} name="tiposanguineo" onChange={handleChange} />
                                </div>
                                <div className={classes.fatorrh}>
                                    <USelect {...{ itens: fatoresRh, value: fatorRh, setValue: setFatorRh, label: 'Fator RH' }} name="fatorrh" onChange={handleChange} />
                                </div>
                                <div className={classes.tamanhocamisa}>
                                    <USelect {...{ itens: tamanhosCamisa, value: tamanhoCamisa, setValue: setTamanhoCamisa, label: 'Tamanho Camisa' }} name="tamanhocamisa" onChange={handleChange} />
                                </div>
                            </form>
                            <form className={classes.linha} autoComplete={false}>
                                <UMaskTextfield
                                    required
                                    helperText="Formato de RNE incorreto"
                                    mask="9999999-9"
                                    error={erroRne}
                                    label="RNE"
                                    style={{ marginLeft: '10px' }}
                                    variant="outlined"
                                    value={props.pessoa.rne || ''}
                                    margin="dense"
                                    name="rne"
                                    onChange={handleChange}
                                />
                                <LinesTextField
                                    fullWidth
                                    className={classes.doencacronica}
                                    name="doencacronica"
                                    onChange={handleChange}
                                    label="Doença crônica"
                                    variant="outlined"
                                    value={props.pessoa.doencacronica || ''}
                                    margin="dense"
                                />
                            </form>
                            <form className={classes.linha} autoComplete={false}>
                                <LinesTextField
                                    fullWidth
                                    name="remediocontrolado"
                                    onChange={handleChange}
                                    label="Remédio controlado"
                                    variant="outlined"
                                    value={props.pessoa.remediocontrolado || ''}
                                    margin="dense"
                                />
                                <LinesTextField
                                    fullWidth
                                    className={classes.intolerancia}
                                    name="intoleranciaalimentar"
                                    onChange={handleChange}
                                    label="Intorância alimentar"
                                    variant="outlined"
                                    value={props.pessoa.intoleranciaalimentar || ''}
                                    margin="dense"
                                />
                            </form>
                            <form className={classes.linha} autoComplete={false}>
                                <LinesTextField
                                    fullWidth
                                    name="obs"
                                    onChange={handleChange}
                                    id="standard-multiline-flexible"
                                    multiline
                                    rows="10"
                                    label="Observação"
                                    variant="outlined"
                                    value={props.pessoa.obs || ''}
                                    margin="dense"
                                />
                            </form>
                        </div>
                    ) : null}
                </TabPanel>


            </Box >
        </>
    )
};

export default CadastroUsuario
