import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
    root: {
        margin: "0px"
    },
    buttonFaturado: {
        color: '#c62828',
        background: 'transparent',
        borderColor: '#c62828',
        '&:hover': {
            color: 'white',
            background: '#c62828',
            borderColor: '#c62828',
        }
    },
    emissaoDialog: {
        height: "100%"
    },
    buttonEmissao: {
        background: green[600],
        color: 'white',
        width: '100%',
        '&:hover': {
            background: green[800]
        }
    },
    finalLinha: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    buttonCartao: {
        color: '#c62828',
        background: 'transparent',
        borderColor: '#c62828',
        '&:hover': {
            color: 'white',
            background: '#c62828',
            borderColor: '#c62828',
        }
    },
    buttonSave: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },
}));