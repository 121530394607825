import { Button, Typography } from "@mui/material";
import { EnumTipoVeiculo } from "../enums/enumVeiculos";

export const tiposdedespesas_Mock = [
  {
    value: 1,
    label: "Alimentação",
    sub: [
      {
        value: 1,
        label: "Jantar",
      },
      {
        value: 2,
        label: "Almoço",
      },
      {
        value: 3,
        label: "Café da manhã",
      },
      {
        value: 4,
        label: "Café da tarde",
      },
      {
        value: 5,
        label: "Lanche",
      },
      {
        value: 5,
        label: "Compras",
      },
    ],
  },
  {
    value: 2,
    label: "Estadia",
    sub: [
      {
        value: 1,
        label: "1 dia ou mais",
      },
      {
        value: 2,
        label: "1 semana ou mais",
      },
      {
        value: 3,
        label: "1 mês ou mais",
      },
    ],
  },
  {
    value: 3,
    label: "Transporte",
    sub: [
      {
        value: 1,
        label: "Ônibus",
      },
      {
        value: 2,
        label: "Uber",
      },
      {
        value: 3,
        label: "Gasolina",
      },
      {
        value: 4,
        label: "Pedágio",
      },
    ],
  },
  {
    value: 4,
    label: "Aéreo",
    sub: [
      {
        value: 1,
        label: "Passagem aérea",
      },
    ],
  },
  {
    value: 5,
    label: "Outros",
    sub: [],
  },
  {
    value: 6,
    label: "Hodometro",
    sub: [
      {
        value: 1,
        label: "Manual",
      },
      {
        value: 2,
        label: "Google maps",
      },
    ],
  },
  {
    value: 7,
    label: "Vazia",
    sub: [
      {
        value: 1,
        label: "Despesa",
      },
      {
        value: 2,
        label: "Hodometro",
      },
    ],
  },
];

export const tiposdehodometro_Mock = {
  manual: 1,
  googlemaps: 2,
};

export function getTipoDespesaForNumber(value, valueSubDespesa) {
  // Buscar entre os tipos de despesas
  let foundLabel = "";
  tiposdedespesas_Mock.some((tipo) => {
    if (tipo.value === value) {
      tipo.sub.some((value2) => {
        if (value2.value === valueSubDespesa) {
          foundLabel = tipo.label + " - " + value2.label;
        }
      });
    }
  });

  return (
    <>
      <Typography style={{ fontSize: "small" }}>
        {foundLabel}
      </Typography>
    </>
  );
}

export function getTipoHodometroForNumber(veiculo) {
  // Buscar entre os tipos de despesas
  const categoria = Object.keys(EnumTipoVeiculo.categoria).find(key => EnumTipoVeiculo.categoria[key] === veiculo.veiculo);
  const combustivel = Object.keys(EnumTipoVeiculo.combustivel).find(key => EnumTipoVeiculo.combustivel[key] === veiculo.combustivel);
  const porte = Object.keys(EnumTipoVeiculo.porte).find(key => EnumTipoVeiculo.porte[key] === veiculo.porte);

  return (
    <>
      <Typography style={{ marginTop: "-20px", fontSize: "small" }}>
        {[categoria, combustivel, porte].map(word => word.replace(/^\w/, c => c.toUpperCase())).join(' - ')}
      </Typography>
    </>
  );
}

export function getTipoDespesaForNumberUnique(value) {
  // Buscar entre os tipos de despesas
  let foundLabel = "";
  tiposdedespesas_Mock.some((tipo) => {
    if (tipo.value === value) {
      foundLabel = tipo.label;
    }
  });

  return foundLabel;
}

export function getTipoDespesaForNumberValue(value) {
  const itemEncontrado = tiposdedespesas_Mock.find(
    (item) => item.value == value
  );
  return itemEncontrado;
}
