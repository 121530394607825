import React from 'react';
import { Box, Typography, Card, CardContent, Button, Divider, Grid } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import CancelIcon from '@mui/icons-material/Cancel';
import { EnumTipo, formatarParaMoeda, formatarParaMoedaString, formatarParaMoedaStringComFlag, formatarValorComMoeda } from '../../../../enums/enumPrestacaoDeContas';

export const ButtonDialogComprovanteInter = ({ adiantamento }) => {
    return (
        <>
            <Card sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" component="div">
                            Pagamento { }
                        </Typography>
                        <Box display="flex" alignItems="center" color="green">
                            <CheckCircleOutlineIcon sx={{ marginRight: 1 }} />
                            <Typography variant="body2" fontWeight="bold">
                                Pago
                            </Typography>
                        </Box>
                    </Box>

                    <Typography variant="body2" color="text.secondary" sx={{ marginY: 2 }}>
                        Adiantamento pago em 
                    </Typography>

                    <Divider sx={{ marginY: 2 }} />

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                                Pago em
                            </Typography>
                            <Typography variant="body2">July 31, 2023</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                                Pedido em
                            </Typography>
                            <Typography variant="body2">August 20, 2023</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                                De
                            </Typography>
                            <Typography variant="body2">
                                Rian Sheik do egito
                                <br />
                                Linestur
                                <br />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                                Para
                            </Typography>
                            <Typography variant="body2">
                                Renato cariani
                                <br />
                                Growth Supplements
                                <br />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Typography variant="body2" sx={{ marginTop: 2 }}>
                        Moeda
                    </Typography>
                    <Typography variant="body1" fontWeight="bold">
                        {formatarParaMoedaStringComFlag(adiantamento.tipo_moeda, adiantamento.total)}
                        {/* Basic Plan – $9.99 */}
                    </Typography>

                    <Divider sx={{ marginY: 2 }} />

                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                                Nota
                            </Typography>
                            <Typography variant="body2">{adiantamento.nota}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                                Em dinheiro
                            </Typography>
                            <Typography variant="body2">{formatarValorComMoeda(EnumTipo.moedas.BRL.id, adiantamento.total_brl)}</Typography>
                        </Grid>
                        {/* <Grid item xs={2}>
                            <Typography variant="body2" color="text.secondary">
                                Qty
                            </Typography>
                            <Typography variant="body2">1</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" color="text.secondary">
                                Total Amount
                            </Typography>
                            <Typography variant="body2">$9.99</Typography>
                        </Grid> */}
                    </Grid>

                    <Divider sx={{ marginY: 2 }} />

                    <Typography variant="body1" fontWeight="bold">
                        Total: {formatarValorComMoeda(adiantamento.tipo_moeda, adiantamento.total)}
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}