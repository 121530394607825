import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  speedDial: {
    zIndex: 1000,
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    '& .MuiFab-primary': {
      color: '#fff',
      backgroundColor: '#c62828'
    },
  },
}));
