import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    button: {
        background: '#c62828',
        color: '#ffffff',
        '&:hover': { background: '#b91119', }
    },
    
}))