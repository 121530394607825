import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import LinesTextField from '../../../../components/LinesTextField';
import ULoaderVoo from '../../../../components/uloadervoo';
import aereoPassageiro from '../../../../repositorios/aereoPassageiro';

export const ChangeValuePax = (props) => {
    const open = props.openChangeValuePax;
    const typeEditPassageiro = props.itemSelectedChangeValuePax.type;
    const pax = props.itemSelectedChangeValuePax.item;
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState();

    const handleClickSalvar = async () => {
        try {
            setIsLoading(true);
            let dto = null
            if (typeEditPassageiro === 'tarifa') {
                dto = { idaereopassageiro: pax.idaereopassageiro, total: value, }
                await aereoPassageiro.editValor(dto);
            } else if (typeEditPassageiro === 'extras') {
                dto = { idaereopassageiro: pax.idaereopassageiro, taxes: value, }
                await aereoPassageiro.putEditTaxes(dto);
            }
            props.getBookingTicket();
            props.setOpenChangeValuePax(false);
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    const setConfigPage = useCallback(async () => {
        switch (typeEditPassageiro) {
            case 'tarifa': return {
                valor: pax.total,
                messageTitle: 'Alterar valor da tarifa'
            }
            case 'extras': return {
                valor: pax.taxes,
                messageTitle: 'Alterar valor dos extras'
            }
            default: break;
        }
    }, [pax, typeEditPassageiro])

    useEffect(() => {
        (async () => {
            setValue(setConfigPage()?.valor);
        })();
    }, [pax, setConfigPage])

    return (
        <>
            <Dialog open={open} onClose={() => props.setOpenChangeValuePax(false)} fullWidth maxWidth="xs" fullScreen={responsive}>
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#c62828' }}>
                        <Typography style={{ color: '#fff' }}>{setConfigPage()?.messageTitle}</Typography>
                        <IconButton onClick={() => props.setOpenChangeValuePax(false)} ><CloseOutlined style={{ color: '#fff' }} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <LinesTextField fullWidth variant='outlined' margin='dense' name="value" label="Novo Valor" value={value} onChange={(e) => setValue(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickSalvar} variant="contained" disabled={isLoading} style={{ color: '#fff', background: '#c62828', '&:hover': { background: '#b91119' } }}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default ChangeValuePax;
