import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useStyles } from './styles';
import { CloseOutlined } from '@material-ui/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
// import CustomizedSnackbars from '../../../components/snackbar';
import { HotelTicketDesktop } from './HotelTicketDesktop';
import hotelRepositorio from '../../../repositorios/hotel';
import { HotelTicketMobile } from './HotelTicketMobile';
import HotelChangeCC from './HotelChangeCC';
import { useCustomSnackbar } from '../../../components/snackbar2/NewSnackBar';

export const HotelTicket = (props) => {
    const classes = useStyles();
    const responsive = props.responsive;
    const open = props.openDialogBookingHotelTicket;
    const [dtoChangeCC, setDtoChangeCC] = useState({
        idpessoa: 0,
        idreserva: 0
    });
    const [openChangeCC, setOpenChangeCC] = useState(false);
    // const [snackState, setSnackState] = useState({ visible: false });
    // const [snackVariant, setSnackVariant] = useState('success');
    // const [snackMessage, setSnackMessage] = useState('');
    const permissaoDashboard = useSelector((state) => state.permissao.permissaoDashboard);
    const [hotelTicketData, setHotelTicketData] = useState({});

    const { enqueueSnackbar } = useCustomSnackbar();

    const getHotelTicket = useCallback(async () => {
        try {
            // props.setIsLoading(true)
            let responseReserva = await hotelRepositorio.getBookingHotelInLines(props.bookingSelected);
            setHotelTicketData(responseReserva);
        } catch (e) {
            enqueueSnackbar('Erro ao obter o hotel.', { variant: 'error' });
        } finally {
            // props.setIsLoading(false)
        }
    }, [setHotelTicketData, props])

    const handleClickEditHotel = async(model) => {
        try {
            props.setIsLoading(true)
            model.idreserva = props.bookingSelected;
            await hotelRepositorio.updateInfoBookingByRoom(model)
            getHotelTicket()
            enqueueSnackbar('Data editada com sucesso.', { variant: 'success' });
        } catch (e) {
            enqueueSnackbar('Erro ao editar as datas.', { variant: 'error' });
        } finally {
            props.setIsLoading(false)
        }
    }

    const openChangeCCButton = (idpessoa) => {
        setDtoChangeCC({
            idpessoa: idpessoa,
            idreserva: props.bookingSelected
        })
        setOpenChangeCC(true);
    }

    function formatarTelefone(telefone) {
        const numeroLimpo = telefone.replace(/\D/g, '');
        const temDDD = numeroLimpo.length > 8;
        const regex = temDDD ? /^(\d{2})(\d{4,5})(\d{4})$/ : /^(\d{4,5})(\d{4})$/;
        const telefoneFormatado = numeroLimpo.replace(regex, temDDD ? '($1) $2-$3' : '$1-$2');
        return telefoneFormatado;
    }

    useEffect(() => {
        getHotelTicket();
    }, [getHotelTicket]);


    return (
        <Dialog open={open} maxWidth='lg' fullWidth fullScreen={responsive}>
            {/* <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} /> */}
            {openChangeCC && (
                <HotelChangeCC {...{
                    open: openChangeCC,
                    setOpenChangeCC,
                    dtoChangeCC,
                    getHotelTicket
                }} />
            )}
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="body1" >Hotel {Object.keys(hotelTicketData).length !== 0 ? hotelTicketData.reserva.idreservatenant : ''}</Typography>
                <IconButton className={classes.closeButton} aria-label="close" onClick={() => props.setOpenDialogBookingHotelTicket(false)}>
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: responsive ? '0px' : '8px' }}>
                {Object.keys(hotelTicketData).length !== 0 ? responsive ?
                    (
                        <HotelTicketMobile {...{
                            permissaoDashboard,
                            hotelTicketData,
                            formatarTelefone,
                            openChangeCCButton
                        }} />
                    ) : (
                        <HotelTicketDesktop {...{
                            permissaoDashboard,
                            hotelTicketData,
                            formatarTelefone,
                            openChangeCCButton,
                            handleClickEditHotel
                        }} />
                    ) : null}
            </DialogContent>
        </Dialog>
    )
};
