import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Grow, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CancelOutlined, CheckCircleOutlineOutlined, PowerInputSharp } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React, { useLayoutEffect, useState } from "react";
import LinesTextField from "../../../components/LinesTextField";
import USearchField from "../../../components/usearchfield";
import ecogestaoRepositorio from "../../../repositorios/ecogestao";
import { useStyles } from "./styles";

export const DialogEditVeiculo = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [veiculos, setVeiculos] = useState([]);
    const [veiculo, setVeiculo] = useState(props.selectedGrupo.veiculo);

    const handleCloseDialogClose = () => {
        props.setOpenDialogEditVeiculo(false);
    }

    const searchOpcoes = async () => {
        try {
            let responseVeiculos = await ecogestaoRepositorio.getVeiculos()
            setVeiculos(responseVeiculos.data)
        } catch (error) {
            props.setSnackVariant('error');
            props.setSnackMessage('Falha ao buscar lista de motoristas.');
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false);
        }
    }

    const handleClickConfirm = async () => {
        try {
            props.setIsLoading(true)
            let dto = {
                idgroup: props.selectedGrupo.idgroup,
                idveiculo: veiculo.idveiculo,
                dadosPesquisa: {
                    dataPartida: props.dataPartida,
                    idCentroCusto: props.centroCusto ? props.centroCusto.value : 0,
                }
            }
            let response = await ecogestaoRepositorio.putSaveVeiculo(dto);
            props.setDataList({ ...props.dataList, grupos: response.data.grupos })
            props.setSnackVariant('success');
            props.setSnackMessage('Veículo alterado com sucesso.');
            props.setSnackState({ visible: true });
            handleCloseDialogClose()
        } catch (error) {
            props.setSnackVariant('error');
            props.setSnackMessage('Falha ao atribuir passageiro ao grupo.');
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    useLayoutEffect(() => {
        searchOpcoes()
    }, [])

    return (
        <>
            <Dialog
                open={props.openDialogEditVeiculo}
                maxWidth="sm"
                onClose={handleCloseDialogClose}
                fullWidth
                fullScreen={responsive}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={classes.typoTitle} style={{ fontSize: '14pt' }}><b>Nº {props.selectedGrupo.idgrouptenant} - {props.selectedGrupo.nome}</b></Typography>
                </DialogTitle>
                <DialogContent >
                    {props.isLoading ? (
                        <Skeleton variant='rect' height={'150px'} />
                    ) : (
                        <>
                            <Grid container>
                                <USearchField
                                    // disabled={!props.permissao.permiteeditarecogestao}
                                    label="Veículo"
                                    itens={veiculos}
                                    value={veiculo}
                                    setValue={setVeiculo}
                                />
                            </Grid>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleCloseDialogClose()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                    )}
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleClickConfirm()} className={classes.buttonDelete} startIcon={<CheckCircleOutlineOutlined />}>Confirmar</Button>
                    )}
                </DialogActions>
            </Dialog >
        </>
    )
}
