import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const emailRepositorio = {

    enviarEmail: async dtoEmail => {
        const response = await api.post('/email', dtoEmail, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    enviarEmailCotacao: async dtoEmailCotacao => {
        const response = await api.post('/Email/EnviarEmailCotacao', dtoEmailCotacao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    getAllContaEmail: async () => {
        const response = await api.get('/ContaEmail', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    getAllContaEmailSearchField: async () => {
        const response = await api.get('/ContaEmail', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response.data.map(item => {
            return {
                label: item.email,
                value: item.idcontaemail
            }
        });
    },

    getSearch: async text => {
        const response = await api.get(`/ContaEmail/Search?text=${text}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    addEmailAgente: async (dtoEmail) => {
        const response = await api.post('/ContaEmail', dtoEmail, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    updateEmailAgente: async (dtoEmail) => {
        const response = await api.put(`/ContaEmail/${dtoEmail.idcontaemail}`, dtoEmail, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
}

export default emailRepositorio;
