import React from 'react';
import { Button, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, FormControlLabel, FormGroup, Grid, Typography, IconButton } from '@mui/material';
import VercelIcon from '@mui/icons-material/Cloud'; // Placeholder icon for Vercel
import Auth0Icon from '@mui/icons-material/Security'; // Placeholder icon for Auth0
import GoogleCalendarIcon from '@mui/icons-material/Event'; // Placeholder icon for Google Calendar
import StripeIcon from '@mui/icons-material/Payment'; // Placeholder icon for Stripe
import StreetviewIcon from '@mui/icons-material/Streetview';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { CreditCard, Edit } from '@mui/icons-material';

const TotalInicialConfig = () => {
    return (
        <Grid container item xs={12}>
            {/* <Grid item xs={12}>
                <Typography>Sobre Viagens</Typography>
            </Grid> */}
            {/* <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "#222222" }}>
                    Limite do valor total inicial para iniciar uma viagem, esse valor é utilizado como limite permitido para o usuario pedir "adiantamento".
                </Typography>
            </Grid> */}
            <Grid item xs={12}>
                <List style={{ width: "100%", boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.7)", borderRadius: "10px" }}>
                    <ListItem>
                        <ListItemIcon>
                            <CreditCard />
                        </ListItemIcon>
                        <ListItemText primary={"Total inicial"} secondary={"R$ 1.500,00"} style={{ maxWidth: "80%" }} />
                        <ListItemSecondaryAction>
                            {/* <FormGroup>
                                <FormControlLabel control={<Switch defaultChecked />} label="Ativo" />
                            </FormGroup> */}
                            {/* 
                            <Button variant="contained" color="primary">
                                Install
                            </Button> 
                            */}
                            <IconButton>
                                <Edit />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};

export default TotalInicialConfig;
