import { makeStyles } from '@material-ui/core/styles';
import { indigo, yellow, amber, grey, red } from '@material-ui/core/colors';
import { Gradient } from '@material-ui/icons';
import { LinearProgress } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    root: {
        overflowX: 'hidden',
        overflowY: 'auto',
        width: '100%',
        marginBottom: theme.spacing(5),
        margin: 0
    },
    dialogTitle: {
        padding: theme.spacing(1, 3),
        background: '#c62828',
        color: 'black'
    },
    divtitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0.2, 2)
    },

    buttonCustom: {
        borderSpacing: '0px',
        margin: '10px',
        borderRadius: 30,
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900]
        },
        whiteSpace: 'nowrap'
    },



    iconeOrdem: {
        color: 'silver',
        marginLeft: theme.spacing(2)
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAutorizar: {
        borderStyle: 'solid',
        background: 'transparent',
        borderWidth: 1,
        borderColor: 'darkgreen',
        color: 'darkgreen',
        '&:hover': {
            background: 'darkgreen',
            color: 'white'
        }
    },
    buttonNegar: {
        borderStyle: 'solid',
        background: 'transparent',
        borderWidth: 1,
        borderColor: '#c62828',
        color: '#c62828',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        }
    },
    content: {
        padding: '0px !important',
        background: 'white'
    },
    titleAutorizacao: {
        background: '#fafafa',
        color: 'black',
        position: 'relative'
    },
    buttonAcao: {
        background: indigo[500],
        width: 80,
        color: 'white',
        '&:hover': {
            background: indigo[700]
        }
    },
    uibutton: {
        background: '#d1d1d1',
        boxShadow: '5px 5px 10px #b2b2b2, -5px -5px 10px #f0f0f0',
        color: 'grey'
    },
    imagem: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pagination: {
        padding: theme.spacing(0),
        paddingLeft: 0,
        marginLeft: 0
    },
    paper: {
        ['@media (max-width:480px)']: {
            padding: theme.spacing(0),
        },
        ['@media (max-width:736px) and (orientation: landscape)']: {
            padding: theme.spacing(0),
        },
        ['@media (max-width:900px) and (orientation: portrait)']: {
            padding: theme.spacing(0),
        },
        padding: theme.spacing(2)
    },
    heads: {
    },
    bodys: {
        fontSize: 12
    },
    toolbar: {
        width: '100%',
        height: 50,
        background: 'silver',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        padding: 5,
    },
    filter: {
        color: 'black'
    },
    linha: {
        padding: theme.spacing(1),
        background: '#d1d1d1'
    },
    icone2: {
        color: 'white'
    },
    icone: {
        marginRight: 10
    },
    mail: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: '100px',
        background: 'transparent',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: red[800],
        color: red[800],
        marginRight: '15px',
        height: 35,
        fontSize: '9px',
        '&:hover': {
            background: red[800],
            borderStyle: 'none',
            borderWidth: 0,
            borderColor: 'transparent',
            color: 'white',
        },

        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 'auto',
            marginRight: '2px',
        }
    },
    buttonSolicitacao: {
        color: 'white',
        background: indigo[500],
        '&:hover': {
            background: indigo[700]
        }
    },
    pesquisar: {
        margin: 0,
        position: 'relative',
        marginLeft: 0
    },
    dialogActions: {
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900]
        },
    },
    dialog: {
        background: "#616161",
        color: 'white',
        position: 'relative',
    },
    dialogtittle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    divpesquisar: {
        width: 'auto'
    },
    testess: {
        background: 'red',
        width: '100%',
        position: 'fixed',
        bottom: '0px'
    },
    testex: {
        marginBottom: theme.spacing(7)
    },
    container: {
        maxHeight: '72vh',
        minHeight: '72vh',

    },
}));
