import DateFnsUtils from '@date-io/date-fns';
import { createTheme } from '@material-ui/core';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import frLocale from "date-fns/locale/pt-BR";
import React, { useState } from 'react';
import LinesTextField from '../LinesTextField';

const Theme = createTheme({
    palette: {
        primary: {
            main: '#c62828'
        },
        secondary: {
            main: '#ffffff'
        }
    }
})

export const UTimePicker = props => {
    const [, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));

    const handleDateChange = date => {
        setSelectedDate(date);
        if (props.onChange) {
            props.onChange(props.name, date)
        }
    };

    return (
        <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <KeyboardTimePicker
                        id="time-picker"
                        className={props.className}
                        margin="dense"
                        name={props.name}
                        label={props.label}
                        value={props.value}
                        cancelLabel="Cancelar"
                        inputVariant="outlined"
                        invalidDateMessage="Formato de hora inválido!"
                        onChange={handleDateChange}
                        TextFieldComponent={LinesTextField}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
        </div>
    )
};

export default UTimePicker;
