import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const cidadeRepositorio = {

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/cidade', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        response.data.map(item => {
            lista.push({
                label: item.nome,
                value: item.idcidade
            })
        })
        return lista;
    }
}

export default cidadeRepositorio;
