import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { IconsSource } from '../../../../../../../images/Icons';

const LocationComponent = ({ onLocationChange, setType }) => {
    const [locationStatus, setLocationStatus] = useState(null);
    const [showTutorial, setShowTutorial] = useState(false);

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (!navigator.geolocation) {
            setLocationStatus('notSupported');
            return;
        }

        if (isSafari) {
            requestLocationPermission();
        } else {
            navigator.permissions.query({ name: 'geolocation' })
                .then((permission) => {
                    if (permission.state === 'granted') {
                        onLocationChange(true);
                    } else if (permission.state === 'prompt') {
                        requestLocationPermission();
                    } else if (permission.state === 'denied') {
                        setShowTutorial(true);
                    }
                }).catch((error) => {
                    setLocationStatus('error');
                    console.error('Error checking geolocation permission:', error);
                });
        }
    }, []);

    const getLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocationStatus('granted');
                onLocationChange(true);
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    setShowTutorial(true);
                } else {
                    setLocationStatus('error');
                    console.error('Geolocation error:', error);
                }
                onLocationChange(false);
            }
        );
    };

    const requestLocationPermission = () => {
        navigator.geolocation.getCurrentPosition(
            () => {
                setLocationStatus('granted');
                onLocationChange(true);
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    setShowTutorial(true);
                } else {
                    setLocationStatus('error');
                    console.error('Geolocation error:', error);
                }
                onLocationChange(false);
                
            }
        );
    };

    const handleTutorialCompletion = () => {
        setShowTutorial(false);
        requestLocationPermission();
    };

    return (
        <Container>
            {locationStatus === 'notSupported' && (
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        p: 4,
                        textAlign: 'center',
                        borderRadius: 2,
                    }}
                >
                    <img src={IconsSource.locationoff} alt="Location Off Icon"></img>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Não suportado
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, mb: 4 }}>
                        Seu navegador ou dispositivo não suporta geolocalização.
                    </Typography>
                    <Button onClick={() => setType(0)} variant="contained" color="primary" sx={{ width: '100%' }}>
                        Voltar
                    </Button>
                </Box>
            )}
            {locationStatus === 'error' && (
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        p: 4,
                        textAlign: 'center',
                        borderRadius: 2,
                    }}
                >
                    <img src={IconsSource.locationoff} alt="Location Off Icon"></img>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Erro
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, mb: 4 }}>
                        Ocorreu um erro ao obter sua localização.
                    </Typography>
                    <Button onClick={() => setType(0)} variant="contained" color="primary" sx={{ width: '100%' }}>
                        Voltar
                    </Button>
                </Box>
            )}
            {showTutorial && (
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        p: 4,
                        textAlign: 'center',
                        borderRadius: 2,
                    }}
                >
                    <img src={IconsSource.mapsandlocation} alt="Maps and Location Icon"></img>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        Sem acesso a sua localização
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2, mb: 4 }}>
                        Precisamos ter acesso a sua localização, siga o passo a passo
                        <ol>
                            <li>Acesse as configurações do seu navegador.</li>
                            <li>Encontre as configurações de localização.</li>
                            <li>Permita o acesso à localização deste site.</li>
                            <li>Para recarregar clique em "Concluído" ou recarregue a página.</li>
                        </ol>
                    </Typography>
                    <Button onClick={() => handleTutorialCompletion()} variant="contained" color="primary" sx={{ width: '100%' }}>
                        Concluído
                    </Button>
                </Box>
            )}
        </Container>
    );
};

export default LocationComponent;
