import api from '../../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const tempoAntecedenciaRepositorio = {

    get: async dtoDadosRelatorio => {
        const response = await api.post('/Relatorio/GetTempoAntecedencia', dtoDadosRelatorio, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },
}

export default tempoAntecedenciaRepositorio;
