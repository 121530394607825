import { Box, ButtonGroup, Grid, Grow, Paper, Radio, RadioGroup, Typography, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ConfirmationNumberOutlined, DeleteOutline } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/SearchRounded';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buscarAereo, buscarVoosIda, buscarVoosIdaComBagagem, buscarVoosVolta, buscarVoosVoltaComBagagem, setVooIda, setVoosMultiTrecho, setVooVolta } from '../../../actions/LinkAereoActions';
import { setDataPesquisaAereo, setVariadosAereosTipoPesquisa } from '../../../actions/VariadosAction';
import Snackbar from '../../../components/snackbar';
import UCheckbox from '../../../components/UCheckbox';
import UDatePicker from '../../../components/udatepicker';
import ULoaderVoo from '../../../components/uloadervoo';
import USearchField from '../../../components/usearchfield';
import UStepper from '../../../components/ustepper';
import Imagem from '../../../images/linesturHomemRelogio.svg';
import { buscarVoos } from '../../../LinkAereo';
import aeroportoRepositorio from '../../../repositorios/aeroporto';
import PoliticaViagemRepositorio from '../../../repositorios/politicaViagem';
import Dashboard from '../../dashboard';
import ImportarBilhete from './ImportarBilhete';
import { useStyles } from './syles';

const ConsultaAereo = props => {
    const classes = useStyles();
    const theme = useTheme();
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    const variadosReduxTipoPesquisa = useSelector(state => state.variados.aereosSearch);
    const variadosReduxTrechos = useSelector(state => state.variados.dtoPesquisaAereo);
    const [valueTipoVoo, setValueTipoVoo] = useState(variadosReduxTipoPesquisa.tipoPesquisaAct);
    const dispatch = useDispatch();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matches2 = useMediaQuery('(max-width:736px) and (orientation: landscape)');
    const matches3 = useMediaQuery('(max-width:900px) and (orientation: portrait)');
    const matches4 = useMediaQuery('(max-width:1280px) and (orientation: landscape)');
    const [isLoading, setIsLoading] = useState(false);
    const [validacao, setValidacao] = useState({ visible: false });
    const [modalImportar, setModalImportar] = useState(false);
    const [mensagemValidacao, setMensagemValidacao] = useState('');
    const [stepperAdultos, setStepperAdultos] = useState({
        value: 1,
        label: 'Adulto(s)',
        minValue: null,
        maxValue: 9
    });
    const [dataIda, setDataIda] = useState(new Date());
    const [dataVolta, setDataVolta] = useState(new Date());
    const [politicaViagem, setPoliticaViagem] = useState({});
    const [tipoCategoria, setTipoCategoria] = useState('1');
    const [tipoBagagem, setTipoBagagem] = useState(1);
    const [aeroportoIda, setAeroportoIda] = useState(null);
    const [aeroportoVolta, setAeroportoVolta] = useState(null);
    const [aeroportos, setAeroportos] = useState([]);
    const [grow, setGrow] = useState(false);
    const [logo, setLogo] = useState(false);
    const [voosMulti, setVoosMulti] = useState([]);
    const [mensagemPolitica, setMensagemPolitica] = useState('');
    const [stepperCriancas, setStepperCriancas] = useState({
        value: 0,
        label: 'Criança(s)',
        minValue: null,
        maxValue: 9
    });
    const [stepperBebes, setStepperBebes] = useState({
        value: 0,
        label: 'Bebê(s)',
        minValue: null,
        maxValue: 9
    });
    const [state, setState] = useState({
        tipoCategoria: '',
        tipoBagagem: '',
        origem: '',
        destino: '',
        dataIda: new Date(),
        dataVolta: new Date(),
        apenasVoosDiretos: false,
        tourcode: '',
        adultos: 1,
        criancas: 0,
        bebes: 0,
        cia: '',
    });

    const setCrianca = (value) => {
        setStepperCriancas(value)
        setState({
            ...state,
            criancas: value.value
        })
    }

    const setAdulto = (value) => {
        setStepperAdultos(value)
        setState({
            ...state,
            adultos: value.value
        })
    }
    const setBebes = (value) => {
        setStepperBebes(value)
        setState({
            ...state,
            bebes: value.value
        })
    }

    const handleApenasVoosDiretosChange = event => {
        setState(({
            ...state,
            [event.target.name]: !state.apenasVoosDiretos,
        }));
    }

    const handleChangeAuto = (campo, valor) => {
        setState({
            ...state,
            [campo]: valor
        })
    }

    const addVooMultiTrecho = (item, index) => {
        if (!item.origem) {
            setMensagemValidacao('Selecione uma origem para reservar');
            setValidacao({ visible: true });
            return;
        }
        if (!item.destino) {
            setMensagemValidacao('Selecione um destino para reservar');
            setValidacao({ visible: true });
            return;
        }
        if (item.origem === item.destino) {
            setMensagemValidacao('Origem não pode ser igual ao destino');
            setValidacao({ visible: true });
            return;
        }
        if (!item.dataIda) {
            setMensagemValidacao('Data de Partida não pode ser vazia');
            setValidacao({ visible: true });
            return;
        }

        let temDataMenor = voosMulti.find(v => item.dataIda < v.dataIda);

        if (temDataMenor) {
            setMensagemValidacao('Data de volta não pode ser menor que as datas anteriores');
            setValidacao({ visible: true });
            return;
        }

        const dto = {
            origem: item.destino,
            destino: null,
            dataIda: item.dataIda
        };

        setVoosMulti(oldValues => [...oldValues, dto]);
    }

    const handleChangeTipoVoo = (event) => {
        setValueTipoVoo(event.target.value);
        dispatch(setVariadosAereosTipoPesquisa(
            {
                tipoPesquisaAct: event.target.value
            }
        ))
    };

    const handleClickDeleteMulti = index => {
        setVoosMulti(voosMulti.filter((_, i) => i !== index));
    }

    const onConsultarClick = async () => {
        let dtoResponseVoos;
        props.buscarVoosIda([]);
        const dto = {
            tipoCategoria: tipoCategoria,
            tipoBagagem: tipoBagagem,
            origem: state.origem,
            destino: state.destino,
            dataIda: dataIda,
            dataVolta: dataVolta,
            apenasVoosDiretos: state.apenasVoosDiretos,
            tourcode: state.tourcode,
            adultos: state.adultos,
            criancas: state.criancas,
            bebes: state.bebes,
            cia: '',
            cotar: false,
            Trechos: [],
            tipoPesquisa: variadosReduxTipoPesquisa.tipoPesquisaAct,
            retorno: true,
        }
        dispatch(setDataPesquisaAereo(
            {
                dtoPesquisaAereo: dto
            }
        ))

        props.buscarAereo(dto);

        if (dto.bebes > dto.adultos) {
            setMensagemValidacao('Não podem haver mais bebês que adultos no bilhete');
            setValidacao({ visible: true });
            return;
        }

        if (dto.tipoCategoria === '3') {

            let temOrigemNaoPreenchido = voosMulti.find(item => !item.origem);

            if (temOrigemNaoPreenchido) {
                setMensagemValidacao('Favor preencher todas as origens');
                setValidacao({ visible: true });
                return;
            }

            let temDestinoNaoPreenchido = voosMulti.find(item => !item.destino);

            if (temDestinoNaoPreenchido) {
                setMensagemValidacao('Favor preencher todos os destinos');
                setValidacao({ visible: true });
                return;
            }

            let temDataNaoPreenchida = voosMulti.find(item => !item.dataIda || new Date(item.dataIda) < new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate()));

            if (temDataNaoPreenchida) {
                setMensagemValidacao('Favor preencher todas as datas em um formato válido');
                setValidacao({ visible: true });
                return;
            }

            if (politicaViagem.impedirreservaforadoprazo) {
                if (politicaViagem.antecedenciamedia !== null && politicaViagem.antecedenciamedia > 0) {

                    let dataPrazo = Moment().add(politicaViagem.antecedenciamedia, 'days');

                    let temDataAnterior = voosMulti.find(item => new Date(item.dataIda) < dataPrazo);

                    if (temDataAnterior) {
                        setMensagemValidacao('Não é possível consultar antes da antecedencia média estipulada!');
                        setValidacao({ visible: true });
                        return;
                    }
                }
            }

            let temOrigemIgual = voosMulti.reduce((acum, atual) => {
                if (atual.origem === atual.destino) {
                    return [...acum, true];
                }
                return [...acum, false];
            }, [])

            let achouOrigemIgual = temOrigemIgual.find(item => item);

            if (achouOrigemIgual) {
                setMensagemValidacao('Origem não pode ser igual ao destino');
                setValidacao({ visible: true });
                return;
            }

            let temDataMenor = voosMulti.reduce((acum, atual, index) => {
                if (index === 0) {
                    return [...acum, false];
                }
                if (atual.dataIda < voosMulti[index - 1].dataIda) {
                    return [...acum, true];
                }
                return [...acum, false];
            }, [])

            let achouDataMenor = temDataMenor.find(item => item);

            if (achouDataMenor) {
                setMensagemValidacao('Data de partida deve ser maior que a data anterior');
                setValidacao({ visible: true });
                return;
            }
        }

        if (dto.tipoCategoria !== '3') {
            if (!dto.origem) {
                setMensagemValidacao('Selecione uma origem para reservar');
                setValidacao({ visible: true });
                return;
            }
            if (!dto.destino) {
                setMensagemValidacao('Selecione um destino para reservar');
                setValidacao({ visible: true });
                return;
            }

            if (dto.origem === dto.destino) {
                setMensagemValidacao('Origem não pode ser igual ao destino');
                setValidacao({ visible: true });
                return;
            }

            if (new Date(dto.dataIda) < new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate())) {
                setMensagemValidacao('Não é permitido reservar com data menor do dia atual');
                setValidacao({ visible: true });
                return;
            }

            if (dto.adultos === 0 && dto.criancas === 0 && dto.bebes) {
                setMensagemValidacao('É necessário definir um passageiro para reservar');
                setValidacao({ visible: true });
                return;
            }

            if (dto.dataIda > dto.dataVolta && dto.tipoCategoria === '2') {
                setMensagemValidacao('Data de ida não pode ser maior que a data de volta');
                setValidacao({ visible: true });
                return;
            }

            if (politicaViagem.impedirreservaforadoprazo) {
                if (politicaViagem.antecedenciamedia !== null && politicaViagem.antecedenciamedia > 0) {

                    let dataPrazo = Moment().add(politicaViagem.antecedenciamedia, 'days');

                    if (dto.tipoCategoria === '1' && dto.dataIda < dataPrazo ||
                        dto.tipoCategoria === '2' && dto.dataIda < dataPrazo ||
                        dto.tipoCategoria === '2' && dto.dataVolta < dataPrazo) {
                        setMensagemValidacao('Não é possível consultar antes da antecedencia média estipulada!');
                        setValidacao({ visible: true });
                        return;
                    }
                }
            }
        }

        setIsLoading(true);

        switch (tipoCategoria) {
            case '1':
                dto.Trechos.push({
                    dataIda: dto.dataIda.toISOString(),
                    origem: dto.origem,
                    destino: dto.destino
                });
                break;
            case '3':
                dto.Trechos = voosMulti.map(item => {
                    return {
                        origem: item.origem.value,
                        destino: item.destino.value,
                        dataIda: item.dataIda,
                    }
                })
                break;
            case '2':
                if (dto.dataIda > dto.dataVolta) {
                    setMensagemValidacao('Data de ida não pode ser maior que a de volta');
                    setValidacao({ visible: true });
                    break;
                }
                dto.Trechos.push({
                    dataIda: dto.dataIda.toISOString(),
                    origem: dto.origem,
                    destino: dto.destino
                })
                dto.Trechos.push({
                    dataIda: dto.dataVolta.toISOString(),
                    origem: dto.destino,
                    destino: dto.origem
                })
                break;
            default:
                break;
        }

        if (dto.Trechos !== null && dto.Trechos.length > 0) {
            const dtoTrechos = {
                trechos: dto.Trechos
            }
            if (valueTipoVoo === 'internacional') {
                let result = await aeroportoRepositorio.postVerifyAeroportosType(dtoTrechos);
                if (!result) {
                    setIsLoading(false);
                    setMensagemValidacao('Ao menos um dos trechos deve ser internacional.');
                    setValidacao({ visible: true });
                    return;
                }
            }
            dtoResponseVoos = await buscarVoos(dto);
            props.buscarVoosIda(dtoResponseVoos);
            let naoTem = dtoResponseVoos.filter(item => item.length <= 0);
            if (naoTem.length > 0) {
                setIsLoading(false);
                setMensagemValidacao('Infelizmente não existem mais voos para esse trecho');
                setValidacao({ visible: true });
                return;
            }
            props.history.push("/listaAereo");
        } else {
            setIsLoading(false);
        }

    }

    const getTextoPoliticaViagem = (politicaViagem) => {
        let dataMin;
        let dataMax;
        let mensagem;
        switch (new Date().getDay()) {
            case 0:
                dataMin =
                    politicaViagem.horaminimadomingos ?
                        politicaViagem.horaminimadomingos :
                        politicaViagem.horaminimapadrao;

                dataMax =
                    politicaViagem.horamaximadomingos ?
                        politicaViagem.horamaximadomingos :
                        politicaViagem.horamaximapadrao;
                break;
            case 1:
                dataMin =
                    politicaViagem.horaminimasegundas ?
                        politicaViagem.horaminimasegundas :
                        politicaViagem.horaminimapadrao;

                dataMax =
                    politicaViagem.horamaximasegundas ?
                        politicaViagem.horamaximasegundas :
                        politicaViagem.horamaximapadrao;
                break;
            case 2:
                dataMin =
                    politicaViagem.horaminimatercas ?
                        politicaViagem.horaminimatercas :
                        politicaViagem.horaminimapadrao;

                dataMax =
                    politicaViagem.horamaximatercas ?
                        politicaViagem.horamaximatercas :
                        politicaViagem.horamaximapadrao;
                break;
            case 3:
                dataMin =
                    politicaViagem.horaminimaquartas ?
                        politicaViagem.horaminimaquartas :
                        politicaViagem.horaminimapadrao;

                dataMax =
                    politicaViagem.horamaximaquartas ?
                        politicaViagem.horamaximaquartas :
                        politicaViagem.horamaximapadrao;
                break;
            case 4:
                dataMin =
                    politicaViagem.horaminimaquintas ?
                        politicaViagem.horaminimaquintas :
                        politicaViagem.horaminimapadrao;

                dataMax =
                    politicaViagem.horamaximaquintas ?
                        politicaViagem.horamaximaquintas :
                        politicaViagem.horamaximapadrao;
                break;
            case 5:
                dataMin =
                    politicaViagem.horaminimasextas ?
                        politicaViagem.horaminimasextas :
                        politicaViagem.horaminimapadrao;

                dataMax =
                    politicaViagem.horamaximasextas ?
                        politicaViagem.horamaximasextas :
                        politicaViagem.horamaximapadrao;
                break;
            case 6:
                dataMin =
                    politicaViagem.horaminimasabados ?
                        politicaViagem.horaminimasabados :
                        politicaViagem.horaminimapadrao;

                dataMax =
                    politicaViagem.horamaximasabados ?
                        politicaViagem.horamaximasabados :
                        politicaViagem.horamaximapadrao;
                break;
        }

        mensagem = `O horário das consultas `;

        if (dataMin && dataMax) {
            mensagem += `é das ${new Date(dataMin).toLocaleTimeString().substr(0, 5)} até as ${new Date(dataMax).toLocaleTimeString().substr(0, 5)}`;
        } else if (dataMin && !dataMax) {
            mensagem += `é após as ${new Date(dataMin).toLocaleTimeString().substr(0, 5)}`;
        } else if (!dataMin && dataMax) {
            mensagem += `é até as ${new Date(dataMax).toLocaleTimeString().substr(0, 5)}`;
        }
        setMensagemPolitica(mensagem);
    }

    useEffect(() => {
        if (dataVolta < dataIda)
            setDataVolta(dataIda)
    }, [dataIda])

    useEffect(() => {
        (async () => {

            setIsLoading(true);
            let listaAeroportos = null;
            let dto = {
                internacional: valueTipoVoo === 'nacional' ? (false) : (true)
            }
            listaAeroportos = await aeroportoRepositorio.getSearchFieldByType(dto);
            setAeroportos(listaAeroportos);
            let variados = variadosReduxTrechos.dtoPesquisaAereo;
            setVoosMulti([{
                origem: null,
                destino: null,
                dataIda: null
            }])

            const organizacao = await PoliticaViagemRepositorio.validarConsulta();
            setIsLoading(false);
            setPoliticaViagem(organizacao.data.politicaViagem);
            if (!organizacao.data.consulta) {
                setLogo(true);
                getTextoPoliticaViagem(organizacao.data.politicaViagem);
                return;
            }
            setGrow(true);
            if (variados?.retorno === true) {
                setTipoCategoria(variados?.tipoCategoria)
                let teste = { ...state }
                teste = ({
                    ...teste, adultos: variados?.adultos,
                    criancas: variados?.criancas,
                    bebes: variados?.bebes,
                    apenasVoosDiretos: variados?.apenasVoosDiretos
                })
                setStepperAdultos({ ...stepperAdultos, value: teste.adultos })
                setStepperCriancas({ ...stepperCriancas, value: teste.criancas })
                setStepperBebes({ ...stepperBebes, value: teste.bebes })

                if (variados?.tipoCategoria !== '3') {
                    let dto = {
                        origem: '',
                        destino: '',
                    }
                    listaAeroportos.map((item, index) => {
                        if (item.value === variados?.Trechos[0]?.origem) {
                            dto.origem = index
                            teste = ({ ...teste, origem: variados?.Trechos[0]?.origem })
                        }
                        if (item.value === variados?.Trechos[0]?.destino) {
                            dto.destino = index
                            teste = ({ ...teste, destino: variados?.Trechos[0]?.destino })
                        }
                    })
                    setDataVolta(new Date(variados?.dataVolta))
                    setDataIda(new Date(variados?.dataIda))
                    setAeroportoIda(listaAeroportos[dto.origem])
                    setAeroportoVolta(listaAeroportos[dto.destino])
                } else {
                    let multiVooTemp = []
                    for (var i = 0; i < variados?.Trechos.length; i++) {
                        let dto = {
                            origem: {},
                            destino: {},
                            dataIda: variados?.Trechos[i]?.dataIda
                        }
                        listaAeroportos.map((item, index) => {
                            if (item.value === variados?.Trechos[i]?.origem) {
                                dto.origem = listaAeroportos[index]
                                teste = ({ ...teste, origem: variados?.Trechos[i]?.origem })
                            } else if (item.value === variados?.Trechos[i]?.destino) {
                                dto.destino = listaAeroportos[index]
                                teste = ({ ...teste, destino: variados?.Trechos[i]?.destino })
                            }
                        })
                        multiVooTemp.push(dto)
                    }
                    setVoosMulti(multiVooTemp)
                }
                setState({ ...teste })
            }
        })()
    }, [valueTipoVoo])

    return (
        <div className={classes.expansion}>
            <Snackbar {...{ variant: 'error', message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <ULoaderVoo isLoading={isLoading} />
            <div className={classes.root}>
                {grow ? (
                    <Grow in={grow} timeout={1000}>
                        <Paper elevation={15} className={classes.muipaper}>
                            <Box className={classes.filtrosContent}>
                                <Box className={classes.filtros}>
                                    <div className={matches ? (classes.tipoTrechoM) : (classes.tipoTrecho)}>
                                        <ButtonGroup color='#c62828' aria-label="outlined secondary button group">
                                            <Button style={{ background: tipoCategoria === '1' ? '#c62828' : '', color: tipoCategoria === '1' ? 'white' : '#c62828', border: 'solid 1px #c62828' }} onClick={() => setTipoCategoria('1')} >Ida</Button>
                                            <Button style={{ background: tipoCategoria === '2' ? '#c62828' : '', color: tipoCategoria === '2' ? 'white' : '#c62828', border: 'solid 1px #c62828' }} onClick={() => setTipoCategoria('2')}>Ida e Volta</Button>
                                            <Button style={{ background: tipoCategoria === '3' ? '#c62828' : '', color: tipoCategoria === '3' ? 'white' : '#c62828', border: 'solid 1px #c62828' }} onClick={() => setTipoCategoria('3')}>Multi Trecho</Button>
                                        </ButtonGroup>
                                        <Box className={classes.boxTipoVoo}>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    name="controlled-radio-buttons-group"
                                                    value={valueTipoVoo}
                                                    onChange={handleChangeTipoVoo}
                                                >
                                                    <FormControlLabel value="nacional" control={<Radio style={{ color: '#c62828' }} />} label="Nacional" />
                                                    <FormControlLabel value="internacional" control={<Radio style={{ color: '#c62828' }} />} label="Internacional" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                        {permissaoDashboard.usuarioLinestur && matches ? (
                                            <div>
                                                <Button onClick={() => setModalImportar(true)} className={classes.button3} startIcon={<ConfirmationNumberOutlined style={{ color: '#fff' }} />}>Importar</Button>
                                            </div>
                                        ) : null}
                                    </div>
                                    {permissaoDashboard.usuarioLinestur && !matches ? (
                                        <div>
                                            <Button onClick={() => setModalImportar(true)} className={classes.button3} startIcon={<ConfirmationNumberOutlined style={{ color: '#fff' }} />}>
                                                Importar
                                            </Button>
                                        </div>
                                    ) : null}
                                </Box>
                            </Box>
                            <ImportarBilhete open={modalImportar} setOpen={setModalImportar} />
                            {tipoCategoria === '3' ? (
                                <div>
                                    {!matches ? (
                                        <div>
                                            {voosMulti?.length > 0 ? (
                                                <div>
                                                    {voosMulti.map((item, index) => (
                                                        <Box className={classes.filtros2}>
                                                            <div className={classes.trecho1}>
                                                                <USearchField {...{ itens: aeroportos, value: voosMulti[index]['origem'], label: 'Origem', setValueArray: setVoosMulti, valueArray: voosMulti, index, tipoArray: 'origem' }}
                                                                    name="origem"
                                                                />
                                                            </div>
                                                            <div className={classes.trecho2}>
                                                                {/* <USearchField {...{ itens: aeroportos, value: voosMulti[index]['destino'], label: 'Destino', setValueArray: setVoosMulti, valueArray: voosMulti, index, tipoArray: 'destino' }} */}
                                                                <USearchField {...{ itens: aeroportos, value: voosMulti[index]['destino'], label: 'Destino', setValueArray: setVoosMulti, valueArray: voosMulti, index, tipoArray: 'destino' }}
                                                                    name="destino"
                                                                />
                                                            </div>
                                                            <div className={classes.udate}>
                                                                <UDatePicker {...{ date: voosMulti[index]['dataIda'], label: "Data De Partida", setValueArray: setVoosMulti, valueArray: voosMulti, index, tipoArray: 'dataIda', disablePast: true }} />
                                                            </div>
                                                            {index === voosMulti.length - 1 ? (
                                                                <div>
                                                                    <Grow in={tipoCategoria === '3' ? true : false} timeout={1000}>
                                                                        <Button className={classes.add} onClick={() => addVooMultiTrecho(item, index)}>
                                                                            <Add />
                                                                        </Button>
                                                                    </Grow>
                                                                    {index > 0 ? (
                                                                        <Grow in={tipoCategoria === '3' ? true : false} timeout={1000}>
                                                                            <Button className={classes.add} onClick={() => handleClickDeleteMulti(index)}>
                                                                                <DeleteOutline />
                                                                            </Button>
                                                                        </Grow>
                                                                    ) : null}
                                                                </div>
                                                            ) : (null)}
                                                        </Box>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <div>
                                            {voosMulti.length > 0 ? (
                                                <div>
                                                    {voosMulti.map((item, index) => (
                                                        <Paper elevation={3} style={{ marginTop: theme.spacing(2) }}>
                                                            <div style={{
                                                                padding: theme.spacing(2),
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}>
                                                                <Typography>Trecho {index + 1}</Typography>
                                                                {index + 1 === voosMulti.length ? (
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Button className={classes.add} onClick={() => addVooMultiTrecho(item, index)}>
                                                                            <Add />
                                                                        </Button>
                                                                        {index > 0 ? (
                                                                            <Button className={classes.add} onClick={() => handleClickDeleteMulti(index)}>
                                                                                <DeleteOutline />
                                                                            </Button>
                                                                        ) : null}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <Box style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                paddingBottom: theme.spacing(2)
                                                            }}>
                                                                <div className={classes.trecho1}>
                                                                    <USearchField {...{ itens: aeroportos, value: voosMulti[index]['origem'], label: 'Origem', setValueArray: setVoosMulti, valueArray: voosMulti, index, tipoArray: 'origem' }}
                                                                        name="origem"
                                                                    />
                                                                </div>
                                                                <div className={classes.trecho2}>
                                                                    <USearchField {...{ itens: aeroportos, value: voosMulti[index]['destino'], label: 'Destino', setValueArray: setVoosMulti, valueArray: voosMulti, index, tipoArray: 'destino' }}
                                                                        name="destino"
                                                                    />
                                                                </div>
                                                                <div className={classes.udate}>
                                                                    <UDatePicker {...{ date: voosMulti[index]['dataIda'], label: "Data De Partida", setValueArray: setVoosMulti, valueArray: voosMulti, index, tipoArray: 'dataIda', disablePast: true }} />
                                                                </div>
                                                            </Box>
                                                        </Paper>
                                                    ))}
                                                </div>
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            ) : <Box className={classes.filtros2}>
                                <div className={classes.trecho1}>
                                    <USearchField {...{ itens: aeroportos, value: aeroportoIda, setValue: setAeroportoIda, label: 'Origem' }}
                                        name="origem"
                                        onChange={handleChangeAuto}
                                    />
                                </div>
                                <div className={classes.trecho2}>
                                    <USearchField {...{ itens: aeroportos, value: aeroportoVolta, setValue: setAeroportoVolta, label: 'Destino' }}
                                        name="destino"
                                        onChange={handleChangeAuto}
                                    />
                                </div>
                                <div className={classes.udate}>
                                    <UDatePicker {...{ date: dataIda, setDate: setDataIda, label: "Data De Partida", disablePast: true }} />
                                </div>
                                {tipoCategoria === '2' ? (
                                    <Grow in={tipoCategoria === '2' ? true : false} >
                                        <div className={classes.udate2}>
                                            <UDatePicker {...{ date: dataVolta, setDate: setDataVolta, label: "Data De Retorno", minDate: dataIda, disablePast: true }} />
                                        </div>
                                    </Grow>
                                ) : null}
                            </Box>}
                            <Box className={classes.filtros2}>
                                {!matches && !matches4 ? (
                                    <div className={classes.tudojunto}>
                                    </div>
                                ) :
                                    <div className={classes.tudojunto}>
                                        <div className={classes.pacotinho}>
                                            <div className={classes.passageiros}>
                                                <UStepper {...stepperAdultos} setValue={{ setValue: setAdulto }} />
                                            </div>
                                            <div className={classes.passageiros}>
                                                <UStepper {...stepperCriancas} setValue={{ setValue: setCrianca }} />
                                            </div>
                                            <div className={classes.passageiros}>
                                                <UStepper {...stepperBebes} setValue={{ setValue: setBebes }} />
                                            </div>
                                        </div>
                                    </div>}
                                {matches || matches3 || matches4 ? (
                                    <Grid container xs={12} className={classes.juntinho}>
                                        <Grid item xs={12} className={classes.voos}>
                                            <FormControl fullWidth component="fieldset">
                                                <FormGroup aria-label="position" name="position" value={state.apenasVoosDiretos || ''} onChange={handleApenasVoosDiretosChange} row>
                                                    <FormControlLabel
                                                        value="Apenas Vôos diretos"
                                                        control={<UCheckbox checked={state.apenasVoosDiretos} />}
                                                        label="Vôos diretos"
                                                        name="apenasVoosDiretos"
                                                        labelPlacement="end"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>) : (
                                    <div className={classes.tudojunto}>
                                        <div className={classes.pacotinho}>
                                            <div className={classes.passageiros}>
                                                <UStepper {...stepperAdultos} setValue={{ setValue: setAdulto }} />
                                            </div>
                                            <div className={classes.passageiros}>
                                                <UStepper {...stepperCriancas} setValue={{ setValue: setCrianca }} />
                                            </div>
                                            <div className={classes.passageiros}>
                                                <UStepper {...stepperBebes} setValue={{ setValue: setBebes }} />
                                            </div>
                                        </div>
                                        <div className={classes.voos}>
                                            <FormControl component="fieldset">
                                                <FormGroup aria-label="position" name="position" value={state.apenasVoosDiretos || ''} onChange={handleApenasVoosDiretosChange} row>
                                                    <FormControlLabel
                                                        value="Apenas Vôos diretos"
                                                        control={<UCheckbox checked={state.apenasVoosDiretos} />}
                                                        label="Vôos diretos"
                                                        name="apenasVoosDiretos"
                                                        labelPlacement="end"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                )}
                                <div style={{ width: '100%', display: 'flex', justifyContent: matches ? 'center' : 'flex-end', alignItems: 'center' }}>
                                    <div className={classes.button2}>
                                        <Button onClick={e => onConsultarClick()} fullWidth className={classes.button3}>
                                            <SearchIcon />
                                            {'Pesquisar'}
                                        </Button>
                                    </div>
                                </div>
                            </Box>
                        </Paper>
                    </Grow>
                ) : null}
                {logo ? (
                    <Grow in={logo} timeout={1000}>
                        <Paper elevation={5} style={{ padding: theme.spacing(4) }}>
                            <Grid container spacint={1} item xs={12} style={{ margin: 0, display: 'flex', justifyContent: !matches ? 'space-around' : 'center', alignItems: 'center' }}>
                                <Grid item xs={!matches ? 4 : 10} style={{
                                    maxWidth: '350px',
                                    width: '100%',
                                    height: 'auto'
                                }}>
                                    <img src={Imagem} height={{
                                        maxWidth: '350px',   /* Máximo da largura da imagem */
                                        width: '100%',
                                        maxHeight: '350px', /* Máximo da altura da imagem */
                                        minHeight: 'auto',      /* Mínimo da altura, por padrão “auto” */
                                        backgroundSize: '100%',
                                        backgroundRepeat: 'no-repeat'
                                    }} />
                                </Grid>
                                <Grid item xs={!matches ? 6 : 12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: theme.spacing(2) }}>
                                    <Typography style={{
                                        font: '400 1.6rem Auro Black',
                                        fontSize: !matches ? 48 : 38,
                                        color: '#c62828',
                                    }}>
                                        <b>Oops...</b>
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography style={{ font: '400 1.6rem Auro Black', fontSize: !matches ? 36 : 26, color: '#c62828' }}
                                    >
                                        <b>{mensagemPolitica}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grow>
                ) : null}
            </div>
        </div >
    )
}

export const Aereo = props => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Dashboard {...props} component={ConsultaAereo} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        aereos: state.linkAereo.aereos,
        vooIda: state.linkAereo.vooIda,
        vooVolta: state.linkAereo.vooVolta,
        voosIda: state.linkAereo.voosIda,
        voosVolta: state.linkAereo.voosVolta,
        voosIdaComBagagem: state.linkAereo.voosIdaComBagagem,
        voosVoltaComBagagem: state.linkAereo.voosVoltaComBagagem,
        voosMulti: state.linkAereo.voosMulti,
        vooIda: state.linkAereo.vooIda,
        vooVolta: state.linkAereo.vooVolta,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ buscarAereo, buscarVoosIda, buscarVoosVolta, buscarVoosIdaComBagagem, setVooIda, setVooVolta, buscarVoosVoltaComBagagem, setVoosMultiTrecho }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Aereo);
