import { TextField, Typography, withStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

const ULinesTextField = withStyles({
    root: {
        '& .MuiInputBase-root': {
            color: 'black'
        },
        '& label': {
            color: 'inherited',
        },
        '& label.Mui-focused': {
            color: '#b91119',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#b91119',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'silver',
            },
            '&:hover fieldset': {
                borderColor: 'silver',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#b91119',
            },
        }
    }
})(TextField)

export const USelect = (props) => {

    const handleChange = (event) => {
        if (props.setValueArray) {
            let array = [...props.valueArray];
            array[props.index] = event.target.value;
            props.setValueArray(array);
        }
        if (props.setValue) {
            props.setValue(event.target.value);
        }
        if (props.onChange) {
            props.onChange(event);
        }
    }

    return (
        <ULinesTextField
            fullWidth
            variant="outlined"
            disabled={props.disabled}
            margin="dense"
            label={props.label}
            name={props.name}
            className={props.className}
            value={props.value}
            onChange={e => handleChange(e)}
            select
        >
            {props.itens.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
        </ULinesTextField>
    )
};

export default USelect;
