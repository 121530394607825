import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    typoInfoReserva: {
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: 0
    }

}));




