export const EnumPax = {

    getFaixaEtaria: {
        'ADT': 'Adulto',
        'CHD': 'Criança',
        'INF': 'Bebê'
    },

    getGenero: {
        1: 'Masculino',
        2: 'Feminino'
    }

}
