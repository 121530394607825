import { Button, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { AddCircleOutlineOutlined, AddOutlined, DeleteOutline } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useLayoutEffect } from "react";
import { useState } from "react";
import MaskedInput from "react-text-mask";
import LinesTextField from "../../../../../components/LinesTextField";
import UMaskTextfield from "../../../../../components/umasktextfield";
import USearchField from "../../../../../components/usearchfield";
import organizacaoConfigRepositorio from "../../../../../repositorios/organizacao";
import { useStyles } from "./styles";

export const AeroportosProximos = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [iAeroportos, setIAeroportos] = useState([]);
    const [iAeroporto, setIAeroporto] = useState(null);
    const [aeroportos, setAeroportos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [km, setKm] = useState('');

    const searchDataAeroportos = async () => {
        try {
            setIsLoading(true)
            let response = await organizacaoConfigRepositorio.getListAeroportos(props.idtenantEmpresa);
            setIAeroportos(response.data.aeroportos);
            setAeroportos(response.data.aeroportos_proximos);
        } catch (error) {
            props.setTipoSnack('error');
            props.setMensagemSnack('Falha ao carregar aeroportos.');
            props.setStatusSnack({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    const onAddAeroporto = async () => {
        try {
            setIsLoading(true)
            const temAeroporto = aeroportos.find(item => item.idtenant === iAeroporto.value);

            if (temAeroporto) {
                props.setTipoSnack('warning');
                props.setMensagemSnack('Aeroporto já esta na lista.');
                props.setStatusSnack({ visible: true });
                setIsLoading(false)
                return;
            }

            if (km.trim() === '' || km === null || km === undefined) {
                props.setTipoSnack('warning');
                props.setMensagemSnack('Quilometragem não pode ser vazio.');
                props.setStatusSnack({ visible: true });
                setIsLoading(false)
                return;
            }

            let dto = {
                idtenant: props.idtenantEmpresa,
                idaeroporto: iAeroporto.id,
                quilometragem: km,
            }
            let response = await organizacaoConfigRepositorio.putAddAirport(dto);
            setIAeroportos(response.data.aeroportos);
            setAeroportos(response.data.aeroportos_proximos);
            setIAeroporto(null)
        } catch (e) {
            props.setTipoSnack('error');
            props.setMensagemSnack('Erro ao adicionar');
            props.setStatusSnack({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    const onDeleteAeroporto = async (id) => {
        try {
            setIsLoading(true)
            let dto = {
                idtenant: props.idtenantEmpresa,
                idaeroporto: id,
            }
            let response = await organizacaoConfigRepositorio.putRemoveAirport(dto);
            setIAeroportos(response.data.aeroportos);
            setAeroportos(response.data.aeroportos_proximos);
            setIAeroporto(null)
        } catch (e) {
            props.setTipoSnack('error');
            props.setMensagemSnack('Erro ao remover');
            props.setStatusSnack({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    const handleChangedKM = (event) => {
        setKm(event.target.value)
    }

    useLayoutEffect(() => {
        searchDataAeroportos()
    }, [])

    return (
        <>
            {isLoading ? (
                <Skeleton variant='rect' height={'400px'} />
            ) : (
                <Grid container spacing={1} style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} item xs={12}>
                    <Grid item xs={responsive ? 12 : 8}>
                        <USearchField required {...{ itens: iAeroportos, value: iAeroporto, setValue: setIAeroporto, label: 'Aeroportos' }} />
                    </Grid>
                    <Grid item xs={responsive ? 8 : 3}>
                        <LinesTextField
                            type={'number'}
                            onChange={handleChangedKM}
                            value={km}
                            name="km"
                            fullWidth
                            label="Quilometragem "
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">km</InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={responsive ? 4 : 1}>
                        <Tooltip title="Adicionar Aeroporto">
                            <Button onClick={() => onAddAeroporto()} className={classes.button} variant="contained" fullWidth >
                                <AddCircleOutlineOutlined />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {aeroportos.map(item => (
                                <ListItem>
                                    <Tooltip title="Adicionar Aeroporto">
                                        <>
                                            <Button onClick={() => onDeleteAeroporto(item.id)} className={classes.button} variant="contained" style={{ marginRight: '1rem' }} >
                                                <DeleteOutline />
                                            </Button>
                                            <ListItemText>
                                                <Typography>
                                                    {item.nome} - {item.km}
                                                </Typography>
                                            </ListItemText>
                                        </>
                                    </Tooltip>


                                    {/* <Tooltip title="Remover Aeroporto">
                                        <IconButton>
                                            <DeleteOutline onClick={() => onDeleteAeroporto(item.id)} />
                                        </IconButton>
                                    </Tooltip> */}
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
