import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close, WarningOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import UDatePicker from '../../../../components/udatepicker';
import ULoaderVoo from '../../../../components/uloadervoo';
import UMaskTextfield from '../../../../components/umasktextfield';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import validatorCPF from '../../../../utils/cpfValidator';
import { useStyles } from './styles';

export const CheckUpCadastroSolicitacao = props => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    // const [snackVariant, setSnackVariant] = useState('error');
    // const [snackMessage, setSnackMessage] = useState('');
    // const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [isLoading, setIsLoading] = useState(false);
    const [dataNascimentoCadastro, setDataNascimentoCadastro] = useState(props?.data?.pessoa?.datanascimento || new Date());
    // const [errorValue, setErrorValue] = useState({
    //     cpf: false,
    //     datanascimento: false,
    //     telefone: false,
    // })

    const [dtoPessoa, setDtoPessoa] = useState({
        telefone: props?.data?.pessoa?.telefone || '',
        cpf: props?.data?.pessoa?.cpf || '',
        datanascimento: props?.data?.pessoa?.datanascimento || new Date(),
    });

    const handleCloseDialogCheckUp = () => {
        props.setOpen(false);
    }

    const handleChangeDtoPessoa = (event) => {
        setDtoPessoa({
            ...dtoPessoa,
            [event.target.name]: event.target.value
        })
    }

    const handleSaveUpdateDados = async () => {
        setIsLoading(true)
        if (dtoPessoa.telefone === "(__)_____-____" || dtoPessoa.telefone === null || dtoPessoa.telefone === 'undefined' || dtoPessoa.telefone.trim() === "") {
            props.setMensagemSnack('O campo telefone não pode ser vazio.');
            props.setTipoSnack('error');
            props.setSnackbarSucess({ visible: true });
            setIsLoading(false)
            return;
        }

        if (dtoPessoa.cpf === '') {
            props.setMensagemSnack('O campo CPF não pode ser vazio.');
            props.setTipoSnack('error');
            props.setSnackbarSucess({ visible: true });
            setIsLoading(false)
            return;
        }

        if (!validatorCPF(dtoPessoa.cpf)) {
            props.setMensagemSnack('CPF inválido.');
            props.setTipoSnack('error');
            props.setSnackbarSucess({ visible: true });
            setIsLoading(false)
            return;
        }

        try {
            let response = await pessoaRepositorio.updatePessoaANAC(dtoPessoa);
            if (response.status === 207) {
                props.setMensagemSnack(response.data.message);
                props.setTipoSnack('error');
                props.setSnackbarSucess({ visible: true });
                setIsLoading(false)
                return
            }

            if (response.status === 202) {
                props.setTipoSnack('info');
            } else {
                props.setTipoSnack('success');
            }

            props.setMensagemSnack(response.data.message);
            props.setSnackbarSucess({ visible: true });
            let pessoa = { ...props?.pessoa, pessoa: dtoPessoa };
            props?.setPessoa(pessoa)
            let pessoas = props?.pessoas.filter(p => p.value !== pessoa.value);
            props?.setPessoas([...pessoas, pessoa])
            props?.continueAddViajante(true)
            handleCloseDialogCheckUp()
        } catch (e) {
            props.setMensagemSnack('Falha ao salvar.');
            props.setTipoSnack('error');
            props.setSnackbarSucess({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setDtoPessoa({ ...dtoPessoa, idpessoa: props?.data?.pessoa?.idpessoa || 0 })
    }, [props, dtoPessoa])

    return (
        <form >
            {/* <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} /> */}
            <Dialog
                open={props.open}
                onClose={handleCloseDialogCheckUp}
                maxWidth="sm"
                fullWidth
                fullScreen={responsive}
            >
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                            <Typography>{props?.data?.pessoa?.nome + ' ' + props?.data?.pessoa?.sobrenome}</Typography>
                            <IconButton className={classes.button} onClick={handleCloseDialogCheckUp}>
                                <Close style={{ color: 'inherited' }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <WarningOutlined fontSize='medium' htmlColor='yellow' />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant='caption'>
                                        Segundo consta na Resolução 595 da ANAC, tornou-se <b>mandatório</b> o envio do CPF, DATA DE NASCIMENTO e
                                        TELEFONE do passageiro para a garantia do aéreo, portanto solicitamos o cadastro correto dos
                                        seguintes campos.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <UMaskTextfield
                                helperText="Formato de telefone incorreto"
                                mask="(99)99999-9999"
                                // error={errorValue.telefone}
                                value={dtoPessoa.telefone}
                                required
                                label="Telefone"
                                style={{ marginLeft: '10px' }}
                                alwaysShowMask={false}
                                name="telefone"
                                onChange={handleChangeDtoPessoa}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UMaskTextfield
                                helperText="Formato de CPF incorreto"
                                alwaysShowMask={false}
                                // error={errorValue.cpf}
                                maskChar=''
                                mask="999.999.999-99"
                                required
                                label="CPF"
                                value={dtoPessoa.cpf}
                                name='cpf'
                                onChange={handleChangeDtoPessoa}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UDatePicker
                                readOnly={true}
                                // error={errorValue.datanascimento}
                                date={dataNascimentoCadastro}
                                setDate={setDataNascimentoCadastro}
                                label={'Data de Nascimento'}
                                name="datanascimento"
                                eventOnChange={handleChangeDtoPessoa}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.appbar}>
                    <Button className={classes.buttoncustom}
                        onClick={() => handleSaveUpdateDados()}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </form >
    )
};

export default CheckUpCadastroSolicitacao;
