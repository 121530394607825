import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, EditRounded, Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import cookie from 'universal-cookie';
import api from '../../../Api';
import LoadingButton from '../../../components/loadingButton';
import Snackbar from '../../../components/snackbar';
import UFloatButton from '../../../components/ufloatbutton';
import Enums from '../../../enums';
import permissaoRepositorio from '../../../repositorios/permissao';
import Dashboard from '../../dashboard';
import CadastroFornecedor from '../cadastro';
import { useStyles } from './styles';

export const ListaFornecedores = () => {
    const classes = useStyles();
    const cookies = new cookie();
    const theme = useTheme();
    const matches = useMediaQuery('(max-width:480px)');
    const matches2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const matches3 = useMediaQuery('(min-width:600px) and (orientation: portrait)');
    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedoresPesquisa, setFornecedoresPesquisa] = useState([]);
    const [fornecedor, setFornecedor] = useState({});
    const [validacao, setValidacao] = useState({ visible: false });
    const [picture, setPicture] = useState(null);
    const [mensagemValidacao, setMensagemValidacao] = useState('');

    const [snackbarError, setSnackbarError] = useState({
        visible: false
    });
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [cadastroOpen, setCadastroOpen] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = fornecedoresPesquisa.length;
    const start = page * itensPerPage;
    const r = itensPerPage + start;
    const todosFornecedores = fornecedoresPesquisa.slice(start, r);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    const onPesquisar = event => {
        const contem = fornecedores.filter(item => {
            return (item.nome.toLowerCase().includes(event.target.value.toLowerCase()) ||
                (item.cidade.nome.toLowerCase().includes(event.target.value.toLowerCase())));
        })
        setFornecedoresPesquisa(contem);
    }

    const salvar = async () => {
        const dto = {
            ...fornecedores,
            sessionId: cookies.get('sessionId'),
            picture: picture

        }
        if (!fornecedor.nome) {
            setMensagemValidacao('O campo nome não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!fornecedor.idcidade) {
            setMensagemValidacao('O campo cidade não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }

        if (fornecedor.idfornecedor) {
            const temPermissaoEditar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.fornecedor, Enums.acaoRequerida.editar)
            if (!temPermissaoEditar.data) {
                setMensagemValidacao('Você não possui permissão!');
                setValidacao({ visible: true });
                return
            }
            api.put('/fornecedor/' + fornecedor.idfornecedor, fornecedor, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }

            }).then(response => {
                if (response.status !== 204) {
                    setSnackbarError({ visible: true });
                    return response;
                }
                setCadastroOpen(false);

                api.get('/fornecedor', {
                    headers: {
                        Authorization: 'Bearer ' + cookies.get('token')
                    }
                }).then(response => {
                    setFornecedores(response.data);
                    setFornecedoresPesquisa(response.data);
                    setSnackbarSucess({ visible: true });
                    setCadastroOpen(false);
                    return;
                })
            })

            return;
        }

        setFornecedor({
            ...fornecedor,
            idcidade: Number(fornecedor.idcidade)
        });

        const temPermissaoCriar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.fornecedor, Enums.acaoRequerida.criar)
        if (!temPermissaoCriar.data) {
            setMensagemValidacao('Você não possui permissão!');
            setValidacao({ visible: true });
            return
        }
        api.post('/fornecedor', fornecedor, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            if (response.status !== 201) {
                setSnackbarError({ visible: true });
                return response;
            }

            api.get('/fornecedor', {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            }).then(response => {
                setFornecedores(response.data);
                setFornecedoresPesquisa(response.data);
                setSnackbarSucess({ visible: true });
                setCadastroOpen(false);
                return;
            })
        })

    }
    const handleCadastroOpen = (item, visible) => {
        setFornecedor(item);
        setCadastroOpen(visible);
    }

    useEffect(() => {
        api.get('/fornecedor', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            setFornecedores(response.data);
            setFornecedoresPesquisa(response.data);
        })
    }, [])

    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: 'error', message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <Snackbar {...{ variant: 'success', message: 'Dados salvos com sucesso!', visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Dialog
                className={classes.cadastro}
                open={cadastroOpen}
                onClose={() => setCadastroOpen(false)}
                fullScreen={fullscreen}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.divtitle}>
                        <Typography style={{ color: 'white' }}>Fornecedor</Typography>
                        <IconButton className={classes.fechar} onClick={() => setCadastroOpen(false)}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ minHeight: 270 }}>
                    <CadastroFornecedor {...{ fornecedor: fornecedor, setFornecedor: setFornecedor, picture: picture, setPicture: setPicture }} />
                    <div className={classes.salvar}>
                        <LoadingButton {...{ salvar: salvar, modalOpen: cadastroOpen, setModalOpen: setCadastroOpen }} />
                    </div>
                </DialogContent>
            </Dialog>
            <Paper elevation={!fullscreen ? 5 : 0}>
                <Toolbar className={classes.toolbar}>
                    {!matches && !matches2 && !matches3 ? (<Button className={classes.textfield} onClick={() => handleCadastroOpen({}, true)}>Novo</Button>) : (<UFloatButton actions={[]} handleClick={() => handleCadastroOpen({ tipousuario: 1 }, true)} />)}
                    <TablePagination
                        component="div"
                        count={pages}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 25, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <div className={classes.search3}>
                        <div className={classes.searchIcon}>
                            <Search />
                        </div>
                        <InputBase
                            onChange={onPesquisar}
                            placeholder="Pesquise…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow className={classes.tableHead}>
                            <TableCell className={classes.heads} align="center"></TableCell>
                            <TableCell className={classes.heads} align="center">ID</TableCell>
                            <TableCell className={classes.heads} align="center">Nome</TableCell>
                            {!matches && !matches2 && !matches3 ? (<TableCell className={classes.heads} align="center">Cidade</TableCell>) : null}
                            {!matches && !matches2 && !matches3 ? (<TableCell className={classes.heads} align="center">Endereço</TableCell>) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {todosFornecedores.map(item => (
                            <TableRow className={classes.tableBody}>
                                <TableCell align="center">
                                    <IconButton onClick={() => handleCadastroOpen(item, true)}>
                                        <EditRounded />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">{item.idfornecedor}</TableCell>
                                <TableCell align="center">{item.nome}</TableCell>
                                {!matches && !matches2 && !matches3 ? (<TableCell align="center">{item.cidade.nome}</TableCell>) : null}
                                {!matches && !matches2 && !matches3 ? (<TableCell align="center">{item.endereco}</TableCell>) : null}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaFornecedores} />
        </div>
    )
};
export default Lista
