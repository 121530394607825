import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, Typography, Chip, Grid, Divider, Avatar, Box, IconButton, Tabs, Tab, Button, TextField, Checkbox, Container, ButtonGroup } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
import AddCardIcon from '@mui/icons-material/AddCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PropTypes from 'prop-types';
import { CreationDate } from '../../../Desktop/sections';
import { EnumTipo, formatarParaNomeStatusViagem } from '../../../../../enums/enumPrestacaoDeContas';
import { getTipoDespesaForNumber, getTipoDespesaForNumberUnique, getTipoHodometroForNumber } from '../../../../../mock/tiposdespesas';
import { createGlobalStyle } from 'styled-components';
import DialogComponent from '../../../Desktop/sections/dialogs-desktop';
import { InfoHodometroMComponent } from '../Components/InfoHodometro';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { ArrowUpward, CurrencyExchangeOutlined, MarkEmailRead, QrCode2Outlined } from "@mui/icons-material";
import { aprovarDespesas_Mock, aprovarViagemParaFinanceiro_Mock, cancelarEnvioDeMensagem_Mock, enviarParaControladoria_Mock, excluirExtra_Mock, retornarCancelarAprovacaoDeDespesa_Mock, retornarDespesaParaViajante_Mock } from '../../../../../mock/prestacaodecontas';
import { useCustomSnackbar } from '../../../../../components/snackbar2/NewSnackBar';
import { ExtraAddComponentM } from '../Extras';
import prestacaoDeContasRepositorio from '../../../../../repositorios/prestacaodecontas';
import { ViewQrCodeProducts } from '../../../../../components/autalii_expanses/viewqrcode';
import { DeskCardPersonExpanses } from '../../../../../components/card_person_expanses';


const GlobalStyle = createGlobalStyle`
  .MuiBox-root.css-19kzrtu {
    padding: 10px !important;
  }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export const AprovarViagemComponentM = ({ viagem, setOpen, getViagensReload }) => {
    const [value, setValue] = useState(0);
    const [despesa, setDespesa] = useState([])
    const [despesasAgrupadas, setDespesasAgrupadas] = useState([]);
    const [descricao, setDescricao] = useState("");
    const [totalSelecionadas, setTotalSelecionadas] = useState(0);

    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [openComprovante, setOpenComprovante] = useState(false)
    const [openReturnDespesa, setOpenReturnDespesa] = useState(false)
    const [currentTab, setCurrentTab] = useState(1);
    const [count, setCount] = useState(0)
    const [openDeleteExtra, setOpenDeleteExtra] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categorias, setCategorias] = useState([])
    const [despesas, setDespesas] = useState([])
    const [extras, setExtras] = useState([])
    const [totalGasto, setTotalgasto] = useState(0)
    const [openAuditar, setOpenAuditar] = useState(false)
    const [openQrCodeView, setOpenQrCodeView] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas()
            setCategorias(response)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const getDespesas = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas)
            setExtras(response.extras)
            getAlltipos()
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        let c = 0;
        viagem?.extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [viagem])

    useEffect(() => {

        getDespesas()
    }, [])

    useEffect(() => {
        agruparDespesasPorTipo()
    }, [despesas, categorias]);

    const reload = () => {
        setTotalSelecionadas(0)
        agruparDespesasPorTipo();
        setDescricao("")
    }

    const agruparDespesasPorTipo = () => {
        const agrupadas = {};

        categorias?.forEach(element => {
            despesas?.forEach(despesa => {
                if (!despesa.aprovado) {
                    if (despesa.produtos.iddespesa_tipo == element.iddespesa_tipo) {
                        const tipoTitulo = element.name;
                        if (!agrupadas[tipoTitulo]) {
                            agrupadas[tipoTitulo] = [];
                        }
                        agrupadas[tipoTitulo].push({ ...despesa, selecionada: false });
                        return
                    }

                }
            });
        });

        setDespesasAgrupadas(Object.entries(agrupadas).map(([titulo, despesas]) => ({ titulo, despesas })));
    };

    const toggleSelecaoDespesa = (tipoTitulo, indexDespesa) => {
        let contador = 0; // Inicializa contador para atualização do total de selecionadas

        setDespesasAgrupadas(despesasAgrupadas.map(grupo => {
            if (grupo.titulo === tipoTitulo) {
                return {
                    ...grupo,
                    despesas: grupo.despesas.map((despesa, index) => {
                        if (index === indexDespesa) {

                            const despesaAtualizada = { ...despesa, selecionada: !despesa.selecionada };
                            // Se a despesa for selecionada, incrementa, senão decrementa
                            contador += despesaAtualizada.selecionada ? 1 : -1;
                            return despesaAtualizada;
                        }
                        // Conta as já selecionadas
                        if (despesa.selecionada) contador++;
                        return despesa;
                    }),
                };
            }
            // Conta as selecionadas em outros grupos
            grupo.despesas.forEach(despesa => {
                if (despesa.selecionada) contador++;
            });
            return grupo;
        }));

        setTotalSelecionadas(contador);
    };

    const handleClickAprovar = async () => {
        setLoading(true)
        try {
            let list = []
            despesasAgrupadas.forEach(element => {
                element.despesas.forEach(item => {
                    if (item.selecionada) {
                        list.push(item.iddespesa)
                        // aprovarDespesas_Mock(viagem.id, item.id)
                    }
                })
            });

            await prestacaoDeContasRepositorio.postAprovarDespesas(viagem.id, list)
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Aprovada com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar aprovar despesa(s).', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickOpenComprovante = (despesa) => {
        setDespesa(despesa)
        setOpenComprovante(true)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    const handleClickAuditar = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putAuditarValor(viagem.id, despesa.iddespesa, totalGasto);
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Valor auditado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao auditar a despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickOpenHodometro = (despesa) => {
        setDespesa(despesa)
        setOpenInfoHodometro(true)
    }

    const handleClickReturn = (despesa) => {
        setDespesa(despesa)
        setOpenReturnDespesa(true)
    }

    const handleOpenEdit = (value) => {
        setDespesa(value)
        setOpenAuditar(true)
    }

    const handleClickOpenQrCode = (despesa) => {
        setDespesa(despesa)
        setOpenQrCodeView(true)
    }

    const handleClickExcluirExtra = () => {
        excluirExtra_Mock(viagem.id, despesa.id)
        setOpenDeleteExtra(false)
        setCurrentTab(1)
        enqueueSnackbar('Removido com sucesso.', { variant: 'success' });
        reload()
    }

    const handleClickRetornarDespesaAprovada = (iddespesa) => {
        retornarCancelarAprovacaoDeDespesa_Mock(viagem.id, iddespesa)
        enqueueSnackbar('Retornado com sucesso.', { variant: 'success' });
        reload()
    }

    const handleClickAprovarViagem = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAvancarViagem(viagem.id)
            getDespesas()
            getViagensReload()
            setOpen(false)
            enqueueSnackbar('Avançada para o financeiro com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar avançar a viagem.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickRetornarDespesaComMensagem = async () => {
        setLoading(true)
        try {
            // setDespesa(prevModel => ({
            //     ...prevModel,
            //     mensagem: descricao
            // }))
            despesa.mensagem = descricao
            await prestacaoDeContasRepositorio.putEnviarParaPessoa(viagem.id, [despesa])
            getDespesas()
            getViagensReload()
            setOpenReturnDespesa(false)
            enqueueSnackbar('Enviado para pessoa com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao enviar despesa para pessoa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickCancelarEnvioDeMensagem = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putCancelarMensagem(viagem.id, despesa.iddespesa)
            enqueueSnackbar('Enviado para controladoria com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro enviar para controladoria.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickControladoriaDespesa = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putEnviarControladoria(viagem.id, despesa.iddespesa, descricao)
            getDespesas()
            getViagensReload()
            setOpenReturnDespesa(false)
            enqueueSnackbar('Enviado para controladoria com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro enviar para controladoria.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container style={{ padding: "unset" }}>
            {despesa !== null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                Informações do Hodômetro
                            </>
                        }
                        open={openInfoHodometro}
                        handleClose={() => setOpenInfoHodometro(false)}
                        body={
                            <InfoHodometroMComponent despesa={despesa} />
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />

                    <DialogComponent
                        title={
                            <>
                                Comprovante
                            </>
                        }
                        fullScreen={true}
                        open={openComprovante}
                        handleClose={() => setOpenComprovante(false)}
                        body={
                            <Grid container item xs={12}>
                                <img style={{ width: "inherit" }} src={despesa.comprovante}></img>
                            </Grid>
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />

                    <DialogComponent
                        title={
                            <>
                                Mensagem
                            </>
                        }
                        open={openReturnDespesa}
                        handleClose={() => setOpenReturnDespesa(false)}
                        body={
                            <Grid container item xs={12}>
                                <Typography style={{ marginTop: "15px", marginBottom: "15px" }}>Envie a descrição do erro dessa despesa para a controladoria ou de volta para a pessoa</Typography>
                                <TextField
                                    id="filled-multiline-flexible"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    defaultValue={despesa?.mensagem}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    disabled={despesa?.mensagem != "" ? true : false}
                                    multiline
                                    maxRows={4}
                                    label="Justificativa/Descrição"
                                    placeholder="Tem algo de errado com..."
                                />
                            </Grid>
                        }
                        action={
                            <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                                {despesa?.mensagem != "" ? (
                                    <>
                                    </>
                                    // <Button color="error" onClick={() => handleClickCancelarEnvioDeMensagem(despesa?.id)}>Cancelar</Button>
                                ) : (
                                    <>
                                        <Button size="large" color="warning" disabled={descricao != "" ? false : true} onClick={() => handleClickControladoriaDespesa(despesa?.id)}>Controladoria</Button>
                                        <Button size="large" disabled={descricao != "" ? false : true} onClick={() => handleClickRetornarDespesaComMensagem(despesa?.id)}>Pessoa</Button>
                                    </>
                                )}
                            </Grid>
                        }
                        width={"sm"}
                    />

                    <DialogComponent
                        title={
                            <>
                                Informações do QrCode
                            </>
                        }
                        open={openQrCodeView}
                        handleClose={() => setOpenQrCodeView(false)}
                        body={
                            <ViewQrCodeProducts QrCode={despesa?.qrCode} />
                        }
                        action={
                            <></>
                        }
                        fullScreen={true}
                    />
                </>
            )}

            <Grid item xs={12} style={{ padding: "0px" }}>
                <Card sx={{ boxShadow: 0.2, borderRadius: 10, padding: 0.1 }}>
                    <GlobalStyle />
                    {
                        viagem != null && (
                            <DeskCardPersonExpanses viagem={viagem} despesas={despesas} fullScreen={true} />
                        )
                    }
                    {viagem != null && count != 0 ? (
                        <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                            <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                                {[{ value: 1, label: "Despesas" }, { value: 2, label: "Extras" }].map((tab) => (
                                    <Button
                                        size="large"
                                        key={tab.value}
                                        onClick={() => handleChangeTab(tab.value)}
                                        style={{
                                            textTransform: "none", // Mantém a capitalização original do texto
                                            fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                            borderColor: "#D6D6D6"
                                        }}
                                    >
                                        <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                            {tab.label}
                                        </Typography>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Grid>
                    ) : (null)}
                    {currentTab == 1 && (
                        <>
                            <Tabs value={value} onChange={handleChange} aria-label="Viagem Tabs">
                                {/* <Tab label="Alimentação" {...a11yProps(0)} />
                        <Tab label="Carro" {...a11yProps(1)} />
                        <Tab label="Alimentação" {...a11yProps(2)} /> */}
                                {/* <Tabs value={value} onChange={handleChange} aria-label="Viagem Tabs"> */}
                                {despesasAgrupadas?.length > 0 && despesasAgrupadas.map((grupo, indexGrupo) => (
                                    <Tab key={indexGrupo} label={grupo.titulo} {...a11yProps(indexGrupo)} />
                                ))}
                                {/* </Tabs> */}
                            </Tabs>
                            {despesasAgrupadas?.length > 0 && despesasAgrupadas.map((grupo, indexGrupo) => (
                                <TabPanel sx={{ p: 0 }} key={indexGrupo} value={value} index={indexGrupo}>
                                    <CardContent sx={{ p: 0 }}>
                                        {grupo.despesas?.map((item, indexDespesa) => (
                                            <>
                                                {item.hodometro != null ? (
                                                    <>
                                                        <Divider style={{ marginTop: "5px" }} />
                                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                            <Box sx={{ flex: 1 }}>
                                                                <Typography variant="body1" style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {item.controladoria ? (
                                                                            <>
                                                                                <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                                </Chip>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {item.aprovado ? (
                                                                                    <Chip label="Aprovado" color="primary" />
                                                                                ) : (
                                                                                    <Chip label="Pendente" color="secondary" />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography variant="body2" color="textSecondary"><CreationDate data={item.datacriacao} /></Typography>
                                                                <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                            <Typography variant="body2"></Typography>
                                                            <Typography variant="body2">Total em Km</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                            <Typography variant="body2" style={{ marginTop: "auto" }}>{item.despesa_desc}</Typography>
                                                            <Typography variant="body2">
                                                                {item.hodometro.kmtotal} Km
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                            {item.mensagem != "" ? (
                                                                <>
                                                                    {item.controladoria ? (
                                                                        <Button size="large" fullWidth color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AdminPanelSettingsIcon />}>
                                                                            Notificado
                                                                        </Button>
                                                                    ) : (
                                                                        <Button size="large" fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                                            Notificado
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <Button size="large" fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                                    Retornar
                                                                </Button>
                                                            )}
                                                            <Button
                                                                size="large"
                                                                onClick={() => handleClickOpenHodometro(item)}
                                                                startIcon={<DirectionsCarIcon />}
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                            >Informações</Button>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                            {item.mensagem == "" ? (
                                                                <>
                                                                    <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">Aprovar</Typography>
                                                                    <Checkbox
                                                                        defaultChecked
                                                                        color="primary"
                                                                        checked={item.selecionada}
                                                                        onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                                                    />
                                                                </>
                                                            ) : (null)}
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Divider style={{ marginTop: "5px" }} />
                                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                            <Box sx={{ flex: 1 }}>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                                                                    <Typography variant="body1" style={{ fontWeight: "600" }}>Despesa</Typography>
                                                                </Box>

                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {item.controladoria ? (
                                                                            <>
                                                                                <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                                </Chip>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {item.aprovado ? (
                                                                                    <Chip label="Aprovado" color="primary" />
                                                                                ) : (
                                                                                    <Chip label="Pendente" color="secondary" />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography variant="body2" color="textSecondary"><CreationDate data={item.datacriacao} /></Typography>
                                                                <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                            <Typography variant="body2">Para</Typography>
                                                            <Typography variant="body2">Total</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                            <Typography variant="body2" style={{ marginTop: "auto" }}>{item.despesa_desc}</Typography>
                                                            <Typography variant="body2">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}</Typography>
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                            {item.qrCode != null && (
                                                                <Button
                                                                    size="large"
                                                                    startIcon={<QrCode2Outlined />}
                                                                    fullWidth
                                                                    onClick={() => handleClickOpenQrCode(item)}
                                                                >Utilizado QrCode</Button>
                                                            )}
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                            {item.mensagem != "" ? (
                                                                <>
                                                                    {item.controladoria ? (
                                                                        <Button size="large" fullWidth color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AdminPanelSettingsIcon />}>
                                                                            Notificado
                                                                        </Button>
                                                                    ) : (
                                                                        <Button size="large" fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                                            Notificado
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <Button size="large" fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                                    Retornar
                                                                </Button>
                                                            )}
                                                            <Button
                                                                size="large"
                                                                startIcon={<ReceiptLongIcon />}
                                                                onClick={() => handleClickOpenComprovante(item)}
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                            >Comprovante</Button>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: "center", mt: 1 }}>
                                                            {item.mensagem == "" ? (
                                                                <>
                                                                    <Typography style={{ fontWeight: "600", fontSize: "14px" }} color="primary">Aprovar</Typography>
                                                                    <Checkbox
                                                                        defaultChecked
                                                                        color="primary"
                                                                        checked={item.selecionada}
                                                                        onChange={() => toggleSelecaoDespesa(grupo.titulo, indexDespesa)}
                                                                    />
                                                                </>
                                                            ) : (null)}
                                                        </Box>
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </CardContent>
                                </TabPanel>
                            ))}
                            {/* {despesasAgrupadas?.length > 0 ? (null) : (
                                <>
                                    {despesas?.map((item) => (
                                        <>
                                            {item.hodometro ? (
                                                <>
                                                    <Divider style={{ marginTop: "5px" }} />
                                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                        <Box sx={{ flex: 1 }}>
                                                            <Typography variant="body1" style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {item.aprovado ? (
                                                                        <Chip label="Aprovado" color="primary" />
                                                                    ) : (
                                                                        <Chip label="Pendente" color="secondary" />
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                            <Typography variant="body2" color="textSecondary"><CreationDate data={item.datadecriacao} /></Typography>
                                                            <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                        <Typography variant="body2"></Typography>
                                                        <Typography variant="body2">Total em Km</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                        <Typography variant="body2" style={{ marginTop: "auto" }}>{getTipoHodometroForNumber(item.hodometro.veiculo)}</Typography>
                                                        <Typography variant="body2">
                                                            {item.hodometro.kmtotal} Km
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                        {item.mensagem != "" ? (
                                                            <>
                                                                {item.controladoria ? (
                                                                    <Button fullWidth color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AdminPanelSettingsIcon />}>
                                                                        Notificado
                                                                    </Button>
                                                                ) : (
                                                                    <Button fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                                        Notificado
                                                                    </Button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Button fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                                Retornar
                                                            </Button>
                                                        )}
                                                        <Button
                                                            onClick={() => handleClickOpenHodometro(item)}
                                                            startIcon={<DirectionsCarIcon />}
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                        >Informações</Button>
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Divider style={{ marginTop: "5px" }} />
                                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                                                        <Box sx={{ flex: 1 }}>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                                                                <Typography variant="body1" style={{ fontWeight: "600" }}>Despesa</Typography>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {item.aprovado ? (
                                                                        <Chip label="Aprovado" color="primary" />
                                                                    ) : (
                                                                        <Chip label="Pendente" color="secondary" />
                                                                    )}
                                                                    {item.valor_auditado != null ? (
                                                                        <>
                                                                            <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor_auditado))} color="warning" style={{ marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeOutlined />}>
                                                                            </Chip>
                                                                        </>) : (null)}
                                                                </Typography>
                                                            </Box>
                                                            <Typography variant="body2" color="textSecondary"><CreationDate data={item.datadecriacao} /></Typography>
                                                            <Typography variant="body2" sx={{ mt: 1 }}>{item.descricao}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                                        <Typography variant="body2">Para</Typography>
                                                        <Typography variant="body2">Total</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                        <Typography variant="body2" style={{ marginTop: "auto" }}>{getTipoDespesaForNumber(item.tipo, item.produtos.value)}</Typography>
                                                        <Typography variant="body2">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                        {item.mensagem != "" ? (
                                                            <>
                                                                {item.controladoria ? (
                                                                    <Button fullWidth color="warning" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AdminPanelSettingsIcon />}>
                                                                        Notificado
                                                                    </Button>
                                                                ) : (
                                                                    <Button fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                                        Notificado
                                                                    </Button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <Button fullWidth color="secondary" style={{ marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                                Retornar
                                                            </Button>
                                                        )}
                                                        <Button
                                                            startIcon={<ReceiptLongIcon />}
                                                            onClick={() => handleClickOpenComprovante(item)}
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                        >Comprovante</Button>
                                                    </Box>
                                                </>
                                            )}
                                        </>
                                    ))}
                                </>
                            )} */}
                        </>
                    )}

                    {currentTab == 2 && (
                        <>
                            <ExtraAddComponentM viagem={viagem} auditor={true} />
                        </>
                    )}
                </Card>
            </Grid>

            {despesasAgrupadas?.length > 0 ? (
                <Grid container item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button size="large" variant="contained" color="primary" disabled={totalSelecionadas > 0 ? false : true} onClick={() => handleClickAprovar()}>Aprovar</Button>
                </Grid>
            ) : (
                <Grid container item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                    <Button size="large" variant="contained" color="primary" fullWidth onClick={() => handleClickAprovarViagem()}>Avançar para o financeiro</Button>
                </Grid>
            )}
        </Container>
    )
}