import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TablePagination from "@material-ui/core/TablePagination";
import {
  Apple,
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  Assignment,
  Beenhere,
  Cancel,
  CancelPresentation,
  CloseOutlined,
  ConfirmationNumber,
  EventNote,
  FindReplaceOutlined,
  LockOpen,
  NoEncryptionOutlined,
  Person,
  QueryBuilder,
  SearchOutlined,
  SyncOutlined,
  TuneOutlined,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Cookie from "universal-cookie";
import LinesTextField from "../../../components/LinesTextField";
import Snackbar from "../../../components/snackbar";
import ULoaderVoo from "../../../components/uloadervoo";
import UYesNoDialog from "../../../components/uyesnodialog";
import Enums from "../../../enums";
import { aereoRepositorio } from "../../../repositorios/aereo/index";
import reservaRepositorio from "../../../repositorios/reserva";
import usuarioRepositorio from "../../../repositorios/usuario";
import Dashboard from "../../../views/dashboard";
import { CadastroSolicitacao } from "../../solicitacao/cadastro";
import AlterarDataCriacao from "./AlterarDataCriacao";
import DialogAccept from "./DialogAccept";
import ViewEmissao from "./emissao";
import EnviarEmailAereo from "./EnviarEmail";
import { useStyles } from "./styles";
import BilheteAereo from "./telabilheteaereo";

const ListaReservas = (props) => {
  const theme = useTheme();
  const { idreserva, token, idacao } = useParams();
  const [snackVariant, setSnackVariant] = useState("success");
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullscreen2 = useMediaQuery(theme.breakpoints.down("md"));
  const [openModalAccept, setOpenModalAccept] = useState(false);
  const classes = useStyles();
  const [cookie, setCookie] = useState();
  const [showTuneStatus, setShowTuneStatus] = useState(false);
  const cookies = new Cookie();
  const [backDrop, setBackDrop] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorAcao, setAnchorAcao] = useState(null);
  const [parametro, setParametro] = useState();

  const [headers, setHeaders] = useState([
    { label: "ID", icon: null },
    { label: "Assunto", icon: null },
    { label: "Status", icon: null },
    { label: "Solicitante", icon: null },
    { label: "Ultima Atualização", icon: null },
    { label: "", icon: null },
  ]);
  const headersInicial = [
    { label: "ID", icon: null },
    { label: "Assunto", icon: null },
    { label: "Status", icon: null },
    { label: "Solicitante", icon: null },
    { label: "Ultima Atualização", icon: null },
    { label: "", icon: null },
  ];
  const [reserva, setReserva] = useState({});
  const [aereo, setAereo] = useState({});
  const [reservas, setReservas] = useState([]);
  const [reservasInicial, setReservasInicial] = useState([]);
  const [historicos, setHistoricos] = useState([]);
  const [numeroReserva, setNumeroReserva] = useState("");
  const [tipoReserva, setTipoReserva] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAutorizacao, setModalAutorizacao] = useState(false);
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [modalEmissao, setModalEmissao] = useState(false);
  const [mensagemSnack, setMensagemSnack] = useState("");
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("dataultimaatualizacao");
  const [openYesNo, setOpenYesNo] = useState(false);
  const [openEnviarEmail, setOpenEnviarEmail] = useState(false);
  const [modalAlterarCriacao, setModalAlterarCriacao] = useState(false);
  const [valueTuneStatus, setValueTuneStatus] = React.useState("0");
  const [valueTuneNome, setValueTuneNome] = React.useState("0");
  const [openDialogReembolso, setOpenDialogReembolso] = useState(false);
  const [emails, setEmails] = useState({
    email: "",
    cc: "",
  });
  const [snackbarSucess, setSnackbarSucess] = useState({
    visible: false,
  });
  const permissaoDashboard = useSelector(
    (state) => state.permissao.permissaoDashboard
  );
  const defaultLabelDisplayedRows = (_ref) => {
    var from = _ref.from,
      to = _ref.to,
      count = _ref.count;
    return ""
      .concat(from, "-")
      .concat(to === -1 ? count : to, " de ")
      .concat(count !== -1 ? count : "maior que ".concat(to));
  };

  const handleClickAlterarCriacao = (item) => {
    setReserva(item);
    handleAcaoClose();
    setModalAlterarCriacao(true);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const sortTable = (index) => {
    let heade = headersInicial;
    switch (index) {
      case 0:
        setOrder(order === "asc" ? "desc" : "asc");
        setOrderBy("idreservatenant");
        heade[0] = {
          label: "ID",
          icon:
            order === "asc" ? (
              <ArrowDownwardOutlined className={classes.iconeOrdem} />
            ) : (
              <ArrowUpwardOutlined className={classes.iconeOrdem} />
            ),
        };
        setHeaders(heade);
        break;
      case 1:
        setOrder(order === "asc" ? "desc" : "asc");
        setOrderBy("assunto");
        heade[1] = {
          label: "Assunto",
          icon:
            order === "asc" ? (
              <ArrowDownwardOutlined className={classes.iconeOrdem} />
            ) : (
              <ArrowUpwardOutlined className={classes.iconeOrdem} />
            ),
        };
        setHeaders(heade);
        break;
      case 2:
        setOrder(order === "asc" ? "desc" : "asc");
        setOrderBy("status");
        heade[2] = {
          label: "Status",
          icon:
            order === "asc" ? (
              <ArrowDownwardOutlined className={classes.iconeOrdem} />
            ) : (
              <ArrowUpwardOutlined className={classes.iconeOrdem} />
            ),
        };
        setHeaders(heade);
        break;
      case 3:
        setOrder(order === "asc" ? "desc" : "asc");
        setOrderBy("idsolicitante");
        heade[3] = {
          label: "Solicitante",
          icon:
            order === "asc" ? (
              <ArrowDownwardOutlined className={classes.iconeOrdem} />
            ) : (
              <ArrowUpwardOutlined className={classes.iconeOrdem} />
            ),
        };
        setHeaders(heade);
        break;
      case 4:
        setOrder(order === "asc" ? "desc" : "asc");
        setOrderBy("dataultimaatualizacao");
        heade[4] = {
          label: "Ultima Atualização",
          icon:
            order === "asc" ? (
              <ArrowDownwardOutlined className={classes.iconeOrdem} />
            ) : (
              <ArrowUpwardOutlined className={classes.iconeOrdem} />
            ),
        };
        setHeaders(heade);
        break;
    }
    setPage(0);
  };

  const setSolicitacaoOuAereo = {
    1: () => {
      setTipoReserva(1);
    },
    2: () => {
      setTipoReserva(2);
    },
  };
  const showBotaoEmitir = () => {
    const mostrar =
      reserva.status !== Enums.reservaStatus.emitido &&
      reserva.status !== Enums.reservaStatus.emissaoNegada &&
      reserva.status !== Enums.reservaStatus.cancelado &&
      reserva.status !== Enums.reservaStatus.encerrado &&
      reserva.status !== Enums.reservaStatus.expirado &&
      reserva.status !== Enums.reservaStatus.limiteExcedido &&
      reserva.status !== Enums.reservaStatus.autorizacaoPendente &&
      permissaoDashboard.usuarioLinestur &&
      permissaoDashboard.permiteEmitir;
    return mostrar;
  };
  const showBotaoCancelar = () => {
    const mostrar =
      reserva.status !== Enums.reservaStatus.emitido &&
        reserva.status !== Enums.reservaStatus.emissaoNegada &&
        reserva.status !== Enums.reservaStatus.cancelado &&
        reserva.status !== Enums.reservaStatus.encerrado &&
        reserva.status !== Enums.reservaStatus.expirado &&
        reserva.status !== Enums.reservaStatus.emissaoPendente &&
        reserva.status !== Enums.reservaStatus.limiteExcedido
        ? true
        : false;
    return mostrar;
  };
  const getIconsHistorico = {
    1: {
      icon: <QueryBuilder fontSize="large" />,
      iconColor: "orange",
      iconDirection: "left",
    },
    2: {
      icon: <LockOpen fontSize="large" />,
      iconColor: "royalblue",
      iconDirection: "right",
    },
    3: {
      icon: <NoEncryptionOutlined fontSize="large" />,
      iconColor: "#c62828",
      iconDirection: "right",
    },
    4: {
      icon: <QueryBuilder fontSize="large" />,
      iconColor: "orange",
      iconDirection: "left",
    },
    5: {
      icon: <Beenhere fontSize="large" />,
      iconColor: "deepskyblue",
      iconDirection: "right",
    },
    6: {
      icon: <Cancel fontSize="large" />,
      iconColor: "#c62828",
      iconDirection: "right",
    },
    7: {
      icon: <ConfirmationNumber fontSize="large" />,
      iconColor: "darkgreen",
      iconDirection: "right",
    },
    8: {
      icon: <CancelPresentation fontSize="large" />,
      iconColor: "#c62828",
      iconDirection: "right",
    },
    9: {
      icon: <Apple />,
      iconColor: "orange",
      iconDirection: "left",
    },
    10: {
      icon: <Apple />,
      iconColor: "orange",
      iconDirection: "left",
    },
    11: {
      icon: <Apple />,
      iconColor: "darkgreen",
      iconDirection: "right",
    },
    12: {
      icon: <FindReplaceOutlined fontSize="large" />,
      iconColor: "darkgreen",
      iconDirection: "right",
    },
  };

  const onClickEmitirReserva = async (item) => {
    handleAcaoClose();
    setBackDrop(true);
    try {
      let dtoStatusReserva = {
        idreserva: reserva.idreserva,
        status: Enums.reservaStatus.emitido,
        justificativa: "",
        politicaAereo: false,
        statusTipo: Enums.aereoStatus.emitido,
        sessionId: cookies.get("sessionId"),
      };
      const response = await aereoRepositorio.get(reserva.idaereo);
      setAereo(response.data);
      setBackDrop(false);
      setModalEmissao(true);
    } catch (e) {
      setBackDrop(false);
      setMensagemSnack("Erro ao emitir aéreo.");
      setSnackbarSucess({ visible: true });
    }
  };

  const getStatusHistorico = () => {
    switch (reserva.status) {
      case Enums.reservaStatus.aguardandoAtendimento:
        return {
          titulo: "Aguardando atendimento",
          cor: "#bf360c",
        };
      case Enums.reservaStatus.emAtendimento:
        return {
          titulo: "Em atendimento",
          cor: "#ff9100",
        };
      case Enums.reservaStatus.emissaoPendente:
        return {
          titulo: "Emissão pendente",
          cor: "#ef6c00",
        };
      case Enums.reservaStatus.aguardandoSolicitante:
        return {
          titulo: "Aguardando Solicitante",
          cor: "#3f51b5",
        };
      case Enums.reservaStatus.encerrado:
        return {
          titulo: "Encerrado",
          cor: "black",
        };
      case Enums.reservaStatus.rascunho:
        return {
          titulo: "Rascunho",
          cor: "gray",
        };
      case Enums.reservaStatus.emitido:
        return {
          titulo: "Emitido",
          cor: "#43a047",
        };
      case Enums.reservaStatus.reservado:
        return {
          titulo: "Tarifa Garantida",
          cor: "#1e88e5",
        };

      case Enums.reservaStatus.autorizacaoPendente:
        switch (reserva.etapaaprovacao) {
          case 1:
            return {
              titulo: "Pendente na Linestur",
              cor: "#ff9100",
            };
          case 2:
            return {
              titulo: "Pendente de autorização",
              cor: "#9e9d24",
            };
          case 3:
            return {
              titulo: "Pendente de aprovação",
              cor: "deepskyblue",
            };
          case 4:
            return {
              titulo: "Aguardando solicitante",
              cor: "#ff9100",
            };
        }
        break;
      case Enums.reservaStatus.expirado:
        return {
          titulo: "Expirado",
          cor: "grey",
        };
      case Enums.reservaStatus.emissaoNegada:
        return {
          titulo: "Emissão negada",
          cor: "#f44336",
        };
      case Enums.reservaStatus.cancelado:
        return {
          titulo: "Cancelado",
          cor: "#f44336",
        };
      case Enums.reservaStatus.limiteExcedido:
        return {
          titulo: "Limite Excedido",
          cor: "#f44336",
        };

      case Enums.reservaStatus.aprovacaoPendente:
        return {
          titulo: "Pendente de Aprovação",
          cor: "royalblue",
        };
      case Enums.reservaStatus.reembolsointegral:
        return {
          titulo: "Reembolso integral",
          cor: "#f44336",
        };
      case Enums.reservaStatus.reembolsoparcial:
        return {
          titulo: "Reembolso Parcial",
          cor: "#43a047",
        };
      default:
        return {
          titulo: "",
          cor: "",
        };
    }
  };

  const SearchParametro = (e) => {
    let status = Cookies.get("FilterStatus");
    // let parametro = e.target.value.toLowerCase();
    if (parametro != null) {
      let resultadoPesquisa = reservasInicial.filter((item) => {
        if (item.idsolicitacao) {
          return (
            (status === "0" || status === undefined
              ? true
              : item.status + "" === status) &&
            (item.assunto?.toLowerCase().includes(parametro) ||
              item.idreservatenant?.toString().includes(parametro) ||
              (valueTuneNome === "0"
                ? item.solicitacao?.passageiros?.some((item) =>
                  `${item.passageiro?.nome} ${item.passageiro?.sobrenome}`
                    .toLowerCase()
                    .includes(parametro)
                )
                : `${item.usuario?.pessoa?.nome} ${item.usuario?.pessoa?.sobrenome}`
                  .toLowerCase()
                  .includes(parametro)) ||
              item.solicitacao?.passageiros.some((item) =>
                item.centrodecusto?.codigo?.toLowerCase().includes(parametro)
              ))
          );
        }
        if (item.idaereo) {
          return (
            (status === "0" || status === undefined
              ? true
              : item.status + "" === status) &&
            (item.assunto?.toLowerCase().includes(parametro) ||
              item.idreservatenant.toString().includes(parametro) ||
              item.aereo?.localizador.toLowerCase().includes(parametro) ||
              (valueTuneNome === "0"
                ? item.aereo?.passageiros.some((item) =>
                  `${item.passageiro?.nome} ${item.passageiro?.sobrenome}`
                    .toLowerCase()
                    .includes(parametro)
                )
                : `${item.usuario?.pessoa?.nome} ${item.usuario?.pessoa?.sobrenome}`
                  .toLowerCase()
                  .includes(parametro)) ||
              item.aereo?.passageiros?.some((item) =>
                item.centrodecusto?.codigo.toLowerCase().includes(parametro)
              ))
          );
        }
      });
      setReservas(resultadoPesquisa);
    }
  };

  const onPesquisarChange = (e) => {
    setParametro(e.target.value.toLowerCase())
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAcaoClick = (event, item) => {
    if (anchorAcao) {
      return;
    }
    setReserva(item);
    setAnchorAcao(event.currentTarget);
  };

  const definirAssunto = (assunto) => {
    if (!assunto) {
      return "";
    }
    const textos = assunto.split("<br />");
    return (
      <div>
        {textos.map((item) => (
          <div>{item}</div>
        ))}
      </div>
    );
  };

  const handleAcaoClose = () => {
    setAnchorAcao(null);
  };

  const isTipoAereo = (tipo = 0) => {
    return tipo === 1 ? true : false;
  };

  const isTipoSolicitacao = (tipo = 0) => {
    return tipo === 2 ? true : false;
  };

  const handleModalOpen = (item) => {
    setSolicitacaoOuAereo[item.tipo]();
    setReserva(item);
    setNumeroReserva(item.idreservatenant);
    setModalOpen(true);
  };

  const handleHistoricoClick = async (item) => {
    const response = await reservaRepositorio.getReservaHistorico(
      item.idreserva
    );
    setHistoricos(response.data);
    setModalAutorizacao(true);
    handleAcaoClose();
  };

  const handleClickYesNo = (type) => {
    handleAcaoClose();
    switch (type) {
      case "cancelar":
        setOpenYesNo(true);
        break;
    }
  };

  const enviarAereoEmail = async ({ email, cc, idreserva }) => {
    try {
      const validaEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

      let dtoAereo = {
        email: email.toLowerCase(),
        cc: cc.toLowerCase(),
        idreserva,
      };

      if (!email || !validaEmail.test(email)) {
        setSnackVariant("error");
        setMensagemSnack("Por favor digite um e-mail válido");
        setSnackbarSucess({ visible: true });
        return;
      }

      if (!validaEmail.test(cc) && cc) {
        setSnackVariant("error");
        setMensagemSnack("Por favor digite um e-mail - CC válido");
        setSnackbarSucess({ visible: true });
        return;
      }

      setBackDrop(true);

      const response = await reservaRepositorio.enviarEmailAereo(dtoAereo);

      if (response.status !== 204) {
        setBackDrop(false);
        setSnackVariant("error");
        setMensagemSnack("Não foi possível enviar!");
        setSnackbarSucess({ visible: true });
        return;
      }

      setBackDrop(false);

      setOpenEnviarEmail(false);
      setSnackVariant("success");
      setMensagemSnack("Mensagem enviada!");
      setSnackbarSucess({ visible: true });
    } catch (e) {
      setBackDrop(false);
      setSnackVariant("error");
      setMensagemSnack("Não foi possível enviar!");
      setSnackbarSucess({ visible: true });
    }
  };

  const handleSyncReservas = async (valor = 0) => {
    try {
      setBackDrop(true);
      await loadReserva(valor);
      setBackDrop(false);
    } catch (error) { }
  };

  const handleClickSincronizarAereo = async (reserva) => {
    try {
      handleAcaoClose();
      setBackDrop(true);
      const response = await aereoRepositorio.sincronizarAereo(
        reserva.idreserva
      );

      if (response.status !== 200) {
        setBackDrop(false);
        setSnackVariant("error");
        setMensagemSnack("Sincronização não concluida!");
        setSnackbarSucess({ visible: true });
        return;
      }

      await loadReserva();
      setBackDrop(false);
      setSnackVariant("success");
      setMensagemSnack("Sincronização concluida!");
      setSnackbarSucess({ visible: true });
    } catch (e) {
      setBackDrop(false);
      setSnackVariant("error");
      setMensagemSnack("Erro: " + e.message);
      setSnackbarSucess({ visible: true });
    }
  };

  const cancelarReserva = async () => {
    handleAcaoClose();
    setBackDrop(true);

    let dtoStatusReserva = {
      idreserva: reserva.idreserva,
      status: Enums.reservaStatus.cancelado,
      justificativa: "",
      politicaAereo: false,
      statusTipo:
        reserva.tipo === 1
          ? Enums.aereoStatus.cancelado
          : reserva.tipo === 2
            ? Enums.statusSolicitacao.cancelado
            : 0,
      sessionId: cookies.get("sessionId"),
    };

    if (reserva.tipo === 1) {
      const response = await aereoRepositorio.cancelarAereo(reserva.idaereo);
      await loadReserva();
      setBackDrop(false);
      setMensagemSnack("Reserva Cancelada com sucesso!");
      setSnackbarSucess({ visible: true });
    }
    if (reserva.tipo === 2) {
      await reservaRepositorio.alterarStatus(dtoStatusReserva);
      await loadReserva();
      setBackDrop(false);
      setMensagemSnack("Reserva Cancelada com sucesso!");
      setSnackbarSucess({ visible: true });
    }

    setBackDrop(false);
  };

  const loadReserva = async (status = 0) => {
    const reservaResponse = await reservaRepositorio.getAll();
    if (status > 0) {
      let reservaList1 = Object.entries(reservaResponse);
      let quant = Object.entries(reservaResponse.data);
      let reservaList2 = [];
      for (var i = 0; i < quant.length; i++) {
        if (reservaList1[0][1][i].status + "" === status) {
          reservaList2.push(reservaList1[0][1][i]);
        }
      }
      setReservas(reservaList2);
    } else {
      setReservas(reservaResponse.data);
    }
    onCloseFilter()
    setReservasInicial(reservaResponse.data);
    if (token && idreserva && idacao) {
      setBackDrop(true);
      let dtoAutorizarNegar = {
        idreserva: idreserva,
        acao: idacao,
        tokenId: token,
        sessionId: cookies.get("sessionId"),
      };
      const response = await reservaRepositorio.autorizarNegarReserva(
        dtoAutorizarNegar
      );
      setBackDrop(false);
      if (response.data.success) {
        setSnackVariant("success");
        setMensagemSnack(response.data.message);
        setSnackbarSucess({ visible: true });
        setTimeout(() => {
          props.history.push("/listaReservas");
        }, 2000);
      } else {
        setSnackVariant("error");
        setMensagemSnack(response.data.message);
        setSnackbarSucess({ visible: true });
      }
      return;
    }
    if (idreserva) {
      const reservaAtual = reservaResponse.data.find(
        (item) => item.idreserva === Number(idreserva)
      );
      if (reservaAtual) {
        setReserva(reservaAtual);
        handleModalOpen(reservaAtual);
      }
    }
  };

  const handleClickTuneStatusOpen = () => {
    setShowTuneStatus(true);
  };

  const handleClickTuneStatusClose = () => {
    // onPesquisarChange()
    setShowTuneStatus(false);
  };

  const handleChangeTuneStatusValue = (event) => {
    setValueTuneStatus(event.target.value);
    Cookies.set("FilterStatus", event.target.value);
    handleSyncReservas(event.target.value);
    handleClickTuneStatusClose();
  };

  const handleChangeTuneNomeValue = (event) => {
    setValueTuneNome(event.target.value);
    handleClickTuneStatusClose();
  };

  const autorizarNegar = async (acao) => {
    setBackDrop(true);
    let dtoAutorizarNegar = {
      idreserva: reserva.idreserva,
      acao,
      sessionId: cookies.get("sessionId"),
    };

    try {
      const response = await reservaRepositorio.autorizarNegarReserva(dtoAutorizarNegar);
      if (response.status === "400") {
        setSnackVariant("warning");
        setMensagemSnack("Não possui permissão.");
        setSnackbarSucess({ visible: true });
        setModalAutorizacao(false);
        return;
      }
      setSnackVariant("success");
      setMensagemSnack(acao === 1 ? "Aprovação com sucesso." : "Aprovação recusada.");
      setSnackbarSucess({ visible: true });
      setModalAutorizacao(false);
    } catch (e) {
      setSnackVariant("error");
      dtoAutorizarNegar.acao === 2 ? setMensagemSnack("Erro ao tentar negar.") : setMensagemSnack("Erro ao tentar aprovar.");
      setSnackbarSucess({ visible: true });
      setModalAutorizacao(false);
    } finally {
      await loadReserva();
      setBackDrop(false);
    }
  };

  const handleClickModalAccept = () => {
    setOpenModalAccept(true);
  };

  const handleClickStatus = async (value) => {
    let status = { integral: true, };
    if (value === "reembolsointegral") {
      status.integral = true;
    } else if (value === "reembolsoparcial") { status.integral = false; }
    try {
      setBackDrop(true);
      await reservaRepositorio.repay(reserva.idreserva, status);
      handleSyncReservas(0);
      setOpenDialogReembolso(false);
    } catch (e) {
      setSnackVariant("error");
      setMensagemSnack("Erro ao tentar realizar reembolso");
      setSnackbarSucess({ visible: true });
      setBackDrop(false);
    }
  };

  const getPageContent = () => {
    // const pages = (stepIndex != voos.length) ? (Math.ceil(voos[stepIndex].flights.length / 10)) : 0;
    // const s = (page - 1) * 10;
    // const r = 10 + s;
    // const flightPerPage = (stepIndex != voos.length) ? (voos[stepIndex].flights.slice(s, r)) : 0;
    let teste = stableSort(reservas, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    return teste;
  }

  const LoadFiltro = async () => {
    if (Cookies.get("FilterStatus") === undefined) {
      Cookies.set("FilterStatus", "0");
      let teste = Cookies.get("FilterStatus");
      setCookie(teste);
      SearchParametro()
    } else {
      let teste = Cookies.get("FilterStatus");
      setValueTuneStatus(teste);
      await handleSyncReservas(teste);
      SearchParametro()
      return;
    }
    await handleSyncReservas(0);
    SearchParametro()
  };

  const onCloseFilter = () => {
    SearchParametro()
    setShowTuneStatus(false)
  }

  useEffect(() => {
    (async () => {
      await LoadFiltro();
    })();
  }, [modalOpen]);

  useEffect(() => {
    SearchParametro();
  }, [parametro])

  useEffect(() => {
    (async () => {
      let response = await usuarioRepositorio.getDadosSessao();
      setDadosUsuario(response.data);
    })();
  }, []);

  return (
    <Box className={classes.root}>
      <AlterarDataCriacao
        open={modalAlterarCriacao}
        reserva={reserva}
        setOpen={setModalAlterarCriacao}
      />
      <DialogAccept
        getData={loadReserva}
        open={openModalAccept}
        setOpenModalAccept={setOpenModalAccept}
        {...{
          setSnackVariant,
          setMensagemSnack,
          setSnackbarSucess,
        }}
      />
      <Snackbar
        {...{
          variant: snackVariant,
          message: mensagemSnack,
          visible: snackbarSucess.visible,
          setSnackbar: setSnackbarSucess,
          autoHideDuration: 5000,
        }}
      />
      <UYesNoDialog
        yesEvent={cancelarReserva}
        message="Deseja realmente efetuar o cancelamento?"
        setOpen={setOpenYesNo}
        open={openYesNo}
      ></UYesNoDialog>
      <ULoaderVoo isLoading={backDrop} />
      <Dialog
        open={modalEmissao}
        onClose={() => setModalEmissao(false)}
        fullWidth={true}
        fullScreen={fullscreen2}
        maxWidth="lg"
      >
        <AppBar position="relative" style={{ background: 'none' }}>
          <Toolbar
            style={{
              background: '#c62828',
              display: "flex",
              justifyContent: "space-between"
            }}>
            <Typography>Emissão</Typography>
            <IconButton onClick={() => setModalEmissao(false)}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent
          style={{ background: "white", padding: fullscreen ? 0 : "auto" }}
        >
          <ViewEmissao
            {...{
              aereo,
              setAereo,
              modalEmissao,
              setModalEmissao,
              reserva,
              loadReserva,
              setVariant: setSnackVariant,
              variant: snackVariant,
              message: mensagemSnack,
              setMessage: setMensagemSnack,
              visible: snackbarSucess.visible,
              setSnackbar: setSnackbarSucess,
              setBackDrop,
            }}
          />
        </DialogContent>
      </Dialog>
      <EnviarEmailAereo
        open={openEnviarEmail}
        setOpen={setOpenEnviarEmail}
        values={emails}
        setValues={setEmails}
        onClick={() =>
          enviarAereoEmail({
            email: emails.email,
            cc: emails.cc,
            idreserva: reserva.idreserva,
          })
        }
      />
      <Dialog
        open={modalAutorizacao}
        onClose={() => setModalAutorizacao(false)}
        fullWidth={true}
        fullScreen={fullscreen}
        maxWidth="lg"
      >
        <AppBar className={classes.titleAutorizacao}>
          <Toolbar>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography style={{ fontFamily: "verdana" }} variant="button">
                <b>Autorizar/Negar</b>
              </Typography>
              <IconButton onClick={() => setModalAutorizacao(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Toolbar>
        </AppBar>

        <AppBar
          style={{
            background: "#616161",
            color: "white",
            position: "relative",
          }}
        >
          <Toolbar>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                color: "white",
                background: getStatusHistorico().cor,
                alignItems: "center",
                borderStyle: "solid",
                borderRadius: "10px",
                borderWidth: "3px",
                borderColor: getStatusHistorico().cor,
              }}
            >
              <Typography variant="h6" style={{ fontFamily: "verdana" }}>
                <b>{getStatusHistorico().titulo}</b>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            padding: theme.spacing(0),
            background: "silver",
            minHeight: 400,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: historicos.length <= 0 ? "center" : "flex-start",
          }}
        >
          <Box style={{ width: "100%", height: "100%" }}>
            <Grid container xs={12} style={{ width: "100%", height: "100%" }}>
              {historicos.length > 0 ? (
                <VerticalTimeline>
                  {historicos.map((item) => (
                    <VerticalTimelineElement
                      position={getIconsHistorico[item.acao].iconDirection}
                      contentStyle={{ background: "white", color: "#fff" }}
                      contentArrowStyle={{ borderRight: "7px solid  white" }}
                      icon={getIconsHistorico[item.acao].icon}
                      iconStyle={{
                        background: getIconsHistorico[item.acao].iconColor,
                        color: "#fff",
                      }}
                    >
                      {getIconsHistorico[item.acao].iconDirection === "right" ||
                        fullscreen ? (
                        <Box style={{ color: "black" }}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "top",
                            }}
                          >
                            <Typography
                              style={{
                                color: "black",
                                margin: "0px 0px 15px 0px",
                              }}
                            >
                              <b>{item.titulo}</b>
                            </Typography>
                          </Grid>
                          <Grid container spacing={1} xs={12}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <EventNote fontSize="small" />
                              <Typography
                                style={{ margin: 0 }}
                                variant="caption"
                              >{` ${new Date(
                                item.datacriacao
                              ).toLocaleString()} `}</Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Person fontSize="small" />
                              <Typography
                                style={{ margin: 0 }}
                              >{` ${item.usuario.usuario} `}</Typography>
                            </Grid>
                            {item.acao === 12 ||
                              (item.justificativa && fullscreen) ? (
                              <Grid
                                container
                                spacing={1}
                                xs={12}
                                style={{ margin: 0 }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    marginTop: 15,
                                  }}
                                >
                                  <Assignment fontSize="small" />
                                  <Typography style={{ margin: 0 }}>
                                    {" Descrição "}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    style={{ margin: 0 }}
                                    variant="caption"
                                  >
                                    {item.justificativa}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Box>
                      ) : null}

                      {getIconsHistorico[item.acao].iconDirection === "left" &&
                        !fullscreen ? (
                        <Box style={{ color: "black" }}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              alignItems: "top",
                            }}
                          >
                            <Typography
                              style={{
                                color: "black",
                                margin: "0px 0px 15px 0px",
                              }}
                            >
                              <b>{item.titulo}</b>
                            </Typography>
                          </Grid>
                          <Grid container spacing={1} xs={12}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{ margin: 0 }}
                                variant="caption"
                              >
                                {new Date(item.datacriacao).toLocaleString() +
                                  " "}
                              </Typography>
                              <EventNote fontSize="small" />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{ margin: 0 }}
                              >{` ${item.usuario.usuario} `}</Typography>
                              <Person fontSize="small" />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                marginTop: 15,
                              }}
                            >
                              <Typography style={{ margin: 0 }}>
                                {" Justificativa "}
                              </Typography>
                              <Assignment fontSize="small" />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{ margin: 0 }}
                                variant="caption"
                              >
                                {item.justificativa}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : null}
                    </VerticalTimelineElement>
                  ))}
                </VerticalTimeline>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ color: "white", fontSize: 24 }}>
                    Não há nada no histórico!
                  </Typography>
                </div>
              )}
            </Grid>
          </Box>
        </DialogContent>
        {permissaoDashboard.permiteautorizarnegar &&
          (reserva.status === 9 || reserva.status === 14) ? (
          <DialogActions id="action7" style={{ background: "#fafafa" }}>
            <Button
              className={classes.buttonAutorizar}
              onClick={() => autorizarNegar(1)}
            >
              Autorizar
            </Button>
            <Button
              className={classes.buttonNegar}
              onClick={() => autorizarNegar(2)}
            >
              Negar
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
      <Dialog
        open={modalOpen}
        maxWidth="lg"
        fullWidth={true}
        fullScreen={fullscreen || fullscreen2}
        onClose={() => setModalOpen(false)}
      >
        <AppBar className={classes.dialog}>
          <Toolbar className={classes.dialogtittle}>
            <Typography>{` ${numeroReserva}`}</Typography>
            <IconButton onClick={() => setModalOpen(false)}>
              <CloseIcon className={classes.icone2} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.content}>
          {isTipoAereo(tipoReserva) ? (
            <BilheteAereo
              {...{
                reserva: reserva,
                setReserva: setReserva,
                modalOpen: modalOpen,
                setModalOpen: setModalOpen,
                setSnackbarSucess: setSnackbarSucess,
                setMensagemSnack: setMensagemSnack,
                setBackDrop,
              }}
            />
          ) : isTipoSolicitacao(tipoReserva) ? (
            <CadastroSolicitacao
              {...{
                // loadReserva: loadReserva,
                reserva: reserva,
                setReserva: setReserva,
                setModalOpen: setModalOpen,
                reservas: reservas,
                setReservas: setReservas,
                setSnackbarSucess: setSnackbarSucess,
                setMensagemSnack: setMensagemSnack,
              }}
            />
          ) : null}
        </DialogContent>
        {isTipoAereo(tipoReserva) ? null : (
          <DialogActions id="action8" style={{ background: "#d1d1d1" }}>
            {isTipoAereo(tipoReserva)
              ? null
              : isTipoSolicitacao(tipoReserva)
                ? null
                : null}
          </DialogActions>
        )}
      </Dialog>
      <Box className={classes.paper}>
        <Paper elevation={!fullscreen ? 5 : 0}>
          <Toolbar className={classes.linha}>

            {fullscreen ? (
              <>
                <Grid container>
                  <Grid item xs={12}>
                    <Tooltip title="Clique aqui para atualizar a caixa de entrada">
                      <IconButton
                        className={classes.uibutton}
                        onClick={() => handleSyncReservas(0)}
                      >
                        <SyncOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Filtro de Status">
                      <IconButton onClick={handleClickTuneStatusOpen}>
                        <Badge
                          color="secondary"
                          variant="dot"
                          invisible={valueTuneStatus != 0 ? false : true}
                        >
                          <TuneOutlined style={{ color: "#c62828" }} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    {dadosUsuario?.nivelpermissao == 6 ||
                      dadosUsuario.nivelpermissao == 1 ||
                      dadosUsuario?.nivelpermissao == 2 ? (
                      <Button
                        className={classes.buttonCustom}
                        onClick={() => handleClickModalAccept()}
                      >
                        Aprovar todos
                      </Button>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <LinesTextField id="pesquisar" style={{ marginRight: "5px" }} variant="outlined" margin="dense" onChange={onPesquisarChange} placeholder="Pesquisar..."
                      fullWidth InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined style={{ color: "#c62828" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TablePagination
                      className={classes.pagination}
                      rowsPerPageOptions={[10, 25, 100,
                        {
                          value: reservas.length,
                          label: "Todos",
                        },
                      ]}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      component="div"
                      count={reservas.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      labelRowsPerPage=""
                      nextIconButtonText="Próxima página"
                      backIconButtonText="Página anterior"
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>

              </>

            ) : (
              <>
                <Grid container>
                  <Grid item xs={1}>
                    <Tooltip title="Clique aqui para atualizar a caixa de entrada">
                      <IconButton
                        className={classes.uibutton}
                        onClick={() => handleSyncReservas(0)}
                      >
                        <SyncOutlined />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    {dadosUsuario?.nivelpermissao == 6 ||
                      dadosUsuario.nivelpermissao == 1 ||
                      dadosUsuario?.nivelpermissao == 2 ? (
                      <Button
                        className={classes.buttonCustom}
                        onClick={() => handleClickModalAccept()}
                      >
                        Aprovar todos
                      </Button>
                    ) : null}
                  </Grid>
                  <Grid item xs={4}>
                    <TablePagination
                      className={classes.pagination}
                      rowsPerPageOptions={[
                        10,
                        25,
                        100,
                        {
                          value: reservas.length,
                          label: "Todos",
                        },
                      ]}
                      labelDisplayedRows={defaultLabelDisplayedRows}
                      component="div"
                      count={reservas.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      labelRowsPerPage=""
                      nextIconButtonText="Próxima página"
                      backIconButtonText="Página anterior"
                    />
                  </Grid>
                  <Grid item xs={4} style={{ display: 'flex' }}>
                    <LinesTextField
                      id="pesquisar"
                      style={{ marginRight: "5px" }}
                      variant="outlined"
                      margin="dense"
                      onChange={onPesquisarChange}
                      placeholder="Pesquisar..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined style={{ color: "#c62828" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Tooltip title="Filtro de Status">
                      <IconButton onClick={handleClickTuneStatusOpen}>
                        <Badge
                          color="secondary"
                          variant="dot"
                          invisible={valueTuneStatus != 0 ? false : true}
                        >
                          <TuneOutlined style={{ color: "#c62828" }} />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </>
            )}

            <Dialog
              maxWidth="lg"
              open={showTuneStatus}
              fullScreen={fullscreen}
              onClose={() => onCloseFilter()}
            >
              <DialogTitle className={classes.dialogTitle}>
                <div className={classes.divtitle} style={{}}>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "16pt",
                      fontWeight: "bold",
                    }}
                  >
                    Filtros
                  </Typography>
                  {fullscreen ? (
                    <IconButton
                      onClick={() => onCloseFilter()}
                      style={{ marginLeft: "15px" }}
                    >
                      <CloseOutlined
                        fontSize="large"
                        style={{ color: "white" }}
                      />
                    </IconButton>
                  ) : null}
                </div>
              </DialogTitle>
              <DialogContent>
                <Box margin="10px" marginY="10px">
                  <Typography>Nome usado:</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="typeNome"
                      name="typeNome"
                      value={valueTuneNome}
                      onChange={handleChangeTuneNomeValue}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Viajante"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Solicitante"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Divider />
                <Box marginX="10px" marginY="10px">
                  <Typography>Status selecionado:</Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={valueTuneStatus}
                      onChange={handleChangeTuneStatusValue}
                    >
                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Todos"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Aguardando Atendimento"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="Aguardando Solicitante"
                      />
                      <FormControlLabel
                        value="9"
                        control={<Radio />}
                        label="Autorização Pendente"
                      />
                      <FormControlLabel
                        value="14"
                        control={<Radio />}
                        label="Aprovação Pendente"
                      />
                      <FormControlLabel
                        value="12"
                        control={<Radio />}
                        label="Cancelado"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Em Atendimento"
                      />
                      <FormControlLabel
                        value="11"
                        control={<Radio />}
                        label="Emissão Negada"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Emissão Pendente"
                      />
                      <FormControlLabel
                        value="7"
                        control={<Radio />}
                        label="Emitido"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="Encerrado "
                      />
                      <FormControlLabel
                        value="10"
                        control={<Radio />}
                        label="Expirado"
                      />
                      <FormControlLabel
                        value="13"
                        control={<Radio />}
                        label="Limite Excedido"
                      />
                      <FormControlLabel
                        value="6"
                        control={<Radio />}
                        label="Rascunho"
                      />
                      <FormControlLabel
                        value="8"
                        control={<Radio />}
                        label="Reservado"
                      />
                      <FormControlLabel
                        value="15"
                        control={<Radio />}
                        label="Reembolso Integral"
                      />
                      <FormControlLabel
                        value="16"
                        control={<Radio />}
                        label="Reembolso Parcial"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </DialogContent>
            </Dialog>
          </Toolbar>
          <Menu
            id="simple-menu"
            anchorEl={anchorAcao}
            keepMounted
            open={Boolean(anchorAcao)}
            onClose={handleAcaoClose}
          >
            {reserva.tipo === 2 && permissaoDashboard.usuarioLinestur ? (
              <MenuItem onClick={() => handleClickAlterarCriacao(reserva)}>
                Alterar data de criação
              </MenuItem>
            ) : null}
            {reserva.tipo === 1 && showBotaoEmitir() ? (
              <MenuItem onClick={onClickEmitirReserva}>Emitir</MenuItem>
            ) : null}
            <MenuItem onClick={() => handleHistoricoClick(reserva)}>
              Historico
            </MenuItem>
            {reserva.tipo === 1 ? (
              <React.Fragment>
                <MenuItem onClick={() => handleClickSincronizarAereo(reserva)}>
                  Sincronizar
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleAcaoClose();
                    setOpenEnviarEmail(true);
                  }}
                >
                  Enviar por Email
                </MenuItem>
              </React.Fragment>
            ) : null}
            {reserva.status === 7 && reserva.tipo === 1 ? (
              <>
                {/* <MenuItem onClick={() => setOpenDialogReembolso(true)}>
                  Reembolsar
                </MenuItem> */}
                <Dialog
                  maxWidth="lg"
                  open={openDialogReembolso}
                  fullScreen={fullscreen}
                  onClose={() => setOpenDialogReembolso(false)}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <AppBar
                    className={classes.dialog}
                    style={{ backgroundColor: "#c62828" }}
                  >
                    <Toolbar className={classes.dialogtittle}>
                      <Typography>
                        Reembolso
                      </Typography>
                      <IconButton onClick={() => setOpenDialogReembolso(false)}>
                        <CloseIcon className={classes.icone2} />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <DialogContent>
                    <Typography>
                      Selecionar a opção de reembolso desejado.
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => handleClickStatus("reembolsoparcial")}
                      style={{ width: "50%" }}
                      className={classes.buttonCustom}
                    >
                      Parcial
                    </Button>
                    <Button
                      onClick={() => handleClickStatus("reembolsointegral")}
                      style={{ width: "50%" }}
                      className={classes.buttonCustom}
                    >
                      Integral
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            ) : null}
            {showBotaoCancelar() ? (
              <div>
                <MenuItem onClick={() => handleHistoricoClick(reserva)}>
                  Autorizar/Negar
                </MenuItem>
                <MenuItem onClick={() => handleClickYesNo("cancelar")}>
                  Cancelar
                </MenuItem>
              </div>
            ) : null}
            {/* {reserva.tipo === 1 && showBotaoCancelar() ? (
                            <div>
                                <MenuItem onClick={() => handleHistoricoClick(reserva)}>Autorizar/Negar</MenuItem>
                                <MenuItem onClick={() => handleClickYesNo('cancelar')}>Cancelar</MenuItem>
                            </div>
                        ) : null}
                        {reserva.tipo === 2 && showBotaoCancelar() ? (
                            <div>
                                <MenuItem onClick={() => handleHistoricoClick(reserva)}>Autorizar/Negar</MenuItem>
                                <MenuItem onClick={() => handleClickYesNo('cancelar')}>Cancelar</MenuItem>
                            </div>
                        ) : null} */}
          </Menu>

          {!fullscreen ? (
            <div>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headers.map((item, index) => (
                      <TableCell
                        onClick={() => sortTable(index)}
                        style={{ cursor: "pointer" }}
                        align="center"
                      >
                        <div className={classes.header}>
                          {item.label}
                          {item.icon}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(reservas, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TableRow hover>
                        <TableCell
                          style={{ borderWidth: 0 }}
                          className={classes.bodys}
                          align="center"
                        >
                          {item.idreservatenant}
                        </TableCell>
                        <TableCell
                          style={{ borderWidth: 0 }}
                          className={classes.bodys}
                          align="center"
                        >
                          {
                            <Link
                              style={{ cursor: "pointer" }}
                              onClick={() => handleModalOpen(item)}
                              color="default"
                              underline="none"
                            >
                              {definirAssunto(item.assunto)}
                            </Link>
                          }
                        </TableCell>
                        <TableCell
                          style={{ borderWidth: 0 }}
                          onClick={() => handleModalOpen(item)}
                          className={classes.bodys}
                          align="center"
                        >
                          {
                            <Typography
                              variant="caption"
                              style={{
                                width: "fit-content",
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: Enums.getReservaStatusCor(
                                  item.status
                                ),
                                background: Enums.getReservaStatusCor(
                                  item.status
                                ),
                                color: "white",
                                borderRadius: 3,
                                padding: 5,
                              }}
                            >
                              {Enums.getReservaStatusDescricao(item.status)}
                            </Typography>
                          }
                        </TableCell>
                        <TableCell
                          style={{ borderWidth: 0 }}
                          onClick={() => handleModalOpen(item)}
                          className={classes.bodys}
                          align="center"
                        >
                          {
                            <Grid className={classes.imagem}>
                              <Tooltip title={item.usuario.usuario}>
                                <Avatar src={item.usuario.imagem} />
                              </Tooltip>
                            </Grid>
                          }
                        </TableCell>
                        <TableCell
                          style={{ borderWidth: 0 }}
                          onClick={() => handleModalOpen(item)}
                          className={classes.bodys}
                          align="center"
                        >
                          {item.dataultimaatualizacao
                            ? new Date(item.dataultimaatualizacao)
                              .toLocaleString()
                              .substr(0, 10) + " " + (new Date(item.dataultimaatualizacao)
                                .toLocaleString()
                                .substr(11, 6))
                            : null}
                        </TableCell>
                        <TableCell style={{ borderWidth: 0 }} align="center">
                          <Button
                            className={classes.button}
                            onClick={(e) => handleAcaoClick(e, item)}
                          >
                            Ação
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div>
              <Grid
                container
                item
                style={{ marginBottom: theme.spacing(2) }}
                xs={12}
              >
                <Grid item xs={12}>
                  {reservas
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <Grid
                        container
                        item
                        spacing={2}
                        style={{ margin: 0 }}
                        xs={12}
                      >
                        <Grid item xs={12}>
                          <Paper>
                            <Grid container item xs={12}>
                              <Grid
                                item
                                style={{
                                  padding: theme.spacing(1),
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                xs={2}
                              >
                                <Tooltip title={item.usuario.usuario}>
                                  aaaaa
                                  <Avatar src={item.usuario.imagem} />
                                </Tooltip>
                              </Grid>
                              <Grid
                                container
                                style={{ padding: 10 }}
                                item
                                xs={10}
                              >
                                <Grid item xs={4}>
                                  <Typography style={{ fontSize: "9px" }}>
                                    {item.dataultimaatualizacao
                                      ? new Date(item.dataultimaatualizacao)
                                        .toLocaleString()
                                        .substr(0, 10) + " " + (new Date(item.dataultimaatualizacao)
                                          .toLocaleString()
                                          .substr(11, 6))
                                      : null}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={8}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      width: "fit-content",
                                      borderStyle: "solid",
                                      fontSize: "11px",
                                      borderWidth: 1,
                                      borderColor: Enums.getReservaStatusCor(
                                        item.status
                                      ),
                                      background: Enums.getReservaStatusCor(
                                        item.status
                                      ),
                                      color: "white",
                                      borderRadius: 3,
                                      padding: 5,
                                    }}
                                  >
                                    {Enums.getReservaStatusDescricao(
                                      item.status
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  style={{ paddingBottom: theme.spacing(1) }}
                                >
                                  <Link
                                    style={{
                                      cursor: "pointer",
                                      fontFamily: "arial",
                                    }}
                                    onClick={() => handleModalOpen(item)}
                                    color="default"
                                    underline="none"
                                  >
                                    {definirAssunto(item.assunto)}
                                  </Link>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <Button
                                    className={classes.button}
                                    onClick={(e) => handleAcaoClick(e, item)}
                                  >
                                    Ação
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </div>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export const Lista = (props) => {
  return <Dashboard {...props} component={ListaReservas} />;
};
export default Lista;
