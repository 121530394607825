import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useState } from 'react';
import pessoa_documentosRepositorio from '../../../repositorios/pessoa/documentos';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfirmDelete = props => {
    const theme = useTheme();
    const classes = useStyles();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [disableButton, setDisableButton] = useState(false);

    const handleCloseModal = () => {
        props.setOpenDialogConfirmDelete(false);
    }

    const handleCloseModalAccept = async () => {

        props.setOpenDialogConfirmDelete(false);
    }

    const handleClickModalAcceptYes = async () => {
        try {

            setDisableButton(true);
            props.setIsLoading(true);
            if (props.typeDocument === 'passaporte') {
                await pessoa_documentosRepositorio.deletePassaporte(props.idpessoa)
            }
            // props.clearComponents()
            props.getDocuments();
            handleCloseModalAccept();
        } catch {
            props.setSnackVisible({
                visible: true
            });
            props.setSnackVariant('error')
            props.setSnackMessage("Erro ao deletar documento.")
            return;
        } finally {
            props.setIsLoading(true);
            setDisableButton(false)
        }
    }

    return (
        <Dialog
            open={props.openDialogConfirmDelete}
            onClose={handleCloseModal}
            maxWidth="sm"
            fullWidth
            scroll={'paper'}
            fullScreen={responsive}
            TransitionComponent={Transition}
        >
            <AppBar position="relative" style={{ background: 'transparent', boxShadow: 'none' }}>
                <Toolbar className={classes.appbar} >
                    <Typography>Confirmação</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <DialogContentText >
                    <Typography >Deseja deletar o documento?</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogContent style={{ padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                {/* <img width='100%' height='auto' src={props.src} alt="Imagem Recibo" object-fit='fill' /> */}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClickModalAcceptYes}
                    disabled={disableButton}
                    className={classes.buttonConfirm}
                >
                    SIM
                </Button>
                <Button
                    onClick={handleCloseModalAccept}
                    className={classes.buttonConfirm}
                >
                    NÃO
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ConfirmDelete;
