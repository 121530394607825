import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Input, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, SaveOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import LinesTextField from '../../../../components/LinesTextField';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import emailRepositorio from '../../../../repositorios/email';
import { useStyles } from './styles';

export const CadastroEmail = props => {
    const { register, handleSubmit, errors } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');

    const handleChanged = event => {
        props.setEmail({
            ...props.email,
            [event.target.name]: event.target.value
        })
    }

    const onSubmit = async data => {
        try {

            setIsLoading(true);

            if (!data.idcontaemail || data.idcontaemail <= 0) {
                await emailRepositorio.addEmailAgente(data);
            } else {
                await emailRepositorio.updateEmailAgente(data);
            }

            await props.refreshList();

            setIsLoading(false);

            props.setOpen(false);

            setSnackVariant('success');
            setSnackMessage('Dados Salvos com sucesso!');
            setSnackState({
                visible: true
            });

        } catch (e) {
            let message = "Erro ao salvar!";

            if (e.response.status === 401) {
                message = "Usuário não autorizado"
            }
            if (e.response.status === 400) {
                message = e.response.data.message
            }

            setIsLoading(false);

            setSnackVariant('error');
            setSnackMessage(message);
            setSnackState({
                visible: true
            });
        }
    }

    return (
        <>
            <ULoaderVoo isLoading={isLoading} />
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                maxWidth="sm"
                fullWidth
                fullScreen={responsive}
            >
                <AppBar position="relative">
                    <Toolbar className={classes.toolbar}>
                        <Typography style={{ color: '#fff' }}>Email Agente</Typography>
                        <IconButton onClick={() => props.setOpen(false)}>
                            <CloseOutlined style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent className={classes.root}>
                        <Input
                            type="hidden"
                            name="idcontaemail"
                            value={props.email.idcontaemail || 0}
                            inputRef={register}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <LinesTextField
                                    fullWidth
                                    variant="outlined"
                                    label="Nome"
                                    name="nome"
                                    value={props.email.nome}
                                    onChange={handleChanged}
                                    inputRef={register({ required: 'Nome é obrigatório.' })}
                                    error={errors.nome}
                                    helperText={errors.nome ? errors.nome.message : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LinesTextField
                                    fullWidth
                                    variant="outlined"
                                    label="Email"
                                    name="email"
                                    type="email"
                                    value={props.email.email}
                                    onChange={handleChanged}
                                    error={errors.email}
                                    helperText={errors.email ? errors.email.message : ''}
                                    inputRef={register({
                                        required: 'Email é obrigatório.',
                                        pattern: {
                                            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: 'Email Inválido'
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LinesTextField
                                    fullWidth
                                    variant="outlined"
                                    label="Senha"
                                    value={props.email.senha}
                                    name="senha"
                                    type="password"
                                    onChange={handleChanged}
                                    error={errors.senha}
                                    helperText={errors.senha ? errors.senha.message : ''}
                                    inputRef={register({
                                        required: 'Senha é obrigatória.'
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LinesTextField
                                    fullWidth
                                    variant="outlined"
                                    label="Confirmação de Senha"
                                    value={props.email.confirmacao}
                                    name="confirmacao"
                                    type="password"
                                    onChange={handleChanged}
                                    error={errors.confirmacao}
                                    helperText={errors.confirmacao ? errors.confirmacao.message : ''}
                                    inputRef={register({
                                        required: 'Confirmação de senha é obrigatória.',
                                        validate: {
                                            matches: value => value === props.email.senha || 'Senhas não conferem.'
                                        }
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} type="submit" startIcon={<SaveOutlined style={{ color: '#fff' }} />} className={classes.button}>Salvar</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
};

export default CadastroEmail;
