import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const pessoaRepositorio = {

    getAll: async () => {
        const response = await api.get('/pessoa/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    get: async idpessoa => {
        const response = await api.get('/pessoa/' + idpessoa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response.data;
    },

    postPessoa: async pessoa => {
        const response = await api.post('/pessoa', pessoa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/pessoa/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        const listaComAtivos = response.data.filter(item => item.ativo);
        listaComAtivos.map(item => {
            lista.push({
                label: item.nome + ' ' + item.sobrenome,
                value: item.idpessoa,
                pessoa: item
            })
        })
        return lista;
    },

    postVerificarCpf: async (dto) => {
        const response = await api.post('/pessoa/VerificarCPF', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    updatePessoa: async (idpessoa, dto) => {
        const response = await api.put(`/pessoa/${idpessoa}`, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    updatePessoaANAC: async (dto) => {
        const response = await api.put('/pessoa/UpdatePessoaANAC', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    }
}

export default pessoaRepositorio;
