import { ElectricalServicesOutlined } from "@mui/icons-material"
import { Container, Grid, Typography } from "@mui/material"

export const Categorias = () => {
    return (
        <Container maxWidth="lg">
            <Grid container item xs={12} style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <Grid container item xs={12} style={{ boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)", borderRadius: "10px", padding: "20px" }}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                        <ElectricalServicesOutlined />
                        <Typography variant="h6" style={{ marginLeft: "20px" }}>
                            Integrações
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ padding: "20px" }}>
                       
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}