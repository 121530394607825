import React, { useCallback, useRef, useState } from 'react';
import { Card, CardContent, Typography, Chip, Grid, Divider, Avatar, Box, IconButton, Button, Tooltip, Stack, ListItemAvatar, ListItemText } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit';
// import { CreationDate, IniciadoEm } from '../../../Desktop/sections';
// import { formatarParaNomeStatusViagem } from '../../../../../enums/enumPrestacaoDeContas';
import AddCardIcon from '@mui/icons-material/AddCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
// import { getTipoDespesaForNumber, getTipoHodometroForNumber } from '../../../../../mock/tiposdespesas';
// import DialogComponent from '../../../Desktop/sections/dialogs-desktop';
// import { ImageComponent } from '../../../../../components/ImageComponent';
import { GoogleMap, Polyline } from '@react-google-maps/api';
// import { primary } from '../../../../../theme/palette';
import { ArrowUpward } from '@mui/icons-material';
import { ImageComponent } from '../../../../../../components/ImageComponent';
import { CreationDate } from '../../../../Desktop/sections';

const apiKey = "AIzaSyDwG3G11O_o14Bax96T3qNQ7b6Pn-n5ldw"; // !!!!!!!!!!!!!!!! ESCONDER ISSO EM ALGUM LUGAR !!!!!!!!!!!!!!
const centerprimary = { lat: -33.8667, lng: 151.1955 };
const libraries = ["places", "drawing", "geometry"];

export const InfoHodometroMComponent = ({ despesa }) => {

    // const mapRef = useRef(null);
    // const onLoad = useCallback((map) => (mapRef.current = map), []);

    return (
        <>
            <Grid container item xs={12} style={{ width: "600px" }}>
                <Grid item xs={12} style={{ padding: "8px" }}>
                    {despesa.hodometro?.comprovanteida != null && despesa.hodometro?.comprovantevolta == "" ? (
                        <>
                            <Tooltip title="Clique e veja foto inicial">
                                <Stack style={{
                                    boxShadow: "10px",
                                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    padding: "10px",
                                    borderRadius: "15px",
                                    cursor: "pointer"
                                }} direction="row" spacing={2}>
                                    <ListItemAvatar>
                                        <ImageComponent width={48} height={48} src={despesa.hodometro.comprovanteida} alt={"Imagem não encontrada"} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={"Foto Inicial"}
                                        secondary={
                                            <>
                                                <Box component="span">
                                                    {despesa.hodometro.kmida} Km/l
                                                </Box>
                                            </>
                                        }
                                    />
                                </Stack>
                            </Tooltip>
                        </>
                    ) : (null)}
                    {despesa.hodometro?.comprovantevolta != null && despesa.hodometro?.comprovantevolta != "" ? (
                        <>
                            <Tooltip title="Clique e veja foto final">
                                <Stack style={{
                                    boxShadow: "10px",
                                    boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    padding: "10px",
                                    borderRadius: "15px",
                                    cursor: "pointer",
                                    marginTop: "10px"
                                }} direction="row" spacing={2}>
                                    <ListItemAvatar>
                                        <ImageComponent width={48} height={48} src={despesa.hodometro.comprovantevolta} alt={"Imagem não encontrada"} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={"Foto Inicial"}
                                        secondary={
                                            <>
                                                <Box component="span">
                                                    {despesa.hodometro.kmfim} Km/l
                                                </Box>
                                            </>
                                        }
                                    />
                                </Stack>
                            </Tooltip>
                        </>
                    ) : (null)}
                    {/* <Grid item xs={12}>
                                {despesa.hodometro?.cords != null ? (
                                    <GoogleMap
                                        zoom={16}
                                        mapContainerStyle={{ width: "100%", height: "100%", borderRadius: "20px" }}
                                        onLoad={onLoad}
                                        options={{
                                            streetViewControl: false,
                                            mapTypeControl: false,
                                            zoomControl: true,
                                            fullscreenControl: true,
                                        }}
                                    >
                                        {despesa.hodometro.cords.length > 0 && (
                                            <Polyline
                                                path={despesa.hodometro.cords}
                                                options={{
                                                    strokeColor: primary.darker,
                                                    strokeWeight: 10,
                                                    strokeOpacity: 2,
                                                }}
                                            />
                                        )}
                                    </GoogleMap>
                                ) : (null)}
                            </Grid> */}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 0 }}>
                            <Typography variant="body1">
                                Data de criação
                            </Typography>
                            <Typography variant="body1">
                                Total em km
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 0 }}>
                            <Typography variant="h6" width={140}><CreationDate data={despesa.datadecriacao} /></Typography>
                            <Typography variant="h3">{despesa.hodometro?.kmtotal != null ? despesa.hodometro.kmtotal + " Km" : "Não mensionado"}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 0, justifyContent: "flex-end" }}>
                            <ArrowUpward color="success" />
                            <Typography variant="body1" sx={{ ml: 1 }}>
                                Total há receber
                            </Typography>
                        </Box>
                        <Typography variant="h3" sx={{ fontWeight: 'bold', marginLeft: "auto" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.hodometro?.valor || 0)}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}