import Cookies from 'universal-cookie';
import api from './Api';

export const Autenticacao = async (usuario, senha) => {
    const cookies = new Cookies();
    try {
        const token = await api.post('/token', {
            usuario: usuario,
            senha: senha
        });

        if (!token) {
            return;
        }

        if (token.status !== 200) {
            return
        }

        const auth = token.data.token;
        const sessionId = token.data.sessionId;
        //1800000 = 30 minutos
        cookies.set('token', auth, { path: '/', expires: new Date(Date.now() + 36000000) });
        cookies.set('sessionId', sessionId, { path: '/', expires: new Date(Date.now() + 36000000) });
        const tokenRetorno = await api.get('/usuario/ambiente', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });

        cookies.set('usession', tokenRetorno.data.usuario, { path: '/', expires: new Date(Date.now() + 36000000) });
        return true;
    } catch {
        return false;
    }
}
