import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    groupButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },
    gridRowFlexEnd: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    gridRowCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    typography_G: {
        color: '#43B947',
        fontWeight: 'bold',
    },
    typography_R: {
        color: '#E50008',
        fontWeight: 'bold',
    },
    typography_B: {
        color: '#41A5D1',
        fontWeight: 'bold',
    },
}));
