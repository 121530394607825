import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, Typography, withStyles } from "@material-ui/core";
import { AddCircleOutlineOutlined, EditOutlined, ExpandMoreOutlined, RemoveCircleOutlineOutlined } from "@material-ui/icons";
import PropTypes from 'prop-types';
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import USearchField from "../../../components/usearchfield";
import { useStyles } from "./styles";

const AntTabs = withStyles({
  root: {
    background: '#fafafa',
    color: '#c62828'
  },
  indicator: {
    backgroundColor: '#c62828',
  },
})(Tabs);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={2}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const EcoGestaoDesk = (props) => {
  const classes = useStyles();
  const isPast = props.dataList.isPast;
  const [tabValue, setTabValue] = useState(0);

  const handleClickRemovePaxFromGroup = (group, pax) => {
    props.handleClickRemovePaxFromGroup(group, pax)
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  }

  const changeVeiculo = (name, value, group, obj) => {
    props.onChangeVeiculoGroup(name, value, group, obj)
  }

  function definirAssunto(assunto) {
    if (!assunto) {
      return "";
    }
    let textos = assunto.split("<br />");
    return (
      <div>
        {textos.map((item) => (
          <div>{item}</div>
        ))}
      </div>
    );
  }

  return (
    <>
      <Box>
        <AntTabs
          variant="fullWidth"
          scrollButtons="on"
          value={tabValue}
          onChange={handleChangeTab}
        >
          <Tab label="Passageiros" {...a11yProps(0)} />
          <Tab label="Grupos" {...a11yProps(1)} />
        </AntTabs>
        <TabPanel value={tabValue} index={0}>
          <Grid container>
            <Grid item xs={12} className={classes.gridAeroportosPax}>
              {props.dataList.aeroportosPassageiros.length <= 0 ? null : props.dataList.aeroportosPassageiros.map((item) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography style={{ fontSize: '14pt' }}><b>{item.cidade}</b></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={{ maxWidth: '55px' }}><Typography></Typography></TableCell>
                          <TableCell align="center"><Typography><b>Nome</b></Typography></TableCell>
                          <TableCell align="center"><Typography><b>Centro de custo</b></Typography></TableCell>
                          <TableCell align="center"><Typography><b>Horário</b></Typography></TableCell>
                          <TableCell align="center"><Typography><b>Trecho</b></Typography></TableCell>
                          <TableCell align="center"><Typography><b>Emissão CO2 (kg)</b></Typography></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.passageiros.map((pax) => (
                          <TableRow >
                            <TableCell align="center" style={{ maxWidth: '55px' }}>
                              <IconButton disabled={isPast || !props.permissao.permiteeditarecogestao} onClick={() => props.handleClickPax(pax, isPast)}>
                                <AddCircleOutlineOutlined />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center"><Typography >{pax.nome}</Typography></TableCell>
                            <TableCell align="center">
                              <>
                                <Typography>{pax.cc}</Typography>
                                <Typography>{pax.ccNome}</Typography>
                              </>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title={(<Typography>Esse valor é referente ao horário de {pax.horadesc} no aeroporto</Typography>)}>
                                <>
                                  <Typography>{pax.horadesc}</Typography>
                                  <Typography>{pax.hora}</Typography>
                                </>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center"><Typography >{definirAssunto(pax.trecho)}</Typography></TableCell>
                            <TableCell style={{ color: '#c62828' }} align="center"><Typography >{pax.carbono}</Typography></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {props.dataList.grupos.length <= 0 ? null : (
            <Grid item container xs={12} className={classes.gridGrupos}>
              {props.dataList.grupos.map((item) => (
                <>
                  <Grid container className={classes.gridItemGrupo}>
                    <Grid item xs={4} style={{ alignSelf: 'center' }}>
                      <Typography style={{ fontSize: '14pt' }}><b>Nº {item.idgrouptenant} - {item.nome}</b></Typography>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'end' }}>
                      <Button
                        disabled={isPast || !props.permissao.permiteeditarecogestao}
                        onClick={() => props.changeVeiculo(item)}
                        endIcon={<EditOutlined />}
                      >
                        <b>{item.veiculo.label}</b>
                      </Button>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'end', alignSelf: 'center' }}>
                      <Typography style={{ fontSize: '14pt' }}><b>Destino: {item.cidade} ({item.ocupacao})</b></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center"><Typography><b>Nome</b></Typography></TableCell>
                            <TableCell align="center"><Typography><b>Centro de custo</b></Typography></TableCell>
                            <TableCell align="center"><Typography><b>Horário</b></Typography></TableCell>
                            <TableCell align="center"><Typography><b>Emissão C02 (kg)</b></Typography></TableCell>
                            <TableCell align="center"><Typography><b>Cancelar</b></Typography></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item.passageiros.map((pax) => (
                            <>
                              <TableRow>
                                <TableCell align="center"><Typography>{pax.nome}</Typography></TableCell>
                                <TableCell align="center">
                                  <Tooltip title={(<Typography>{pax.ccNome}</Typography>)}>
                                    <>
                                      <Typography>{pax.cc}</Typography>
                                      <Typography>{pax.ccNome}</Typography>
                                    </>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography>{pax.horadesc}</Typography>
                                  <Typography>{pax.hora}</Typography>
                                </TableCell>
                                {/* <TableCell align="center">
                                  <Typography>{definirAssunto(pax.trecho)}</Typography>
                                </TableCell> */}
                                <TableCell align="center">
                                  <Typography style={item.isEco ? { color: '#43a047' } : { color: '#c62828' }}><b>{pax.carbono}</b></Typography>
                                </TableCell>
                                <TableCell align="center" >
                                  <IconButton size="small" disabled={isPast || !props.permissao.permiteeditarecogestao} onClick={() => handleClickRemovePaxFromGroup(item, pax)}>
                                    <RemoveCircleOutlineOutlined />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ textAlign: 'center' }} colSpan={4}><Typography>Endereço: {pax.pontoPartida}</Typography></TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
          )}
        </TabPanel>
      </Box>

    </>
  );
};

export default EcoGestaoDesk;
