import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textfield: {
        margin: theme.spacing(0),
        padding: theme.spacing(0)
    },
    linha: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: 0.4,
        borderRadius: 5
    },
    borda: {
        height: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
