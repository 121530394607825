import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    dialogTitle: {
        background: '#c62828',
    },
    typoTitle: {
        color: '#fafafa',
    },
    buttonCancel: {
        color: '#fafafa',
        background: '#808080',
        '&:hover': {
            background: '#999999',
        },
    },
    buttonDelete: {
        color: '#fafafa',
        background: '#c62828',
        '&:hover': {
            background: '#d15252',
        },
    },

}));
