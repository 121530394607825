import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Grid, useMediaQuery } from "@material-ui/core";
import { useRef } from "react";
import { useState } from "react";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#c62828",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["hodômetro ida", "hodômetro volta"];
}

export default function StepperContainer(props) {
  const classes = useStyles();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (props.file != null && activeStep == 1) {
      handleNext();
    }
    if (props.file2 != null) {
      handleNext();
    }
  }, []);

  useEffect(() => {
    if (props.file != null && activeStep == 1) {
      handleNext();
    }
    if (props.file2 != null) {
      handleNext();
    }
  }, [props.handleInputChange]);

  return (
    <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      <Grid style={{ textAlign: "center" }}>
        {responsive ? (
          <Typography className={classes.instructions}>
            Enviar imagem do hodômetro
          </Typography>
        ) : null}
      </Grid>
    </div>
  );
}

export const DespesasViagemContainerHdometro = () => {
  const [file, setFile] = useState(null);
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleInputChange = (event) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <Grid container spacing={1}>
      <StepperContainer />
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          id="icon-button-file"
          style={{ display: "none" }}
          required
          accept="image/*, application/pdf"
          type="file"
          onChange={handleInputChange}
        />
        <label htmlFor="icon-button-file">
          <input
            id="icon-button-file"
            ref={fileInputRef}
            style={{ display: "none" }}
            required
            accept="image/*, application/pdf"
            type="file"
            onChange={handleInputChange}
          />
          <label htmlFor="icon-button-file">
            <Button
              startIcon={<PhotoCameraIcon />}
              style={{
                backgroundColor: "#c62828",
                color: "white",
                height: "16px",
                padding: "20px",
              }}
              onClick={handleButtonClick}
            >
              <p>Enviar foto</p>
            </Button>
          </label>
        </label>
      </Grid>
    </Grid>
  );
};
