import { Dialog, DialogContent, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@material-ui/core';
import { EditRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import cookie from 'universal-cookie';
import api from '../../../../Api';
import LoadingButton from '../../../../components/loadingButton';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import PoliticaViagemRepositorio from '../../../../repositorios/politicaViagem';
import Dashboard from '../../../dashboard';
import CadastroConfiguracaoOrganizacao from '../cadastro';
import { useStyles } from './styles';

export const ListaConfiguracaoOrganizacao = props => {
    const matches = useMediaQuery('(max-width:480px)');
    const classes = useStyles();
    const cookies = new cookie();
    const [empresas, setEmpresas] = useState([]);
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [tipoSnack, setTipoSnack] = useState('success');
    const [statusSnack, setStatusSnack] = useState({ visible: false });
    const [backDrop, setBackDrop] = useState(false);
    const fullscreen = useMediaQuery('(max-width:480px), (max-width:900px) and (orientation: landscape)');
    const [cadastroOpen, setCadastroOpen] = useState(false);
    const [politica, setPolitica] = useState({});

    const salvar = async () => {
        try {
            const response = await PoliticaViagemRepositorio.update(politica);
            if (response.status !== 204) {
                throw 'Erro'
            }
            setMensagemSnack('Dados salvos com sucesso!');
            setTipoSnack('success');
            setStatusSnack({ visible: true });
            setCadastroOpen(false);
        } catch {
            setMensagemSnack('Não foi possivel Salvar devido a um erro!');
            setTipoSnack('error');
            setStatusSnack({ visible: true });
            return;
        }

    }
    const handleCadastroOpen = async (item, visible) => {
        setBackDrop(true);
        const response = await PoliticaViagemRepositorio.getPoliticaEmpresa(item.idempresa);
        setPolitica(response.data);
        setBackDrop(false);
        setCadastroOpen(visible);
    }

    useEffect(() => {
        api.get('/empresa/GetEmpresaTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        }).then(response => {
            setEmpresas(response.data);
        })
    }, [])

    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: statusSnack.visible, setSnackbar: setStatusSnack }} />
            <ULoaderVoo isLoading={backDrop} />
            <Dialog
                className={classes.cadastro}
                open={cadastroOpen}
                onClose={e => setCadastroOpen(false)}
                fullScreen={fullscreen}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogContent style={{
                    padding: '0px',
                    paddingTop: '0px'
                }}>
                    <CadastroConfiguracaoOrganizacao {...{ politica, setPolitica, setModalOpen: setCadastroOpen }} />
                    <div className={classes.salvar}>
                        <LoadingButton {...{ salvar: salvar, modalOpen: cadastroOpen, setModalOpen: setCadastroOpen }} />
                    </div>
                </DialogContent>
            </Dialog>
            <Paper elevation={matches ? 0 : 3} className={classes.paper}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.heads} align="center"></TableCell>
                                <TableCell className={classes.heads} align="center">ID</TableCell>
                                <TableCell className={classes.heads} align="center">Nome</TableCell>
                                {!matches ? (<TableCell className={classes.heads} align="center">Status</TableCell>) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {empresas.map(item => (
                                <TableRow className={classes.row}>
                                    <TableCell align='center'>
                                        <IconButton onClick={() => handleCadastroOpen(item, true)} margin="small">
                                            <EditRounded fontSize="default" />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align='center'>{item.idempresa}</TableCell>
                                    <TableCell align='center'>{item.nomefantasia}</TableCell>
                                    {!matches ? (<TableCell align='center'>{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>) : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
};

export const Lista = props => {

    return (
        <div>
            <Dashboard {...props} component={ListaConfiguracaoOrganizacao} />
        </div>
    )
};
export default Lista;
