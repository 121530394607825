import { EnumTipo } from "../enums/enumPrestacaoDeContas";

export const _bankingCreditCard = [
  {
    id: 3213,
    balance: 23432.03,
    cardType: "mastercard",
    cardHolder: "Renato Cariani",
    cardNumber: "**** **** **** 3640",
    cardValid: "11/22",
  },
  {
    id: 12123,
    balance: 18000.23,
    cardType: "visa",
    cardHolder: "Julio Balestrin",
    cardNumber: "**** **** **** 8864",
    cardValid: "11/25",
  },
  {
    id: 1232,
    balance: 2000.89,
    cardType: "mastercard",
    cardHolder: "Igor Guedes",
    cardNumber: "**** **** **** 7755",
    cardValid: "11/22",
  },
];

export const _bankingRecentTransitions = [
  {
    id: 122,
    name: "ASDDA",
    avatarUrl: "SASASASSAA",
    type: "Income",
    message: "Receive money from",
    category: "Annette Black",
    date: new Date(),
    amount: 1212,
    pessoa: {
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_13.jpg",
      nome: "Renato Cariani",
      email: "retinhowhey1kg@gmail.com",
    },
    datadecriacao: new Date(2023, 6, 21),
    titulo: "Viagem pra growth",
    centrodecusto: 1,
    totalinicial: 600,
    total: 589.9,
    status: EnumTipo.viagemstatus.correcaorealizada,
    datainicio: new Date(2022, 4, 3),
    datafim: new Date(2023, 2, 15),
  },
  {
    id: 1222,
    name: "ASDDA",
    avatarUrl: "SASASASSAA",
    type: "Income",
    message: "Receive money from",
    category: "Annette Black",
    date: new Date(),
    amount: 1212,
    pessoa: {
      img: "https://api-dev-minimal-v510.vercel.app/assets/images/avatar/avatar_13.jpg",
      nome: "Renato Cariani",
      email: "retinhowhey1kg@gmail.com",
    },
    datadecriacao: new Date(2023, 6, 21),
    titulo: "Viagem pra growth",
    centrodecusto: 1,
    totalinicial: 600,
    total: 589.9,
    status: EnumTipo.viagemstatus.correcaorealizada,
    datainicio: new Date(2022, 4, 3),
    datafim: new Date(2023, 2, 15),
  },
  // {
  //   id:32132,
  //   name: _mock.fullName(3),
  //   avatarUrl: _mock.image.avatar(3),
  //   type: "Expenses",
  //   message: "Payment for",
  //   category: "Courtney Henry",
  //   date: _mock.time(3),
  //   status: "completed",
  //   amount: _mock.number.price(3),
  // },
  // {
  //   id: _mock.id(4),
  //   name: _mock.fullName(4),
  //   avatarUrl: _mock.image.avatar(4),
  //   type: "Receive",
  //   message: "Payment for",
  //   category: "Theresa Webb",
  //   date: _mock.time(4),
  //   status: "failed",
  //   amount: _mock.number.price(4),
  // },
  // {
  //   id: _mock.id(5),
  //   name: null,
  //   avatarUrl: null,
  //   type: "Expenses",
  //   message: "Payment for",
  //   category: "Beauty & Health",
  //   date: _mock.time(5),
  //   status: "completed",
  //   amount: _mock.number.price(5),
  // },
  // {
  //   id: _mock.id(6),
  //   name: null,
  //   avatarUrl: null,
  //   type: "Expenses",
  //   message: "Payment for",
  //   category: "Books",
  //   date: _mock.time(6),
  //   status: "progress",
  //   amount: _mock.number.price(6),
  // },
];
