import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
        '& label.Mui-focused': {
            color: '#c62828',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#c62828',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#c62828',
            },
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },

}));
