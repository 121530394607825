import { makeStyles, fade } from "@material-ui/core/styles";
import { yellow, teal, lightGreen } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    height: "100%",
    width: "450px",
    ["@media (max-width:480px)"]: {
      maxWidth: 345,
      height: "100%",
      width: "280px",
    },
    ["@media (max-width:900px) and (orientation: landscape)"]: {
      maxWidth: 345,
      height: "100%",
      width: "280px",
    },
    ["@media (max-width:800px) and (orientation: portrait)"]: {
      maxWidth: 345,
      height: "100%",
      width: "280px",
    },
  },
  media: {
    height: 30,
    minHeight: 30,
    ["@media (max-width:900px) and (orientation: landscape)"]: {
      height: 30,
      minHeight: 30,
    },
    ["@media (max-width:800px) and (orientation: portrait)"]: {
      height: 30,
      minHeight: 30,
    },
  },
  cards: {
    alignItems: "center",
    justifyContent: "space-evenly",
    display: "flex",
    maxWidth: "100vw",
    overflowY: "hidden",
    flexDirection: "row",
    width: "100%",
    marginTop: "30px",
    ["@media (max-width:480px)"]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100vw",
    },
    ["@media (max-width:900px) and (orientation: landscape)"]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100vw",
    },
    ["@media (max-width:900px) and (orientation: portrait)"]: {
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100vw",
    },
  },
  teste: {
    display: "flex",
    flexDirection: "row",
  },
  button: {
    background: "#b91119",
    color: "white",
    "&:hover": {
      background: "#810b11",
    },
    borderRadius: "5px",
  },
  titulo: {
    fontSize: "25px",
    padding: theme.spacing(1, 5),
    ["@media(max-width:480px)"]: {
      padding: theme.spacing(1, 3),
    },
  },
  expansion: {
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(7),
    },
  },
  search: {
    width: "70%",
    marginRight: "1%",
    ["@media (max-width:480px)"]: {
      width: "100%",
      marginLeft: "1%",
    },
  },
  search2: {
    width: "100%",
    marginLeft: "1%",
    marginRight: "1%",
    ["@media (max-width:480px)"]: {
      width: "100%",
      marginLeft: "1%",
    },
    ["@media (max-width:900px) and (orientation: landscape)"]: {
      width: "70%",
      marginLeft: "0%",
      marginRight: "0%",
    },
    ["@media (max-width:800px) and (orientation: portrait)"]: {
      width: "100%",
      marginLeft: "2%",
      marginRight: "0%",
    },
    ["@media (max-width:1280px) and (orientation: landscape)"]: {
      width: "100%",
    },
  },
  filtros: {
    marginTop: "3%",
    marginBottom: "1%",
    ["@media (max-width:480px)"]: {
      marginTop: "0%",
      marginBottom: "0%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    ["@media (max-width:900px) and (orientation: landscape)"]: {
      marginTop: "0%",
      marginBottom: "0%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    ["@media (max-width:800px) and (orientation: portrait)"]: {
      marginTop: "0%",
      marginBottom: "0%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    ["@media (max-width:1280px) and (orientation: landscape)"]: {
      marginTop: "0%",
      marginBottom: "0%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
  },

  testevai: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    ["@media (max-width:480px)"]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(1, 0),
    },
  },
  botoes: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ["@media (max-width: 900px) and (orientation: landscape)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    ["@media (max-width: 800px) and (orientation: portrait)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  botoes1: {
    ["@media (max-width:480px)"]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  botao: {
    position: "static",
    width: "100%",
    ["@media (max-width:900px) and (orientation: landscape)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: theme.spacing(1),
    },
    ["@media (max-width:800px) and (orientation: portrait)"]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingBottom: theme.spacing(1),
    },
  },
  botao1: {
    ["@media (max-width:480px)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: theme.spacing(1),
    },
  },
  udate: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media (max-width: 900px) and (orientation: landscape)"]: {
      flexDirection: "column",
      width: "100%",
    },
    ["@media (max-width: 800px) and (orientation: portrait)"]: {
      flexDirection: "row",
      width: "64%",
    },
    ["@media (max-width: 1280px) and (orientation: landscape)"]: {
      flexDirection: "row",
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
      width: "71%",
    },
  },
  data: {
    marginRight: "3%",
    ["@media(max-width:900px) and (orientation: landscape)"]: {
      marginLeft: "0%",
      marginRight: "0%",
      width: "70%",
    },
    ["@media(max-width:800px) and (orientation: portrait)"]: {
      marginLeft: "2%",
      marginRight: "0%",
      width: "100%",
    },
    ["@media(max-width:1280px) and (orientation: landscape)"]: {
      marginLeft: "0%",
      marginRight: "0%",
      width: "100%",
    },
  },
  lista2: {
    padding: theme.spacing(0, 5),
    ["@media (max-width:480px)"]: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(0, 3),
    },
  },
  search3: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    ["@media(max-width:900px) and (orientation: landscape)"]: {
      width: "50%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
    ["@media(max-width:800px) and (orientation: portrait)"]: {
      width: "50%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
    ["@media(max-width:1280px) and (orientation: landscape)"]: {
      width: "50%",
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(1),
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
  botao0: {
    marginLeft: "2%",
    background: "#b91119",
    color: "white",
    "&:hover": {
      background: "#810b11",
    },
  },
  botao1: {
    marginLeft: "2%",
    background: "black",
    color: "white",
    "&:hover": {
      background: "black",
    },
  },
  botao2: {
    marginLeft: "2%",
    background: "black",
    color: "white",
    "&:hover": {
      background: "black",
    },
  },
  groupButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  botao3: {
    marginLeft: "2%",
    background: "#b91119",
    color: "white",
    "&:hover": {
      background: "#810b11",
    },
  },
  botao4: {
    marginLeft: "2%",
    background: "#b91119",
    color: "white",
    "&:hover": {
      background: "#810b11",
    },
  },
  lista: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(7),
    },
  },
  iconzitobutton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    alignItems: "right",
    width: "100%",
    ["@media (max-width:480px)"]: {
      paddingTop: theme.spacing(1.5),
      justifyContent: "center",
      alignItems: "center",
    },
    ["@media (max-width:900px) and (orientation: landscape)"]: {
      paddingTop: theme.spacing(1.5),
      justifyContent: "center",
      alignItems: "center",
    },
    ["@media (max-width:800px) and (orientation: portrait)"]: {
      paddingTop: theme.spacing(1.5),
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    ["@media (max-width:1280px) and (orientation: landscape)"]: {
      paddingTop: theme.spacing(1.5),
      justifyContent: "center",
      alignItems: "center",
      width: "40%",
    },
  },
  listCard: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(0, 1, 0, 1),
  },
  card1: {
    ["@media(max-width:480px)"]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  },
  separar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: "0%",
    paddingBottom: "0%",
    ["@media(max-width:900px) and (orientation: landscape)"]: {
      paddingTop: "5%",
      padding: theme.spacing(0),
      paddingBottom: "1%",
      flexDirection: "row",
    },
    ["@media(max-width:800px) and (orientation: portrait)"]: {
      paddingTop: "5%",
      padding: theme.spacing(0),
      paddingBottom: "1%",
      flexDirection: "row",
    },
  },
  separar1: {
    ["@media(max-width:480px)"]: {
      paddingTop: "5%",
      paddingBottom: "10%",
      flexDirection: "column",
    },
  },
  cardtab: {
    width: "95%",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    ["@media(max-width:900px) and (orientation: landscape)"]: {
      width: "97%",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    ["@media(max-width:800px) and (orientation: portrait)"]: {
      width: "97%",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  cardtable: {
    paddingBottom: "20%",
    ["@media(max-width:900px) and (orientation: landscape)"]: {
      paddingBottom: "5%",
    },
    ["@media(max-width:800px) and (orientation: portrait)"]: {
      paddingBottom: "5%",
    },
  },
  dados: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  matches4: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    ["@media(max-width:1280px) and (orientation: landscape)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    ["@media(max-width:800px) and (orientation: portrait)"]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
  },
  search4: {
    width: "35%",
    marginLeft: theme.spacing(1),
    ["@media(max-width:1280px) and (orientation: landscape)"]: {
      width: "32%",
      marginLeft: theme.spacing(3),
    },
  },
  data1: {
    ["@media(max-width:800px) and (orientation: portrait)"]: {
      marginLeft: "2%",
      marginRight: "0%",
      width: "100%",
    },
    ["@media(max-width:1280px) and (orientation: landscape)"]: {
      marginLeft: "2%",
      marginRight: "0%",
      width: "100%",
    },
  },
  udate1: {
    ["@media (max-width:480px)"]: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  search31: {
    width: "100%",
  },
}));
