import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Snackbar from '../../../../components/snackbar';
import ExportExcel from '../../../../components/UExportExcel';
import ULoaderVoo from '../../../../components/uloadervoo';
import viagemRepositorio from '../../../../repositorios/viagem';
import { useStyles } from './styles';

function isValidDate(date) {
    return moment(date).isValid();
}

const DialogConfirmExportExcel = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');
    const [backDrop, setBackDrop] = useState(false);
    const [colunasExcel, setColunasExcel] = useState([]);
    const [rowsExcel, setRowsExcel] = useState([]);

    const handleClickCloseDialogConfirmExportExcel = () => {
        props.setOpenDialogExportExcel(false);
        setBackDrop(false);
    }

    const convertDate = date => {
        return date ? date.constructor === Date ? date.toLocaleDateString() : date : date;
    }

    const handleClickConfirm = async () => {

        if (props.dto.dataInicio > props.dto.dataFim) {
            setSnackVariant('error');
            setSnackMessage('Data de início maior que data final.');
            setSnackVisible({ visible: true })
            return;
        }

        if (!isValidDate(props.dto.dataInicio) || !isValidDate(props.dto.dataFim)) {
            setSnackVariant('error');
            setSnackMessage('Data Inválida.');
            setSnackVisible({ visible: true });
            return;
        }

        try {
            setBackDrop(true)
            let response = await viagemRepositorio.postExportDataAllViagens(props.dto);
            setRowsExcel(response.data)
            document.getElementById("exportClick").click();
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Falha ao pesquisar despesas.');
            setSnackVisible({ visible: true });
            return;
        } finally {
            setBackDrop(false)
        }
    }

    useEffect(() => {
        setColunasExcel(["ID", "Viajante", "Centro de Custo", "Título", "Data de Criação", "Localizador", "ID despesa", "Tipo", "Valor", "Data de Inserção", "Descrição", "Valor Auditado"])
    }, [])

    return (
        <>
            <ULoaderVoo isLoading={backDrop} />
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <Box hidden={true}>
                <ExportExcel name="Exportação das despesas por viagem" columns={colunasExcel} data={rowsExcel} element={
                    <>
                        <Button className={classes.button} id={'exportClick'}>
                            Exportar
                        </Button>
                    </>
                } />
            </Box>
            <Dialog
                open={props.open}
                maxWidth="xs"
                onClose={() => handleClickCloseDialogConfirmExportExcel()}
                fullWidth
                fullScreen={responsive}
            >
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Typography>Confirmação</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText >
                        <Typography>
                            Excel será exportado seguindo os seguintes critérios:
                            <br />
                            Status: Finalizado
                            <br />
                            Data Início: {convertDate(props.dto.dataInicio)}<br />
                            Data Fim: {convertDate(props.dto.dataFim)}
                            {props.dto.idCentroCusto ? (
                                <>
                                    <br />
                                    Centro de Custo: {props.dto.centroCusto}
                                </>
                            ) : ''}
                            {props.dto.idViajante ? (
                                <>
                                    <br />
                                    Viajante: {props.dto.viajante}
                                </>
                            ) : ''}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <>
                        <Button
                            className={classes.buttonConfirm}
                            onClick={() => handleClickCloseDialogConfirmExportExcel()}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            className={classes.buttonConfirm}
                            onClick={() => handleClickConfirm()}
                        >
                            CONFIRMAR
                        </Button>

                    </>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DialogConfirmExportExcel;
