import React from 'react';
import { Card, CardContent, CardHeader, Avatar, Typography, Box, Chip, Button } from '@mui/material';
import { styled } from '@mui/system';
import { themepalette } from '../../../../theme/palette';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { EnumTipo, formatarValorComMoeda } from '../../../../enums/enumPrestacaoDeContas';


const CardFooter = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'auto',
});

export const RecentTransfersCard = ({ transfers }) => {
    return (
        <Card sx={{ maxWidth: 500, margin: 'auto', boxShadow: 1, borderRadius: 2 }}>
            <CardHeader title="Transferências Recentes" />
            <CardContent>
                {transfers?.length == 0 ? (
                    <Box sx={{ ml: 1, justifyContent: "center" }}>
                        <Typography variant="body1" fontWeight="bold">
                            Nenhuma transferência recente
                        </Typography>
                    </Box>
                ) : (
                    <>
                        {transfers?.map((transfer) => (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar src={transfer.perfil} alt={transfer.nome} sx={{ width: 46, height: 46 }} />
                                <Box sx={{ ml: 1, justifyContent: "left" }}>
                                    <Typography variant="subtitle" fontWeight="bold">
                                        {transfer.nome}
                                    </Typography>
                                    <Chip
                                        label={transfer.tipo}
                                        sx={{
                                            backgroundColor: 'rgba(76, 175, 80, 0.1)',
                                            color: '#4CAF50',
                                            fontWeight: 'bold',
                                            fontSize: '0.875rem',
                                            height: '24px',
                                            marginLeft: "-10px"
                                        }}
                                    />
                                </Box>
                                <Box sx={{ ml: 'auto' }}>
                                    <Typography variant="h6" style={{ fontSize: "14px" }} fontWeight="bold" color="green">
                                        {formatarValorComMoeda(transfer.tipo_moeda != null ? transfer.tipo_moeda : EnumTipo.moedas.BRL.id, transfer.total)}
                                        {/* {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(transfer.total)} */}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </>
                )}
            </CardContent>
            {/* <CardFooter sx={{ p: 2 }} style={{ justifyContent: "right" }}>
                <Button
                    endIcon={<ChevronRight />}
                    style={{ color: themepalette.palette.grey[900] }}
                >
                    Ver Todas
                </Button>
            </CardFooter> */}
        </Card>
    );
};
