import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    // root: {
    //     padding: theme.spacing(2)
    // },
    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        },
    },

    gridDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paperCustom: {
        height: '120px',
        width: '90vw',
        maxWidth: '90vw',
        marginTop: '15px',
        marginBottom: '15px'
    },

    boxCustom: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
}));
