import React, { useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';

const CameraPermission = ({ cameraPermission = "Loading", setCameraPermission }) => {

    useEffect(() => {
        checkCameraPermission();
    }, []);

    const checkCameraPermission = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.error('getUserMedia não é suportado neste navegador');
            setCameraPermission(false);
            return;
        }

        try {
            const permission = await navigator.permissions.query({ name: 'camera' });
            if (permission.state === 'granted') {
                setCameraPermission(true);
            } else if (permission.state === 'prompt') {
                setCameraPermission(null);
            } else if (permission.state === 'denied') {
                setCameraPermission(false);
            }
        } catch (error) {
            console.error('Erro ao verificar permissão da câmera:', error);
            setCameraPermission(false);
        }
    };

    const requestCameraPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            stream.getTracks().forEach(track => track.stop());
            setCameraPermission(true);
        } catch (error) {
            console.error('Erro ao solicitar permissão da câmera:', error);
            setCameraPermission(false);
        }
    };

    return (
        <Box textAlign="center" p={2}>
            {cameraPermission === null && (
                <>
                    <Typography variant="h6">Solicitando permissão para acessar a câmera...</Typography>
                    <Button size="large" variant="contained" color="primary" onClick={requestCameraPermission}>
                        Permitir Acesso à Câmera
                    </Button>
                </>
            )}
            {cameraPermission === false && (
                <>
                    <Typography variant="h6" gutterBottom>Permissão para acessar a câmera foi negada.</Typography>
                    <Typography variant="body1" gutterBottom>
                        Para permitir o acesso à câmera, siga estas etapas:
                    </Typography>
                    <Typography variant="body2" align="left">
                        <ol>
                            <li>Abra as configurações do navegador.</li>
                            <li>Vá para "Privacidade e segurança" ou "Configurações do site".</li>
                            <li>Selecione "Permissões" ou "Câmera".</li>
                            <li>Encontre este site na lista e permita o acesso à câmera.</li>
                            <li>Recarregue a página e tente novamente.</li>
                        </ol>
                    </Typography>
                    <Button size="large" variant="contained" color="primary" onClick={requestCameraPermission} sx={{ mt: 2 }}>
                        Tentar novamente
                    </Button>
                </>
            )}
            {cameraPermission === true && (
                <Typography variant="h6">Permissão para acessar a câmera concedida.</Typography>
            )}
        </Box>
    );
};

export default CameraPermission;
