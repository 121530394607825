import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {

    },
        reserva:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            width:'100%'
        },
        border:{
            borderStyle:'solid',
            borderWidth:'1px',
            borderColor:'DimGray',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
        },
        dados:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-evenly',
            alignItems:'center',
            width:'100%'
        },
        
        bilhete:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            width:'100%'
        },
        tab:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white'
        },
        tab2:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        tabmodificado3:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
        },
        tabmodificado4:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        tabmodificado5:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        tabsobrenome:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        titulos:{
            background:'DimGray',
            color:'white',
            justifyContent:'space-evenly',
            alignItems:'center',
        },
        taborigem:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        tabcentrocusto:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        tabmodificado2:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        tabmodificado:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        tabnome:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            background:'DimGray',
            color:'white',
        },
        conteudo:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
        },
        titulo:{
            display:'flex',
            flexDirection:'row',
            justifyContent:'left',
            alignItems:'left',
        },
        info:{
            display:'flex',
            flexDirection:'column',
            justifyContent:'left',
            alignItems:'left',
            fontFamily:'arial',
            fontSize:'1%',
            
        },
}));