import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    accordionRed: {
        backgroundColor: "#C62828",
        color: "#FFFFFF",
    },

    accordionValues: {
        padding: '0px',
    },

    accordionDetailsItem: {
        padding: '1rem'
    },

    buttonRed: {
        background: '#c62828',
        color: '#ffffff',
        width: '7rem',
        '&:hover': {
            background: 'rgba(198, 40, 40,0.5)',
            borderStyle: 'none',
            borderWidth: 0,
            borderColor: 'transparent',
            color: 'white',
        },
    },

    colorIcon: {
        color: '#ffffff'
    },

    typographyGrey: {
        color: '#505050'
    },

    typographyWhite: {
        color: '#ffffff'
    },

    gridCenterAll: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    gridTextCenter: {
        textAlign: 'center'
    },

    gridCenterAllWithDirection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },

    gridContainerCustom: {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        padding: "20px",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)",
        marginTop: "15px",
        marginBottom: "15px",
        borderRadius: "10px"
    },

    gridContainerInfoCustom: {
        padding: '1rem',
        textAlign: 'justify',
        color: "white"
    },


    gridContainerRed: {
        backgroundColor: "#C62828",
        borderRadius: "20px 20px 0px 0px",
        boxShadow: "none"
    },

    gridTax: {
        placeSelf: 'center'
    },

    menuCustom: {
        minWidth: '4rem',
    },

    resumValues: {
        color: "#FFFFFF",
        // borderColor: "#ffffff",
        border: "1px solid #FFFFFF",
        borderRadius: "10px",
        margin: '1rem',
        padding: '1rem',
    }

}));
