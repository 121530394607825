import { Box, Button, ButtonGroup, Container, Grid, Hidden, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GetApp, SearchOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '../../../../components/snackbar';
import UDatePicker from '../../../../components/udatepicker';
import ExportExcel from '../../../../components/UExportExcel';
import ULoaderVoo from '../../../../components/uloadervoo';
import USearchField from '../../../../components/usearchfield';
import EnumCompany from '../../../../enums/enumFlightCompany';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import itinerarioRealizadoRepositorio from '../../../../repositorios/relatorio/itinerarioRealizado';
import Dashboard from '../../../dashboard';
import ItinerariosList from './IntinerariosList';
import ItinerariosGrid from './ItinerariosGrid';
import { useStyles } from './styles';
import ExportToExcel_ItinerarioRealizado from './ItinerariosGrid/exportToExcel_ItinerarioRealizado';

function isValidDate(date) {
    return moment(date).isValid();
}

export const ListaItinerarioRealizado = () => {
    const classes = useStyles();
    const theme = useTheme();
    const permissao = useSelector(state => state.permissao.permissaoDashboard);
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [listaRelatorio, setListaRelatorio] = useState([]);
    const [centroCusto, setCentroCusto] = useState(null);
    const politicaViagem = useSelector(state => state.permissao.politicaViagem);
    const [viajantes, setViajantes] = useState([]);
    const [viajante, setViajante] = useState(null);
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [isLoading, setIsLoading] = useState(false);
    const [colunasExcel, setColunasExcel] = useState([]);
    const [rowsExcel, setRowsExcel] = useState([]);

    const handleClickSearch = async () => {
        if (dataInicio > dataFim) {
            setSnackVariant('error');
            setSnackMessage('Data de início maior que data final.');
            setSnackVisible({ visible: true })
            return;
        }

        if (!isValidDate(dataInicio) || !isValidDate(dataFim)) {
            setSnackVariant('error');
            setSnackMessage('Data Inválida.');
            setSnackVisible({ visible: true });
            return;
        }

        let dto = {
            dataInicio,
            dataFim,
            idCentroCusto: centroCusto ? centroCusto.value : 0,
            idViajante: viajante ? viajante.value : 0
        }

        try {
            setIsLoading(true);
            const response = await itinerarioRealizadoRepositorio.getItinerarioTenant_v2(dto);
            setListaRelatorio(response.data);
            // setListaRelatorio(mapToData(response.data));
        } catch (error) {
            setSnackVariant('error');
            setSnackMessage('Erro ao gerar relatório.');
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false);
        }
    };

    const mapToData = lista => {
        const listaFormatada = lista.map((item, index) => {
            return {
                id: index + 1,
                idreserva: item.idreserva,
                passageiro: item.passageiro,
                cia: item.cia,
                trecho: item.trecho,
                datacriacao: new Date(item.horaida).toLocaleDateString(),
                horaida: new Date(item.horaida).toLocaleTimeString().substr(0, 5),
                horachegada: new Date(item.horachegada).toLocaleTimeString().substr(0, 5),
                localizador: item.localizador
            }
        });
        return listaFormatada;
    }

    const getData = async () => {
        try {
            setIsLoading(true);
            let dto = {
                dataInicio,
                dataFim,
                idCentroCusto: centroCusto ? centroCusto.value : 0,
                idViajante: viajante ? viajante.value : 0
            }
            const response = await itinerarioRealizadoRepositorio.getItinerarioTenant_v2(dto);
            setListaRelatorio(mapToData(response.data));

        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    const setPeriodoPesquisa = (periodo) => {
        let inicio = new Date();
        let fim = new Date();
        switch (periodo) {
            case 1:
                inicio.setDate(inicio.getDate() - 7);
                // getData()
                break;
            case 2:
                inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
                fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
                // getData()
                break;
            case 3:
                inicio = new Date(inicio.getFullYear(), 0, 1);
                fim = new Date(inicio.getFullYear(), 11, 31);
                // getData()
                break;
        }

        setDataInicio(inicio);
        setDataFim(fim);
    }

    const handleChangeData = () => {
        let inicio = new Date();
        if ((dataInicio !== '' && dataInicio !== undefined && dataInicio !== null) && (dataFim !== '' && dataFim !== undefined && dataFim !== null)) {

            if (dataInicio.toLocaleDateString() === new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date().toLocaleDateString()) {
                return 1;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth(), 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0).toLocaleDateString()) {
                return 2;
            }
            if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), 0, 1).toLocaleDateString() &&
                dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), 11, 31).toLocaleDateString()) {
                return 3;
            }

            return 4;
        }
    }

    useEffect(() => {
        (async () => {
            const response = await centroCustoRepositorio.getSearchField();
            const responseViajantes = await pessoaRepositorio.getSearchField();
            //await getData();
            setCentrosCusto(response);
            setViajantes(responseViajantes);

        })()
    }, [])

    // useEffect(() => {
    //     setColunasExcel(["ID", "Passageiro", "CIA", "Trecho", "Data", "Horário Ida", "Horário Chegada", "Localizador"])
    //     const datas = listaRelatorio.map(item => {
    //         let valueNome = EnumCompany.getLogoCompanhiaAereaEnum(item.cia);
    //         return [
    //             item.idreserva,
    //             item.passageiro,
    //             valueNome.nome,
    //             item.trecho,
    //             item.datacriacao,
    //             item.horaida,
    //             item.horachegada,
    //             item.localizador
    //         ]
    //     })
    //     setRowsExcel(datas);
    // }, [listaRelatorio]);


    return (
        <section>
            <Hidden smUp>
                <ULoaderVoo isLoading={isLoading} />
            </Hidden>
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <Container maxWidth="xl">
                <Box mt={2} mb={responsive ? 10 : 0}>
                    <Box mb={1}>
                        <Grid container>
                            <Grid item xs={responsive ? 12 : 2}>
                                <Typography variant="h6" component="h1">Itinerários Realizados</Typography>
                            </Grid>
                            {responsive ? null : (
                                <>
                                    <Grid item xs={8}>
                                        <Grid item xs={12} className={classes.groupButton}>
                                            <ButtonGroup>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 1 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 1 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(1)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Semanal</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 2 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 2 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(2)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Mensal</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 3 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 3 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(3)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Anual</Typography>
                                                </Button>
                                                <Button
                                                    style={{
                                                        background: handleChangeData() === 4 ? '#c62828' : 'transparent',
                                                        color: handleChangeData() === 4 ? 'white' : '#c62828',
                                                        border: '1px solid #c62828',
                                                        '&:hover': {
                                                            background: '#c62828',
                                                            color: 'white'
                                                        }
                                                    }}
                                                    onClick={() => setPeriodoPesquisa(4)}
                                                >
                                                    <Typography style={{ fontSize: 11 }}>Personalizado</Typography>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}></Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    <Paper elevation={3}>
                        <Box p={3}>
                            <form autoComplete="off">
                                <Grid container spacing={1} >
                                    <Grid item xs={12} md={2}>
                                        <UDatePicker date={dataInicio} setDate={setDataInicio} label="Data Início" />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <UDatePicker date={dataFim} setDate={setDataFim} label="Data Fim" />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        {permissao.relatorioCentroCusto ? (
                                            <USearchField disabled={!politicaViagem.utilizarcentrocusto} label="Centro de custo" itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        {permissao.relatorioPessoal ? (
                                            <USearchField label="Viajante" itens={viajantes} value={viajante} setValue={setViajante} />
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} md={1} style={{ alignSelf: 'center' }}>
                                        <Button onClick={handleClickSearch} className={classes.button} variant="contained" fullWidth >
                                            <SearchOutlined />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Paper>
                    <Box mt={2}>
                        {responsive ? (
                            <>
                                <Grid container >
                                    <Grid item xs={12} className={classes.gridRowFlexEnd}>
                                        {/* <ExportExcel name="Relatório de itinerários" columns={colunasExcel} data={rowsExcel} element={
                                            <>
                                                <Button className={classes.button}>
                                                    <GetApp />
                                                    Exportar
                                                </Button>
                                            </>
                                        } />*/}
                                        {listaRelatorio.length > 0 && (
                                            <ExportToExcel_ItinerarioRealizado data={listaRelatorio} />
                                        )}
                                    </Grid>
                                </Grid>
                                <ItinerariosList data={listaRelatorio} />
                            </>
                        ) : (
                            <>
                                <Paper elevation={3}>
                                    <Grid container spacing={1} style={{ margin: '0px' }}>
                                        <Grid item xs={10}></Grid>
                                        <Grid item xs={2} className={classes.gridRowFlexEnd}>
                                            {/* <ExportExcel name="Relatório de itinerários" columns={colunasExcel} data={rowsExcel} element={
                                                <>
                                                    <Button className={classes.button}>
                                                        <GetApp />
                                                        Exportar
                                                    </Button>
                                                </>
                                            } /> */}
                                            {listaRelatorio.length > 0 && (
                                                <ExportToExcel_ItinerarioRealizado data={listaRelatorio} />
                                            )}
                                        </Grid>
                                    </Grid>
                                    <ItinerariosGrid isLoading={isLoading} data={listaRelatorio} />
                                </Paper>
                            </>
                        )}
                    </Box>
                </Box>
            </Container>
        </section>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaItinerarioRealizado} />
        </div>
    )
};
export default Lista;
