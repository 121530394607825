import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const compraRealizadoRepositorio = {

    getRelatorioComprasRealizadas: async (dtoDadosRelatorio, filtros) => {
        let dto = {
            dtoDadosRelatorio,
            filtros
        }
        const response = await api.post('/Relatorio/GetRelatorioComprasRealizadas', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

}

export default compraRealizadoRepositorio;
