import { Container, Grid, Typography } from "@mui/material"
import PowerIcon from '@mui/icons-material/Power';
import CableIcon from '@mui/icons-material/Cable';
import ServiceList from "./servicos";
import TotalInicialConfig from "./totalinicial";
import { ElectricalServicesOutlined, LuggageOutlined, PaidOutlined, Receipt, ReceiptOutlined } from "@mui/icons-material";
import LuggageIcon from '@mui/icons-material/Luggage';
import PalavrasConfigQrCode from "./palavras";
import { useEffect, useState } from "react";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import { prestacaoDeContasConfigRepositorio } from "../../../../../repositorios/prestacaodecontas/config";
import ULoaderVoo from "../../../../../components/uloadervoo";

export const ControlConfigComponent = () => {
    const [loading, setLoading] = useState(false)
    const [configsCompanie, setConfigsCompanie] = useState();
    const [gruposPalavras, setGruposPalavras] = useState([])

    const users = [
        { name: 'User 1', avatar: 'https://via.placeholder.com/40' },
        { name: 'User 2', avatar: 'https://via.placeholder.com/40' },
        { name: 'User 3', avatar: 'https://via.placeholder.com/40' },
        { name: 'User 4', avatar: 'https://via.placeholder.com/40' },
    ];

    const { enqueueSnackbar } = useCustomSnackbar();

    const getConfigCompanie = () => {
        // try{
        // }

        setConfigsCompanie({
            idtenant: 1,
            totalinicialpermitido: 2000,
            permiteusargps: true,
            permiteusarqrcode: true,
            permiteautodespesas: true
        })
    }

    const getAllGroups = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasConfigRepositorio.getAllGroupQrCode();
            setGruposPalavras(response)
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar grupos.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickCreateGroup = async (newGroup) => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.postCreateGroupQrCode(newGroup);
            getAllGroups()
            enqueueSnackbar('Grupo criado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao adicionar grupo.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditGroup = async (id, newGroup) => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.putEditGroupQrCode(id, newGroup);
            getAllGroups()
            enqueueSnackbar('Grupo editado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao adicionar grupo.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickExcluirGroup = async (idgroup) => {
        setLoading(true)
        try {
            await prestacaoDeContasConfigRepositorio.deleteGroup(idgroup);
            getAllGroups()
            enqueueSnackbar('Grupo criado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao editar grupo.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getConfigCompanie()
        getAllGroups()
    }, [])


    return (
        <Container maxWidth="lg">
            <ULoaderVoo isLoading={loading} />
            {/* 
            <Grid item xs={12}>
                <Typography variant="h6">Controle sobre QrCode</Typography>
            </Grid>
            */}
            {/* <Grid container item xs={12} style={{ marginBottom: "20px", backgroundColor: "white", marginTop: "3px" }}>
                <Grid container item xs={12} style={{ boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)", borderRadius: "10px", padding: "20px" }}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                        <LuggageOutlined />
                        <Typography variant="h6" style={{ marginLeft: "20px" }}>
                            Sobre Viagens
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ padding: "20px" }}>
                        <TotalInicialConfig />
                    </Grid>
                </Grid>
            </Grid> */}

            <Grid container item xs={12} style={{ marginBottom: "20px", backgroundColor: "white" }}>
                <Grid container item xs={12} style={{ display: "flex" }}>
                    <Typography variant="h5" style={{ marginLeft: "20px" }}>
                        Sobre Despesas
                    </Typography>
                </Grid>
                <Grid container item xs={12} style={{ padding: "16px" }}>
                    <Typography variant="body1" style={{ padding: "5px", color: "#575757" }}>Grupos de palavras, utilizada para ser verificada quando o usuario utilizar o Qr code</Typography>
                    <PalavrasConfigQrCode  users={users} grupos={gruposPalavras} handleClickCreateGroup={handleClickCreateGroup} handleClickEditGroup={handleClickEditGroup} handleClickExcluirGroup={handleClickExcluirGroup} />
                </Grid>
            </Grid>

            {/* <Grid container item xs={12} style={{ backgroundColor: "white", borderRadius: "10px" }}>
                <Grid container item xs={12} style={{ boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)", borderRadius: "10px", padding: "20px" }}>
                    <Grid item xs={12} style={{ display: "flex" }}>
                        <ElectricalServicesOutlined />
                        <Typography variant="h6" style={{ marginLeft: "20px" }}>
                            Integrações
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} style={{ padding: "20px" }}>
                        <Grid container item xs={12} style={{ boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)", borderRadius: "10px", padding: "20px" }}>
                            <ServiceList />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid item xs={12} style={{ height: "40px" }}>

            </Grid>
        </Container>
    )
}