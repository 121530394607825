import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {

    },
    button: {
        color: '#fff'
    },
    buttonSave: {
        background: '#c62828',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#b91119',
        }
    },
    appbar: {
        background: '#616161',
    },
    inline: {
        display: 'inline',
    },
    inner: {
        background: '#FFFFFF',
        border: '0px',
        width: '-webkit-fill-available',
        scrollBar: 'hidden',
        textAlign: 'justify',
        fontSize: '11pt',
        resize: "none",
        fontFamily: "sans-serif",
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    buttonConfirm: {
        background: '#c62828',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            background: '#b91119',
        }
    },
}));
