import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 250,
        padding: theme.spacing(4)
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#646464'
    },
    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119'
        }
    }
}));
