import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({

    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },

    appbarGrey: {
        background: "#616161",
        color: "white",
        position: "relative"
    },

    buttonAutorizar: {
        borderStyle: 'solid',
        borderWidth: 1,
        color: '#fafafa',
        '&:hover': {
            background: 'darkgreen',
            color: 'white'
        }
    },

    buttonNegar: {
        borderStyle: 'solid',
        borderWidth: 1,
        color: '#fafafa',
        '&:hover': {
            background: '#fafafa',
            color: '#c62828'
        }
    },


    boxStatus: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        color: "white",
        alignItems: "center",
        borderRadius: "10px",
    },


    dialogContent: {
        padding: 0,
        background: "silver",
        minHeight: 400,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        // alignItems: historicos.length <= 0 ? "center" : "flex-start",
    },

    dialogActions: {
        background: '#c62828'
    },

    gridEnd: {
        display: 'flex',
        justifyContent: 'end'
    },

    iconClose: {
        color: '#fafafa'
    },

    typographyCenter: {
        display: 'flex',
        alignContent: 'center'
    },

    typographyNoHistory: {
        color: '#ffffff',
        width: "100%",
        textAlign: 'center'
    }
}));
