import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LinesTextField from '../../../../../components/LinesTextField';
import ULoaderVoo from '../../../../../components/uloadervoo';
import { useStyles } from './styles';

const DialogTextCancelamentoDespesa = (props) => {
    const [backDrop, setBackDrop] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const valueReadJustify = props.valueReadJustify;
    const [valueDefaultJustify, setValueDefaultJustify] = useState();
    const openModalJustificarCancelar = props.openModalJustificarCancelar;
    const valueJustifyCancelID = props.valueJustifyCancelID;
    const [valueJustifyCancel, setValueJustifyCancel] = useState(props.valueJustifyCancel);
    var alterado = false

    const handleCancel = () => {
        props.setValueJustifyCancel(null)
        props.setValueDefaultJustify(null)
        props.handleCloseJustificarCancel(valueJustifyCancelID, true)
    }

    useEffect(() => {
        if (!valueReadJustify) {
            props.setValueJustifyCancel("")
            props.setValueDefaultJustify("")
        }
    }, [])

    useEffect(() => {
        return () => {
            if (alterado) {
                return;
            } else {
                props.setValueJustifyCancel(null)
            }
        }
    }, [props.valueDefaultJustify, alterado])

    return (
        <>
            <ULoaderVoo isLoading={backDrop} />
            <Dialog onClose={!valueReadJustify ? () => handleCancel() : () => props.handleCloseJustificarCancel(valueJustifyCancelID)} open={openModalJustificarCancelar} >
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Typography>Justificativa do cancelamento</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText style={{ width: '500px' }} >
                        {!valueReadJustify ?
                            (
                                <>
                                    <Typography >Justique o motivo de estar marcando esse item para ser cancelado.</Typography>
                                    <br />
                                </>
                            ) :
                            (null)}
                        <LinesTextField
                            fullWidth
                            variant="outlined"
                            multiline
                            disabled={valueReadJustify}
                            defaultValue={props.valueDefaultJustify}
                            value={props.valueJustifyCancel}
                            onChange={(e) => {
                                alterado = true
                                props.setValueJustifyCancel(e.target.value)
                            }}
                            maxRows={6}
                            rows={6}
                            className={classes.inner}
                        />
                    </DialogContentText>
                </DialogContent>
                {!valueReadJustify ?
                    (
                        <DialogActions>
                            <Button
                                onClick={handleCancel}
                                className={classes.buttonConfirm}
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => props.handleSaveJustifyCancel(props.valueJustifyCancel, valueJustifyCancelID)}
                                className={classes.buttonConfirm}
                            >
                                Salvar
                            </Button>
                        </DialogActions>
                    ) : (null)}
            </Dialog>
        </>
    );
}

export default DialogTextCancelamentoDespesa;
