import { AppBar, Button, IconButton, Slide, Toolbar, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Close } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogExit(props) {
  const classes = useStyles();

  return (
    <Dialog open={props.openDialog} onClose={props.handleCloseDialog} fullScreen={props.responsive} TransitionComponent={Transition}>
      <AppBar position="relative" style={{ background: "none" }}>
        <Toolbar className={classes.appbar}>
          <Typography>Sair</Typography>
          <IconButton onClick={props.handleCloseDialog}>
            <Close className={classes.iconButton} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography>
          Deseja realmente sair?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleCloseDialog}
          color="primary"
          className={classes.button}
        >
          NÃO
        </Button>
        <Button
          onClick={props.handleClickSair}
          color="primary"
          autoFocus
          className={classes.button}
        >
          SIM
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default DialogExit;
