import { createTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import 'core-js/stable';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import App from './App';
import LinkAereo from './reducers/LinkAereoReducer';
import Permissao from './reducers/permissaoReducer';
import Variados from './reducers/VariadosReducer';
import { registerServiceWorker } from './serviceWorker';
import './styles.css';

const reducers = combineReducers({
    linkAereo: LinkAereo,
    permissao: Permissao,
    variados: Variados,
})

ReactDOM.render(
    <Provider store={createStore(reducers)}>
        <App />
    </Provider>
    , document.getElementById('root'));
registerServiceWorker();
