import { useState, useCallback, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, emptyRows, getComparator, useTable } from '../../../../../components/table';
import { isAfter } from 'date-fns';
import { Avatar, Badge, Button, CardHeader, Checkbox, Chip, Divider, ListItemText, Menu, MenuItem, Stack, TableCell, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Scrollbar from '../../../../../components/scrollbar';
import { useBoolean } from '../../../../../hooks/use-boolean';
import OrderTableToolbar from '../../../../prestacaodecontas/Desktop/sections/forTables/order-table-toolbar';
import OrderTableFiltersResult from '../../../../prestacaodecontas/Desktop/sections/forTables/order-table-filters-result';
import { AddCardOutlined, Info, MoreVertOutlined, NotificationImportant } from '@mui/icons-material';
import { fDate, fTime } from '../../../../../utils/format-time';
import { EnumTipo, formatarParaNomeStatusAdiantamento, formatarParaNomeStatusAdiantamentoBool, formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusViagem } from '../../../../../enums/enumPrestacaoDeContas';
import { DetalhesViagemComponent } from '../../../../prestacaodecontas/Desktop/auditor/detalhesViagem';
import DialogComponent from '../../../../prestacaodecontas/Desktop/sections/dialogs-desktop';
import { PagamentoComponent } from '../../Pagamento';
import { HistoricoDePagamentosComponent } from '../../Historico';
import TableHeadCustomFinance from './table-head-custom';

const TABLE_HEAD = [
    { id: 'pessoa', label: 'Descrição', width: "auto" },
    { id: 'total', label: 'Total', width: "auto", align: 'center' },
    { id: 'totalinicial', label: 'Total inicial', width: "auto", align: 'center' },
    { id: 'status', label: 'Status', width: "auto", align: 'center' },
];

const CustomTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: "#212B36", // Cor do indicador personalizada
    },
    '& .MuiTab-root.Mui-selected': {
        color: "#212B36", // Cor do indicador personalizada
    },
    '& .MuiTab-root': {
        minHeight: "0px !important"
    }
});

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const STATUS_OPTIONS = [
    { value: 'todos', label: 'Todos', status: [0] },
    { value: 'finalizado', label: 'Finalizado', status: [5] },
    { value: 'financeiro', label: 'Financeiro', status: [4] },
    { value: 'emviagem', label: 'Em viagem', status: [1] },
    { value: 'pendente', label: 'Pendente', status: [4, 0] },
];

const defaultFilters = {
    name: '',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    statusFilter: 'todos',
    statusArr: [0],
    centrodecusto: 0,
    idpessoa: 0
};
// ----------------------------------------------------------------------

export default function OrderListView({ data, handleClickFilter, getAllViagens, getViagensReload, filtredvalues }) {
    // const classes = useStyles();
    const table = useTable({ defaultOrderBy: 'orderNumber' });
    const confirm = useBoolean();
    const [dataTable, setDataTable] = useState(data);
    const [filters, setFilters] = useState(defaultFilters);
    const [anchorEl, setAnchorEl] = useState(null);
    const [viagem, setViagem] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [openPag, setOpenPag] = useState(false);
    const [openHist, setOpenHist] = useState(false);
    const [openAdiant, setOpenAdiant] = useState(false);
    const [selectViagem, setSelectViagem] = useState({})
    const open = Boolean(anchorEl);
    const dateError = isAfter(filters.startDate, filters.endDate);
    const dataFiltered = dataTable;

    const denseHeight = table.dense ? 56 : 56 + 20;

    const canReset =
        filters.statusFilter != 'todos';

    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

    const handleFilters = useCallback(
        (name, value) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );

    const applyFilters = () => {
        let filteredData = data;

        if (filters.name) {
            filteredData = filteredData.filter(item =>
                item.titulo.toLowerCase().includes(filters.name.toLowerCase())
            );
        }

        if (filters.statusFilter && filters.statusFilter !== 'todos') {
            filteredData = filteredData.filter(item => filters.statusArr.includes(item.status));
        }

        setDataTable(filteredData);
    };

    const handleClick = (event, row) => {
        setViagem(row)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickDetails = (row) => {
        setOpenDetails(true)
        handleClose(false)
    }

    const handleClickPagamento = (row) => {
        setOpenPag(true)
        handleClose(false)
    }

    const handleClickHistory = (row) => {
        setOpenHist(true)
        handleClose(false)
    }

    const handleResetFilters = useCallback(() => {
        handleStatusOption(0);
        setFilters(defaultFilters);
    }, []);

    const handleDeleteRow = useCallback(
        (id) => {
            const deleteRow = dataTable.filter((row) => row.id !== id);
            setDataTable(deleteRow);
        },
        [dataTable]
    );

    const handleStatusOption = (value, status) => {
        if (status[0] == 0) {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: 'todos', statusArr: [0] }));
            return
        } else {
            setFilters((prevFilters) => ({ ...prevFilters, statusFilter: value, statusArr: status }));
            return
        }
    }

    const handleFilterStatus = useCallback(
        (event, newValue) => {
            handleFilters('status', newValue);
        },
        [handleFilters]
    );

    const getInvoiceLength = (status) => {
        if (status == 0) {  // Considerando '0' como o valor default para todos os status
            return data.length;
        }
        return data?.filter((item) => status.includes(item.status)).length;
    }

    const handleClickSearch = () => {
        handleClickFilter(filters)
    }

    useEffect(() => {
        setDataTable(data)
    }, [data])

    useEffect(() => {
        applyFilters()
    }, [filters])

    return (
        <Container maxWidth="xl">
            {viagem != null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                #{viagem.id} - Pagar viagem
                            </>
                        }
                        open={openPag}
                        handleClose={() => setOpenPag(false)}
                        body={<PagamentoComponent viagem={viagem} setOpen={setOpenPag} getAllViagens={getAllViagens} />}
                        action={null}
                        width={"lg"}
                    />

                    <DialogComponent
                        title={
                            <>
                                {viagem?.titulo}  {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                            </>
                        }
                        open={openDetails}
                        handleClose={() => setOpenDetails(false)}
                        body={<DetalhesViagemComponent viagem={viagem} />}
                        action={null}
                        width={"sm"}
                    />

                    <DialogComponent
                        title={
                            <>
                                {viagem?.titulo}  {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                            </>
                        }
                        open={openHist}
                        handleClose={() => setOpenHist(false)}
                        body={<HistoricoDePagamentosComponent viagem={viagem} />}
                        action={null}
                        width={"sm"}
                    />
                </>
            )}

            <Card style={{ borderRadius: "10px", marginBottom: "20px" }}>
                <CustomTabs
                    value={filters.status}
                    onChange={handleFilterStatus}
                    aria-label="secondary tabs example"
                    size="small"
                >
                    {STATUS_OPTIONS.map((tab) => (
                        <Tab
                            key={tab.value}
                            iconPosition="end"
                            value={tab.value}
                            label={tab.label}
                            variant="subtitle1"
                            style={{ fontFamily: "unset " }}
                            onClick={() => handleStatusOption(tab.value, tab.status)}
                            icon={
                                <Chip
                                    size="small"
                                    variant={
                                        ((tab.status.includes(STATUS_OPTIONS[0].value) || tab.value === filters.status) && 'filled') || 'soft'
                                    }
                                    sx={{
                                        backgroundColor:
                                            (tab.value === 'todos' && '#212B36') ||
                                            (tab.value === 'finalizado' && 'primary.light') ||
                                            (tab.value === 'financeiro' && 'primary.dark') ||
                                            (tab.value === 'pendente' && 'secondary.main') ||
                                            (tab.value === 'emviagem' && 'primary.main') ||
                                            // (tab.value === 'controladoria' && '#fbc02d') 
                                            // ||
                                            (tab.value === 'cancelado' && '#c62828'),
                                        color: tab.value === 'todos' || tab.value === 'cancelado' ? '#FFFFFF' : '#FFFFFF',
                                        borderRadius: '5px'
                                    }}
                                    label={
                                        tab.value === 'todos'
                                            ? data?.length
                                            : getInvoiceLength(tab.status)
                                    }
                                />
                            }
                        />

                    ))}
                </CustomTabs>
                <OrderTableToolbar
                    filters={filters}
                    setFilters={setFilters}
                    data={dataTable}
                    onFilters={handleFilters}
                    handleClickSearch={handleClickSearch}
                    dateError={dateError}
                />
                {canReset && (
                    <OrderTableFiltersResult
                        filters={filters}
                        onFilters={handleFilters}
                        handleStatusOption={handleStatusOption}
                        onResetFilters={handleResetFilters}
                        results={dataFiltered.length}
                        sx={{ p: 2.5, pt: 0 }}
                    />
                )}
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={dataFiltered.length}
                        onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                                checked,
                                dataFiltered.map((row) => row.id)
                            )
                        }
                        action={
                            <Tooltip title="Delete">
                                <IconButton color="primary" onClick={confirm.onTrue}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                    <Scrollbar>
                        <Table size="small" sx={{ minWidth: 660 }}>
                            <TableHeadCustomFinance
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={dataTable.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                                onSelectAllRows={(checked) =>
                                    table.onSelectAllRows(
                                        checked,
                                        dataFiltered.map((row) => row.id)
                                    )
                                }
                            />
                            <TableBody>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={(e) => handleClose(e)}
                                >
                                    <MenuItem onClick={() => handleClickDetails(viagem)} disableRipple>
                                        <Info />
                                        Detahes
                                    </MenuItem>
                                    {
                                        (
                                            viagem?.status == EnumTipo.viagemstatus.financeiro && (formatarParaNomeStatusAdiantamentoBool(viagem.adiantamento) === "pendente" || viagem.adiantamento.status !== 4) ||
                                            viagem?.status == EnumTipo.viagemstatus.aguardandoinicio && (formatarParaNomeStatusAdiantamentoBool(viagem.adiantamento) === "pendente" || viagem.adiantamento.status !== 4)
                                        )
                                        && (
                                            <MenuItem onClick={() => handleClickPagamento(viagem)} disableRipple>
                                                <AddCardOutlined />
                                                Pagar Viagem
                                            </MenuItem>
                                        )}
                                    <MenuItem onClick={() => handleClickHistory(viagem)} disableRipple>
                                        <HistoryToggleOffIcon />
                                        Histórico De Pagamentos
                                    </MenuItem>
                                </StyledMenu>
                                {dataTable ? (
                                    <>
                                        {dataTable
                                            ?.slice(
                                                table.page * table.rowsPerPage,
                                                table.page * table.rowsPerPage + table.rowsPerPage
                                            )
                                            .map((row) => (
                                                <TableRow hover selected={table.selected.includes(row.id)} width="auto">
                                                    <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Avatar alt={row.pessoa.nome} src={row.pessoa.img} sx={{ mr: 2 }} />

                                                        <ListItemText
                                                            primary={row.titulo}
                                                            secondary={row.pessoa.nome}
                                                            primaryTypographyProps={{ fontSize: "12px" }}
                                                            secondaryTypographyProps={{
                                                                fontSize: "11px",
                                                                color: 'text.disabled',
                                                            }}
                                                        />

                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography style={{ fontSize: "12px" }}>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.total)}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="center" >
                                                        {row.tem_multimonetario ? (
                                                            <>
                                                                {formatarParaNomeStatusAdiantamentoListI(row.adiantamento)}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Tooltip title={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.totalinicial)}>
                                                                    {formatarParaNomeStatusAdiantamentoList(row.adiantamento)}
                                                                </Tooltip>
                                                            </>
                                                        )}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <Typography
                                                            variant="soft"
                                                        >
                                                            {row.status != null ? (
                                                                <>
                                                                    {formatarParaNomeStatusViagem(row.status)}
                                                                </>
                                                            ) : (<>
                                                            </>)}
                                                        </Typography>
                                                    </TableCell>

                                                    <TableCell align="right" sx={{ px: 1 }}>
                                                        <IconButton
                                                            id="demo-customized-button"
                                                            aria-controls={open ? 'demo-customized-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            variant="contained"
                                                            disableElevation
                                                            onClick={(e) => handleClick(e, row)}
                                                        >
                                                            <MoreVertOutlined />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        <TableEmptyRows
                                            height={denseHeight}
                                            emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered.length)}
                                        />
                                    </>
                                ) : null}
                                <TableNoData notFound={notFound} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                    count={dataFiltered.length}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />
            </Card>
        </Container>
    );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters, dateError }) {
    const { status, name, startDate, endDate } = filters;

    const stabilizedThis = inputData?.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (name) {
        inputData = inputData?.filter(
            (order) =>
                order.orderNumber.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
                order.customer.name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
                order.customer.email.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
    }

    if (status !== 'all') {
        inputData = inputData?.filter((order) => order.status === status);
    }

    if (!dateError) {
        if (startDate && endDate) {
            //   inputData = inputData.filter((order) => isBetween(order.createdAt, startDate, endDate));
        }
    }

    return inputData;
}
