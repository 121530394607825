import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    },
    permissaocontent: {
        ['@media(max-width: 480px)']: {
            padding: theme.spacing(0)
        }
    },
    button: {
        border: 'solid 1px #c62828',
        color: '#c62828',
        background: 'transparent',
        '&:hover': {
            color: 'white',
            background: '#c62828'
        }
    },
    fechar: {
        color: 'white'
    },
    bodys: {
        borderBottom: 0
    },
    heads: {
        background: '#fafafa'
    },
    dialogTitle: {
        padding: theme.spacing(1, 3),
        background: '#616161',
        color: 'white'
    },
    row: {
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
        }
    },
    appbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
}));