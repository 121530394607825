import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CancelOutlined, SaveOutlined } from "@material-ui/icons";
import { useState } from "react";
import LinesTextField from "../../../../components/LinesTextField";
import cardsRepositorio from "../../../../repositorios/cartao";
import { useStyles } from "./styles";


export const CartaoDialogEdit = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState(null);
    const [changed, setChanged] = useState(false);

    const [state, setState] = useState({
        apelido: "",
        cvc: "",
        expiry: "",
        focus: "",
        name: "",
        number: "",
        cpf: "",
    });

    const handleCloseDialog = () => {
        props.setOpenDialogEdit(false);
    }

    const handleChange = (event) => {
        if (event.target.name === "name") {
            if (!isNaN(event.target.value) && event.target.value !== "") {
                return;
            }
        }
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        setValue(null);
        setChanged(true);
    };

    const handleClickSave = async () => {
        if (!state.apelido) {
            props.setSnackVariant("error");
            if (changed) {
                props.setSnackMessage("O apelido do cartão não pode ser vazio!");
                props.setSnackState({ visible: true });
                return
            }
            props.setSnackMessage("O apelido do cartão não pode ser o mesmo do anterior.");
            props.setSnackState({ visible: true });
            return
        }

        try {
            let dto = {
                idcartao: props.selectedItem.idcartao,
                apelido: state.apelido
            };

            props.setIsLoading(true);
            await cardsRepositorio.putCard(dto);
            props.setSnackVariant("success");
            props.setSnackMessage("Cartão atualizado com sucesso.");
            props.setSnackState({ visible: true });
            handleCloseDialog();
            props.getListCard();
        } catch (e) {
            props.setSnackVariant("error");
            props.setSnackMessage("Erro ao salvar");
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <Dialog
            open={props.openDialogEdit}
            onClose={handleCloseDialog}
            maxWidth={'xs'}
            fullScreen={responsive}
        >
            <DialogTitle className={classes.dialogTitle}><Typography className={classes.typoTitle}>Editar cartão {props.selectedItem.numero}</Typography></DialogTitle>
            <DialogContent className={responsive ? null : classes.dialogContent}>
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid container item xs={12}>
                            <form style={{ width: '-webkit-fill-available' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h8" style={{ color: "grey" }}>Somente é possível editar o apelido do cartão, caso necessite realizar alteração nos outros dados, favor excluir o cartão e inserir novamente com o dados corretos.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <LinesTextField
                                        name="apelido"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={handleChange}
                                        value={state.apelido || changed ? state.apelido : props.selectedItem.apelido}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%" }}
                                        label="Apelido"
                                    />
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                <Button onClick={() => handleClickSave()} className={classes.buttonSave} startIcon={<SaveOutlined />}>Salvar</Button>
            </DialogActions>
        </Dialog >
    )
}
