import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useCallback, useEffect, useState } from "react";
import { criarNovaDespesaExtra_Mock, excluirExtra_Mock } from "../../../../../mock/prestacaodecontas";
import { convertImageToBase64 } from "../../../../../hooks/convertImageToBase64";
import { UtaliiDatePickerNormal } from "../../../Desktop/sections/custom-date-picker-normal";
import { getTipoDespesaForNumber, tiposdedespesas_Mock } from "../../../../../mock/tiposdespesas";
import DialogComponent from "../../../Desktop/sections/dialogs-desktop";
import { DeleteOutline } from "@mui/icons-material";
import { CreationDate } from "../../../Desktop/sections";

// import { criarNovaDespesaExtra_Mock } from "../../../../../mock/prestacaodecontas";
// import { convertImageToBase64 } from "../../../../../hooks/convertImageToBase64";
// import { UploadAvatar } from "../../../../../upload";
// import { UtaliiDatePickerNormal } from "../../sections/custom-date-picker-normal";
// import { tiposdedespesas_Mock } from "../../../../../mock/tiposdespesas";

export const ExtraAddComponentM = ({ viagem, auditor }) => {
    const [openAddExtra, setOpenAddExtra] = useState(false);
    const [file, setFile] = useState(null);
    const [titulo, setTitulo] = useState("");
    const [tipodedespesa, setTipodedespesa] = useState(null);
    const [tipoGasto, setTipoGasto] = useState(null)
    const [descricao, setDescricao] = useState("");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [totalgasto, setTotalgasto] = useState(0);
    const [typeD, setTypeD] = useState(0);
    const [count, setCount] = useState(0);
    const [isExtra, setIsExtra] = useState(false);
    const [openDeleteExtra, setOpenDeleteExtra] = useState(false);
    const [despesa, setDespesa] = useState([])

    const handleClickExcluirExtra = () => {
        excluirExtra_Mock(viagem.id, despesa.id)
        setOpenDeleteExtra(false)
        // setCurrentTab(1)
        // enqueueSnackbar('Removido com sucesso.', { variant: 'success' });
        // reload()
    }

    useEffect(() => {
        let c = 0;
        viagem?.extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [viagem])

    const handleCreateDespesa = () => {
        criarNovaDespesaExtra_Mock({
            idviagem: viagem.id,
            datadecriacao: datadecompra,
            titulo: titulo,
            tipo: tipodedespesa != null ? tipodedespesa.value : 7,
            comprovante: file,
            total: Number(totalgasto),
            produtos: {
                descricao: tipoGasto != null ? tipoGasto.label : "",
                value: tipoGasto != null ? tipoGasto.value : 0
            },
            descricao: descricao
        })
        setOpenAddExtra(false)
    }

    const handleChangeTipoDeDespesa = (value) => {
        setTipoGasto(null)
        setTipodedespesa(value);
    };

    const handleChangeGasto = (value) => {
        setTipoGasto(value)
    }

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            convertImageToBase64(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(
                    base64String // Aqui você tem a imagem convertida em base64
                );
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    return (
        <>
            <DialogComponent
                title={
                    <>
                        Excluir extra?
                    </>
                }
                open={openDeleteExtra}
                handleClose={() => setOpenDeleteExtra(false)}
                body={
                    <Grid container item xs={12} width={280}>
                        <Typography>Deseja realmente excluir o extra?</Typography>
                    </Grid>
                }
                action={
                    <Grid>
                        <Button onClick={() => handleClickExcluirExtra()}>Confirmar</Button>
                    </Grid>
                }
                width={"sm"}
            />
            <DialogComponent
                title={
                    <>
                        Adicionar Extra
                    </>
                }
                fullScreen={true}
                open={openAddExtra}
                handleClose={() => setOpenAddExtra(false)}
                body={
                    <Grid item xs={12} container>
                        <Grid container item xs={12} style={{ marginTop: "5px", alignContent: "space-between", paddingLeft: "10px", alignContent: "center" }} spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-multiline-flexible"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={(e) => setDescricao(e.target.value)}
                                    multiline
                                    maxRows={4}
                                    label="Justificativa/Descrição"
                                    placeholder="Viagem para..."

                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={tipodedespesa != null ? tipodedespesa.value : null}
                                        defaultValue={0}
                                        label="Categoria"
                                    >
                                        {tiposdedespesas_Mock?.map((e) => (
                                            <MenuItem value={e.value} onClick={() => handleChangeTipoDeDespesa({ label: e.label, value: e.value, sub: e.sub })}>{e.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {tipodedespesa != null && tipodedespesa?.sub?.lenght != 0 && tipodedespesa?.label != "Outros" ? (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Gasto com</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={tipoGasto != null ? tipoGasto.value : null}
                                            defaultValue={0}
                                            label="Categoria"
                                        >
                                            {tipodedespesa?.sub?.map((e) => (
                                                <MenuItem value={e.value} onClick={() => handleChangeGasto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                                            ))}
                                            <MenuItem value={0} onClick={() => handleChangeGasto({ label: "Outros", value: 0 })}>Outros</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : (
                                null
                            )}
                            <Grid item xs={6} style={{ paddingRight: "10px" }}>
                                <TextField
                                    fullWidth
                                    id="outlined-number"
                                    label="Total gasto"
                                    onChange={(e) => setTotalgasto(e.target.value)}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ paddingLeft: "10px" }}>
                                <UtaliiDatePickerNormal
                                    value={datadecompra}
                                    onChange={setDatadecompra}
                                    label="Data de Compra"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                }
                action={
                    <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                        <Button onClick={() => handleCreateDespesa()} startIcon={<PostAddIcon />}>Adicionar Extra</Button>
                    </Grid>
                }
                width={"sm"}
            />
            {auditor && (
                <>
                    <Grid style={{ display: "flex", padding: "10px", justifyContent: "right" }} item xs={12}>
                        <Button onClick={() => setOpenAddExtra(true)} startIcon={<PostAddIcon />}>Adicionar Extra</Button>
                    </Grid>
                </>
            )}
            {viagem?.extras?.map((value) => (
                <>
                    <Grid
                        container
                        item xs={12}
                        style={{
                            marginTop: "5px",
                            padding: "20px",
                            transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                            cursor: 'default', // Cursor padrão quando não está em hover
                            backgroundColor: "#FFFFFF",
                            textAlign: 'center',
                            borderRadius: "10px",
                            boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                        }}
                    >
                        <Grid container item xs={12}>
                            <Grid container item xs={6} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                <Typography style={{ fontWeight: "600" }}>{value.hodometro ? ("Hodometro") : ("Despesa")}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                                {auditor && (
                                    <IconButton onClick={() => { setOpenDeleteExtra(true); setDespesa(value); }}>
                                        <DeleteOutline />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "left", marginTop: "8px" }}>
                            <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignContent: "flex-start",
                            alignItems: "flex-start",
                            flexDirection: "row"
                        }}>
                            {/* {value.tipo == 7 ? (<Typography style={{ fontWeight: "600", fontSize: "small", color: warning.main }}>Pendente</Typography>) : (null)} */}
                            {getTipoDespesaForNumber(value.tipo, value.produtos.value)}
                        </Grid>
                        <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "space-between", justifyContent: "left" }}>
                            <CreationDate data={value.datadecriacao} />
                        </Grid>
                        <Grid item xs={3} style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignContent: "flex-end",
                            alignItems: "flex-end",
                            flexDirection: "row"
                        }}>
                            <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                        </Grid>
                    </Grid>
                </>
            ))}
        </>
    )
}
