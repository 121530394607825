import { Box, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { CloseOutlined } from "@material-ui/icons"
import { useStyles } from "./styles";

export const SearchBookingForStatus = (props) => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();

    const onCloseFilter = () => {
        props.setShowTuneStatus(false)
    }

    return (
        <Dialog
            maxWidth="lg"
            open={props.showTuneStatus}
            fullScreen={fullscreen}
            onClose={() => onCloseFilter()}
        >
            <DialogTitle className={classes.dialogTitle}>
                <div className={classes.divtitle} style={{}}>
                    <Typography
                        style={{
                            color: "white",
                            fontSize: "16pt",
                            fontWeight: "bold",
                        }}
                    >
                        Filtros
                    </Typography>
                    {fullscreen ? (
                        <IconButton
                            onClick={() => onCloseFilter()}
                            style={{ marginLeft: "15px" }}
                        >
                            <CloseOutlined
                                fontSize="large"
                                style={{ color: "white" }}
                            />
                        </IconButton>
                    ) : null}
                </div>
            </DialogTitle>
            <DialogContent>
                <Box margin="10px" marginY="10px">
                    <Typography>Nome usado:</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="typeNome"
                            name="typeNome"
                            value={props.valueTuneNome}
                            onChange={(e) => props.handleChangeTuneNomeValue(e)}
                        >
                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Viajante"
                            />
                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Solicitante"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Divider />
                <Box marginX="10px" marginY="10px">
                    <Typography>Status selecionado:</Typography>
                    <FormControl component="fieldset">
                        <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            value={props.valueTuneStatus}
                            onChange={props.handleChangeTuneStatusValue}
                        >
                            <FormControlLabel
                                value="0"
                                control={<Radio />}
                                label="Todos"
                            />
                            <FormControlLabel
                                value="1"
                                control={<Radio />}
                                label="Aguardando Atendimento"
                            />
                            <FormControlLabel
                                value="4"
                                control={<Radio />}
                                label="Aguardando Solicitante"
                            />
                            <FormControlLabel
                                value="9"
                                control={<Radio />}
                                label="Autorização Pendente"
                            />
                            <FormControlLabel
                                value="14"
                                control={<Radio />}
                                label="Aprovação Pendente"
                            />
                            <FormControlLabel
                                value="12"
                                control={<Radio />}
                                label="Cancelado"
                            />
                            <FormControlLabel
                                value="2"
                                control={<Radio />}
                                label="Em Atendimento"
                            />
                            <FormControlLabel
                                value="11"
                                control={<Radio />}
                                label="Emissão Negada"
                            />
                            <FormControlLabel
                                value="3"
                                control={<Radio />}
                                label="Emissão Pendente"
                            />
                            <FormControlLabel
                                value="7"
                                control={<Radio />}
                                label="Emitido"
                            />
                            <FormControlLabel
                                value="5"
                                control={<Radio />}
                                label="Encerrado "
                            />
                            <FormControlLabel
                                value="10"
                                control={<Radio />}
                                label="Expirado"
                            />
                            <FormControlLabel
                                value="13"
                                control={<Radio />}
                                label="Limite Excedido"
                            />
                            <FormControlLabel
                                value="6"
                                control={<Radio />}
                                label="Rascunho"
                            />
                            <FormControlLabel
                                value="8"
                                control={<Radio />}
                                label="Tarifa Garantida"
                            />
                            <FormControlLabel
                                value="15"
                                control={<Radio />}
                                label="Reembolso Integral"
                            />
                            <FormControlLabel
                                value="16"
                                control={<Radio />}
                                label="Reembolso Parcial"
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
