import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const aereoPassageiro = {

    post: async passageiro => {
        let response = await api.post('/AereoPassageiro', passageiro, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    put: async (passageiro) => {
        let response = await api.put(`/AereoPassageiro/${passageiro.idaereopassageiro}`, passageiro, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    putEditTaxes: async (dto) => {
        let response = await api.put(`/AereoPassageiro/edit-taxes`, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    editValor: async (passageiro) => {
        let response = await api.post(`/AereoPassageiro/editvalor`, passageiro, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

}

export default aereoPassageiro;
