import { Box, Button, ButtonGroup, Container, Grid, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import IconButton from "@material-ui/core/IconButton";
import { GetApp, SearchOutlined } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TuneIcon from "@material-ui/icons/Tune";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Snackbar from "../../../components/snackbar";
import UDatePicker from "../../../components/udatepicker";
import ExportExcel from '../../../components/UExportExcel';
import ULoaderVoo from "../../../components/uloadervoo";
import USearchField from "../../../components/usearchfield";
import EnumCompany from "../../../enums/enumFlightCompany";
import centroCustoRepositorio from "../../../repositorios/centroCusto";
import pessoaRepositorio from "../../../repositorios/pessoa";
import compraRealizadoRepositorio from "../../../repositorios/relatorio/compraRealizada";
import Dashboard from "../../dashboard";
import ComprasRealizadasChart from "./ComprasRealizadasChart";
import ComprasRealizadasDialog from "./comprasRealizadasDialog";
import ComprasRealizadasGrid from "./comprasRealizadasGrid/index.js";
import ComprasRealizadasList from "./comprasRealizadasList/index.js";
import { useStyles } from "./styles";

function isValidDate(date) {
  return moment(date).isValid();
}

function definirAssunto(assunto) {
  if (!assunto) {
    return '';
  }
  let textos = assunto.split('<br />');
  return (
    <div>
      {textos.map(item => (
        <div>{item}</div>
      ))}
    </div>
  );
}

export const GetRelatorioComprasRealizadas = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const permissao = useSelector((state) => state.permissao.permissaoDashboard);
  const [dataInicio, setDataInicio] = useState(new Date());
  const [dataFim, setDataFim] = useState(new Date());
  const [centroCusto, setCentroCusto] = useState(null);
  const [viajante, setViajante] = useState(null);
  const [viajantes, setViajantes] = useState([]);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [dadosRelatorio, setDadosRelatorio] = useState({
    grafico: {
      totalComprado: 0,
      totalConsolidado: 0,
      totalNaoConsolidado: 0,
      totalAereoNacional: 0,
      totalAereoInternacional: 0,
      totalHotel: 0,
      totalCarro: 0,
      totalPacote: 0,
      totalOutros: 0,
    },
    itens: [],
  });
  const [categoriaProduto, setCategoriaProduto] = useState([]);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [isLoading, setIsLoading] = useState(false);
  const [acordOpen, setAcordOpen] = useState(true);
  const [colunasExcel, setColunasExcel] = useState([]);
  const [rowsExcel, setRowsExcel] = useState([]);
  const [editando, setEditando] = useState(false);
  const [dateresponse, setDateresponse] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [solicitante, setSolicitante] = useState({});
  const [periodo, setPeriodo] = useState(4);
  const [existFiltrosEsp, setExistFiltrosEsp] = useState(false);
  var filtros = {
    idusuario: 0,
    produtos: [],
  };

  const handlePesquisa = () => {
    filtros = {
      idusuario: solicitante.value,
      produtos: categoriaProduto,
    };
    handleClickPesquisar();
    setOpen(false);
  };

  const handleClickPesquisar = async () => {
    if (filtros.idusuario === 0 && filtros.produtos.length === 0) {
      setExistFiltrosEsp(false);
    } else {
      setExistFiltrosEsp(true);
    }

    if (dataInicio > dataFim) {
      setSnackVariant("error");
      setSnackMessage("Data de início maior que data final.");
      setSnackVisible({ visible: true });
      return;
    }

    if (!isValidDate(dataInicio) || !isValidDate(dataFim)) {
      setSnackVariant("error");
      setSnackMessage("Data Inválida.");
      setSnackVisible({ visible: true });
      return;
    }

    let dto = {
      dataInicio,
      dataFim,
      idCentroCusto: centroCusto ? centroCusto.value : 0,
      idViajante: viajante ? viajante.value : 0,
    };
    try {
      setIsLoading(true);
      const response = await compraRealizadoRepositorio.getRelatorioComprasRealizadas(dto, filtros);
      setDadosRelatorio(response.data);
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao carregar relatório.");
      setSnackVisible({ visible: true });
    } finally {
      setIsLoading(false);
    }
  };

  const setPeriodoPesquisa = (periodo) => {
    let inicio = new Date();
    let fim = new Date();

    switch (periodo) {
      case 1:
        inicio.setDate(inicio.getDate() - 7);
        handleClickPesquisar();
        break;
      case 2:
        inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
        fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
        handleClickPesquisar();
        break;
      case 3:
        inicio = new Date(inicio.getFullYear(), 0, 1);
        fim = new Date(inicio.getFullYear(), 11, 31);
        handleClickPesquisar();
        break;
    }

    setDataInicio(inicio);
    setDataFim(fim);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChangeData = () => {
    let inicio = new Date();
    if (
      dataInicio !== "" && dataInicio !== undefined && dataInicio !== null &&
      dataFim !== "" && dataFim !== undefined && dataFim !== null
    ) {
      if (
        dataInicio.toLocaleDateString() === new Date(new Date().setDate(new Date().getDate() - 7)).toLocaleDateString() &&
        dataFim.toLocaleDateString() === new Date().toLocaleDateString()
      ) {
        return 1;
      }
      if (
        dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth(), 1).toLocaleDateString() &&
        dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0).toLocaleDateString()
      ) {
        return 2;
      }
      if (dataInicio.toLocaleDateString() === new Date(inicio.getFullYear(), 0, 1).toLocaleDateString() &&
        dataFim.toLocaleDateString() === new Date(inicio.getFullYear(), 11, 31).toLocaleDateString()
      ) {
        return 3;
      }
      return 4;
    }
  };

  useEffect(() => {
    if (periodo) {
      let inicio = new Date();
      let fim = new Date();

      switch (periodo) {
        case 1:
          inicio.setDate(inicio.getDate() - 7);
          break;
        case 2:
          inicio = new Date(inicio.getFullYear(), inicio.getMonth(), 1);
          fim = new Date(inicio.getFullYear(), inicio.getMonth() + 1, 0);
          break;
        case 3:
          inicio = new Date(inicio.getFullYear(), 0, 1);
          fim = new Date(inicio.getFullYear(), 11, 31);
          break;
      }

      setDataInicio(inicio);
      setDataFim(fim);
    }
  }, [periodo]);

  const handleAcord = (panel) => (event, newExpanded) => {
    setAcordOpen(newExpanded ? panel : false);
  };

  useEffect(() => {
    (async () => {
      const responseViajantes = await pessoaRepositorio.getSearchField();
      const responseCentrosCusto = await centroCustoRepositorio.getSearchField();
      setViajantes(responseViajantes);
      setCentrosCusto(responseCentrosCusto);
    })();
  }, []);

  useEffect(() => {
    setColunasExcel([
      "ID Reserva",
      "Viajante",
      // "CIA",
      "Fornecedor",
      "Trecho",
      "Data Ida",
      "Data Volta",
      "Localizador",
      "Tipo",
      "Valor",
      "Solicitante",
      "Centro de Custo"
    ]);

    const datas = dadosRelatorio?.itens?.map((item) => {
      let valueNome = EnumCompany.getLogoCompanhiaAereaEnum(item.cia);

      const trecho = item.trecho || "";
      const dataInicio = item.datainicio || "";
      const dataFim = item.datafim || "";
      const fornecedor = item.fornecedor || null;

      const cleanTrecho = trecho.replace(/<br\s*\/?>/g, '').trim();
      const containsArrow = cleanTrecho.includes("→");

      return [
        item.idreserva,
        item.viajante,
        // valueNome.nome,
        item.fornecedor == null ? valueNome.nome : item.fornecedor,
        item.trecho.replace(/<br\s*\/?>|(\s+)/g, ''),
        item.datainicio,
        (!fornecedor && containsArrow && dataInicio === dataFim) ? "-" : item.datafim,
        item.loc,
        item.tipo,
        item.valor,
        item.solicitante,
        item.centrodecusto,
      ];
    });
    setRowsExcel(datas);
  }, [dadosRelatorio]);

  return (
    <section>
      <ULoaderVoo isLoading={isLoading} />
      <Snackbar
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      {open ? (
        <ComprasRealizadasDialog
          {...{
            setSnackMessage,
            setSnackVariant,
            setSnackVisible,
            setIsLoading,
            filtros,
            handleClickPesquisar,
            open,
            setOpen,
            solicitante,
            setSolicitante,
            categoriaProduto,
            setCategoriaProduto,
            handlePesquisa,
          }}
        />
      ) : (null)}
      <Container maxWidth="xl">
        <Box mt={2} mb={responsive ? 10 : 3}>
          <Box mb={1}>
            <Grid container>
              <Grid item xs={responsive ? 12 : 2}>
                <Typography variant="h6" component="h1">
                  Compras Realizadas
                </Typography>
              </Grid>
              {responsive ? null : (
                <>
                  <Grid item xs={8}>
                    <Grid item xs={12} className={classes.groupButton}>
                      <ButtonGroup>
                        <Button
                          style={{
                            background:
                              periodo === 1
                                ? "#c62828"
                                : "transparent",
                            color:
                              periodo === 1 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodo(1)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Semanal
                          </Typography>
                        </Button>
                        <Button
                          style={{
                            background:
                              periodo === 2
                                ? "#c62828"
                                : "transparent",
                            color:
                              periodo === 2 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodo(2)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Mensal
                          </Typography>
                        </Button>
                        <Button
                          style={{
                            background:
                              periodo === 3
                                ? "#c62828"
                                : "transparent",
                            color:
                              periodo === 3 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodo(3)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Anual
                          </Typography>
                        </Button>
                        <Button
                          style={{
                            background:
                              periodo === 4
                                ? "#c62828"
                                : "transparent",
                            color:
                              periodo === 4 ? "white" : "#c62828",
                            border: "1px solid #c62828",
                            "&:hover": {
                              background: "#c62828",
                              color: "white",
                            },
                          }}
                          onClick={() => setPeriodo(4)}
                        >
                          <Typography style={{ fontSize: 11 }}>
                            Personalizado
                          </Typography>
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </>
              )}
            </Grid>
          </Box>
          <Paper elevation={3}>
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                  <UDatePicker
                    date={dataInicio}
                    setDate={setDataInicio}
                    label="Data início"
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <UDatePicker
                    date={dataFim}
                    setDate={setDataFim}
                    label="Data Fim"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  {permissao.relatorioCentroCusto ? (
                    <USearchField
                      itens={centrosCusto}
                      value={centroCusto}
                      setValue={setCentroCusto}
                      label="Centro de custo"
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12} md={3}>
                  {permissao.relatorioPessoal ? (
                    <USearchField
                      itens={viajantes}
                      value={viajante}
                      setValue={setViajante}
                      label="Viajante"
                    />
                  ) : null}
                </Grid>
                <Grid item xs={6} md={1} style={{ display: 'flex', justifyContent: "center" }}>
                  <IconButton
                    variant="outlined"
                    className={classes.search}
                    onClick={handleClickOpen}
                    fullWidth
                  >
                    <TuneIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={6} md={1} style={{ alignSelf: "center" }}>
                  <Button
                    onClick={handleClickPesquisar}
                    className={classes.button}
                    fullWidth
                  >
                    <SearchOutlined />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Box mt={2}>
            {responsive ? (
              <>
                <Grid
                  container
                  style={{ display: "flex", flexDirection: "column" }}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Box
                      border={1}
                      borderColor={"#41A5D1"}
                      borderRadius={4}
                      padding={1}
                      sx={{ height: "100%", flexDirection: "column" }}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        className={classes.typography_B}
                        variant="body1"
                      >
                        Comprado:
                      </Typography>
                      <Typography
                        className={classes.typography_B}
                        variant="body1"
                      >
                        R$
                        {Number(
                          dadosRelatorio.grafico.totalComprado
                        ).toFormatDefault()}
                      </Typography>
                    </Box>
                  </Grid>
                  {!existFiltrosEsp ? (
                    <>
                      <Grid item xs={12}>
                        <Box
                          border={1}
                          borderColor={"#43B947"}
                          borderRadius={4}
                          padding={1}
                          sx={{ height: "100%", flexDirection: "column" }}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography
                            className={classes.typography_G}
                            variant="body1"
                          >
                            Consolidado:{" "}
                          </Typography>
                          <Typography
                            className={classes.typography_G}
                            variant="body1"
                          >
                            R$
                            {Number(
                              dadosRelatorio.grafico.totalConsolidado
                            ).toFormatDefault()}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          border={1}
                          borderColor={"#E50008"}
                          borderRadius={4}
                          padding={1}
                          sx={{ height: "100%", flexDirection: "column" }}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography
                            className={classes.typography_R}
                            variant="body1"
                          >
                            Não Consolidado:{" "}
                          </Typography>
                          <Typography
                            className={classes.typography_R}
                            variant="body1"
                          >
                            R$
                            {Number(
                              dadosRelatorio.grafico.totalNaoConsolidado
                            ).toFormatDefault()}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Gráfico
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography style={{ width: "100%" }}>
                          <Box mt={2}>
                            <Paper elevation={3}>
                              <ComprasRealizadasChart
                                data={dadosRelatorio.grafico}
                              />
                            </Paper>
                          </Box>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                <ComprasRealizadasList data={dadosRelatorio} />
              </>
            ) : (
              <>
                <Paper elevation={3}>
                  <Grid container spacing={0} style={{ margin: "0px" }}>
                    <Grid item xs={existFiltrosEsp ? 12 : 4} style={{ padding: "5px" }}>
                      <Box
                        border={1}
                        borderColor={"#41A5D1"}
                        borderRadius={4}
                        padding={1}
                        sx={{ height: "100%", flexDirection: "column" }}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          className={classes.typography_B}
                          variant="body1"
                        >
                          Comprado:
                        </Typography>
                        <Typography
                          className={classes.typography_B}
                          variant="body1"
                        >
                          R$
                          {Number(
                            dadosRelatorio.grafico.totalComprado
                          ).toFormatDefault()}
                        </Typography>
                      </Box>
                    </Grid>
                    {!existFiltrosEsp ? (
                      <>
                        <Grid item xs={4} style={{ padding: "5px" }}>
                          <Box
                            border={1}
                            borderColor={"#43B947"}
                            borderRadius={4}
                            padding={1}
                            sx={{ height: "100%", flexDirection: "column" }}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Typography
                              className={classes.typography_G}
                              variant="body1"
                            >
                              Consolidado:{" "}
                            </Typography>
                            <Typography
                              className={classes.typography_G}
                              variant="body1"
                            >
                              R$
                              {Number(
                                dadosRelatorio.grafico.totalConsolidado
                              ).toFormatDefault()}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} style={{ padding: "5px" }}>
                          <Box
                            border={1}
                            borderColor={"#E50008"}
                            borderRadius={4}
                            padding={1}
                            sx={{ height: "100%", flexDirection: "column" }}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <Typography
                              className={classes.typography_R}
                              variant="body1"
                            >
                              Não Consolidado:{" "}
                            </Typography>
                            <Typography
                              className={classes.typography_R}
                              variant="body1"
                            >
                              R$ {Number(dadosRelatorio.grafico.totalNaoConsolidado).toFormatDefault()}
                            </Typography>
                          </Box>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Paper>
                <Box mt={2}>
                  <Paper elevation={3}>
                    <Accordion
                      square
                      expanded={acordOpen}
                      onChange={handleAcord("panel1")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Gráfico
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography style={{ width: "100%" }}>
                          <Box mt={2}>
                            <Paper elevation={3}>
                              <ComprasRealizadasChart
                                data={dadosRelatorio.grafico}
                              />
                            </Paper>
                          </Box>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Paper>
                </Box>
                <Box marginY={2}>
                  <Paper elevation={2}>
                    <Grid container spacing={1} style={{ margin: '0px' }}>
                      <Grid item xs={10} />
                      <Grid item xs={2} >
                        <ExportExcel name="Relatório de Compras Realizadas" columns={colunasExcel} data={rowsExcel} element={
                          <>
                            <Button className={classes.button}>
                              <GetApp />
                              Exportar

                            </Button>
                          </>
                        } />
                      </Grid>
                      <Grid item xs={12} classes={classes.gridRowFlexEnd}>
                        <ComprasRealizadasGrid
                          Loading={isLoading}
                          data={dadosRelatorio}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Container>
    </section >
  );
};

export const Lista = (props) => {
  return (
    <div>
      <Dashboard {...props} component={GetRelatorioComprasRealizadas} />
    </div>
  );
};
export default Lista;
