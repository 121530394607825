import { XGrid } from '@material-ui/x-grid';
import React from 'react';
import CustomNoRowsOverlay from '../../../configuracao/email/lista/emptyList';

function EconomiaNaEmissaoGrid(props) {

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    const columns = [
        { field: 'id', hide: true },
        { field: 'idreserva', headerName: 'ID', width: 80 },
        { field: 'passageiro', headerName: 'Passageiro', width: 220 },
        { field: 'trecho', headerName: 'Trecho', width: 130 },
        { field: 'datacriacao', headerName: 'Dt. Criação', width: 130, type: 'date' },
        { field: 'loc', headerName: 'Loc.', width: 130 },
        { field: 'duracao', headerName: 'Duração (Dias)', width: 130 },
        { field: 'consolidado', headerName: 'Consolidado', width: 130 },
        { field: 'comparativo', headerName: 'Comparativo', width: 130 },
        { field: 'economia', headerName: 'Economia', width: 130 },
    ];

    return (
        <>
            <div style={{ height: '52vh', width: '100%' }}>
                <XGrid
                    disableColumnFilter={true}
                    disableColumnMenu={true}
                    id={Math.random()}
                    rows={props.data}
                    columns={columns}
                    loading={props.isLoading}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay
                    }}
                    hideFooterSelectedRowCount
                    disableSelectionOnClick
                    hideFooterRowCount
                    pagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                />
            </div>
        </>
    );
};

export default EconomiaNaEmissaoGrid;
