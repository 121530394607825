import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import Snackbar from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import viagemRepositorio from '../../../../repositorios/viagem';
import { useStyles } from './styles';

const DialogConfirmPagamentoAgendado = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');
    const [backDrop, setBackDrop] = useState(false);

    const handleClickDialogConfirmPagamentoAgendadoClose = () => {
        props.getData();
        props.setOpenDialogPagamento(false);
        setBackDrop(false);
    }

    const handleClickConfirm = async () => {
        let data = props.dataConfirm;
        let j = 0;
        setBackDrop(true);
        for (var i = 0; i < data.length; i++) {
            if (data[i].status === 6) {
                try {
                    await viagemRepositorio.avancarStatusViagem(data[i].idviagem);
                } catch (e) {
                    j++;
                }
            }
        }

        if (j > 0) {
            setSnackVariant('error');
            setSnackMessage('Falha ao tentar avançar algum dos itens.');
            setSnackState({
                visible: true
            });
        } else {
            setSnackVariant('success');
            setSnackMessage('Êxito ao avançar todos itens.');
            setSnackState({
                visible: true
            });
        }
        handleClickDialogConfirmPagamentoAgendadoClose();
    }

    return (
        <>
            <ULoaderVoo isLoading={backDrop} />
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
            <Dialog
                open={props.open}
                maxWidth="xs"
                onClose={() => handleClickDialogConfirmPagamentoAgendadoClose()}
                fullWidth
                fullScreen={responsive}
            >
                <AppBar position="relative" style={{ background: 'none' }}>
                    <Toolbar className={classes.appbar} >
                        <Typography>Confirmação</Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <DialogContentText >
                        <Typography>
                            Confirme se realmente deseja avançar todos itens listados no resultado encontrado,
                            que estão com o seu status em <b>"Pagamento agendado"</b>.
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {props.status !== 1 && props.status !== 5 ? (
                        <>
                            <Button
                                className={classes.buttonConfirm}
                                onClick={() => handleClickDialogConfirmPagamentoAgendadoClose()}
                            >
                                CANCELAR
                            </Button>
                            <Button
                                className={classes.buttonConfirm}
                                onClick={() => handleClickConfirm()}
                            >
                                CONFIRMAR
                            </Button>
                        </>
                    ) : null}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DialogConfirmPagamentoAgendado;
