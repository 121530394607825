import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    main: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(7)
        }
    },
    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119'
        }
    },
    container: {
        width: '100%',
        height: 500,
        display: 'flex',
        flexDirection: 'column'
    },
    toolbar: {

    },
    gridAlign: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    gridAlignEnd: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    }
}));
