import { IconButton, List, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
import React from "react";
import { useStyles } from "../styles";

function definirAssunto(assunto) {
  if (!assunto) {
    return "";
  }
  let textos = assunto.split("<br />");
  return (
    <div>
      {textos.map((item) => (
        <div>{item}</div>
      ))}
    </div>
  );
}

function PessoasPagination(props) {
  const classes = useStyles();

  return (
    <>
      <List>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.heads} align="center"></TableCell>
              <TableCell className={classes.heads} align="center">
                Nome
              </TableCell>
              {!props.fullscreen ? (
                <TableCell className={classes.heads} align="center">
                  CPF
                </TableCell>
              ) : null}
              <TableCell className={classes.heads} align="center">
                Ativo
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.todasPessoas.map((item) => (
              <TableRow className={classes.row} key={item.idusuario}>
                <TableCell className={classes.bodys} align="center">
                  <IconButton
                    onClick={() => props.handleCadastroOpen(item, true)}
                    margin="small"
                  >
                    <EditRounded fontSize="default" />
                  </IconButton>
                </TableCell>
                <TableCell className={classes.bodys} align="center">
                  <Typography>{item.nome + " " + item.sobrenome}</Typography>
                  {props.fullscreen ? (
                    <Typography>{item.cpf}</Typography>
                  ) : null}
                </TableCell>
                {!props.fullscreen ? (
                  <TableCell className={classes.bodys} align="center">
                    {item.cpf}
                  </TableCell>
                ) : null}
                <TableCell className={classes.bodys} align="center">
                  {item.ativo ? "Sim" : "Não"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </List>
    </>
  );
}

export default PessoasPagination;
