import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    button: {
        background: '#c62828',
        color: '#ffffff',
        '&:hover': { background: '#b91119', }
    },

    mainGrid: {
        marginTop: 3
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },

    passageiros: {
        width: 90,
        marginRight: '3%',
        marginLeft: '3%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
            paddingTop: theme.spacing(2)

        },
        ['@media (max-width:736px) and (orientation: landscape)']: {

        },
        ['@media (max-width:900px) and (orientation: portrait)']: {
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
            paddingTop: theme.spacing(2)
        },
        ['@media (max-width:1280px) and (orientation: landscape)']: {
            paddingBottom: '2%'
        },
    },
    pacotinho: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '3%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,

        },
        width: "max-content"
    },

    chartGrid: {
        // height: '20rem',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'

    },

    gridCard: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'center'
    },

    gridCardMain: {
        padding: "20px"
    },

    gridCardValues: {
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
        margin: "10px",
        padding: "15px",
        borderRadius: "3px",
    },

    paperCardGrid: {
        border: '2px solid black',
        borderRadius: '20px',
        padding: '7px',
        width: '25rem'
    },

    groupButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    buttonSelected: {
        background: '#C62828',
        color: '#ffffff',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        }
    },

    buttonUnselected: {
        background: '#ffffff',
        color: '#C62828',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        }
    },

    buttonTypo: {
        fontSize: 11,
    },

    typography_G: {
        color: '#70AD47',
        fontWeight: 'bold',
    },
    typography_O: {
        color: '#FF3300',
        fontWeight: 'bold',
    },
    typography_P: {
        color: '#FFC000',
        fontWeight: 'bold',
    },

    dot_G: {
        height: '7px',
        width: '7px',
        backgroundColor: '#70AD47',
        borderRadius: '50%',
        display: 'inline-block'
    },
    dot_O: {
        height: '7px',
        width: '7px',
        backgroundColor: '#FF3300',
        borderRadius: '50%',
        display: 'inline-block'
    },
    dot_P: {
        height: '7px',
        width: '7px',
        backgroundColor: '#FFC000',
        borderRadius: '50%',
        display: 'inline-block'
    },
    leafletContainer: {
        height: "600px",
        width: "100%"
    },
    fontCancelation: {
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "20px",
        display: "inline-flex",
        alignItems: "center",
        marginBottom: "10px",
        marginRight: "30px"
    },
    fontPoliticsDescription: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        display: "inline-flex",
        alignItems: "center",
        marginBottom: "10px"
    }
}));
