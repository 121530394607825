import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';

export const DialogShowImage = props => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCloseModal = () => {

        props.setOpen(false);
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseModal}
            maxWidth="sm"
            fullWidth
            scroll={'paper'}
            fullScreen={responsive}
        >
            <AppBar position="relative" style={{ background: 'transparent', boxShadow: 'none' }}>
                <Toolbar>
                    <Box width="100%" justifyContent="flex-end" alignItems="center">
                        <IconButton onClick={handleCloseModal}>
                            <Close />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                <img width='100%' height='auto' src={props.src} alt="Imagem Recibo" object-fit='fill' />
            </DialogContent>
        </Dialog>
    )
};

export default DialogShowImage;
