import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { styled, useTheme } from '@mui/material/styles';
import chart, { useChart } from '../../../../components/chart';

// import { fNumber } from 'src/utils/format-number';

// import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

const StyledChart = styled(chart)(({ theme }) => ({
    height: CHART_HEIGHT,
    '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
        height: `100% !important`,
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        borderTop: `dashed 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

export default function AnalyticsCurrentVisits({ title, subheader, chart, colors, ...other }) {
    const theme = useTheme();

    const { series, options } = chart;

    const chartSeries = series.map((i) => i.value);

    const chartOptions = useChart({
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        colors,
        labels: series.map((i) => i.label),
        stroke: {
            //   colors: [theme.palette.background.paper],
        },
        legend: {
            floating: true,
            position: 'bottom',
            horizontalAlign: 'center',
        },
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: false,
            },
        },
        tooltip: {
            fillSeriesColor: false,
            theme: 'dark', // Define o tema do tooltip como dark
            style: {
                fontSize: '12px',
                color: 'white', // Cor do texto do tooltip
            },
            y: {
                formatter: (value) => value,
                title: {
                    color: "white",
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        color: "white",
                        show: false,
                    },
                },
            },
        },
        ...options,
    });

    return (
        <Card {...other} style={{ width: "-webkit-fill-available", boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.75)" }}>
            <CardHeader title={title} subheader={subheader} sx={{ mb: 5 }} />

            <StyledChart
                dir="ltr"
                type="pie"
                series={chartSeries}
                options={chartOptions}
                width="100%"
                height={280}
            />
        </Card>
    );
}

AnalyticsCurrentVisits.propTypes = {
    chart: PropTypes.object,
    subheader: PropTypes.string,
    title: PropTypes.string,
};
