import api from '../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const paisRepositorio = {

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/pais', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        response.data.map(item => {
            lista.push({
                label: item.nome,
                value: item.idpais
            })
        })
        return lista;
    }
}

export default paisRepositorio;
