import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },

    buttonGroup: {
        color: "#c62828",
        borderColor: "#c62828",
    },

    buttonGroupSelected: {
        color: "#ffffff",
        background: "#c62828",
        borderColor: "#c62828",
    },

    iconButton: {
        color: '#ffffff'
    },

    gridGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }

}));
