import { Box, Grid, List, ListItem, Typography } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { CalendarTodayOutlined, FlightOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import EnumCompany from "../../../../enums/enumFlightCompany";

function definirAssunto(assunto) {
  if (!assunto) {
    return "";
  }
  let textos = assunto.split("<br />");
  return (
    <div>
      {textos.map((item) => (
        <div>{item}</div>
      ))}
    </div>
  );
}

function ComprasRealizadasList(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [itensPerPage, setItensPergPage] = useState(10);
  const pages = Math.ceil(props.data.itens.length)
  const start = page * itensPerPage;
  const r = itensPerPage + start;
  const todoRelatorio = props.data.itens.slice(start, r);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setItensPergPage(event.target.value);
  };

  return (
    <>
      <Box>
        <TablePagination
          component="div"
          style={{ minWidth: 'max-content' }}
          labelRowsPerPage={''}
          count={pages}
          page={page}
          variant={"outlined"}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 50]}
          size={"small"}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <List>
        {todoRelatorio?.map((item) => (
          <ListItem divider>
            <div>
              <Grid container spacing={1} style={{ border: "1px solid #c62828", padding: "10px", borderRadius: "20px", }}>
                <Grid item xs={8}>
                  <Typography variant="h6">
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                      ID: {item.idreserva}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {item.cia.trim() !== "-" ? (
                    <img width={80} height={40} src={EnumCompany.getLogoCompanhiaAereaEnum(item.cia).logo} />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {item.cia.trim() !== "-" ? (<Typography>Loc: {item.loc}</Typography>) : (<Typography>Fornecedor: {item.fornecedor}</Typography>)}
                </Grid>
                <Grid item xs={12}>
                  <Typography>Viajante: {item.viajante}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Centro de custo: {item.centrodecusto}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Valor: {item.valor}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <Box display={"flex"} width={"-webkit-fill-available"}>
                      <Box display={"flex"} alignItems={"center"}>
                        Trecho:&nbsp; {definirAssunto(item.trecho)}{" "}
                        <FlightOutlined fontSize="small" />
                      </Box>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography>Data Ida:</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>Data Volta:</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <Grid item xs={12}>
                        <Typography>
                          <Box display={"flex"} width={"-webkit-fill-available"} justifyContent={"space-around"}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} m>
                              {item.datainicio}{" "}
                              <CalendarTodayOutlined fontSize="small" />
                            </Box>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <Box display={"flex"} width={"-webkit-fill-available"} justifyContent={"space-around"}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} >
                              {item.datafim}{" "}
                              <CalendarTodayOutlined fontSize="small" />
                            </Box>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Tipo: {item.tipo}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Solicitante: {item.solicitante}</Typography>
                </Grid>
              </Grid>
            </div>
          </ListItem>
        ))}
      </List >
    </>
  );
}

export default ComprasRealizadasList;
