import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    button: {
        background: '#FFFFFF',
        color: '#ffffff',
        '&:hover': { background: '#FFFFFF', }
    },

    mainGrid: {
        marginTop: 3
    },
    appBar: {
        position: 'relative',
        background: '#FFFFFF',
        boxShadow: "0px 0px 0px",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));