import { makeStyles } from '@material-ui/core/styles';
import { indigo, green } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme => ({
    root: {
        padding: theme.spacing(3),
        ['@media (max-width:480px)']: {
            padding: theme.spacing(0)
        }
    },
    paper: {
        ['@media (max-width:480px)']: {
            marginBottom: theme.spacing(5)
        }
    },
    cadastro: {
        paddingTop: '0px'
    },
    row: {
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
        }

    },
    linha: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },

    linhat: {
        marginLeft: theme.spacing(21),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left'
    },

    appbar: {
        background: '#464646'
    },

    appba: {
        background: '#FF69B4',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
    },

    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        height: 400,
    },

    body2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 400,
        justifyContent: 'center',

    },
    body3: {
        display: 'grid',
        gridTemplateColumns: '25% 25% 25% 25%',
        height: 400,
        padding: '15px'
    },
    divs: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textfield: {
        paddingLeft: '2%'
    },

    textField2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        padding: '5px',
        width: '100%',
    },

    txt: {
        width: '90%',
    },

    card: {
        display: 'flex',
        flexDirection: 'Row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
        width: '100%'
    },

    bigAvatar: {
        margin: 10,
        width: 150,
        height: 150,
    },
    bigAvatar2: {
        margin: 10,
        width: 100,
        height: 100,
    },

    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
    },

    button2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'right',
        alignItems: 'right',
        marginLeft: 250,
        marginTop: 100,
    },

    coluna: {
        marginTop: theme.spacing(20),
        marginLeft: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left'
    },
    coluna2: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left'
    },

    appbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
    },
    obs: {
        width: 665
    },
    textfielde: {
        width: '100%'
    }



})));
