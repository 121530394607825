import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {

  },
  button: {
    background: '#c62828',
    color: '#fff',
    '&:hover': {
      background: '#b91119',
    }
  },

  listCard: {
    display: 'flex'
  },

  typography_G: {
    color: '#43B947',
    fontWeight: 'bold',
  },
  typography_R: {
    color: '#E50008',
    fontWeight: 'bold',
  },
  typography_B: {
    color: '#41A5D1',
    fontWeight: 'bold',
  },

  boxCustom: {
    border: '1px solid #41A5D1'
  },
  gridRowCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  groupButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

}));
