import { red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    appbar: {
        background: '#fafafa',
    },
    buttoncustom: {
        width: '200px',
        borderSpacing: '0px',
        margin: '10px',
        borderRadius: 30,
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900]
        },
        whiteSpace: 'nowrap'
    },
    gridIMG: {
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center'
    },
    typoH5: {
        color: '#191919'
    },
    typoBUTTON: {
        lineHeight: 1,
        margin: '8px'
    }
}));
