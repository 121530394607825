import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const ecogestaoRepositorio = {

    postAll: async (dto) => {
        const response = await api.post('/ecogestao/postAll', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    postAllInfoDialog: async (data) => {
        const response = await api.post('/ecogestao/postListMotoristasEGrupos', data, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    postSavePaxInGroup: async (dto) => {
        const response = await api.post('/ecogestao/postSavePaxInGroup', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    removePaxFromGroup: async (dto) => {
        const response = await api.put('/ecogestao/removePaxFromGroup', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    getVeiculos: async () => {
        let response = await api.get('/EcoGestao/getVeiculos', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    getSearchFieldMotoristas: async () => {
        let response = await api.get('/EcoGestao/getMotoristas', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response.data;
    },

    putSaveVeiculo: async (dto) => {
        let response = await api.put('/EcoGestao/putSaveVeiculo', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        })
        return response;
    },

    getRelatorio: async (dto, filtro) => {
        const response = await api.post('/ecogestao/GetRelatorio', { dtoDadosRelatorio: dto, filtros: filtro }, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            },
        });
        return response;
    },


}

export default ecogestaoRepositorio;
