import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const getDadosSessao = async () => {
    const response = await api.get('/UsuarioTenant/GetAllPorTenant', {
        headers: {
            Authorization: 'Bearer ' + cookies.get('token'),
            sessionId: cookies.get('sessionId')
        }
    });
    return response;
}

const UsuarioTenantRepositorio = {

    getDadosSessao: async () => {
        const response = await api.get('/UsuarioTenant/GetAllPorTenant', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });
        return response;
    },

    alterarOrganizacao: async (dtoUsuarioTenant) => {
        const response = await api.post('/UsuarioTenant/AlterarEmpresa', dtoUsuarioTenant, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    addOrganizacao: async (dtoUsuarioTenant) => {
        const response = await api.post('/UsuarioTenant/AddOrganizacao', dtoUsuarioTenant, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getAllByUsuario: async idusuario => {
        const response = await api.get('/UsuarioTenant/GetAllByUsuario', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                idusuario
            }
        });
        return response;
    },

    getSearchFieldDashboard: async () => {
        let lista = [];
        const response = await getDadosSessao();
        response.data.map(item => {
            lista.push({
                label: item.tenant.empresa.dominio,
                value: item.idtenant
            })
        })
        return lista;
    },

    deleteOrganizacao: async idusuariotenant => {
        const response = await api.delete('/UsuarioTenant/DeleteOrganizacao', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                idusuariotenant
            }
        });
        return response;
    },

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/UsuarioTenant/GetAll', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        response.data.map(item => {
            lista.push({
                label: item.empresa.dominio,
                value: item.idtenant
            })
        })
        return lista;
    }
}

export default UsuarioTenantRepositorio;
