import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Typography, Box, Button } from '@mui/material';
import { CreationDate } from '../../../prestacaodecontas/Desktop/sections';

export const OrderTimeline = ({ events, fullScreen = false }) => {
    const getColor = (type) => {
        switch (type) {
            case 'completed':
                return "green";
            case 'processing':
                return "green";
            case 'new':
                return "green";
            case 'cancelled':
                return "green";
            default:
                return "green";
        }
    };

    return (
        <Box sx={{ p: 2, border: '1px solid #e0e0e0', borderRadius: '8px' }}>
            <Typography variant="h6" gutterBottom>
                Linha do tempo
            </Typography>
            <Timeline>
                {events.map((event, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent>
                            {fullScreen ? (
                                <>
                                    <Typography style={{ fontSize: "10px" }} variant="body2" color="textSecondary">
                                        <CreationDate data={event.data} />
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body2" color="textSecondary">
                                        <CreationDate data={event.data} />
                                    </Typography>
                                </>
                            )}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot style={{ backgroundColor: getColor(event.type) }} />
                            {index < events.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                            {fullScreen ? (
                                <>
                                    <Typography variant="body1" style={{ fontSize: "12px" }}>{event.tipo}</Typography>
                                    <Typography variant="body2" color="textSecondary" style={{ fontSize: "12px" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(event.total)}</Typography>
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1">{event.tipo}</Typography>
                                    <Typography variant="body2" color="textSecondary">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(event.total)}</Typography>
                                </>
                            )}
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Box>
    );
};