import { green, red } from '@material-ui/core/colors';
import { makeStyles, } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    buttonImportar: {
        background: green[700],
        color: 'white',
        '&:hover': {
            background: green[900],
        }
    },
    buttonCancelar: {
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900],
        }
    },
    dialogTittle: {
        background: '#616161',
        color: 'white',
        display: 'flex',
        alignItems: 'center',

    },
    gridItem: {
        display: 'flex',
        alignItems: 'center'
    }
}
));
