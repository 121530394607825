import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const aereoVoo = {

    post: async voo => {
        const response = await api.post('/AereoVoo', voo, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },
}

export default aereoVoo;
