import { useCallback, useEffect, useState } from "react";
import { convertImageToBase64 } from "../../../../../../hooks/convertImageToBase64";
import { AppBar, Badge, Button, Checkbox, Container, Dialog, DialogContent, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Skeleton, TextField, Toolbar, Typography } from "@mui/material";
import { UploadAvatar } from "../../../../../../upload";
import { UtaliiDatePickerNormal } from "../../../../Desktop/sections/custom-date-picker-normal";
import { fData } from "../../../../../../upload/utils/utils";
import { tiposdedespesas_Mock } from "../../../../../../mock/tiposdespesas";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { CloseOutlined, FeedOutlined, QrCodeScannerOutlined } from "@mui/icons-material";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { ExtrasDespesasComponentM } from "./Extras";
import { criarNovaDespesaExtra_Mock } from "../../../../../../mock/prestacaodecontas";
import prestacaoDeContasRepositorio from "../../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../../components/snackbar2/NewSnackBar";
import { QrCodeLearnComponent } from "./QrCode";
import CameraPermission from "./CheckForCam";
import { EnumTipo, formatarMoeda, getCurrencySymbol, getEnumForID } from "../../../../../../enums/enumPrestacaoDeContas";
import Flag from "react-world-flags";

export const AdicionarDespesaComponentM = ({ viagem, handleCreate, setType }) => {
    const [file, setFile] = useState(null);
    const [tipodedespesa, setTipodedespesa] = useState(null);
    const [tipoGasto, setTipoGasto] = useState(null)
    const [descricao, setDescricao] = useState("Despesa para operações diárias.");
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [totalgasto, setTotalgasto] = useState(0);
    const [typeD, setTypeD] = useState(0);
    const [count, setCount] = useState(0)
    const [isExtra, setIsExtra] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(false)
    const [openCQrCode, setOpenCQrCode] = useState(false);
    const [accessCam, setAccessCam] = useState(false);
    const [moeda, setMoeda] = useState(viagem.adiantamento[0].tipo_moeda); // Moeda padrão (Euro)

    const { enqueueSnackbar } = useCustomSnackbar();

    // Função para lidar com a mudança de moeda e reformatar o valor
    const handleMoedaChange = (event) => {
        const novaMoeda = event.target.value;
        setMoeda(novaMoeda);

        if (totalgasto !== '') {
            setTotalgasto(totalgasto); // Mantém o valor atual, mas ele será formatado na apresentação
        }

    };


    useEffect(() => {
        let c = 0;
        viagem?.extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [viagem])

    const getAlltipos = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas()
            setCategorias(response)

            response?.forEach(categoria => {
                if (categoria.name == "Alimentação") {
                    setTipodedespesa({ label: categoria.name, value: categoria.iddespesa_tipo, sub: categoria.produtos });
                    categoria.produtos.forEach(sub => {
                        if (sub.name == "") {

                        }
                        setTipoGasto(null)
                    })
                }
            })

        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleCreateDespesa = () => {
        if (file == null || file == "") {
            enqueueSnackbar('Adicione a foto do comprovante.', { variant: 'warning' });
        }

        let totalString = totalgasto.replace(/\./g, '').replace(',', '.');
        // Converte a string para um número float
        let newtotal = parseFloat(totalString);

        const despesadata = {
            idviagem: viagem.id,
            datadecompra: datadecompra,
            descricao: descricao,
            hodometro: null,
            aprovado: false,
            controladoria: false,
            is_extra: isExtra,
            total: newtotal,
            mensagem: "",
            comprovante: file,
            tipo_moeda: moeda
        }

        if (tipodedespesa != null && tipoGasto != null) {
            despesadata.produtos = {
                iddespesa_tipo: tipodedespesa.value,
                iddespesa_subtipo: tipoGasto.value
            }
        }

        handleCreate(despesadata)
        setType(0)
    }

    const handleValorChange = (event) => {
        const valorInserido = event.target.value;

        // Formata o valor
        const valorFormatado = formatarMoeda(valorInserido);
        // Atualiza o estado com o valor formatado
        setTotalgasto(valorFormatado);
    };

    const handleChangeTipoDeDespesa = (value) => {
        setTipoGasto(null)
        setTipodedespesa(value);
    };

    const handleChangeGasto = (value) => {
        setTipoGasto(value)
    }

    const handleDropAvatar = useCallback((acceptedFiles) => {
        const newFile = acceptedFiles[0];
        if (newFile) {
            convertImageToBase64(Object.assign(newFile, {
                preview: URL.createObjectURL(newFile),
            })).then(base64String => {
                setFile(
                    base64String // Aqui você tem a imagem convertida em base64
                );
            }).catch(error => {
                console.error("Erro ao converter imagem para Base64:", error);
            });
        }
    }, []);

    useEffect(() => {
        getAlltipos()
    }, [])

    return (
        <Container maxWidth={"sm"} container item xs={12} style={{ width: "auto", padding: "0px" }}>
            {/* <Grid container item xs={12} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}> */}
            <Grid item xs={12} style={{ marginTop: "-20px", paddingLeft: "10px" }}>
                {typeD == 0 ? (
                    <>
                        <Button
                            size="large"
                            onClick={() => setType(0)}
                            startIcon={<ArrowBackIosNewIcon />}
                            color="primary"
                        >
                            Voltar
                        </Button>
                    </>
                ) : (<>
                    <Button
                        size="large"
                        onClick={() => setTypeD(0)}
                        startIcon={<ArrowBackIosNewIcon />}
                        color="primary"
                    >
                        Voltar
                    </Button>
                </>)}
            </Grid>
            {/* </Grid> */}
            <Grid item xs={12} style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                {typeD == 0 && (
                    <div style={{ width: "auto", justifyContent: "center", display: "flex", padding: "10px" }}>
                        <p style={{ color: "#637381", fontFamily: "sans-serif", fontSize: "14px", textAlign: "center" }}>Você pode adicionar despesas manualmente ou utilizando um QR Code.</p>
                    </div>
                )}
                {typeD == 0 ? (
                    <>
                        <Grid item xs={12} style={{ width: 350, padding: "10px" }}>
                            {viagem != null ? (
                                <Button
                                    size="large"
                                    startIcon={<NoteAddIcon />}
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    style={{ color: "white" }}
                                    onClick={() => setTypeD(1)}
                                >Adicionar Manualmente</Button>
                            ) : (null)}
                            {viagem != null ? (
                                <Button
                                    size="large"
                                    startIcon={<QrCodeScannerOutlined />}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{ color: "white" }}
                                    onClick={() => setOpenCQrCode(true)}
                                >Adicionar com QR Code</Button>
                            ) : (null)}
                            {/* <Grid item xs={12}>
                                    <Divider variant="ul" />

                                </Grid> */}

                            <Dialog
                                fullScreen
                                open={openCQrCode}
                                onClose={() => setOpenCQrCode(false)}
                            >
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => setOpenCQrCode(false)}
                                            aria-label="close"
                                        >
                                            <CloseOutlined />
                                        </IconButton>
                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                            QR code
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                                {accessCam ? (
                                    <>
                                        <QrCodeLearnComponent viagem={viagem} handleCreate={handleCreate} setType={setType} />
                                    </>
                                ) : (
                                    <>
                                        <CameraPermission cameraPermission={accessCam} setCameraPermission={setAccessCam} />
                                    </>
                                )}
                            </Dialog>

                        </Grid>
                    </>
                ) : (null)}
            </Grid>
            {typeD == 1 ? (
                <div style={{ padding: "20px" }}>
                    <Grid container item xs={12} style={{ justifyContent: "center", textAlign: "left", alignContent: "space-between", marginTop: "0px" }} spacing={1}>
                        <UploadAvatar
                            file={file}
                            accept="image/jpeg,image/png,image/gif"
                            style={{ width: "100px" }}
                            onDrop={handleDropAvatar}
                            capture="environment"
                        />
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: "10px", alignContent: "space-between", alignContent: "center" }} spacing={1}>
                        <Grid container item xs={12} spacing={1} style={{ paddingTop: "10px" }}>
                            <Grid item xs={12}>
                                <TextField
                                    id="filled-multiline-flexible"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={(e) => setDescricao(e.target.value)}
                                    defaultValue={descricao}
                                    multiline
                                    maxRows={4}
                                    label="Justificativa/Descrição"
                                    placeholder="Viagem para..."
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {categorias.length == 0 ? (
                                <Skeleton height={20}>
                                </Skeleton>
                            ) : (
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={tipodedespesa != null ? tipodedespesa.value : null}
                                        defaultValue={0}
                                        label="Categoria"

                                    >
                                        {categorias
                                            ?.filter(e => e.name !== "Hodometro" && e.name !== "Pendente")
                                            .map((e) => (
                                                <MenuItem
                                                    value={e.iddespesa_tipo}
                                                    onClick={() => handleChangeTipoDeDespesa({ label: e.name, value: e.iddespesa_tipo, sub: e.produtos })}
                                                >
                                                    {e.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        {tipodedespesa != null && tipodedespesa?.sub?.lenght != 0 && tipodedespesa?.label != "Outros" ? (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Gasto com</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={tipoGasto != null ? tipoGasto.value : 1}
                                        label="Categoria"
                                    >
                                        {tipodedespesa?.sub?.map((e) => (
                                            <MenuItem value={e.iddespesa_subtipo} onClick={() => handleChangeGasto({
                                                label: e.name, value: e.iddespesa_subtipo
                                                // , limite: e.limite 
                                            })}>{e.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ) : (
                            null
                        )}
                        {viagem.tem_multimonetario && (
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                                    <InputLabel id="select-moeda-label">Moeda</InputLabel>
                                    <Select
                                        labelId="select-moeda-label"
                                        id="select-moeda"
                                        value={moeda}
                                        onChange={handleMoedaChange}
                                        label="Moeda"
                                    >
                                        {viagem.adiantamento?.map((i) => {
                                            const item = getEnumForID(i.tipo_moeda);
                                            return (
                                                <MenuItem key={item.id} value={item.id}>
                                                    <Flag code={item.flagCode} width="20" style={{ marginRight: '10px' }} /> {item.nome}
                                                </MenuItem>
                                            );
                                        })}
                                        <MenuItem key={EnumTipo.moedas.BRL.id} value={EnumTipo.moedas.BRL.id}>
                                            <Flag code={EnumTipo.moedas.BRL.flagCode} width="20" style={{ marginRight: '10px' }} /> {EnumTipo.moedas.BRL.nome}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="outlined-number"
                                label="Total gasto"
                                value={totalgasto}
                                onChange={handleValorChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {getCurrencySymbol(moeda)}
                                        </InputAdornment>
                                    ),
                                    inputProps: {
                                        inputMode: 'numeric', // Orienta o uso de teclado numérico
                                        style: { fontSize: '18px' }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ justifyContent: "center", textAlign: "center" }} width={"auto"}>
                            <UtaliiDatePickerNormal
                                value={datadecompra}
                                onChange={setDatadecompra}
                                label="Data de Compra"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                        <Button size="large" fullWidth variant="contained" color="primary" autoFocus onClick={() => handleCreateDespesa()}>
                            Criar despesa
                        </Button>
                    </Grid>
                </div>
            ) : (
                <>
                </>
            )}
            {typeD == 2 ? (
                <>
                    <ExtrasDespesasComponentM viagem={viagem} />
                </>
            ) : (
                <>
                </>
            )}
        </Container>
    )
}
