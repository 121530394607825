import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({


    dialogTitle: {
        background: '#c62828',
    },

    typoTitle: {
        color: '#ffffff',
    },

    dialogContent: {
        width: 'auto',
    },

    gridCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    buttonSave: {
        color: '#fafafa',
        background: '#c62828',
        '&:hover': {
            background: '#d15252',
        },
    },

    buttonCancel: {
        color: '#fafafa',
        background: '#808080',
        '&:hover': {
            background: '#999999',
        },
    },
}));
