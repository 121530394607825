import { Box, Button, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { AddOutlined, SearchOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Snackbar from '../../components/snackbar';
import UDatePicker from '../../components/udatepicker';
import ULoaderVoo from '../../components/uloadervoo';
import USearchField from '../../components/usearchfield';
import EnumPackagesAgentes from '../../enums/enumStatusPackagesAgentes';
import pacoteAgenteRepositorio from '../../repositorios/pacoteAgente';
import pessoaRepositorio from '../../repositorios/pessoa';
import Dashboard from '../dashboard';
import DialogCreatePacote from './DialogCreatePacote';
import DialogPacote from './DialogPacote';
import MobileDados from './MobileDados';
import MobileDialogPacote from './MobileDialogPacote';
import { useStyles } from './styles';
import TabelaDados from './TabelaDados';

function isValidDate(date) {
    return moment(date).isValid();
}

export const ListaAgendaPacotes = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedPacote, setSelectedPacote] = useState({});
    const [openDialogPacote, setOpenDialogPacote] = useState(false);
    const [openDialogCreatePacote, setOpenDialogCreatePacote] = useState(false);
    const [load, setLoad] = useState(false);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [data, setData] = useState([]);
    const [dataInicio, setDataInicio] = useState(new Date());
    const [dataFim, setDataFim] = useState(new Date());
    const [viajante, setViajante] = useState(null);
    const [viajantes, setViajantes] = useState([]);
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [isLoading, setIsLoading] = useState(false);
    const [statusSelected, setStatusSelected] = useState(0);
    const [statusValues, setStatusValues] = useState([
        {
            label: 'Todos',
            value: 0,
        },
        {
            label: EnumPackagesAgentes.getInfoPackage(1).text,
            value: 1,
        },
        {
            label: EnumPackagesAgentes.getInfoPackage(2).text,
            value: 2,
        },
        {
            label: EnumPackagesAgentes.getInfoPackage(3).text,
            value: 3,
        },
        {
            label: EnumPackagesAgentes.getInfoPackage(4).text,
            value: 4,
        },
    ]);

    const handleClickOpenDialogCreatePacote = () => {
        setOpenDialogCreatePacote(!openDialogCreatePacote)
    }

    const handleClickOpenDialogConfirm = () => {
        setOpenDialogConfirm(!openDialogConfirm)
    }

    const handleClickPesquisar = async () => {
        if (dataInicio > dataFim) {
            setSnackVariant('error');
            setSnackMessage('Data de início maior que data final.');
            setSnackVisible({ visible: true })
            return;
        }

        if (!isValidDate(dataInicio) || !isValidDate(dataFim)) {
            setSnackVariant('error');
            setSnackMessage('Data Inválida.');
            setSnackVisible({ visible: true });
            return;
        }

        if (statusSelected === null) {
            setSnackVariant('error');
            setSnackMessage('Status não pode ser vazio.');
            setSnackVisible({ visible: true });
            return;
        }

        let dto = {
            dataInicio,
            dataFim,
            statusPacote: statusSelected.value,
            idViajante: viajante ? viajante.value : 0,
        }
        getData(dto)
    }

    async function getData(dto) {
        try {
            setIsLoading(true)
            let response = await pacoteAgenteRepositorio.getAllPacotes(dto);
            setData(response.data)
        } catch (e) {
            setSnackVariant('error');
            setSnackMessage('Erro ao pesquisar dados.');
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        (async () => {
            setStatusSelected(statusValues[0])
            let responseViajantes = await pessoaRepositorio.getSearchField();
            let today = Date.now()
            setDataInicio(new Date(today - 2592000000))
            setDataFim(new Date(today))
            let dto = {
                dataFim: new Date(today),
                dataInicio: new Date(today - 2592000000),
                statusPacote: statusSelected.value,
                idViajante: viajante ? viajante.value : 0,
            }
            getData(dto)
            setViajantes(responseViajantes);
        })()
    }, [load])

    return (
        <section >
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <ULoaderVoo isLoading={isLoading} />
            <DialogCreatePacote {...{ setOpenDialogCreatePacote, openDialogCreatePacote, viajantes, handleClickOpenDialogCreatePacote, setIsLoading, setSnackVariant, setSnackMessage, setSnackVisible, setLoad, load }} />
            <Box p={2}>
                <form autoComplete="off">
                    <Grid container spacing={1} >
                        <Grid item xs={12} md={2}>
                            <UDatePicker date={dataInicio} setDate={setDataInicio} label="Data Início" />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <UDatePicker date={dataFim} setDate={setDataFim} label="Data Fim" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <USearchField label="Status" itens={statusValues} value={statusSelected} setValue={setStatusSelected} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <USearchField label="Viajante" itens={viajantes} value={viajante} setValue={setViajante} />
                        </Grid>
                        <Grid item xs={6} md={1} style={{ alignSelf: 'center' }}>
                            <Button onClick={handleClickOpenDialogCreatePacote} className={classes.button} variant="contained" fullWidth >
                                <AddOutlined />
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={1} style={{ alignSelf: 'center' }}>
                            <Button onClick={handleClickPesquisar} className={classes.button} variant="contained" fullWidth >
                                <SearchOutlined />
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box mt={2}>
                {responsive ? (
                    <>
                        <MobileDialogPacote {...{ selectedPacote, openDialogPacote, setOpenDialogPacote, setIsLoading, setSnackVariant, setSnackMessage, setSnackVisible, handleClickOpenDialogConfirm, openDialogConfirm, setLoad, load }} />
                        <MobileDados {...{ data, setSelectedPacote, setOpenDialogPacote }} />
                    </>
                ) : (
                    <>
                        <DialogPacote {...{ selectedPacote, openDialogPacote, setOpenDialogPacote, setIsLoading, setSnackVariant, setSnackMessage, setSnackVisible, handleClickOpenDialogConfirm, openDialogConfirm, setLoad, load }} />
                        <TabelaDados {...{ data, setSelectedPacote, setOpenDialogPacote }} />
                    </>
                )}
            </Box>
        </section>
    )
};

export const AgendaPacotes = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaAgendaPacotes} />
        </div>
    )
};

export default AgendaPacotes;
