import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LinesTextField from '../../../../../components/LinesTextField';
import ULoaderVoo from '../../../../../components/uloadervoo';
import USearchField from '../../../../../components/usearchfield';

export const CadastroViajanteSolicitacaoProduto = props => {
    const [passageiros, setPassageiros] = useState([]);
    const [centroCusto, setCentroCusto] = useState('');

    const handleChangePorcentagem = event => {
        props.setPorcentagem(event.target.value);
    }

    useEffect(() => {
        let listaPassageiro = props.solicitacao.passageiros.map(item => {
            return {
                value: item.idpassageiro,
                label: `${item.passageiro.nome} ${item.passageiro.sobrenome}`,
                idsolicitacaopassageiro: item.idsolicitacaopassageiro,
                centroCusto: {
                    value: item.idcentrocusto,
                    label: `${item.centroCusto.nome}`
                }
            }
        });
        props.produto.passageiros.forEach(item => {
            listaPassageiro = listaPassageiro.filter(p => p.idsolicitacaopassageiro !== item.idsolicitacaopassageiro);
        });
        setPassageiros(listaPassageiro);
    }, [props.produto.passageiros, props.solicitacao.passageiros])

    useEffect(() => {
        if (!props.selecionado) {
            setCentroCusto('');
            return;
        }
        if (!props.selecionado.centroCusto) {
            setCentroCusto('');
            return;
        }
        setCentroCusto(props.selecionado.centroCusto.label)
    }, [props.selecionado])

    return (
        <div>
            <ULoaderVoo isLoading={props.backDropViajante} />
            <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                    <USearchField itens={passageiros} value={props.selecionado} setValue={props.setSelecionado} label={'Passageiro'} />
                </Grid>
                <Grid item xs={9}>
                    <LinesTextField variant="outlined" fullWidth margin="dense" label="Centro Custo" value={centroCusto || ''} />
                </Grid>
                <Grid item xs={3}>
                    <LinesTextField variant="outlined" fullWidth margin="dense" placeholder="%" value={props.porcentagem} onChange={handleChangePorcentagem} />
                </Grid>
            </Grid>
        </div>
    )
};

export default CadastroViajanteSolicitacaoProduto;
