import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LinesTextField from '../../../components/LinesTextField';
import USwitch from '../../../components/uswitch';
import { useStyles } from './styles';

export const CadastroCentroCusto = props => {
    const classes = useStyles();
    const [ativo, setAtivo] = useState(props.centroCusto.idcentrocusto ? props.centroCusto.ativo : true || true);
    const [solicitacao, setSolicitacao] = useState(props.centroCusto.idcentrocusto ? props.centroCusto.necessitaautorizacao : true || true);

    const handleChange = event => {
        props.setCentroCusto({
            ...props.centroCusto,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeOther = (item, value) => {
        props.setCentroCusto({
            ...props.centroCusto,
            [item[0]]: value[0],
            [item[1]]: value[1]
        })
    };

    useEffect(() => {
        let item = {
            0: 'ativo',
            1: 'necessitaautorizacao'
        }
        let value = {
            0: ativo,
            1: solicitacao
        }
        handleChangeOther(item, value);
    }, [ativo, solicitacao])

    return (
        <div>
            <Grid container spacing={1} style={{ margin: 0 }} item xs={12}>
                <Grid item xs={12}>
                    <USwitch {...{ checked: ativo, setChecked: setAtivo, label: 'Ativo' }} />
                </Grid>
                <Grid item xs={12}>
                    <LinesTextField
                        required
                        fullWidth
                        id="teste1"
                        label="Nome"
                        name="nome"
                        variant="outlined"
                        margin="dense"
                        value={props.centroCusto.nome}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LinesTextField
                        fullWidth
                        id="teste1"
                        label="Código"
                        name="codigo"
                        defaultValue={""}
                        variant="outlined"
                        margin="dense"
                        value={props.centroCusto.codigo}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LinesTextField
                        fullWidth
                        id="teste1"
                        label="Orçamento"
                        name="orcamento"
                        variant="outlined"
                        margin="dense"
                        value={props.centroCusto.orcamento}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <USwitch {...{
                        checked: solicitacao, setChecked: setSolicitacao, label: 'Necessita Pedir Solicitação De Autorização'
                    }} />
                </Grid>
            </Grid>

        </div>

    )
};

export default CadastroCentroCusto
