import { AppBar, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Grow, Hidden, IconButton, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AddRounded, Close, SaveOutlined, SearchOutlined, WarningOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import LinesTextField from '../../../../components/LinesTextField';
import Snackbar from '../../../../components/snackbar';
import UCheckbox from '../../../../components/UCheckbox';
import UDatePicker from '../../../../components/udatepicker';
import ULoaderVoo from '../../../../components/uloadervoo';
import UMaskTextfield from '../../../../components/umasktextfield';
import USearchField from '../../../../components/usearchfield';
import USelect from '../../../../components/uselect';
import Enums from '../../../../enums';
import centroCustoRepositorio from '../../../../repositorios/centroCusto';
import permissaoRepositorio from '../../../../repositorios/permissao';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import PoliticaViagemRepositorio from '../../../../repositorios/politicaViagem';
import validatorCPF from '../../../../utils/cpfValidator';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { useStyles } from './styles';

export const AdicionarNovaPessoa = props => {
    const classes = useStyles();
    const theme = useTheme();
    const cookies = new Cookies;
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [pessoaCadastro, setPessoaCadastro] = useState({});
    const [dataNascimentoCadastro, setDataNascimentoCadastro] = useState(new Date(1900, 1, 1));
    const [generoCadastro, setGeneroCadastro] = useState(0);
    const [politicaViagem, setPoliticaViagem] = useState({});
    const [modalCadastroPassageiro, setModalCadastroPassageiro] = useState(false);
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackVisibility, setSnackVisibility] = useState({ visible: false });
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [permiteAdicionarPassageiro, setPermiteAdicionarPassageiro] = useState(false);
    const [centroCustos, setCentrosCusto] = useState([]);

    const [errorValue, setErrorValue] = useState({
        cpf: false,
        rg: false,
        dataNascimento: false,
        nome: false,
        sobrenome: false,
        genero: false,
        telefone: false,
    })
    const [researched, setResearched] = useState(true)
    const [disableValue, setDisableValue] = useState({
        cpf: false,
        rg: researched,
        datanascimento: true,
        nome: researched,
        sobrenome: researched,
        genero: researched,
        telefone: researched,
    })
    const [preencherCampos, setPreencherCampos] = useState({
        rg: true,
        cpf: true,
        datanascimento: true
    });
    const [genero, setGenero] = useState(props.passageiro.genero === 'M' ? 1 : props.passageiro.genero === 'F' ? 2 : 0);
    const [dataNascimento, setDataNascimento] = useState(props.passageiro.dataNascimento ? props.passageiro.dataNascimento : new Date());
    const itensGenero = [{
        value: 1,
        label: "Masculino"
    },
    {
        value: 2,
        label: "Feminino"
    }]

    const [pessoa, setPessoa] = useState(props.passageiro.idpassageiro ? {
        label: props.passageiro.nome + ' ' + props.passageiro.sobrenome, value: props.passageiro.idpassageiro
    } : {
        label: '', value: 0
    });
    const [pessoas, setPessoas] = useState([]);

    const handleChangeCheckbox = event => {
        setPreencherCampos({
            ...preencherCampos,
            [event.target.name]: event.target.checked
        })
    }

    const handleChangeCadastro = event => {
        settingError(event.target.name)
        setPessoaCadastro({
            ...pessoaCadastro,
            [event.target.name]: event.target.value
        })
    }

    const settingError = (itemID) => {
        switch (itemID) {
            case 'nome':
                setErrorValue({ ...errorValue, nome: false })
                break;
            case 'sobrenome':
                setErrorValue({ ...errorValue, sobrenome: false })
                break;
            case 'sexo':
                setErrorValue({ ...errorValue, genero: false })
                break;
            case 'rg':
                setErrorValue({ ...errorValue, rg: false })
                break;
            case 'cpf':
                setErrorValue({ ...errorValue, cpf: false })
                break;
            case 'datanascimento':
                setErrorValue({ ...errorValue, datanascimento: false })
                break;
            case 'telefone':
                setErrorValue({ ...errorValue, telefone: false })
                break;
            default:
                break;
        }
    }

    const handleClickSalvarPassageiro = async () => {

        const dto = {
            ...pessoaCadastro,
            rg: !preencherCampos.rg ? '0' : pessoaCadastro.rg,
            cpf: !preencherCampos.cpf ? '0' : pessoaCadastro.cpf,
            datanascimento: !preencherCampos.datanascimento ? new Date('01/01/1900') : pessoaCadastro.datanascimento,
            ativo: true,
            sessionId: cookies.get('sessionId')
        }


        if (!dto.nome) {
            setSnackVariant('error');
            setMensagemSnack('O campo nome não pode ser vazio.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, nome: true })
            return;
        }

        if (!dto.sobrenome) {
            setSnackVariant('error');
            setMensagemSnack('O campo sobrenome não pode ser vazio.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, sobrenome: true })
            return;
        }

        if (!dto.sexo) {
            setSnackVariant('error');
            setMensagemSnack('O campo gênero não pode ser vazio.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, genero: true })
            return;
        }

        if (dto.telefone === "(__)_____-____" || dto.telefone === null || dto.telefone === 'undefined' || dto.telefone.trim() === "") {
            setSnackVariant('error');
            setMensagemSnack('O campo telefone não pode ser vazio.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, telefone: true })
            return;
        }

        if (!dto.rg && preencherCampos.rg) {
            setSnackVariant('error');
            setMensagemSnack('O campo RG não pode ser vazio.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, rg: true })
            return;
        }

        if (isNaN(dto.rg) && preencherCampos.rg) {
            setSnackVariant('error');
            setMensagemSnack('O campo RG deve conter apenas números.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, rg: true })
            return;
        }

        if (!dto.cpf && preencherCampos.cpf) {
            setSnackVariant('error');
            setMensagemSnack('O campo CPF não pode ser vazio.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, cpf: true })
            return;
        }

        if (!validatorCPF(dto.cpf)) {
            setSnackVariant('error');
            setMensagemSnack('CPF inválido.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, cpf: true })
            return;
        }

        if (!dto.datanascimento && preencherCampos.datanascimento) {
            setSnackVariant('error');
            setMensagemSnack('O campo data de nascimento não pode ser vazio.');
            setSnackVisibility({ visible: true });
            setErrorValue({ ...errorValue, datanascimento: true })
            return;
        }

        let response;
        try {
            setIsLoading(true)
            response = await pessoaRepositorio.postPessoa(dto);
            const pessoasResponse = await pessoaRepositorio.getSearchField();
            setModalCadastroPassageiro(false);
            setPessoas(pessoasResponse);
            setPessoa({ value: response.data.value, label: response.data.label });
            handleChangePassageiro('nome', response.data.value);

            setSnackVariant('success');
            setMensagemSnack('Passageiro salvo com sucesso!');
            setSnackVisibility({ visible: true });
        } catch (e) {
            setSnackVariant('error');
            setMensagemSnack('Falha ao tentar salvar.');
            setSnackVisibility({ visible: true });
        } finally {
            setIsLoading(false)
        }

    }


    const handleChangePassageiro = (campo, valor) => {
        if (!valor && !isNaN(valor) || valor <= 0) {
            return
        }
        props.setPassageiro({ ...props.passageiro, pessoaRepositorio });
        pessoaRepositorio.get(valor).then(response => {
            props.setPassageiro({
                ...props.passageiro,
                idpassageiro: response.idpessoa,
                nome: response.nome,
                sobrenome: response.sobrenome,
                porcentagem: '100',
                email: 'zulma@linestur.com',
                dataNascimento: new Date(response.datanascimento),
                genero: response.sexo === 1 ? 'M' : 'F',
                idcentrocusto: politicaViagem.utilizarcentrocusto ? props.passageiro.idcentrocusto : centroCustos[0].value,
                centroCusto: politicaViagem.utilizarcentrocusto ? props.passageiro.centroCusto : centroCustos[0].label,
                cpf: response.cpf,
                telefone: response.telefone,
                nacionalidade: response.nacionalidade,
            })
            setGenero(response.sexo);
            setDataNascimento(new Date(response.datanascimento));
        });
    }



    const handleVerifyCPF = async () => {
        if (!validatorCPF(pessoaCadastro.cpf)) {
            setSnackVariant('error');
            setMensagemSnack('CPF inválido.');
            setSnackVisibility({ visible: true });
            return;
        }

        setIsLoading(true)
        try {
            let dto = {
                cpf: pessoaCadastro.cpf
            }

            let response = await pessoaRepositorio.postVerificarCpf(dto);
            if (response.status === 207) {
                setSnackVariant('error');
                setMensagemSnack(response.data.message);
                setSnackVisibility({ visible: true });
                return
            }

            if (response.status === 202) {
                setSnackVariant('info');
            } else {
                setSnackVariant('success');
            }

            setResearched(!researched)
            setMensagemSnack(response.data.message);
            setSnackVisibility({ visible: true });
            setPessoaCadastro({
                ...pessoaCadastro,
                nome: response.data.nome,
                sobrenome: response.data.sobrenome,
                datanascimento: new Date(response.data.nascimento)
            })
            setDataNascimentoCadastro(new Date(response.data.nascimento))
            changeInitialStateFields()


        } catch (e) {
            return;
        } finally {
            setIsLoading(false)
        }
    }

    function changeInitialStateFields() {
        setDisableValue(
            {
                rg: !disableValue.rg,
                nome: !disableValue.nome,
                sobrenome: !disableValue.sobrenome,
                genero: !disableValue.genero,
                telefone: !disableValue.telefone,
            }
        )
    }

    useEffect(() => {
        (async () => {
            pessoaRepositorio.getSearchField().then(response => setPessoas(response));
            centroCustoRepositorio.getSearchField().then(response => setCentrosCusto(response));
            const organizacao = await PoliticaViagemRepositorio.validarConsulta();
            const permite = await permissaoRepositorio.temPermissao(Enums.moduloSistema.pessoa, Enums.acaoRequerida.criar);
            setPermiteAdicionarPassageiro(permite.data && organizacao.data.politicaViagem.permitepreenchermanualpassageiro);
            setPoliticaViagem(organizacao.data.politicaViagem);
        })()
    }, [])

    return (
        <div className={classes.root}>
            <Hidden smUp>
                <ULoaderVoo isLoading={isLoading} />
            </Hidden>
            <Snackbar {...{ variant: snackVariant, message: mensagemSnack, visible: snackVisibility.visible, setSnackbar: setSnackVisibility }} />
            <Dialog
                open={modalCadastroPassageiro}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={matches ? true : false}
                onClose={() => setModalCadastroPassageiro(false)}
            >
                <React.Fragment>
                    <Snackbar {...{ variant: snackVariant, message: mensagemSnack, visible: snackVisibility.visible, setSnackbar: setSnackVisibility }} />
                    <AppBar position="relative">
                        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', background: '#616161' }}>
                            <Typography style={{ color: '#ffffff' }}>Cadastrar Pessoa</Typography>
                            <IconButton onClick={() => setModalCadastroPassageiro(false)}>
                                <Close style={{ color: '#ffffff' }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </React.Fragment>
                <DialogContent>
                    <Grid container spacing={2} style={{ margin: 0 }} item xs={12}>
                        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <WarningOutlined fontSize='medium' htmlColor='yellow' />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant='caption'>
                                Segundo consta na Resolução 595 da ANAC, tornou-se <b>mandatório</b> o envio do CPF, DATA DE NASCIMENTO e
                                TELEFONE da pessoa para a garantia da aéreo, portanto solicitamos o cadastro correto dos
                                seguintes campos.
                            </Typography>
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            <LinesTextField
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled={disableValue.nome} fullWidth error={errorValue.nome} variant="outlined" margin="dense" label="Nome" name="nome" value={pessoaCadastro.nome} onChange={handleChangeCadastro} />
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            <LinesTextField
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled={disableValue.sobrenome} fullWidth error={errorValue.sobrenome} variant="outlined" margin="dense" label="Sobrenome" name="sobrenome" value={pessoaCadastro.sobrenome} onChange={handleChangeCadastro} />
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            <USelect disabled={disableValue.genero} {...{ itens: itensGenero, value: generoCadastro, setValue: setGeneroCadastro, label: 'Gênero' }} name="sexo" error={errorValue.genero} onChange={handleChangeCadastro} />
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            <UMaskTextfield
                                disabled={disableValue.telefone}
                                helperText="Formato de telefone incorreto"
                                mask="(99)99999-9999"
                                error={errorValue.telefone}
                                value={pessoaCadastro.telefone || ''}
                                required
                                label="Telefone"
                                style={{ marginLeft: '10px' }}
                                alwaysShowMask={false}
                                name="telefone"
                                onChange={handleChangeCadastro}
                            />
                        </Grid>
                        <Grid item xs={matches ? 12 : 6} style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={<UCheckbox checked={preencherCampos.rg} onChange={handleChangeCheckbox} name="rg" />}
                                    label="Preencher RG"
                                    labelPlacement="end"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={matches ? 12 : 6} style={{ display: 'flex', alignItems: 'center' }}>
                            {preencherCampos.rg ? (
                                <LinesTextField
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={disableValue.rg} fullWidth error={errorValue.rg} variant="outlined" margin="dense" label="RG" name="rg" value={pessoaCadastro.rg} onChange={handleChangeCadastro} />
                            ) : null}
                        </Grid>
                        <Grid item xs={matches ? 12 : 6} style={{ display: 'flex', alignItems: 'center' }}>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={<UCheckbox checked={preencherCampos.cpf} name="cpf" />}
                                    label="Preencher CPF"
                                    labelPlacement="end"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            {preencherCampos.cpf ? (
                                <UMaskTextfield
                                    autoFocus={true}
                                    helperText="Formato de CPF incorreto"
                                    alwaysShowMask={false}
                                    error={errorValue.cpf}
                                    maskChar=''
                                    mask="999.999.999-99"
                                    required
                                    label="CPF"
                                    value={pessoaCadastro.cpf || ''}
                                    name='cpf'
                                    onChange={handleChangeCadastro}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={<UCheckbox checked={preencherCampos.datanascimento} name="datanascimento" />}
                                    label="Preencher data de nascimento"
                                    labelPlacement="end"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={matches ? 12 : 6}>
                            {preencherCampos.datanascimento ? (
                                <UDatePicker
                                    readOnly={true}
                                    disabled={disableValue.datanascimento}
                                    error={errorValue.dataNascimento}
                                    {...{ date: dataNascimentoCadastro, setDate: setDataNascimentoCadastro, label: "Data Nascimento *" }}
                                    name="datanascimento"
                                    eventOnChange={handleChangeCadastro} />
                            ) : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {pessoaCadastro.nome === undefined ? (
                        <Button className={classes.button} onClick={handleVerifyCPF} startIcon={<SearchOutlined style={{ color: '#ffffff' }} />}>Pesquisar</Button>
                    ) : (
                        <Button className={classes.button} onClick={handleClickSalvarPassageiro} startIcon={<SaveOutlined style={{ color: '#ffffff' }} />}>Salvar</Button>
                    )}
                </DialogActions>
            </Dialog>
            <Grid container xs={12} spacing={1} style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {permiteAdicionarPassageiro ? (
                    <Grow in={permiteAdicionarPassageiro} timeout={500}>
                        <Grid item xs={!matches ? 12 : 12}>
                            <Tooltip title="Cadastrar nova pessoa">
                                <Button onClick={() => setModalCadastroPassageiro(true)}>
                                    <GroupAddIcon />
                                </Button>
                                {/* <IconButton onClick={() => setModalCadastroPassageiro(true)}>
                                    <GroupAddIcon />
                                </IconButton> */}
                            </Tooltip>
                        </Grid>
                    </Grow>
                ) : null}
            </Grid>
        </div>
    )
};

