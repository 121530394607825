import { Avatar, Box, Card, CardContent, Divider, Typography } from "@mui/material"
import { CreationDate, IniciadoEm } from "../../../views/prestacaodecontas/Desktop/sections"
import { formatarParaNomeStatusAdiantamento, formatarParaNomeStatusViagem } from "../../../enums/enumPrestacaoDeContas"

export const CardViagem_Mobile = ({ viagem, moreAction }) => {
    return (
        <>
            <Card sx={{ width: "100%", maxWidth: 325, boxShadow: 3, borderRadius: "10px", margin: "3px" }}>
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Avatar
                            src={viagem.pessoa.img}
                            alt={viagem.pessoa.nome}
                            sx={{ width: 40, height: 40 }}
                        />
                        <Box sx={{ ml: 2, flexGrow: 1 }}>
                            <Typography variant="subtitle1">{viagem.pessoa.nome}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                {viagem.qtd_despesas} Despesas
                            </Typography>
                        </Box>
                        {moreAction && (
                            <>
                                {moreAction}
                            </>
                        )}
                    </Box>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                        {viagem.titulo}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
                        <CreationDate data={viagem.datadecriacao} />
                    </Typography>
                    <Typography variant="h6" color="textSecondary" sx={{ mb: 1, fontSize: "14px" }}>
                        {formatarParaNomeStatusViagem(viagem.status)}
                        <div style={{ height: "4px" }}></div>
                        {formatarParaNomeStatusAdiantamento(viagem.adiantamento.status, viagem.totalinicial)}
                    </Typography>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Typography variant="body2" color="textSecondary">
                            <IniciadoEm datainicio={viagem.datainicio} />
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="textSecondary">
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                Total: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(viagem.total)}
                            </Typography>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}
