import { Container, Grid, Tab, Tabs, tabsClasses } from "@mui/material"
import { useCallback, useState } from "react"
import OrderListView from "./tabelaConfig"
import { ProfileCompanie } from "./profile"
import { PermissionList } from "./permissonslist"
import { AccountTreeOutlined, FactCheckOutlined, ListOutlined, PolicyOutlined, PrivacyTipOutlined } from "@mui/icons-material"
import { ControlConfig } from "./control"
import DrawerMenu from "./menudrawer"
import LuggageIcon from '@mui/icons-material/Luggage';
import { Categorias } from "./categorias"


export const ConfigAll = ({ permissions }) => {
    const [listaPessoas, setListaPessoas] = useState([
        {
            pessoa: {
                nome: "Zulma torassi",
                img: "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/avatar/avatar-7.webp",
                centrodecusto: "Não Possui - 01010101",
                permissao: 1
            },
        },
        {
            pessoa: {
                nome: "Zulma torassi",
                img: "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/avatar/avatar-3.webp",
                centrodecusto: "Vendas",
                permissao: 1
            },
        },
        {
            pessoa: {
                nome: "Zulma torassi",
                img: "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/avatar/avatar-17.webp",
                centrodecusto: "Marketing - 05",
                permissao: 1
            },
        },
        {
            pessoa: {
                nome: "Zulma torassi",
                img: "https://pub-c5e31b5cdafb419fb247a8ac2e78df7a.r2.dev/mock/assets/images/avatar/avatar-5.webp",
                centrodecusto: "Atendentes 12",
                permissao: 1
            },
        }
    ])

    const TABS_GROUPED = [
        ...(permissions?.permissao_editarpermissao ? [
            {
                groupTitle: 'Pessoas',
                tabs: [
                    {
                        value: 'permissions',
                        label: 'Permissões',
                        icon: <PrivacyTipOutlined style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                        viewer: <PermissionList data={listaPessoas} />
                    },
                ],
            },
        ] : []),
        ...(permissions.permissao_vergrupopalavra ? [
            {
                groupTitle: 'Geral',
                tabs: [
                    // {
                    //     value: 'control',
                    //     label: 'Controle',
                    //     icon: <FactCheckOutlined style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                    //     viewer: <ControlConfig />
                    // },
                    // {
                    //     value: 'control',
                    //     label: 'Controle',
                    //     icon: <FactCheckOutlined style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                    //     viewer: <ControlConfig />
                    // },

                    {
                        value: 'politicas',
                        label: 'Gerenciar Grupos',
                        icon: <AccountTreeOutlined style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                        viewer: <ControlConfig />
                    },
                    // {
                    //     value: 'categorias',
                    //     label: 'Categorias',
                    //     icon: <ListOutlined style={{ marginRight: "5px", marginTop: "5px" }} />, // Adicione o ícone relevante aqui
                    //     viewer: <Categorias />
                    // },
                ],
            },
        ] : []),
    ];


    const [currentTab, setCurrentTab] = useState("permissions");

    const handleChangeTab = useCallback((event, newValue) => {
        setCurrentTab(newValue);
    }, []);

    const getCurrentViewer = () => {
        for (const group of TABS_GROUPED) {
            for (const tab of group.tabs) {
                if (tab.value === currentTab) {
                    return tab.viewer;
                }
            }
        }
        return null;
    };

    return (
        <>
            <Container maxWidth="lg">
                <Grid container item xs={12}>
                    <Grid item xs={3}>
                        <DrawerMenu
                            TABS_GROUPED={TABS_GROUPED}
                            currentTab={currentTab}
                            handleChangeTab={handleChangeTab} />
                    </Grid>
                    <Grid item xs={9}>
                        {getCurrentViewer()}
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                </Grid>
            </Container>
        </>
    )
}