import React, { useState, useCallback, useRef, useEffect } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    Autocomplete,
    Polyline,
    DrawingManager,
    Marker,
} from "@react-google-maps/api";
import {
    Alert,
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import GoogleIcon from '@mui/icons-material/Google';
import { EnumTipoVeiculo } from "../../../../../../../enums/enumVeiculos";
import { primary } from "../../../../../../../theme/palette";
import html2canvas from "html2canvas";
import { useCustomSnackbar } from "../../../../../../../components/snackbar2/NewSnackBar";
import prestacaoDeContasRepositorio from "../../../../../../../repositorios/prestacaodecontas";
import DialogComponent from "../../../../../Desktop/sections/dialogs-desktop";

const apiKey = "AIzaSyDwG3G11O_o14Bax96T3qNQ7b6Pn-n5ldw"; // !!!!!!!!!!!!!!!! ESCONDER ISSO EM ALGUM LUGAR !!!!!!!!!!!!!!
const center = { lat: -33.8667, lng: 151.1955 };
const libraries = ["places", "drawing", "geometry"];

//para testes 

// { lat: -28.679814, lng: -49.367283 },
// { lat: -28.679959, lng: -49.366883 },
// { lat: -28.680086, lng: -49.366537 },
// { lat: -28.680152, lng: -49.366279 },
// { lat: -28.680022, lng: -49.366293 },
// { lat: -28.679652, lng: -49.366701 },
// { lat: -28.679233, lng: -49.367077 },
// { lat: -28.678824, lng: -49.367103 },
// { lat: -28.678432, lng: -49.366886 },
// { lat: -28.678880, lng: -49.366388 },
// { lat: -28.679510, lng: -49.365725 },
// { lat: -28.679819, lng: -49.365403 },
// { lat: -28.680233, lng: -49.364969 },
// { lat: -28.680575, lng: -49.364571 },
// { lat: -28.679043, lng: -49.362594 },

export const GoogleMapsMComponent = ({ idviagem, setType, handleClickCreateHodometro }) => {
    //Para a API
    const [polylines, setPolylines] = useState([]);
    const [snappedCoordinates, setSnappedCoordinates] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const [message, setMessage] = useState("");
    const [log, setLog] = useState(false);
    const [typeLog, setTypeLog] = useState("");
    const [path, setPath] = useState([]);

    //Para logica
    const [start, setStart] = useState(false);
    const [finalized, setFinalized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalDistance, setTotalDistance] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [timerActive, setTimerActive] = useState(false);
    const [timerId, setTimerId] = useState(null);
    const [tipoHodoOk, setTipoHodoOk] = useState(false);
    const [tipoveiculo, setTipoveiculo] = useState(1);
    const [tipocombustivel, setTipocombustivel] = useState(1);
    const [tipoporte, setTipoporte] = useState(2)
    const [descricao, setDescricao] = useState("");
    const [totalGanho, setTotalGanho] = useState(0)
    const [produto, setProduto] = useState({});
    const [openCancelarTrajeto, setOpenCancelarTrajeto] = useState(false);

    const { enqueueSnackbar } = useCustomSnackbar();

    //Para api 
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: apiKey,
        libraries,
    });

    const mapRef = useRef(null);
    const onLoad = useCallback((map) => (mapRef.current = map), []);

    const startTimer = () => {
        if (!timerActive) {
            const id = setInterval(() => {
                setMinutes((prevMinutes) => prevMinutes + 1);
            }, 60000); // 60000 ms = 1 minuto
            setTimerId(id);
            setTimerActive(true);
        }
    };

    // Função para parar o temporizador
    const stopTimer = () => {
        if (timerActive && timerId) {
            clearInterval(timerId);
            setTimerActive(false);
            setTimerId(null);
        }
    };

    useEffect(() => {
        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timerId]);

    const calculateDistance = (points) => {
        let totalDistance = 0;

        if (points.length > 1) {
            const path = points.map(
                (point) => new window.google.maps.LatLng(point.lat, point.lng)
            );
            totalDistance = window.google.maps.geometry.spherical.computeLength(path);
        }

        return totalDistance / 1000; // Converte para quilômetros.
    };

    const onPolylineComplete = (polyline) => {
        const value = path.map((latlng) => `${latlng.lat},${latlng.lng}`);
        runSnapToRoad(value);
        setPolylines([...polylines, polyline]);
        setLoading(false);
    };

    const runSnapToRoad = (pathValues) => {
        const path = pathValues.join("|");
        setLoading(true);
        fetch(
            `https://roads.googleapis.com/v1/snapToRoads?interpolate=true&key=${apiKey}&path=${path}`
        )
            .then((response) => response.json())
            .then((data) => {
                const newCoordinates = data.snappedPoints.map((point) => ({
                    lat: point.location.latitude,
                    lng: point.location.longitude,
                }));
                setSnappedCoordinates(newCoordinates);

                // Calcula e atualiza a distância total.
                const distance = calculateDistance(newCoordinates);
                setTotalDistance(distance);
            });
    };

    const handleClickFinalized = () => {
        setFinalized(true);
        setStart(false);
        onPolylineComplete();
        stopTimer();
    };

    const captureMap = async () => {
        if (mapRef.current) {
            const map = mapRef.current.getDiv()
            const canvas = await html2canvas(map, { useCORS: true, scale: 1 })
            const image2 = canvas.toDataURL('image/png')
            return image2
        }
    };

    const getLocation = async () => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newLocation = { lat: latitude, lng: longitude };
                    if (!start) {
                        setCurrentLocation(newLocation);
                    }
                },
                (error) => {
                    console.error("Error Code = " + error.code + " - " + error.message);
                },
                { enableHighAccuracy: true }
            );

            return () => navigator.geolocation.clearWatch(watchId);
        }
    }

    function calculateDistance2(lat1, lon1, lat2, lon2) {
        const R = 6371e3; // metros
        const φ1 = (lat1 * Math.PI) / 180;
        const φ2 = (lat2 * Math.PI) / 180;
        const Δφ = ((lat2 - lat1) * Math.PI) / 180;
        const Δλ = ((lon2 - lon1) * Math.PI) / 180;

        const a =
            Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distância em metros
    }

    const clearPolylines = () => {
        polylines.forEach((polyline) => polyline.setMap(null));
        setOpenCancelarTrajeto(false)
        setPolylines([]);
        setSnappedCoordinates([]);
        setStart(false);
        setLoading(false)
        setTotalDistance(0);
        setMinutes(0)
        setType(0)
    };

    useEffect(() => {
        let watchId = null; // Este identificador controlará o monitoramento de posição

        if (start) {
            startTimer()
            setLoading(true)
            if (navigator.geolocation) {
                let lastPosition = null;

                watchId = navigator.geolocation.watchPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;

                        setLoading(true);
                        if (lastPosition !== null) {
                            const distance = calculateDistance2(
                                latitude,
                                longitude,
                                lastPosition.lat,
                                lastPosition.lng
                            );

                            if (distance > 50) {
                                logMessage("Atualizando localização...", "success");
                                setCurrentLocation({ lat: latitude, lng: longitude });
                                lastPosition = { lat: latitude, lng: longitude };
                                setPath((prevPath) => [...prevPath, lastPosition]);
                            } else if (distance > 1000) {
                                logMessage(
                                    "Movimento dentro de 1 km, não atualiza.",
                                    "success"
                                );
                            } else {
                                logMessage(
                                    "Movimento dentro de 50 metros, não atualiza.",
                                    "success"
                                );
                            }
                        } else {
                            logMessage("Definindo localização inicial...", "success");
                            setCurrentLocation({ lat: latitude, lng: longitude });
                            lastPosition = { lat: latitude, lng: longitude };
                            setPath((prevPath) => [...prevPath, lastPosition]);
                        }
                    },
                    (error) => {
                        console.error("Error Code = " + error.code + " - " + error.message);
                    },
                    { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
                );
            }
        } else if (watchId !== null) {
            navigator.geolocation.clearWatch(watchId);
            logMessage("Monitoramento de posição finalizado.", "success");
        }

        // Limpeza que ocorre quando o componente é desmontado ou quando start muda
        return () => {
            if (watchId !== null) {
                navigator.geolocation.clearWatch(watchId);
            }
        };
    }, [start]); // Dependência adicionada para reagir à mudança do modo de teste

    const handleClickCreate = async () => {
        try {
            let image = await captureMap()
            handleClickCreateHodometro(
                {
                    idviagem: idviagem,
                    datadecompra: new Date(),
                    descricao: descricao,
                    produtos: {
                        iddespesa_tipo: produto?.iddespesa_tipo,
                        iddespesa_subtipo: produto?.iddespesa_subtipo
                    },
                    aprovado: false,
                    controladoria: false,
                    hodometro: {
                        kminicial: 0,
                        kmfinal: totalDistance,
                        kmtotal: 0,
                        duracao: minutes,
                        valor: 0,
                        veiculo: {
                            categoria: tipoveiculo,
                            combustivel: tipocombustivel,
                            porte: tipoporte
                        },
                        comprovanteinicial: image,
                        comprovantefinal: "",
                    },
                    total: 0,
                    mensagem: "",
                    comprovante: "",
                }
            )
            setType(0)
        } catch {

        } finally {

        }
    }

    const getAlltipos = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas()
            response.forEach(item => {
                if (item.name == "Hodometro") {
                    setProduto({
                        iddespesa_tipo: item.iddespesa_tipo,
                        iddespesa_subtipo: item.produtos[1].iddespesa_subtipo
                    })
                }
            })
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAlltipos()
        getLocation()
    }, [])

    const handleClickCriar = () => {
        if (descricao == "") {
            return
        }
        setTipoHodoOk(true)
    }

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return; // Não faz nada se o usuário clicar fora do Snackbar
        }
        setLog(false); // Atualiza o estado para fechar o Snackbar
    };

    const logMessage = (message, type) => {
        setMessage(message);
        setTypeLog(type);
        setLog(true);
    };

    return isLoaded ? (
        <>
            {
                !tipoHodoOk ? (
                    <>
                        <Container maxWidth="sm">
                            <Grid container item xs={12} style={{ padding: "20px" }}>
                                <Grid item xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
                                    <Typography style={{ fontWeight: "600", fontSize: "18px" }}>Informações do seu trajeto</Typography>
                                </Grid>
                                <TextField
                                    style={{ marginBottom: "10px" }}
                                    id="filled-multiline-flexible"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    onChange={(e) => setDescricao(e.target.value)}
                                    multiline
                                    maxRows={4}
                                    label="Justificativa/Descrição"
                                    placeholder="Viagem para..."
                                />
                                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="veiculo-select-label">Veículo utilizado</InputLabel>
                                        <Select
                                            labelId="veiculo-select-label"
                                            id="veiculo-select"
                                            value={tipoveiculo}
                                            onChange={(e) => setTipoveiculo(e.target.value)}
                                            label="Veículo utilizado"
                                        >
                                            {Object.entries(EnumTipoVeiculo.categoria).map(([key, value]) => (
                                                <MenuItem key={value} value={value}>
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="combustivel-select-label">Tipo de Combustível</InputLabel>
                                        <Select
                                            labelId="combustivel-select-label"
                                            id="combustivel-select"
                                            value={tipocombustivel}
                                            onChange={(e) => setTipocombustivel(e.target.value)}
                                            label="Tipo de Combustível"
                                        >
                                            {Object.entries(EnumTipoVeiculo.combustivel).map(([key, value]) => (
                                                <MenuItem key={value} value={value}>
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="porte-select-label">Porte do veículo</InputLabel>
                                        <Select
                                            labelId="porte-select-label"
                                            id="porte-select"
                                            value={tipoporte}
                                            onChange={(e) => setTipoporte(e.target.value)}
                                            label="Porte do veículo"
                                        >
                                            {Object.entries(EnumTipoVeiculo.porte).map(([key, value]) => (
                                                <MenuItem key={value} value={value}>
                                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', textAlign: "center", marginTop: "15px", justifyContent: "center" }}>
                                    <GoogleIcon />
                                    <span style={{ marginLeft: 1, fontFamily: "sans-serif", fontWeight: "500" }}>oogle maps oficial</span>
                                </Grid>
                                <Box sx={{ flexGrow: 1 }} />
                                <Grid container item xs={12} spacing={1} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px", position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: "white", padding: "10px" }}>
                                    <Grid item xs={6}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="info"
                                            fullWidth
                                            onClick={() => setType(0)}
                                        >
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={handleClickCriar}
                                        >
                                            Criar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </>
                ) : (
                    <>
                        <Grid container style={{ width: '100%', height: 'calc(100vh)', position: 'absolute', marginTop: "-60px" }}>
                            <Snackbar
                                open={log}
                                autoHideDuration={2000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity={typeLog} variant="filled" style={{ fontFamily: "sans-serif", padding: "2px 8px 2px", fontWeight: "600" }} sx={{ width: '100%' }}>
                                    {message}
                                </Alert>
                            </Snackbar>
                            {!start && !finalized ? (
                                    <Grid item xs={12} style={{ zIndex: 1, justifyContent: "left", marginLeft: "20px", height: "20px" }}>
                                        <Button
                                            variant="contained"
                                            color="info"
                                            onClick={() => setTipoHodoOk(false)}
                                        >
                                            Voltar
                                        </Button>
                                    </Grid>
                                ) : (null)}
                            {/* Mapa deve preencher todo o diálogo */}
                            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                <GoogleMap
                                    ref={mapRef}
                                    center={currentLocation || center}
                                    zoom={finalized ? totalDistance * 11 : 17}
                                    mapContainerStyle={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                                    onLoad={onLoad}
                                    options={{
                                        streetViewControl: false,
                                        mapTypeControl: false,
                                        zoomControl: false,
                                        fullscreenControl: false,
                                    }}
                                >
                                    {path.length > 1 && (
                                        <Polyline
                                            path={path}
                                            options={{
                                                strokeColor: primary.main,
                                                strokeOpacity: 10,
                                                strokeWeight: 8,
                                            }}
                                        />
                                    )}
                                    {snappedCoordinates.length > 0 && (
                                        <Polyline
                                            path={snappedCoordinates}
                                            options={{
                                                strokeColor: primary.darker,
                                                strokeWeight: 10,
                                                strokeOpacity: 2,
                                            }}
                                        />
                                    )}
                                    {currentLocation && (
                                        <Marker
                                            position={currentLocation}
                                            icon={{
                                                path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
                                                scale: 7,
                                                fillColor: primary.main,
                                                fillOpacity: 1,
                                                strokeWeight: 2,
                                                strokeColor: "white",
                                            }}
                                        />
                                    )}
                                    {/* Seus componentes do Mapa como Autocomplete, Polyline, Marker */}
                                </GoogleMap>
                            </div>

                            {/* Fundo escuro com opacidade para sobrepor ao mapa e destacar o botão */}
                            <Grid item xs={12} style={{ position: 'absolute', bottom: 0, width: "-webkit-fill-available", background: 'rgba(0, 0, 0, 0)', padding: "20px" }}>
                                {loading && (
                                    <LinearProgress color="success" style={{ width: '100%' }} />
                                )}
                                {finalized ? (
                                    <Grid container item xs={12} justifyContent="center" style={{ padding: '20px', backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <DirectionsCarIcon fontSize="large" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    DISTÂNCIA
                                                </Typography>
                                                <Typography variant="h6">
                                                    {totalDistance.toFixed(1)} km
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    DURAÇÃO
                                                </Typography>
                                                <Typography variant="h6">
                                                    {minutes} min
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    TOTAL EM REAIS
                                                </Typography>
                                                <Typography variant="h6">
                                                    {totalGanho.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                    fullWidth
                                                    disabled={loading}
                                                    onClick={() => handleClickCreate()}
                                                >
                                                    Adicionar Trajeto
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container item xs={12} justifyContent="center" style={{ padding: '20px', backgroundColor: "#FFFFFF", borderRadius: "10px", zIndex: 2 }}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item>
                                                <DirectionsCarIcon fontSize="large" />
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    DISTÂNCIA
                                                </Typography>
                                                <Typography variant="h6">
                                                    {totalDistance} km
                                                </Typography>
                                            </Grid>
                                            <Grid item xs>
                                                <Typography variant="caption" display="block" gutterBottom>
                                                    DURAÇÃO
                                                </Typography>
                                                <Typography variant="h6">
                                                    {minutes} min
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item xs>
                      <Typography variant="caption" display="block" gutterBottom>
                        PRICE
                      </Typography>
                      <Typography variant="h6">
                        $25.00
                      </Typography>
                    </Grid> */}
                                        </Grid>
                                        {start ? (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => handleClickFinalized()}
                                            >
                                                Finalizar Trajeto
                                            </Button>
                                        ) : (
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => setStart(true)}
                                            >
                                                Iniciar Trajeto
                                            </Button>
                                        )}
                                        {start ? (
                                            <>
                                                <DialogComponent
                                                    title={"Cancelar trajeto"}
                                                    open={openCancelarTrajeto}
                                                    handleClose={() => setOpenCancelarTrajeto(false)}
                                                    body={
                                                        <Grid container item xs={12}>
                                                            <Typography>Ao cancelar seu trajeto, não poderá ser visto, será apagado, deseja realmente cancelar seu trajeto?</Typography>
                                                        </Grid>
                                                    }
                                                    action={
                                                        <Grid>
                                                            <Button size="large" onClick={() => setOpenCancelarTrajeto(false)}>Cancelar</Button>
                                                            <Button size="large" onClick={() => clearPolylines()}>Confimar</Button>
                                                        </Grid>
                                                    }
                                                    width={"sm"}
                                                />
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    color="info"
                                                    fullWidth
                                                    onClick={() => setOpenCancelarTrajeto(true)}
                                                >
                                                    Cancelar
                                                </Button>
                                            </>
                                        ) : (
                                            null
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
        </>
    ) : (
        <div>
            <Typography style={{ fontWeight: "600", fontSize: "20px" }}>
                Carregando...
            </Typography >
        </div >
    );
}