import {
  Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme
} from "@material-ui/core";
import { EditRounded } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./styles";

function CentroCustoGRID(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery("(maxWidth:480px)");
  const matches2 = useMediaQuery(
    "(maxWidth:900px) and (orientation: landscape)"
  );
  const matches3 = useMediaQuery(
    "(minWidth:600px) and (orientation: portrait)"
  );
  const centroCusto = props.data;
  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHead}>
              <TableCell className={classes.heads} align="center"></TableCell>
              <TableCell className={classes.heads} align="center">
                ID
              </TableCell>
              <TableCell className={classes.heads} align="center">
                Nome
              </TableCell>
              <TableCell className={classes.heads} align="center">
                Código
              </TableCell>
              {!matches && !matches2 && !matches3 ? (
                <TableCell className={classes.heads} align="center">
                  Orçamento Restante
                </TableCell>
              ) : null}
              {!matches && !matches2 && !matches3 ? (
                <TableCell className={classes.heads} align="center">
                  Status
                </TableCell>
              ) : null}
              {!matches && !matches2 && !matches3 ? (
                <TableCell className={classes.heads} align="center">
                  Impedir Excedência
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.todosCentros.map((item) => (
              <TableRow className={classes.tableBody} key={item.idcentrocusto}>
                <TableCell className={classes.tableCell} align="center">
                  <IconButton
                    onClick={() => props.handleCadastroOpen(item, true)}
                    margin="small"
                  >
                    <EditRounded fontSize="default" />
                  </IconButton>
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {item.idcentrocustotenant}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {item.nome}
                </TableCell>
                <TableCell className={classes.tableCell} align="center">
                  {item.codigo}
                </TableCell>
                {!matches && !matches2 && !matches3 ? (
                  <TableCell className={classes.tableCell} align="center">
                    {item.orcamento}
                  </TableCell>
                ) : null}
                {!matches && !matches2 && !matches3 ? (
                  <TableCell className={classes.tableCell} align="center">
                    {item.ativo ? "Ativo" : "Inativo"}
                  </TableCell>
                ) : null}
                {!matches && !matches2 && !matches3 ? (
                  <TableCell className={classes.tableCell} align="center">
                    {item.necessitaautorizacao ? "Sim" : "Não"}
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default CentroCustoGRID;
