import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    button: {
        background: '#c62828',
        color: '#ffffff',
        '&:hover': { background: '#b91119', }
    },

    mainGrid: {
        marginTop: 3
    },

    chartGrid: {
        // height: '20rem',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'

    },

    gridCard: {
        marginTop: '5px',
        display: 'flex',
        justifyContent: 'center'
    },

    gridCardMain: {
        padding: "20px"
    },

    gridCardValues: {
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
        margin: "10px",
        padding: "15px",
        borderRadius: "3px",
    },

    paperCardGrid: {
        border: '2px solid black',
        borderRadius: '20px',
        padding: '7px',
        width: '25rem'
    },

    groupButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    buttonSelected: {
        background: '#C62828',
        color: '#ffffff',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        }
    },

    buttonUnselected: {
        background: '#ffffff',
        color: '#C62828',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        }
    },

    buttonTypo: {
        fontSize: 11,
    },

    typography_G: {
        color: '#70AD47',
        fontWeight: 'bold',
    },
    typography_O: {
        color: '#FF3300',
        fontWeight: 'bold',
    },
    typography_P: {
        color: '#FFC000',
        fontWeight: 'bold',
    },

    dot_G: {
        height: '7px',
        width: '7px',
        backgroundColor: '#70AD47',
        borderRadius: '50%',
        display: 'inline-block'
    },
    dot_O: {
        height: '7px',
        width: '7px',
        backgroundColor: '#FF3300',
        borderRadius: '50%',
        display: 'inline-block'
    },
    dot_P: {
        height: '7px',
        width: '7px',
        backgroundColor: '#FFC000',
        borderRadius: '50%',
        display: 'inline-block'
    },
}));
