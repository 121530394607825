import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import Dashboard from '../../../../dashboard';
import { EditRounded, SaveOutlined, CloseOutlined } from '@material-ui/icons';
import { Table, useMediaQuery, TableHead, TableRow, TableCell, Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent, TableBody, IconButton, Paper, useTheme } from '@material-ui/core';
import cookie from 'universal-cookie';
import CadastroConfiguracaoPermissaoUsuario from '../cadastro';
import usuarioRepositorio from '../../../../../repositorios/usuario';
import permissaoRepositorio from '../../../../../repositorios/permissao';
import Snackbar from '../../../../../components/snackbar';

export const ListaConfiguracaoPermissaoUsuario = props => {
    const classes = useStyles();
    const cookies = new cookie();
    const [validacao, setValidacao] = useState({ visible: false });
    const [mensagemValidacao, setMensagemValidacao] = useState('');
    const [mensagemVariant, setMensagemVariant] = useState('success');
    const theme = useTheme();
    const [usuarios, setUsuarios] = useState([]);
    const [permissao, setPermissao] = useState({});
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [cadastroOpen, setCadastroOpen] = useState(false);

    const handleClickSalvar = async () => {
        const response = await permissaoRepositorio.salvar(permissao);
        if (response.status !== 204) {
            setMensagemValidacao('Erro ao salvar!');
            setMensagemVariant('error');
            setValidacao({ visible: true });
            return;
        }
        setMensagemValidacao('Dados salvos com sucesso!');
        setMensagemVariant('success');
        setValidacao({ visible: true });
        setCadastroOpen(false);
    }

    const handleClickEditar = async item => {
        const response = await permissaoRepositorio.getUsuarioPermissao(item.idusuario);
        setPermissao(response.data);
        setCadastroOpen(true);
    }

    const handleCadastroOpen = (item, visible) => {
        setPermissao(item);
        setCadastroOpen(visible);
    }

    useEffect(() => {
        usuarioRepositorio.getAll().then(response => {
            setUsuarios(response.data);
        });
    }, [])
    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: mensagemVariant, message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <Dialog
                className={classes.cadastro}
                open={cadastroOpen}
                onClose={e => setCadastroOpen(false)}
                fullScreen={fullscreen}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div position="static" className={classes.appbar}>
                        <Typography variant="h6" className={classes.title}>Permissão Usuário</Typography>
                        <IconButton className={classes.fechar} onClick={e => setCadastroOpen(false)}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className={classes.permissaocontent}>
                    <CadastroConfiguracaoPermissaoUsuario {...{ permissao: permissao, setPermissao: setPermissao }} />
                </DialogContent>
                <DialogActions id="action5">
                    <Button className={classes.button} onClick={handleClickSalvar}>
                        <SaveOutlined />
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
            <Paper elevation={5}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.heads} align="center"></TableCell>
                            {!fullscreen ? (<TableCell className={classes.heads} align="center">ID</TableCell>) : null}
                            <TableCell className={classes.heads} align="center">Usuário</TableCell>
                            {!fullscreen ? (<TableCell className={classes.heads} align="center">Grupo</TableCell>) : null}
                            <TableCell className={classes.heads} align="center">Ativo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usuarios.map(item => (
                            <TableRow className={classes.row}>
                                <TableCell className={classes.bodys} align="center" >
                                    <IconButton onClick={() => handleClickEditar(item)} margin="small">
                                        <EditRounded fontSize="default" />
                                    </IconButton>
                                </TableCell>
                                {!fullscreen ? (<TableCell className={classes.bodys} align="center">{item.idusuariotenant}</TableCell>) : null}
                                <TableCell className={classes.bodys} align="center">{item.usuario}</TableCell>
                                {!fullscreen ? (<TableCell className={classes.bodys} align="center">{item.privilegio}</TableCell>) : null}
                                <TableCell className={classes.bodys} align="center">{item.ativo ? 'Sim' : 'Não'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
};

export const Lista = props => {
    const classes = useStyles();

    return (
        <div>
            <Dashboard {...props} component={ListaConfiguracaoPermissaoUsuario} />
        </div>
    )
};
export default Lista
