import { Avatar, Box, Button, Checkbox, Chip, Grid, IconButton, ListItemAvatar, ListItemText, Paper, Stack, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from "@mui/material"
import { useStyles } from "./style";
import { getTipoDespesaForNumber } from "../../../../../mock/tiposdespesas";
import { ImageComponent } from "../../../../../components/ImageComponent";
import { CreationDate, IniciadoEm } from "../../sections";
import CallMadeIcon from '@mui/icons-material/CallMade';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DialogComponent from "../../sections/dialogs-desktop";
import { EditarDespesaComponent } from "../../minhasviagens/Viagem/EditarDespesa";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import BuildIcon from '@mui/icons-material/Build';
import { useCallback, useEffect, useRef, useState } from "react";
import { ArrowUpward, MarkEmailRead } from "@mui/icons-material";
import { EnumTipo } from "../../../../../enums/enumPrestacaoDeContas";
import { aprovarDespesa_CONTROLADOR_Mock, auditarValorTotalDespesa_Mock, cancelarEnvioDeMensagem_CONTROLADORIA_Mock, cancelarEnvioDeMensagem_Mock, enviarParaAuditor_Mock, enviarParaPessoa_CONTROLADOR_Mock, retornarDespesaParaViajante_Mock } from "../../../../../mock/prestacaodecontas";
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { DeskCardPersonExpanses } from "../../../../../components/card_person_expanses";
import { GoogleMap, Polyline, useJsApiLoader } from "@react-google-maps/api";
import { primary } from "../../../../../theme/palette";
import { ViewHodometerExpansesComponent } from "../../../../../components/autalii_expanses/viewhodometer_expanses";
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../../../components/uloadervoo";


export const ControladoriaDespesasComponent = ({ viagem, handleClickEditDespesa, getViagensReload }) => {
    const classes = useStyles();
    const [despesa, setDespesa] = useState({ total: 0 });
    const [openEdit, setOpenEdit] = useState(false);
    const [descricao, setDescricao] = useState("")
    const [openReturnDespesa, setOpenReturnDespesa] = useState(null)
    const [totalGasto, setTotalgasto] = useState(despesa?.total);
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [despesas, setDespesas] = useState([])
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const getDespesas = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas)
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickReturn = (despesa) => {
        setDespesa(despesa)
        setOpenReturnDespesa(true)
    }

    const handleOpenEdit = (value) => {
        setDespesa(value)
        setOpenEdit(true)
    }

    const handleClickOpenHodometro = (despesa) => {
        setDespesa(despesa)
        setOpenInfoHodometro(true)
    }

    const handleClickAuditar = async () => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.putAuditarValor(viagem.id, despesa.iddespesa, totalGasto);
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Valor auditado com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao auditar a despesa.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickPessoaDespesa = () => {
        enviarParaPessoa_CONTROLADOR_Mock(viagem.id, descricao, despesa.id)
        setOpenReturnDespesa(false)
    }

    const handleClickAprovar = async (item) => {
        setLoading(true)
        try {
            await prestacaoDeContasRepositorio.postAprovarDespesas(viagem.id, [item.iddespesa])
            getDespesas()
            getViagensReload()
            enqueueSnackbar('Aprovada com sucesso.', { variant: 'success' });
        } catch {
            enqueueSnackbar('Ocorreu um erro ao tentar aprovar despesa(s).', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }
    const handleClickCancelarEnvioDeMensagem = (iddespesa) => {
        cancelarEnvioDeMensagem_CONTROLADORIA_Mock(viagem.id, iddespesa, EnumTipo.viagemstatus.viagemfinalizada);
        setOpenReturnDespesa(false)
    }

    useEffect(() => {
        getDespesas()
    }, [])

    return (
        <>
            {despesa != null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                Retornar Despesa
                            </>
                        }
                        open={openReturnDespesa}
                        handleClose={() => setOpenReturnDespesa(false)}
                        body={
                            <Grid container item xs={12} style={{ width: "400px" }}>
                                <Typography style={{ marginTop: "15px", marginBottom: "15px" }}>Ao enviar para a pessoa ela poderá modificar a despesa.</Typography>
                                <TextField
                                    id="filled-multiline-flexible"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    defaultValue={despesa?.mensagemcontrolador != null ? despesa?.mensagemcontrolador : ""}
                                    onChange={(e) => setDescricao(e.target.value)}
                                    multiline
                                    maxRows={4}
                                    label="Justificativa/Descrição"
                                    placeholder="Tem algo de errado com..."
                                />
                            </Grid>
                        }
                        action={
                            <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                                {/* {despesa?.mensagem != "" ? (
                            <Button color="error" onClick={() => handleClickCancelarEnvioDeMensagem(despesa?.id)}>Cancelar</Button>
                        ) : (
                            null
                        )} */}
                                <Button color="primary" disabled={descricao != "" ? false : true} onClick={() => handleClickPessoaDespesa(despesa?.id)}>Pessoa</Button>
                            </Grid>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Informações do Hodômetro
                            </>
                        }
                        open={openInfoHodometro}
                        handleClose={() => setOpenInfoHodometro(false)}
                        body={
                            <ViewHodometerExpansesComponent despesa={despesa} />
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />
                </>
            )}
            {/* <DialogComponent
                title={
                    <>
                        Aprovar Despesa
                    </>
                }
                open={openReturnDespesa}
                handleClose={() => setOpenReturnDespesa(false)}
                body={
                    <Grid container item xs={12} style={{ width: "400px" }}>
                        <Typography style={{ marginTop: "15px", marginBottom: "15px" }}>Ao enviar de volta para a pessoa, ela poderá corrigir a despesa de acordo com a sua descrição.</Typography>
                        <TextField
                            id="filled-multiline-flexible"
                            variant="outlined"
                            fullWidth
                            required
                            defaultValue={despesa?.mensagem}
                            onChange={(e) => setDescricao(e.target.value)}
                            multiline
                            maxRows={4}
                            label="Justificativa/Descrição"
                            placeholder="Tem algo de errado com..."
                        />
                    </Grid>
                }
                action={
                    <Grid container item xs={12} style={{ display: "flex", textAlign: "right", justifyContent: "right" }}>
                        <Button color="primary" disabled={descricao != "" ? false : true} onClick={() => handleClickPessoaDespesa(despesa?.id)}>Pessoa</Button>
                    </Grid>
                }
                width={"sm"}
            /> */}
            <ULoaderVoo isLoading={loading} />
            <Grid container item xs={12} style={{ width: "600px" }}>
                <Grid item xs={12} container style={{ textAlign: "center" }}>
                    <Grid item xs={12} container style={{ marginTop: "0px", marginBottom: "10px" }}>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <IniciadoEm datainicio={viagem.datainicio} />
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", }}>
                            <Grid style={{ marginLeft: "auto" }}>
                                <CreationDate data={viagem.datadecriacao} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
                            <DeskCardPersonExpanses viagem={viagem} despesas={despesas} />
                        </Grid>
                    </Grid>
                    {despesas?.filter(item => item.controladoria === true).map((item) => (
                        <>
                            <Grid
                                item xs={12}
                                className={classes.card}
                                container
                            >
                                {item.hodometro ? (
                                    <>
                                        <Grid container item xs={12} style={{ padding: "20px" }}>
                                            <Grid container item xs={6}>
                                                <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                    <Typography style={{ fontWeight: "600" }}>Hodômetro</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={6} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                <Grid style={{ marginLeft: "auto" }}>
                                                    <CreationDate data={item.datacriacao} />
                                                </Grid>
                                                {item.valor_auditado != 0 ? (
                                                    <>
                                                        <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor_auditado))} color="warning" style={{ marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeIcon />}>
                                                        </Chip>
                                                    </>) : (null)}
                                            </Grid>
                                            <Grid item xs={8} style={{ textAlign: "left" }}>
                                                <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                            </Grid>
                                            <Grid item xs={4} style={{ display: "flex", justifyContent: "right" }}>
                                                <Button
                                                    startIcon={<DirectionsCarIcon />}
                                                    color="secondary"
                                                    onClick={() => handleClickOpenHodometro(item)}
                                                >
                                                    Ver Informações
                                                </Button>
                                            </Grid>
                                            <Grid item xs={4} style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignContent: "flex-start",
                                                alignItems: "flex-start",
                                                flexDirection: "row", marginTop: "auto"
                                            }}>
                                                {getTipoDespesaForNumber(item.tipo, item.produtos.value)}
                                            </Grid>
                                            <Grid item xs={8} style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignContent: "flex-end",
                                                alignItems: "center",
                                                flexDirection: "row"
                                            }}>
                                                {/* {item.mensagemcontrolador != "" && item.mensagemcontrolador != null ? (
                                                    <Button color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                        Mensagem
                                                    </Button>
                                                ) : (
                                                    <Button color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                        Retornar
                                                    </Button>
                                                )} */}
                                                <Grid style={{ display: "column" }}>
                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{item.hodometro.kmtotal} Km - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.hodometro.valor || 0)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid container item xs={10}>
                                            <Grid container item xs={6} style={{ padding: "20px" }}>
                                                <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                    <Typography style={{ fontWeight: "600" }}>Despesa</Typography>
                                                </Grid>
                                                <Grid container item xs={12} marginTop={5}>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={6} style={{ display: "flex", justifyContent: "right", textAlign: "right", padding: "20px" }}>
                                                <Grid style={{ marginLeft: "auto" }}>
                                                    <CreationDate data={item.datacriacao} />
                                                </Grid>
                                                {item.valor_auditado != 0 ? (
                                                    <>
                                                        <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor_auditado))} color="warning" style={{ marginTop: "-14px", marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeIcon />}>
                                                        </Chip>
                                                    </>) : (null)}
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: "left", padding: "20px", marginTop: "-70px" }}>
                                                <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                <br></br>
                                            </Grid>
                                            <Grid item xs={5} style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignContent: "flex-start",
                                                alignItems: "flex-start",
                                                flexDirection: "row",
                                                padding: "30px 20px 0px 20px"
                                            }}>
                                                {item.despesa_desc}
                                            </Grid>
                                            <Grid item xs={7} style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignContent: "flex-end",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                padding: "10px 20px 10px 0px"
                                            }}>
                                                {/* {item.mensagemcontrolador != "" && item.mensagemcontrolador != null ? (
                                                    <Button color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<MarkEmailRead />}>
                                                        Mensagem
                                                    </Button>
                                                ) : (
                                                    <Button color="warning" style={{ marginTop: "-5px", marginLeft: "5px" }} onClick={() => handleClickReturn(item)} startIcon={<AssignmentReturnIcon />}>
                                                        Retornar
                                                    </Button>
                                                )} */}

                                                <Button color="secondary" style={{ marginTop: "-5px", marginRight: "3px", borderRadius: "20px" }} onClick={() => handleOpenEdit(item)} startIcon={<BuildIcon />}>
                                                    Corrigir
                                                </Button>

                                                <DialogComponent
                                                    title={
                                                        <>
                                                            Controladoria
                                                        </>
                                                    }
                                                    open={openEdit}
                                                    handleClose={() => setOpenEdit(false)}
                                                    body={
                                                        <>
                                                            <Grid item xs={12} style={{ padding: "5px" }}>
                                                                <TextField
                                                                    fullWidth
                                                                    id="outlined-number"
                                                                    label="Total gasto"
                                                                    defaultValue={despesa.total}
                                                                    onChange={(e) => setTotalgasto(e.target.value)}
                                                                    type="number"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                                                                <Button variant="contained" color="primary" autoFocus onClick={() => handleClickAuditar()}>
                                                                    Auditar Total
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    }
                                                    action={
                                                        <>
                                                        </>
                                                    }
                                                    width={"sm"}
                                                />
                                                <Grid style={{ display: "column" }}>
                                                    <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}</Typography>
                                                </Grid>
                                            </Grid>
                                            {/* Precisa encaixar os tamanhos das fonts e adicionar as fotos e mais despesas */}
                                        </Grid>
                                        <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <ImageComponent width={100} height={148} src={item.comprovante} alt={"Imagem não encontrada"} />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: "0px", textAlign: "left", padding: "20px" }}>
                                <Typography style={{ fontWeight: "600" }}>Mensagem Do Auditor:</Typography>
                                <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.mensagem}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: "0px", textAlign: "right", padding: "20px" }}>
                                <Button variant="contained" color="secondary" style={{ marginTop: "-5px", marginRight: "3px", borderRadius: "20px" }} onClick={() => handleClickAprovar(item)} startIcon={<AddModeratorIcon />}>
                                    Aprovar
                                </Button>
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}