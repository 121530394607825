import {
  AppBar,
  Button,
  Container,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Close } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogTerms(props) {
  const classes = useStyles();
  const file =
    "https://res.cloudinary.com/dwkxt4am5/image/upload/v1673958068/Contratos/Lamoda.pdf";

  return (
    <Dialog
      open={props.openDialog}
      onClose={props.handleCloseDialog}
      fullScreen={props.responsive}
      TransitionComponent={Transition}
      maxWidth="md"
      fullWidth
    >
      <AppBar position="relative" style={{ background: "none" }}>
        <Toolbar className={classes.appbar}>
          <Typography>Contrato de Prestação de Serviço Linestur Digital</Typography>
          <IconButton onClick={props.handleCloseDialog}>
            <Close className={classes.iconButton} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Container style={{ minHeight: "60vh" }} className={classes.container}>
          <iframe className={classes.embed} src={file} title="termos"></iframe>
        </Container>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={props.handleCloseDialog}
          color="primary"
          className={classes.button}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogTerms;
