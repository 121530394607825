import React from 'react';
import Typography from '@material-ui/core/Typography';
import "../../../../../fonts/fonts.css"
// Importe TodayIcon se você precisar usá-lo
// import TodayIcon from '@material-ui/icons/Today';

function FormattedDate(props) {
  // Função para formatar a data no formato "2 Jan"
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short' };
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', options);
  };

  // Formatar datas de checkin e checkout
  const formattedCheckin = formatDate(props.checkin);
  const formattedCheckout = formatDate(props.checkout);

  return (
    <Typography style={{ color: "white", lineHeight: "24px", display: "inline-flex", alignItems: "center", fontFamily: "HindBold" }}>
      {/* Descomente a linha abaixo se quiser usar o ícone */}
      {/* <TodayIcon style={{ marginRight: "5px" }} /> */}
      {`${formattedCheckin} - ${formattedCheckout}`}
    </Typography>
  );
}

export default FormattedDate;


