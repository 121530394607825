import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 40px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    margin: 0,
  },

  title: {
    fontSize: "25px",
  },

  listItemCustom: {
    maxWidth: '95%',
    border: '2px solid #c62828',
    borderRadius: '10px',
    margin: '8px',
    padding: '8px',
  },

  gridTitle: {
    textAlign: 'center',
  },

  listItemPax: {
    paddingLeft: '4px',
    paddingRight: '4px'
  },

  gridButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  button: {
    maxWidth: '150px',
    background: '#c62828',
    color: '#fff',
    '&:hover': {
      background: '#b91119'
    }
  },

  // boxPage: {
  //   width: '100%',
  //   paddingTop: 0,
  //   ['@media (maxWidth:480px)']: {
  //     padding: theme.spacing(0),
  //     marginBottom: theme.spacing(3)
  //   },
  //   ['@media (maxWidth:900px) and (orientation: landscape)']: {
  //     padding: theme.spacing(0)
  //   },
  //   ['@media (minWidth:600px) and (orientation: portrait)']: {
  //     padding: theme.spacing(0),
  //   },
  // },

}));
