import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const pessoa_documentosRepositorio = {

    postDocumentoPassaporte_pessoa: async (dto) => {
        const response = await api.post('/pessoa/passaporte/create', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getDocumentos_pessoa: async (idpessoa) => {
        const response = await api.get('/pessoa/getDocuments/' + idpessoa, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    deletePassaporte: async (idpessoa) => {
        const response = await api.put('/pessoa/passaporte/delete/' + idpessoa, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    }
}

export default pessoa_documentosRepositorio;
