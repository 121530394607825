import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LinesTextField from '../../../../components/LinesTextField';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));

const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
};

function EmailChipInput({ setEmailsCotar, emailsCotar, fullScreen }) {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [emailChips, setEmailChips] = useState([]);

    const handleDelete = (chipToDelete) => () => {
        setEmailChips((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const addEmailChip = (emailToAdd) => {
        if (isValidEmail(emailToAdd)) {
            const newEmailChip = { key: emailChips.length, label: emailToAdd };
            const newEmail = emailToAdd;
            setEmailChips([...emailChips, newEmailChip]);
            setEmailsCotar([...emailsCotar, newEmail])
            setEmail('');
        } else {
            alert('Por favor, insira um endereço de e-mail válido.');
        }
    };

    const handleAddDown = () => {
        addEmailChip(email);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addEmailChip(email);
        }
    };

    return (
        <Grid container item xs={12}>
            <Grid item xs={fullScreen ? 10 : 8}>
                <LinesTextField
                    label="Enviar para / Email"
                    value={email}
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    variant="outlined"
                    margin="normal"
                />
            </Grid>
            <Grid item xs={fullScreen ? 2 : 4} style={{ alignItems: "center", display: "flex", padding: "10px" }}>
                {/* <IconButton onClick={() => handleAddDown()}>
                    <AddCircleIcon />
                    Adicione Tarifa
                </IconButton> */}
                {fullScreen ? (
                    <IconButton style={{
                        background: '#FFFFFF',
                        color: '#c62828',
                        marginTop: "5px",
                        '&:hover': { background: '#b91119', }
                    }}onClick={() => handleAddDown()}>
                        <AddCircleIcon />
                    </IconButton>
                ) : (
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            background: '#c62828',
                            color: '#ffffff',
                            marginTop: "5px",
                            '&:hover': { background: '#b91119', }
                        }}
                        onClick={() => handleAddDown()}
                        endIcon={<AddCircleIcon></AddCircleIcon>}
                    >
                        Adicionar
                    </Button>
                )}
            </Grid>
            {emailChips.length != 0 ? (
                <Grid item xs={12}>
                    {emailChips.map((data) => {
                        return (
                            <Chip
                                label={data.label}
                                onDelete={handleDelete(data)}
                                className={classes.chip}
                            />
                        );
                    })}
                </Grid>
            ) : (null)}
        </Grid>
    );
}

export default EmailChipInput;
