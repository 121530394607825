import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {

    },
    appbar: {
        background: '#616161',
    },
    button: {
        color: '#fff'
    },
    buttonSave: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },
    large: {
        cursor: 'pointer',
        width: theme.spacing(8),
        height: theme.spacing(8),
        background: '#c62828',
    },
}));
