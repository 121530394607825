
import OrderListView from "./tabelaViagens/custom-order-list"
import { themepalette } from "../../../../theme/palette"
import { editarDespesa_Mock, todasviagens, viagensdousuario } from "../../../../mock/prestacaodecontas";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import IntegrationNotistack, { useCustomSnackbar } from "../../../../components/snackbar2/NewSnackBar";
import prestacaoDeContasRepositorio from "../../../../repositorios/prestacaodecontas";
import ULoaderVoo from "../../../../components/uloadervoo";
import { Button } from "@mui/material";

export const Auditor = ({ permissions }) => {
    const [viagens, setViagens] = useState([]);
    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    const handleClickEditDespesa = (despesa) => {
        editarDespesa_Mock(despesa)
    }

    const getAllViagens = async () => {
        setLoading(true)
        try {
            let model = {
                data_inicial: new Date(new Date().getFullYear(), 0, 1), // Início do ano: 1º de janeiro do ano atual
                data_final: new Date(new Date().getFullYear(), 11, 31),
                id_pessoa: 0,
                id_centrocusto: 0,
                minhasviagens: false
            }

            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model); 
            setViagens(response.viagens)
        } catch {
            enqueueSnackbar('Erro ao obter as viagens.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickFilter = async (filters) => {
        setLoading(true)
        try {
            let model = {
                data_inicial: filters.startDate != null ? new Date(filters.startDate) : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                data_final: filters.endDate != null ? new Date(filters.endDate) : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                id_pessoa: filters.idpessoa,
                id_centrocusto: filters.centrodecusto,
                minhasviagens: false
            }

            const response = await prestacaoDeContasRepositorio.getAllViagensUser(model);
            setViagens(response.viagens)
        } catch {
            enqueueSnackbar('Erro ao filtrar as viagens.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllViagens()
    }, [])

    return (
        <div style={{ backgroundColor: "#FFFFFF" }}>
            <ULoaderVoo isLoading={loading} />
            <OrderListView permissions={permissions} data={viagens} handleClickFilter={handleClickFilter} handleClickEditDespesa={handleClickEditDespesa} getViagensReload={getAllViagens} />
        </div>
    )
}