import { Avatar, Box, Button, Chip, Grid, IconButton, List, ListItem, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { RemoveRedEyeOutlined, SendOutlined } from '@material-ui/icons';
import React, { useEffect } from 'react';
import Enums from '../../../../enums';
import DeleteIcon from '@material-ui/icons/Delete';
import permissaoRepositorio from '../../../../repositorios/permissao';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    inline: {
        display: 'inline',
    },
}));

function DespesasList(props) {
    const [arrayVerifyStatus, setArrayVerifyStatus] = React.useState({
        item1: true,
        item2: true,
        item3: true,
        item4: true,
        item5: true,
        item6: true,
    })

    const { data } = props;
    const classes = useStyles();

    const handleClickEditar = (idviagem, idpessoa) => {
        props.onClickEdit(idviagem, idpessoa);
    }

    const handleClickModalAccept = (id) => {
        props.onClickModalAccept(id);
    }

    const verifyStatus = (permissao) => {
        if (permissao.permitecontroletotaldespesasviagem) {
            setArrayVerifyStatus({
                ...arrayVerifyStatus,
                item1: false,
                item2: false,
                item3: false,
                item4: false,
                item6: false
            })
        } else if (permissao.permitesercontabilidade) {
            setArrayVerifyStatus({
                ...arrayVerifyStatus,
                item1: false,
                item2: false,
            })
        } else if (permissao.permitesercontroladoriaauditoria) {
            setArrayVerifyStatus({
                ...arrayVerifyStatus,
                item1: false,
                item3: false,
            })
        } else if (permissao.permiteserfinanceiro) {
            setArrayVerifyStatus({
                ...arrayVerifyStatus,
                item1: false,
                item4: false,
                item6: false
            })
        } else {
            setArrayVerifyStatus({
                ...arrayVerifyStatus,
                item1: false,
            })
        }
    }

    useEffect(() => {
        (async () => {
            await permissaoRepositorio.getPorSessao().then(res => verifyStatus(res.data))
        })()
    }, [props.data])

    return (
        <List className={classes.root}>
            {data.map(item => (
                <>
                    <ListItem divider key={item.idviagem}>

                        <Grid container>
                            <Grid item xs={12} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                <Typography variant='h6' style={{ fontSize: '14pt' }}>
                                    {item.titulo}
                                </Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={3} style={{ textAlign: '-webkit-center' }}>
                                    <Typography>
                                        {`  ${item.idviagemtenant} `}
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Chip
                                        label={Enums.getDespesaStatus(item.status)}
                                        size="small"
                                        style={{
                                            color: "#FFFFFF",
                                            background: Enums.getDespesaCor(item.status),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                <Grid item xs={3} style={{ textAlign: '-webkit-center', alignSelf: 'center' }}>
                                    <Avatar style={item.status === 5 || item.status === 6 || arrayVerifyStatus[`item${item.status}`] === false ? { background: '#c62828', cursor: 'pointer' } : null} alt="Remy Sharp">
                                        <IconButton style={{ color: '#FAFAFA' }} onClick={() => handleClickEditar(item.idviagem, item.idpessoa)} disabled={item.status === 5 || item.status === 6 ? false : arrayVerifyStatus[`item${item.status}`]}>
                                            <RemoveRedEyeOutlined />
                                        </IconButton>
                                    </Avatar>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        Viajante: {`${item.usuario.pessoa.nome} ${item.usuario.pessoa.sobrenome}`}
                                        <br />
                                        Data de criação:{` ${new Date(item.datacriacao).toLocaleDateString()}`}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} style={{ paddingTop: '5px', paddingBottom: '5px', alignItems: 'center' }}>
                                <Grid item xs={3} style={{ textAlign: '-webkit-center' }}>
                                    <Button
                                        disabled={item.status != 1}
                                        onClick={() => props.handleOpenExcluirViagem(item.idviagem)}
                                        variant="outlined"
                                        disableElevation
                                        size="small"
                                        style={{ color: '#808080', borderRadius: "20px", marginTop: "8px" }}
                                    > 
                                        <DeleteIcon style={{ color: '#808080' }} />
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button onClick={() => handleClickModalAccept(item.idviagem)}
                                        disabled={item.status == 5}
                                        variant="outlined"
                                        size="small"
                                        endIcon={<SendOutlined />}
                                        style={{ color: "#c62828", borderRadius: "20px", marginTop: "8px", marginLeft: '10px' }}>
                                        Avançar
                                    </Button>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={{ fontWeight: "600", color: "#242424" }}>{item.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </ListItem>
                </>
            ))
            }
        </List >
    );
}


export default DespesasList;
