import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import Cookie from 'universal-cookie';
import viagemPoliticaDocRepositorio from '../../repositorios/politicaViagemDoc';
import usuarioRepositorio from '../../repositorios/usuario';

export const Auth = props => {
    const history = useHistory();
    const cookies = useMemo(() => new Cookie(), []);

    const cleanCookies = useCallback(() => {
        cookies.remove('token');
        cookies.remove('sessionId');
    }, [cookies]);

    const redirectUnauthorized = useCallback(() => {
        history.push('/');
    }, [history])

    useEffect(() => {
        (async () => {
            try {
                const response = await usuarioRepositorio.isAuthenticated();
                if (response.data) {
                    const teste = await viagemPoliticaDocRepositorio.getStatusConfirmed()
                    if (!teste) {
                        history.push('/PoliticaViagemDoc');
                    }
                    return;
                }
                cleanCookies();
                redirectUnauthorized();

            } catch (e) {
                cleanCookies();
                redirectUnauthorized();
            }
        })()
    }, [history.location.pathname, redirectUnauthorized, cleanCookies])

    return (
        <>
            {props.children}
        </>
    )
};

export default Auth
