import { AppBar, Box, Button, Dialog, DialogContent, Grid, IconButton, Slide, Toolbar, Typography } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import hotelRepositorio from "../../../../repositorios/hotel";
import { useStyles } from "./styles";
import ULoaderVoo from "../../../../components/uloadervoo";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const HotelCancel = (props) => {
    const open = props.open;
    const responsive = props.responsive;
    const booking = props.bookingSelected;
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();

    const handleCloseHotelCancel = () => { props.handleClickCloseHotelCancel() }

    const handleClickCommitCancel = async () => {
        setIsLoading(true);
        try {
            if (booking.idreserva == null) {
                props.setSnackMessage('Não foi encontrado o id.');
                props.setSnackState({ visible: true });
                props.setSnackVariant('error');
                return;
            }
            await hotelRepositorio.postHotelBookingCancel_Lines(booking.idreserva);
            props.handleClickCloseHotelCancel();
        } catch (e) {
            props.setSnackMessage('Falha ao cancelar hotel.');
            props.setSnackState({ visible: true });
            props.setSnackVariant('error');
        } finally {
            props.getBookingList();
            setIsLoading(false);
        }
    };

    return (
        <>
            <ULoaderVoo isLoading={isLoading} />
            <Dialog open={open} onClose={() => handleCloseHotelCancel()} fullWidth maxWidth="sm" fullScreen={responsive} TransitionComponent={Transition}>
                <AppBar position="relative">
                    <Toolbar className={classes.appbar}>
                        <Typography>Confirmação de Cancelamento {booking.idreservatenant}</Typography>
                        <IconButton onClick={() => handleCloseHotelCancel()} ><CloseOutlined className={classes.iconButton} /></IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Grid container style={{ margin: responsive ? '1rem' : '0' }}>
                        <Grid item xs={responsive ? 12 : 12} className={classes.gridCenter}>
                            <Box className={classes.boxCustom} >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography style={{ textAlign: 'center' }} variant="body1"><b>POR FAVOR, ENTRAR EM CONTATO COM A SUA AGENTE DESIGNADA PARA REALIZAR O CANCELAMENTO</b></Typography>
                                        <Typography style={{ textAlign: 'justify' }} variant='body1'>Ao confirmar o cancelamento da reserva, você estará concordando em arcar com eventuais penalidades que possam ser aplicadas pelo hotel, conforme estabelecido nas regras apresentadas durante a criação da reserva. </Typography>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={6}>
                                        {!props.permissaoDashboard.usuarioLinestur === true ? (
                                            null
                                        ) : (
                                            <Button disabled={isLoading} onClick={() => handleClickCommitCancel()} className={classes.buttonEmissao}>Cancelar reserva</Button>
                                        )}
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}
