import api from '../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const cardsRepositorio = {

    getListCards: async () => {
        let response = await api.get('/AereoBilhete/getCards', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    postCard: async (card) => {
        await api.post('/AereoBilhete/saveCard', card, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
    },

    deleteCard: async (idcard) => {
        await api.delete(`AereoBilhete/removeCard/${idcard}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
    },

    getListReservasCard: async (idcard) => {
        let response = await api.get(`AereoBilhete/getIdReservasComCartao/${idcard}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        return response;
    },

    putCard: async (card) => {
        await api.put("/AereoBilhete/editCard", card, {
            headers: {
                Authorization: "Bearer " + cookies.get("token"),
            },
        });
    },



}

export default cardsRepositorio;
