import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import cookie from 'universal-cookie';
import api from '../../../Api';
import LinesTextField from '../../../components/LinesTextField';
import UMaskTextfield from '../../../components/umasktextfield';
import URadioGroup from '../../../components/uradiogroup';
import USearchField from '../../../components/usearchfield';
import USwitch from '../../../components/uswitch';
import { useStyles } from './styles';

export const CadastroEmpresa = props => {
    const classes = useStyles();
    const cookies = new cookie();
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [tipoEmpresa, setTipoEmpresa] = useState((props.empresa.tipoempresa ? props.empresa.tipoempresa.toString() : '2'));
    const [estados, setEstados] = useState([]);
    const [paises, setPaises] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [errocnpjcpf, setErrocnpjcpf] = useState(false);
    const [errocep, setErroCep] = useState(false);
    const [errotelefone, serErroTelefone] = useState(false);
    const [ativo, setAtivo] = useState((props.empresa.ativo ? true : false) || null);
    const [estado, setEstado] = useState((props.empresa.estado ? { label: props.empresa.estado.nome, value: props.empresa.idestado } : { label: '', value: 0 }) || null);
    const [pais, setPais] = useState((props.empresa.pais ? { label: props.empresa.pais.nome, value: props.empresa.idpais } : { label: '', value: 0 }) || null);
    const [cidade, setCidade] = useState((props.empresa.cidade ? { label: props.empresa.cidade.nome, value: props.empresa.idcidade } : { label: '', value: 0 }) || null);
    const tiposEmpresa = [
        {
            item: 'Juridica',
            value: '2'
        }]

    const handleChange = event => {
        props.setEmpresa({
            ...props.empresa,
            [event.target.name]: event.target.value,
        })
    }
    const handleChangeAuto = item => {
        props.setEmpresa({
            ...props.empresa,
            [item.name]: item.value,
        })
    }

    useEffect(() => {
        let estados = [];
        let paises = [];
        let cidades = [];

        api.get('/estado', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            response.data.map(item => {
                estados.push({
                    label: item.nome,
                    value: item.idestado
                })
            })
            setEstados(estados);
        })

        api.get('/pais', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            response.data.map(item => {
                paises.push({
                    label: item.nome,
                    value: item.idpais
                })
            })
            setPaises(paises);
        })

        api.get('/cidade', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            response.data.map(item => {
                cidades.push({
                    label: item.nome,
                    value: item.idcidade
                })
            })
            setCidades(cidades);
        })

    }, [])

    useEffect(() => {
        if (pais) {
            handleChangeAuto({ name: 'idpais', value: pais.value })
        }
    }, [pais])

    useEffect(() => {
        if (cidade) {
            handleChangeAuto({ name: 'idcidade', value: cidade.value })
        }
    }, [cidade])

    useEffect(() => {
        if (estado) {
            handleChangeAuto({ name: 'idestado', value: estado.value })
        }
    }, [estado])

    useEffect(() => {
        if (ativo !== null) {
            handleChangeAuto({ name: 'ativo', value: ativo })
            return
        }
        setAtivo(props.empresa.ativo);
        handleChangeAuto({ name: 'ativo', value: true });
    }, [ativo])

    useEffect(() => {
        if (tipoEmpresa !== null) {
            handleChangeAuto({ name: 'tipoempresa', value: Number(tipoEmpresa) })
        }
    }, [tipoEmpresa])

    return (
        <div className={classes.root}>
            <Grid container item xs={12} spacing={2} style={{ margin: 0 }}>
                {!fullscreen ? (
                    <Grid item xs={12} style={{ display: 'flex' }}>
                        {props.empresa.idempresa ? (
                            <USwitch {...{ checked: ativo, setChecked: setAtivo, label: 'Ativo' }} />
                        ) : (null)}
                        <URadioGroup {...{ value: tipoEmpresa, setValue: setTipoEmpresa, itens: tiposEmpresa }} />
                    </Grid>
                ) : (
                    <Grid container item xs={12}>
                        {props.empresa.idempresa ? (
                            <Grid item xs={12}>
                                <USwitch {...{ checked: ativo, setChecked: setAtivo, label: 'Ativo' }} />
                            </Grid>
                        ) : (null)}
                        <Grid item xs={12}>
                            <URadioGroup {...{ value: tipoEmpresa, setValue: setTipoEmpresa, itens: tiposEmpresa }} />
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={!fullscreen ? 6 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        name='razaosocial'
                        id="teste"
                        label="Razão Social"
                        variant="outlined"
                        value={props.empresa.razaosocial || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 6 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        name='nomefantasia'
                        id="teste"
                        label="Nome fantasia"
                        variant="outlined"
                        value={props.empresa.nomefantasia || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 4 : 12}>
                    <UMaskTextfield
                        helperText={errocnpjcpf ? "Formato de CNPJ incorreto" : ""}
                        error={errocnpjcpf}
                        mask='99.999.999/9999-99'
                        name='cnpjcpf'
                        label='CNPJ'
                        value={props.empresa.cnpjcpf}
                        onChange={handleChange}
                    />

                </Grid>
                <Grid item xs={!fullscreen ? 8 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        name='email'
                        id="teste"
                        label="E-mail"
                        variant="outlined"
                        value={props.empresa.email || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 6 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        name='endereco'
                        id="teste"
                        label="Rua"
                        variant="outlined"
                        value={props.empresa.endereco || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 3 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        name='endereconumero'
                        id="teste"
                        label="Número"
                        variant="outlined"
                        value={props.empresa.endereconumero || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 3 : 12}>
                    <UMaskTextfield
                        helperText={errocep ? "Formato de CEP incorreto" : ""}
                        error={errocep}
                        mask='99999-999'
                        label='CEP'
                        name='cep'
                        value={props.empresa.cep}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 6 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        name='complemento'
                        id="teste"
                        label="Complemento"
                        variant="outlined"
                        value={props.empresa.complemento || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 6 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        name='bairro'
                        id="teste"
                        label="Bairro"
                        variant="outlined"
                        value={props.empresa.bairro || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 4 : 12}>
                    <USearchField {...{ itens: cidades, value: cidade, setValue: setCidade, label: 'Cidade' }} />
                </Grid>
                <Grid item xs={!fullscreen ? 4 : 12}>
                    <USearchField {...{ itens: estados, value: estado, setValue: setEstado, label: 'Estado' }} />
                </Grid>
                <Grid item xs={!fullscreen ? 4 : 12}>
                    <USearchField {...{ itens: paises, value: pais, setValue: setPais, label: 'Pais' }} />
                </Grid>
                <Grid item xs={!fullscreen ? 4 : 12}>
                    <UMaskTextfield
                        helperText={errotelefone ? "Formato de telefone incorreto" : ""}
                        error={errotelefone}
                        mask='(99)99999-9999'
                        name='telefone'
                        label='Telefone'
                        value={props.empresa.telefone}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={!fullscreen ? 8 : 12}>
                    <LinesTextField
                        required
                        fullWidth
                        id="teste"
                        name='dominio'
                        label="Domínio"
                        variant="outlined"
                        value={props.empresa.dominio || ''}
                        onChange={e => handleChange(e)}
                        margin="dense"
                    />
                </Grid>
            </Grid>
        </div>
    )
};

export default CadastroEmpresa;
