import { Box, Button, Container, Grid, Paper, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { AssignmentOutlined, SearchOutlined } from "@material-ui/icons";
import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomizedSnackbars from "../../components/snackbar";
import UDatePicker from "../../components/udatepicker";
import ULoaderVoo from "../../components/uloadervoo";
import USearchField from "../../components/usearchfield";
import centroCustoRepositorio from "../../repositorios/centroCusto";
import ecogestaoRepositorio from "../../repositorios/ecogestao";
import Dashboard from "../dashboard";
import EcoGestaoDesk from "./Desk";
import { DialogEditVeiculo } from "./DialogEditVeiculo";
import { DialogSelectPax } from "./DialogSelectPax";
import EcoGestaoMobi from "./Mobi";
import EcoGestaoResumoDesk from "./Resumo/Desk";
import EcoGestaoResumoMobi from "./Resumo/Mobi";
import { useStyles } from "./styles";

export const EcoGestao = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState();
  const [snackState, setSnackState] = useState({ visible: false });
  const [snackVariant, setSnackVariant] = useState("success");
  const [snackMessage, setSnackMessage] = useState("");
  const permissao = useSelector(state => state.permissao.permissaoDashboard);
  const politicaViagem = useSelector(state => state.permissao.politicaViagem);
  const [centrosCusto, setCentrosCusto] = useState([]);
  const [centroCusto, setCentroCusto] = useState(null);

  const [dataPartida, setDataPartida] = useState(new Date());
  const [dataList, setDataList] = useState({
    aeroportosPassageiros: [],
    grupos: [],
    isPast: null
  });
  const [selectedPax, setSelectedPax] = useState({});
  const [selectedGrupo, setSelectedGrupo] = useState({});
  const [openDialogSelectPax, setOpenDialogSelectPax] = useState(false);
  const [openDialogEditVeiculo, setOpenDialogEditVeiculo] = useState(false);
  const [isSummary, setIsSummary] = useState(false);
  const [opcoesPartida, setOpcoesPartida] = useState([
    {
      value: 0,
      label: 'Empresa'
    },
    {
      value: 1,
      label: 'Moradia'
    },
    {
      value: 2,
      label: 'Outros'
    },
  ])
  const [opcaoPartida, setOpcaoPartida] = useState({})

  const getData = async (isSummary = false) => {
    setIsSummary(isSummary)
    try {
      setIsLoading(true);
      let dto = {
        dataPartida,
        idCentroCusto: centroCusto ? centroCusto.value : 0,
      }
      let response = await ecogestaoRepositorio.postAll(dto);
      setDataList(response.data)
    } catch (error) {
      setSnackVariant('error');
      setSnackMessage('Falha ao buscar dados.');
      setSnackState({ visible: true });
    } finally {
      setIsLoading(false);
    }
  }

  const handleClickPax = (pax, isPast) => {
    if (isPast) return
    setSelectedPax(pax)
    setOpenDialogSelectPax(true)
  }

  const handleClickRemovePaxFromGroup = async (group, pax) => {
    try {
      setIsLoading(true)
      let dto = {
        idgroup: group.idgroup,
        idaereovoo: pax.idaereovoo,
        idpassageiro: pax.idpassageiro,
        ida: pax.ida,
        dadosPesquisa: {
          dataPartida: dataPartida,
          idCentroCusto: centroCusto ? centroCusto.value : 0,
        }
      }
      let response = await ecogestaoRepositorio.removePaxFromGroup(dto);
      setDataList(response.data)
    } catch (error) {
      setSnackVariant('error');
      setSnackMessage('Falha ao remover o passageiro.');
      setSnackState({ visible: true });
    } finally {
      setIsLoading(false)
    }
  }

  const changeVeiculo = (grupo) => {
    setSelectedGrupo(grupo)
    setOpenDialogEditVeiculo(true)
  }

  useLayoutEffect(() => {
    (async () => {
      const response = await centroCustoRepositorio.getSearchField();
      await getData();
      setCentrosCusto(response);
    })()
  }, [])

  return (
    <>
      <ULoaderVoo isLoading={isLoading} />
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackState.visible,
          setSnackbar: setSnackState,
        }}
      />
      {openDialogSelectPax ? (
        <DialogSelectPax {...{
          setIsLoading,
          isLoading,
          selectedPax,
          openDialogSelectPax,
          setOpenDialogSelectPax,
          setSnackMessage,
          setSnackState,
          setSnackVariant,
          setDataList,
          dataPartida,
          centroCusto,
        }} />
      ) : null}
      {openDialogEditVeiculo ? (
        <DialogEditVeiculo {...{
          setIsLoading,
          isLoading,
          selectedGrupo,
          openDialogEditVeiculo,
          setOpenDialogEditVeiculo,
          setSnackMessage,
          setSnackState,
          setSnackVariant,
          permissao,
          setDataList,
          dataList,
          dataPartida,
          centroCusto,
        }} />
      ) : null}
      <Container maxWidth="xl">
        <Box mt={2} mb={responsive ? 0 : 0}>
          <Box mb={1}>
            <Grid container>
              <Grid item xs={responsive ? 12 : 2}>
                <Typography variant="h6" component="h1">Eco Gestão</Typography>
              </Grid>
            </Grid>
          </Box>
          <Paper elevation={3}>
            <Box p={3}>
              <form autoComplete="off">
                <Grid container spacing={1} >
                  <Grid item xs={12} md={2}>
                    <UDatePicker date={dataPartida} setDate={setDataPartida} label="Data" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    {permissao.relatorioCentroCusto ? (
                      <USearchField disabled={!politicaViagem.utilizarcentrocusto} label="Centro de custo" itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} />
                    ) : null}
                  </Grid>
                  {responsive ? null : (
                    <Grid item md={4} />
                  )}

                  <Grid item xs={6} md={1} style={{ alignSelf: 'center' }}>
                    <Tooltip title={(<Typography>Gerar resumo</Typography>)}>
                      <Button onClick={() => getData(true)} className={classes.button} variant="contained" fullWidth >
                        <AssignmentOutlined />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6} md={1} style={{ alignSelf: 'center' }}>
                    <Tooltip title={(<Typography>Realiar pesquisa</Typography>)}>
                      <Button onClick={() => getData(false)} className={classes.button} variant="contained" fullWidth >
                        <SearchOutlined />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
          <Box mt={2}>
            {Object.keys(dataList).length > 0 && !isSummary ? (responsive ? (
              null
            ) : (
              <EcoGestaoDesk {...{ changeVeiculo, permissao, dataPartida, handleClickRemovePaxFromGroup, handleClickPax, dataList, setIsLoading, setSnackMessage, setSnackState, setSnackVariant, setOpcaoPartida, opcaoPartida, opcoesPartida }} />
            )
            ) : (null)}
          </Box>
          <Box mt={2}>
            {isSummary ? responsive ? (
              null
            ) : (
              <EcoGestaoResumoDesk {...{ dataPartida, dataList }} />
            ) : null}
          </Box>
        </Box>
      </Container>
      <Box mt={2}>
        {(Object.keys(dataList.aeroportosPassageiros).length > 0 ||
          Object.keys(dataList.grupos).length > 0) && !isSummary ?
          (responsive ? (
            <EcoGestaoMobi {...{ changeVeiculo, permissao, dataPartida, handleClickRemovePaxFromGroup, handleClickPax, dataList, setIsLoading, setSnackMessage, setSnackState, setSnackVariant, setOpcaoPartida, opcaoPartida, opcoesPartida }} />
          )
            : (
              null
            )
          ) : (null)}
      </Box>
      <Box mt={2}>
        {isSummary ? responsive ? (
          <EcoGestaoResumoMobi  {...{ dataPartida, dataList }} />
        ) : (
          null
        ) : null}
      </Box>
    </>
  );
};

export const EcoGestaoDash = (props) => {
  return (
    <div>
      <Dashboard {...props} component={EcoGestao} />
    </div>
  );
};
export default EcoGestaoDash;
