import api from '../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const pacoteAgenteRepositorio = {

    postCreatePacote: async (dto) => {
        const response = await api.post(`/Pacote`, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getAllPacotes: async (dto) => {
        const response = await api.post(`/Pacote/getAll`, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getItensPacote: async (id) => {
        const response = await api.get(`/PacoteItens/` + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getListItemPacoteToAdd: async (id) => {
        const response = await api.get(`/PacoteItens/listAdd/` + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getHistoricPacote: async (id) => {
        const response = await api.get(`/pacotehistorico/` + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    putFinalizarPacote: async (id) => {
        const response = await api.put(`/pacote/FinalizarPacote/` + id, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    putCancelarPacote: async (id) => {
        const response = await api.put(`/pacote/CancelPacote/` + id, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    putItensAddPacote: async (id, dto) => {
        const response = await api.put(`/pacoteitens/additem/` + id, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    putRemoveItemPacote: async (id, dto) => {
        const response = await api.put(`/pacoteitens/removeItems/` + id, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    putResendPacote: async (id) => {
        const response = await api.put(`/pacote/ReenviarPacote/` + id, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },
}

export default pacoteAgenteRepositorio;
