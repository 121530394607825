import { Box, Button, Grid, ListItemAvatar, ListItemText, Stack, Tooltip, Typography } from "@mui/material"
import { ImageComponent } from "../../ImageComponent"
import { CreationDate } from "../../../views/prestacaodecontas/Desktop/sections"
import { ArrowUpward } from "@mui/icons-material"

export const ViewHodometerExpansesComponent = ({ despesa, isMobile = false }) => {
    return (
        <>
            {despesa != null && (
                <Grid container item xs={12} style={{ width: "600px" }}>
                    <Grid item xs={isMobile ? 12 : 6} style={{ padding: "8px" }}>
                        {despesa.hodometro?.comprovanteinicial != "" ? (
                            <>
                                {despesa.hodometro?.is_manual ? (
                                    <Tooltip title="Clique e veja foto inicial">
                                        <Stack style={{
                                            boxShadow: "10px",
                                            boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                            padding: "10px",
                                            borderRadius: "15px",
                                            cursor: "pointer"
                                        }} direction="row" spacing={2}>
                                            <ListItemAvatar>
                                                <ImageComponent width={48} height={48} src={despesa.hodometro.comprovanteinicial} alt={"Imagem não encontrada"} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={"Foto Inicial"}
                                                secondary={
                                                    <>
                                                        <Box component="span">
                                                            {despesa.hodometro.kminicial} Km/l
                                                        </Box>
                                                    </>
                                                }
                                            />
                                        </Stack>
                                    </Tooltip>
                                ) : (
                                    <>

                                        <ImageComponent width={248} height={248} src={despesa.hodometro?.comprovanteinicial} alt={"Imagem não encontrada"} />
                                    </>
                                )}
                            </>
                        ) : (null)}
                        {despesa.hodometro?.comprovantefinal != "" ? (
                            <>
                                <Tooltip title="Clique e veja foto final">
                                    <Stack style={{
                                        boxShadow: "10px",
                                        boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                        padding: "10px",
                                        borderRadius: "15px",
                                        cursor: "pointer",
                                        marginTop: "10px"
                                    }} direction="row" spacing={2}>
                                        <ListItemAvatar>
                                            <ImageComponent width={48} height={48} src={despesa.hodometro?.comprovantefinal} alt={"Imagem não encontrada"} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={"Foto Inicial"}
                                            secondary={
                                                <>
                                                    <Box component="span">
                                                        {despesa?.hodometro?.kmfinal} Km/l
                                                    </Box>
                                                </>
                                            }
                                        />
                                    </Stack>
                                </Tooltip>
                            </>
                        ) : (null)}

                    </Grid>
                    <Grid item xs={isMobile || despesa.hodometro?.comprovanteinicial === "" || despesa.hodometro?.comprovanteinicial === null ? 12 : 6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 0 }}>
                                <Typography variant="body1">
                                    Data de criação
                                </Typography>
                                <Typography variant="body1">
                                    Total em km
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 0 }}>
                                <Typography variant="h6" width={140}><CreationDate data={despesa.datacriacao} /></Typography>
                                <Typography variant="h3">{despesa.hodometro?.kmtotal != null ? despesa.hodometro.kmtotal + " Km" : "Não mensionado"}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 0, justifyContent: "flex-end" }}>
                                <ArrowUpward color="success" />
                                <Typography variant="body1" sx={{ ml: 1 }}>
                                    Total há receber
                                </Typography>
                            </Box>
                            <Typography variant="h3" sx={{ fontWeight: 'bold', marginLeft: "auto" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.hodometro?.valor || 0)}</Typography>
                        </Box>
                    </Grid>
                    {/* {despesa.hodometro?.comprovanteida != null ? (
                        <>
                            <ImageComponent width={348} height={248} src={despesa.hodometro.comprovanteida} alt={"Imagem não encontrada"} />
                        </>
                    ) : (null)}
                    {despesa.hodometro?.comprovanteida != null ? (
                        <>
                            <ImageComponent width={348} height={248} src={despesa.hodometro.comprovanteida} alt={"Imagem não encontrada"} />
                        </>
                    ) : (null)} */}
                </Grid>
            )}
        </>
    )
}