import { Grid, Typography } from "@material-ui/core";
import Highcharts from "highcharts";
import PieChart from "highcharts-react-official";
import React from "react";

const localeStringNumber = (value = 0) => {
  return value.toLocaleString('pt-BR')
}

export const RelatorioChartEcoGestao = (props) => {
  const totais = props.totaisRelatorio;

  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: ""
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Kg",
        colorByPoint: true,
        data: props.co2dto,
      },
    ],
  };

  return (
    <>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">
            Emissão e preservação de CO²
          </Typography>
        </Grid>
        <Grid item xs={0} md={2}>
        </Grid>
        <Grid item xs={12} md={4}>
          <PieChart
            highcharts={Highcharts}
            options={options}
            maxWidth={"auto"}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="h6">Total emitido translado: <b>{localeStringNumber(totais.totalEmitidoTranslado)} Kg</b></Typography>
          <Typography variant="h6">Total emitido aéreo: <b>{localeStringNumber(totais.totalEmitidoAereo)} Kg</b></Typography>
          <Typography variant="h6">Total preservado: <b>{localeStringNumber(totais.totalPreservado)} Kg</b></Typography>
          <Typography variant="h6">Total emitido: <b>{localeStringNumber(totais.total)} Kg</b></Typography>
        </Grid>
      </Grid>
    </ >
  );
};
