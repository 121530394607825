import React from 'react';
import InputMask from 'react-input-mask';
import LinesTextField from '../LinesTextField';

export const UMaskTextfield = props => {

    return (
        <InputMask
            mask={props.mask}
            formatChars={props.formatChars}
            alwaysShowMask={props.alwaysShowMask === false ? props.alwaysShowMask : true}
            maskChar={props.maskChar}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            onFocus={props.onFocus}>
            {(inputProps) => <LinesTextField fullWidth {...inputProps}
                onInput={props.onInput}
                autoFocus={props.autoFocus}
                error={props.error}
                helperText={props.error ? props.helperText : null}
                required={props.required}
                variant="outlined"
                margin="dense"
                type="tel"
                disableUnderline label={props.label} />}
        </InputMask>
    )
};

export default UMaskTextfield;
