import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CancelOutlined, SaveOutlined } from "@material-ui/icons";
import { useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import LinesTextField from "../../../../components/LinesTextField";
import UMaskTextfield from "../../../../components/umasktextfield";
import cardsRepositorio from "../../../../repositorios/cartao";
import { useStyles } from "./styles";


export const CartaoDialogRegister = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState(null);
    const [changed, setChanged] = useState(false);

    const [state, setState] = useState({
        apelido: "",
        cvc: "",
        expiry: "",
        focus: "",
        name: "",
        number: "",
        cpf: "",
    });

    const handleCloseDialog = () => {
        props.setOpenDialog(false);
    }

    const handleChange = (event) => {
        if (event.target.name === "name") {
            if (!isNaN(event.target.value) && event.target.value !== "") {
                return;
            }
        }
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        setValue(null);
        setChanged(true);
    };

    const handleInputFocus = (e) => {
        setState({ ...state, focus: e.target.name });
    };

    const bandeirasCartao = [
        {
            title: "AMEX",
            regex: /^3[47][0-9]{13}$/,
            result: "AX",
        },
        {
            title: "DINERS",
            regex: /'(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
            result: "DC",
        },
        {
            title: "DISCOVER",
            regex: /^6(?:011|5[0-9]{2})[0-9]{12}/,
            result: "EL",
        },
        {
            title: "ELO",
            regex: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
            result: "EL",
        },
        {
            title: "HIPERCARD",
            regex: /^606282|^3841(?:[0|4|6]{1})0/,
            result: "HC",
        },
        {
            title: "MASTERCARD",
            regex: /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
            result: "CA",
        },
        {
            title: "VISA",
            regex: /^4[0-9]{15}$/,
            result: "VI",
        },
    ];

    const converteDataCartao = (dataCartao = "00/00") => {
        const mes = Number(dataCartao.substr(0, 2));
        const ano = Number("20" + dataCartao.substr(3, 2));
        let dataFormatada = new Date(ano, mes, 0);
        return dataFormatada;
    };

    const validarCartao = (nCartao) => {
        let sigla = bandeirasCartao.find((item) => item.regex.test(nCartao)) || {};

        return sigla || "";
    };

    const handleClickSave = async () => {

        if (!state.name) {
            props.setSnackVariant("error");
            props.setSnackMessage("Nome não pode ser vazio!");
            props.setSnackState({ visible: true });
            return

        }
        if (!state.cpf) {
            props.setSnackVariant("error");
            props.setSnackMessage("CPF não pode ser vazio!");
            props.setSnackState({ visible: true });
            return
        }
        if (!state.number) {
            props.setSnackVariant("error");
            props.setSnackMessage("Número não pode ser vazio!");
            props.setSnackState({ visible: true });
            return
        }
        if (!state.expiry) {
            props.setSnackVariant("error");
            props.setSnackMessage("Data de validade não pode ser vazio!");
            props.setSnackState({ visible: true });
            return
        }

        let cartaoValido = validarCartao(state.number);

        if (!cartaoValido) {
            props.setSnackVariant("error");
            props.setSnackMessage("Cartão inválido!");
            props.setSnackState({ visible: true });
            return;
        }

        let dataValida = converteDataCartao(state.expiry);

        try {
            let dto = {
                dataValidade: dataValida,
                apelido: state.apelido,
                bandeira: cartaoValido.title,
                cpf: state.cpf,
                codigo: state.cvc,
                cartaoNome: state.name,
                cartaoNumero: state.number,
                emissorCartao: cartaoValido.result,
            };
            props.setIsLoading(true);
            await cardsRepositorio.postCard(dto);
            props.setSnackVariant("success");
            props.setSnackMessage("Opção salva com sucesso.");
            props.setSnackState({ visible: true });
            props.getListCard();
            handleCloseDialog();
        } catch (e) {
            props.setSnackVariant("error");
            props.setSnackMessage("Erro ao salvar");
            props.setSnackState({ visible: true });
        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <Dialog
            open={props.openDialog}
            onClose={handleCloseDialog}
            maxWidth={'md'}
            fullScreen={responsive}
        >
            <DialogTitle className={classes.dialogTitle}><Typography className={classes.typoTitle}>Cadastrar cartão</Typography></DialogTitle>
            <DialogContent className={responsive ? null : classes.dialogContent}>
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid item xs={responsive ? 12 : 6} className={classes.gridCard}>
                            <Cards
                                cvc={state.cvc}
                                expiry={state.expiry}
                                focused={state.focus}
                                name={state.name}
                                number={state.number}
                            />
                        </Grid>
                        <Grid container item xs={responsive ? 12 : 6}>
                            <form style={{ width: '-webkit-fill-available' }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Dados do cartão</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <LinesTextField
                                        name="apelido"
                                        variant="outlined"
                                        margin="dense"
                                        onChange={handleChange}
                                        value={state.apelido}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%" }}
                                        label="Apelido"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LinesTextField
                                        required
                                        name="name"
                                        variant="outlined"
                                        margin="dense"
                                        onFocus={handleInputFocus}
                                        onChange={handleChange}
                                        value={state.name}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: "100%" }}
                                        label="Nome do titular no cartão"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <UMaskTextfield
                                        required
                                        mask="999.999.999-99"
                                        maskChar=""
                                        name="cpf"
                                        label="CPF"
                                        value={state.cpf || ""}
                                        onChange={handleChange}
                                        onFocus={handleInputFocus}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <UMaskTextfield
                                        required
                                        mask="9999999999999999"
                                        maskChar={null}
                                        name="number"
                                        label="Nº do cartão"
                                        value={state.number}
                                        onChange={handleChange}
                                        onFocus={handleInputFocus}
                                    />
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={6} style={{ paddingRight: '5px' }}>
                                        <UMaskTextfield
                                            required
                                            mask="99/99"
                                            maskChar=""
                                            formatChars={{
                                                9: "[0-9]",
                                                "/": "",
                                            }}
                                            name="expiry"
                                            label="Validade"
                                            value={state.expiry}
                                            onChange={handleChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </Grid>

                                    <Grid item xs={6} style={{ paddingLeft: '5px' }}>
                                        <UMaskTextfield
                                            required
                                            mask="999?"
                                            formatChars={{
                                                9: "[0-9]",
                                                "?": "[0-9]",
                                            }}
                                            maskChar=""
                                            name="cvc"
                                            label="Código"
                                            value={state.cvc}
                                            onFocus={handleInputFocus}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                <Button onClick={() => handleClickSave()} className={classes.buttonSave} startIcon={<SaveOutlined />}>Salvar</Button>

            </DialogActions>
        </Dialog >
    )
}
