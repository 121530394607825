import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useCallback, useState } from "react";
import { UploadAvatar } from "../../../../../../upload";
import { fData } from "../../../../../../upload/utils/utils";
import { convertImageToBase64 } from "../../../../../../hooks/convertImageToBase64";
import { UtaliiDatePickerNormal } from "../../../../Desktop/sections/custom-date-picker-normal";
import { EnumTipoVeiculo } from "../../../../../../enums/enumVeiculos";
import { tiposdehodometro_Mock } from "../../../../../../mock/tiposdespesas";
import { EditHodometerGoogleMaps } from "./GoogleMaps";
import { EditHodometerManual } from "./Manual";

//fazer o modo editar

export const ViewHodometerMComponent = ({ viagem, hodometro, setType, editHodometro, typehodometro }) => {
    return (
        <>
            <Grid container item xs={12} style={{ justifyContent: "left", display: "flex", flexDirection: "row", alignContent: "flex-start", alignItems: "center" }}>
                <Grid item xs={6} style={{ marginTop: "-20px" }}>
                    <Button
                        onClick={() => setType(0)}
                        startIcon={<ArrowBackIosNewIcon />}
                        color="info"
                    >
                        Voltar
                    </Button>
                </Grid>
            </Grid>
            <Container item xs={12} maxWidth={"sm"}>
                {hodometro.hodometro.is_manual ? (
                    <EditHodometerManual viagemid={viagem.id} editHodometro={editHodometro} hodometro={hodometro} setType={setType} />
                ) : (
                    <EditHodometerGoogleMaps viagemid={viagem.id} editHodometro={editHodometro} hodometro={hodometro} setType={setType} />
                )}
            </Container>
        </>
    )
}
