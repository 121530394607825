import { Avatar, Box, Button, Card, CardContent, Divider, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, OutlinedInput, Pagination, Select, Typography, useMediaQuery, useTheme } from "@mui/material";
import OrderListView from "./ListaPagamentos/OrderListView";
import BankingWidgetSummary from "../../../components/cardbankingsummary";
import BankingCurrentBalance from "../../../components/cardbankingsummary/index-saldo";
import { _bankingCreditCard } from "../../../mock/financeiro";
import { todasviagens } from "../../../mock/prestacaodecontas";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AppWidgetSummary from "./Components/appwidgetsummary";
import { themepalette } from "../../../theme/palette";
import AnalyticsCurrentVisits from "./Components/analyticscurrrentvisits";
import { RecentTransfersCard } from "./Components/recentstransferscard";
import { getHistoryTransferencias_Mock, historicoTransferencia_Mock } from "../../../mock/historico";
import { useEffect, useLayoutEffect, useState } from "react";
import financeiroRepositorio from "../../../repositorios/financeiro";
import { useCustomSnackbar } from "../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../components/uloadervoo";
import { EnumTipo, formatarParaNomeStatusAdiantamento, formatarParaNomeStatusViagem, mapaNomesViagemStatus } from "../../../enums/enumPrestacaoDeContas";
import { AddCardOutlined, AdminPanelSettings, AssignmentTurnedInOutlined, HistoryToggleOffOutlined, Info, MoreVertOutlined } from "@mui/icons-material";
import { CreationDate, IniciadoEm } from "../../prestacaodecontas/Desktop/sections";
import { CardViagem_Mobile } from "../../../components/autalii_expanses/card_viagem_mob";
import DialogComponent from "../../prestacaodecontas/Desktop/sections/dialogs-desktop";
import { PagamentoComponentMobile } from "./Pagamento/MobileIndex";
import { DetalhesViagemComponentM } from "../../prestacaodecontas/Mobile/auditor/detalhesViagem";
import { HistoricoDePagamentosComponentMobile } from "./Historico/indexMobile";
import { StyledMenu } from "../../../components/styledMenu";
import { validateSections } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import UtaliiDatePickerInput from "../../prestacaodecontas/Desktop/sections/custom-date-picker-start_and_end";
import pessoaRepositorio from "../../../repositorios/pessoa";
import centroCustoRepositorio from "../../../repositorios/centroCusto";

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const PagamentosComponent = () => {
    const theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down('md'));
    const [viagem, setViagem] = useState({})
    const [viagens, setViagens] = useState([]);
    const [loading, setLoading] = useState(false);
    const [graficoValues, setGraficoValues] = useState([])
    const [recentTransfers, setRecentTransfers] = useState([])
    const [anchorEl, setAnchorEl] = useState(false)
    const [openDetails, setOpenDetails] = useState(false);
    const [openPag, setOpenPag] = useState(false);
    const [openHist, setOpenHist] = useState(false);
    const [openAdiant, setOpenAdiant] = useState(false);
    const [selectedDate, setSelectedDate] = useState([null, null]);
    const [centrosDeCusto, setCentrosDeCusto] = useState([]);
    const [centroDeCusto, setCentroDeCusto] = useState();
    const [pessoas, setPessoas] = useState([])
    const [pessoa, setPessoa] = useState();
    const [personName, setPersonName] = useState([]);
    const [filtredvalues, setFiltredValues] = useState({
        pendentes: 0,
        pagas: 0,
        transferido: 0
    })

    const open = Boolean(anchorEl);

    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = viagens.slice(startIndex, endIndex);

    const { enqueueSnackbar } = useCustomSnackbar();

    const statusOptions = Object.entries(mapaNomesViagemStatus);

    const handleChangeCentrodecusto = (event) => {
        setCentroDeCusto(event.value);
    };

    const handleChangePessoa = (event) => {
        setPessoa(event.value);
    };

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };

    const handleChangePage = (event, value) => {
        setPage(value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleChange = (event) => {
        const { target: { value } } = event;
        setPersonName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleClick = (event, value) => {
        setViagem(value)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickDetails = (row) => {
        setOpenDetails(true)
        handleClose(false)
    }

    const handleClickPagamento = (row) => {
        setOpenPag(true)
        handleClose(false)
    }

    const handleClickHistory = (row) => {
        setOpenHist(true)
        handleClose(false)
    }


    const getRecentTransfer = async () => {
        setLoading(true)
        try {
            const response = await financeiroRepositorio.getHistorico()
            setRecentTransfers(response)
        } catch {

        } finally {
            setLoading(false)
        }
    }

    const getAllViagens = async () => {
        setLoading(true)
        try {
            let model = {
                data_inicial: new Date(new Date().getFullYear(), 0, 1), // Início do ano: 1º de janeiro do ano atual
                data_final: new Date(new Date().getFullYear(), 11, 31),
                id_pessoa: 0,
                id_centrocusto: 0,
                minhasviagens: false
            };

            const response = await financeiroRepositorio.getAllViagens(model)
            setViagens(response.resultados)
            setGraficoValues(response.grafico)
            getRecentTransfer()
        } catch {

        } finally {
            setLoading(false)
        }
    }

    const handleClickFilter = async (filters) => {
        setLoading(true)
        try {
            let model = {
                data_inicial: filters.startDate != null ? new Date(filters.startDate) : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                data_final: filters.endDate != null ? new Date(filters.endDate) : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                id_pessoa: filters.idpessoa,
                id_centrocusto: filters.centrodecusto,
                minhasviagens: false
            }

            const response = await financeiroRepositorio.getAllViagens(model);
            setViagens(response.resultados)
            setGraficoValues(response.grafico)
            getRecentTransfer()

        } catch {
            enqueueSnackbar('Erro ao filtrar as viagens.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickFilterMobile = async () => {
        setLoading(true)
        try {
            let datai = null;
            let dataf = null
            let filtredStatus = [];

            personName.forEach(item => {
                filtredStatus.push(EnumTipo.viagemstatus[item]);
            });


            if (selectedDate[0] != null && selectedDate[1] != null) {
                datai = selectedDate[0].$d
                dataf = selectedDate[1].$d
            }

            let model = {
                data_inicial: datai != null ? new Date(datai) : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                data_final: dataf != null ? new Date(dataf) : new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                id_pessoa: pessoa != null ? pessoa : 0,
                id_centrocusto: centroDeCusto != null ? centroDeCusto : 0,
                minhasviagens: false
            }

            const response = await financeiroRepositorio.getAllViagens(model);

            if (filtredStatus.length != 0) {
                const filteredViagens = response.resultados.filter(viagem => {
                    const statusMatch = filtredStatus.length === 0 || filtredStatus.includes(viagem.status);
                    return statusMatch;
                });

                setViagens(filteredViagens)
                setGraficoValues(response.grafico)

                return
            }

            setViagens(response.resultados)
            getRecentTransfer()
        } catch {
            enqueueSnackbar('Erro ao filtrar as viagens.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllViagens()
    }, [])

    useLayoutEffect(() => {
        (async () => {
            const responsePessoas = await pessoaRepositorio.getSearchField();
            const response = await centroCustoRepositorio.getSearchField();
            // await getData();
            setCentrosDeCusto(response);
            setPessoas(responsePessoas)
        })()
    }, []);

    useEffect(() => {

        let totalpendente = 0;
        let totalpago = 0;
        let totalutilizado = 0;

        viagens.forEach((viagem) => {
            if (viagem.status == EnumTipo.viagemstatus.financeiro) {
                totalpendente += viagem.total
            }
            if (viagem.status == EnumTipo.viagemstatus.finalizado) {
                totalpago += viagem.total
            }
            if (viagem.status == EnumTipo.viagemstatus.emviagem) {
                totalutilizado += viagem.total;
            }
        })

        setFiltredValues({
            pendentes: totalpendente,
            pagas: totalpago,
            transferido: totalutilizado
        })
    }, [viagens])

    const STATUS_OPTIONS = [
        { value: 'todos', label: 'Todos', status: 0, color: 'secondary' },
        { value: 'finalizado', label: 'Finalizado', status: 5, color: 'primary' },
        { value: 'financeiro', label: 'Pendente', status: 4, color: 'success' },
        { value: 'financeiro', label: 'Negado', status: 4, color: 'success' },
    ];

    return (
        <Grid container item xs={12} style={{ display: "ruby" }}>
            <ULoaderVoo isLoading={loading} />

            {viagem != null && (
                <>
                    {/* <DialogComponent
                        title={
                            <>
                                Nova tranferência
                            </>
                        }
                        open={openPag}
                        handleClose={() => setOpenPag(false)}
                        body={<PagamentoComponentMobile viagem={viagem} setOpen={setOpenPag} getAllViagens={getAllViagens} />}
                        action={null}
                        width={"sm"}
                        fullScreen={true}
                    />

                    <DialogComponent
                        title={
                            <>
                                Detalhes da viagem
                            </>
                        }
                        open={openDetails}
                        handleClose={() => setOpenDetails(false)}
                        body={<DetalhesViagemComponentM viagem={viagem} />}
                        action={null}
                        width={"sm"}
                        fullScreen={true}
                    />

                    <DialogComponent
                        title={
                            <>
                                {viagem?.titulo}
                            </>
                        }
                        open={openHist}
                        handleClose={() => setOpenHist(false)}
                        body={<HistoricoDePagamentosComponentMobile viagem={viagem} />}
                        action={null}
                        width={"sm"}
                        fullScreen={true}
                    /> */}
                </>
            )}



            {fullscreen ? (
                <>
                    <Grid style={{ display: "flex", justifyContent: "center", marginTop: "-40px" }} item xs={12}>
                        <Grid item xs={12} style={{ maxWidth: "400px" }}>
                            <FormControl sx={{ width: "100%", marginBottom: "8px" }}>
                                <UtaliiDatePickerInput
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                />
                            </FormControl>

                            <FormControl fullWidth style={{ width: "100%", marginBottom: "8px" }}>
                                <InputLabel id="demo-simple-select-label">Pessoa</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pessoa}
                                    defaultValue={0}
                                    label="Pessoa"

                                >
                                    <MenuItem value={0} onClick={() => handleChangePessoa({ label: "Todos", value: 0 })}>Todos</MenuItem>
                                    {pessoas?.map((e) => (
                                        <MenuItem value={e.value} onClick={() => handleChangePessoa({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Centro de custo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={centroDeCusto}
                                    defaultValue={0}
                                    label="Centro de custo"

                                >
                                    <MenuItem value={0} onClick={() => handleChangeCentrodecusto({ label: "Todos", value: 0 })}>Todos</MenuItem>
                                    {centrosDeCusto?.map((e) => (
                                        <MenuItem value={e.value} onClick={() => handleChangeCentrodecusto({ label: e.label, value: e.value })}>{e.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ width: "100%", marginTop: "8px" }}>
                                <InputLabel id="demo-multiple-chip-label">Status Da Viagem</InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={personName}
                                    onChange={handleChange}
                                    input={<OutlinedInput id="select-multiple-chip" label="Status Da Viagem" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <>
                                                    {formatarParaNomeStatusViagem(value)}
                                                </>
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={{ PaperProps: { style: { maxHeight: 224, width: 250 } } }}
                                >
                                    {statusOptions.map(([key, label]) => (
                                        <MenuItem
                                            key={key}
                                            value={key}
                                            style={getStyles(key, personName, theme)}
                                        >
                                            {label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button onClick={() => handleClickFilterMobile()} variant="contained" fullWidth color="primary">
                                Pesquisar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", flexDirection: "column", flexDirection: "row", flexWrap: "wrap", marginTop: "15px" }}>
                        {currentItems.map((v) => (
                            <>
                                <CardViagem_Mobile
                                    viagem={v}
                                    moreAction={
                                        <>
                                            <IconButton onClick={(e) => handleClick(e, v)} aria-label="settings">
                                                <MoreVertOutlined />
                                            </IconButton>
                                            <StyledMenu
                                                id="demo-customized-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'demo-customized-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={() => handleClickDetails(validateSections)} disableRipple>
                                                    <Info />
                                                    Detahes
                                                </MenuItem>
                                                {
                                                    (
                                                        viagem?.status == EnumTipo.viagemstatus.financeiro ||
                                                        viagem?.status == EnumTipo.viagemstatus.aguardandoinicio
                                                    )
                                                    && (
                                                        <MenuItem onClick={() => handleClickPagamento(v)} disableRipple>
                                                            <AddCardOutlined />
                                                            Pagar Viagem
                                                        </MenuItem>
                                                    )}
                                                <MenuItem onClick={() => handleClickHistory(v)} disableRipple>
                                                    <HistoryToggleOffOutlined />
                                                    Histórico De Pagamentos
                                                </MenuItem>
                                            </StyledMenu>
                                        </>
                                    }
                                />
                            </>
                        ))}
                    </Grid>
                    <Grid item xs={12} marginBottom={8} marginTop={2} style={{ display: "flex", justifyContent: "center" }}>
                        <Pagination
                            color="primary"
                            count={Math.ceil(viagens.length / itemsPerPage)}
                            page={page}
                            onChange={handleChangePage}
                            siblingCount={0}
                            shape="rounded"
                        />
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container item xs={9}>
                        <Grid container item xs={12} style={{ padding: "20px" }}>
                            <Grid item xs={4} style={{ padding: "9px" }}>
                                <AppWidgetSummary
                                    title="Despesas Pendentes"
                                    percent={2.6}
                                    total={filtredvalues.pendentes}
                                    chart={{
                                        series: [18, 18, 12, 51, 5, 11, 9, 27, 27, 20],
                                    }}
                                    sx={{}}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ padding: "9px" }}>
                                <AppWidgetSummary
                                    title="Despesas Pagas"
                                    percent={2.6}
                                    total={filtredvalues.pagas}
                                    chart={{
                                        colors: [themepalette.palette.info.light, themepalette.palette.info.main],
                                        series: [5, 18, 12, 51, 68, 11, 39, 37, 27, 20],
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ padding: "9px" }}>
                                <AppWidgetSummary
                                    title="Total Transferido"
                                    percent={2.6}
                                    total={filtredvalues.transferido}
                                    chart={{
                                        colors: [themepalette.palette.warning.light, themepalette.palette.warning.main],
                                        series: [15, 18, 12, 51, 3, 11, 39, 37, 6, 20],
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <OrderListView title={"Pagamentos"} data={viagens} getAllViagens={getAllViagens} filtredvalues={filtredvalues} setFiltredValues={setFiltredValues} handleClickFilter={handleClickFilter} statusOptions={STATUS_OPTIONS} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={3} style={{ maxHeight: "-moz-fit-content" }}>
                        <Grid container item xs={12} style={{ boxShadow: "0px 0px 6px -4px rgba(0,0,0,0.75)", borderRadius: "10px", marginTop: "15px" }}>
                            <Grid item xs={12}>
                                <AnalyticsCurrentVisits
                                    title="Despesas"
                                    colors={(
                                        [
                                            '#00A76F',
                                            '#FFAB00',
                                            '#00B8D9',
                                            '#FF5630'
                                        ])}
                                    chart={{
                                        series: graficoValues,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ padding: "10px" }}>
                                <RecentTransfersCard transfers={recentTransfers} />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    )
}