import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AddCircleOutline, CloseOutlined, IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import CustomizedSnackbars from '../../../../components/snackbar';
import ULoaderVoo from '../../../../components/uloadervoo';
import solicitacaoRepositorio from '../../../../repositorios/solicitacao';
import CadastroViajanteSolicitacaoProduto from './cadastroViajante';
import { useStyles } from './styles';

export const ModalViajante = props => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [modalVincular, setModalVincular] = useState(false);
    const [selecionado, setSelecionado] = useState({});
    const [porcentagem, setPorcentagem] = useState('');
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });
    const [backDrop, setBackDrop] = useState(false);
    const [backDropViajante, setBackDropViajante] = useState(false);

    const handleClickAdicionar = async () => {
        if (!selecionado) {
            setMensagemSnack('Passageiro não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        if (!selecionado.idsolicitacaopassageiro) {
            setMensagemSnack('Passageiro não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        if (!selecionado.centroCusto) {
            setMensagemSnack('Centro de custo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        if (isNaN(porcentagem) || !porcentagem) {
            setMensagemSnack('Porcentagem inválida!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        let dtoPassageiro = {
            idsolicitacaoproduto: props.produto.idsolicitacaoproduto,
            idcentrocusto: (selecionado.centroCusto.value || null),
            idtenant: props.produto.idtenant,
            idsolicitacaopassageiro: (selecionado.idsolicitacaopassageiro || null),
            porcentagem: (Number(porcentagem) || null)
        }

        setBackDropViajante(true);
        try {
            const response = await solicitacaoRepositorio.produtoPassageiroAdicionar(dtoPassageiro);
            props.produto.passageiros.push(response.data);
            setBackDropViajante(false);
            setModalVincular(false);
            setSelecionado({});
            setPorcentagem('');
            setMensagemSnack('Passageiro vinculado com sucesso!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setBackDropViajante(false);
            setMensagemSnack('Erro ao vincular passageiro!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
    }

    const handleClickRemover = async (item, index) => {
        setBackDrop(true);
        try {
            await solicitacaoRepositorio.produtoPassageiroRemover(item.idsolicitacaoprodutopassageiro);
            props.produto.passageiros.splice(index, 1);
            setBackDrop(false);
            setMensagemSnack('Passageiro desvinculado com sucesso!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setBackDrop(false);
            setMensagemSnack('Erro ao desvincular passageiro!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }
    }

    const getPassageiroProduto = id => {
        // pegar passageiro da solicitacao / achar uma solução para passageiros repetidos 
        const tem = props.solicitacao.passageiros.find(p => p.idsolicitacaopassageiro === id);
        if (!tem) {
            return ''
        }
        return `${tem.passageiro.nome} ${tem.passageiro.sobrenome}`;
    }

    const getCentroCustoPassageiro = id => {
        const tem = props.solicitacao.passageiros.find(p => p.idsolicitacaopassageiro === id);
        if (!tem) {
            return ''
        }
        return `${tem.centroCusto.nome}`;
    }

    return (
        <div>
            <CustomizedSnackbars {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <ULoaderVoo isLoading={backDrop} />
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={modalVincular}
                fullScreen={fullScreen}
                onClose={() => setModalVincular(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography>Vincular Viajante</Typography>
                        <IconButton onClick={() => setModalVincular(false)} style={{ color: 'white' }}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <CustomizedSnackbars {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
                    <CadastroViajanteSolicitacaoProduto {...{ solicitacao: props.solicitacao, setSolicitacao: props.setSolicitacao, porcentagem, setPorcentagem, selecionado, setSelecionado, backDropViajante, produto: props.produto }} />
                </DialogContent>
                <DialogActions id="action9">
                    <Button onClick={() => handleClickAdicionar()} className={classes.button}>Adicionar</Button>
                </DialogActions>
            </Dialog>
            <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                    <Button
                        className={classes.button}
                        onClick={() => setModalVincular(true)}
                        startIcon={<AddCircleOutline />}
                    >
                        Adicionar
                    </Button>
                </Grid>
                {fullScreen ? (
                    <Grid container item xs={12} style={{ maxHeight: 300 }}>
                        {props.produto?.passageiros?.map((item, index) => (
                            <Grid item xs={12}>
                                <List>
                                    <Divider />
                                    <ListItem>
                                        <ListItemText primary={
                                            <Tooltip title={getPassageiroProduto(item.idsolicitacaopassageiro)}>
                                                <Typography noWrap>
                                                    {getPassageiroProduto(item.idsolicitacaopassageiro)}
                                                </Typography>
                                            </Tooltip>
                                        }
                                            secondary={
                                                <React.Fragment>
                                                    <Tooltip title={`${getCentroCustoPassageiro(item.idsolicitacaopassageiro)} - ${item.porcentagem + '%'}`}>
                                                        <Typography
                                                            noWrap
                                                            variant="body2"
                                                        >
                                                            {`${getCentroCustoPassageiro(item.idsolicitacaopassageiro)} - ${item.porcentagem + '%'}`}
                                                        </Typography>
                                                    </Tooltip>
                                                </React.Fragment>}
                                        />
                                        <ListItemIcon>
                                            <Tooltip title="Desvincular Viajante">
                                                <IconButton style={{ color: '#b91119' }} onClick={() => handleClickRemover(item, index)}>
                                                    <IndeterminateCheckBoxOutlined />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Viajante</TableCell>
                                            <TableCell>Centro de Custo</TableCell>
                                            <TableCell>Porcentagem</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.produto?.passageiros?.map((item, index) => (
                                            <TableRow>
                                                <TableCell>{getPassageiroProduto(item.idsolicitacaopassageiro)}</TableCell>
                                                <TableCell>{getCentroCustoPassageiro(item.idsolicitacaopassageiro)}</TableCell>
                                                <TableCell>{item.porcentagem + '%'}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="Desvincular Viajante">
                                                        <IconButton style={{ color: '#b91119' }} onClick={() => handleClickRemover(item, index)}>
                                                            <IndeterminateCheckBoxOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </div>
    )
};

export default ModalViajante;
