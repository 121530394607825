import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();

const aeroportoRepositorio = {
    get: async (id) => {
        const response = await api.get('/Aeroporto/' + id, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },

    getSearchField: async () => {
        let lista = [];
        const response = await api.get('/Aeroporto', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        response.data.map(item => {
            lista.push({
                label: item.nome + ' - ' + item.cidade + ' - ' + item.sigla,
                value: item.sigla
            })
        })
        return lista;
    },

    getSearchFieldByType: async (typeSearch) => {
        let lista = [];
        const response = await api.post(`/Aeroporto/GetAllByType`, typeSearch, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        response.data.map(item => {
            lista.push({
                label: item.nome + ' - ' + item.cidade + ' - ' + item.sigla,
                value: item.sigla,
            })
        })
        return lista;
    },

    postVerifyAeroportosType: async (dto) => {
        const response = await api.post(`/Aeroporto/postVerifyAeroportosType`, dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response.data;
    }
}

export default aeroportoRepositorio;
