import { AppBar, Avatar, Box, Button, ClickAwayListener, Collapse, Dialog, DialogContent, Divider, Grid, Grow, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AddCircleOutline, AirlineSeatReclineNormalOutlined, BusinessCenterOutlined, CloseOutlined, HelpOutlineOutlined, RemoveCircleOutline, SendOutlined } from '@material-ui/icons';
// import { Editor } from '@tinymce/tinymce-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cookie from 'universal-cookie';
import LinesTextField from '../../../components/LinesTextField';
import Snackbar from '../../../components/snackbar';
import UDatePicker from '../../../components/udatepicker';
import ULoaderVoo from '../../../components/uloadervoo';
import UMultiSelect from '../../../components/umultiselect';
import USearchField from '../../../components/usearchfield';
import USelect from '../../../components/uselect';
import Enums from '../../../enums';
import cidadeRepositorio from '../../../repositorios/cidade';
import pessoaRepositorio from '../../../repositorios/pessoa';
import produtoRepositorio from '../../../repositorios/produto';
import reservaRepositorio from '../../../repositorios/reserva';
import solicitacaoRepositorio from '../../../repositorios/solicitacao';
import usuarioRepositorio from '../../../repositorios/usuario';
import validatorCPF from '../../../utils/cpfValidator';
import Dashboard from '../../dashboard';
import CheckUpCadastroSolicitacao from './CheckUpCadastroSolicitacao/index.jsx';
import ModalDestino from './destino';
import ModalProduto from './produto';
import { useStyles } from './styles';
import ModalViajante from './viajante';
import VoucherSolicitacao from './voucher';
import SaveIcon from '@material-ui/icons/Save';
import permissaoRepositorio from '../../../repositorios/permissao/index.js';
import centroCustoRepositorio from '../../../repositorios/centroCusto/index.js';
import dayjs from 'dayjs';

export const CadastroSolicitacao = props => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [solicitacao, setSolicitacao] = useState({});
    const permissaoDashboard = useSelector(state => state.permissao.permissaoDashboard);
    const politicaViagem = useSelector(state => state.permissao.politicaViagem);
    const styleHtml = '<style> .gmail_quote{display: none;} .gmail_attr {display: none;} </style>';
    const [mensagem, setMensagem] = useState('');
    const [value, setValue] = useState({
        Destino: '',
        Assunto: '',
        Mensagem: '',
    });
    const classes = useStyles();
    const cookies = useMemo(() => new cookie(), []);
    const [solicitantes, setSolicitantes] = useState([]);
    const [solicitante, setSolicitante] = useState({});
    const [prodState, setProdState] = useState({
        produto: '',
        moeda: '',
        unidade: '',
        preco: '',
        cambio: ''
    })
    const [centroCusto, setCentroCusto] = useState(null);
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });
    const [destinoSelecionado, setDestinoSelecionado] = useState({});
    const [listaColapse, setListaColapse] = useState([]);
    const [motivo, setMotivo] = useState('');
    const [grow, setGrow] = useState(false);
    const [backDrop, setBackDrop] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef(null);
    const [editando, setEditando] = useState(false);
    const [centrosCusto, setCentrosCusto] = useState([]);
    const [viajantes, setViajantes] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [destinos, setDestinos] = useState([]);
    const [origem, setOrigem] = useState(null);
    const [destino, setDestino] = useState(null);
    const [dataIda, setDataIda] = useState(new Date());
    const [dataVolta, setDataVolta] = useState(new Date());
    const [pessoas, setPessoas] = useState([]);
    const [pessoa, setPessoa] = useState(null);
    const [produtos, setProdutos] = useState([]);
    const [produto, setProduto] = useState(null);
    const [produtoSelecionado, setProdutoSelecionado] = useState({});
    const [categoriaProduto, setCategoriaProduto] = useState([]);
    const [status, setStatus] = useState(solicitacao.idsolicitacao ? solicitacao.status : 0);
    const [prioridade, setPrioridade] = useState(3);
    const [statusFinanceiro, setStatusFinanceiro] = useState(0);
    const [modalSelected, setModalSelected] = useState(0);
    const [moeda, setMoeda] = useState(0);
    const [tituloStatus, setTituloStatus] = useState('');
    // const [tituloStatusFinanceiro, setTituloStatusFinanceiro] = useState('');
    const [openAddBilhetePDF, setOpenAddBilhetePDF] = useState(false);
    const [openCheckUpCadastro, setOpenCheckUpCadastro] = useState(false);
    const [dataCheckUp, setDataCheckUp] = useState();
    // const [isChangedPessoa, setIsChangedPessoa] = useState(false);
    const [hiddenTeste, setHiddenTeste] = useState(true);
    const [permiteAlterarFaturamento, setPermiteAlterarFaturamento] = useState(false)

    const moedas = [{
        value: 1,
        label: 'Real Brasileiro',
        format: "R$ "
    }, {
        value: 2,
        label: 'Euro',
        format: "€ "
    }, {
        value: 3,
        label: 'Dólar Americano',
        format: "$ "
    }]

    const setStatusButtonSolicitacao = () => {
        switch (status) {
            case 1:
                return 'Atender Solicitação';
            case 2:
                return 'Aguardar solicitante';
            case 3:
                return 'Solicitar Autorização';
            case 11:
                return 'Emitir';
            default:
                return '';
        }
    }

    const statusSolicitacao = useMemo(() => [
    {
        value: 1,
        label: 'Aguardando Atendimento'
    }, {
        value: 2,
        label: 'Em Atendimento'
    }, {
        value: 3,
        label: 'Aguardando Solicitante'
    }, {
        value: 4,
        label: 'Encerrado'
    }, {
        value: 5,
        label: 'Rascunho'
    }, {
        value: 6,
        label: 'Autorização Pendente'
    }, {
        value: 14,
        label: 'Aprovação Pendente'
    }, {
        value: 7,
        label: 'Expirado'
    }, {
        value: 8,
        label: 'Cancelado'
    }, {
        value: 9,
        label: 'Emitido'
    }, {
        value: 10,
        label: 'Limite Excedido'
    }, {
        value: 11,
        label: 'Emissão pendente'
    }, {
        value: 12,
        label: 'Emissão negada'
    }], []);

    const prioridades = [{
        value: 1,
        label: 'Muito Alta'
    }, {
        value: 2,
        label: 'Alta'
    }, {
        value: 3,
        label: 'Média'
    }, {
        value: 4,
        label: 'Baixa'
    }, {
        value: 5,
        label: 'Muito Baixa'
    }]
    const statusFinanceiros = [{
        value: 0,
        label: 'Pendente'
    }, {
        value: 1,
        label: 'Faturado'
    }, {
        value: 2,
        label: 'Consolidado'
    }]

    const itens2 = [
        'Hotel',
        'Ônibus',
        'Locação de Carro',
        'Aéreo Internacional',
        'Pacotes',
        'Outros',
        'Seguro Viagem',
        'Aéreo'
    ];

    const getStatusReserva = {
        0: 0,
        1: 1,
        2: 2,
        3: 4,
        4: 5,
        5: 6,
        6: 9,
        7: 10,
        8: 12,
        9: 7,
        10: 13,
        11: 3,
        12: 11,
        14: 14
    }

    const handleClickOpenAddBilhetePDF = () => {
        setOpenAddBilhetePDF(true);
    };

    // const handleChanged = e => {
    //     setMensagem(e.target.value);
    //     setValue({
    //         ...value,
    //         mensagem: e.target.value
    //     });
    // }

    // const handleChangedRichTextField = e => {
    //     setMensagem(e.target.getContent());
    //     setValue({
    //         ...value,
    //         mensagem: e.target.getContent()
    //     });
    // }

    const handleTextChangeQuill = (value) => {
        setMensagem(value);
        setValue({
            ...value,
            mensagem: value
        });
    };

    const handleClick = async () => {

        setBackDrop(true);

        let dtoMensagem = {
            idremetente: 1,
            idtenant: solicitacao.idtenant,
            ordem: 1,
            iddestinatario: solicitacao.idsolicitante,
            idsolicitacao: solicitacao.idsolicitacao,
            messageid: '0',
            assunto: motivo,
            messageno: 0,
            u_id: 0,
            corpomensagem: mensagem,
            corpomensagemsimples: 'Essa mensagem foi gerada através do Linestur Digital',
            respostapara: '',
            referencia: '',
            rascunho: false,
            datacriacao: new Date(),
            dataultimaatualizacao: new Date(),
            sessionId: cookies.get('sessionId')
        }
        try {

            await solicitacaoRepositorio.mensagemAdicionar(dtoMensagem);

            await alterarStatusAgente(2);

            loadSolicitacao();
            setValue({
                ...value,
                Mensagem: ''
            })
            setMensagemSnack('Mensagem enviada!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
            setBackDrop(false);
            return;
        } catch (e) {
            setMensagemSnack('Erro ao enviar mensagem!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            setBackDrop(false);
        }
    }

    const getMoeda = value => {
        const procurarMoeda = moedas.find(item => item.value === value);
        return procurarMoeda.label || '';
    }

    const getMoedaFormat = (value) => {
        const procurarMoeda = moedas.find(item => item.value === value);
        return procurarMoeda.format || '';
    }

    const toMoeda = valor => valor.toString().replace(',', '.');

    const handleClickAdicionarViajante = async () => {

        let dto = {
            pessoa: pessoa,
            centroCusto: politicaViagem.utilizarcentrocusto ? centroCusto : centrosCusto[0]
        }

        if (!pessoa) {
            setMensagemSnack('Viajante não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        if (!dto.centroCusto) {
            setMensagemSnack('Centro de Custo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        let validateData = true

        if (!validatorCPF(pessoa.pessoa.cpf) && pessoa.pessoa.nacionalidade === 1) {
            setMensagemSnack('CPF do(a) viajante está inválido.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        if (!pessoa.pessoa.datanascimento) {
            setMensagemSnack('A data de nascimento do(a) viajante está vazia.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        if (pessoa.pessoa.telefone === "(__)_____-____" || pessoa.pessoa.telefone === null || pessoa.pessoa.telefone === 'undefined' || pessoa.pessoa.telefone.trim() === "") {
            setMensagemSnack('O telefone do(a) viajante está vazio.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            validateData = false
        }

        if (!validateData) {
            setDataCheckUp(pessoa)
            setOpenCheckUpCadastro(!validateData)
            return
        }

        try {
            let dtoSend = { idpassageiro: pessoa.pessoa.idpessoa }
            let response = await pessoaRepositorio.postVerificarCpf(dtoSend)
            if (response.status === 207) {
                setMensagemSnack(response.data.message);
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return
            }

            if (response.status === 202) {
                setMensagemSnack(response.data.message);
                setTipoSnack('info');
                setSnackbarSucess({ visible: true });
            }
        } catch (e) {
            setMensagemSnack('Falha ao verificar dados do(a) viajante.');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return
        }

        addViajante(dto)
    }

    const continueAddViajante = (isValid) => {
        if (isValid) {
            let dto = {
                pessoa: pessoa,
                centroCusto: politicaViagem.utilizarcentrocusto ? centroCusto : centrosCusto[0]
            }
            addViajante(dto)
        }
    }

    const addViajante = async (dto) => {
        if (solicitacao.idsolicitacao) {
            let dtoPassageiro = {
                idtenant: solicitacao.idtenant,
                idpassageiro: pessoa.value,
                idsolicitacao: solicitacao.idsolicitacao,
                idcentrocusto: centroCusto.value,
            }
            try {
                await solicitacaoRepositorio.passageiroAdicionar(dtoPassageiro);
                await loadSolicitacao();
                setBackDrop(false);
                setPessoa(null);
                setCentroCusto(null);
                setMensagemSnack('Viajante adicionado com sucesso!');
                setTipoSnack('success');
                setSnackbarSucess({ visible: true });
            } catch (e) {
                setMensagemSnack('Erro ao adicionar viajante!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
            }
            finally {
                setBackDrop(false);
            }
            return;
        }
        setViajantes(oldValues => [...oldValues, dto]);
        setPessoa(null);
        setCentroCusto(null);
    }

    // useEffect(() => {
    //     if (isChangedPessoa) {
    //         addViajante(pessoa)
    //     }
    // }, [isChangedPessoa])

    const handleClickRemoverViajante = async (index, idpassageiro) => {

        if (!solicitacao.idsolicitacao) {
            setHiddenTeste(false)
            setViajantes(viajantes.filter((_, i) => i !== index));
            setTimeout(() => {
                setHiddenTeste(true)
            }, 1);
            return;
        }
        try {
            setBackDrop(true);
            await solicitacaoRepositorio.passageiroRemover(idpassageiro);
            await loadSolicitacao();
            setBackDrop(false);
            setMensagemSnack('Viajante removido com sucesso!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setBackDrop(false);
            setMensagemSnack('Erro ao remover Viajante!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }

    }
    const handleClickAdicionarDestino = async () => {

        if (!origem) {
            setMensagemSnack('Origem não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        if (!destino) {
            setMensagemSnack('Destino não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        if (!dataIda) {
            setMensagemSnack('Data de Ida não pode ser vazia!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }
        if (!dataVolta) {
            setMensagemSnack('Data de Volta não pode ser vazia!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        let hoje = new Date(solicitacao.datacriacao ? solicitacao.datacriacao : null),
            ida = dataIda,
            volta = dataVolta;

        hoje.setHours(0, 0, 0, 0);
        ida.setHours(0, 0, 0, 0);
        volta.setHours(0, 0, 0, 0);

        if (ida < hoje || volta < hoje) {
            setMensagemSnack('Datas não podem ser menores que a data de criação da solicitação!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        setBackDrop(true);
        let dto = {
            dataIda: dataIda,
            dataVolta: dataVolta,
            origem: origem,
            destino: destino
        }
        if (solicitacao.idsolicitacao) {

            let dtoDestino = {
                idsolicitacao: solicitacao.idsolicitacao,
                idorigem: origem.value,
                iddestino: destino.value,
                datapartida: dataIda,
                datadestino: dataVolta,
                idtenant: solicitacao.idtenant
            }
            try {
                await solicitacaoRepositorio.destinoAdicionar(dtoDestino);
                await loadSolicitacao();
                setBackDrop(false);
                setOrigem(null);
                setDestino(null);
                setDataIda(new Date());
                setDataVolta(new Date());
                setMensagemSnack('Destino adicionado com sucesso!');
                setTipoSnack('success');
                setSnackbarSucess({ visible: true });
            } catch (e) {
                setBackDrop(false);
                setMensagemSnack('Erro ao adicionar destino!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
            }
            return;
        }
        setBackDrop(false);
        setDestinos(oldValues => [...oldValues, dto]);
        setOrigem(null);
        setDestino(null);
        setDataIda(new Date());
        setDataVolta(new Date());
    }

    const handleClickRemoverDestino = async (index, iddestino) => {
        if (!solicitacao.idsolicitacao) {
            setDestinos(destinos.filter((_, i) => i !== index));
            return;
        }
        try {
            setBackDrop(true);
            await solicitacaoRepositorio.destinoRemover(iddestino);
            await loadSolicitacao();
            setBackDrop(false);
            setMensagemSnack('Destino removido com sucesso!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setBackDrop(false);
            setMensagemSnack('Erro ao remover destino!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }
    }

    const handleClickDestinoProdutos = index => {

        setDestinoSelecionado(solicitacao.destinos[index]);
        setModalSelected(3);
        setModalOpen(true);
    }
    const handleClickProdutoViajantes = item => {
        setProdutoSelecionado(item);
        setModalSelected(1);
        setModalOpen(true);
    }

    const getValorUnitário = (valor, cambio) => {
        let cambioFormatado = Number(cambio);
        let valorFormatado = Number(valor);

        if (isNaN(cambio) || isNaN(valor)) {
            return 0;
        }
        return valorFormatado * cambioFormatado;
    }

    const handleClickAdicionarProduto = async () => {

        try {
            if (!produto) {
                setMensagemSnack('Produto não pode ser vazio!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return
            }
            if (!moeda) {
                setMensagemSnack('Moeda não pode ser vazia!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return
            }
            if (!prodState.unidade) {
                setMensagemSnack('Quantidade não pode ser vazio!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return
            }
            if (!prodState.preco) {
                setMensagemSnack('Preço não pode ser vazio!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return
            }
            if (!prodState.cambio || prodState.cambio <= 0) {
                setMensagemSnack('Cambio não pode ser vazio ou zero!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                return
            }

            setBackDrop(true);
            let dtoProduto = {
                moeda: moeda,
                idtenant: solicitacao.idtenant,
                emitido: false,
                token: '',
                cambio: toMoeda(prodState.cambio),
                quantidade: prodState.unidade,
                idproduto: produto.value,
                total: toMoeda(prodState.preco),
                idsolicitacao: solicitacao.idsolicitacao
            }
            await solicitacaoRepositorio.produtoAdicionar(dtoProduto)
            await loadSolicitacao();


            setProduto(null);
            setMoeda(0);
            setProdState({
                ...prodState,
                moeda: '',
                unidade: '',
                preco: '',
                cambio: ''
            })

            setBackDrop(false);
            setMensagemSnack('Produto adicionado com sucesso!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setBackDrop(false);
            setMensagemSnack('Erro ao adicionar produto!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }


    }
    const handleChangedProduto = event => {
        setProdState({
            ...prodState,
            [event.target.name]: event.target.value
        })

    }

    const handleChangedProdutoSearch = produtoItem => {
        setProdState({
            ...prodState,
            produto: produtoItem
        })
    }

    const handleClickRemoverProduto = async idSolicitacaoProduto => {
        try {
            setBackDrop(true);
            await solicitacaoRepositorio.produtoRemover(idSolicitacaoProduto);
            await loadSolicitacao();
            setBackDrop(false);
            setMensagemSnack('Produto removido com sucesso!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setBackDrop(false);
            setMensagemSnack('Erro ao remover produto!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }
    }

    const getListaPassageirosIncompletos = () => {
        return solicitacao.passageiros.filter(item => {
            let temProduto = [];
            let temDestinoVinculado = [];
            
            solicitacao.produtos?.forEach(p => {
                let temPassageirosProduto = p.passageiros.find(pitem => pitem.idsolicitacaopassageiro === item.idsolicitacaopassageiro);
                if (temPassageirosProduto) {
                    temProduto.push(p);
                }
            });
            
            solicitacao.destinos?.forEach(d => {
                d.produtos.forEach(dp => {
                    let temProdutoDestino = temProduto.find(ditem => ditem.idsolicitacaoproduto === dp.idsolicitacaoproduto);
                    if (temProdutoDestino) {
                        temDestinoVinculado.push(d);
                    }
                });
            });
            
            return temProduto.length <= 0 || temDestinoVinculado.length <= 0;
        });
    };
    

    const alterarStatus = async event => {

        if (!solicitacao.idsolicitacao) {
            return;
        }
        let statusReserva = getStatusReserva[event.target.value];

        if (statusReserva === 7) {
            let passageirosComDadosImcompletos = getListaPassageirosIncompletos();
            if (passageirosComDadosImcompletos.length > 0) {
                setMensagemSnack('Não é possível emitir há dados não vinculados!');
                setTipoSnack('error');
                setStatus(null);
                setSnackbarSucess({ visible: true });
                setBackDrop(false);
                return;
            }
        }

        setBackDrop(true);
        let dtoSolicitacao = {
            solicitacao: {
                ...solicitacao,
                idsolicitacao: solicitacao.idsolicitacao,
                idagente: 1,
                status: event.target.value,
                rascunho: false,
                obs: '1',
                datacriacao: new Date(),
                dataultimaatualizacao: new Date(),
                motivo: motivo,
                tipohotel: categoriaProduto.includes('Hotel'),
                tipoonibus: categoriaProduto.includes('Ônibus'),
                tipolocacaocarro: categoriaProduto.includes('Locação de Carro'),
                tipoaereointernacional: categoriaProduto.includes('Aéreo Internacional'),
                tipopacotes: categoriaProduto.includes('Pacotes'),
                tipooutros: categoriaProduto.includes('Outros'),
                tiposeguroviagem: categoriaProduto.includes('Seguro Viagem'),
                tipoaereo: categoriaProduto.includes('Aéreo'),
                sessionId: cookies.get('sessionId')
            },
            reserva: {
                ...props.reserva,
                idtenant: props.reserva.idtenant,
                status: statusReserva
            }
        }
        const response = await solicitacaoRepositorio.put(dtoSolicitacao);
        if (!response.sucess) {
            setBackDrop(false);
            return;
        }

        // const reservaResponse = await reservaRepositorio.getAll();
        // props.setReservas(reservaResponse.data);

        props.setIsUpdateTicket(true)
        setBackDrop(false);
        props.setMensagemSnack('Dados Salvos com Sucesso!');
        props.setSnackbarSucess({ visible: true });
        props.setModalOpen(false);
    }

    const criarSolicitacao = async () => {
        if (!motivo) {
            setMensagemSnack('Titulo não pode ser vazio!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return
        }
        if (viajantes.length <= 0) {
            setMensagemSnack('Deve haver no mínimo 1 viajante!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return
        }
        if (!value.mensagem) {
            setMensagemSnack('Mensagem não pode ser Vazia!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return
        }
        setBackDrop(true);

        let listaDestinos = destinos.map(item => {
            return {
                idorigem: item.origem.value,
                iddestino: item.destino.value,
                datapartida: item.dataIda,
                datadestino: item.dataVolta
            }
        });

        const listaPassageiros = viajantes.map(item => {
            return {
                idcentrocusto: item.centroCusto.value,
                idpassageiro: item.pessoa.value,
                datacriacao: new Date(),
                destino: listaDestinos
            }
        });

        let dtoMensagem = {
            idremetente: 1,
            idtenant: 0,
            ordem: 1,
            iddestinatario: 5,
            idsolicitacao: 0,
            messageid: '0',
            assunto: motivo,
            messageno: 0,
            u_id: 0,
            corpomensagem: mensagem,
            corpomensagemsimples: 'Teste',
            respostapara: '',
            referencia: '',
            rascunho: false,
            datacriacao: new Date(),
            dataultimaatualizacao: new Date(),
            sessionId: cookies.get('sessionId')
        }

        let dtoSolicitacao = {
            solicitacao: {
                idsolicitante: solicitante.value || 0,
                idagente: 1,
                status: 1,
                rascunho: false,
                obs: '1',
                datacriacao: new Date(),
                dataultimaatualizacao: new Date(),
                motivo: motivo,
                tipohotel: categoriaProduto.includes('Hotel'),
                tipoonibus: categoriaProduto.includes('Ônibus'),
                tipolocacaocarro: categoriaProduto.includes('Locação de Carro'),
                tipoaereointernacional: categoriaProduto.includes('Aéreo Internacional'),
                tipopacotes: categoriaProduto.includes('Pacotes'),
                tipooutros: categoriaProduto.includes('Outros'),
                tiposeguroviagem: categoriaProduto.includes('Seguro Viagem'),
                tipoaereo: categoriaProduto.includes('Aéreo'),
                sessionId: cookies.get('sessionId'),
            },
            mensagem: dtoMensagem,
            destinos: listaDestinos,
            passageiros: listaPassageiros,
            sessionId: cookies.get('sessionId')
        }

        await solicitacaoRepositorio.post(dtoSolicitacao);

        setBackDrop(false);

        setMensagemSnack('Dados Salvos com Sucesso!');
        setTipoSnack('success');
        setSnackbarSucess({ visible: true });
        props.history.push('/listaReservas');;
    }

    const AlterStatusFinance = async () => {
        try {
            setBackDrop(true);
            await solicitacaoRepositorio.putUpdateFinanceiro(props.reserva.idsolicitacao, statusFinanceiro, props.reserva?.idreserva, props.reserva.idtenant)
            setMensagemSnack('Dados Salvos com Sucesso!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch {
        } finally {
            setBackDrop(false)
        }
    }

    const loadSolicitacao = useCallback(async () => {
        if (props.reserva) {
            let dtoDadosSolicitacao = {
                idsolicitacao: props.reserva.idsolicitacao,
                sessionId: cookies.get('sessionId')
            };
            const responseSolicitacao = await solicitacaoRepositorio.get(dtoDadosSolicitacao);
            responseSolicitacao.data.idsolicitacao ? setEditando(true) : setEditando(false);
            setSolicitacao(responseSolicitacao.data);
            let listaCategoria = [];

            if (responseSolicitacao.data.tipohotel) {
                listaCategoria.push('Hotel')
            };
            if (responseSolicitacao.data.tipoonibus) {
                listaCategoria.push('Ônibus');
            }
            if (responseSolicitacao.data.tipolocacaocarro) {
                listaCategoria.push('Locação de Carro');
            }
            if (responseSolicitacao.data.tipoaereointernacional) {
                listaCategoria.push('Aéreo Internacional');
            }
            if (responseSolicitacao.data.tipopacotes) {
                listaCategoria.push('Pacotes');
            }
            if (responseSolicitacao.data.tipooutros) {
                listaCategoria.push('Outros');
            }
            if (responseSolicitacao.data.tiposeguroviagem) {
                listaCategoria.push('Seguro Viagem');
            }
            if (responseSolicitacao.data.tipoaereo) {
                listaCategoria.push('Aéreo');
            }

            let listaViajantes = [];
            let listaDestinos = [];
            setMotivo(responseSolicitacao.data.motivo);
            setStatus(responseSolicitacao.data.status);
            let statusTitulo = statusSolicitacao.find(item => item.value === responseSolicitacao.data.status);
            setStatusFinanceiro(responseSolicitacao.data.statusfinanceiro);
            setTituloStatus(statusTitulo.label)
            setCategoriaProduto(listaCategoria);
            responseSolicitacao.data.destinos?.forEach(item => {
                listaDestinos.push({
                    iddestino: item.idsolicitacaodestino,
                    origem: { value: item.origem.idcidade, label: item.origem.nome },
                    destino: { value: item.destino.idcidade, label: item.destino.nome },
                    dataIda: item.datapartida,
                    dataVolta: item.datadestino,
                })
            })
            setDestinos(listaDestinos);
            responseSolicitacao.data.passageiros?.forEach(item => {
                listaViajantes.push({
                    idpassageiro: item.idsolicitacaopassageiro,
                    pessoa: { value: item.passageiro.idpessoa, label: item.passageiro.nome + ' ' + item.passageiro.sobrenome },
                    centroCusto: { value: item.centroCusto.idcentrocusto, label: item.centroCusto.nome + " - " + item.centroCusto.codigo, codigo: item.centroCusto.codigo }
                })
            })
            setViajantes(listaViajantes);

            let listaCol = responseSolicitacao.data.mensagens.map(() => {
                return false
            });

            listaCol[listaCol.length - 1] = true;

            setListaColapse(listaCol);
        }
    }, [cookies, statusSolicitacao, props]);

    const getStatusButtonSolicitacao = () => {
        let statusProcurar = props.reserva ? props.reserva.status : 0;
        let status = [5, 6, 7, 8, 9, 10, 11, 12, 13].find(item => item === statusProcurar);
        return status ? false : true;
    }

    // const getStatusButtonFinanceiro = () => {
    //     let statusProcurar = props.reserva ? props.reserva.statusfinanceiro : 0;
    //     let status = [0, 1, 2].find(item => item === statusProcurar);
    //     return status ? false : true;
    // }

    const handleClickActionSolicitacao = async () => {
        setBackDrop(true);
        let status = 1;

        switch (props.reserva.status) {
            case 1:
                status += 1;
                break;
            case 2:
                status = 4;
                break;
            case 3:
                status = 7;
                break;
            case 4:
                status = 9;
                break;
            case 9:
                status = 3;
                break;
            default:
                status = 0;
                break;
        }
        let dtoStatusReserva = {
            idreserva: props.reserva?.idreserva,
            status: status,
            justificativa: '',
            politicaAereo: false,
            statusTipo: Enums.getStatusSolicitacaoReserva[status],
            sessionId: cookies.get('sessionId')
        }
        if (status === 7) {
            let passageirosComDadosImcompletos = getListaPassageirosIncompletos();
            if (passageirosComDadosImcompletos.length > 0) {
                setMensagemSnack('Não é possível emitir há dados não vinculados!');
                setTipoSnack('error');
                setSnackbarSucess({ visible: true });
                setBackDrop(false);
                return;
            }
        }
        const response = await reservaRepositorio.alterarStatus(dtoStatusReserva);

        if (response.status === 207) {
            setMensagemSnack(response.data.message);
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            setBackDrop(false);
            return;
        }

        const reservaResponse = await reservaRepositorio.getAll();
        props.setReservas(reservaResponse.data);
        await loadSolicitacao();

        setBackDrop(false);
        setMenuOpen(false);
        props.setMensagemSnack('Dados Salvos com Sucesso!');
        props.setSnackbarSucess({ visible: true });
        props.setModalOpen(false);
    }

    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setMenuOpen(false);
    };

    const onBlurAssuntoTextField = async () => {
        if (props.reserva?.idreserva > 0) {
            try {
                setBackDrop(true);
                if (props.reserva.assunto !== motivo) {
                    await solicitacaoRepositorio.putUpdateTitle(props.reserva?.idreserva, motivo)
                }
                // await props.loadReserva()
            } catch (error) {

            } finally {
                setBackDrop(false);
            }
        }
    }

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setMenuOpen(false);
        }
    }

    const setOpenCollapse = (index) => {
        let listaCol = [...listaColapse];
        listaCol[index] = !listaCol[index];
        setListaColapse(listaCol);
    }

    const alterarStatusAgente = useCallback(async (acao) => {
        try {
            if (props.reserva?.idreserva) {
                let dto = {
                    acao: acao,
                    idreserva: props.reserva?.idreserva
                }
                await reservaRepositorio.alterarStatusSolicitacaoAgente(dto);
                props.loadReserva();
            }
        } catch (e) {
            return;
        }
    }, [props])

    // const convertForMoeda = (price, cambio) => {
    //     return <h1>JSJSJSJS</h1>
    // }

    const prevOpen = React.useRef(menuOpen);

    useEffect(() => {
        if (prevOpen.current === true && menuOpen === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = menuOpen;
    }, [menuOpen]);

    useEffect(() => {
        (async () => {
            setBackDrop(true);
            await alterarStatusAgente(1);

            await loadSolicitacao();

            let listaCentroCusto = [];

            const centroCustoResponse = await centroCustoRepositorio.getSearchField();
            // api.get('/centrocusto/GetAllPorTenant', {
            //     headers: {
            //         Authorization: 'Bearer ' + cookies.get('token'),
            //         sessionId: cookies.get('sessionId')
            //     }
            // });
            const responsePermissao = await permissaoRepositorio.getPorSessao();
            setPermiteAlterarFaturamento(responsePermissao.data.permitealterarfinanceiro);
            
            centroCustoResponse?.forEach(item => {
                listaCentroCusto.push({
                    label: item.label,
                    value: item.value,
                })
            })

            setCentrosCusto(listaCentroCusto);

            const responsePessoa = await pessoaRepositorio.getSearchField();
            setPessoas(responsePessoa);

            const responseProduto = await produtoRepositorio.getSearchField();
            setProdutos(responseProduto)

            const responseCidade = await cidadeRepositorio.getSearchField();
            setCidades(responseCidade);

            const responseSolicitante = await usuarioRepositorio.getSearchField();
            setSolicitantes(responseSolicitante);

            setGrow(true);
            setBackDrop(false);

        })()
    }, [alterarStatusAgente, loadSolicitacao])

    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <ULoaderVoo isLoading={backDrop} />
            {openAddBilhetePDF ? (
                <VoucherSolicitacao
                    open={openAddBilhetePDF}
                    setOpenAddBilhetePDF={setOpenAddBilhetePDF}
                    data={solicitacao}
                />
            ) : null}
            {openCheckUpCadastro ? (
                <>
                    <CheckUpCadastroSolicitacao
                        open={openCheckUpCadastro}
                        setOpen={setOpenCheckUpCadastro}
                        data={dataCheckUp}
                        setPessoas={setPessoas}
                        pessoas={pessoas}
                        setPessoa={setPessoa}
                        pessoa={pessoa}
                        continueAddViajante={continueAddViajante}
                        setMensagemSnack={setMensagemSnack}
                        setTipoSnack={setTipoSnack}
                        setSnackbarSucess={setSnackbarSucess}
                    />
                </>
            ) : null}
            <Dialog
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={fullScreen}
            >
                <AppBar className={classes.dialogTitle}>
                    <Toolbar>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            {modalSelected === 1 ? (
                                <Typography>{'Viajantes Vinculados'}</Typography>
                            ) : modalSelected === 2 ? (
                                <Typography>{'Destinos Vinculados'}</Typography>
                            ) : modalSelected === 3 ? (
                                <Typography>{'Produtos Vinculados'}</Typography>
                            ) : null}
                            <IconButton onClick={() => setModalOpen(false)} style={{ color: 'white' }}>
                                <CloseOutlined />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    {modalSelected === 1 ? (
                        <ModalViajante {...{ solicitacao, setSolicitacao, produto: produtoSelecionado, setProduto: setProdutoSelecionado }} />
                    ) : modalSelected === 2 ? (
                        <ModalDestino {...props} />
                    ) : modalSelected === 3 ? (
                        <ModalProduto {...{ solicitacao, setSolicitacao, destino: destinoSelecionado, setDestino: setDestinoSelecionado }} />
                    ) : null}
                </DialogContent>
            </Dialog>
            <Grow in={grow}
                {...(grow ? { timeout: 1000 } : {})}
            >
                <Paper elevation={5} className={classes.paper}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 10px', flexDirection: 'column' }}>
                        <Typography variant="h5">Solicitação</Typography>
                        {props.reserva ? (
                            <Grid>
                                <Grid container style={fullScreen ? { display: 'flex' } : null}>
                                    <Grid item xs={9}>
                                        <Button
                                            variant="contained"
                                            className={classes.buttonPDF}
                                            onClick={() => handleClickOpenAddBilhetePDF()}
                                        >
                                            Voucher
                                        </Button>
                                        <Button ref={anchorRef} className={classes.buttonAtender} onClick={() => handleToggle()}>{tituloStatus}</Button>
                                        {getStatusButtonSolicitacao() ? (
                                            <Popper open={menuOpen && permissaoDashboard.usuarioLinestur} anchorEl={anchorRef.current} placement="bottom" style={{ zIndex: 100 }} role={undefined} transition disablePortal>
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{ transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom' }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClose}>
                                                                <MenuList autoFocusItem={menuOpen && permissaoDashboard.usuarioLinestur} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                                    <MenuItem onClick={() => handleClickActionSolicitacao()}>
                                                                        {setStatusButtonSolicitacao()}
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        ) : null}
                                    </Grid>
                                    {permiteAlterarFaturamento && props.reserva.status === 7 ? (
                                        <Grid container item xs={3} style={{ textAlign: "center", marginTop: "-22px", }}>
                                            <Grid item xs={9} style={{ textAlign: "left" }}>
                                                <Typography style={{ fontSize: "13px", fontStyle: "800", marginBottom: "-8px" }}>Situação financeira</Typography>
                                                <Grid style={{ textAlign: "center" }}>
                                                    <USelect className={classes.buttonSelect} itens={statusFinanceiros} value={statusFinanceiro} setValue={setStatusFinanceiro} label="" />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginTop: "16px" }}>
                                                <IconButton onClick={() => AlterStatusFinance()}>
                                                    <SaveIcon style={{ color: "#c62828" }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    ) : (null)}
                                    {/* {getStatusButtonFinanceiro() ? (
                                        <Popper open={menuOpen && permissaoDashboard.usuarioLinestur} anchorEl={anchorRef.current} placement="bottom" style={{ zIndex: 100, marginLeft: "10px" }} role={undefined} transition disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{ transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom' }}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <MenuList autoFocusItem={menuOpen && permissaoDashboard.usuarioLinestur} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                                <MenuItem onClick={handleClickActionSolicitacao}>
                                                                    {setStatusButtonSolicitacao()}
                                                                </MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    ) : null} */}
                                </Grid>
                            </Grid>
                        ) : null}
                    </Box>
                    <Grid container xs={12} spacing={1} className={classes.linha}>
                        <Grid xs item>
                            <LinesTextField onBlur={() => onBlurAssuntoTextField()} fullWidth label="Titulo" value={motivo} onChange={e => setMotivo(e.target.value)} variant="outlined" margin="dense" />
                        </Grid>
                        {!props.reserva && permissaoDashboard.usuarioLinestur ? (
                            <Grid xs item>
                                <USearchField itens={solicitantes} value={solicitante} setValue={setSolicitante} label="Solicitante" />
                            </Grid>
                        ) : null}
                        <Grid item xs>
                            <UMultiSelect disabled={editando} value={categoriaProduto} setValue={setCategoriaProduto} itens={itens2} label="Serviços" />
                        </Grid>
                    </Grid>
                </Paper>
            </Grow>
            {
                solicitacao.idsolicitacao ? (
                    <Grow in={grow && categoriaProduto.length > 0 ? true : false}
                        {...(grow && categoriaProduto.length > 0 ? { timeout: 1000 } : {})}>
                        <div>
                            <Paper elevation={5} className={classes.paper}>
                                <Grid container xs={12}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">{solicitacao.motivo}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                {solicitacao.mensagens.length > 0 ? solicitacao.mensagens.map((item, index) => (
                                    <Grid
                                        container xs={12}
                                        className={classes.linha2}
                                        spacing={1}>
                                        {item.ordem === 2 ? (
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <List className={classes.rootList}>
                                                        <ListItem button onClick={() => setOpenCollapse(index)} alignItems="flex-start">
                                                            <ListItemAvatar>
                                                                <Avatar className={classes.avatar} src={item.remetente.imagem} />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={
                                                                    <React.Fragment>
                                                                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                                            {item.remetente.usuario}{" - "}
                                                                            <Typography variant="body2" style={{ fontWeight: "600", marginLeft: "4px", marginBottom: "1px" }}>
                                                                                {dayjs(item.datacriacao).format('D [de] MMM. [de] YYYY [às] HH:mm')}
                                                                            </Typography>
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography variant="body2" color="textPrimary" noWrap>
                                                                            {item.remetente.email}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider variant="inset" component="li" />
                                                    </List>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Collapse in={listaColapse[index]} timeout="auto" unmountOnExit>
                                                        <List className={classes.rootList}>
                                                            <ListItem alignItems="flex-start">
                                                                <ListItemAvatar>
                                                                </ListItemAvatar>
                                                                <div dangerouslySetInnerHTML={{ __html: styleHtml + item.corpomensagem }} />
                                                            </ListItem>
                                                            <Divider variant="inset" component="li" />
                                                        </List>
                                                    </Collapse>
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                        {item.ordem === 1 ? (
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <List className={classes.rootList}>
                                                        <ListItem button onClick={() => setOpenCollapse(index)} alignItems="flex-start">
                                                            <ListItemAvatar>
                                                                <Avatar className={classes.avatar} src={item.remetente.imagem} />
                                                            </ListItemAvatar>
                                                            <ListItemText
                                                                primary={
                                                                    <React.Fragment>
                                                                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                                                                            {item.remetente.usuario}{" - "}
                                                                            <Typography variant="body2" style={{ fontWeight: "600", marginLeft: "4px", marginBottom: "1px" }}>
                                                                                {dayjs(item.datacriacao).format('D [de] MMM. [de] YYYY [às] HH:mm')}
                                                                            </Typography>
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="textPrimary"
                                                                            noWrap
                                                                        >
                                                                            {item.remetente.email}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider variant="inset" component="li" />
                                                    </List>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Collapse in={listaColapse[index]} timeout="auto" unmountOnExit>
                                                        <List className={classes.rootList}>
                                                            <ListItem alignItems="flex-start">
                                                                <ListItemAvatar>
                                                                </ListItemAvatar>
                                                                <div dangerouslySetInnerHTML={{ __html: styleHtml + item.corpomensagem }} />
                                                            </ListItem>
                                                            <Divider variant="inset" component="li" />
                                                        </List>
                                                    </Collapse>
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                )) : null}
                            </Paper>
                        </div>
                    </Grow>
                ) : null
            }
            {
                categoriaProduto.length > 0 ? (
                    <div>
                        <Grow in={grow && categoriaProduto.length > 0 ? true : false}
                            {...(grow && categoriaProduto.length > 0 ? { timeout: 1000 } : {})}
                        >
                            <Paper elevation={5} className={classes.paper}>
                                <Typography variant="h5">Viajantes</Typography>
                                <Grid container xs={12}>
                                    {!editando || permissaoDashboard.usuarioLinestur || [1, 2, 3, 4, 9, 14].includes(props.reserva.status) ? (
                                        <Grid container style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={2} xs={12}>
                                            <Grid item xs={politicaViagem.utilizarcentrocusto ? !fullScreen ? 5 : 12 : !fullScreen ? 5 : 12}>
                                                <USearchField {...{ itens: pessoas, value: pessoa, setValue: setPessoa, label: 'Viajante' }} />
                                            </Grid>
                                            {politicaViagem.utilizarcentrocusto ? (
                                                <Grid item xs={!fullScreen ? 5 : 12}>
                                                    <USearchField itens={centrosCusto} value={centroCusto} setValue={setCentroCusto} label="Centro de Custo" />
                                                </Grid>
                                            ) : null}
                                            <Grid item xs={!fullScreen ? 2 : 12}>
                                                <Tooltip title="Adicionar Viajante">
                                                    <Button
                                                        className={classes.button}
                                                        onClick={() => handleClickAdicionarViajante()}
                                                        startIcon={<AddCircleOutline />}
                                                    >Adicionar</Button>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                    {fullScreen ? (
                                        <Grid container item xs={12} style={{ maxHeight: 300 }}>
                                            {viajantes.map((item, index) => (
                                                <Grid item xs={12}>
                                                    <List>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={item.pessoa.label}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        {politicaViagem.utilizarcentrocusto ? (
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="textPrimary"
                                                                                noWrap
                                                                            >
                                                                                {`${item.centroCusto.label} - ${item.centroCusto.codigo}`}
                                                                            </Typography>
                                                                        ) : null}
                                                                    </React.Fragment>}
                                                            />
                                                            <ListItemIcon>
                                                                {!editando || permissaoDashboard.usuarioLinestur || [1, 2, 3, 4, 9, 14].includes(props.reserva.status) ? (
                                                                    <Tooltip title="Remover Viajante">
                                                                        <IconButton onClick={() => handleClickRemoverViajante(index, item.idpassageiro)} style={{ color: '#b91119' }}>
                                                                            <RemoveCircleOutline fontSize="large" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                ) : null}
                                                            </ListItemIcon>
                                                        </ListItem>
                                                        <Divider />
                                                    </List>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : (
                                        <TableContainer className={classes.TableContainer}>
                                            <Table padding="checkbox" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Viajante</TableCell>
                                                        {politicaViagem.utilizarcentrocusto ? (
                                                            <TableCell align="center">Centro de Custo</TableCell>
                                                        ) : null}
                                                        {!editando || permissaoDashboard.usuarioLinestur ? (
                                                            <TableCell align="left"></TableCell>
                                                        ) : null}
                                                    </TableRow>
                                                </TableHead>
                                                {hiddenTeste ?
                                                    <TableBody>
                                                        {viajantes.map((item, index) => (
                                                            <TableRow key={'viajante' + item.pessoa.value}>
                                                                <TableCell align="center"><Typography>{item.pessoa.label}</Typography></TableCell>
                                                                {politicaViagem.utilizarcentrocusto ? (
                                                                    <TableCell align="center"><Typography>{`${item.centroCusto.label}`}</Typography></TableCell>
                                                                ) : null}
                                                                {!editando || permissaoDashboard.usuarioLinestur || [1, 2, 3, 4, 9, 14].includes(props.reserva.status) ? (
                                                                    <TableCell align="left"><Tooltip title="Remover Viajante"><IconButton onClick={() => handleClickRemoverViajante(index, item.idpassageiro)} style={{ color: '#b91119' }}><RemoveCircleOutline /></IconButton></Tooltip></TableCell>
                                                                ) : null}
                                                            </TableRow>))}
                                                    </TableBody>
                                                    : null}
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Grid>
                            </Paper>
                        </Grow>
                        <Grow in={grow && categoriaProduto.length > 0 ? true : false}
                            {...(grow && categoriaProduto.length > 0 ? { timeout: 2000 } : {})}
                        >
                            <Paper elevation={5} className={classes.paper}>
                                <Typography variant="h5">Destinos</Typography>
                                <Grid container xs={12} >
                                    <Grid container item xs={12} spacing={3} style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Grid item xs={fullScreen ? 12 : 3}>
                                            {/* <USearchFieldAsync {...{ itens: cidades, value: origem, setValue: setOrigem, label: 'Origem' }}  required={true} /> */}
                                            <USearchField {...{ itens: cidades, value: origem, setValue: setOrigem, label: 'Origem' }} required={true} />
                                        </Grid>
                                        <Grid item xs={fullScreen ? 12 : 3}>
                                            <USearchField {...{ itens: cidades, value: destino, setValue: setDestino, label: 'Destino' }} required={true} />
                                        </Grid>
                                        <Grid item xs={fullScreen ? 12 : 2}>
                                            <UDatePicker {...{ date: dataIda, setDate: setDataIda, label: "Partida", disablePast: true }} required={true} />
                                        </Grid>
                                        <Grid item xs={fullScreen ? 12 : 2}>
                                            <UDatePicker {...{ date: dataVolta, setDate: setDataVolta, label: "Retorno", disablePast: true }} required={true} />
                                        </Grid>
                                        <Grid item xs={!fullScreen ? 2 : 12}>
                                            <Tooltip title="Adicionar Destino">
                                                <Button
                                                    className={classes.button}
                                                    onClick={() => handleClickAdicionarDestino()}
                                                    startIcon={<AddCircleOutline />}
                                                >Adicionar</Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    {fullScreen ? (
                                        <Grid container item xs={12} style={{ maxHeight: 300 }}>
                                            {destinos.map((item, index) => (
                                                <Grid item xs={12}>
                                                    <List>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={
                                                                <Tooltip title={item.origem.label}>
                                                                    <Typography noWrap>
                                                                        {item.origem.label}
                                                                    </Typography>
                                                                </Tooltip>
                                                            }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Tooltip title={new Date(item.dataIda).toLocaleDateString() || ''}>
                                                                            <Typography
                                                                                noWrap
                                                                                variant="body2"
                                                                            >
                                                                                {new Date(item.dataIda).toLocaleDateString() || ''}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </React.Fragment>}
                                                            />
                                                            <ListItemText primary={
                                                                <Tooltip title={item.destino.label}>
                                                                    <Typography noWrap>
                                                                        {item.destino.label}
                                                                    </Typography>
                                                                </Tooltip>
                                                            }
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Tooltip title={new Date(item.dataVolta).toLocaleDateString() || ''}>
                                                                            <Typography
                                                                                noWrap
                                                                                variant="body2"
                                                                            >
                                                                                {new Date(item.dataVolta).toLocaleDateString() || ''}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    </React.Fragment>}
                                                            />
                                                            <ListItemIcon>
                                                                {editando && permissaoDashboard.usuarioLinestur ? (
                                                                    <div>
                                                                        {solicitacao.idsolicitacao && permissaoDashboard.usuarioLinestur ? (
                                                                            <Tooltip title="Ver/Adicionar Produtos Vinculados"><IconButton style={{ color: '#b91119' }} onClick={() => handleClickDestinoProdutos(index)}><BusinessCenterOutlined /></IconButton></Tooltip>
                                                                        ) : null}
                                                                    </div>
                                                                ) : null}
                                                            </ListItemIcon>
                                                            {permissaoDashboard.usuarioLinestur ? (
                                                                <ListItemIcon>
                                                                    <IconButton style={{ color: '#b91119' }} onClick={() => handleClickRemoverDestino(index, item.iddestino)}><RemoveCircleOutline /></IconButton>
                                                                </ListItemIcon>
                                                            ) : null}
                                                        </ListItem>
                                                        <Divider />
                                                    </List>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : (
                                        <TableContainer className={classes.TableContainer}>
                                            <Table padding="checkbox" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" style={{ width: 20 }}></TableCell>
                                                        <TableCell align="center">Origem</TableCell>
                                                        <TableCell align="center">Destino</TableCell>
                                                        <TableCell align="center">Partida</TableCell>
                                                        <TableCell align="center">Retorno</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {destinos.map((item, index) => (
                                                        <TableRow key={'cidade' + item.value}>
                                                            <TableCell align="center" style={{ width: 20 }}>{index + 1}</TableCell>
                                                            <TableCell align="center" ><Typography>{item.origem.label || ''}</Typography></TableCell>
                                                            <TableCell align="center"><Typography>{item.destino.label || ''}</Typography></TableCell>
                                                            <TableCell align="center"><Typography>{new Date(item.dataIda).toLocaleDateString() || ''}</Typography></TableCell>
                                                            <TableCell align="center"><Typography>{new Date(item.dataVolta).toLocaleDateString() || ''}</Typography></TableCell>
                                                            <TableCell align="left">
                                                                {solicitacao.idsolicitacao && permissaoDashboard.usuarioLinestur ? (
                                                                    <Tooltip title="Ver/Adicionar Produtos Vinculados"><IconButton style={{ color: '#b91119' }} onClick={() => handleClickDestinoProdutos(index)}><BusinessCenterOutlined /></IconButton></Tooltip>
                                                                ) : null}
                                                            </TableCell>
                                                            <TableCell align="left"><IconButton style={{ color: '#b91119' }} onClick={() => handleClickRemoverDestino(index, item.iddestino)}><RemoveCircleOutline /></IconButton></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}
                                </Grid>
                            </Paper>
                        </Grow>
                        <Grow in={grow && categoriaProduto.length > 0 ? true : false}
                            {...(grow && categoriaProduto.length > 0 ? { timeout: 3000 } : {})}
                        >

                            <Paper elevation={5} className={classes.paper}>
                                <Typography variant="h5">Mensagem</Typography>
                                <Grid
                                    container xs={12}
                                    className={classes.linha2}
                                    spacing={2}
                                >
                                    <Grid item xs={!fullScreen ? 6 : 12}>
                                        <USelect itens={statusSolicitacao} disabled={!permissaoDashboard.usuarioLinestur || !editando} value={status} onChange={alterarStatus} setValue={setStatus} label="Status" />
                                    </Grid>
                                    <Grid item xs={!fullScreen ? 6 : 12}>
                                        <USelect itens={prioridades} value={prioridade} setValue={setPrioridade} label="Prioridade" />
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.email} item xs={12}> 
                                    {(permissaoDashboard.usuarioLinestur) || (solicitacao.status = 4) ? (
                                        <Grid item xs={12}>
                                            {/* <Editor
                                                apiKey="29fbwmmgocubh74tk9xjg7hswfikxh1imntkkun15fp16jgu"
                                                name="Mensagem"
                                                onChange={handleChangedRichTextField}
                                                init={{
                                                    height: 400,
                                                    menubar: false,
                                                    language: 'pt_BR',
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        `undo redo | formatselect | bold italic backcolor forecolor |
                                                         alignleft aligncenter alignright alignjustify | 
                                                         bullist numlist outdent indent | removeformat | help`
                                                }}
                                            /> */}
                                            <ReactQuill style={{ height: "50vh" }} value={mensagem} onChange={handleTextChangeQuill} />
                                        </Grid>
                                    ) : null}
                                </Grid>
                                <Grid container xs={12}>
                                    {!editando ? (
                                        <Grid item xs={12}>
                                            <LinesTextField margin="dense" variant="outlined" label="Observação" fullWidth multiline rows={5} />
                                        </Grid>
                                    ) : null}
                                    {editando || permissaoDashboard.usuarioLinestur ? (
                                        <Grid container item xs={12} style={{ margin: 0, padding: 5, display: 'flex', justifyContent: 'flex-end' }}>
                                            <Grid item xs={!fullScreen ? 2 : 12}>
                                                <Button className={classes.button} endIcon={<SendOutlined />} onClick={() => handleClick()}>Enviar</Button>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Paper>
                        </Grow>
                        {
                            solicitacao.idsolicitacao ? (
                                <Grow in={grow && categoriaProduto.length > 0 ? true : false}
                                    {...(grow && categoriaProduto.length > 0 ? { timeout: 4000 } : {})}>
                                    <Paper elevation={5} className={classes.paper}>
                                        <Typography variant="h5">Produtos</Typography>
                                        <Grid container item xs={12}>
                                            {permissaoDashboard.usuarioLinestur ? (
                                                <Grid container item xs={12} spacing={2} style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid item xs={!fullScreen ? 5 : 12}>
                                                        <USearchField {...{ itens: produtos, value: produto, setValue: setProduto, label: 'Produto' }} onChange={() => handleChangedProdutoSearch(produto)} />
                                                    </Grid>
                                                    <Grid item xs={!fullScreen ? 2 : 6} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <USelect itens={moedas} value={moeda} setValue={setMoeda} label="Moeda" />
                                                        <Tooltip title="Selecione sua moeda: Dólar, Euro ou Reais. Para evitar conversão, defina '1' no campo 'Câmbio'. Para converter, preencha 'Preço' com o custo na moeda do local e 'Câmbio' com a taxa de conversão da moeda escolhida." interactive>
                                                            <HelpOutlineOutlined style={{ marginRight: "-9px", marginLeft: "5px" }} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={!fullScreen ? 1 : 6}>
                                                        <LinesTextField margin="dense" variant="outlined" label="Quant." name="unidade" value={prodState.unidade} onChange={handleChangedProduto} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={!fullScreen ? 1 : 6}>
                                                        <LinesTextField margin="dense" variant="outlined" label="Preço" name="preco" value={prodState.preco} onChange={handleChangedProduto} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={!fullScreen ? 1 : 6}>
                                                        <LinesTextField margin="dense" variant="outlined" label="Cambio" name="cambio" value={prodState.cambio} onChange={handleChangedProduto} fullWidth />
                                                    </Grid>
                                                    <Grid item xs={!fullScreen ? 2 : 12}>
                                                        <Tooltip title="Adicionar Produto">
                                                            <Button
                                                                className={classes.button}
                                                                onClick={() => handleClickAdicionarProduto()}
                                                                startIcon={<AddCircleOutline />}
                                                            >Adicionar</Button>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            ) : null}
                                            {fullScreen ? (
                                                <Grid container item xs={12} style={{ maxHeight: 300 }}>
                                                    {solicitacao.produtos.map((item) => (
                                                        <Grid item xs={12}>
                                                            <List>
                                                                <Divider />
                                                                <ListItem>
                                                                    <ListItemText primary={
                                                                        <Tooltip title={`${item.produto.fornecedor.nome} - ${item.produto.nome}`}>
                                                                            <Typography noWrap>
                                                                                {`${item.produto.fornecedor.nome} - ${item.produto.nome}`}
                                                                            </Typography>
                                                                        </Tooltip>
                                                                    }
                                                                        secondary={
                                                                            <React.Fragment>
                                                                                <Tooltip title={`${item.quantidade}x  R$${getValorUnitário(item.total, item.cambio).toFormatDefault()}`}>
                                                                                    <Typography
                                                                                        noWrap
                                                                                        variant="body2"
                                                                                    >
                                                                                        {`${item.quantidade}x  ${getMoedaFormat(item.moeda)}${getValorUnitário(item.total, item.cambio).toFormatDefault()}`}
                                                                                    </Typography>
                                                                                </Tooltip>
                                                                            </React.Fragment>}
                                                                    />
                                                                    <ListItemIcon>
                                                                        {!editando || permissaoDashboard.usuarioLinestur ? (
                                                                            <div>
                                                                                {solicitacao.idsolicitacao && permissaoDashboard.usuarioLinestur ? (
                                                                                    <Tooltip title="Ver/Editar Viajantes Vinculados"><IconButton style={{ color: '#b91119' }} onClick={() => handleClickProdutoViajantes(item)}><AirlineSeatReclineNormalOutlined /></IconButton></Tooltip>
                                                                                ) : null}
                                                                            </div>
                                                                        ) : null}
                                                                    </ListItemIcon>
                                                                    <ListItemIcon>
                                                                        {permissaoDashboard.usuarioLinestur ? (
                                                                            <Tooltip title="Remover Produto"><IconButton style={{ color: '#b91119' }} onClick={() => handleClickRemoverProduto(item.idsolicitacaoproduto)}><RemoveCircleOutline /></IconButton></Tooltip>
                                                                        ) : null}
                                                                    </ListItemIcon>
                                                                </ListItem>
                                                                <Divider />
                                                            </List>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            ) : (
                                                <TableContainer>
                                                    <Table padding="checkbox">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ width: 20 }} ></TableCell>
                                                                <TableCell>Produto</TableCell>
                                                                <TableCell>Moeda</TableCell>
                                                                <TableCell>Quantidade</TableCell>
                                                                <TableCell style={{ width: '15%' }}>Preço</TableCell>
                                                                <TableCell>Cambio</TableCell>
                                                                {permissaoDashboard.usuarioLinestur ? (
                                                                    <TableCell>Viajantes</TableCell>
                                                                ) : null}
                                                                {permissaoDashboard.usuarioLinestur ? (
                                                                    <TableCell></TableCell>
                                                                ) : null}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {solicitacao.produtos.map((item, index) => (
                                                                <TableRow>
                                                                    <TableCell style={{ width: 20 }} ><Typography>{index + 1}</Typography></TableCell>
                                                                    <TableCell><Typography>{`${item.produto.fornecedor.nome} - ${item.produto.nome}`}</Typography></TableCell>
                                                                    {/* <TableCell><Typography>{item.moeda}</Typography></TableCell> */}
                                                                    <TableCell><Typography>{getMoeda(item.moeda)}</Typography></TableCell>
                                                                    <TableCell><Typography>{item.quantidade}</Typography></TableCell>
                                                                    <TableCell style={{ width: '15%' }}>
                                                                        <Typography>
                                                                            R$ {getValorUnitário(item.total, item.cambio).toFormatDefault()}
                                                                            {Number(item.cambio) !== 1 ? (
                                                                                <>
                                                                                    {" - " + getMoedaFormat(item.moeda)} {item.total.toFormatDefault()}
                                                                                </>
                                                                            ) : (
                                                                                null
                                                                            )}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell><Typography>{Number(item.cambio).toFormatDefault()}</Typography></TableCell>
                                                                    {permissaoDashboard.usuarioLinestur ? (
                                                                        <TableCell><Tooltip title="Ver/Editar Viajantes Vinculados"><IconButton style={{ color: '#b91119' }} onClick={() => handleClickProdutoViajantes(item)}><AirlineSeatReclineNormalOutlined /></IconButton></Tooltip></TableCell>
                                                                    ) : null}
                                                                    {permissaoDashboard.usuarioLinestur ? (
                                                                        <TableCell><Tooltip title="Remover Produto"><IconButton style={{ color: '#b91119' }} onClick={() => handleClickRemoverProduto(item.idsolicitacaoproduto)}><RemoveCircleOutline /></IconButton></Tooltip></TableCell>
                                                                    ) : null}
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </Grid>
                                    </Paper>
                                </Grow>
                            ) : null
                        }
                        {
                            !solicitacao.idsolicitacao ? (
                                <Grid xs={12} container spacing={1} className={classes.linhaBotao}>
                                    <Grid item xs={fullScreen ? 12 : 2}>
                                        <Button fullWidth onClick={() => criarSolicitacao()} className={classes.button}>Criar Solicitação</Button>
                                    </Grid>
                                    <Grid item xs={fullScreen ? 12 : 2} >
                                        <Button fullWidth className={classes.button} onClick={() => { props.history.push('/listaReservas') }}>Cancelar</Button>
                                    </Grid>
                                </Grid>
                            ) : null
                        }
                    </div >
                ) : null
            }
        </div >
    )
};

export const Cadastro = props => {
    return (
        <div>
            <Dashboard {...props} component={CadastroSolicitacao} />
        </div>
    )
};
export default Cadastro;

