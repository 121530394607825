import { Button, Grid, List, ListItem, Tooltip, Typography } from "@material-ui/core";
import { ImportExportOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { DialogExportExcel } from "../../DialogExportExcel";
import { useStyles } from "./styles";

export const EcoGestaoResumoMobi = (props) => {
    const classes = useStyles();
    const data = props.dataList.grupos;
    const [openConfirmDialogExport, setOpenConfirmDialogExport] = useState(false)

    const handleClickDialogExport = () => {
        setOpenConfirmDialogExport(true)
    }

    return (
        <>
            {openConfirmDialogExport ? (
                <Tooltip title={<Typography>Exportar resumo</Typography>}>
                    <DialogExportExcel
                        {...{ openConfirmDialogExport, setOpenConfirmDialogExport }}
                    />
                </Tooltip>
            ) : null}
            {data.length === 0 ? (
                <Typography variant="h6" className={classes.gridTitle}> Não há registros para essa data </Typography>
            ) : (
                <Grid container >
                    <Grid item xs={12} className={classes.gridTitle}>
                        <Typography variant="h5">Data: <b>{props.dataPartida.toLocaleDateString('pt-Br')}</b></Typography>
                        {/* <Button onClick={() => handleClickDialogExport()} className={classes.button} variant="contained" fullWidth >
                            <ImportExportOutlined />
                        </Button> */}
                    </Grid>
                    <List>
                        {data.map((item) => (
                            <ListItem className={classes.listItemCustom}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} className={classes.gridTitle}>
                                        <Typography variant="h6"><b>Nº {item.idgrouptenant} - {item.nome}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridTitle}>
                                        <Typography variant="h6"> <b>{item.cidade}</b></Typography>
                                        <Typography className={classes.typoLittleFont}>Aeroporto: {item.aeroporto}</Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.gridTitle}>
                                        <Typography>Passageiro(s)</Typography>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <List divider>
                                            {item.passageiros.map((pax) => (
                                                <ListItem className={classes.listItemPax}>
                                                    <Grid container>
                                                        <Grid item xs={12} className={classes.gridTitle}>
                                                            <Typography><b>{pax.nome}</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.gridTitle}>
                                                            <Typography >Horário: </Typography>
                                                            <Typography variant="h6"><b>{pax.horadesc} - {pax.hora}</b> </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.gridTitle}>
                                                            <Typography >{pax.cc}</Typography>
                                                            <Typography>{pax.ccNome}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.gridTitle}>
                                                            <Typography >Emissão CO2 (kg):</Typography>
                                                            <Typography variant="h6" style={item.isEco ? { color: '#43a047' } : { color: '#c62828' }}><b>{pax.carbono}</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={12} className={classes.gridTitle}>
                                                            <Typography>Endereço: {pax.pontoPartida}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            )}
        </>
    );
};

export default EcoGestaoResumoMobi;
