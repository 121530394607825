import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { Info, MoreVertOutlined, NotificationImportant } from '@mui/icons-material';
import { fTime } from '../../../../../utils/format-time';
import { Badge, Tooltip, Typography, alpha, styled } from '@mui/material';
import { formatarParaNomeStatusAdiantamento, formatarParaNomeStatusAdiantamentoList, formatarParaNomeStatusAdiantamentoListI, formatarParaNomeStatusViagem } from '../../../../../enums/enumPrestacaoDeContas';
import DialogComponent from '../dialogs-desktop';
import { DetalhesViagemComponent } from '../../auditor/detalhesViagem';
import Menu from '@mui/material/Menu';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useState } from 'react';
import { AprovarDespesasComponent } from '../../auditor/aprovarDespesas';
import { ControladoriaDespesasComponent } from '../../auditor/controladoriaDespesas';
import { stringAvatar } from '../../../../../theme/palette';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function OrderTableRow({ permissions, row, data, selected, onViewRow, onSelectRow, onDeleteRow, handleClickEditDespesa, getViagensReload }) {
  const [openDetails, setOpenDetails] = useState(false);
  const [openApprove, setOpenApprove] = useState(null);
  const [openControladoria, setOpenControladoria] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [viagem, setViagem] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDetails = () => {
    setViagem(row);
    setOpenDetails(true);
    handleClose(false);
  };

  const handleClickApprove = () => {
    setViagem(row);
    setOpenApprove(true);
    handleClose(false);
  };

  const handleClickControladoria = () => {
    setViagem(row);
    setOpenControladoria(true);
    handleClose(false);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const { status } = row;

  const renderPrimary = (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>

        <Avatar {...stringAvatar(row.pessoa.nome)} src={row.pessoa.img} style={{ width: 48, height: 48 }} />

        <ListItemText
          primary={row.titulo}
          secondary={row.pessoa.nome}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
            //   style: {
            //     display: 'inline-block',
            //     minWidth: '100px', // Defina o limite de largura desejado
            //     whiteSpace: 'nowrap',
            //     overflowX: 'auto',
            //   },
            // }}
            // style={{
            //   display: 'inline-block',
            //   minWidth: '200px', // Defina o limite de largura desejado
            //   whiteSpace: 'nowrap',
            //   overflowX: 'auto',
          }}
        />

      </TableCell>

      <TableCell>
        <ListItemText
          primary={capitalizeFirstLetter(format(new Date(row.datadecriacao), "EEE'.' d MMM 'de' yyyy", { locale: ptBR }))}
          secondary={fTime(row.datadecriacao)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>

      <TableCell align="center">
        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.total)}
      </TableCell>

      <TableCell align="center">
        {row.tem_multimonetario ? (
          <>
            {formatarParaNomeStatusAdiantamentoListI(row.adiantamento)}
          </>
        ) : (
          <>
            <Tooltip title={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.totalinicial)}>
              {formatarParaNomeStatusAdiantamentoList(row.adiantamento)}
            </Tooltip>
          </>
        )}
      </TableCell>

      <TableCell align="center">
        <Typography
          variant="soft"
          color={
            (status === 'completed' && 'success') ||
            (status === 'pending' && 'warning') ||
            (status === 'cancelled' && 'error') ||
            'default'
          }
        >
          {row.status != null ? (
            <>
              {formatarParaNomeStatusViagem(row.status)}
            </>
          ) : (<>
          </>)}
        </Typography>
      </TableCell>

      <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleClickDetails()} disableRipple>
            <Info />
            Detalhes
          </MenuItem>
          {row.status === 3 && permissions.permissao_controladoria && (
            <MenuItem onClick={() => handleClickControladoria()} disableRipple>
              <AdminPanelSettingsIcon />
              Controladoria
            </MenuItem>
          )}
          {(row.status === 8 || row.status === 9 || row.status === 10 || row.status === 2) && permissions.permissao_contabilidade && (
            <MenuItem onClick={() => handleClickApprove()} disableRipple>
              <AssignmentTurnedInIcon />
              Aprovar
            </MenuItem>
          )}
        </StyledMenu>

        <Badge
          color="error"
          badgeContent={
            <Tooltip title="Fora da política">
              <NotificationImportant fontSize="small" style={{ color: "#FFFFFF" }} />
            </Tooltip>
          }
          invisible={row.necessita_atencao != null && row.necessita_atencao ? false : true}
        >
          <IconButton
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
          >
            <MoreVertOutlined />
          </IconButton>
        </Badge>

        {viagem != null && (
          <>
            <DialogComponent
              title={
                <>
                  {viagem?.titulo} {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                </>
              }
              open={openDetails}
              handleClose={() => setOpenDetails(false)}
              body={<DetalhesViagemComponent viagem={viagem} />}
              action={null}
              width="sm"
            />

            <DialogComponent
              title={
                <>
                  {viagem?.titulo} {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                </>
              }
              open={openApprove}
              handleClose={() => setOpenApprove(false)}
              body={<AprovarDespesasComponent viagem={viagem} setViagem={setViagem} setOpen={setOpenApprove} getViagensReload={getViagensReload} />}
              action={null}
              width="md"
            />

            <DialogComponent
              title={
                <>
                  {viagem?.titulo} {viagem ? formatarParaNomeStatusViagem(viagem.status) : null}
                </>
              }
              open={openControladoria}
              handleClose={() => setOpenControladoria(false)}
              body={<ControladoriaDespesasComponent viagem={viagem} setViagem={setViagem} setOpen={setOpenApprove} getViagensReload={getViagensReload} handleClickEditDespesa={handleClickEditDespesa} />}
              action={null}
              width="md"
            />
          </>
        )}

      </TableCell>
    </TableRow>
  );

  return (
    <>
      {renderPrimary}
    </>
  );
}

OrderTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  selected: PropTypes.bool,
  onViewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};
