import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import Enums from "../../../../enums";

export const MenuAction = (props) => {
    // const open = props.open;
    const anchor = props.anchor

    const handleAcaoClose = () => {
        props.setOpen(false)
    }

    const showBotaoEmitir = () => {
        let mostrar = props.bookingSelected.status !== Enums.reservaStatus.emitido &&
            props.bookingSelected.status !== Enums.reservaStatus.emissaoNegada &&
            props.bookingSelected.status !== Enums.reservaStatus.cancelado &&
            props.bookingSelected.status !== Enums.reservaStatus.encerrado &&
            props.bookingSelected.status !== Enums.reservaStatus.expirado &&
            props.bookingSelected.status !== Enums.reservaStatus.limiteExcedido &&
            props.bookingSelected.status !== Enums.reservaStatus.autorizacaoPendente &&
            props.bookingSelected.status !== Enums.reservaStatus.aprovacaoPendente &&
            props.permissaoDashboard.usuarioLinestur && props.permissaoDashboard.permiteEmitir;
        return mostrar;
    };

    const showBotaoCancelar = () => {
        let mostrar = props.bookingSelected.status !== Enums.reservaStatus.emitido &&
            props.bookingSelected.status !== Enums.reservaStatus.emissaoNegada &&
            props.bookingSelected.status !== Enums.reservaStatus.cancelado &&
            props.bookingSelected.status !== Enums.reservaStatus.encerrado &&
            props.bookingSelected.status !== Enums.reservaStatus.expirado &&
            props.bookingSelected.status !== Enums.reservaStatus.emissaoPendente &&
            props.bookingSelected.status !== Enums.reservaStatus.limiteExcedido
        return mostrar;
    };

    const showBotaoCancelarHotel = () => {
        let mostrar =
            props.bookingSelected.status === Enums.reservaStatus.emissaoPendente ||
            props.bookingSelected.status === Enums.reservaStatus.reservado ||
            props.bookingSelected.status === Enums.reservaStatus.autorizacaoPendente ||
            props.bookingSelected.status === Enums.reservaStatus.aprovacaoPendente ||
            props.bookingSelected.status === Enums.reservaStatus.emitido
        return mostrar;
    };

    return (
        <>
            <Menu
                id="menuActionListBooking"
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleAcaoClose}>
                {props.bookingSelected.tipo === 2 && props.permissaoDashboard.usuarioLinestur ? (
                    <MenuItem onClick={() => { props.handleClickOpenChangeCreatedDate(); handleAcaoClose(); }}>Alterar data de criação</MenuItem>
                ) : null}
                {props.bookingSelected.tipo === 1 && showBotaoEmitir() ? (
                    <MenuItem onClick={() => { props.handleClickOpenBookingIssue(); handleAcaoClose(); }}>Emitir</MenuItem>
                ) : null}
                {props.bookingSelected.tipo === 3 && showBotaoEmitir() ? (
                    <MenuItem onClick={() => { props.handleClickOpenHotelIssue(); handleAcaoClose(); }}>Emitir2</MenuItem>
                ) : null}
                {props.bookingSelected.tipo === 3 && showBotaoCancelarHotel() && props.permissaoDashboard.usuarioLinestur ? (
                    <MenuItem onClick={() => { props.handleClickOpenHotelCancel(); handleAcaoClose(); }}>Cancelar</MenuItem>
                ) : null}
                <MenuItem onClick={() => { props.handleClickOpenHistory(); handleAcaoClose(); }}>Histórico</MenuItem>
                {props.bookingSelected.tipo === 1 ? (
                    <>
                        <MenuItem onClick={() => { props.handleClickSincronizarAereo(); handleAcaoClose() }}>Sincronizar</MenuItem>
                        <MenuItem onClick={() => { props.handleClickOpenSendEmail(); handleAcaoClose(); }}>Enviar por Email</MenuItem>
                    </>
                ) : null}
                {/* {props.bookingSelected.status === 7 && props.bookingSelected.tipo === 1 ? (
                    <> */}
                {/* <MenuItem onClick={() => setOpenDialogReembolso(true)}>
                  Reembolsar
                </MenuItem> */}

                {/* </>
                ) : null} */}
                {showBotaoCancelar() ? (
                    <>
                        <MenuItem onClick={() => { props.handleClickOpenHistory(); handleAcaoClose(); }}>Autorizar/Negar</MenuItem>
                        {props.bookingSelected.tipo === 1 ? (
                            <MenuItem onClick={() => { props.handleClickOpenCancelBooking(); handleAcaoClose(); }}>Cancelar</MenuItem>
                        ) : null}
                    </>
                ) : null}
            </Menu>
        </>
    )
}
