import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left'
    },
    linha2: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center',
    },
    genero: {
        width: '100%',
    },
    button: {
        background: '#c62828',
        color: 'white',
        '&:hover': {
            background: '#b91119'
        }
    }
}));
