import cookie from 'universal-cookie';
import api from '../../Api';

const cookies = new cookie();


const permissaoRepositorio = {

    salvar: async (permissao) => {
        const response = await api.put('/Permissao/' + permissao.idpermissao, permissao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });

        return response;
    },

    getUsuarioPermissao: async (idusuario) => {
        const response = await api.get('/Permissao/GetUsuarioPermissao', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                id: idusuario
            }
        });

        return response;
    },

    getPorSessao: async () => {
        const response = await api.get('/Permissao/GetSessionPermissao', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });

        return response;
    },

    getDadosDashboard: async () => {
        const response = await api.get('/Permissao/GetDadosDashboard', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                sessionId: cookies.get('sessionId')
            }
        });

        return response;
    },

    temPermissao: async (modulo, acao) => {
        const dtoVerificarPermissao = {
            sessionId: cookies.get('sessionId'),
            acao: acao,
            modulo: modulo
        };
        const response = await api.post('/Permissao/TemPermissao', dtoVerificarPermissao, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
            }
        });
        return response;
    },
}

export default permissaoRepositorio;
