import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

export const ComprasRealizadasChart = props => {
    const options = {
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis: {
            categories: [''],
        },
        tooltip: {
            valuePrefix: 'R$',
        },
        title: {
            text: 'Relação de Compras Realizadas'
        },
        series: [{
            type: 'column',

            name: 'Aéreo Nacional',
            data: [props.data.totalAereoNacional]
        }, {
            type: 'column',
            name: 'Aéreo Internacional',
            data: [props.data.totalAereoInternacional]
        }, {
            type: 'column',
            name: 'Carro',
            data: [props.data.totalCarro]
        }, {
            type: 'column',
            name: 'Hotel',
            data: [props.data.totalHotel]
        }, {
            type: 'column',
            name: 'Ônibus',
            data: [props.data.totalOnibus]
        }, {
            type: 'column',
            name: 'Outros',
            data: [props.data.totalOutros]
        }, {
            type: 'column',
            name: 'Pacotes',
            data: [props.data.totalPacote]
        },]
    }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
};

export default ComprasRealizadasChart;
