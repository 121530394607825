import React, { useState } from 'react';
import { Button, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Switch, FormControlLabel, FormGroup, Grid, Typography, IconButton, Box, TextField, Autocomplete, Chip } from '@mui/material';
import VercelIcon from '@mui/icons-material/Cloud'; // Placeholder icon for Vercel
import Auth0Icon from '@mui/icons-material/Security'; // Placeholder icon for Auth0
import GoogleCalendarIcon from '@mui/icons-material/Event'; // Placeholder icon for Google Calendar
import StripeIcon from '@mui/icons-material/Payment'; // Placeholder icon for Stripe
import StreetviewIcon from '@mui/icons-material/Streetview';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { AddCircleOutline, CreditCard, DeleteOutline, Edit, EditOutlined } from '@mui/icons-material';
import { UserRoleCard } from '../../../../../../components/autalii_expanses/minicardgroupperson';
import DialogComponent from '../../../../Desktop/sections/dialogs-desktop';
import { useCustomSnackbar } from '../../../../../../components/snackbar2/NewSnackBar';
// import { UserRoleCard } from '../../../../../../components/autalii_expanses/minicardgroupperson';
// import DialogComponent from '../../../sections/dialogs-desktop';
// import { useCustomSnackbar } from '../../../../../../components/snackbar2/NewSnackBar';

const PalavrasConfigQrCode = ({ users, grupos, handleClickCreateGroup, handleClickEditGroup, handleClickExcluirGroup }) => {
    const [addNewGroup, setAddNewGroup] = useState(false);
    const [editGroup, setEditGroup] = useState(false)
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState([]);
    const [value, setValue] = useState([]);
    const [titulo, setTitulo] = useState("");
    const [id, setId] = useState(0);

    const { enqueueSnackbar } = useCustomSnackbar();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            setTags((prevTags) => [...prevTags, inputValue.trim()]);
            setInputValue('');
            event.preventDefault(); // Previne a submissão do formulário
        }
    };

    const handleDeleteTag = (tagToDelete) => () => {
        setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    };

    const handleClickCreate = () => {
        if (titulo === "") {
            enqueueSnackbar('Adicione um título ao grupo.', { variant: 'warning' });
            return
        }

        handleClickCreateGroup(
            {
                wordsnotpermited: tags,
                nameconfig: titulo
            }
        )
        setAddNewGroup(false)
    }

    const handleClickEdit = () => {
        if (titulo === "") {
            enqueueSnackbar('Adicione um título ao grupo.', { variant: 'warning' });
            return
        }

        handleClickEditGroup(
            id,
            {
                wordsnotpermited: tags,
                nameconfig: titulo
            }
        )
        setEditGroup(false)
    }

    const handleClickConfirmExcluir = () => {
        handleClickExcluirGroup(id)
        setEditGroup(false)
    }

    const handleSelectEditGroup = (item) => {
        setTitulo(item.nameconfig)
        setTags(item.wordsnotpermited)
        setId(item.id_config_group)
        setEditGroup(true)
    }

    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <DialogComponent
                    title={"Novo grupo de palavras"}
                    open={addNewGroup}
                    handleClose={() => setAddNewGroup(false)}
                    body={
                        <Grid container item xs={12} style={{ width: "400px", maxWidth: "auto" }}>
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Título"
                                    onChange={(e) => setTitulo(e.target.value)}
                                    color="primary"
                                    style={{ borderRadius: "15px", marginTop: "2px" }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                <TextField
                                    label="Palavras"
                                    placeholder="Escreva e pressione enter"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    {tags?.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            onDelete={handleDeleteTag(tag)}
                                            variant="outlined"
                                            style={{ backgroundColor: "#FFFFFF" }}
                                        />
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    action={<>
                        <Button onClick={() => handleClickCreate()}>Criar grupo</Button>
                    </>}
                    width={"xl"}
                />
                <DialogComponent
                    title={"Editar grupo"}
                    open={editGroup}
                    handleClose={() => setEditGroup(false)}
                    body={
                        <Grid container item xs={12} style={{ width: "400px", maxWidth: "auto" }}>
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label="Título"
                                    defaultValue={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                    color="primary"
                                    style={{ borderRadius: "15px", marginTop: "2px" }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "10px" }}>
                                <TextField
                                    label="Palavras"
                                    placeholder="Escreva e pressione enter"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                    <Typography variant="body1">
                                        {tags?.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={tag}
                                                onDelete={handleDeleteTag(tag)}
                                                variant="outlined"
                                                style={{ backgroundColor: "#FFFFFF", fontFamily: "sans-serif" }}
                                            />
                                        ))}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    action={<>
                        <Button color="error" variant="outlined" onClick={() => handleClickConfirmExcluir()}>Excluir</Button>
                        <Button variant="outlined" onClick={() => handleClickEdit()}>Editar</Button>
                    </>}
                    width={"xl"}
                />
                <Box
                    gap={3}
                    display="grid"
                    gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(3, 1fr)',
                    }}
                >
                    {grupos.lenght != 0 ? (
                        <>
                            {grupos.map((item) => (
                                <UserRoleCard role={item.nameconfig} palavras={item.wordsnotpermited} onSelect={() => handleSelectEditGroup(item)} />
                            ))}
                            <List onClick={() => setAddNewGroup(true)} style={{ width: "100%", boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.5)", borderRadius: "10px", cursor: "pointer" }}>
                                <ListItem style={{
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}>
                                    <Typography variant="h5">Adicionar</Typography>
                                    <AddCircleOutline />
                                </ListItem>
                            </List>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </Box>
            </Grid>
        </Grid >
    );
};

export default PalavrasConfigQrCode;
