import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputBase, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, EditRounded, Search } from '@material-ui/icons';
import React, { useState } from 'react';
import Draggable from 'react-draggable';
import cookie from 'universal-cookie';
import api from '../../../Api';
import LoadingButton from '../../../components/loadingButton';
import Snackbar from '../../../components/snackbar';
import UFloatButton from '../../../components/ufloatbutton';
import Enums from '../../../enums';
import permissaoRepositorio from '../../../repositorios/permissao';
import Dashboard from '../../dashboard';
import CadastroProduto from '../cadastro';
import { useStyles } from './styles';

const PaperComponent = props => {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

export const ListaProdutos = props => {
    const classes = useStyles();
    const cookies = new cookie();
    const theme = useTheme();
    const matches = useMediaQuery('(max-width:480px)');
    const matches2 = useMediaQuery('(max-width:900px) and (orientation: landscape)');
    const matches3 = useMediaQuery('(min-width:600px) and (orientation: portrait)');
    const [validacao, setValidacao] = useState({ visible: false });
    const [mensagemValidacao, setMensagemValidacao] = useState('');
    const [produtos, setProdutos] = useState([]);
    const [produtosPesquisa, setProdutosPesquisa] = useState([]);
    const [produto, setProduto] = useState([])
    const [fornecedor, setFornecedor] = useState({});
    const [picture, setPicture] = useState(null);

    const [snackbarError, setSnackbarError] = useState({
        visible: false
    });

    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [cadastroOpen, setCadastroOpen] = useState(false);

    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [itensPerPage, setItensPergPage] = useState(10);
    const pages = produtosPesquisa.length;
    const start = page * itensPerPage;
    const r = itensPerPage + start;
    const todosProdutos = produtosPesquisa.slice(start, r);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setItensPergPage(event.target.value);
    };

    const onPesquisar = event => {
        const contem = produtos.filter(item => {
            return (item.nome.toLowerCase().includes(event.target.value.toLowerCase()));
        })
        setProdutosPesquisa(contem);
    }

    const salvar = async () => {
        const dto = {
            ...produto,
            sessionId: cookies.get('sessionId'),
            picture: picture

        }
        if (!produto.nome) {
            setMensagemValidacao('O campo nome não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!produto.categoria) {
            setMensagemValidacao('O categoria não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!produto.idfornecedor) {
            setMensagemValidacao('O campo fornecedor não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (produto.idproduto) {
            const temPermissaoEditar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.produto, Enums.acaoRequerida.editar)
            if (!temPermissaoEditar.data) {
                setMensagemValidacao('Você não possui permissão!');
                setValidacao({ visible: true });
                return
            }
            api.put('/produto/' + produto.idproduto, produto, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }

            }).then(response => {
                if (response.status !== 204) {
                    setSnackbarError({ visible: true });
                    return response;
                }
                api.get('/produto', {
                    headers: {
                        Authorization: 'Bearer ' + cookies.get('token')
                    }
                }).then(resp => {
                    setProdutos(resp.data);
                    setProdutosPesquisa(resp.data);
                    setSnackbarSucess({ visible: true });
                    return response;
                })
                setCadastroOpen(false);
            })
            return;
        }
        const temPermissaoCriar = await permissaoRepositorio.temPermissao(Enums.moduloSistema.produto, Enums.acaoRequerida.criar)
        if (!temPermissaoCriar.data) {
            setMensagemValidacao('Você não possui permissão!');
            setValidacao({ visible: true });
            return
        }

        api.post('/produto', produto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            if (response.status !== 201) {
                setSnackbarError({ visible: true });
                return response;

            }
            api.get('/produto', {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            }).then(resp => {
                setProdutos(resp.data);
                setProdutosPesquisa(resp.data);
                setSnackbarSucess({ visible: true });
                return response;
            })
            setCadastroOpen(false);
        })

    }

    const handleCadastroOpen = (item, visible) => {
        setProduto(item);
        setCadastroOpen(visible);
    }

    useState(() => {
        api.get('/produto', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            setProdutos(response.data);
            setProdutosPesquisa(response.data);
        })
    }, [])

    const handleModalClose = () => {
        setCadastroOpen(false);
    }

    return (
        <div className={classes.root}>
            <Snackbar {...{ variant: 'error', message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <Snackbar {...{ variant: 'success', message: 'Dados salvos com sucesso!', visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Dialog
                className={classes.cadastro}
                PaperComponent={PaperComponent}
                open={cadastroOpen}
                onClose={handleModalClose}
                fullScreen={fullscreen}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.appbar}>
                        <Typography variant="h6" className={classes.title}>
                            Produto
                        </Typography>
                        <IconButton className={classes.fechar} onClick={handleModalClose}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <CadastroProduto {...{ produto: produto, setProduto: setProduto, picture: picture, setPicture: setPicture }} />
                    <div className={classes.salvar}>
                        <LoadingButton {...{ salvar: salvar, modalOpen: cadastroOpen, setModalOpen: setCadastroOpen }} />
                    </div>
                </DialogContent>
            </Dialog>
            <Paper elevation={!fullscreen ? 5 : 0}>
                <Toolbar className={classes.toolbar}>
                    {!matches && !matches2 && !matches3 ? (<Button className={classes.button} onClick={e => handleCadastroOpen({ produto: {} }, true)}>Novo</Button>) : (<UFloatButton actions={[]} handleClick={e => handleCadastroOpen({ tipousuario: 1 }, true)} />)}
                    <TablePagination
                        component="div"
                        count={pages}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[10, 25, 50]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <div className={classes.search3}>
                        <div className={classes.searchIcon}>
                            <Search />
                        </div>

                        <InputBase
                            onChange={onPesquisar}
                            placeholder="Pesquise…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                </Toolbar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center">ID</TableCell>
                            <TableCell align="center">Nome</TableCell>
                            {!matches && !matches2 && !matches3 ? (<TableCell align="center">Categoria</TableCell>) : null}
                            {!matches && !matches2 && !matches3 ? (<TableCell align="center">status</TableCell>) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {todosProdutos.map(item => (
                            <TableRow className={classes.row} key={item.idproduto}>
                                <TableCell className={classes.bodys} align="center" >
                                    <IconButton onClick={e => handleCadastroOpen(item, true)} margin="small">
                                        <EditRounded fontSize="default" />
                                    </IconButton>
                                </TableCell>
                                <TableCell className={classes.bodys} align="center">{item.idproduto}</TableCell>
                                <TableCell className={classes.bodys} align="center">{item.nome}</TableCell>
                                {!matches && !matches2 && !matches3 ? (<TableCell className={classes.bodys} align="center">{item.categoria}</TableCell>) : null}
                                {!matches && !matches2 && !matches3 ? (<TableCell className={classes.bodys} align="center">{item.ativo ? 'Ativo' : 'Inativo'}</TableCell>) : null}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
};

export const Lista = props => {
    return (
        <div>
            <Dashboard {...props} component={ListaProdutos} />
        </div>
    )
};
export default Lista;
