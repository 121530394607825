import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119'
        }
    },

}));
