import { makeStyles, fade } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors'

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      marginBottom: theme.spacing(7)
    }
  },

  heads: {
    background: '#fafafa'
  },
  bodys: {
    fontSize: 12,
    borderBottom: 0
  },
  toolbar: {
    padding: '5px 10px 10px 10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'silver',
  },
  buttonSalvar: {
    background: green[500],
    color: 'white',
    "&:hover": {
      background: green[900]
    }
  },
  fechar: {
    color: 'white',
  },
  divtitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.2, 2)
  },
  search3: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  row: {
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  },
  button: {
    width: '100px',
    display: 'block',
    background: '#b91119',
    color: 'white',
    '&:hover': {
      background: '#810b11'
    },
    marginRight: '15px',
    height: 35,
    fontSize: '9px'
  },

  cadastro: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(0),
    zIndex: 1300
  },
  salvar: {
    width: '100%',


  },
}));
