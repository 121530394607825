import { makeStyles } from "@material-ui/core/styles";
import { de } from "date-fns/locale";

export const useStyles = makeStyles((theme) => ({
  GlobalGridResum1: {
    marginTop: "5px"
  },
  GlobalGridResum2: {
    marginTop: "5px",
    marginBottom: "12px"
  },
  GlobalGridResum3: {
    marginTop: "0px"
  },
  header: {
    flexDirection: "column",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    "& h1": {
      fontFamily: "sans-serif",
      fontStyle: "normal",
      fontWeight: "Bold",
      fontSize: "20px",
    },
    "& h2": {
      fontSize: "14px",
      fontFamily: "sans-serif",
      fontWeight: "normal",
      "& span": {
        color: "#A6A6A6",
      },
    },
    "& h3": {
      fontSize: "14px",
      fontWeight: "700",
      fontFamily: "sans-serif",
    },
    "& h4": {
      fontSize: "14px",
      fontWeight: "bolder",
      fontFamily: "sans-serif",
      color: "#A6A6A6",
    },
  },
  buttonGrid: {
    alignItems: "center",
    textAlign: "center",
    flexDirection: "center",
    justifyContent: "center",
    display: "flex",
    "& button": {
      backgroundColor: "#B91119",
      color: "white",
      width: "auto",
    },
  },
  ButtonFonts: {
    color: "#c62828",
    fontFamily: "sans-serif",
    fontWeight: "bold",
  },
  resumCenter: {
    alignItems: "center",
    textAlign: "center",
    flexDirection: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: "0px",
  },
  resumVooCenter: {
    padding: "8%",
    fontStyle: "initial",
    marginTop: "15px",
    textAlign: "center",
    "& h1": {
      fontSize: "16px",
      fontWeight: "bold",
      fontFamily: "sans-serif",
      color: "#A6A6A6",
    },
    "& h2": {
      fontSize: "16px",
      fontWeight: "normal",
      fontFamily: "sans-serif",
      marginTop: "-15px",
    },
  },
  break: {
    textAlign: "center",
    marginBottom: "20px",
    "& hr": {
      backgroundColor: "#BABABA",
      height: "1px",
      width: "90%",
      border: "none",
    },
  },
  ResumoPVoo: {
    color: "black",
    textAlign: "center",
    fontFamily: "sans-serif",
    flexDirection: "row",
    justifyContent: "center",
    "& h1": {
      marginTop: "15px",
      fontWeight: "600",
      fontSize: "24px",
    },
    "& h2": {
      marginTop: "-4px",
      fontWeight: "500",
      fontSize: "16px",
    },
    "& h3": {
      fontWeight: "500",
      color: "rgba(0,0,0,0.7)",
      fontSize: "16px",
    },
  },
  GridPassageiros: {
    color: "#FFFFFF",
    fontFamily: "sans-serif",
    "& h1": {
      fontWeight: "500",
      fontSize: "16px",
    },
    "& h2": {
      fontSize: "18px",
      fontWeight: "600",
    },
    "& h3": {
      marginTop: "-4px",
      fontWeight: "500",
      color: "#BABABA",
      fontSize: "16px",
    },
    "& h6": {
      fontSize: "18px",
      fontWeight: "500",
    },
    "& div": {
      marginTop: "15px"
    }
  },
  VooResum: {
    textAlign: "center",
    color: "#FFFFFF",
    fontFamily: "sans-serif",
    "& h1": {
      fontSize: "14px",
      fontWeight: "700px",
      marginBottom: "0px",
    },
    "& h2": {
      fontSize: "20px",
      fontWeight: "700px",
      marginBottom: "0px",
    },
    "& h3": {
      fontSize: "10px",
      fontWeight: "700px",
      marginBottom: "0px",
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "600px",
    },
    "& h5": {
      fontSize: "16px",
      fontWeight: "500px",
    },
    "& h6": {
      fontSize: "18px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "10px",
      fontWeight: "700px",
      marginBottom: "-8px",
    },
  },
  values: {
    backgroundColor: "#C62828",
    color: "#FFFFFF",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    textAlign: "left",
    fontWeight: "500",
    "& h1": {
      fontStyle: "normal",
      textAlign: "center",
      fontWeight: "600",
      fontSize: "16px",
    },
    "& h2": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
    },
    "& div": {
      marginTop: "8px",
    }
  },
  resumValues: {
    textAlign: "center",
    color: "#FFFFFF",
    borderColor: "white",
    borderRadius: "10px",
    border: "1px solid #FFFFFF",
    padding: "10px",
    "& h1": {
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& h2": {
      fontWeight: "500",
      fontSize: "16px",
    },
  },
  marginTop: {
    marginTop: "12px",
  },
  detailsVoo: {
    backgroundColor: "#C62828",
    borderRadius: "20px 20px 0px 0px",
    margin: "0px",
    transition: "margin-top 2s",
    webkitBoxShadow: "0px 0px 2px 1px rgba(0,0,0,0.61)",
    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.61)",
  },
  accordionsList: {
    margin: 0,
  },
  accordion: {
    backgroundColor: "#C62828",
    color: "#FFFFFF",
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "500",
    "& h6": {
      fontSize: "17px",
      fontWeight: "bold"
    }
  },
  accordionSubGrid: {
    width: "100%",
    padding: "0px",
    margin: "0px"
  },
  accordionValues: {
    padding: "0px",
  },
  info: {
    marginTop: "10px",
    color: "white",
    padding: "10px",
    fontFamily: "sans-serif",
    "& li": {
      marginTop: "10px",
      marginBottom: "10px"
    }
  },
  tituloDetails: {
    fontFamily: "sans-serif",
    fontSize: "16px",
    color: "rgba(255, 255, 255, 0.7)",
    fontWeight: "bold",
    padding: "10px",
  },
  colorIcon: {
    color: "#FFFFFF"
  },
  center: {
    textAlign: "center"
  }
}));
