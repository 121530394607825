import { Paper, Typography, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EnumTipoVeiculo } from '../../../../../../../enums/enumVeiculos';
import { GoogleMap, Polyline, useJsApiLoader } from '@react-google-maps/api';
import { primary } from '../../../../../../../theme/palette';
import { CreationDate } from '../../../../../Desktop/sections';
import { tiposdehodometro_Mock } from '../../../../../../../mock/tiposdespesas';
import { useCustomSnackbar } from '../../../../../../../components/snackbar2/NewSnackBar';
import prestacaoDeContasRepositorio from '../../../../../../../repositorios/prestacaodecontas';

const apiKey = "AIzaSyDwG3G11O_o14Bax96T3qNQ7b6Pn-n5ldw"; // !!!!!!!!!!!!!!!! ESCONDER ISSO EM ALGUM LUGAR !!!!!!!!!!!!!!
const centerprimary = { lat: -33.8667, lng: 151.1955 };
const libraries = ["places", "drawing", "geometry"];

export const EditHodometerGoogleMaps = ({ viagemid, hodometro, editHodometro, setType }) => {
    const mapRef = useRef(null);
    const onLoad = useCallback((map) => (mapRef.current = map), []);

    const [descricao, setDescricao] = useState(hodometro.descricao);
    const [inicialFoto, setInicialFoto] = useState(hodometro.hodometro.comprovanteinicial);
    const [finalFoto, setFinalFoto] = useState(null);
    const [inicialKm, setInicialKm] = useState(hodometro.hodometro.kminicial);
    const [finalKm, setFinalKm] = useState(hodometro.hodometro.kmfinal);
    const [datadecompra, setDatadecompra] = useState(new Date());
    const [tipoveiculo, setTipoveiculo] = useState(hodometro.hodometro.categoria);
    const [tipocombustivel, setTipocombustivel] = useState(hodometro.hodometro.combustivel);
    const [tipoporte, setTipoporte] = useState(hodometro.hodometro.porte)
    const [center, setCenter] = useState(centerprimary);
    const [loading, setLoading] = useState(false)
    const [produto, setProduto] = useState({})

    const { enqueueSnackbar } = useCustomSnackbar();

    const getAlltipos = async () => {
        setLoading(true)
        try {
            const response = await prestacaoDeContasRepositorio.getAllTiposDeDespesas()
            response.forEach(item => {
                if (item.name == "Hodometro") {
                    setProduto({
                        iddespesa_tipo: item.iddespesa_tipo,
                        iddespesa_subtipo: item.produtos[1].iddespesa_subtipo
                    })
                }
            })
        } catch {
            enqueueSnackbar('Ocorreu um erro ao buscar categorias de despesas.', { variant: 'error' });
        } finally {
            setLoading(false)
        }
    }

    const handleClickEditGoogleMaps = () => {
        const hodometroData = {
            kminicial: inicialKm,
            kmfinal: finalKm,
            kmtotal: finalKm - inicialKm,
            duracao: 0,
            valor: 0,
            veiculo: {
                categoria: tipoveiculo,
                combustivel: tipocombustivel,
                porte: tipoporte
            }
        };

        const editData = {
            idviagem: viagemid,
            iddespesa: hodometro.iddespesa,
            descricao: descricao,
            hodometro: hodometroData,
            produtos: {
                iddespesa_tipo: produto?.iddespesa_tipo,
                iddespesa_subtipo: produto?.iddespesa_subtipo
            },
            datadecompra: datadecompra,
            aprovado: false,
            controladoria: false,
            total: 0,
            mensagem: "",
            comprovante: "",
        };

        editHodometro(editData);
        setType(0);
    }

    useEffect(() => {
        getAlltipos()
    },[])

    return (
        <Grid item xs={12} container style={{ display: "flex", justifyContent: "center", padding: "0px" }}>
            <Paper style={{ padding: 15, width: "100%", borderRadius: "10px" }}>
                <Grid container>
                    <Grid item xs={12}>
                        {/* Substitua este elemento pelo seu componente de mapa */}
                        {/* <div style={{ height: 200, borderRadius: "20px" }}>
                            {cords != null ? (
                                <GoogleMap
                                    center={center}
                                    zoom={17}
                                    mapContainerStyle={{ width: "100%", height: "100%", borderRadius: "20px" }}
                                    onLoad={onLoad}
                                    options={{
                                        streetViewControl: false,
                                        mapTypeControl: false,
                                        zoomControl: false,
                                        fullscreenControl: false,
                                    }}
                                > 
                                    {cords.length > 0 && (
                                        <Polyline
                                            path={cords}
                                            options={{
                                                strokeColor: primary.darker,
                                                strokeWeight: 10,
                                                strokeOpacity: 2,
                                            }}
                                        />
                                    )}
                                </GoogleMap>
                            ) : (null)}
                        </div> */}
                        <img src={inicialFoto} style={{ width: "-webkit-fill-available" }}></img>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography color="textSecondary">
                                <CreationDate data={datadecompra} />
                            </Typography>
                        </Grid>
                        <TextField
                            style={{ marginBottom: "10px", marginTop: "15px" }}
                            id="filled-multiline-flexible"
                            variant="outlined"
                            fullWidth
                            required
                            value={descricao}
                            onChange={(e) => setDescricao(e.target.value)}
                            multiline
                            maxRows={4}
                            label="Justificativa/Descrição"
                            placeholder="Viagem para..."
                        />
                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="veiculo-select-label">Veículo utilizado</InputLabel>
                                <Select
                                    labelId="veiculo-select-label"
                                    id="veiculo-select"
                                    value={tipoveiculo}
                                    onChange={(e) => setTipoveiculo(e.target.value)}
                                    label="Veículo utilizado"
                                >
                                    {Object.entries(EnumTipoVeiculo.categoria).map(([key, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="veiculo-select-label">Tipo de Combustivel</InputLabel>
                                <Select
                                    labelId="veiculo-select-label"
                                    id="veiculo-select"
                                    value={tipocombustivel}
                                    onChange={(e) => setTipocombustivel(e.target.value)}
                                    label="Tipo de Combustivel"
                                >
                                    {Object.entries(EnumTipoVeiculo.combustivel).map(([key, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "10px" }}>
                            <FormControl fullWidth>
                                <InputLabel id="veiculo-select-label">Porte do veículo</InputLabel>
                                <Select
                                    labelId="veiculo-select-label"
                                    id="veiculo-select"
                                    value={tipoporte}
                                    onChange={(e) => setTipoporte(e.target.value)}
                                    label="Porte do veículo"
                                >
                                    {Object.entries(EnumTipoVeiculo.porte).map(([key, value]) => (
                                        <MenuItem key={value} value={value}>
                                            {key.charAt(0).toUpperCase() + key.slice(1)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Button variant="contained" color="primary" fullWidth>
                            Em auditar
                        </Button>
                    </Grid> */}
                </Grid>
            </Paper>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "right" }}>
                <Button variant="contained" color="primary" autoFocus onClick={() => handleClickEditGoogleMaps()}>
                    Editar despesa
                </Button>
            </Grid>
        </Grid>
    )
}