import { Button, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Cards from "react-credit-cards";
import Cookies from 'universal-cookie';
import LinesTextField from "../../../../../components/LinesTextField";
import USearchFieldAsync from "../../../../../components/uSearchFieldAsync";
import UMaskTextfield from "../../../../../components/umasktextfield";
import aereoRepositorio from "../../../../../repositorios/aereo";
import { ConfirmIssueDialog } from "../ConfirmIssueDialog";
import { useStyles } from "./styles";

export const CreditCardPayment = (props) => {
    const responsive = props.responsive;
    const booking = props.booking;
    const classes = useStyles();
    const cookies = new Cookies();
    const [itensParcelas, setItensParcelas] = useState([]);
    const [itemParcela, setItemParcela] = useState(null);
    const [state, setState] = useState({ cvc: "", expiry: "", focus: "", name: "", number: "", cpf: "", });
    const [changed, setChanged] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const bandeirasCartao = [
        {
            title: "AMEX",
            regex: /^3[47][0-9]{13}$/,
            result: "AX",
        },
        {
            title: "DINERS",
            regex: /'(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
            result: "DC",
        },
        {
            title: "DISCOVER",
            regex: /^6(?:011|5[0-9]{2})[0-9]{12}/,
            result: "EL",
        },
        {
            title: "ELO",
            regex: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
            result: "EL",
        },
        {
            title: "HIPERCARD",
            regex: /^606282|^3841(?:[0|4|6]{1})0/,
            result: "HC",
        },
        {
            title: "MASTERCARD",
            regex: /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
            result: "CA",
        },
        {
            title: "VISA",
            regex: /^4[0-9]{15}$/,
            result: "VI",
        },
    ];

    const handleClickOpenConfirmDialog = () => { setOpenConfirmDialog(true) }
    const handleClickCloseConfirmDialog = () => { setOpenConfirmDialog(false) }

    const handleChange = (event) => {
        if (event.target.name === "holderCC") { if (!isNaN(event.target.value) && event.target.value !== "") { return; } }
        setState({ ...state, [event.target.name]: event.target.value, });
        setItemParcela(null);
        setChanged(true);
    };

    const handleInputFocus = (e) => {
        setState({ ...state, focus: e.target.name });
    };

    const converteDataCartao = (dataCartao = "00/00") => {
        const mes = Number(dataCartao.substr(0, 2));
        const ano = Number("20" + dataCartao.substr(3, 2));
        let dataFormatada = new Date(ano, mes, 0);
        return dataFormatada;
    };

    const validarCartao = (nCartao) => {
        let sigla = bandeirasCartao.find((item) => item.regex.test(nCartao)) || {};
        return sigla || "";
    };

    const buscarParcelas = async () => {
        let validade = converteDataCartao(state.expiry);
        let cartaoValido = validarCartao(state.number).result;
        if (!cartaoValido) {
            props.setSnackMessage('Cartão não reconhecido!')
            props.setSnackState({ visible: true })
            props.setSnackVariant('error')
            setItensParcelas([]);
            setItemParcela(null);
            return;
        }
        if (!state.name) {
            props.setSnackMessage('Preencha o nome do titular!')
            props.setSnackState({ visible: true })
            props.setSnackVariant('error')
            setItensParcelas([]);
            setItemParcela(null);
            return;
        }
        if (!state.number) {
            props.setSnackMessage('Preencha o numero do cartão!')
            props.setSnackState({ visible: true })
            props.setSnackVariant('error')
            setItensParcelas([]);
            setItemParcela(null);
            return;
        }
        if (!state.expiry) {
            props.setSnackMessage('Preencha a validade!')
            props.setSnackState({ visible: true })
            props.setSnackVariant('error')
            setItensParcelas([]);
            setItemParcela(null);
            return;
        }
        if (!state.cvc) {
            props.setSnackMessage('Preencha o código!');
            props.setSnackState({ visible: true });
            props.setSnackVariant('error');
            setItensParcelas([]);
            setItemParcela(null);
            return;
        }
        setItensParcelas([]);
        setItemParcela(null);
        try {
            props.setIsLoading(true);
            let dtoDados = {
                idreserva: booking.idreserva,
                companhiaaerea: booking.companhiaaerea,
                localizador: booking.localizador,
                cartaoNumero: state.number,
                emissorCartao: cartaoValido,
                dataValidade: validade,
                cartaoNome: state.name,
                codigo: state.cvc,
            };
            let response = await aereoRepositorio.recuperarFinanciamentos(dtoDados);
            if (response.data) {
                let listaItens = response.data.installmentOptions[0].options.map((item) => {
                    if (item.quantity === 1) {
                        return { value: item.quantity, label: `1x - 1 de R$${item.installment}`, idparcela: item.idparcela, };
                    } else {
                        return { value: item.quantity, label: `${item.quantity}x - 1 de R$${item.firstInstallment} e ${item.quantity - 1}x de R$${item.installment}`, idparcela: item.idparcela, };
                    }
                });
                setItensParcelas(listaItens);
            };
            setChanged(false);
        } catch (error) {
            props.setSnackMessage('Falha ao buscar parcelas.');
            props.setSnackState({ visible: true });
            props.setSnackVariant('error');
        } finally {
            props.setIsLoading(false);
        }
    };

    const emitirCartao = async () => {
        let cartaoValido = validarCartao(state.number).result;
        if (!cartaoValido) {
            props.setSnackMessage('Cartão inválido!');
            props.setSnackState({ visible: true });
            props.setSnackVariant('error');
            return;
        }
        let dataValida = converteDataCartao(state.expiry);
        try {
            let dtoDadosCartao = {
                idreserva: booking.idreserva,
                sessionId: cookies.get("sessionId"),
                localizador: booking.localizador,
                companhiaaerea: booking.companhiaaerea,
                valor: booking.total,
                emissorCartao: cartaoValido,
                cartaoNome: state.name,
                cartaoNumero: state.number,
                dataValidade: dataValida,
                codigo: state.cvc,
                nParcelas: itemParcela.value,
                tipoPagamento: 2,
                idparcela: itemParcela.idparcela,
                cpf: state.cpf,
            };
            props.setIsLoading(true);
            const response = await aereoRepositorio.emitir(dtoDadosCartao);
            if (response.data.status === "SUCCESS") {
                // await props.loadReserva();
                props.setSnackMessage('Aéreo emitido com sucesso.');
                props.setSnackState({ visible: true });
                props.setSnackVariant('success');
                // props.setModalEmissao(false);
            } else {
                props.setSnackMessage(`Ocorreu o seguinte erro ao emitir: ${response.data.value.error.message} `);
                props.setSnackState({ visible: true });
                props.setSnackVariant('error');
            }
        } catch (e) {
            props.setSnackMessage("Não foi possivel Emitir!");
            props.setSnackState({ visible: true });
            props.setSnackVariant('error');
        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <>
            <ConfirmIssueDialog  {...{ responsive, booking, open: openConfirmDialog, handleClickCloseConfirmDialog, }} yesEvent={emitirCartao} />
            <Grid container spacing={2} >
                <Grid item xs={responsive ? 12 : 6} className={classes.gridCenter}>
                    <Cards cvc={state.cvc} expiry={state.expiry} focused={state.focus} name={state.name} number={state.number} />
                </Grid>
                <Grid item container xs={responsive ? 12 : 6} className={classes.gridCenter}>
                    <Grid item xs={12}>
                        <Typography variant="h6"><b>Dados para pagamento</b></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <LinesTextField
                            name="name"
                            variant="outlined"
                            margin="dense"
                            onFocus={handleInputFocus}
                            onChange={handleChange}
                            value={state.name}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: "100%" }}
                            label="Nome do titular no cartão"
                        />
                    </Grid>
                    {booking.idpai === 1 ? null : (
                        <Grid item xs={12}>
                            <UMaskTextfield
                                mask="999.999.999-99"
                                maskChar=""
                                name="cpf"
                                label="CPF"
                                value={state.cpf || ""}
                                onChange={handleChange}
                                onFocus={handleInputFocus}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <UMaskTextfield
                            mask="9999999999999999"
                            maskChar={null}
                            name="number"
                            label="Nº do cartão"
                            value={state.number}
                            onChange={handleChange}
                            onFocus={handleInputFocus}
                        />
                    </Grid>
                    <Grid item xs={responsive ? 12 : 6}>
                        <UMaskTextfield
                            mask="99/99"
                            maskChar=""
                            formatChars={{ 9: "[0-9]", "/": "", }}
                            name="expiry"
                            label="Validade"
                            value={state.expiry}
                            onChange={handleChange}
                            onFocus={handleInputFocus}
                        />
                    </Grid>
                    <Grid item xs={responsive ? 12 : 6}>
                        <UMaskTextfield
                            mask="999?"
                            formatChars={{ 9: "[0-9]", "?": "[0-9]", }}
                            maskChar=""
                            name="cvc"
                            label="Código"
                            value={state.cvc}
                            onFocus={handleInputFocus}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <USearchFieldAsync
                            itens={itensParcelas}
                            value={itemParcela}
                            request={buscarParcelas}
                            loading={props.isLoading}
                            changed={changed}
                            setValue={setItemParcela}
                            label="Parcelas"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={props.isLoading} onClick={() => handleClickOpenConfirmDialog()} className={classes.buttonEmissao}>
                            {`Emitir R$${booking.total.toFormatDefault()}`}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
