import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@material-ui/core";
import { Alert, AlertTitle, Rating } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { IconsSource, LogoSistemSource } from "../../../../images/Icons";
import { useStyles } from "./styles";
import { Icon } from 'leaflet';
import { EditOutlined, Person } from "@material-ui/icons";
import { Avatar, Button, Card, CardContent, CardMedia, Chip, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { CoffeeOutlined, LocalCafeOutlined, WifiOutlined } from "@mui/icons-material";
import Enums from "../../../../enums";
import UtaliiDatePickerInput from "../../../prestacaodecontas/Desktop/sections/custom-date-picker-start_and_end";
import dayjs from "dayjs";
import DatePickerWrapper, { DateDisplay } from "./DatePickerWrapper";
import LinesTextField from "../../../../components/LinesTextField";
import DialogComponent from "../../../prestacaodecontas/Desktop/sections/dialogs-desktop";

export const HotelTicketDesktop = (props) => {
  const classes = useStyles();
  // const cookies = new Cookies();
  // const data = props.hotelTicketData;
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [hotelData, setHotelData] = useState(props.hotelTicketData);
  const [totaledit, setTotaledit] = useState(0)
  const [openTotalEdit, setOpenTotalEdit] = useState(false)
  const permissaoDashboard = props.permissaoDashboard;

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
  };

  const handleInputChange = (event) => {
    setTotaledit(event.target.value);
  };

  const formatCurrency = (value) => {
    // Remove todos os caracteres não numéricos
    const numericValue = value.replace(/[^\d]/g, '');
    // Converte para número
    const number = parseFloat(numericValue) / 100;
    // Formata para moeda com duas casas decimais
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const handleClickEditDate = () => {
    props.handleClickEditHotel({
      idreserva: 0,
      data_checkin: selectedDate[0].toDate(),
      data_checkout: selectedDate[1].toDate()
    })
  }

  const handleClickEditTotal = () => {
    const valorDiariaDecimal = parseFloat(totaledit.replace(',', '.'));
    props.handleClickEditHotel({
      idreserva: 0,
      data_checkin: selectedDate[0].toDate(),
      data_checkout: selectedDate[1].toDate(),
      valor_diaria: valorDiariaDecimal
    })
  }

  const handleClickOpenEditTotal = (value, dates) => {
    setTotaledit(value.toFixed(2))
    setSelectedDate(dates)
    setOpenTotalEdit(true)
  }

  const customIcon = new Icon({
    iconUrl: IconsSource.locIcon,
    iconSize: [38, 38]
  })

  function contarQtdDias(data1, data2) {
    var dt1 = new Date(data1);
    var dt2 = new Date(data2);

    var diferencaEmMilissegundos = Math.abs(dt2 - dt1);

    var diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

    return diferencaEmDias;
  }

  useEffect(() => {
    setHotelData(props.hotelTicketData)
    // setTotaledit(props.hotelTicketData.total.toFixed(2))
  }, [props.hotelTicketData])

  function hotelRating(rating) {
    switch (rating) {
      case 1:
        return "Terrível";
      case 2:
        return "Ruim";
      case 3:
        return "Comum";
      case 4:
        return "Bom";
      case 5:
        return "Ótimo";
      default:
        return "Não informado";
    }
  }

  return (
    <>
      <Grid container >
        <Grid container item xs={8}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '16px' }}>
              <Card sx={{ display: 'flex', flexDirection: 'row', width: '100%' }} elevation={0}>
                <CardMedia
                  component="img"
                  sx={{ width: 400, maxHeight: "200px" }}
                  image={hotelData.url_image}
                  alt="Hotel Image"
                  style={{ borderRadius: "10px" }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <CardContent style={{ marginTop: "-14px" }}>
                    <Typography variant="h5" style={{ fontWeight: "600", color: "#2E2E2E" }}>{hotelData.nome}</Typography>
                    <Box sx={{ overflowY: "scroll", maxHeight: "165px" }}>
                      <Typography style={{ color: "#6C6C6C" }}>{hotelData.detalhes.descricao}</Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Box>

          </Grid>
          <Grid item xs={12}>
            {/* <Box sx={{ display: 'flex', alignItems: "center", paddingLeft: "16px" }}>
              <Typography style={{
                backgroundColor: "#434343",
                padding: "4px 12px 4px 12px",
                width: "fit-content",
                borderRadius: "10px",
                fontWeight: "600",
                color: "white"
              }}>{hotelData.estrelas}</Typography>
              <Typography style={{ fontWeight: "500", fontSize: "18px", marginLeft: "6px" }}>{hotelRating(hotelData.estrelas)}</Typography>
            </Box> */}
            <Box style={{ textAlign: "center" }}>
              <Chip size="medium" label={Enums.getReservaStatusDescricao(hotelData.reserva.status)} style={{ background: Enums.getReservaStatusCor(hotelData.reserva.status), color: "#ffffff", fontWeight: "600" }} />
            </Box>
            {/* <Grid container item xs={12} style={{ padding: "16px" }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '50%', padding: '8px' }}>
                <Box>
                  <Typography style={{ fontWeight: '600', color: "#171717", fontSize: "18px" }}>Solicitante:</Typography>
                  <Typography variant="body2">{hotelData.reserva.solicitante}</Typography>
                </Box>
                <Box>
                  <Typography style={{ fontWeight: '600', color: "#171717", fontSize: "18px" }}>Criado em:</Typography>
                  <Typography variant="body2">{new Date(hotelData.data_criacao).toLocaleString('pt-Br')}</Typography>
                </Box>
              </Box>
            </Grid> */}
            <Grid container item xs={12} style={{ padding: "16px" }}>
              {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}> */}

              {/* <Typography variant="body2">{hotelData.reserva.solicitante}</Typography>
                  <Typography variant="body2">{new Date(hotelData.data_criacao).toLocaleString('pt-Br')}</Typography> */}
              {/* </Box> */}
            </Grid>
            {/* <Divider variant="middle" style={{ marginTop: "15px" }} /> */}
          </Grid>


          <Grid item xs={12} style={{ padding: "16px", marginTop: "-25px" }}>
            {/* <Grid style={{ textAlign: "left", padding: "0px", marginTop: "10px" }}>
              <Typography variant={"h6"}><b>Onde você vai dormir</b></Typography>
            </Grid> */}
            <Grid container>
              {/* <DialogComponent
                title={"Editar total por noite "}
                open={openTotalEdit}
                handleClose={() => setOpenTotalEdit(false)}
                // fullScreen={true}
                body={
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue={totaledit}
                    onChange={(e) => setTotaledit(e.target.value)}
                    inputProps={{
                      type: 'number',
                    }}
                  />
                }
                action={<></>}
                width={"sm"}
              /> */}

              <Dialog
                open={openTotalEdit}
                // TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenTotalEdit(false)}
                aria-describedby="alert-dialog-slide-description"
                style={{ borderRadius: "20px" }}
              >
                <DialogTitle>
                  <Typography variant="h6" style={{ fontWeight: "600" }}>
                    {"Editar total por diária"}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <LinesTextField
                    variant="outlined"
                    fullWidth
                    value={totaledit}
                    // defaultValue={totaledit}
                    onChange={(e) => setTotaledit(e.target.value)}
                    inputProps={{
                      type: 'number',
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleClickEditTotal()} variant="contained" style={{ marginTop: "5px", borderRadius: "6px", backgroundColor: "#c62828" }}>Editar</Button>
                </DialogActions>
              </Dialog>
              {hotelData.quartos.map(item => (
                <>
                  <Grid container item xs={12} style={{ boxShadow: "0px 0px 3.2px 0px rgba(0,0,0,0.2)", marginBottom: "14px", padding: "20px", borderRadius: "16px" }}>
                    <Grid container item xs={8}>
                      <Grid item xs={12}>
                        <DateDisplay item={item} title={hotelData.nome} />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePickerWrapper setValue={setSelectedDate} item={item} usuarioLinestur={permissaoDashboard.usuarioLinestur} handleClickEdit={handleClickEditDate} />
                      </Grid>
                    </Grid>
                    <Grid container item xs={4} style={{ boxShadow: "0px 0px 3px 0px rgba(0,0,0,0.4)", marginBottom: "14px", padding: "20px", borderRadius: "16px" }}>
                      <Grid item xs={12}>
                        <Typography variant="h6"><b>
                          {`${item.descricao_tipo_acomodacao}`}
                        </b></Typography>
                        {/* (${item.descricao_tipo_quarto}) */}
                        <Typography variant="body1">Por noite</Typography>
                        <Typography variant="h5" style={{ fontWeight: "600" }}>{item.valor_diaria.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                          {permissaoDashboard.usuarioLinestur && (
                            <IconButton onClick={() => handleClickOpenEditTotal(item.valor_diaria, [dayjs(item.data_entrada), dayjs(item.data_saida)])} style={{ padding: "-10px", marginTop: "-10px", marginBottom: "-10px" }}>
                              <EditOutlined style={{ color: "#c62828" }} />
                            </IconButton>
                          )}
                        </Typography>

                        <Typography variant="body1">Nº de diárias: {contarQtdDias(item.data_entrada, item.data_saida)}</Typography>
                        <Divider variant="hr" style={{ marginTop: "10px", marginBottom: "10px" }} />
                        <Typography variant="body1">Hospede(s)</Typography>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          {item.hospedes.map(pax => (
                            <ListItem>
                              <ListItemText primary={`${pax.nome} ${pax.sobrenome}`} secondary={(<Typography variant="body1">
                                {`${pax.nome_centrocusto} (${pax.codigo})`}
                                {permissaoDashboard.usuarioLinestur ? (
                                  <Button fullWidth variant="contained" style={{ marginTop: "5px", borderRadius: "16px", backgroundColor: "#c62828" }} onClick={() => props.openChangeCCButton(pax.idpessoas)}>
                                    Editar cc
                                    {/* <EditOutlined /> */}
                                  </Button>
                                ) : null}
                              </Typography>)} />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item xs={12} style={{}}>
                        <img
                          src={LogoSistemSource.logoL}
                          width={50}
                          style={{ position: 'absolute', bottom: 20, right: 30 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Alert severity="warning" style={{ width: "100%", borderRadius: "16px" }}>
                      <AlertTitle><Typography><strong>Regra(s)</strong></Typography></AlertTitle>
                      <Typography>
                        {item.regras.map(rule => (
                          <>
                            {rule.deadline_descricao} —
                            Data limite: <strong>{new Date(rule.deadline_data_limite).toLocaleDateString('pt-Br')}</strong>
                          </>
                        ))}
                      </Typography>
                    </Alert>
                  </Grid>
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ padding: '20px' }}>
          <Grid container item xs={12} style={{ maxHeight: "500px", position: "sticky", top: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
              <Box sx={{ display: 'flex', flexDirection: "column", width: '100%', borderRadius: "16px", boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)" }}>
                <Card sx={{ width: '2005', height: "200px", borderRadius: "10px" }} elevation={0}>
                  <MapContainer center={[hotelData.localizacao.latitude, hotelData.localizacao.longitude]} zoom={13}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[hotelData.localizacao.latitude, hotelData.localizacao.longitude]} icon={customIcon}>
                    </Marker>
                  </MapContainer>
                  <CardContent>
                    <Typography variant="h6">Rede Andrade Mercado</Typography>
                  </CardContent>
                </Card>
                <Typography variant="h6" style={{ color: "red", padding: "10px", fontWeight: "600" }}>
                  Onde você estará
                </Typography>
                <Typography style={{ color: "#6C6C6C", padding: "10px", marginTop: "-18px" }}>{hotelData.localizacao.cidade}  {hotelData.localizacao.endereco}</Typography>
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: "16px", marginTop: "-18px" }}> */}
                {/* <Typography variant="h6" style={{ color: "#2E2E2E", fontWeight: "500", fontSize: "22px" }}>{hotelData.localizacao.cidade}</Typography> */}
                {/* </Box> */}
              </Box>
            </Box>
            <Grid container item xs={12} style={{ marginTop: "20px", boxShadow: "0px 0px 3.2px 0px rgba(0,0,0,0.2)", padding: "20px", borderRadius: "16px" }}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography style={{ fontWeight: "600" }}>Entrar em contato</Typography>
              </Grid>
              <Grid container item xs={12} style={{ marginTop: "10px" }}>
                <Grid item xs={6}>
                  <Typography variant="body1">Telefone:</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography>{props.formatarTelefone(hotelData.telefone)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">Email:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{ textAlign: "right" }}>{hotelData.email == ""?"Não possui":hotelData.email}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="hr" style={{ marginTop: "10px", marginBottom: "10px" }} />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={8}>
                  <Typography variant="h6" style={{ fontWeight: "600", marginTop: "10px" }}>Solicitante</Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "right", marginTop: "-14px" }}>
                  <List>
                    <ListItem style={{ marginLeft: "-15px" }}>
                      <ListItemText primary={hotelData.reserva.solicitante} secondary={("Em "+new Date(hotelData.data_criacao).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' }))} />
                    </ListItem>
                  </List>
                  {/* <Typography variant="body2">{hotelData.reserva.solicitante}</Typography>
                  <Typography variant="body2">{new Date(hotelData.data_criacao).toLocaleString('pt-Br')}</Typography> */}
                  {/* <Box>
                    <Typography style={{ fontWeight: '600', color: "#171717", fontSize: "18px" }}>Solicitante:</Typography>
                    <Typography variant="body2">{hotelData.reserva.solicitante}</Typography>
                  </Box>
                  <Box>
                    <Typography style={{ fontWeight: '600', color: "#171717", fontSize: "18px" }}>Criado em:</Typography>
                    <Typography variant="body2">{new Date(hotelData.data_criacao).toLocaleString('pt-Br')}</Typography>
                  </Box> */}
                  {/* <Typography variant="h6" style={{ fontWeight: "600", marginTop: "10px" }}>{hotelData.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
