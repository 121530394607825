import { Dialog, DialogContent, DialogTitle, IconButton, Snackbar, Typography } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { CadastroSolicitacao } from "../../solicitacao/cadastro";
import { useStyles } from "./styles";
import reservaRepositorio from "../../../repositorios/reserva";

export const RequestTicketBooking = (props) => {
    const responsive = props.responsive;
    const open = props.openDialogBookingRequestTicket;
    const booking = props.bookingSelected
    const classes = useStyles();
    const [snackState, setSnackState] = useState({ visible: false });
    const [snackVariant, setSnackVariant] = useState('success');
    const [snackMessage, setSnackMessage] = useState('');
    const [reservas, setReservas] = useState([]);
    const [reserva, setReserva] = useState({});


    const getDataBooking = useCallback(async () => {
        let temp_response = await reservaRepositorio.get(booking.idreservatenant);
        setReserva(temp_response)
    }, [booking]);

    const handleCloseRequestTicket = async () => {
        props.setOpenDialogBookingRequestTicket(false);
    }

    useLayoutEffect(() => {
        getDataBooking()
    }, [getDataBooking])

    return (
        <>
            <Dialog open={open} maxWidth='lg' fullWidth fullScreen={responsive}>
                <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackState.visible, setSnackbar: setSnackState }} />
                <DialogTitle disableTypography className={classes.dialogTitle}>
                    <Typography variant="body1">Solicitação {`- ${reserva.idreservatenant === undefined ? '' : reserva.idreservatenant}`}</Typography>
                    <IconButton className={classes.closeButton} aria-label="close" onClick={() => handleCloseRequestTicket()}><CloseOutlined /></IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: responsive ? '0px' : '8px' }}>
                    {Object.keys(reserva).length !== 0 && (
                        <CadastroSolicitacao {...{
                            setIsUpdateTicket: props.setIsUpdateTicket,
                            // loadReserva: loadReserva,
                            setReservas: setReservas,
                            reservas: reservas,
                            setReserva: props.setBookingSelected,
                            reserva: reserva,
                            setModalOpen: props.setOpenDialogBookingRequestTicket,
                            setSnackbarSucess: setSnackVariant,
                            setMensagemSnack: setSnackMessage,
                        }} />
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}
