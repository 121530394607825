import { AppBar, Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import Snackbar from '../../../../components/snackbar';
import UDatePicker from '../../../../components/udatepicker';
import ULoaderVoo from '../../../../components/uloadervoo';
import reservaRepositorio from '../../../../repositorios/reserva';

export const AlterarDataCriacao = props => {
    const [dataCriacao, setDataCriacao] = useState(new Date());
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [tipoSnack, setTipoSnack] = useState('success');
    const [mensagemSnack, setMensagemSnack] = useState('');
    const [snackbarSucess, setSnackbarSucess] = useState({
        visible: false
    });

    const handleClickSalvar = async () => {
        if (!dataCriacao) {
            setMensagemSnack('Data de criação não pode ser vazia!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
            return;
        }

        let dto = {
            idreserva: props.reserva.idreserva,
            datacriacao: dataCriacao
        }

        try {
            setIsLoading(true);
            await reservaRepositorio.alterarDataCriacao(dto);
            setIsLoading(false);
            props.setOpen(false);
            setMensagemSnack('Data de criação alterada!');
            setTipoSnack('success');
            setSnackbarSucess({ visible: true });
        } catch (e) {
            setIsLoading(false);
            setMensagemSnack('Erro ao salvar!');
            setTipoSnack('error');
            setSnackbarSucess({ visible: true });
        }
    }

    return (
        <>
            <Snackbar {...{ variant: tipoSnack, message: mensagemSnack, visible: snackbarSucess.visible, setSnackbar: setSnackbarSucess }} />
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                fullWidth
                maxWidth="xs"
                fullScreen={responsive}
            >
                <ULoaderVoo isLoading={isLoading} />
                <AppBar position="relative">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#646464' }}>
                        <Typography style={{ color: '#fff' }}>Alterar Data de criação</Typography>
                        <IconButton onClick={() => props.setOpen(false)} >
                            <CloseOutlined style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <UDatePicker {...{ date: dataCriacao, setDate: setDataCriacao, label: "Data de criação" }} name="datacriacao" />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClickSalvar}
                        variant="contained"
                        style={{
                            color: '#fff',
                            background: '#c62828',
                            '&:hover': {
                                background: '#b91119'
                            }
                        }}
                    >
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default AlterarDataCriacao;
