import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    tableCustom: {
        minWidth: 650
    },
    button: {
        width: '35vw',
        background: '#b91119',
        color: 'white',
        '&:hover': {
            background: '#810b11'
        },
    },
    iconCustom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
