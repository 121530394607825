import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  imageSlider: {
    position: 'relative',
    textAlign: 'center',
    height: "250px"
  },
  sliderButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#fff',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    backgroundColor: "#08080860",
    justifyContent: "center",
    
  },
  prev: {
    left: '10px',
  },
  next: {
    right: '10px',
  },
  imageSlider: {
    position: "relative",
    overflow: "hidden"
  },
  slideContainer: {
    display: "flex",
    transition: "transform 0.5s ease-in-out"
  },
  
  slide: {
    boxSizing: "borderBox"
  },
  indexDisplay: {
    position: "absolute",
    top: "10px", /* Ajuste conforme necessário */
    left: "10px", /* Ajuste conforme necessário */
    color: "#fff", /* Escolha uma cor que se destaque no seu fundo */
    zIndex: "10", /* Certifique-se de que esteja sobre as imagens */
    fontSize: "20px", /* Ajuste conforme necessário */
    backgroundColor: "#0000009C",
    padding: "8px",
    borderRadius: "10px",
    fontSize: "14px"
  }
  

}))