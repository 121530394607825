import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1)
    },
    tittle: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    moreButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    groupButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonPesquisar: {
        background: '#c62828',
        color: 'white',
        '&:hover': {
            background: '#b91119',
        }
    },
    closeIcon: {
        color: 'white'
    },
    dialogtittle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    dialog: {
        background: "#616161",
        color: 'white',
        position: 'relative',
    },
    button: {
        background: '#c62828',
        color: '#fff',
        '&:hover': {
            background: '#b91119',
        }
    },
    appbar: {
        background: '#c62828',
        display: "flex",
        justifyContent: "space-between"
    },
}));
