import React from 'react';

export const ModalDestino = props => {
    return (
        <div>
            Destino
        </div>
    )
};

export default ModalDestino
