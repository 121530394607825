import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#c62828",
    color: "#ffffff",
  },
  icon: {
    color: "#fff",
  },
  button: {
    background: "#c62828",
    color: "white",
    "&:hover": {
      background: "#b91119",
    },
  },
  gridCenter: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center'
  }
}));
