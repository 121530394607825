import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  backdrop: {
    marginBottom: theme.spacing(7)
  },
  speedDial: {
    zIndex: 2000,
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(0),
  },
}));
