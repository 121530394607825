import Resizer from "react-image-file-resizer";
import mime from "mime-types";

export default async function sendFile(file) {
    if (!file) {
        return false;
    }

    const mimeType = mime.lookup(file.name);

    let resizeFile = null;

    if (mimeType.includes("image")) {
        resizeFile = new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1280,
                1280,
                "JPEG",
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });
    } else if (mimeType === "application/pdf") {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        resizeFile = new Promise((resolve) => {
            reader.onload = (event) => {
                const base64 = event.target.result;
                resolve(base64);
            };
        });
    }
    else {
        return false;
    }

    return resizeFile;
}
