import { makeStyles, fade } from '@material-ui/core/styles';
import { yellow, orange, lightGreen, grey } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  expansion: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(2),
      padding: theme.spacing(0)
    }
  },
  muipaper: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      display: 'flex',
    },
  },
  testt: {

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      padding: theme.spacing(0),

    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      padding: theme.spacing(0),
    },
  },

  lista2: {
    width: '100%',
    padding: theme.spacing(0, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      padding: theme.spacing(0),
    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      padding: theme.spacing(0),
    },
    ['@media (max-width:1280px) and (orientation: landscape)']: {
      padding: theme.spacing(0, 3),
    },
  },
  consulta: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
      padding: theme.spacing(0),
    },

    ['@media (max-width:736px) and (orientation: landscape)']: {
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0),

    },

    ['@media (max-width:900px) and (orientation: portrait)']: {
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0),
    },
  },

  button: {
    background: 'transparent',
    borderRadius: '0px',
    color: '#c62828',
    border: 'solid 1px #c62828',
    '&:hover': {
      background: '#c62828',
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginRigth: '10%'
    },
  },
  filtrosContent: {
    width: '100%',
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  filtros: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },

  filtros2: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(0),
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    },
  },

  tipoTrecho: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },

  tipoTrechoM: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },

  boxTipoVoo: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    }
  },
  appBar: {
    position: 'relative',
    backgroundColor: "#c62828"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  select: {
    "& .MuiSelect-outlined": {
      borderColor: '#c62828 !important',
    },
    "& .MuiSelect-outlined.Mui-focused": {
      borderColor: '#c62828 !important',
    },
  },
  bagagem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  trecho1: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '90%',
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      flexDirection: 'column',
      width: '90%',
    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      flexDirection: 'column',
      width: '90%',
    },
  },

  trecho2: {
    width: '30%',
    marginLeft: '1%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '90%',
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      flexDirection: 'column',
      width: '90%',
      marginLeft: '0%'
    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      flexDirection: 'column',
      width: '90%',
      marginLeft: '0%'
    },
  },

  textfield: {
    width: '80%'
  },

  udate: {
    width: '20%',
    marginLeft: '1%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '90%',
      marginTop: theme.spacing(3),
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      flexDirection: 'column',
      width: '90%',
      marginTop: theme.spacing(3),
    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      flexDirection: 'column',
      width: '90%',
      marginTop: theme.spacing(3),
    },
  },

  udate2: {
    width: '20%',
    marginLeft: '1%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '90%',
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      flexDirection: 'column',
      width: '90%',
    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      flexDirection: 'column',
      width: '90%',
    },
  },

  button2: {
    width: '20%',
    marginLeft: '2%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '90%',
      marginBottom: theme.spacing(2)
    },
  },

  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textfield: {
    margin: theme.spacing(0),
    padding: theme.spacing(0)
  },
  linha: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: 0.4,
    borderRadius: 5
  },
  borda: {
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  filtros3: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {



    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      marginBottom: theme.spacing(3)
    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      flexDirection: 'column',
    },
  },
  titulo: {
    ['@media (max-width:900px) and (orientation: portrait)']: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',

    },
  },
  button3: {
    width: '100%',
    background: '#c62828',
    color: 'white',
    '&:hover': {
      background: '#b91119',
    }
  },
  passageiros: {
    width: 90,
    marginRight: '3%',
    marginLeft: '3%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '50%',
      paddingTop: theme.spacing(2)

    },
    ['@media (max-width:736px) and (orientation: landscape)']: {

    },
    ['@media (max-width:900px) and (orientation: portrait)']: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '50%',
      paddingTop: theme.spacing(2)
    },
    ['@media (max-width:1280px) and (orientation: landscape)']: {
      paddingBottom: '2%'
    },
  },
  pacotinho: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '3%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,

    },
  },
  tourcode: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  voos: {
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '0',
      marginLeft: '0',
    }
  },
  responsividade: {
    ['@media (max-width:1680px)']: {
      //notebooks e telas full hd
    },
    ['@media (max-width: 1280px)']: {
      //notebooks e telas full hd e tablets full hd
    },
    ['@media (max-width:980px)']: {
      //tablets hd
    },
    ['@media (max-width:736px) and (orientation: landscape)']: {
      //smartphones em modo paisagem
    },
    [theme.breakpoints.down('sm')]: {
      //smartphones em geral com tela padrão
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },

  lista: {
    padding: theme.spacing(5)
  },
  add: {
    marginLeft: theme.spacing(1),
    background: 'transparent',
    borderRadius: '0px',
    color: '#c62828',
    border: 'solid 1px #c62828',
    '&:hover': {
      background: '#c62828',
      color: 'white'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingTop: '10px'
    },
  },
  container: {
    maxHeight: 300
  },
  juntinho: {
    flexWrap: 'nowrap',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      width: '100%',
      marginTop: theme.spacing(1),
      marginLeft: 0,
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  txtTourCode: {
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
  },
  tudojunto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  }

}));
