import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close, PersonAdd } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '../../../../components/snackbar';
import pessoaRepositorio from '../../../../repositorios/pessoa';
import validatorCPF from '../../../../utils/cpfValidator';
import CheckUpCadastro from '../../checkupCadastro';
import ModalPassageiros from '../passageiros';
import { useStyles } from './styles';

export const Reservar = props => {
    const classes = useStyles();
    const theme = useTheme();
    const [indexPassageiro, setIndexPassageiro] = useState(null);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [validacao, setValidacao] = useState({ visible: false });
    const [mensagemValidacao, setMensagemValidacao] = useState('');
    const [openCheckUpCadastro, setOpenCheckUpCadastro] = useState(false);
    const aereos = useSelector(state => state.linkAereo.aereos);
    const politicaViagem = useSelector(state => state.permissao.politicaViagem);
    const dadosSessao = useSelector(state => state.permissao.dadosSessao);
    const [passageiro, setPassageiro] = useState({});
    const [dataCheckUp, setDataCheckUp] = useState();
    const [modalPassageiroOpen, setModalPassageiroOpen] = useState(false);
    const [dadosInvalidos, setDadosInvalidos] = useState({
        mensagem: ''
    })

    const handleClickAdicionar = async () => {

        if (!passageiro.idpassageiro) {
            setMensagemValidacao('Passageiro não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!passageiro.idcentrocusto) {
            setMensagemValidacao('Centro de Custo não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!passageiro.porcentagem) {
            setMensagemValidacao('Porcentagem não pode ser vazia');
            setValidacao({ visible: true });
            return;
        }
        if (!passageiro.genero) {
            setMensagemValidacao('Gênero não pode ser vazio');
            setValidacao({ visible: true });
            return;
        }
        if (!passageiro.dataNascimento) {
            setMensagemValidacao('Data de nascimento não pode ser vazia');
            setValidacao({ visible: true });
            return;
        }
        if (!passageiro.dataNascimento === new Date()) {
            setMensagemValidacao('Data de nascimento invalida');
            setValidacao({ visible: true });
            return;
        }
        const calcularIdade = (data) => {
            let dataAtual = new Date(aereos.dataIda);
            let diaAtual = dataAtual.getDate();
            let mesAtual = dataAtual.getMonth() + 1;
            let anoAtual = dataAtual.getFullYear();

            let idade = anoAtual - data.getFullYear();

            if (mesAtual < (data.getMonth() + 1)) idade -= 1;

            if (data.getDate() > diaAtual && mesAtual === (data.getMonth() + 1)) idade -= 1;

            return idade;
        }

        let idade = calcularIdade(passageiro.dataNascimento);

        if (idade < 12 && passageiro.faixaEtaria.type === "ADT") {
            setMensagemValidacao('Passageiro não se caracteriza como adulto.');
            setValidacao({ visible: true });
            return;
        }
        if ((idade < 2 && passageiro.faixaEtaria.type === "CHD") || (idade >= 12 && passageiro.faixaEtaria.type === "CHD")) {
            setMensagemValidacao('Passageiro não se caracteriza como criança.');
            setValidacao({ visible: true });
            return;
        }
        if (idade >= 2 && passageiro.faixaEtaria.type === "INFANT") {
            setMensagemValidacao('Passageiro não se caracteriza como bebê.');
            setValidacao({ visible: true });
            return;
        }

        if (passageiro.faixaEtaria === 'Adulto') {
            setPassageiro({ ...passageiro, tipo: 'ADT' });
        }
        if (passageiro.faixaEtaria === 'Criança') {
            setPassageiro({ ...passageiro, tipo: 'CHD' });
        }
        if (passageiro.faixaEtaria === 'Bebê') {
            setPassageiro({ ...passageiro, tipo: 'INFANT' });
        }

        try {
            let dto = {
                idpassageiro: passageiro.idpassageiro
            }


            if ((!validatorCPF(passageiro.cpf) && passageiro.nacionalidade === 1) ||
                passageiro.telefone === null ||
                passageiro.telefone === "(__)_____-____" ||
                passageiro.telefone === "") {
                setDadosInvalidos({ mensagem: 'Viajante possui dados incompletos.' })
                setMensagemValidacao('Viajante possui dados incompletos.');
                setValidacao({ visible: true });
                setDataCheckUp(passageiro);
                setOpenCheckUpCadastro(true);
                return
            }

            let response = await pessoaRepositorio.postVerificarCpf(dto);
            if (response.status === 207) {
                setDadosInvalidos({ mensagem: response.data.message });
                setMensagemValidacao(response.data.message)
                setValidacao({ visible: true })
                setDataCheckUp(passageiro)
                setOpenCheckUpCadastro(true)
                return
            }

            let pas = [...props.passageiros];
            pas[indexPassageiro] = passageiro;
            if (props) {
                props.setPassageiros(pas);
            }
            setPassageiro({});
            setModalPassageiroOpen(false);

        } catch (e) {
            return;
        }
    }

    const handleEditPassageiro = (item, index) => {
        setIndexPassageiro(index);
        setPassageiro(item);
        setModalPassageiroOpen(true);
    }

    const handleOpenCheckUpDialog = () => {
        setOpenCheckUpCadastro(true)
    }

    useEffect(() => {
        let passageiros = [];
        for (let ia = 0; ia < Number(aereos.adultos); ia++) {
            passageiros.push({
                nome: '',
                sobrenome: '',
                centrocusto: '',
                porcentagem: '',
                email: '',
                genero: '',
                faixaEtaria: {
                    type: 'ADT',
                    label: 'Adulto',
                    value: 1
                }
            })
        }
        for (let ic = 0; ic < Number(aereos.criancas); ic++) {
            passageiros.push({
                nome: '',
                sobrenome: '',
                centrocusto: '',
                porcentagem: '',
                email: '',
                genero: '',
                faixaEtaria: {
                    type: 'CHD',
                    label: 'Criança',
                    value: 2
                }
            })
        }
        for (let ib = 0; ib < Number(aereos.bebes); ib++) {
            passageiros.push({
                nome: '',
                sobrenome: '',
                centrocusto: '',
                porcentagem: '',
                email: '',
                genero: '',
                faixaEtaria: {
                    type: 'INFANT',
                    label: 'Bebê',
                    value: 3
                }
            })
        }

        props.setPassageiros(passageiros)
    }, [])

    return (
        <div className={classes.root}>
            {openCheckUpCadastro ? (
                <CheckUpCadastro
                    open={openCheckUpCadastro}
                    setOpen={setOpenCheckUpCadastro}
                    data={dataCheckUp}
                    passageiros={props?.passageiros}
                    setPassageiros={props?.setPassageiros}
                    setPassageiro={setPassageiro}
                    passageiro={passageiro}
                    dadosInvalidos={dadosInvalidos}
                    indexPassageiro={indexPassageiro}
                    setSnackVariant={setSnackVariant}
                    setSnackMessage={setSnackMessage}
                    setSnackVisible={setSnackVisible}
                />
            ) : null}
            <Snackbar {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
            <Snackbar {...{ variant: 'error', message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
            <Dialog
                hideBackdrop
                open={modalPassageiroOpen}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={fullScreen ? true : false}
                onClose={() => setModalPassageiroOpen(false)}
            >

                <DialogTitle style={{ background: '#d1d1d1', color: 'black', padding: '5px 16px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography>Adicionar Passageiro</Typography>
                        <IconButton onClick={() => setModalPassageiroOpen(false)}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Snackbar {...{ variant: 'error', message: mensagemValidacao, visible: validacao.visible, setSnackbar: setValidacao }} />
                    <ModalPassageiros {...{ passageiro, setPassageiro, index: indexPassageiro, setIndex: setIndexPassageiro }} passageiros={props.passageiros} />
                </DialogContent>
                <DialogActions id="action3" style={{ background: '#d1d1d1' }} >
                    <Button onClick={() => handleClickAdicionar()} className={classes.button}>Adicionar</Button>
                </DialogActions>
            </Dialog>

            <div>
                {fullScreen ? (
                    <Grid container item xs={12} spacing={4} style={{ padding: theme.spacing(1, 0), margin: 0 }}>
                        {props.passageiros.map((item, index) => (
                            <Grid item xs={12}>
                                {item.nome ? (
                                    <Paper elevation={3}>
                                        <Grid container item spacing={2} style={{ padding: theme.spacing(0, 1), margin: 0 }} xs={12}>
                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <IconButton>
                                                    <PersonAdd onClick={() => handleEditPassageiro(item, index)} />
                                                </IconButton>
                                                <Typography>{item.faixaEtaria ? item.faixaEtaria.label : ''}</Typography>
                                            </Grid>
                                            <Grid container xs={12} spacing={2} style={{ padding: theme.spacing(1, 3, 3, 3) }}>
                                                <Grid item xs={12}>
                                                    <Tooltip title={item.nome + ' ' + item.sobrenome} >
                                                        <Typography noWrap>{item.nome + ' ' + item.sobrenome}</Typography>
                                                    </Tooltip>
                                                </Grid>
                                                {politicaViagem.utilizarcentrocusto ? (
                                                    <React.Fragment>
                                                        <Grid item xs={12}>
                                                            <Tooltip title={item.centroCusto}>
                                                                <Typography noWrap>{item.centroCusto}</Typography>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography noWrap>{item.porcentagem ? item.porcentagem + '%' : ''}</Typography>
                                                        </Grid>
                                                    </React.Fragment>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ) : (
                                    <Paper elevation={3}>
                                        <Grid container item spacing={2} style={{ padding: theme.spacing(0, 1), margin: 0 }} xs={12}>
                                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <IconButton>
                                                    <PersonAdd onClick={() => handleEditPassageiro(item, index)} />
                                                </IconButton>
                                                <Typography>{item.faixaEtaria ? item.faixaEtaria.label : ''}</Typography>
                                            </Grid>
                                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={12}>
                                                <Typography noWrap>Não Preenchido</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Paper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Passageiro</TableCell>
                                        {politicaViagem.utilizarcentrocusto ? (
                                            <React.Fragment>
                                                <TableCell>Centro Custo</TableCell>
                                                <TableCell>Porcentagem</TableCell>
                                            </React.Fragment>
                                        ) : null}
                                        <TableCell>Faixa Etária</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.passageiros.map((item, index) => (
                                        <TableRow>
                                            <TableCell>
                                                <Tooltip title="Clique para preencher passageiro">
                                                    <IconButton onClick={() => handleEditPassageiro(item, index)}>
                                                        <PersonAdd />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>{item.nome + ' ' + item.sobrenome}</TableCell>
                                            {politicaViagem.utilizarcentrocusto ? (
                                                <React.Fragment>
                                                    <TableCell>{item.centroCusto}</TableCell>
                                                    <TableCell>{item.porcentagem}</TableCell>
                                                </React.Fragment>
                                            ) : null}
                                            <TableCell>{item.faixaEtaria ? item.faixaEtaria.label : ''}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                )}
            </div>
        </div>
    )
};

export default Reservar
