import cookie from 'universal-cookie';
import api from '../../../Api';

const cookies = new cookie();

const compraConsolidadaRepositorio = {

    InserirExcelConsolidado: async (listaConsolidados) => {
        const response = await api.post('/Relatorio/InserirExcelConsolidado', listaConsolidados, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
    },

    InserirExcelConsolidadoNew: async (file) => {
        const response = await api.post('/Relatorio/InserirExcelConsolidadoNew', file, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    OrganizarExcelConsolidado: async (file) => {
        const response = await api.post('/Relatorio/postOrganizarExcelConsolidado', file, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    GenerateExcel: async (file) => {
        const response = await api.post('/Relatorio/postGenerateExcel', file, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response;
    },

    getConsolidadoTenant: async (dto) => {
        const response = await api.post('/Relatorio/GetConsolidadoTenant', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response.data;
    },

    getConsolidadoTenantByNFatura: async (nfatura) => {
        const response = await api.get(`/Relatorio/GetConsolidadoTenantByNFatura/${nfatura}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response.data;
    },

    getConsolidadoTiposByTenant: async () => {
        const response = await api.get(`/Relatorio/GetConsolidadoTiposByTenant/`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response.data;
    },

    getTeste: async () => {
        const response = await api.get(`/teste/modeloTesteRelatorioConsolidado`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        })
        return response.data;
    },
}

export default compraConsolidadaRepositorio;
