import React from 'react';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const ExportExcel = props => {
    const multiDataSet = [
        {
            columns: props.columns,
            data: props.data
        },
    ];

    return (
        <ExcelFile filename={`Relatório - ${new Date().toLocaleString()}`} element={props.element} >
            <ExcelSheet dataSet={multiDataSet} name={props.name} />
        </ExcelFile>
    )
};

export default ExportExcel;
