import { makeStyles } from '@material-ui/core/styles';
import { indigo, yellow, amber, grey, red } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
    dialogTitle: {
        padding: theme.spacing(1, 3),
        background: '#c62828',
        color: 'black'
    },
    divtitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(0.2, 2)
    },
    dialogActions: {
        background: red[700],
        color: 'white',
        '&:hover': {
            background: red[900]
        },
    },
    dialog: {
        background: "#616161",
        color: 'white',
        position: 'relative',
    },
    dialogtittle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));
