import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';

export const LoadingButton = props => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const timer = React.useRef();

    const buttonClassname = clsx([classes.fab], {
        [classes.buttonSuccess]: success,
    });

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleButtonClick = async () => {
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            let response = await props.salvar();
            if (response) {
                if (response.status !== 204 && response.status !== 201) {
                    setSuccess(false);
                    setLoading(false);
                    return
                }
                setSuccess(true);
                setLoading(false);
                props.setModalOpen(props.isPessoa)
            }
            setSuccess(false);
            setLoading(false);
        }
    };

    return (
        <div className={classes.root}>
            <Fab
                aria-label="save"
                color="primary"
                className={buttonClassname}
                onClick={handleButtonClick}
            >
                {success ? <CheckIcon /> : <SaveIcon />}
            </Fab>
        </div>
    );
}

export default LoadingButton
