const INITIAL_STATE = {
    aereosSearch: {
        tipoPesquisaAct: 'nacional',

    },
    dtoPesquisaAereo: {
        retorno: false,
        adultos: 0,
        bebes: 0,
        criancas: 0,
        tipoPesquisa: '',
        tipoCategoria: '',
        dataIda: new Date,
        Trechos: [],
        dataVolta: new Date,
        tipoBagagem: 0,
        origem: '',
        destino: '',
        apenasVoosDiretos: false,
        tourcode: '',
        cia: '',
        cotar: false,
    },
}

const Variados = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_TIPO_PESQUISA':
            return { ...state, aereosSearch: action.tipoPesquisaAct }
        case 'SET_DATA_PESQUISA_AEREO':
            return { ...state, dtoPesquisaAereo: action.dtoPesquisaAereo }
        case 'CLEAR_DATA_PESQUISA_AEREO':
            return { ...state, dtoPesquisaAereo: INITIAL_STATE }
        default:
            return state;
    }
}

export default Variados;
