import { Box, Grid, List, ListItem, Typography } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { CheckCircleRounded } from "@material-ui/icons";
import React, { useState } from 'react';

function CentroCustoLIST(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [itensPerPage, setItensPergPage] = useState(10);
  const pages = props.data.length;
  const start = page * itensPerPage;
  const r = itensPerPage + start;
  const todosCentros = props.data.slice(start, r);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setItensPergPage(event.target.value);
  };

  return (
    <>
      <Box>
        <TablePagination
          component="div"
          style={{ minWidth: 'max-content' }}
          labelRowsPerPage={''}
          count={pages}
          page={page}
          variant={"outlined"}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 25, 50]}
          size={"small"}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <List>
        {todosCentros?.map((item) => (
          <ListItem divider onClick={() => props.handleCadastroOpen(item, true)}>
            <div>
              <Grid container spacing={1} style={{ border: "1px solid #c62828", padding: "10px", borderRadius: "20px", }}>
                <Grid item xs={8}>
                  <Typography variant="h6">
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                      ID: {item.idcentrocusto}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ display: 'grid', justifyContent: 'end', alignItems: 'center', color: item.ativo ? 'green' : 'red' }}>
                  <CheckCircleRounded />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Nome: {item.nome}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Código: {item.codigo}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Status: {item.ativo ? "Ativo" : "Inativo"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Impedir excedencia: {item.necessitaautorizacao ? "Sim" : "Não"}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Orçamento Restante: {item.orcamento}</Typography>
                </Grid>
              </Grid>
            </div>
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default CentroCustoLIST;
