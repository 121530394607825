import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import cookie from 'universal-cookie';
import api from '../../../Api';
import LinesTextField from '../../../components/LinesTextField';
import USearchField from '../../../components/usearchfield';
import USelect from '../../../components/uselect';
import USwitch from '../../../components/uswitch';
import Enums from '../../../enums';
import { useStyles } from './styles';

export const CadastroProduto = props => {
    const classes = useStyles();
    const cookies = new cookie();
    const itens = [{
        value: Enums['categoriaProduto'].hotel,
        label: 'Hotel'
    }, {
        value: Enums['categoriaProduto'].onibus,
        label: 'Ônibus'
    }, {
        value: Enums['categoriaProduto'].locacaoDeCarro,
        label: 'Locação de Carro'
    }, {
        value: Enums['categoriaProduto'].seguroViagem,
        label: 'Seguro Viagem'
    }, {
        value: Enums['categoriaProduto'].pacotes,
        label: 'Pacotes'
    }, {
        value: Enums['categoriaProduto'].aereo,
        label: 'Aereo'
    }, {
        value: Enums['categoriaProduto'].aereoInternacional,
        label: 'Aéreo Internacional'
    }, {
        value: Enums['categoriaProduto'].outros,
        label: 'Outros'

    }];
    const itens2 = [{
        value: Enums['tipoMoeda'].real,
        label: 'Real Brasileiro'
    }, {
        value: Enums['tipoMoeda'].dolar,
        label: 'US Dollar'
    }, {
        value: Enums['tipoMoeda'].euro,
        label: 'Euro'
    }]
    const [categoria, setCategoria] = useState(props.produto ? props.produto.categoria : 0 || 0);
    const [moeda, setMoeda] = useState(props.produto ? props.produto.moeda : 0 || 0);
    const [fornecedor, setFornecedor] = useState((props.produto.idproduto ? {
        label: props.produto.fornecedor.nome,
        value: props.produto.fornecedor.idfornecedor
    } : { label: '', value: 0 }) || null);
    const [fornecedores, setFornecedores] = useState([]);
    const [ativo, setAtivo] = useState((props.produto.idproduto ? props.produto.ativo : true) || false);

    const handleChange = event => {
        props.setProduto({
            ...props.produto,
            [event.target.name]: event.target.value
        })
    }

    useEffect(() => {
        let listaFornecedores = [];
        api.get('/fornecedor', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        }).then(response => {
            response.data.map(item => {
                listaFornecedores.push({
                    label: item.nome,
                    value: item.idfornecedor
                })
            })
            setFornecedores(listaFornecedores);
        })
    }, [])

    useEffect(() => {
        if (fornecedor) {
            props.setProduto({
                ...props.produto,
                idfornecedor: fornecedor.value
            })
        }
    }, [fornecedor])

    useEffect(() => {
        props.setProduto({
            ...props.produto,
            categoria: categoria
        })
    }, [categoria])

    useEffect(() => {
        props.setProduto({
            ...props.produto,
            moeda: moeda
        })
    }, [moeda])

    useEffect(() => {
        props.setProduto({
            ...props.produto,
            ativo: ativo
        })
    }, [ativo])

    return (
        <div className={classes.root}>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <USwitch {...{ checked: ativo, setChecked: setAtivo, label: 'Ativo' }} />
                </Grid>
                <Grid item xs={12}>
                    <LinesTextField
                        fullWidth
                        required
                        id="teste1"
                        label="Nome"
                        name="nome"
                        variant="outlined"
                        value={props.produto.nome || ''}
                        onChange={handleChange}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <USelect {...{ itens: itens, value: categoria, setValue: setCategoria, label: 'Categoria' }} />
                </Grid>
                <Grid item xs={12}>
                    <USearchField {...{ value: fornecedor, setValue: setFornecedor, itens: fornecedores, label: 'Fornecedor' }} />
                </Grid>
                <Grid item xs={12}>
                    <USelect {...{ itens: itens2, value: moeda, setValue: setMoeda, label: 'Moeda Corrente' }} />
                </Grid>
            </Grid>
        </div>
    )
};

export default CadastroProduto;
