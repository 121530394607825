import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect } from 'react';
import LinesTextField from '../LinesTextField';

export const USearchFieldAsync = props => {
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (open && props.changed) {
            (props.request)()
        }
    }, [open])

    return (
        <Autocomplete
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option.label}
            options={props.itens}
            loading={props.loading}
            loadingText="Buscando Parcelas..."
            clearText='Limpar'
            noOptionsText="Não há nada para selecionar"
            value={props.value || ''}
            onChange={(_event, newValue) => {
                props.setValue(newValue);
                if (props.onChange && newValue) {
                    props.onChange(props.name, newValue.value)
                }
            }}
            renderInput={(params) => (
                <LinesTextField
                    {...params}
                    required={props.required}
                    disabled={props.disabled}
                    label={props.label}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
};

export default USearchFieldAsync;
